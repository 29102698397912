import { useCallback, useEffect } from 'react'

function useKeyPress(targetKey: string, callback: () => void): void {
  // Handler to call the callback if the target key is pressed
  const downHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === targetKey) {
        callback()
      }
    },
    [targetKey, callback]
  )

  // Add event listeners for keydown events
  useEffect(() => {
    window.addEventListener('keydown', downHandler)

    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler)
    }
  }, [downHandler])

  return
}

export default useKeyPress
