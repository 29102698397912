/**
 * Hook to fetch data from the sportsYouApi, can handle graphql or static api calls
 */
import { DependencyList, useCallback, useEffect, useRef, useState } from 'react'

import { useIsMounted } from './use-is-mounted'
import { sleep } from '@sportsyou/core'
import {
  ApiResponse,
  CacheOptions,
  getErrorType,
  queryPing,
} from '@sportsyou/api'

enum ErrorType {
  API_ERROR = 'api_error',
  NETWORK_ERROR = 'network_error',
  NONE = 'none',
}
export interface UseFetchResponse<T extends ApiResponse> {
  cancel: () => void // function that lets the caller cancel a fetch in progress
  data?: T['data'] // convenience object to return response.data, cast to the correct type
  error?: T['error'] // convenience object to return response.error
  fetch: (passThrough?: any) => Promise<T> // function that lets the caller kick off a fetch
  isFetching: boolean // lets the caller see if a fetch is in progress, for a loading indicator or similar
  response?: T // returns the full response object from the fetch, cast to the type the caller specified
  setState: (response?: T) => void // a function that allows the caller to cancel any requests and set the response manually
}

export interface UseFetchOptions {
  deps?: DependencyList // Array of values to auto fire the fetch on change, pass [] to auto fire on mount only
  retryInterval?: number // how many milliseconds to wait between fetches, default is 1000 (1 second)
  retryOnNetworkError?: boolean // retry fetch if there is a network error, default is true
  retryOnNetworkErrorInBackground?: boolean // retry fetch in the background if there is a network error even after retry times has been used up, default is true
  retryTimes?: number // retry the fetch the specified number of times, default is 5
  returnFinalOnly?: boolean // if false, set the results using cached values immediately if possible. If true only set the results after all the retries have been tried. Default is true.
  noStateUpdates?: boolean // if true, have fetch only return the data, don't update any state variables
}

export function useFetchApi<T extends ApiResponse>(
  fetchFunction: (
    passThrough?: any
  ) => Promise<T> | Promise<undefined> | undefined,
  options?: UseFetchOptions
): UseFetchResponse<T> {
  const isMounted = useIsMounted()

  const [error, setError] = useState<string | any>()
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [response, setResponse] = useState<T>()

  const isCancelled = useRef<boolean>(false)
  const networkCheckInProgress = useRef(false) // ping the server to see if the network is reconnected

  const retryTimes = options?.retryTimes ?? 3
  const retryInterval = options?.retryInterval ?? 1000
  const retryOnNetworkError = options?.retryOnNetworkError ?? false
  const returnFinalOnly = options?.returnFinalOnly ?? false
  const retryOnNetworkErrorInBackground =
    options?.retryOnNetworkErrorInBackground ?? false

  const stopNetworkCheck = useCallback(() => {
    networkCheckInProgress.current = false
  }, [])

  // function to cancel the fetching, return to caller so it can cancel the fetch
  const cancel = useCallback(() => {
    isCancelled.current = true
    stopNetworkCheck()
    setIsFetching(false)
  }, [stopNetworkCheck])

  // function to do the fetching, return to caller so it can start the fetch
  const fetchData = useCallback(
    (passThrough?: any): Promise<T> => {
      const loadData = async () => {
        let res: T
        try {
          res = (await fetchFunction(passThrough)) ?? ({ ok: false } as T)
        } catch (err) {
          res = { ok: false, error: err, errorType: getErrorType(err) } as T
        }
        return res
      }

      if (options?.noStateUpdates) {
        return loadData()
      }

      setError(undefined)
      setResponse(undefined)
      isCancelled.current = false
      stopNetworkCheck()
      setIsFetching(true)

      const checkNetwork = async (passThrough?: any) => {
        let retryingFetch = false
        while (networkCheckInProgress.current) {
          await sleep(retryInterval * 3)
          try {
            const resp = await queryPing({
              requestOptions: { useCache: CacheOptions.NEVER },
            })
            if (resp.ok) {
              if (networkCheckInProgress.current)
                retryingFetch = retryOnNetworkError
              networkCheckInProgress.current = false
            }
          } catch (e) {
            console.warn(e)
          }
        }
        if (retryingFetch) {
          console.log('useFetchApi retrying after network recovery')
          setTimeout(() => {
            fetchData(passThrough)
          }, 10)
        }
      }

      const startNetworkCheck = (passThrough: any) => {
        if (!networkCheckInProgress.current) {
          networkCheckInProgress.current = true
          checkNetwork(passThrough)
        }
      }

      const retryFetch = async (res: T) => {
        if (!res) {
          return res
        }
        const errorType = res.errorType
        if (errorType === ErrorType.NONE) {
          return res
        }
        let retryCount = 0

        let retVal: T | void = res

        // on network do a loop retrying until we either get a value back or hit the retry limit
        while (
          retryOnNetworkError &&
          errorType === ErrorType.NETWORK_ERROR &&
          retryCount <= retryTimes &&
          !isCancelled.current
        ) {
          retryCount++
          retVal = await loadData()
          console.log('useFetchApi retrying on network error:', retVal)

          // if we got a network error then set in redux that the network hasn't been connected
          const isNetworkError = retVal?.errorType === ErrorType.NETWORK_ERROR

          if (retVal && (!retVal.ok || isNetworkError)) {
            if (
              isNetworkError &&
              retVal.isFromCache &&
              retryCount === 1 &&
              !returnFinalOnly
            ) {
              setResponse(retVal) // if we got the result from cache then set the response, but try again
            }
          } else {
            if (retVal) {
              retVal.errorType = ErrorType.NONE
            }
            return retVal
          }
          await sleep(retryInterval)
        }

        // we did all the retries and there is still a network error, kick off a loop in the background to check when the network comes back
        if (!isCancelled.current) {
          if (retVal?.errorType === ErrorType.NETWORK_ERROR) {
            if (retryOnNetworkErrorInBackground)
              setTimeout(startNetworkCheck, 10)
          }
        }

        return retVal
      }

      const setupData = async (): Promise<T> => {
        let res = await loadData()
        if (!isCancelled.current && res) {
          if (
            (!res?.ok && res?.error) ||
            (res?.errorType === ErrorType.NETWORK_ERROR && res?.isFromCache)
          ) {
            console.log('useFetchApi ERROR', res, res?.error)
            res = await retryFetch(res)
          }
          console.log('useFetchApi Result', res)
          setIsFetching(false)
          setResponse(res)
          if (
            res?.errorType === ErrorType.NETWORK_ERROR &&
            retryOnNetworkErrorInBackground
          ) {
            startNetworkCheck(passThrough)
          }
        } else if (!isCancelled.current) {
          setIsFetching(false)
          setResponse(undefined)
        } else {
          console.log('useFetchApi setupData isCancelled')
        }
        return res
      }

      return setupData()
    },
    [
      fetchFunction,
      options?.noStateUpdates,
      retryInterval,
      retryOnNetworkError,
      retryOnNetworkErrorInBackground,
      retryTimes,
      returnFinalOnly,
      stopNetworkCheck,
    ]
  )

  // function that allows the caller to change the api response object. It calls cancel first so any pending requests don't accidentally overwrite what the caller set
  const setState = useCallback(
    (response?: T) => {
      cancel()
      setResponse(response)
    },
    [cancel]
  )

  // if you want to do the query if a value changes, use deps to specify the value, otherwise kick off the fetch manually by cally the returned fetch function
  useEffect(() => {
    if (options?.deps) {
      if (isMounted || options?.deps?.length === 0) setTimeout(fetchData, 10)
    }
  }, [fetchData, isMounted, options?.deps])

  // when the component unmounts cancel anything running
  useEffect(() => {
    return () => {
      cancel()
    }
  }, [cancel])

  return {
    response,
    data: response?.data,
    setState,
    isFetching,
    error,
    fetch: fetchData,
    cancel,
  }
}

export default useFetchApi
