// TODO: Handle Icons
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { checkColorValue, Colors, darken, lighten } from '@sportsyou/core'

import Spinner from '../spinner'
import Tooltip from '../tooltip'
import { StatusTypes, VariantTypes } from '../../utils/prop-types'

export type ButtonAppearance = 'minimal' | 'ghost' | 'solid'
export type ButtonVariant = VariantTypes | StatusTypes

interface Props {
  /**
   * Changes the appearance of the button component
   */
  appearance?: ButtonAppearance
  /**
   * Custom className for button
   */
  className?: string
  collapse?: boolean
  /**
   * Whether the button is disabled or not
   */
  disabled?: boolean
  /**
   * Converts button to full width of parent
   */
  fullWidth?: boolean

  /**
   * TODO:
   */
  // iconBefore?: string
  // iconAfter?: string

  /**
   * Replaces children with loading spinner component
   */
  loading?: boolean

  /**
   * Text to display while loading
   */
  loadingText?: string

  onClick?: React.MouseEventHandler<HTMLButtonElement>

  /**
   * Add custom styling to the button
   */
  style?: React.CSSProperties
  textStyle?: React.CSSProperties
  /**
   * Button title (for accessibility)
   */
  title?: string
  tooltip?: string // show a tooltip on hover
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type']

  /**
   * Applies a white base for `minimal` appearance buttons for hover and active
   * states
   */
  useWhiteBase?: boolean

  value?: string
  /**
   * Changes button colors based on its intent
   */
  variant?: ButtonVariant

  // buttonColor?: string
}

type NativeAttributes = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  keyof Props
>
export type ButtonProps = Props & NativeAttributes

/**
 * A button to trigger actions or events
 */
export const Button = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<ButtonProps>
>(
  (
    {
      appearance = 'solid',
      // buttonColor = Colors.DUSTY_GRAY,
      children,
      className,
      collapse = false,
      disabled = false,
      fullWidth = false,
      id,
      loading = false,
      onClick,
      style,
      textStyle,
      tooltip,
      title = '',
      type = 'button',
      useWhiteBase = false,
      value = '',
      variant = 'primary',
      ...props
    }: ButtonProps,
    ref: React.Ref<HTMLButtonElement>
  ): React.ReactElement => {
    const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      !disabled && !loading && onClick?.(e)
    }

    // Function to return the current button color based of variant. This color
    // is used to dynamically style the button based off variant and state.
    const getButtonColor = useMemo(() => {
      let color = Colors.DUSTY_GRAY
      if (variant === 'primary') {
        color = Colors.HAVELOCK_BLUE
      } else if (variant === 'secondary') {
        color = Colors.PUNCH
      } else if (variant === 'alternate') {
        color = Colors.MINE_SHAFT
      } else if (variant === 'success') {
        color = Colors.MOUNTAIN_MEADOW
      } else if (variant === 'danger') {
        color = Colors.MONZA
      }
      return color
    }, [variant])

    const renderButton = () => (
      <StyledButton
        $appearance={appearance}
        $collapse={collapse}
        // send the color prop to our styled component to dynamically change
        // color based on the state or variant of the button
        $color={getButtonColor}
        className={className}
        disabled={disabled}
        id={id}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleOnClick(e)}
        ref={ref}
        style={{
          width: fullWidth ? '100%' : undefined,
          ...style,
        }}
        title={title}
        type={type}
        useWhiteBase={useWhiteBase}
        value={value}
        {...props}
      >
        {loading ? (
          <LoadingContainer>
            <StyledSpinner fill={'currentColor'} size={20} />
            {props.loadingText && (
              <Text style={textStyle}>{props.loadingText}</Text>
            )}
          </LoadingContainer>
        ) : (
          <Text style={textStyle}>{children ?? 'Button'}</Text>
        )}
      </StyledButton>
    )

    if (tooltip) {
      return (
        <Tooltip placement='top' text={tooltip} trigger='hover'>
          {renderButton()}
        </Tooltip>
      )
    } else {
      return renderButton()
    }
  }
)

const StyledButton = styled.button<{
  $appearance: ButtonAppearance
  $collapse: boolean
  $color: Colors
  useWhiteBase: boolean
}>`
  align-items: center;
  border: 2px solid transparent;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  font-family: inherit;
  min-height: 38px;
  min-width: ${({ $collapse }) => ($collapse ? '38px' : '90px')};
  padding-left: ${({ $collapse }) => ($collapse ? '4px' : '14px')};
  padding-right: ${({ $collapse }) => ($collapse ? '4px' : '14px')};
  // padding: 8px 14px;

  // position: relative;
  transition: 150ms ease-in-out;

  vertical-align: middle;

  background-color: ${({ $appearance, $color }) =>
    $appearance === 'solid' ? $color : 'transparent'};
  border-color: ${({ $appearance, $color }) =>
    $appearance === 'minimal' ? 'transparent' : $color};
  color: ${({ $appearance, $color = Colors.DUSTY_GRAY }) =>
    $appearance === 'solid' ? checkColorValue($color) : $color};

  outline: none;

  &:hover {
    background-color: ${({
      $appearance,
      $color = Colors.DUSTY_GRAY,
      useWhiteBase,
    }) =>
      $appearance === 'minimal' || $appearance === 'ghost'
        ? useWhiteBase
          ? 'rgba(255, 255, 255, 0.24)'
          : 'rgba(0, 0, 0, 0.06)'
        : darken($color, 12)};
    border-color: ${({ $appearance, $color = Colors.DUSTY_GRAY }) =>
      $appearance === 'minimal'
        ? 'transparent' // darken(Colors.WHITE, 12)
        : darken($color, 12)};
  }

  &:active {
    background-color: ${({
      $appearance,
      $color = Colors.DUSTY_GRAY,
      useWhiteBase,
    }) =>
      $appearance === 'minimal' || $appearance === 'ghost'
        ? useWhiteBase
          ? 'rgba(255, 255, 255, 0.3)'
          : 'rgba(0, 0, 0, 0.06)'
        : darken($color, 20)};
    border-color: ${({ $appearance, $color = Colors.DUSTY_GRAY }) =>
      $appearance === 'minimal' ? 'transparent' : darken($color, 20)};
  }

  &:focus {
    box-shadow: ${({ $color = Colors.DUSTY_GRAY }) =>
      `0 0 0 2px ${lighten($color, 40)}`};
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:active {
    background-color: ${Colors.ALTO};
    border-color: transparent !important;
    cursor: not-allowed;
  }
`
const Text = styled.span`
  align-items: center;
  color: inherit;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  user-select: none;
  ${StyledButton}:disabled & {
    color: ${Colors.DUSTY_GRAY};
  }
`

const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  gap: 10px;
`

const StyledSpinner = styled(Spinner)`
  display: inline-block;
`

Button.displayName = 'Button'

export default Button
