import { Navigate, Route, Routes } from 'react-router-dom'

import { Colors } from '@sportsyou/core'

import AuthLayout from './layouts/AuthLayout'
import CampaignEdit from './pages/Campaigns/Detail/Detail'
import Campaigns from './pages/Campaigns'
import Home from './pages/Home'
import LoginPage from './pages/Auth/Login'
import MainLayout from './layouts/MainLayout'
import Pages from './pages/Pages/PagesIndex'
import PagesPostDetail from './pages/Pages/Posts/Detail'
import PagesPostEditor from './pages/Pages/Posts/Editor'
import PagesProfile from './pages/Pages/Profile/Profile'
import SponsoredPostsDetail from './pages/SponsoredPosts/Detail'
import SponsoredPostsEdit from './pages/SponsoredPosts/Edit'
import SponsoredPostsIndex from './pages/SponsoredPosts/index'
import SponsoredPostsPreview from './pages/SponsoredPosts/Preview'
import useContentPortal from './UseContentPortal'

export function App() {
  const { portalAccess } = useContentPortal()

  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path='/' element={<LoginPage />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/logout' element={<LoginPage logout />} />
      </Route>

      <Route
        element={
          <MainLayout
            backgroundColor={Colors.MINE_SHAFT}
            portalId={undefined}
            portalName='Content Portal'
          />
        }
      >
        <Route
          path={'/home'}
          element={
            <Home
              canUseCampaigns={portalAccess.campaigns}
              canUsePages={portalAccess.pages}
              canUseSponsoredPosts={portalAccess.sponsoredPosts}
            />
          }
        />
      </Route>

      {portalAccess.sponsoredPosts ? (
        <Route
          element={
            <MainLayout
              backgroundColor={Colors.MINE_SHAFT}
              portalId='sponsoredPosts'
              portalName='Sponsored Posts'
            />
          }
        >
          <Route path={'/sponsored-posts'} element={<SponsoredPostsIndex />} />
          <Route
            path={'/sponsored-posts/new'}
            element={<SponsoredPostsEdit />}
          />
          <Route
            path={'/sponsored-posts/:id'}
            element={<SponsoredPostsDetail tab='metrics' />}
          />
          <Route
            path={'/sponsored-posts/:id/edit'}
            element={<SponsoredPostsEdit />}
          />
          <Route
            path={'/sponsored-posts/:id/metrics'}
            element={<SponsoredPostsDetail tab='metrics' />}
          />
          <Route
            path={'/sponsored-posts/:id/preview'}
            element={<SponsoredPostsPreview />}
          />
          <Route
            path={'/sponsored-posts/:id/moderation'}
            element={<SponsoredPostsDetail tab='moderation' />}
          />
        </Route>
      ) : null}

      {portalAccess.campaigns ? (
        <Route
          element={
            <MainLayout
              backgroundColor={Colors.PUNCH}
              portalId='campaigns'
              portalName='Campaigns'
            />
          }
        >
          <Route path={'/campaigns'} element={<Campaigns tab='campaigns' />} />
          <Route
            path={'/campaigns/settings'}
            element={<Campaigns tab='settings' />}
          />
          <Route path={'/campaigns/new'} element={<CampaignEdit />} />
          <Route path={'/campaigns/:id'} element={<CampaignEdit />} />
          <Route path={'/campaigns/:id/:tab'} element={<CampaignEdit />} />
        </Route>
      ) : null}

      {portalAccess.pages ? (
        <Route
          element={
            <MainLayout
              backgroundColor={Colors.DUSTY_GRAY}
              portalId='pages'
              portalName='Pages'
            />
          }
        >
          <Route path={'/pages'} element={<Pages />} />
          <Route path={'/pages/:id'} element={<PagesProfile />} />
          <Route
            path={'/pages/:id/settings'}
            element={<PagesProfile tab='settings' />}
          />
          <Route
            path={'/pages/:id/posts'}
            element={<PagesProfile tab='posts' />}
          />
          <Route path={'/pages/:id/posts/new'} element={<PagesPostEditor />} />
          <Route
            path={'/pages/:id/posts/:postId'}
            element={<PagesPostDetail />}
          />
          <Route
            path={'/pages/:id/posts/:postId/edit'}
            element={<PagesPostEditor />}
          />
        </Route>
      ) : null}

      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  )
}

export default App
