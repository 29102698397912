import { useCallback, useEffect, useMemo } from 'react'
import moment from 'moment'
import styled from 'styled-components'

import {
  Avatar,
  Button,
  DateRange,
  FadeInContainer,
  Select,
  TextInput,
} from '@sportsyou/react-dom-ui'
import { Campaign, Sponsor } from '@sportsyou/api'
import AudienceForm from '../AudienceForm/AudienceForm'

interface CampaignFormDetailsProps {
  campaign: Campaign
  campaignSponsor?: Sponsor
  hasChanges?: boolean
  isNew?: boolean
  onClickCancel: () => void
  onClickNext: () => void
  setCampaignProp: (key: keyof Campaign, value: unknown) => void
  sponsors: Sponsor[]
}

export default function CampaignFormDetails(props: CampaignFormDetailsProps) {
  const { campaign, campaignSponsor, isNew, setCampaignProp, sponsors } = props

  const onChangeCampaignName = useCallback(
    (e: any) => {
      setCampaignProp('campaignName', e.target.value)
    },
    [setCampaignProp]
  )

  const onClickPriorityOption = useCallback(
    (value: string) => {
      setCampaignProp('weight', value)
    },
    [setCampaignProp]
  )

  const renderSponsorName = useCallback((sponsor: Sponsor) => {
    return (
      <SponsorPickerItemName>
        <StyledAvatar name={sponsor.name as string} />
        {sponsor.name}
      </SponsorPickerItemName>
    )
  }, [])

  const canGoToNext = useMemo(() => {
    return !!campaign.campaignName && !!campaign.sponsorId
  }, [campaign])

  useEffect(() => {
    // if there is only one sponsor, set it as the default
    if (sponsors.length === 1) {
      setCampaignProp('sponsorId', sponsors[0].id)
    }
  }, [setCampaignProp, sponsors])

  return (
    <>
      <SelectFullWidth
        id='sponsor-select'
        displayValue={campaignSponsor?.name ?? ''}
        initialValue={campaignSponsor?.name ?? ''}
        label='Choose Sponsor'
      >
        {sponsors.map((sponsor, index) => (
          <Select.Option
            data-testid={`sponsor-option-${index}`}
            isSelected={campaign.sponsorId === sponsor.id}
            key={sponsor.id}
            onClick={() => setCampaignProp('sponsorId', sponsor.id)}
            value={sponsor.id as string}
          >
            {renderSponsorName(sponsor)}
          </Select.Option>
        ))}
      </SelectFullWidth>

      <TextInputFullWidth
        id='campaign-name'
        label='Campaign Name'
        onChange={onChangeCampaignName}
        placeholder=''
        value={campaign.campaignName ?? ''}
      />

      <DateRangeContainer>
        <StyledDateRange
          endDate={moment(campaign.endDate ?? new Date()).toDate()}
          labelEnd='End Date'
          labelStart='Start Date'
          setEndDate={(date) =>
            setCampaignProp('endDate', moment(date).toISOString())
          }
          setStartDate={(date) =>
            setCampaignProp('startDate', moment(date).toISOString())
          }
          startDate={moment(campaign.startDate ?? new Date()).toDate()}
        />
        <Select label='Priority' initialValue={campaign.weight?.toString()}>
          <Select.Option
            isSelected={campaign.weight === 100}
            onClick={onClickPriorityOption}
            value='100'
          >
            Highest
          </Select.Option>
          <Select.Option
            isSelected={campaign.weight === 75}
            onClick={onClickPriorityOption}
            value='75'
          >
            High
          </Select.Option>
          <Select.Option
            isSelected={campaign.weight === 50 || !campaign.weight}
            onClick={onClickPriorityOption}
            value='50'
          >
            Medium
          </Select.Option>
          <Select.Option
            isSelected={campaign.weight === 25}
            onClick={onClickPriorityOption}
            value='25'
          >
            Low
          </Select.Option>
          <Select.Option
            isSelected={campaign.weight === 0}
            onClick={onClickPriorityOption}
            value='0'
          >
            Lowest
          </Select.Option>
        </Select>
      </DateRangeContainer>

      {isNew || campaign?.selections ? (
        <FadeInContainer>
          <AudienceForm
            audience={campaign.selections ?? {}}
            campaignId={campaign.id as string}
            isEditable={true}
            setCampaignProp={setCampaignProp}
            sponsorId={campaign.sponsorId as string}
          />
        </FadeInContainer>
      ) : null}
      <NavButtons>
        <Button disabled={!canGoToNext} onClick={props.onClickNext}>
          Next
        </Button>
      </NavButtons>
    </>
  )
}

const NavButtons = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  margin-top: 20px;

  button {
    border-width: 1px;
  }
`

const TextInputFullWidth = styled(TextInput)`
  margin-bottom: 10px;
  width: 100%;
`

const SelectFullWidth = styled(Select)`
  margin-bottom: 10px;
  width: 100%;
`

const DateRangeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
`

const StyledDateRange = styled(DateRange)`
  flex-grow: 1;
`

const StyledAvatar = styled(Avatar)`
  height: 28px;
  width: 28px;
  margin-right: 10px;
`

const SponsorPickerItemName = styled.span`
  align-items: center;
  display: flex;
  gap: 10px;
`
