import { useCallback, useMemo, useState } from 'react'
import warning from '../utils/warning'

import Alert from '../components/alert'
import Banner from '../components/banner'
import Confirm from '../components/confirm'
// import Toast from '../components/toast'
import DialogContext, {
  AlertDialogProps,
  BannerDialogProps,
  ConfirmDialogProps,
  DialogType,
  ToastDialogProps,
  initialAlertValues,
  initialBannerValues,
  initialConfirmValues,
  initialToastValues,
} from '../context/dialog-context'

const RESET_TIMER = 450

export const DialogProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [alert, setAlert] = useState<AlertDialogProps>(initialAlertValues.alert)
  const [banner, setBanner] = useState<BannerDialogProps>(
    initialBannerValues.banner
  )
  const [confirm, setConfirm] = useState<ConfirmDialogProps>(
    initialConfirmValues.confirm
  )
  const [toast, setToast] = useState<ToastDialogProps>(initialToastValues.toast)

  const reset = useCallback((type: DialogType) => {
    if (type === 'alert') {
      setAlert((prevState) => ({
        ...prevState,
        isVisible: false,
      }))
      setTimeout(() => {
        setAlert(initialAlertValues.alert)
      }, RESET_TIMER)
    } else if (type === 'banner') {
      setBanner((prevState) => ({
        ...prevState,
        isVisible: false,
      }))
      setTimeout(() => {
        setBanner(initialBannerValues.banner)
      }, RESET_TIMER)
    } else if (type === 'confirm') {
      setConfirm((prevState) => ({
        ...prevState,
        isVisible: false,
      }))
      setTimeout(() => {
        setConfirm(initialConfirmValues.confirm)
      }, RESET_TIMER)
    } else if (type === 'toast') {
      setToast((prevState) => ({
        ...prevState,
        isVisible: false,
      }))
      setTimeout(() => {
        setToast(initialToastValues.toast)
      }, RESET_TIMER)
    }
  }, [])

  const sendAlert = useCallback((props: AlertDialogProps) => {
    if (!props.message) {
      warning({
        message: 'Message is missing from alert.',
        component: 'useDialog',
        prop: 'message',
      })
    }

    setAlert({
      ...initialAlertValues.alert,
      ...props,
      isVisible: true,
    })
  }, [])

  const sendBanner = useCallback((props: BannerDialogProps) => {
    if (!props.message) {
      warning({
        message: 'Message is missing from banner.',
        component: 'useDialog',
        prop: 'message',
      })
    }
    setBanner({
      ...initialBannerValues.banner,
      ...props,
      isVisible: true,
    })
  }, [])

  const sendConfirm = useCallback((props: ConfirmDialogProps) => {
    if (!props.message) {
      warning({
        component: 'useDialog',
        message: 'sendConfirm() is missing text prop',
        prop: 'message',
      })
    }
    setConfirm({
      ...initialConfirmValues.confirm,
      ...props,
      isVisible: true,
    })
  }, [])

  const sendToast = useCallback((props: ToastDialogProps) => {
    if (!props.message) {
      warning({
        message: 'Message is missing from toast dialog.',
        component: 'useDialog',
        prop: 'message',
      })
    }
    setToast({
      ...initialToastValues.toast,
      ...props,
      isVisible: true,
    })
  }, [])

  const defaultValues = useMemo(
    () => ({
      alert,
      banner,
      confirm,
      toast,
      reset,
      sendAlert,
      sendBanner,
      sendConfirm,
      sendToast,
      setAlert,
      setBanner,
      setConfirm,
      setToast,
    }),
    [
      alert,
      banner,
      confirm,
      reset,
      sendAlert,
      sendBanner,
      sendConfirm,
      sendToast,
      toast,
    ]
  )

  return (
    <DialogContext.Provider value={defaultValues}>
      {children}
      <Alert />
      <Banner />
      <Confirm />
      {/* <Toast />  <-- not used at the moment. also needs some adjustment to prevent excessive rerendering */}
    </DialogContext.Provider>
  )
}

export default DialogProvider
