import { CSSProperties, FC, PropsWithChildren } from 'react'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'

import CloseButton from '../close-button'

export interface ModalHeaderProps {
  children?: React.ReactNode
  className?: string
  onClick?: (event: React.MouseEvent) => void
  style?: CSSProperties
}

export const ModalHeader: FC<PropsWithChildren<ModalHeaderProps>> = ({
  children,
  className,
  onClick,
  style,
}: ModalHeaderProps) => (
  <Header className={className} style={style}>
    {children}
    <CloseButton
      appearance='circular'
      hideBackground
      onClick={onClick}
      size={24}
    />
  </Header>
)

const Header = styled.header`
  align-items: center;
  border-bottom: 1px solid ${Colors.ALTO};
  display: flex;
  flex: 0 0 auto;
  font-size: 14px;
  justify-content: space-between;
  padding: 10px 12px;
`

export default ModalHeader
