import React, { useMemo } from 'react'
import styled from 'styled-components'

interface Props {
  className?: string
  gap?: number | string
  isSticky?: boolean
  isVisible?: boolean
  showCloseButton?: boolean
  style?: React.CSSProperties
  width?: number | string
  yOffset?: number
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type ContentAsideProps = Props & NativeAttributes

export const ContentAside = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<ContentAsideProps>
>(
  (
    {
      children,
      className,
      gap,
      isSticky = true,
      isVisible = true,
      style,
      width = 230,
      yOffset = 0,
    }: ContentAsideProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const gapAsString = useMemo(() => {
      if (typeof gap === 'string') return gap
      return `${gap}px`
    }, [gap])

    const widthAsString = useMemo(() => {
      if (typeof width === 'string') return width
      return `${width}px`
    }, [width])

    return isVisible ? (
      <Aside
        className={className}
        gap={gapAsString}
        isSticky={isSticky}
        ref={ref}
        style={style}
        $width={widthAsString}
        yOffset={yOffset}
      >
        {children}
      </Aside>
    ) : null
  }
)

const Aside = styled.div<
  Pick<ContentAsideProps, 'gap' | 'isSticky' | 'yOffset'> & { $width: string }
>`
  display: none;
  @media all and (min-width: 768px) {
    display: block;
    flex: 0 0 ${({ $width }) => $width};
    margin-left: ${({ gap }) => gap};
    position: ${({ isSticky }) => (isSticky ? 'sticky' : 'static')};
    top: ${({ isSticky, yOffset }) => (isSticky ? `${yOffset}px` : 'unset')};
  }
`

export default ContentAside
