/*
 * Convert a number to a readable string (e.g. 1000 -> 1,000 or 1000000 -> 1,000,000 or 1000.1234 -> 1,000.1234)
 * @param value?: number | string
 * @returns string
 */
export default function readableNumber(value?: number | string): string {
  const [whole, decimals] = (value ?? '').toString().split('.')
  const formattedWhole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return decimals ? `${formattedWhole}.${decimals}` : formattedWhole
}
