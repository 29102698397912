import React, { useCallback, useEffect, useState } from 'react'

interface Props {
  className?: string
  clearTime?: number
  enterTime?: number
  leaveTime?: number
  name?: string
  onEnd?: (visible: boolean) => void
  onStart?: (visible: boolean) => void
  visible?: boolean
}

export type TransitionProps = Props

export const Transition: React.FC<React.PropsWithChildren<TransitionProps>> = ({
  children,
  className = '',
  clearTime = 60,
  enterTime = 60,
  leaveTime = 60,
  name = 'transition',
  onEnd,
  onStart,
  visible = false,
  ...props
}: React.PropsWithChildren<TransitionProps>) => {
  const [classNames, setClassNames] = useState<string>('')
  const [canRender, setCanRender] = useState<boolean>(visible)

  useEffect(() => {
    onStart?.(visible)
    const status = visible ? 'enter' : 'leave'
    const time = visible ? enterTime : leaveTime

    if (visible && !canRender) {
      setCanRender(true)
    }

    setClassNames(`${name}-${status}`)

    const timer = setTimeout(() => {
      setClassNames(`${name}-${status} ${name}-${status}-active`)
      clearTimeout(timer)
      onEnd?.(visible)
    }, time)

    const removeClassesTimer = setTimeout(() => {
      if (!visible) {
        setClassNames('')
        setCanRender(false)
      }
      clearTimeout(removeClassesTimer)
    }, time + clearTime)

    return () => {
      clearTimeout(timer)
      clearTimeout(removeClassesTimer)
    }
  }, [canRender, clearTime, enterTime, leaveTime, name, visible])

  const getClassNames = useCallback(
    (children: React.ReactElement) => {
      const classes = [className, classNames]
      if (children.props.className && children.props.className !== '') {
        classes.unshift(children.props.className)
      }
      return classes
    },
    [className, classNames]
  )

  if (!React.isValidElement(children) || !canRender) return null

  return React.cloneElement(children as React.ReactElement, {
    ...props,
    className: getClassNames(children),
  })
}

Transition.displayName = 'Transition'

export default Transition
