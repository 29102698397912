import * as React from 'react'
import type { SVGProps } from 'react'
const NavBell = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M26.33 19.71l-.92-.93a1.93 1.93 0 0 1-.57-1.35v-4a7.54 7.54 0 0 0-5.21-7.17v-.63a3.63 3.63 0 1 0-7.26 0v.58a7.54 7.54 0 0 0-5.21 7.17v4a1.93 1.93 0 0 1-.57 1.35l-.92.93a3.86 3.86 0 0 0-1.14 2.73 3.72 3.72 0 0 0 3.71 3.72h3.51a4.27 4.27 0 0 0 8.5 0h3.51a3.72 3.72 0 0 0 3.71-3.72 3.86 3.86 0 0 0-1.14-2.68zm-12-14.08a1.68 1.68 0 1 1 3.35 0v.23h-3.36zM16 28.05a2.35 2.35 0 0 1-2.3-1.89h4.59A2.34 2.34 0 0 1 16 28.05zm7.76-3.85H8.24a1.75 1.75 0 0 1-1.75-1.76 1.92 1.92 0 0 1 .56-1.35l.95-.93a3.86 3.86 0 0 0 1.14-2.73v-4a5.58 5.58 0 0 1 5.55-5.63h2.62a5.58 5.58 0 0 1 5.58 5.58v4A3.86 3.86 0 0 0 24 20.16l.92.93a1.92 1.92 0 0 1 .56 1.35 1.75 1.75 0 0 1-1.72 1.76z' />
  </svg>
)
export default NavBell
