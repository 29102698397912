import { Image as ImageProps } from '@sportsyou/api'

export interface GetTranscodeUrlInterface {
  transcodeTypes: Array<string>
  upload: Array<ImageProps>
}

/**
 * Get viewUrl from upload (transcodes from image/video) of given transcodeTypes.
 * Search will be done in the order of transcodeTypes given
 * (viewUrl from first found transcodeType will be returned).
 * @param param0.upload Image[] Array of upload transcodes
 * @param param0.transcodeTypes string[] Array of transcode types
 * @returns
 */
export const getTranscodeUrl = ({
  upload: transcodes,
  transcodeTypes,
}: GetTranscodeUrlInterface): string => {
  let url = ''

  if (!(transcodes?.length && transcodeTypes?.length)) return url

  url = transcodeTypes.reduce(
    (acc, curr) =>
      acc ||
      (transcodes.find((up) => up.transcodeType === curr)?.viewUrl ?? ''),
    url
  )

  return url
}

export default getTranscodeUrl
