import * as React from 'react'
import type { SVGProps } from 'react'
const ChevronRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 20 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path
      fillRule='evenodd'
      d='M2.21562.595843c.93328-.850005 2.37891-.782494 3.22891.150782L17.9351 14.4609c.7944.8722.7944 2.2058 0 3.0781L5.44514 31.2533c-.84998.9333-2.29561 1.0009-3.22891.1509-.9333-.85-1.00085-2.2956-.15087-3.2289L13.1537 16 2.06484 3.82475c-.85001-.93327-.7825-2.3789.15078-3.228907Z'
      clipRule='evenodd'
    />
  </svg>
)
export default ChevronRight
