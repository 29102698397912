import * as React from 'react'
import type { SVGProps } from 'react'
const FabCreateGroup = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M9.86 17.92a.67.67 0 0 0-.67-.67H5.84a4 4 0 0 0-4 4 .67.67 0 0 0 1.34 0 2.68 2.68 0 0 1 2.67-2.68h3.34a.67.67 0 0 0 .67-.65zM8.79 23.66a.74.74 0 1 0 1.48 0 4.2 4.2 0 0 1 4.2-4.19h.34c0-.12.06-.22.09-.32v-.1c0-.11.06-.23.1-.34a7.76 7.76 0 0 1 .3-.72h-.86a5.68 5.68 0 0 0-5.65 5.67zM16.94 15.76a5.06 5.06 0 1 0-3.58-1.48 5.05 5.05 0 0 0 3.58 1.48zm-2.53-7.58a3.57 3.57 0 0 1 5.06 0 3.57 3.57 0 0 1 0 5.06 3.58 3.58 0 1 1-5.06-5.06zM7.52 15.24A3.46 3.46 0 0 0 10 9.34a3.54 3.54 0 0 0-4.89 0 3.46 3.46 0 0 0 2.45 5.9zm-1.5-5a2.12 2.12 0 1 1 3 3 2.17 2.17 0 0 1-3 0 2.11 2.11 0 0 1 0-3zM26.1 17.54l-.36-.36a6.25 6.25 0 0 0-.83-.63 5.19 5.19 0 0 0-.65-.37l-.42-.13a5.05 5.05 0 0 0-1.66-.33h-.69a5.59 5.59 0 0 0-1.08.22 5.33 5.33 0 0 0-2 1.12c-.06 0-.12.09-.18.15a5.38 5.38 0 0 0-1.33 2.12c0 .09-.05.19-.08.28a5.5 5.5 0 0 0-.16.58 5.29 5.29 0 0 0-.08.8v.11a5.39 5.39 0 0 0 .14 1.14c0 .17.1.33.15.5a1.55 1.55 0 0 0 .06.23 5.35 5.35 0 0 0 .59 1.11l.05.07a5 5 0 0 0 .4.54 5.36 5.36 0 0 0 .83.75 5.3 5.3 0 0 0 2.32 1 5.18 5.18 0 0 0 .89.07 4.92 4.92 0 0 0 1.09-.11 5.43 5.43 0 0 0 3-9zm-.63 4a.57.57 0 0 1-.47.25h-2.36v2.37a.7.7 0 0 1 0 .14.6.6 0 0 1-.56.45.58.58 0 0 1-.56-.45v-.07-2.48h-2.43a.58.58 0 0 1-.59-.59.51.51 0 0 1 0-.13.59.59 0 0 1 .57-.46h2.37V18.2a.48.48 0 0 1 0-.18.59.59 0 0 1 1.15.18v2.37H25a.59.59 0 0 1 .59.56.56.56 0 0 1-.12.37z' />
  </svg>
)
export default FabCreateGroup
