import * as React from 'react'
import type { SVGProps } from 'react'
const X = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path
      fillRule='nonzero'
      d='M16 18.967l-7.418 7.418a2.098 2.098 0 1 1-2.967-2.967L13.033 16 5.615 8.582a2.098 2.098 0 0 1 2.967-2.967L16 13.033l7.418-7.418a2.098 2.098 0 0 1 2.967 2.967L18.967 16l7.418 7.418a2.098 2.098 0 1 1-2.967 2.967L16 18.967z'
    />
  </svg>
)
export default X
