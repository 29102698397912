import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import styled, { css, keyframes } from 'styled-components'

import { Colors } from '@sportsyou/core'

import { useToast } from '../../hooks/use-toast'

import Icon from '../icon'

import { ActivityTypes, StatusTypes } from '../../utils/prop-types'
import { getStatusColor, getStatusIcon } from '../../utils'
import hexToRGBA from '../../utils/color/hex-to-rgba'

import Transition from '../css-transition'

import { ToastItemProps } from './toast-types'

export type Toast = {
  id: string
  text: string | React.ReactNode
}

type ToastTransition = 'fade' | 'grow' | 'slide'

export const ToastItem: React.FC<React.PropsWithChildren<ToastItemProps>> = ({
  action,
  autoDismiss = true,
  children,
  delay = 4000,
  header,
  hideDismissButton,
  hideStatusIcon,
  id = '',
  light,
  message,
  onDismiss,
  showTimer,
  slideFromDirection = 'right',
  status,
  style,
  transition = 'slide',
  useMarkdown,
}: ToastItemProps) => {
  const [isVisible, setIsVisible] = useState(true)
  const toast = useToast()

  const progressRef = useRef<HTMLDivElement | null>(null)

  const timer = useRef<number | undefined>()

  const isReactNode = typeof message !== 'string'

  const handleDismiss = useCallback(() => {
    setIsVisible(false)
    // run animations first
    setTimeout(() => {
      toast.removeToast(id)
      onDismiss?.()
    }, 300)
  }, [id, onDismiss])

  useEffect(() => {
    if (autoDismiss) {
      timer.current = window.setTimeout(() => {
        handleDismiss()
      }, delay)
    }

    return () => {
      clearTimeout(timer.current)
    }
  }, [autoDismiss, delay, handleDismiss])

  const handleOnClickAction = (
    event: React.MouseEvent<HTMLButtonElement>,
    action: ToastItemProps['action']
  ) => {
    action?.onClick?.(event)
    handleDismiss()
  }

  const handleOnMouseEnter = () => {
    if (!autoDismiss) return

    if (!progressRef.current) return
    clearTimeout(timer.current)
    progressRef.current.style.animationPlayState = 'paused'
  }

  const handleOnMouseLeave = () => {
    if (!autoDismiss) return

    if (!progressRef.current || !progressRef.current.parentElement) return

    const remainingTime =
      (progressRef.current.offsetWidth /
        progressRef.current.parentElement.offsetWidth) *
      4000

    progressRef.current.style.animationPlayState = 'running'

    timer.current = window.setTimeout(() => {
      handleDismiss()
    }, remainingTime)
  }

  return (
    <Transition clearTime={300} name={transition} visible={isVisible}>
      <Container
        $status={status}
        isLight={!!light}
        hasStatus={!!status}
        // flexDirection={action && !autoDismiss ? 'column' : 'row'}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        $transition={transition}
        slideFromDirection={slideFromDirection}
        role='status'
        style={{ ...style }}
      >
        <Body flexDirection={action && !autoDismiss ? 'column' : 'row'}>
          {status && !hideStatusIcon && (
            <IconContainer>
              {status === 'processing' ? (
                <ProcessingIconContainer>
                  {getStatusIcon(status, {
                    fill: getStatusColor(status),
                    size: 16,
                    // style: { margin: 10 },
                  })}
                </ProcessingIconContainer>
              ) : (
                getStatusIcon(status, {
                  fill: getStatusColor(status),
                  size: 16,
                  // style: { margin: 10 },
                  // style: { alignSelf: 'center', marginRight: 6 },
                })
              )}
            </IconContainer>
          )}
          <TextContainer hasAutoDismiss={autoDismiss} hasStatus={!!status}>
            {header && <Header>{header}</Header>}
            {isReactNode ? (
              message
            ) : useMarkdown ? (
              <ReactMarkdown children={message} components={{ p: 'span' }} />
            ) : (
              <Text hasHeader={!!header}>{message}</Text>
            )}
          </TextContainer>

          {!autoDismiss && !action && (
            <CloseButton $status={status} onClick={handleDismiss}>
              <Icon fill='currentColor' name='X' size={12} />
            </CloseButton>
          )}
        </Body>
        {action && (
          <Footer>
            {autoDismiss ? (
              <ActionButton
                $status={status}
                hasStatus={!!status}
                onClick={(event) => handleOnClickAction(event, action)}
              >
                {action.label}
              </ActionButton>
            ) : (
              <>
                {!hideDismissButton && (
                  <DismissButton hasStatus={!!status} onClick={handleDismiss}>
                    Dismiss
                  </DismissButton>
                )}
                <ActionButton
                  $status={status}
                  hasStatus={!!status}
                  onClick={(event) => handleOnClickAction(event, action)}
                >
                  {action.label}
                </ActionButton>
              </>
            )}
          </Footer>
        )}
        {autoDismiss && showTimer && (
          <ProgressBarContainer>
            <ProgressBar $status={status} delay={delay} ref={progressRef} />
          </ProgressBarContainer>
        )}
        {status && <StatusBackground $status={status} />}
      </Container>
    </Transition>
  )
}
const ProgressAnim = keyframes`
  0% { width: 100%; }
  100% { width: 0%; }
`
// flex-direction: ${({ flexDirection }) => flexDirection};
// justify-content: ${({ flexDirection }) => flexDirection === 'row' ? 'space-between' : undefined};
const Container = styled.div<{
  isLight: boolean
  hasStatus: boolean
  slideFromDirection: 'left' | 'right'
  $status?: ActivityTypes | StatusTypes
  // flexDirection: CSSProperties['flexDirection']
  $transition?: ToastTransition
}>`
  background-color: ${({ hasStatus, isLight }) =>
    hasStatus ? Colors.WHITE : isLight ? Colors.WHITE : Colors.MINE_SHAFT};
  border: 1px solid
    ${({ isLight, $status }) =>
      $status ? getStatusColor($status) : isLight ? Colors.ALTO : Colors.BLACK};
  border-radius: 6px;

  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;

  color: ${({ isLight, $status }) =>
    isLight || $status ? Colors.MINE_SHAFT : Colors.WHITE};

  margin-top: 10px;
  max-width: 100%;
  // padding: 12px 16px;

  opacity: 0;

  position: relative;

  transform: ${({ $transition, slideFromDirection }) =>
    $transition === 'slide'
      ? slideFromDirection === 'right'
        ? 'translate3d(120%, 0, 0)'
        : 'translate3d(-120%, 0, 0)'
      : $transition === 'grow'
      ? 'scale(0.4)'
      : undefined};

  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  width: 320px;

  will-change: opacity, visibility;

  &.fade-enter {
    opacity: 0;
    visibility: hidden;
  }
  &.fade-enter-active {
    opacity: 1;
    visibility: visible;
  }
  &.fade-leave {
    opacity: 1;
    visibility: visible;
  }
  &.fade-leave-active {
    opacity: 0;
    visibility: hidden;
  }

  &.grow-enter {
    opacity: 0;
    transform: scale(0.4);
  }
  &.grow-enter-active {
    opacity: 1;
    transform: scale(1);
  }
  &.grow-leave {
    opacity: 1;
    transform: scale(1);
  }
  &.grow-leave-active {
    opacity: 0;
    transform: scale(0.4);
  }
  &.slide-enter {
    opacity: 0;
    transform: translate3d(
      ${({ slideFromDirection }) =>
        slideFromDirection === 'right' ? '120%' : '-120%'},
      0,
      0
    );
  }
  &.slide-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.slide-leave {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.slide-leave-active {
    opacity: 0;
    transform: translate3d(
      ${({ slideFromDirection }) =>
        slideFromDirection === 'right' ? '120%' : '-120%'},
      0,
      0
    );
  }

  @media all and (min-width: 767px) {
    width: 400px;
  }
`
const StatusBackground = styled.div<{ $status?: ActivityTypes | StatusTypes }>`
  background-color: ${({ $status }) =>
    $status ? hexToRGBA(getStatusColor($status), 10) : undefined};
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
`
const Body = styled.div<{
  flexDirection: React.CSSProperties['flexDirection']
}>`
  display: flex;
`
const Footer = styled.div`
  justify-content: flex-end;
  display: flex;
  margin: 0 10px 10px;
`
const TextContainer = styled.div<{
  hasAutoDismiss: boolean
  hasStatus: boolean
}>`
  margin-left: ${({ hasStatus }) => (!hasStatus ? '15px' : undefined)};
  margin-right: ${({ hasAutoDismiss }) => (hasAutoDismiss ? '15px' : '10px')};

  display: flex;
  flex-direction: column;
  min-height: 40px;

  a {
    color: inherit;
    transition: background-color 90ms ease-in-out;
    &:hover,
    &:active {
      background-color: rgba(255, 255, 255, 0.12);
    }
  }
`
const Header = styled.p`
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
  margin-top: 10px;
`
const Text = styled.p<{ hasHeader: boolean }>`
  font-size: 0.85rem;
  line-height: 1.4;
  margin: 0;
  // 1om: 0.25rem;
  // margin-top: 0.25rem;
  // margin-right: 0.2rem;
  margin-top: ${({ hasHeader }) => (hasHeader ? '2px' : '10px')};
  margin-bottom: 10px;
`
const ButtonStyles = css`
  border-radius: 4px;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  min-height: 1px;
  min-width: 1px;
  padding-top: 0.25rem;
  padding: 0.25rem 0.5rem;
  transition: background-color 90ms ease-in-out;
`
const DismissButton = styled.button<{ hasStatus: boolean }>`
  ${ButtonStyles};
  margin-right: 8px;

  &:hover,
  &:active {
    text-decoration: underline;
    background-color: ${({ hasStatus }) =>
      hasStatus ? undefined : 'rgba(255, 255, 255, 0.12)'};
    border-color: transparent;
  }
`
const ActionButton = styled.button<{
  $status?: ActivityTypes | StatusTypes
  hasStatus: boolean
}>`
  ${ButtonStyles};

  // margin-left: auto;

  background-color: ${({ hasStatus, $status }) =>
    hasStatus
      ? $status
        ? hexToRGBA(getStatusColor($status), 20)
        : 'rgba(255, 255, 255, 0.12)'
      : undefined};
  &:hover,
  &:active {
    background-color: ${({ $status }) =>
      $status
        ? hexToRGBA(getStatusColor($status), 30)
        : 'rgba(255, 255, 255, 0.12)'};
    border-color: transparent;
  }
`
// ? darken(getStatusColor($status), 5)
// color: ${Colors.WHITE};
const CloseButton = styled.button<{ $status?: ActivityTypes | StatusTypes }>`
  align-items: center;
  align-self: center;
  background: none;
  color: inherit;

  flex: 0 0 30px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: 10px;
  height: 30px;
  width: 30px;
  // right: -16px;
  // top: -16px;
  // position: absolute;
  transition: background-color 90ms ease-in-out;
  &:hover {
    background-color: ${({ $status }) =>
      $status
        ? hexToRGBA(getStatusColor($status), 10)
        : 'rgba(255, 255, 255, 0.08)'};
  }
  &:active {
    background-color: rgba(255, 255, 255, 0.2);
  }
`
const ProgressBarContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.1);
`
const ProgressBar = styled.div<{
  delay: number
  $status?: ActivityTypes | StatusTypes
}>`
  height: 100%;
  animation: ${ProgressAnim} ${({ delay }) => delay}ms linear forwards;
  background-color: ${({ $status }) =>
    $status ? getStatusColor($status) : Colors.DUSTY_GRAY};
`
const IconContainer = styled.div`
  height: 40px;
  width: 40px;
  flex: 0 0 auto;
  display: inline-flex;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
`
const SpinAnim = keyframes`
from { transform: rotate(0)};
to { transform: rotate(360deg)};
`
const ProcessingIconContainer = styled.div`
  animation: ${SpinAnim} 1200ms linear infinite;
  align-items: center;
  display: flex;
  justify-content: center;
`

// .toast-progress-bar.success {
//   background-color: var(--success);
// }

// .toast-progress-bar.info {
//   background-color: var(--info);
// }

// .toast-progress-bar.warning {
//   background-color: var(--warning);
// }

// .toast-progress-bar.error {
//   background-color: var(--error);
// }

export default ToastItem
