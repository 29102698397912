import React from 'react'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'

import Dropdown from '../../dropdown'

export type PostMenuAction =
  | {
      name: string
      fn: () => void
      isDestructive?: boolean
    }
  | 'divider'

export interface PostMenuProps {
  actions?: Array<PostMenuAction>
  style?: React.CSSProperties
}

export const PostMenu: React.FC<PostMenuProps> = ({
  actions,
  style,
}: PostMenuProps) => {
  if (actions && actions.length === 0) return null
  return (
    <DropdownContainer style={style}>
      <Dropdown
        title={'•••'}
        collapse
        hideChevron
        placement='bottomEnd'
        triggerStyle={{ borderRadius: '50%' }}
      >
        {actions?.map((action: PostMenuAction, i: number) => {
          return action === 'divider' ? (
            <Dropdown.Divider key={i} />
          ) : (
            <Dropdown.Item key={i} onClick={action.fn}>
              <span
                style={{
                  color: action.isDestructive
                    ? Colors.MONZA
                    : Colors.MINE_SHAFT,
                }}
              >
                {action.name}
              </span>
            </Dropdown.Item>
          )
        })}
      </Dropdown>
    </DropdownContainer>
  )
}

const DropdownContainer = styled.div`
  margin-left: auto;
`

export default PostMenu
