import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'
import { Checkmark } from '@sportsyou/react-icons'

export interface CheckboxEventTarget {
  checked: boolean
  value: string
}

export interface CheckboxEvent {
  nativeEvent: React.ChangeEvent
  preventDefault: () => void
  stopPropagation: () => void
  target: CheckboxEventTarget
}

interface Props {
  checked?: boolean
  checkboxStyle?: React.CSSProperties
  checkboxTriggersChange?: boolean
  className?: string
  description?: string
  descriptionStyle?: React.CSSProperties
  disabled?: boolean
  id?: string
  /**
   * State in addition to "checked" and "unchecked". When true, the checkbox
   * displays a "minus" icon.
   */
  indeterminate?: boolean
  labelClassName?: string
  labelStyle?: React.CSSProperties
  name?: string
  size?: number
  // status?: 'success' | 'danger'
  style?: React.CSSProperties
  textContainerStyle?: React.CSSProperties
  title?: string
  /** The value attribute of the checkbox. */
  value?: string
  /** Function called when state changes. */
  onChange?: (e: CheckboxEvent) => void
  /**
   * Function called when label is clicked changes.
   * `checkboxTriggersChange` must be set to true for this to fire.
   */
  onClickLabel?: () => void
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type CheckboxProps = Props & NativeAttributes

/**
 * The Checkbox component allows user to select multiple items from a list.
 */
export const Checkbox: FC<CheckboxProps> = ({
  checkboxStyle,
  checkboxTriggersChange = false,
  checked = false,
  children,
  className,
  description,
  descriptionStyle,
  disabled = false,
  id,
  indeterminate = false,
  labelClassName,
  labelStyle,
  name,
  onChange,
  onClickLabel,
  size = 18,
  style,
  textContainerStyle,
  title,
  value = '',
  ...props
}: CheckboxProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked)

  // handle state updates when prop change
  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  // update state and return event when change occurs
  const handleOnChange = (event: React.ChangeEvent) => {
    setIsChecked(!isChecked)

    const _event: CheckboxEvent = {
      target: {
        checked: !isChecked,
        value: (event.target as HTMLInputElement).value,
      },
      stopPropagation: event.stopPropagation,
      preventDefault: event.preventDefault,
      nativeEvent: event,
    }
    onChange?.(_event)
  }

  const handleOnClickLabel = useCallback(() => {
    checkboxTriggersChange && onClickLabel?.()
  }, [checkboxTriggersChange, onClickLabel])

  const renderLabel = useMemo(
    () => (
      <TextContainer onClick={handleOnClickLabel} style={textContainerStyle}>
        {children && (
          <Text className={labelClassName} style={labelStyle}>
            {children}
          </Text>
        )}
        {description && (
          <Description style={descriptionStyle}>{description}</Description>
        )}
      </TextContainer>
    ),
    [
      children,
      description,
      descriptionStyle,
      handleOnClickLabel,
      labelClassName,
      labelStyle,
      textContainerStyle,
    ]
  )

  // seperate the checkbox from the label to allow clicks on the label
  if (checkboxTriggersChange) {
    return (
      <Container className={className} style={style}>
        <Label
          $disabled={disabled}
          htmlFor={name}
          aria-label={title}
          title={title}
          // onClick={handleOnClick}
        >
          <HiddenInput
            aria-checked={isChecked}
            checked={isChecked}
            disabled={disabled}
            id={id}
            name={name}
            onChange={(e: React.ChangeEvent) => handleOnChange(e)}
            type='checkbox'
            value={value}
            {...props}
          />
          <CheckmarkContainer
            $checked={isChecked}
            $disabled={disabled}
            $size={size}
            style={{
              alignSelf: children && description ? 'flex-start' : undefined,
              ...checkboxStyle,
            }}
          >
            {indeterminate ? (
              <Dash />
            ) : isChecked ? (
              <Checkmark fill={disabled ? Colors.DUSTY_GRAY : Colors.WHITE} />
            ) : null}
          </CheckmarkContainer>
        </Label>
        {(children || description) && renderLabel}
      </Container>
    )
  }

  return (
    <Label
      $disabled={disabled}
      aria-label={title}
      className={className}
      htmlFor={name}
      style={style}
      title={title}
    >
      <HiddenInput
        aria-checked={isChecked}
        checked={isChecked}
        disabled={disabled}
        id={id}
        name={name}
        onChange={(e: React.ChangeEvent) => handleOnChange(e)}
        type='checkbox'
        value={value}
        {...props}
      />
      <CheckmarkContainer
        $checked={isChecked}
        $disabled={disabled}
        $size={size}
        style={{
          alignSelf: children && description ? 'flex-start' : undefined,
          ...checkboxStyle,
        }}
      >
        {indeterminate ? (
          <Dash />
        ) : isChecked ? (
          <Checkmark fill={disabled ? Colors.DUSTY_GRAY : Colors.WHITE} />
        ) : null}
      </CheckmarkContainer>
      {(children || description) && renderLabel}
    </Label>
  )
}

const Container = styled.div`
  align-items: center;
  display: inline-flex;
`
const Label = styled.label<{ $disabled: boolean }>`
  align-items: center;
  color: ${Colors.MINE_SHAFT};
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  outline: 0;
  position: relative;
  user-select: none;
`
const HiddenInput = styled.input`
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  visibility: hidden;
`
const CheckmarkContainer = styled.div<{
  $checked: boolean
  $disabled: boolean
  $size: number
}>`
  background-color: ${({ $checked, $disabled }) =>
    !$checked ? Colors.WHITE : $disabled ? Colors.ALTO : Colors.HAVELOCK_BLUE};
  transition: background-color 120ms ease-in-out, border-color 120ms ease-in-out;
  border: 2px solid
    ${({ $checked, $disabled }) =>
      $disabled
        ? Colors.ALTO
        : $checked
        ? Colors.HAVELOCK_BLUE
        : Colors.SHUTTLE_GRAY};
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  height: ${({ $size }) => $size}px;
  width: ${({ $size }) => $size}px;
  align-items: center;
  justify-content: center;
  padding: 2px;
`
const Dash = styled.span`
  background-color: ${Colors.SHUTTLE_GRAY};
  border-radius: 4px;
  height: 2px;
  width: 80%;
`
const TextContainer = styled.div`
  flex-direction: column;
  display: flex;
  margin-left: 6px;
`
const Text = styled.span`
  line-height: 20px;
`
const Description = styled.span`
  line-height: 20px;
  color: ${Colors.DUSTY_GRAY};
`

export default Checkbox
