import { CSSProperties, useCallback, useMemo } from 'react'

import Avatar from '../avatar'
import { AvatarDataTypes, AvatarGroupItemProps } from '../avatar/helpers'

interface AvatarGridPropTypes {
  data: AvatarDataTypes[]
  backgroundColor?: string
  borderRadius?: number
  items: Array<AvatarGroupItemProps>
  diameter?: number
  hideBackground?: boolean

  names?: Array<string>
  layout?: 'grid' | 'stack'
  stackDirection?: 'horizontal' | 'vertical' | 'diagonal'
  testId?: string
}

const AvatarGroupGrid: React.FC<AvatarGridPropTypes> = ({
  backgroundColor,
  data,
  borderRadius,
  hideBackground,
  diameter = 60,
  names = [],
  testId,
}: AvatarGridPropTypes) => {
  const adjustedDiameter = diameter / 2 - 4

  const setContainerStyles = useMemo(() => {
    const sharedStyles: CSSProperties = {
      boxSizing: 'border-box',
      backgroundColor: hideBackground
        ? 'transparent'
        : backgroundColor ?? '#d7d8e1',
      borderRadius: hideBackground ? 0 : diameter / 4,
      // padding: 4,
      height: diameter,
      justifyContent: 'space-between',
      position: 'relative',
      width: diameter,
    }
    let containerStyles: CSSProperties = {}

    if (names.length >= 4) {
      containerStyles = {
        display: 'flex',
        flexWrap: 'wrap',
      }
    }

    return {
      ...sharedStyles,
      ...containerStyles,
    }
  }, [backgroundColor, diameter, hideBackground, names.length])

  const setAvatarPosition = useCallback(
    (index: number): CSSProperties => {
      const total = names.length
      let styles: CSSProperties = {}
      const sharedStyles: CSSProperties = {
        flex: `0 0 ${adjustedDiameter}px`,
        position: total === 4 ? 'relative' : 'absolute',
      }
      const spaceFromEdge = diameter / 2 - adjustedDiameter - 1

      if (total === 2) {
        if (index === 0) {
          styles = {
            left: spaceFromEdge,
            top: spaceFromEdge,
          }
        } else if (index === 1) {
          styles = {
            bottom: spaceFromEdge,
            right: spaceFromEdge,
          }
        }
      } else if (total === 3) {
        if (index === 0) {
          styles = {
            left: spaceFromEdge,
            top: spaceFromEdge,
          }
        } else if (index === 1) {
          styles = {
            right: spaceFromEdge,
            top: spaceFromEdge,
          }
        } else if (index === 2) {
          styles = {
            bottom: spaceFromEdge,
            left: spaceFromEdge,
          }
        }
      } else if (total >= 4) {
        if (index === 0) {
          styles = {
            position: 'absolute',
            left: spaceFromEdge,
            top: spaceFromEdge,
          }
        } else if (index === 1) {
          styles = {
            position: 'absolute',
            right: spaceFromEdge,
            top: spaceFromEdge,
          }
        } else if (index === 2) {
          styles = {
            left: spaceFromEdge,
            bottom: spaceFromEdge,
            position: 'absolute',
          }
        } else if (index === 3) {
          styles = {
            right: spaceFromEdge,
            bottom: spaceFromEdge,
            position: 'absolute',
          }
        }
        styles = {
          ...styles,
          // flexDirection: 'row' : 'column',
          display: 'flex',
          flexWrap: 'wrap',
        }
      }

      return {
        ...sharedStyles,
        ...styles,
      }
    },
    [adjustedDiameter, diameter, names.length]
  )

  return (
    <div data-testid={testId} style={{ ...setContainerStyles }}>
      {data.length &&
        data.map((item, index) => (
          <Avatar
            borderRadius={borderRadius}
            diameter={data.length === 2 ? diameter / 2 : adjustedDiameter}
            // fontSize={12}
            key={index}
            name={item.display}
            uri={item.uri}
            useRawName={item.useRawString}
            style={setAvatarPosition(index)}
            title={item.title}
          />
        ))}
    </div>
  )
}

export default AvatarGroupGrid
