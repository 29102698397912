import { FC, HTMLAttributes, PropsWithChildren } from 'react'
import { Colors } from '@sportsyou/core'
import { BsWindows } from 'react-icons/bs'
import styled from 'styled-components'

interface Props {
  className?: string
  command?: boolean
  ctrl?: boolean
  option?: boolean
  shift?: boolean
  windows?: boolean
}

type NativeAttributes = Omit<HTMLAttributes<HTMLElement>, keyof Props>
export type KeyboardProps = Props & NativeAttributes

export const Keyboard: FC<PropsWithChildren<KeyboardProps>> = ({
  children,
  className = '',
  command = false,
  ctrl = false,
  option = false,
  shift = false,
  windows = false,
  ...props
}: KeyboardProps) => (
  <Container className={className} {...props}>
    {windows && (
      <Span>
        <BsWindows size={10} />
      </Span>
    )}
    {command && <Span>⌘</Span>}
    {shift && <Span>⇧</Span>}
    {option && <Span>⌥</Span>}
    {ctrl && <Span>⌃</Span>}
    {children && <Span>{children}</Span>}
  </Container>
)

const Container = styled.kbd`
  background-color: #fafafa;
  border: 1px solid ${Colors.ALTO};
  border-radius: 4px;
  box-sizing: border-box;
  color: ${Colors.SHUTTLE_GRAY};
  display: inline-block;
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 0.875rem;
  height: auto;
  line-height: 2em;
  margin: 0;
  min-height: 2em;
  min-width: 2em;
  padding: 0 0.6em;
  text-align: center;
  user-select: none;
  width: fit-content;

  & + & {
    margin-left: 0.4em;
  }
`
const Span = styled.span`
  font-size: 1em;
  line-height: 2em;

  text-align: center;
  & + span {
    margin-left: 0.3em;
  }
`

export default Keyboard
