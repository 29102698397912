import * as React from 'react'
import type { SVGProps } from 'react'
const FolderSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M30.37 5.9h-9.94l-3-3.25a1 1 0 0 0-.73-.32h-15A1.63 1.63 0 0 0 0 4v24a1.63 1.63 0 0 0 1.63 1.63h28.74A1.63 1.63 0 0 0 32 28V7.53a1.63 1.63 0 0 0-1.63-1.63z' />
  </svg>
)
export default FolderSolid
