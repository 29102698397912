import * as React from 'react'
import type { SVGProps } from 'react'
const FabCode = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M23.23 13.23A2.77 2.77 0 0 0 20.47 16a2.74 2.74 0 0 0 .39 1.39l-3.47 3.47a2.74 2.74 0 0 0-1.39-.39 2.77 2.77 0 1 0 2.76 2.76 2.72 2.72 0 0 0-.38-1.38l3.47-3.47a2.72 2.72 0 0 0 1.38.38 2.77 2.77 0 1 0 0-5.53zM16 24.6a1.37 1.37 0 1 1 1.37-1.37A1.37 1.37 0 0 1 16 24.6zm7.23-7.23A1.37 1.37 0 1 1 24.6 16a1.38 1.38 0 0 1-1.37 1.37z' />
    <path d='M23.23 20.47A2.77 2.77 0 1 0 26 23.23a2.77 2.77 0 0 0-2.77-2.76zm0 4.13a1.37 1.37 0 1 1 1.37-1.37 1.37 1.37 0 0 1-1.37 1.37zM14.61 18.38a2.73 2.73 0 0 0 1.39.38A2.77 2.77 0 0 0 18.76 16a2.73 2.73 0 0 0-.38-1.39l3.47-3.47a2.73 2.73 0 0 0 1.38.39 2.79 2.79 0 1 0-2.37-1.37l-3.47 3.46a2.74 2.74 0 0 0-1.39-.39A2.78 2.78 0 0 0 13.23 16a2.74 2.74 0 0 0 .39 1.39l-3.46 3.47a2.76 2.76 0 1 0 1.37 2.37 2.73 2.73 0 0 0-.39-1.38zm8.62-11a1.37 1.37 0 1 1-1.37 1.36 1.36 1.36 0 0 1 1.37-1.34zM16 14.63A1.37 1.37 0 1 1 14.63 16 1.37 1.37 0 0 1 16 14.63zm-7.24 10a1.37 1.37 0 1 1 1.37-1.37 1.36 1.36 0 0 1-1.37 1.34z' />
    <path d='M8.76 18.76A2.77 2.77 0 0 0 11.53 16a2.74 2.74 0 0 0-.39-1.39l3.47-3.47a2.74 2.74 0 0 0 1.39.39 2.79 2.79 0 1 0-2.38-1.37l-3.46 3.46a2.76 2.76 0 1 0-1.4 5.14zM16 7.4a1.37 1.37 0 1 1-1.37 1.36A1.37 1.37 0 0 1 16 7.4zm-7.24 7.23A1.37 1.37 0 1 1 7.4 16a1.37 1.37 0 0 1 1.36-1.37z' />
    <path d='M8.76 11.53A2.77 2.77 0 1 0 6 8.76a2.77 2.77 0 0 0 2.76 2.77zm0-4.13A1.37 1.37 0 1 1 7.4 8.76 1.36 1.36 0 0 1 8.76 7.4z' />
  </svg>
)
export default FabCode
