import { Colors } from '@sportsyou/core'
import { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { BORDER_RADIUS, Button, FadeInContainer } from '@sportsyou/react-dom-ui'
import { Upload } from '@sportsyou/api'
import AssetPickerItem from './AssetPickerItem'

interface AssetPickerProps {
  assets: Upload[]
  locationName: string
  onChange: (locationName: string, uploadId: string | number) => void
  selectedAssetId?: string | null
}

export default function AssetPicker({
  assets,
  locationName,
  onChange,
  selectedAssetId,
}: AssetPickerProps) {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  const handleOnClick = useCallback(
    (uploadId?: string | number) => {
      uploadId && onChange(locationName, uploadId)
      handleToggle()
    },
    [handleToggle, locationName, onChange]
  )

  const selectedAsset = useMemo(() => {
    return assets?.find((asset) => asset.id === selectedAssetId)
  }, [assets, selectedAssetId])

  return (
    <Container onClick={handleToggle}>
      <Header>
        {selectedAsset ? (
          <AssetPickerItem
            asset={selectedAsset}
            locationName={locationName}
            onClick={handleToggle}
          />
        ) : null}
        <ToggleButton appearance='ghost'>Select from saved assets</ToggleButton>
      </Header>
      {isOpen ? (
        <Content>
          <AssetPickerContent>
            {assets?.map((asset) => (
              <AssetPickerItem
                asset={asset}
                key={asset.id}
                locationName={locationName}
                onClick={handleOnClick}
                selectable
                selected={selectedAssetId === asset.id}
              />
            ))}
          </AssetPickerContent>
        </Content>
      ) : null}
    </Container>
  )
}

const Container = styled.div`
  background-color: ${Colors.WHITE};
  border-radius: ${BORDER_RADIUS};
  border: 1px solid ${Colors.ALTO};
  cursor: pointer;
`

const Header = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  gap: 20px;
`

const Content = styled(FadeInContainer)`
  border-top: 2px solid ${Colors.ALTO};
`

const AssetPickerContent = styled.div`
  border: 0;
  width: 100%;
  padding: 0;
  padding-top: 0;
  padding-bottom: 0;

  & > div:not(:last-child) {
    border-bottom: 1px solid ${Colors.ALTO};
  }
`

const ToggleButton = styled(Button)`
  font-size: 16px;
  min-width: 200px;
`
