import React, { useCallback } from 'react'

import { Colors, FILE_COLORS } from '@sportsyou/core'
import { IconNames } from '@sportsyou/react-icons'

import Icon from '../icon'
interface GetFileIconProps {
  color: string
  icon: IconNames | string
}

interface Props {
  className?: string
  contentType?: string
  fileName: string
  isFolder?: boolean
  nameCase?: 'kebab' | 'pascal'
  size?: number
  style?: React.CSSProperties
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type FileTypeIconProps = Props & NativeAttributes

const getFileIcon = (
  fileName: string,
  contentType?: string,
  isFolder?: boolean
): GetFileIconProps => {
  const fileExtension: string = fileName?.split('.').pop()?.toLowerCase() ?? ''
  const fileIcon: GetFileIconProps = {
    icon: 'generic',
    color: FILE_COLORS.GENERIC,
  }

  switch (contentType) {
    case 'video/avi':
    case 'video/msvideo':
    case 'video/x-msvideo':
    case 'application/x-troff-msvideo':
      fileIcon.icon = 'avi'
      break
    case 'application/msword': // .doc, .dot
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': // .docx
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template': // .dotx
    case 'application/vnd.ms-word.document.macroEnabled.12': // .docm
    case 'application/vnd.ms-word.template.macroEnabled.12': // .dotm
      fileIcon.icon = 'doc'
      break
    case 'image/jpeg':
      fileIcon.icon = 'jpg'
      break
    case 'video/x-m4v':
      fileIcon.icon = 'm4v'
      break
    case 'video/mkv':
    case 'video/x-matroska':
      fileIcon.icon = 'mkv'
      break
    case 'video/quicktime':
      fileIcon.icon = 'mov'
      break
    case 'audio/mp3':
    case 'audio/mpeg':
    case 'audio/mpeg3':
    case 'audio/x-mpeg-3':
      fileIcon.icon = 'mp3'
      break
    case 'application/mp4':
    case 'video/mp4':
      fileIcon.icon = 'mp4'
      break
    case 'application/pdf':
    case 'application/x-pdf':
    case 'application/epub+zip':
      fileIcon.icon = 'pdf'
      break
    case 'image/png':
      fileIcon.icon = 'png'
      break
    case 'image/gif':
      fileIcon.icon = 'gif'
      break
    case 'application/vnd.ms-powerpoint': // .ppt, .pot, .pps, .ppa
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation': // .pptx
    case 'application/vnd.openxmlformats-officedocument.presentationml.template': // .potx
    case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow': // .ppsx
    case 'application/vnd.ms-powerpoint.addin.macroEnabled.12': // .ppam
    case 'application/vnd.ms-powerpoint.presentation.macroEnabled.12': // .pptm
    case 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12': // .ppsm
      fileIcon.icon = 'ppt'
      break
    case 'application/vnd.ms-excel': // .xls, .xlt, .xla
    case 'application/msexcel':
    case 'application/x-msexcel':
    case 'application/x-ms-excel':
    case 'application/x-excel':
    case 'application/x-dos_ms_excel':
    case 'application/xls':
    case 'application/x-xls':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': // .xlsx
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template': // .xltx
    case 'application/vnd.ms-excel.sheet.macroEnabled.12': // .xlsm
    case 'application/vnd.ms-excel.template.macroEnabled.12': // .xltm
    case 'application/vnd.ms-excel.addin.macroEnabled.12': // .xlam
    case 'application/vnd.ms-excel.sheet.binary.macroEnabled.12': // .xlsb
      fileIcon.icon = 'xls'
      break
    case 'application/zip':
    case 'application/x-zip':
    case 'application/x-zip-compressed':
      fileIcon.icon = 'zip'
      break
    case 'application/octet-stream':
      switch (fileExtension) {
        case 'doc':
        case 'docx':
        case 'gdoc': // google document
          fileIcon.icon = 'doc'
          break
        case 'ppt':
        case 'pptx':
          fileIcon.icon = 'ppt'
          break
        case 'xls':
        case 'xlsx':
        case 'gsheet': // google spreadsheet
          fileIcon.icon = 'xls'
          break
        case 'zip':
        case 'gz':
          fileIcon.icon = 'zip'
          break
        case 'jpg':
        case 'jpeg':
          fileIcon.icon = 'jpg'
          break
        case 'avi':
        case 'gif':
        case 'm4v':
        case 'mkv':
        case 'mov':
        case 'mp4':
        case 'pdf':
          fileIcon.icon = fileExtension
          break
        // case 'action':
        // case 'db':
        // case 'dmg':    // disk image
        // case 'lnk':    // windows alias/shortcut
        // case 'key':    // keynote
        // case 'log':
        // case 'numbers':
        // case 'pages':
        // case 'webloc':    // url file
        default:
        // fileIcon.icon = 'generic'
      }
      break
    case 'text/plain':
    case 'text/html':
      switch (fileExtension) {
        // case 'txt':
        // case 'html':
        // case 'pages':
        default:
        // fileIcon.icon = 'generic'
      }
      break
    // case '':
    // case 'application/x-iwork-pages-sffpages':    // .pages
    // case 'application/x-iwork-keynote-sffnumbers':    // .numbers
    // case 'application/x-iwork-keynote-sffkey':    // .key
    default:
      if (isFolder) {
        fileIcon.icon = 'folder-solid'
        fileIcon.color = Colors.HAVELOCK_BLUE
      } else {
        switch (fileExtension) {
          case 'doc':
          case 'docx':
          case 'gdoc': // google document
            fileIcon.icon = 'doc'
            break
          case 'ppt':
          case 'pptx':
            fileIcon.icon = 'ppt'
            break
          case 'xls':
          case 'xlsx':
          case 'gsheet': // google spreadsheet
            fileIcon.icon = 'xls'
            break
          case 'zip':
          case 'gz':
            fileIcon.icon = 'zip'
            break
          case 'jpg':
          case 'jpeg':
            fileIcon.icon = 'jpg'
            break
          case 'avi':
          case 'gif':
          case 'm4v':
          case 'mkv':
          case 'mov':
          case 'mp4':
            fileIcon.icon = fileExtension
            break
          // case 'js':
          default:
          // fileIcon.icon = 'generic'
        }
      }
  }

  // set icon color based on color name
  if (fileIcon.icon !== 'generic' && fileIcon.color === FILE_COLORS.GENERIC) {
    const key = fileIcon.icon.toUpperCase()
    fileIcon.color =
      FILE_COLORS[key as keyof typeof FILE_COLORS] || FILE_COLORS.GENERIC
  }

  // add file- prefix to icon name
  if (!fileIcon.icon.includes('-')) {
    fileIcon.icon = `file-${fileIcon.icon}`
  }

  return fileIcon
}

const convertToPascal = (string: string) =>
  string
    .replace(/\w+/g, (w) => w[0].toUpperCase() + w.slice(1).toLowerCase())
    .split('-')
    .join('')

export const FileTypeIcon: React.FC<FileTypeIconProps> = ({
  className,
  contentType,
  fileName,
  isFolder,
  nameCase = 'kebab',
  size = 24,
  style,
}: FileTypeIconProps) => {
  const fileIcon = getFileIcon(fileName, contentType, isFolder)

  const getIconName = useCallback(
    (name: string) => {
      if (nameCase === 'pascal') {
        return convertToPascal(name)
      }
      return name
    },
    [nameCase]
  )
  return (
    <Icon
      className={className}
      fill={fileIcon.color}
      name={getIconName(fileIcon.icon) as IconNames}
      style={style}
      height={size}
      width={size}
    />
  )
}

export default FileTypeIcon
