import * as React from 'react'
import type { SVGProps } from 'react'
const FilePdf = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 537.7 661.8'
    width='1em'
    height='1em'
    style={props.style}
    {...props}
  >
    <path
      fill='none'
      d='M200.9 396.2c1-17.4-1-26.5-3.4-30-1-1.4-2.4-4.4-6.7-4.4-2.6 0-4.1 2-5.1 5.7-.6 2.2-.8 11.5 10.9 24.5 0-.1 1.5 1.5 4.3 4.2z'
    />
    <path
      fill='none'
      d='M20.7 641.1h455V268.8H227.5a20.74 20.74 0 0 1-20.7-20.7V103.4a20.74 20.74 0 0 1 20.7-20.7h248.2v-62h-455v620.4zm131.5-124c7.9-4.3 16.8-8.5 23.7-11.5 5.8-22.7 15.8-62 21.1-85.5-7.7-6.2-34.9-30.1-31.1-54.5 5.2-33 42.2-31.9 51.9-1.1 4 12.7 2.3 36.4 1 49.1 16.6 15.7 43.2 38.7 74.9 63 10.9-1 37.3-1.8 57.2 1.5 24.2 4 31.5 22.3 31.5 34.1l-.2.8a4.88 4.88 0 0 0-.3.7c-1 8.7-5.3 16.1-11.7 19.5-17.3 9-33.3 3.3-55.4-14.9-8.7-7.2-18.2-16.4-25.1-22.4-29.7 1.8-66 7.3-98 22.1-1.3 8.1-7.5 26.5-16.5 40.5-15 23.3-50.1 31.9-50.9-2.8-.3-11.9 12.2-30 27.9-38.6zM76.7 282.2a1.38 1.38 0 0 1 1.2 1.3 1.4 1.4 0 0 1-1.2 1.4zm0 59.2a1.38 1.38 0 0 1 1.2 1.3 1.4 1.4 0 0 1-1.2 1.4z'
    />
    <path
      fill='none'
      d='M229.4 485.3c13.3-3.2 27.5-4.7 37.6-5.9l-52.7-45.1-14.6 60.5a238.56 238.56 0 0 1 29.7-9.5zM352.6 517c3.6 0 7-.4 9-8a7.27 7.27 0 0 0-2-6.4c-4.3-5-13.5-6.6-16.7-6.7-.5 0-10.1.1-23.6.1-.1 0-.5-.2-.6-.2 11.3 9.6 12.4 11.7 23 18.3 1 .5 6.7 2.9 10.9 2.9zm-207.5 41.4c4.1-.1 14.2-2.6 25-30.9-11 6-16.8 10.1-23.1 19.9-1.9 3.7-3.1 8.7-2.1 10.7.1.1.2.2.2.3z'
    />
    <path
      fill='#e6175d'
      d='M517 82.7h-20.7v-62A20.74 20.74 0 0 0 475.6 0h-455A20.72 20.72 0 0 0 0 20.7v620.4a20.74 20.74 0 0 0 20.7 20.7h455a20.74 20.74 0 0 0 20.7-20.7V268.8H517a20.74 20.74 0 0 0 20.7-20.7V103.4A20.74 20.74 0 0 0 517 82.7zm-41.4 0H227.5a20.74 20.74 0 0 0-20.7 20.7v144.8a20.74 20.74 0 0 0 20.7 20.7h248.2v372.2h-455V20.7h455v62zm-1 84.3v12.8h-36.3V212h-16v-74.9h53v12.8h-37.1V167zm-63.6 7.6a37.44 37.44 0 0 1-5.1 19.6 34.65 34.65 0 0 1-14 13.1 44.48 44.48 0 0 1-20.7 4.7h-29.5v-74.9h29.5a44.48 44.48 0 0 1 20.7 4.7 33.66 33.66 0 0 1 14 13.2 37.18 37.18 0 0 1 5.1 19.6zm-79.2-13.4a25 25 0 0 1-3 12.1 22.21 22.21 0 0 1-8.6 8.6 27.5 27.5 0 0 1-13.5 3.2h-19.1V212h-16v-74.9h35a26.86 26.86 0 0 1 13.6 3.3 21.79 21.79 0 0 1 8.6 8.7 23.14 23.14 0 0 1 3 12.1z'
    />
    <path
      fill='#e6175d'
      d='M175.2 558.4c9-13.9 15.3-32.4 16.5-40.5 32-14.8 68.3-20.3 98-22.1 6.9 6 16.3 15.2 25.1 22.4 22.2 18.1 38.2 23.9 55.4 14.9 6.4-3.4 10.7-10.8 11.7-19.5a4.88 4.88 0 0 0 .3-.7l.2-.8c0-11.7-7.3-30.1-31.5-34.1-19.8-3.2-46.3-2.4-57.2-1.5a957.6 957.6 0 0 1-74.9-63c1.3-12.7 3-36.4-1-49.1-9.7-30.8-46.8-31.9-51.9 1.1-3.8 24.4 23.4 48.2 31.1 54.5-5.3 23.6-15.3 62.8-21.1 85.5-6.9 3-15.8 7.2-23.7 11.5-15.7 8.6-28.2 26.7-28 38.6.9 34.7 35.9 26.1 51 2.8zm144.1-62.3c13.6 0 23.2-.2 23.6-.1 3.1.1 12.4 1.7 16.7 6.7a7.27 7.27 0 0 1 2 6.4c-2.1 7.6-5.4 8-9 8-4.2 0-9.9-2.4-10.9-2.8-10.6-6.6-11.7-8.7-23-18.3.1-.1.5.1.6.1zm-104.9-61.7l52.7 45.1c-10.1 1.2-24.4 2.7-37.6 5.9a239 239 0 0 0-29.7 9.6zm-17.8-42.5c-11.7-13-11.5-22.3-10.9-24.5 1-3.6 2.5-5.7 5.1-5.7 4.3 0 5.7 3 6.7 4.4 2.4 3.5 4.4 12.6 3.4 30-2.8-2.6-4.3-4.2-4.3-4.2zM147 547.4c6.4-9.8 12.1-13.9 23.1-19.9-10.8 28.3-20.9 30.8-25 30.9-.1-.1-.1-.2-.2-.3-1-2 .2-7 2.1-10.7zm157.5-397.5h-17v22.5h17a10.47 10.47 0 0 0 7.9-3.1 12.13 12.13 0 0 0 0-16.2 10.33 10.33 0 0 0-7.9-3.2zm66.8 0h-13.6v49.3h13.6a23.08 23.08 0 0 0 12.5-3.3 21.06 21.06 0 0 0 8.1-8.9 26.91 26.91 0 0 0 2.8-12.4c0-7.3-2.1-13.2-6.2-17.8s-9.8-6.9-17.2-6.9z'
    />
  </svg>
)
export default FilePdf
