import { useCallback } from 'react'
import styled from 'styled-components'

import { VideoPlaylistSummary } from '@sportsyou/api'
import { Colors, pluralize, secondsToTime } from '@sportsyou/core'

import Icon from '../icon'

export interface VideoPlaylistCardProps {
  onClickVideoPlaylistCard?: (playlist: VideoPlaylistSummary) => void
  playlist: VideoPlaylistSummary
  testId?: string
}

export const VideoPlaylistCard: React.FC<VideoPlaylistCardProps> = (
  props: VideoPlaylistCardProps
) => {
  const clipCount = props.playlist.clipIds?.length ?? 0

  const onClick = useCallback(() => {
    props.onClickVideoPlaylistCard?.(props.playlist)
  }, [props])

  return (
    <Container data-testid={props.testId} onClick={onClick}>
      <Body>
        <PlayIcon name='Play' fill={Colors.WHITE} height={40} width={40} />
        <img
          src={props.playlist.thumbnailSrc ?? ''}
          alt={props.playlist.title ?? 'Playlist'}
        />
        <ClipCount>
          {clipCount} {pluralize(clipCount, 'Clip', 'Clips')}
        </ClipCount>
      </Body>
      <Footer>
        <FooterTitle>{props.playlist.title}</FooterTitle>
        <FooterSubtitle>
          {secondsToTime(props.playlist.duration ?? 0)}
        </FooterSubtitle>
      </Footer>
    </Container>
  )
}

const Container = styled.div`
  background-color: ${Colors.SHUTTLE_GRAY};
  border-radius: 8px;
  border: 1px solid ${Colors.SHUTTLE_GRAY};
  color: ${Colors.WHITE};
  cursor: pointer;
  margin: 12px;
  overflow: hidden;
`
const Body = styled.div`
  position: relative;

  img {
    align-items: center;
    background-color: #000;
    display: flex;
    height: auto;
    justify-content: center;
    max-height: 300px;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
`
const PlayIcon = styled(Icon)`
  bottom: 48%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 50%);
`
const ClipCount = styled.div`
  background-color: #000;
  border-radius: 8px;
  bottom: 10px;
  color: #fff;
  font-weight: 500;
  left: 10px;
  padding: 4px 8px;
  position: absolute;
`
const Footer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`
const FooterTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
`
const FooterSubtitle = styled.div`
  font-size: 14px;
`

export default VideoPlaylistCard
