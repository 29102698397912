import { useEffect, useState } from 'react'

export interface Dimensions {
  height?: number
  width?: number
}

const getWindowDimensions = (): Dimensions => {
  const { innerHeight: height, innerWidth: width } = window
  return {
    height,
    width,
  }
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

export default useWindowDimensions
