import React from 'react'
import styled from 'styled-components'

interface Props {
  style?: React.CSSProperties
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type TabsExtraContentProps = Props & NativeAttributes

const TabsExtraContent: React.FC<
  React.PropsWithChildren<TabsExtraContentProps>
> = ({ children, style }: TabsExtraContentProps) => {
  return <Container style={style}>{children}</Container>
}

const Container = styled.div`
  margin-left: auto;
`

export default TabsExtraContent
