import React from 'react'
import styled from 'styled-components'

import Button, { ButtonProps } from '../button'

interface Props {
  className?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  style?: React.CSSProperties
}

type NativeAttributes = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  keyof Props | keyof ButtonProps
>
export type WidgetButtonProps = ButtonProps & Props & NativeAttributes

export const WidgetButton: React.FC<
  React.PropsWithChildren<WidgetButtonProps>
> = ({ className, children, onClick, style, ...props }: WidgetButtonProps) => {
  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(event)
  }
  return (
    <StyledButton
      className={className}
      onClick={handleOnClick}
      style={style}
      {...props}
    >
      {children}
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  margin-bottom: 0;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 10px;
`

export default WidgetButton
