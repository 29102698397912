export const getYouTubeVideoId = (url: string): string => {
  const regex =
    /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/

  const matches = url.match(regex)

  return matches && matches[1].length == 11 ? matches[1] : ''
}

export default getYouTubeVideoId
