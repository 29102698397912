import * as React from 'react'
import type { SVGProps } from 'react'
const DownloadLine = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path
      fillRule='evenodd'
      d='M15.9999 0c.9142 0 1.6552.741046 1.6552 1.65517V19.1765l9.1285-9.1285c.6464-.64638 1.6944-.64638 2.3407 0 .6464.6464.6464 1.6944 0 2.3408L15.9999 25.5132 2.87554 12.3888c-.64639-.6464-.64639-1.6944 0-2.3408.64638-.64638 1.69438-.64638 2.34076 0l9.1285 9.1285V1.65517C14.3448.741046 15.0858 0 15.9999 0ZM2.39075 30.3448c0-.9141.74104-1.6551 1.65517-1.6551H27.954c.9141 0 1.6551.741 1.6551 1.6551 0 .9142-.741 1.6552-1.6551 1.6552H4.04592c-.91413 0-1.65517-.741-1.65517-1.6552Z'
      clipRule='evenodd'
    />
  </svg>
)
export default DownloadLine
