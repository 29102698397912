/**
 * Post Helmet
 *
 * The PostHelmet component handles the top most module of the post. It handles the
 * team/group/multi post indicator, viewed by stats, and multi-post toggle button.
 */
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import {
  Post,
  Team,
  TeamMember,
  TeamMember as TeamMemberProps,
} from '@sportsyou/api'
import { capitalize, Colors } from '@sportsyou/core'

import type { PostProps } from '../post-types'
import Icon from '../../icon'
type ProfileType = 'group' | 'profile' | 'team'

export interface PostHelmetProps extends Post {
  hideToggleButton?: boolean
  isMinimized?: boolean
  isMultiPost?: boolean
  postsIndex?: number
  posts?: Array<PostProps>
  // postType?: 'group' | 'multi' | 'team'
  renderRightContent?: React.ReactNode | JSX.Element
  scheduleId?: string
  scheduledTime?: string
  targetName?: string
  // teamName?: string
  totalMembers?: any
  onClickEditScheduledPost?: (id: string, time: string) => void
  onClickHelmetProfile?: PostProps['onClickHelmetProfile']
  onClickViewedBy?: (viewed: Array<TeamMember>, postsIndex?: number) => void
  onToggleMultiPost?: () => void
}

export const PostHelmet: React.FC<PostHelmetProps> = ({
  createdBy,
  hideToggleButton,
  isMinimized,
  isMultiPost,
  isPinned,
  onClickEditScheduledPost,
  onClickHelmetProfile,

  onClickViewedBy,
  onToggleMultiPost,
  posts,
  postsIndex,
  // postType,
  targetName,
  scheduleId,
  scheduledTime,
  team,
  // teamName,
  viewed,
  viewCount,
  renderRightContent,
}: PostHelmetProps) => {
  const [isScheduledPost] = useState(typeof scheduleId !== 'undefined')
  const [totalMembers, setTotalMembers] = useState(
    team?.uniqueMembersCount ?? 0
  )
  const [membersViewedCount, setMembersViewedCount] = useState(
    viewCount ?? viewed?.length
  )
  const [showViewedBy, setShowViewedBy] = useState(
    totalMembers > 0 &&
      typeof membersViewedCount === 'number' &&
      !team?.isLargeTeam
  )
  const [showViewByCountOnly, setShowViewByCountOnly] = useState(
    totalMembers > 0 &&
      typeof membersViewedCount === 'number' &&
      team?.isLargeTeam
  )

  useEffect(() => {
    setTotalMembers(team?.uniqueMembersCount ?? 0)
  }, [team?.uniqueMembersCount])

  useEffect(() => {
    setMembersViewedCount(viewCount ?? viewed?.length)
  }, [viewCount, viewed])

  useEffect(() => {
    setShowViewedBy(
      totalMembers > 0 &&
        typeof membersViewedCount === 'number' &&
        !team?.isLargeTeam
    )
    setShowViewByCountOnly(
      totalMembers > 0 &&
        typeof membersViewedCount === 'number' &&
        team?.isLargeTeam
    )
  }, [membersViewedCount, totalMembers])

  const getPostType = useMemo(() => {
    const postType = isMultiPost ? 'multi' : (team?.type as 'group' | 'team')
    return capitalize(postType)
  }, [isMultiPost, team?.type])

  const handleOnClickTarget = (
    e: React.MouseEvent<HTMLAnchorElement>,
    posts?: PostProps[]
  ) => {
    let id: string
    if (postsIndex && posts && posts.length > 0) {
      id = posts[postsIndex].team?.id || posts[postsIndex].createdBy?.id || ''
    } else {
      id = team?.id || createdBy?.id || ''
    }

    const profileType: ProfileType = (
      team && team.type ? team.type : 'profile'
    ) as ProfileType

    e.preventDefault()
    e.stopPropagation()

    const teams = posts?.map((post) => post.team as Team)

    if (id.length !== 0) onClickHelmetProfile?.({ id, profileType, teams })
  }

  const handleOnClickEditScheduledPost = () => {
    if (scheduleId && scheduledTime) {
      onClickEditScheduledPost &&
        onClickEditScheduledPost(scheduleId, scheduledTime)
    }
  }

  const handleOnClickViewedBy = () => {
    onClickViewedBy?.(viewed as TeamMemberProps[], postsIndex)
  }

  // TODO Scheduled post info
  return (
    <Container isMinimized={isMinimized}>
      {isScheduledPost ? (
        <>
          <ScheduledPostTarget>
            {posts && posts.length > 1 ? (
              <>
                Multi Post:{' '}
                <PostTargetLink
                  aria-label={`View ${posts.length} Teams/Groups`}
                  onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                    handleOnClickTarget(e, posts)
                  }
                  title={`View ${posts.length} Teams/Groups`}
                >
                  {posts.length} Teams/Groups
                </PostTargetLink>
              </>
            ) : (
              <>
                {getPostType} Post:{' '}
                <PostTargetLink
                  aria-label={`Profile for ${targetName}`}
                  onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                    handleOnClickTarget(e)
                  }
                  title={`Profile for ${targetName}`}
                >
                  {targetName}
                </PostTargetLink>
              </>
            )}
          </ScheduledPostTarget>
          <TextButton
            aria-label='Edit Scheduled Post'
            onClick={handleOnClickEditScheduledPost}
            title='Edit Scheduled Post'
          >
            Edit
          </TextButton>
        </>
      ) : (
        <>
          <PostTargetContainer>
            {isPinned && (
              <Icon
                name='Pin'
                style={{ marginRight: 4, verticalAlign: 'middle' }}
              />
            )}
            {getPostType} Post:{' '}
            <PostTargetLink
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                handleOnClickTarget(e)
              }
              title={`Profile for ${targetName}`}
            >
              {targetName}
            </PostTargetLink>
          </PostTargetContainer>

          {showViewByCountOnly && (
            <ViewedByTextOnly>
              Viewed By: {membersViewedCount}/{totalMembers}
            </ViewedByTextOnly>
          )}
          {showViewedBy && (
            <ViewedBy onClick={handleOnClickViewedBy}>
              Viewed By: {membersViewedCount}/{totalMembers}
            </ViewedBy>
          )}

          {renderRightContent && <div>{renderRightContent}</div>}
          {isMultiPost && !hideToggleButton && (
            <PostToggleButton type='button' onClick={onToggleMultiPost}>
              {isMinimized ? '+ Show Full Post' : '- Hide Full Post'}
            </PostToggleButton>
          )}
        </>
      )}
    </Container>
  )
}

const Container = styled.div<Pick<PostHelmetProps, 'isMinimized'>>`
  align-items: center;
  // border-bottom: 1px solid transparent;
  border-bottom: ${({ isMinimized }) =>
    isMinimized ? 'none' : `1px solid ${Colors.ALTO}`};
  box-sizing: border-box;
  display: flex;
  font-size: 13px;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
  height: 38px;
  height: ${({ isMinimized }) => (isMinimized ? '36px' : '37px')};
`
const PostTargetLink = styled.a`
  cursor: pointer;
  font-weight: 700;
  &:hover,
  &:active {
    text-decoration: underline;
  }
`
const PostToggleButton = styled.button`
  align-items: center;
  background: none;
  border: none;
  border-radius: 4px;
  color: ${Colors.HAVELOCK_BLUE};
  cursor: pointer;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 4px 8px;

  &:hover {
    background-color: rgb(0 0 0 / 10%);
    text-decoration: underline;
  }
`
const TextButton = styled.button`
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 4px;
  color: ${Colors.HAVELOCK_BLUE};
  cursor: pointer;
  font-size: 12px;
  height: 30px;
  // padding: 0 6px;
  // padding: 0;
  transition: background-color 160ms ease-in-out;

  &:hover,
  &:active {
    background-color: ${Colors.MYSTIC};
  }
`
const ScheduledPostTarget = styled.div``

const PostTargetContainer = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  margin-right: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const ViewedBy = styled.span`
  color: ${Colors.HAVELOCK_BLUE};
  cursor: pointer;
  flex: 0 1 auto;
  white-space: nowrap;
  &:hover,
  &:active {
    text-decoration: underline;
  }
`
const ViewedByTextOnly = styled.span`
  flex: 0 1 auto;
  white-space: nowrap;
`

export default PostHelmet
