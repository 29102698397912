import * as React from 'react'
import type { SVGProps } from 'react'
const FabCreateAlbum = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M24.16 12.37v-.5a2.76 2.76 0 0 0-1.79-2.58v-.53A2.77 2.77 0 0 0 19.6 6h-7.2a2.77 2.77 0 0 0-2.77 2.76v.53a2.75 2.75 0 0 0-1.79 2.58v.5A2.77 2.77 0 0 0 6 15v8.26A2.78 2.78 0 0 0 8.78 26h14.44A2.78 2.78 0 0 0 26 23.23V15a2.77 2.77 0 0 0-1.84-2.63zM12.4 7.4h7.2A1.36 1.36 0 0 1 21 8.76v.34H11v-.34a1.36 1.36 0 0 1 1.4-1.36zm-1.79 3.1h10.78a1.37 1.37 0 0 1 1.37 1.37v.33H9.24v-.33a1.37 1.37 0 0 1 1.37-1.37zM7.4 23.23V15a1.37 1.37 0 0 1 1.38-1.4h14.44A1.37 1.37 0 0 1 24.6 15v5l-2.91-2.33a1.74 1.74 0 0 0-2.43.27l-2.66 3.3a.33.33 0 0 1-.22.13.35.35 0 0 1-.25-.07l-1.34-1.08a1.74 1.74 0 0 0-2.41.25L8.93 24.6h-.15a1.37 1.37 0 0 1-1.38-1.37zm15.82 1.37H10.74l2.71-3.24a.34.34 0 0 1 .47-.05l1.34 1.08a1.71 1.71 0 0 0 1.27.37 1.76 1.76 0 0 0 1.16-.64l2.66-3.33a.33.33 0 0 1 .47-.05l3.5 2.8a.71.71 0 0 0 .28.13v1.56a1.37 1.37 0 0 1-1.38 1.37z' />
    <path d='M10.63 18.43a1.75 1.75 0 1 0-1.74-1.74 1.74 1.74 0 0 0 1.74 1.74z' />
  </svg>
)
export default FabCreateAlbum
