// TODO: Create Text Input Component
// TODO: Create useDialog hook
import React, { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'
import { usePortal } from '@sportsyou/react-hooks'
import Button, { ButtonProps } from '../button'
import Backdrop from '../backdrop'
import CloseButton from '../close-button'
import Transition from '../css-transition'

interface PromptButtonProps extends ButtonProps {
  label?: string
}

// TODO: Extend InputProps
type PromptInputProps = {
  label?: string
  onChange?: (value: string) => void
  placeholder?: string
  value: string
}

interface PromptFuntions {
  onCancel?: () => void
  onClickBackdrop?: () => void
  onClickContent?: () => void
  onClose?: () => void
  onSubmit?: (response: Array<string>) => void
}

interface Props extends PromptFuntions {
  backdropIgnoresClicks?: boolean
  cancelButton?: PromptButtonProps
  className?: string
  clickBackdropToClose?: boolean
  isVisible?: boolean
  required?: boolean
  inputs?: Array<PromptInputProps>
  submitButton?: PromptButtonProps
  text: React.ReactNode | string
  header?: string
  testId?: string
}

type NativeAttributes = Omit<React.HTMLAttributes<HTMLDivElement>, keyof Props>
export type PromptProps = Props & NativeAttributes

export const Prompt: React.FC<PromptProps> = ({
  cancelButton,
  className,
  header,
  backdropIgnoresClicks,
  clickBackdropToClose = true,
  inputs,
  isVisible,
  onCancel,
  onClose,
  onClickBackdrop,
  onClickContent,
  onSubmit,
  required,
  submitButton,
  testId,
  text,
}: PromptProps) => {
  const portal = usePortal('dialog')

  const [userResponse, setUserResponse] = useState<Array<string>>(
    inputs?.map((input) => input.value) ?? new Array(inputs?.length).fill('')
  )

  const canSumbit = useMemo(
    () => !!required && userResponse.every((val) => val !== ''),
    [required, userResponse]
  )

  const handleOnChangeInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = event.target
    setUserResponse((prevState) =>
      prevState.map((prev, i) => (index === i ? value : prev))
    )
  }

  const handleOnClose = () => {
    onClose?.()
  }

  const handleOnClickBackdrop = () => {
    if (backdropIgnoresClicks) return

    onClickBackdrop?.()
    clickBackdropToClose && handleOnClose()
  }

  const handleOnClickCancel = () => {
    onCancel?.()
    handleOnClose()
  }
  const handleOnClickSubmit = () => {
    onSubmit?.(userResponse)
    handleOnClose()
  }

  if (!portal) return null

  return createPortal(
    <Backdrop
      onClick={handleOnClickBackdrop}
      onClickContent={onClickContent}
      visible={isVisible}
    >
      <Transition clearTime={300} name='prompt' visible={isVisible}>
        <Container className={className} data-testid={testId} role='dialog'>
          {header && (
            <Header>
              {header}

              <CloseButton
                appearance='circular'
                hideBackground
                onClick={handleOnClose}
                size={24}
              />
            </Header>
          )}
          <Body>
            <Text>{text}</Text>

            {inputs?.map((input: PromptInputProps, index) => (
              <div key={index}>
                <Input
                  key={index}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleOnChangeInput(event, index)
                  }
                  placeholder={input.placeholder}
                />
              </div>
            ))}

            {/* TODO: Add custom TextInput component */}
            {/* <Input
          onChange={(e) => handleOnChangeInput(e)}
          type='text'
          value={userResponse}
        /> */}
          </Body>
          <Footer>
            <Button
              appearance={cancelButton?.appearance ?? 'minimal'}
              className={cancelButton?.className}
              onClick={handleOnClickCancel}
              style={cancelButton?.style}
              variant={cancelButton?.variant ?? 'alternate'}
            >
              {cancelButton?.label ?? 'Cancel'}
            </Button>
            <Button
              appearance={submitButton?.appearance ?? 'solid'}
              className={submitButton?.className}
              disabled={required ? !canSumbit : undefined}
              onClick={handleOnClickSubmit}
              style={{
                marginLeft: 10,
                ...submitButton?.style,
              }}
              variant={submitButton?.variant ?? 'primary'}
            >
              {submitButton?.label ?? 'OK'}
            </Button>
          </Footer>
        </Container>
      </Transition>
    </Backdrop>,
    portal
  )
}

const Container = styled.div`
  background-color: ${Colors.WHITE};
  border: 1px solid ${Colors.ALTO};
  border-radius: 4px;
  box-shadow: 0 4px 20px rgb(0 0 0 / 14%);
  box-sizing: border-box;
  color: ${Colors.MINE_SHAFT};
  max-width: 90vw;
  opacity: 0;
  position: relative;
  transform: translate3d(0, -30px, 0);
  transition: opacity 0.35s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    transform 0.35s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  width: 420px;
  will-change: opacity, transform;

  &.prompt-enter {
    opacity: 0;
    transform: translate3d(0, -30px, 0);
  }
  &.prompt-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.prompt-leave {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.prompt-leave-active {
    opacity: 0;
    transform: translate3d(0, -30px, 0);
  }
`
const Header = styled.header`
  align-items: center;
  border-bottom: 1px solid ${Colors.ALTO};
  display: flex;
  font-size: 14px;
  font-weight: 700;
  justify-content: space-between;
  padding: 10px 15px;
`
const Body = styled.div`
  padding: 10px 15px;
`
const Text = styled.p`
  margin-bottom: 0;
  margin-top: 0;
  line-height: 1.4;
  & + & {
    margin-top: 10px;
  }
`
const Input = styled.input`
  box-sizing: border-box;
  font-family: inherit;
  padding: 8px 10px;
  min-height: 44px;
`
const Footer = styled.footer`
  align-items: center;
  // border-top: 1px solid ${Colors.ALTO};
  display: flex;
  justify-content: flex-end;
  padding: 10px 15px;
`

export default Prompt
