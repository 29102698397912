import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'

import {
  Campaign,
  mutationCampaignCreate,
  mutationCampaignDelete,
  mutationCampaignUpdate,
  mutationUploadDelete,
  mutationUploadUpdateAsset,
  queryCampaign,
  queryCampaigns,
  querySponsor,
  querySponsorAssets,
  querySponsors,
  Sponsor,
  Upload,
} from '@sportsyou/api'
import { useFetchApi } from '@sportsyou/react-hooks'

const defaultCampaign: Campaign = {
  endDate: moment().add(1, 'week').endOf('day').toISOString(),
  startDate: moment().startOf('day').toISOString(),
  weight: 50,
}

export default function UseCampaignPortal() {
  const { fetch: fetchCampaign } = useFetchApi(queryCampaign)
  const { fetch: fetchCampaignCreate } = useFetchApi(mutationCampaignCreate)
  const { fetch: fetchCampaignDelete } = useFetchApi(mutationCampaignDelete)
  const { fetch: fetchCampaigns } = useFetchApi(queryCampaigns)
  const { fetch: fetchCampaignUpdate } = useFetchApi(mutationCampaignUpdate)
  const { fetch: fetchSponsor } = useFetchApi(querySponsor)
  const { fetch: fetchSponsorAssets } = useFetchApi(querySponsorAssets)
  const { fetch: fetchSponsors } = useFetchApi(querySponsors)
  const { fetch: fetchUploadUpdateAsset } = useFetchApi(
    mutationUploadUpdateAsset
  )
  const { fetch: fetchUploadDelete } = useFetchApi(mutationUploadDelete)

  const [currentCampaign, setCurrentCampaign] =
    useState<Campaign>(defaultCampaign)
  const [currentCampaignSponsor, setCurrentCampaignSponsor] = useState<
    Sponsor | undefined
  >()
  const [campaigns, setCampaigns] = useState<Campaign[]>([])
  const [currentAssets, setCurrentAssets] = useState<Upload[]>([])
  const [isAssetsLoading, setIsAssetsLoading] = useState(false)
  const [isCampaignsLoading, setIsCampaignsLoading] = useState(false)
  const [isSponsorsLoading, setIsSponsorsLoading] = useState(false)
  const [sponsors, setSponsors] = useState<Sponsor[]>([])

  const isLoading = useMemo(() => {
    return isAssetsLoading || isCampaignsLoading || isSponsorsLoading
  }, [isAssetsLoading, isCampaignsLoading, isSponsorsLoading])

  const currentCampaignOriginal = useRef<Campaign>()

  const loadSponsors = useCallback(async () => {
    setIsSponsorsLoading(true)
    const { data } = await fetchSponsors()
    setSponsors(data as Sponsor[])
    setIsSponsorsLoading(false)
  }, [fetchSponsors])

  const loadSponsorAssets = useCallback(
    async (sponsorId: string) => {
      setIsAssetsLoading(true)
      const { data } = await fetchSponsorAssets({ sponsorId })
      setCurrentAssets(data as Upload[])
      setIsAssetsLoading(false)
    },
    [fetchSponsorAssets]
  )

  const renameSponsorAsset = useCallback(
    async (uploadId: string, assetName: string) => {
      const { data } = await fetchUploadUpdateAsset({
        uploadId,
        assetName,
      })
      return data
    },
    [fetchUploadUpdateAsset]
  )

  const loadAllCampaigns = useCallback(async () => {
    setIsCampaignsLoading(true)
    const allCampaigns = await Promise.all(
      sponsors.map(async (sponsor) => {
        const { data } = await fetchCampaigns({ sponsorId: sponsor.id })
        return data as Campaign
      })
    ).then((campaigns) => campaigns.flat())
    setCampaigns(allCampaigns)
    setIsCampaignsLoading(false)
  }, [fetchCampaigns, sponsors])

  const loadCampaign = useCallback(
    async (campaignId: string) => {
      setIsCampaignsLoading(true)
      const { data } = await fetchCampaign({ id: campaignId })
      setCurrentCampaign(data as Campaign)
      setIsCampaignsLoading(false)
    },
    [fetchCampaign]
  )

  const setCurrentCampaignProp = useCallback((key: string, value: unknown) => {
    setCurrentCampaign((campaign) => {
      return {
        ...campaign,
        [key]: value,
      }
    })
  }, [])

  const setCurrentCampaignScreenLocationProp = useCallback(
    (locationName: string, key: string, value: unknown) => {
      if (key === 'enabled') {
        if (value) {
          // Create a new screen location if it is enabled
          setCurrentCampaign((campaign) => {
            return {
              ...campaign,
              screenLocations: [
                ...(campaign?.screenLocations ?? []),
                {
                  locationName,
                },
              ],
            }
          })
        } else {
          // Remove the screen location if it is disabled
          setCurrentCampaign((campaign) => {
            return {
              ...campaign,
              screenLocations:
                campaign?.screenLocations?.filter(
                  (screenLocation) =>
                    screenLocation?.locationName !== locationName
                ) ?? [],
            }
          })
        }
        return
      }

      // update a screen location property
      setCurrentCampaign((campaign) => {
        return {
          ...campaign,
          screenLocations: campaign.screenLocations?.map((screenLocation) => {
            if (screenLocation?.locationName === locationName) {
              return {
                ...screenLocation,
                [key]: value,
              }
            }
            return screenLocation
          }) ?? [
            {
              locationName,
              [key]: value,
            },
          ],
        }
      })
    },
    []
  )

  const findSponsorById = useCallback(
    (sponsorId?: string) => {
      return sponsors.find((sponsor) => sponsor.id === sponsorId)
    },
    [sponsors]
  )

  const currentCampaignHasChanges = useMemo(() => {
    return (
      JSON.stringify(currentCampaignOriginal.current) !==
      JSON.stringify(currentCampaign)
    )
  }, [currentCampaign])

  const saveCurrentCampaign = useCallback(async () => {
    if (!currentCampaign) {
      return
    }

    // prepare campaign object for saving
    const campaign = { ...currentCampaign }
    campaign.weight = Number(campaign.weight ?? 50)
    campaign.startDate = moment(campaign.startDate).toISOString()
    campaign.endDate = moment(campaign.endDate).toISOString()
    campaign.screenLocations = campaign.screenLocations?.map(
      (screenLocation) => {
        return {
          ...screenLocation,
          assets: screenLocation?.assets?.map((asset) => {
            return {
              ...asset,
              // omit sending the viewUrl
              viewUrl: undefined,
            }
          }),
        }
      }
    )

    console.log('currentCampaign', campaign)
    if (campaign.id) {
      return fetchCampaignUpdate({ campaign: campaign })
    } else {
      const { data } = await fetchCampaignCreate({ campaign })
      setCurrentCampaign(data as Campaign)
      return data
    }
  }, [currentCampaign, fetchCampaignCreate, fetchCampaignUpdate])

  const approveCampaign = useCallback(
    async (campaign: Campaign) => {
      const { data } = await fetchCampaignUpdate({
        campaign: {
          ...campaign,
          status: 'active',
        },
      })
      return data
    },
    [fetchCampaignUpdate]
  )

  const endCampaign = useCallback(
    async (campaign: Campaign) => {
      const { data } = await fetchCampaignUpdate({
        campaign: {
          ...campaign,
          status: 'deactivated',
        },
      })
      return data
    },
    [fetchCampaignUpdate]
  )

  const deleteCampaign = useCallback(
    async (campaignId: string) => {
      const { data } = await fetchCampaignDelete({ campaignId })
      return data
    },
    [fetchCampaignDelete]
  )

  const deleteAsset = useCallback(
    async (uploadId: string) => {
      const { data } = await fetchUploadDelete({ id: uploadId })
      return data
    },
    [fetchUploadDelete]
  )

  const pathIndex = useCallback(() => {
    return `/campaigns/`
  }, [])

  const pathSettings = useCallback(() => {
    return `/campaigns/settings`
  }, [])

  const pathCampaigns = useCallback(() => {
    return `/campaigns`
  }, [])

  const pathCampaignNew = useCallback(() => {
    return `/campaigns/new`
  }, [])

  const pathCampaignDetail = useCallback((campaign: Campaign, tab?: string) => {
    return `/campaigns/${campaign.id}${tab ? `/${tab}` : ''}`
  }, [])

  const pathCampaignEdit = useCallback((campaign: Campaign) => {
    return `/campaigns/${campaign.id}/edit`
  }, [])

  useEffect(() => {
    // Set the current campaign sponsor data
    if (currentCampaign?.sponsorId) {
      fetchSponsor({ id: currentCampaign.sponsorId }).then(({ data }) => {
        setCurrentCampaignSponsor(data as Sponsor)
      })
    }
  }, [currentCampaign?.sponsorId, fetchSponsor])

  return {
    approveCampaign,
    campaigns,
    currentAssets,
    currentCampaign,
    currentCampaignHasChanges,
    currentCampaignSponsor,
    deleteAsset,
    deleteCampaign,
    endCampaign,
    findSponsorById,
    isAssetsLoading,
    isCampaignsLoading,
    isLoading,
    isSponsorsLoading,
    loadAllCampaigns,
    loadCampaign,
    loadSponsorAssets,
    loadSponsors,
    pathCampaignDetail,
    pathCampaignEdit,
    pathCampaignNew,
    pathCampaigns,
    pathIndex,
    pathSettings,
    renameSponsorAsset,
    saveCurrentCampaign,
    setCurrentCampaignProp,
    setCurrentCampaignScreenLocationProp,
    sponsors,
  }
}
