import * as React from 'react'
import type { SVGProps } from 'react'
const CreateAlbum = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <g>
      <path d='M29.05 10.2v-.81a4.42 4.42 0 0 0-2.86-4.13v-.84A4.43 4.43 0 0 0 21.76 0H10.24a4.43 4.43 0 0 0-4.43 4.42v.84A4.42 4.42 0 0 0 3 9.39v.81a4.4 4.4 0 0 0-3 4.15v13.23A4.44 4.44 0 0 0 4.44 32h23.12A4.44 4.44 0 0 0 32 27.58V14.35a4.42 4.42 0 0 0-2.95-4.15zm-18.81-8h11.52A2.2 2.2 0 0 1 24 4.42V5H8.05v-.58a2.2 2.2 0 0 1 2.19-2.19zm-2.87 5h17.26a2.2 2.2 0 0 1 2.19 2.2v.53H5.18v-.54a2.2 2.2 0 0 1 2.19-2.2zM2.23 27.58V14.35a2.21 2.21 0 0 1 2.21-2.2h23.12a2.21 2.21 0 0 1 2.21 2.2v8l-4.66-3.72a2.77 2.77 0 0 0-3.89.43L17 24.39a.57.57 0 0 1-.37.2.49.49 0 0 1-.39-.12l-2.15-1.71a2.77 2.77 0 0 0-3.86.39l-5.55 6.62h-.24a2.21 2.21 0 0 1-2.21-2.19zm25.33 2.19h-20l4.33-5.19a.54.54 0 0 1 .75-.08l2.15 1.72a2.76 2.76 0 0 0 3.89-.44L23 20.47a.54.54 0 0 1 .76-.08l5.6 4.47a1 1 0 0 0 .45.21v2.51a2.21 2.21 0 0 1-2.25 2.19z' />
      <path d='M7.41 19.89a2.79 2.79 0 1 0-2.79-2.79 2.8 2.8 0 0 0 2.79 2.79z' />
    </g>
  </svg>
)
export default CreateAlbum
