import React from 'react'

import { Button, ButtonProps } from '../button/button'
import { Chevron } from '../chevron/chevron'

type ChevronDirection = 'down' | 'left' | 'right' | 'up'

interface Props extends ButtonProps {
  className?: string
  chevronDirectionOpened?: ChevronDirection
  chevronDirectionClosed?: ChevronDirection
  hideChevron?: boolean
  isExpanded?: boolean
  onClick?: () => void
  style?: React.CSSProperties
  textStyle?: React.CSSProperties
}

type NativeAttributes = Omit<React.HTMLAttributes<HTMLElement>, keyof Props>
export type AccordionTriggerProps = Props & NativeAttributes

export const AccordionTrigger: React.FC<
  React.PropsWithChildren<AccordionTriggerProps>
> = ({
  appearance = 'minimal',
  children,
  className,
  chevronDirectionOpened = 'down',
  chevronDirectionClosed = 'right',
  collapse = true,
  hideChevron = false,
  id,
  isExpanded = false,
  onClick,
  textStyle,
  style,
  variant = 'alternate',
}: AccordionTriggerProps) => {
  const handleOnClick = () => {
    onClick?.()
  }

  return (
    <Button
      appearance={appearance}
      className={className}
      collapse={collapse}
      id={id}
      onClick={handleOnClick}
      style={style}
      textStyle={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        ...textStyle,
      }}
      variant={variant}
    >
      {children}
      {!hideChevron && (
        <Chevron
          direction={
            isExpanded ? chevronDirectionOpened : chevronDirectionClosed
          }
          fill={'currentColor'}
          style={children ? { marginLeft: 6 } : undefined}
        />
      )}
    </Button>
  )
}

export default AccordionTrigger
