import { FC, KeyboardEvent, useEffect, useMemo } from 'react'
import ReactMarkdown from 'react-markdown'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'
import { usePortal } from '@sportsyou/react-hooks'

import { useDialog } from '../../hooks'

import Backdrop from '../backdrop'
import Button from '../button'
import CloseButton from '../close-button'
import Transition from '../css-transition'

import { ConfirmProps } from './confirm-types'

export const Confirm: FC<ConfirmProps> = ({
  backdropIgnoresClicks,
  cancelText = 'Cancel',
  className,
  clickBackdropToClose = true,
  confirmText = 'Confirm',
  disableSubmitOnEnter = false,
  id,
  isDestructive = false,
  isVisible = false,
  message,
  onCancel,
  onClickBackdrop,
  onClickContent,
  onClose,
  onConfirm,
  style,
  testId,
  title = 'Confirm',
  useMarkdown,
}: ConfirmProps) => {
  const portal = usePortal('dialog')

  const { confirm, reset } = useDialog()

  // const { alert: alertDialog, resetAlert } = useAlert()

  const _isVisible = useMemo(
    () => confirm.isVisible || isVisible,
    [confirm.isVisible, isVisible]
  )

  const handleOnClose = () => {
    onClose?.()
    confirm.onClose?.()
    reset?.('confirm')
    // resetAlert()
  }

  const handleOnClickBackdrop = () => {
    if (confirm.backdropIgnoresClicks || backdropIgnoresClicks) return
    // if (alertDialog.disableBackdropClick) return
    confirm.onClickBackdrop?.()
    onClickBackdrop?.()
    if (confirm.clickBackdropToClose || clickBackdropToClose) {
      handleOnClose()
    }
  }

  const handleOnClickCancel = () => {
    confirm.onCancel?.()
    onCancel?.()
    handleOnClose()
  }

  const handleOnClickConfirm = () => {
    confirm.onConfirm?.()
    onConfirm?.()
    handleOnClose()
  }

  const handleOnKeyUp = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !disableSubmitOnEnter) {
      handleOnClickConfirm()
    }
  }

  useEffect(() => {
    if (_isVisible) {
      setTimeout(() => {
        const confirmButton = document.querySelector(
          'div[role="dialog"] button#confirm-button'
        ) as HTMLButtonElement
        confirmButton?.focus()
      }, 0)
    }
  }, [_isVisible])

  return portal
    ? createPortal(
        <Backdrop
          onClick={handleOnClickBackdrop}
          onClickContent={confirm.onClickContent ?? onClickContent}
          data-testid={testId?.backdrop}
          visible={_isVisible}
        >
          <Transition clearTime={300} name='alert' visible={_isVisible}>
            <Container
              className={confirm.className ?? className}
              data-testid={testId?.container}
              onKeyUp={handleOnKeyUp}
              role='dialog'
              style={style}
              tabIndex={-1}
              // {...props}
            >
              {(confirm.title || title) && (
                <Header data-testid={testId?.title}>
                  {confirm.title ?? title}
                  <CloseButton
                    appearance='circular'
                    testId={testId?.close}
                    hideBackground
                    onClick={handleOnClose}
                    size={24}
                  />
                </Header>
              )}
              {(confirm.message || message) && ( //alertdialog.text
                <Body data-testid={testId?.message}>
                  {confirm.useMarkdown || useMarkdown ? (
                    <ReactMarkdown
                      children={confirm.message ?? message}
                      components={{ p: 'span' }}
                    />
                  ) : (
                    confirm.message ?? message
                  )}
                </Body>
              )}

              <Footer>
                <Button
                  appearance='minimal'
                  collapse
                  data-testid={testId?.cancel}
                  onClick={handleOnClickCancel}
                  variant='alternate'
                  style={{ marginRight: 10, padding: '8px 12px' }}
                >
                  {confirm.cancelText ?? cancelText}
                </Button>
                <Button
                  collapse
                  data-testid={testId?.confirm}
                  id='confirm-button'
                  onClick={handleOnClickConfirm}
                  style={{ padding: '8px 12px' }}
                  variant={
                    confirm.isDestructive || isDestructive
                      ? 'danger'
                      : 'primary'
                  }
                >
                  {confirm.confirmText ?? confirmText}
                </Button>
              </Footer>
              {/* {children} */}
            </Container>
          </Transition>
        </Backdrop>,
        portal
      )
    : null
}
const Container = styled.div`
  background-color: ${Colors.WHITE};
  border-radius: 4px;
  box-shadow: 0 4px 20px rgb(0 0 0 / 14%);
  box-sizing: border-box;
  color: ${Colors.MINE_SHAFT};
  display: flex;
  height: auto;
  max-width: 90vw;
  flex-direction: column;
  font-size: 1rem;
  opacity: 0;
  outline: none;
  overflow: hidden;
  position: relative;
  transform: translate3d(0, -30px, 0);
  transition: opacity 0.35s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    transform 0.35s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  vertical-align: middle;
  width: 420px;
  will-change: opacity, transform;

  &.alert-enter {
    opacity: 0;
    transform: translate3d(0, -30px, 0);
  }
  &.alert-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.alert-leave {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.alert-leave-active {
    opacity: 0;
    transform: translate3d(0, -30px, 0);
  }
`
const Header = styled.div`
  align-items: center;
  border-bottom: 1px solid ${Colors.ALTO};
  display: flex;
  font-size: 14px;
  font-weight: 700;
  justify-content: space-between;
  padding: 10px 15px;
`
const Body = styled.div`
  padding: 10px 15px;
`
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 15px;
`

export default Confirm
