import { useEffect, useRef } from 'react'

type Handler = (args: any) => void

export function useEventListener(
  eventName: keyof WindowEventMap,
  handler: Handler,
  element = window
) {
  const savedHandler = useRef<Handler>()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    function eventListener(this: Window, ev: WindowEventMap[typeof eventName]) {
      savedHandler.current?.(ev)
    }

    element.addEventListener(eventName, eventListener)
    return () => {
      element.removeEventListener(eventName, eventListener)
    }
  }, [eventName, element])
}

export default useEventListener
