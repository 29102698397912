import SubscriptionBase from './subscription-base'
import graphql from './graphql'
// import SubscriptionHooks from './Hooks'

const Subscription = {
  ...graphql,
  ...SubscriptionBase,
  // ...SubscriptionHooks,
}

export * from './subscription-base'
export * from './graphql'
// export * from './Hooks'

export { default as SubscriptionClient } from './subscription-base'

export default Subscription
