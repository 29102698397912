import * as React from 'react'
import type { SVGProps } from 'react'
const CalendarSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M25.92 1.84h-2.19v-.72a1.12 1.12 0 0 0-2.24 0v.72h-11v-.72a1.13 1.13 0 0 0-2.25 0v.72H6.08A6.07 6.07 0 0 0 0 7.89V26a6.07 6.07 0 0 0 6.08 6h19.84A6.07 6.07 0 0 0 32 26V7.89a6.07 6.07 0 0 0-6.08-6.05zM15.12 23a3.28 3.28 0 0 1-6.44.9 1.12 1.12 0 0 1 2.16-.62 1 1 0 0 0 1 .77A1.06 1.06 0 0 0 12.88 23a.83.83 0 0 0-.83-.83 1.12 1.12 0 1 1 0-2.23.83.83 0 0 0 .83-.82A1.06 1.06 0 0 0 11.82 18a1 1 0 0 0-1 .77 1.13 1.13 0 0 1-1.39.76 1.11 1.11 0 0 1-.77-1.38 3.26 3.26 0 0 1 3.14-2.39 3.3 3.3 0 0 1 3.3 3.29 3 3 0 0 1-.7 1.94 3 3 0 0 1 .72 2.01zm7.12 3.28h-4.43a1.12 1.12 0 1 1 0-2.23h1.09v-4.62l-.53.49a1.14 1.14 0 0 1-1.59-.08 1.11 1.11 0 0 1 .08-1.58l2.41-2.17a1.13 1.13 0 0 1 1.88.83V24h1.09a1.12 1.12 0 1 1 0 2.23zm7.51-16.2H2.25V7.89a3.83 3.83 0 0 1 3.83-3.82h2.18v.73a1.13 1.13 0 0 0 2.25 0v-.73h11v.73a1.12 1.12 0 0 0 2.24 0v-.73h2.19a3.83 3.83 0 0 1 3.83 3.82z' />
  </svg>
)
export default CalendarSolid
