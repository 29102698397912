import React, { FC, PropsWithChildren, useMemo } from 'react'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'

import { BORDER_RADIUS } from '../../theme'
import { getChildrenFromReactNode, getPropsFromReactNode } from '../../utils'

import AgendaWidget, { ExtdCalendarEvent } from './templates/agenda'
import WidgetHeader from './widget-header'
import WidgetFooter from './widget-footer'

export interface IWidgetProps {
  children?: any
  headerRight?: any
  title?: string
}

interface SocialLink {
  title?: string
  url?: string
}
interface Props {
  bodyClassName?: string
  bodyStyle?: React.CSSProperties

  className?: string
  containerStyle?: React.CSSProperties

  contentClassName?: string
  contentMaxHeight?: number
  contentStyle?: React.CSSProperties

  headerClassName?: string
  headerStyle?: React.CSSProperties

  events?: ExtdCalendarEvent[]
  onClickEvent?: (event?: ExtdCalendarEvent, date?: string) => void

  footerClassName?: string
  footerStyle?: React.CSSProperties

  socialLinks?: Array<SocialLink>
  title?: string
  widgetMaxHeight?: number
}

type NativeAttributes = Omit<React.HTMLAttributes<HTMLElement>, keyof Props>
export type WidgetProps = Props & NativeAttributes

// interface NodeData {
//   header?: React.ReactNode
//   footer?: React.ReactNode
// }
// type NodeTypes = 'header' | 'footer'

export const Widget: FC<PropsWithChildren<WidgetProps>> = ({
  bodyClassName,
  bodyStyle,
  className,
  children,
  contentClassName,
  contentStyle,
  contentMaxHeight,
  events,
  onClickEvent,
  containerStyle,
  headerClassName,
  headerStyle,
  footerClassName,
  footerStyle,
  title,
  widgetMaxHeight,
}) => {
  const headerNode = useMemo(
    () =>
      React.Children.map(children, (item) => {
        if (!React.isValidElement(item)) return null
        return item.type === WidgetHeader ? item : null
      }),
    [children]
  )

  const footerNode = useMemo(
    () =>
      React.Children.map(children, (item) => {
        if (!React.isValidElement(item)) return null
        return item.type === WidgetFooter ? item : null
      }),
    [children]
  )

  // const nodes: NodeData = (
  //   React.Children.map(children, (item) => {
  //     if (React.isValidElement(item)) {
  //       if (item.type === WidgetHeader) {
  //         return { header: item }
  //       }
  //       if (item.type === WidgetFooter) {
  //         return { footer: item }
  //       }
  //     }
  //     return null
  //   }) ?? []
  // ).reduce((a, val) => {
  //   const key: NodeTypes = Object.keys(val)[0] as NodeTypes
  //   return { ...a, [key]: val[key] }
  // }, {})

  // const xcontentNodes =
  //   React.Children.map(children, (item) => {
  //     if (React.isValidElement(item)) {
  //       if (item.type !== WidgetHeader && item.type !== WidgetFooter) {
  //         return item
  //       }
  //     }
  //     return null
  //   }) ?? []
  const contentNodes = useMemo(
    () =>
      React.Children.map(children, (item) => {
        if (!React.isValidElement(item)) return null
        return item.type !== WidgetHeader && item.type !== WidgetFooter
          ? item
          : null
      }),
    [children]
  )

  // const getChildren = (item: React.ReactNode) => {
  //   if (!React.isValidElement(item)) return null
  //   return item.props.children
  // }

  const getWidgetContent = useMemo(() => {
    if (events) {
      return (
        <AgendaWidget
          className={className}
          contentMaxHeight={contentMaxHeight}
          events={events}
          onClickEvent={onClickEvent}
          // title={title}
        />
      )
    }
    return (
      <WidgetBody className={bodyClassName} style={bodyStyle}>
        {contentNodes && contentNodes}
      </WidgetBody>
    )
  }, [
    bodyClassName,
    bodyStyle,
    className,
    contentMaxHeight,
    contentNodes,
    events,
    onClickEvent,
  ])

  const getContentMaxHeight = useMemo(() => {
    if (events && events.length > 0 && !contentMaxHeight) {
      return 390
    }
    return contentMaxHeight
  }, [contentMaxHeight, events])
  return (
    <Container
      className={className}
      style={containerStyle}
      widgetMaxHeight={widgetMaxHeight}
    >
      {headerNode &&
        headerNode.map((node, index) => (
          <WidgetHeader
            key={index}
            className={headerClassName}
            style={headerStyle}
            {...getPropsFromReactNode(node)}
          >
            {getChildrenFromReactNode(node)}
          </WidgetHeader>
        ))}
      {events && (
        <AgendaHeader>
          <AgendaHeaderLeft>Agenda</AgendaHeaderLeft>
          <AgendaHeaderRight>Next 7 Days</AgendaHeaderRight>
        </AgendaHeader>
      )}
      <Content
        className={contentClassName}
        contentMaxHeight={getContentMaxHeight}
        style={contentStyle}
      >
        {getWidgetContent}
      </Content>

      {footerNode &&
        footerNode.map((node, index) => (
          <WidgetFooter
            key={index}
            {...getPropsFromReactNode(node)}
            className={footerClassName}
            style={footerStyle}
          >
            {getChildrenFromReactNode(node)}
          </WidgetFooter>
        ))}
    </Container>
  )
}

const Container = styled.div<Pick<WidgetProps, 'widgetMaxHeight'>>`
  background-color: ${Colors.WHITE};
  border: 1px solid ${Colors.ALTO};
  border-radius: ${BORDER_RADIUS};
  // margin-bottom: 16px;
  // min-width: 300px;
  max-height: ${({ widgetMaxHeight }) =>
    widgetMaxHeight ? `${widgetMaxHeight}px` : 'none'};
  overflow-y: ${({ widgetMaxHeight }) =>
    widgetMaxHeight ? 'auto' : 'initial'};
`
const AgendaHeader = styled.header`
  align-items: center;
  border-bottom: 1px solid ${Colors.MYSTIC};
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 40px;
  justify-content: space-between;
  padding: 6px 10px;
`
const AgendaHeaderLeft = styled.div`
  color: ${Colors.MINE_SHAFT};
  font-weight: 700;
`
const AgendaHeaderRight = styled.div`
  color: ${Colors.DUSTY_GRAY};
  font-size: 14px;
`
const Content = styled.div<Pick<WidgetProps, 'contentMaxHeight'>>`
  max-height: ${({ contentMaxHeight }) =>
    contentMaxHeight ? `${contentMaxHeight}px` : 'none'};
  overflow-y: ${({ contentMaxHeight }) =>
    contentMaxHeight ? 'auto' : 'initial'};
`
const WidgetBody = styled.div`
  padding-bottom: 10px;
  padding-top: 10px;
`

Widget.displayName = 'Widget'

export default Widget
