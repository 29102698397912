import * as React from 'react'
import type { SVGProps } from 'react'
const FilePpt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 537.7 661.8'
    width='1em'
    height='1em'
    style={props.style}
    {...props}
  >
    <path
      fill='none'
      d='M174.2 514.6l74-74v-88.5a105 105 0 0 0-49.6 18.5 103.31 103.31 0 0 0-24.4 144z'
    />
    <path
      fill='none'
      d='M206.8 248.2V103.4a20.74 20.74 0 0 1 20.7-20.7h248.2v-62h-455v620.4h455V268.8H227.5a20.66 20.66 0 0 1-20.7-20.6zM382.6 455a124.15 124.15 0 0 1-124.1 124.1C190 579 134.4 523.5 134.4 455a124.1 124.1 0 0 1 248.2 0z'
    />
    <path
      fill='none'
      d='M329.5 530l-71-71-71 71a103.08 103.08 0 0 0 142 0zm31.9-64.9a103.33 103.33 0 0 0-92.5-113v88.5l74 74a104.19 104.19 0 0 0 18.5-49.5z'
    />
    <path
      fill='#cf4515'
      d='M134.4 455a124.1 124.1 0 1 0 124.1-124.1A124.15 124.15 0 0 0 134.4 455zm134.4-102.9a103.13 103.13 0 0 1 92.5 113 105 105 0 0 1-18.5 49.6l-74-74zM329.5 530a103.21 103.21 0 0 1-142.1 0l71-71zM198.6 370.6a102.14 102.14 0 0 1 49.6-18.5v88.5l-74 74a103.31 103.31 0 0 1 24.4-144z'
    />
    <path
      fill='#cf4515'
      d='M517 82.7h-20.7v-62A20.74 20.74 0 0 0 475.6 0h-455A20.72 20.72 0 0 0 0 20.7v620.4a20.74 20.74 0 0 0 20.7 20.7h455a20.74 20.74 0 0 0 20.7-20.7V268.8H517a20.74 20.74 0 0 0 20.7-20.7V103.4A20.74 20.74 0 0 0 517 82.7zm-41.4 558.4h-455V20.7h455v62H227.5a20.74 20.74 0 0 0-20.7 20.7v144.8a20.74 20.74 0 0 0 20.7 20.7h248.2v372.2z'
    />
    <path
      fill='#fff'
      d='M275.9 134.2h35a26.86 26.86 0 0 1 13.6 3.3 21.79 21.79 0 0 1 8.6 8.7 25.86 25.86 0 0 1 2.9 12.2 25 25 0 0 1-3 12.1 22.21 22.21 0 0 1-8.6 8.6 27.5 27.5 0 0 1-13.5 3.2h-19.1v26.8h-16v-74.9zm40.7 16a10.62 10.62 0 0 0-7.9-3.1h-17v22.5h17a10.47 10.47 0 0 0 7.9-3.1 10.93 10.93 0 0 0 3.1-8.1 10.36 10.36 0 0 0-3.1-8.2zm29.4-16h35a26.86 26.86 0 0 1 13.6 3.3 21.79 21.79 0 0 1 8.6 8.7 25.86 25.86 0 0 1 2.9 12.2 25 25 0 0 1-3 12.1 22.21 22.21 0 0 1-8.6 8.6 27.5 27.5 0 0 1-13.5 3.2h-19.1v26.8h-16v-74.9zm40.7 16a10.62 10.62 0 0 0-7.9-3.1h-17v22.5h17a10.47 10.47 0 0 0 7.9-3.1 10.93 10.93 0 0 0 3.1-8.1 10.36 10.36 0 0 0-3.1-8.2zm46.6-3.2h-21.9v-12.8h59.8V147h-21.8v62.1h-16.1z'
    />
  </svg>
)
export default FilePpt
