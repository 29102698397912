import { Profile, Image } from '@sportsyou/api'

import { getTranscodeUrl } from './get-transcode-url'

export const getProfileCoverImageUrl = (profile: Profile) =>
  profile.coverImage
    ? getTranscodeUrl({
        transcodeTypes: ['coverImage'],
        upload: profile.coverImage as Image[],
      })
    : ''

export default getProfileCoverImageUrl
