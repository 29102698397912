import GetMetadata from './get-metadata'
import GetQRCode from './get-qr-code'

export * from './get-metadata'
export * from './get-qr-code'

export default {
  ...GetMetadata,
  ...GetQRCode,
}
