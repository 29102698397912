import moment from 'moment'

type DateType = Date | string | undefined

export const DATE_FORMATS = {
  DATETIME_WITH_YEAR: 'MM/DD/YYYY, h:mm A',
  DATETIME_WITHOUT_YEAR: 'MM/DD/YYYY, h:mm A',
  DATE_WITH_YEAR: 'dddd, MMMM D, YYYY',
  DATE_WITHOUT_YEAR: 'dddd, MMMM D',
}

export const formatTimestamp = (
  date: DateType = undefined,
  includeTime = true,
  fromNow?: boolean,
  format?: string
): string => {
  let formatted = ''

  const momentDate = moment(date)
  const aYearAgo = moment().subtract(1, 'year')

  if (fromNow) {
    const timeDiff = moment().diff(momentDate, 'seconds')
    if (timeDiff < 120) {
      return 'just now'
    }
    return momentDate.fromNow()
  }

  if (format) {
    return momentDate.format(format)
  } else {
    switch (true) {
      case momentDate.isSameOrBefore(aYearAgo) && !includeTime:
        formatted = momentDate
          .startOf('day')
          .format(DATE_FORMATS.DATE_WITH_YEAR)
        break
      case !momentDate.isSameOrBefore(aYearAgo) && !includeTime:
        formatted = momentDate
          .startOf('day')
          .format(DATE_FORMATS.DATE_WITHOUT_YEAR)
        break
      case !momentDate.isSameOrBefore(aYearAgo) && includeTime:
        formatted = momentDate.format(DATE_FORMATS.DATETIME_WITHOUT_YEAR)
        break
      case momentDate.isSameOrBefore(aYearAgo) && includeTime:
      default:
        formatted = momentDate.format(DATE_FORMATS.DATETIME_WITH_YEAR)
    }
  }

  return formatted
}

export default formatTimestamp
