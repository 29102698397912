import * as React from 'react'
import type { SVGProps } from 'react'
const CreateGroup = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M18.09 18.36h-2.78a6.39 6.39 0 0 0-6.38 6.39.83.83 0 1 0 1.66 0A4.72 4.72 0 0 1 15.31 20h2.78a.83.83 0 0 0 .83-.83.84.84 0 0 0-.83-.81zm0-2.5a5.69 5.69 0 1 0-4-1.66 5.67 5.67 0 0 0 4 1.66zm-2.85-8.53a4 4 0 1 1 0 5.69 4 4 0 0 1 0-5.69zM10 17.81a.83.83 0 0 0-.84-.81H5a5 5 0 0 0-5 5 .83.83 0 0 0 1.66 0A3.34 3.34 0 0 1 5 18.64h4.16a.83.83 0 0 0 .84-.83zm-2.92-3.33a4.31 4.31 0 0 0 3-7.35 4.41 4.41 0 0 0-6.08 0 4.31 4.31 0 0 0 3 7.35zM5.21 8.31A2.64 2.64 0 0 1 8.94 12a2.69 2.69 0 0 1-3.73 0 2.64 2.64 0 0 1 0-3.73zm25.22 9.05c-.13-.14-.26-.28-.4-.41a7.07 7.07 0 0 0-.93-.7 4.72 4.72 0 0 0-.73-.41c-.15-.06-.31-.1-.46-.15a6.09 6.09 0 0 0-1.86-.37h-.32a6.49 6.49 0 0 0-1.66.29A6.23 6.23 0 0 0 21.65 17a6.43 6.43 0 0 0-1.75 3.33 4.9 4.9 0 0 0-.1 1 5.89 5.89 0 0 0 .2 1.35 7.08 7.08 0 0 0 .24.82 6.1 6.1 0 0 0 .68 1.26.18.18 0 0 0 .05.07 5.32 5.32 0 0 0 .45.61 6.19 6.19 0 0 0 .93.84 6 6 0 0 0 2.6 1.11 6.4 6.4 0 0 0 1 .08 6.26 6.26 0 0 0 1.23-.12 6.1 6.1 0 0 0 4.87-6 6 6 0 0 0-1.62-3.99zm-.7 4.43a.63.63 0 0 1-.52.28h-2.65v2.65a.8.8 0 0 1 0 .15.66.66 0 0 1-.63.51.64.64 0 0 1-.62-.51v-2.8h-2.72a.66.66 0 0 1 0-1.32h2.65V18.1a.66.66 0 0 1 1.32 0v2.65h2.65a.66.66 0 0 1 .65.62.63.63 0 0 1-.13.42z' />
  </svg>
)
export default CreateGroup
