import { RefObject, useCallback, useRef } from 'react'

const useRefWithCallback = (): [
  RefObject<HTMLElement>,
  (node: HTMLElement | null) => void
] => {
  const ref = useRef<HTMLElement | null>(null)
  const setRef = useCallback((node: HTMLElement | null) => {
    ref.current = node
  }, [])
  return [ref, setRef]
}

export default useRefWithCallback
