import React from 'react'

export interface RadioConfig {
  disabledAll: boolean
  inGroup: boolean
  updateState?: (value: number | string) => void
  value?: number | string
}

const defaultContext = {
  disabledAll: false,
  inGroup: false,
}

export const RadioContext = React.createContext<RadioConfig>(defaultContext)

export const useRadioContext = (): RadioConfig =>
  React.useContext<RadioConfig>(RadioContext)
