import React, {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { createPortal } from 'react-dom'
import { BsX } from 'react-icons/bs'
import ReactMarkdown from 'react-markdown'
import styled, { css } from 'styled-components'

import { Colors, checkColorValue } from '@sportsyou/core'
import { usePortal } from '@sportsyou/react-hooks'

import { useDialog } from '../../hooks'
import { StatusTypes } from '../../utils/prop-types'

import Transition from '../css-transition'

import type { BannerAction, BannerProps as Props } from './banner-types'

type NativeAttributes = Omit<React.HTMLAttributes<HTMLDivElement>, keyof Props>
export type BannerProps = Props & NativeAttributes

const getBackgroundColor = (status?: StatusTypes) => {
  if (status === 'success') {
    return Colors.MOUNTAIN_MEADOW
  } else if (status === 'alert') {
    return Colors.VIVID_TANGERINE
  } else if (status === 'info') {
    return Colors.PERANO
  } else if (status === 'danger') {
    return Colors.MONZA
  }
  return Colors.SHUTTLE_GRAY
}

export const Banner: FC<BannerProps> = ({
  actions,
  autoDismiss,
  dismissTime = 3000,
  floating = true,
  isVisible = false,
  message,
  onClose,
  status,
  testId,
  useMarkdown = false,
}: BannerProps) => {
  const portal = usePortal('dialog')

  const { banner, reset } = useDialog()

  const timer = useRef<number | undefined>()

  const messageStyles: React.CSSProperties = useMemo(() => {
    // Does the banner have user-defined actions
    if (
      (banner.actions && banner.actions.length > 0) ||
      (actions && actions.length > 0)
    ) {
      return {
        marginRight: 10,
        maxWidth: '100%',
        textAlign: 'left',
      }
    }

    if (!banner.autoDismiss || !autoDismiss) {
      return {
        maxWidth: `calc(100% - ${34 * 2 + 10}px)`, // 34 is size of close button + 5 for margins
        textAlign: 'center',
      }
    }
    return {
      maxWidth: '100%',
      textAlign: 'center',
    }
  }, [actions, autoDismiss, banner.actions, banner.autoDismiss])

  const handleOnClose = useCallback(() => {
    onClose?.()
    banner.onClose?.()
    reset?.('banner')
  }, [banner, onClose, reset])

  useEffect(() => {
    if (!banner.isVisible && !isVisible) return
    if (banner.autoDismiss || autoDismiss) {
      timer.current = window.setTimeout(() => {
        handleOnClose()
        timer.current && clearTimeout(timer.current)
      }, banner.dismissTime ?? dismissTime)
    }
  }, [
    autoDismiss,
    banner.isVisible,
    banner.autoDismiss,
    banner.dismissTime,
    dismissTime,
    isVisible,
    handleOnClose,
  ])

  // const resetBanner

  const hasActionOrCloseButtons = useMemo(() => {
    const _actions = banner.actions ?? actions
    const _autoDismiss = banner.autoDismiss ?? autoDismiss

    return _actions || !_autoDismiss
  }, [actions, autoDismiss, banner.actions, banner.autoDismiss])

  const hasActions = useMemo(
    () =>
      (banner.actions && banner.actions.length > 0) ||
      (actions && actions.length > 0),
    [actions, banner.actions]
  )

  const handleMouseEvent = (isHovered: boolean) => {
    if (banner.autoDismiss || autoDismiss) {
      if (isHovered) {
        return timer.current && clearTimeout(timer.current)
      }
      timer.current = window.setTimeout(() => {
        handleOnClose()
        timer.current && clearTimeout(timer.current)
      }, banner.dismissTime ?? dismissTime)
    }
  }

  const handleOnClickAction = (
    event: MouseEvent<HTMLButtonElement>,
    action: BannerAction
  ) => {
    action.onClick?.(event)
    // if (
    //   typeof action.dismissBannerOnClick === 'undefined' ||
    //   action.dismissBannerOnClick
    // ) {
    handleOnClose()
    // }
  }

  if (!portal) return null
  return createPortal(
    <Transition
      name='banner'
      clearTime={300}
      visible={banner.isVisible || isVisible}
    >
      <Container
        data-testid={testId}
        floating={banner.floating ?? floating}
        onMouseEnter={() => handleMouseEvent(true)}
        onMouseLeave={() => handleMouseEvent(false)}
        role='dialog'
        status={banner.status ?? status}
      >
        {(banner.message || message) && (
          <Message style={messageStyles}>
            {useMarkdown ? (
              <ReactMarkdown
                children={banner.message ?? message}
                components={{ p: 'span' }}
              />
            ) : (
              banner.message ?? message
            )}
          </Message>
        )}

        {hasActionOrCloseButtons &&
          (hasActions ? (
            <ActionButtons>
              {(banner.actions ?? actions)?.map((action, index) => (
                <ActionButton
                  key={`${index}-banner-action`}
                  type='button'
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                    handleOnClickAction(event, action)
                  }
                  status={action.status}
                  style={action.style}
                >
                  {action.label}
                </ActionButton>
              ))}
            </ActionButtons>
          ) : (
            <CloseButton
              aria-label='Dismiss'
              type='button'
              onClick={handleOnClose}
            >
              <BsX size={22} />
            </CloseButton>
          ))}
      </Container>
    </Transition>,
    portal
  )
}

const Container = styled.div<BannerProps>`
  align-items: center;
  background-color: ${({ status }) => getBackgroundColor(status)};
  border-radius: ${({ floating }) => (floating ? '8px' : undefined)};

  box-shadow: ${({ floating }) =>
    floating
      ? '0 4px 10px -4px rgba(0, 0, 0, 0.4)'
      : '0 2px 8px -2px rgba(0, 0, 0, 0.2)'};

  box-sizing: border-box;
  color: ${({ status }) => checkColorValue(getBackgroundColor(status))};
  display: flex;
  // justify-content: flex-start;
  justify-content: center;
  // left: 0;
  left: ${({ floating }) => (floating ? '10px' : 0)};
  min-height: ${({ floating }) => (floating ? 48 : 60)}px;

  max-width: ${({ floating }) => (floating ? '600px' : undefined)};
  margin-left: ${({ floating }) => (floating ? 'auto' : undefined)};
  margin-right: ${({ floating }) => (floating ? 'auto' : undefined)};

  opacity: 0;
  padding: 10px;
  position: fixed;
  right: ${({ floating }) => (floating ? '10px' : 0)};
  top: ${({ floating }) => (floating ? '30px' : 0)};
  transform: ${({ floating }) =>
    floating ? 'translate3d(0, -200%, 0)' : 'translate3d(0, -120%, 0)'};
  transition: opacity 0.35s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    transform 0.35s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  will-change: opacity, transform;
  z-index: 1000;

  &.banner-enter {
    opacity: 0;
    transform: ${({ floating }) =>
      floating ? 'translate3d(0, -200%, 0)' : 'translate3d(0, -120%, 0)'};
  }
  &.banner-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.banner-leave {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.banner-leave-active {
    opacity: 0;
    transform: ${({ floating }) =>
      floating ? 'translate3d(0, -200%, 0)' : 'translate3d(0, -120%, 0)'};
  }
`
// ${({ floating }) => (floating ? FloatingStyles : FixedStyles)};
const Message = styled.p`
  color: inherit;
  flex: 1 1 auto;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.6;
  margin: 0;
  max-width: calc(100% - 40px);
  text-align: center;
`
const ActionButtons = styled.div`
  align-items: center;
  // background-color: blue;
  display: flex;
  // margin-right: 10px;
  // position: absolute;
  // right: 0;
  // align-self: flex-end;
`

const Button = css`
  background: none;
  border: none;
  border-radius: 4px;
  color: inherit;
  cursor: pointer;
  height: 34px;
  margin: 0;
  transition: background-color 160ms ease-in-out;
  &:hover {
    background-color: rgb(255 255 255 / 10%);
  }
  &:active {
    background-color: rgb(255 255 255 / 20%);
  }
`
const ActionButton = styled.button<{ status?: StatusTypes }>`
  ${Button};
  padding-left: 12px;
  padding-right: 12px;
  & + & {
    margin-left: 6px;
  }
`
const CloseButton = styled.button`
  ${Button};
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  padding: 0;
  width: 34px;
  position: absolute;
  right: 10px;
`

export default Banner
