import GraphQL from './graphql'
import Static from './static'

const Api = {
  ...GraphQL,
  ...Static,
}

export * from './api-base'
export * from './graphql'
export * from './static'

export default Api
