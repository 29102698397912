import React from 'react'
import styled from 'styled-components'

interface Props {
  className?: string
  style?: React.CSSProperties
}

type NativeAttributes = Omit<React.HTMLAttributes<HTMLDivElement>, keyof Props>
export type WidgetTextProps = Props & NativeAttributes

export const WidgetText: React.FC<React.PropsWithChildren<WidgetTextProps>> = ({
  className,
  children,
  style,
}: WidgetTextProps) => {
  return (
    <Container className={className} style={style}>
      {children}
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 12px;
  padding-right: 12px;
  &:not(:first-child) {
    margin-top: 10px;
  }
`

export default WidgetText
