import React from 'react'
import styled from 'styled-components'
import { Colors } from '@sportsyou/core'

export interface DropdownDividerProps {
  /** Color of divider
   * @default ALTO
   */
  fill?: string

  /** Margin on top and bottom of divider (in em)
   * @default 0.5
   */
  gap?: number

  testId?: string
  /** Thickness of divider line (in px)
   * @default 1px
   */
  thickness?: number
}

/**
 * Divider for dropdown menu items
 */
const DropdownDivider: React.FC<DropdownDividerProps> = ({
  fill = Colors.ALTO,
  gap = 0.5,
  testId,
  thickness = 1,
}: DropdownDividerProps) => {
  return (
    <Rule
      aria-orientation='horizontal'
      data-testid={testId}
      fill={fill}
      gap={gap}
      role='separator'
      thickness={thickness}
    />
  )
}

const Rule = styled.hr<DropdownDividerProps>`
  border-color: ${({ fill }) => fill};
  border-width: ${({ thickness }) => `0 0 ${thickness}px}`};
  height: 0;
  margin: ${({ gap }) => gap ?? 0.5}em 0;
  width: auto;
`

export default DropdownDivider
