import * as React from 'react'
import type { SVGProps } from 'react'
const NavHome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M31 12.31L16.56 2.18a1 1 0 0 0-1.12 0L1 12.31a1 1 0 0 0 1.12 1.6L3.45 13v16a1 1 0 0 0 1 1h7.24a1 1 0 0 0 1-1v-5.76a1.92 1.92 0 0 1 1.91-1.92h2.9a1.92 1.92 0 0 1 1.91 1.92V29a1 1 0 0 0 1 1h7.24a1 1 0 0 0 1-1V13l1.36.95a1 1 0 0 0 .99-1.64zm-4.4 15.74h-5.28v-4.81a3.88 3.88 0 0 0-3.87-3.88h-2.9a3.88 3.88 0 0 0-3.87 3.88v4.81H5.4V11.59L16 4.17l10.6 7.42z' />
  </svg>
)
export default NavHome
