import * as React from 'react'
import type { SVGProps } from 'react'
const Jersey = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M28.66 3.34l-4.48-2.08a2.81 2.81 0 0 0-2.9.32L16 5.62l-5.1-3.9a3.16 3.16 0 0 0-3.28-.36l-4.28 2A5.78 5.78 0 0 0 0 8.57v4.37a2.62 2.62 0 0 0 2.62 2.62H6.3v12.82A2.62 2.62 0 0 0 8.92 31h14.16a2.62 2.62 0 0 0 2.62-2.62V15.56h3.68A2.63 2.63 0 0 0 32 12.94V8.57a5.78 5.78 0 0 0-3.34-5.23zm-5.05 20.8H8.39v-3.86h15.22zm-.53 4.77H8.92a.53.53 0 0 1-.53-.53v-2.15h15.22v2.15a.53.53 0 0 1-.53.53zm6.83-16a.53.53 0 0 1-.53.53H25.7v-2.3a1 1 0 0 0-2.09 0v7.05H8.39v-7a1 1 0 0 0-2.09 0v2.33H2.62a.53.53 0 0 1-.53-.53V8.57a3.69 3.69 0 0 1 2.13-3.33l4.27-2a1.11 1.11 0 0 1 1.14.12l5.73 4.39a1.08 1.08 0 0 0 1.28 0l5.91-4.53a.75.75 0 0 1 .75-.08l4.48 2.08a3.69 3.69 0 0 1 2.13 3.33z' />
  </svg>
)
export default Jersey
