import styled from 'styled-components'

import { Colors } from '@sportsyou/core'
import { Icon } from '@sportsyou/react-dom-ui'
import { IconNames } from '@sportsyou/react-icons'
import { ScreenLocationOption } from './CampaignFormAssets'

interface LocationIconProps {
  option: ScreenLocationOption
}

export default function LocationIcon({ option }: LocationIconProps) {
  if (option.name === 'loadingScreen') {
    return <Icon name='SyLogoCircle' color={Colors.PUNCH} size={24} />
  } else {
    return (
      <Container>
        <Icon name={option.icon as IconNames} color={Colors.WHITE} size={12} />
      </Container>
    )
  }
}

const Container = styled.div`
  align-items: center;
  background-color: ${Colors.PUNCH};
  border-radius: 50%;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
`
