import React, { useCallback, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'

import { getChildrenFromReactNode, getPropsFromReactNode } from '../../utils'
import { BORDER_RADIUS } from '../../theme'

import Searchbar from '../searchbar/searchbar'
import SelectOption, { SelectOptionProps } from './select-option'

interface SelectMenuProps {
  className?: string
  filterable?: boolean
  onChangeValue: (value: string) => void
  onCloseMenu: () => void
  options?: Array<React.ReactElement>
  selectedValue: string
  style?: React.CSSProperties
}

export const SelectMenu: React.FC<SelectMenuProps> = ({
  className,
  filterable,
  onChangeValue,
  onCloseMenu,
  options,
  selectedValue,
  style,
}: SelectMenuProps) => {
  // const [filteredOptions, setFilteredOptions] = useState(options)
  const [filterValue, setFilterValue] = useState('')

  const menuRef = useRef<HTMLDivElement>(null)

  const filterableOptions = useMemo(() => {
    return options?.filter((option) => {
      // Recursively map all children so we can filter out anything that
      // doesn't match the `filterValue` during search
      const children = getChildrenFromReactNode(option, () => {})
        .map((child) => child.toString().toLowerCase())
        .join()

      return children.includes(filterValue.toLowerCase())
    })

    // getChildrenFromReactNode(options, () => {})
    //   .map((c: any) => c.toString().toLowerCase())
    //   .includes(filterValue)

    // return options?.filter((option) => {
    //   // store children from props
    //   const children = (option.props as SelectOptionProps).children

    //   // console.log({ a, searchableChildren })
    //   const _searchableChildren =
    //     // check if there are multiple children
    //     typeof children !== 'string' && React.Children.count(children) > 1
    //       ? React.Children.map(children, (child) =>
    //           React.isValidElement(child)
    //             ? // NOTE: this is not a deep filter and only works 1 level
    //               // retrieve children from children
    //               getChildrenFromReactNode(child)
    //             : null
    //         )?.join()
    //       : // only one child, we can retrieve children with helper and use
    //         // that for our filter
    //         getChildrenFromReactNode(option)
    //   return searchableChildren
    //     .toString()
    //     .toLowerCase()
    //     .trim()
    //     .includes(filterValue.toLowerCase())
    // })
  }, [filterValue, options])

  const getChildren = (item: React.ReactElement) => {
    if (!React.isValidElement(item)) return null
    return (item.props as SelectOptionProps).children
  }
  // const reactChildren = React.Children.map(
  //   item,
  //   (child) => React.isValidElement(child) && child.props
  // )

  // if (typeof reactChildren !== 'string') {
  //   console.log({ reactChildren })
  // }
  // // console.log({ c })

  // const { children } = item.props as SelectOptionProps // | SelectOptionProps[]
  // if (!children) return

  // if (typeof children !== 'string' && [children]?.length) {
  //   console.log([children].length)
  //   const x = [children]
  //     .filter((c: any) => c.props?.children)
  //     .map((c: any) => c.props?.children)
  //     .join('')
  //   console.log({ x })
  // }
  // return null
  // return React.isValidElement(item)
  //   ? // check if multiple children and and retrieve the nested values
  //     typeof props.children !== 'string' && [props.children]?.length
  //     ? [props.children]
  //         .filter((c: any) => c.props.children)
  //         .map((c) => c.props.children)
  //         .join('')
  //     : // .filter((c) => c.children)
  //       // console.log(item.props.children, { props })
  //       // }
  //       props.children
  //   : null

  const getValue = (item: React.ReactElement) => {
    if (!React.isValidElement(item)) return null
    return (item.props as SelectOptionProps).value
  }

  const handleOnClickOption = (option: React.ReactElement) => {
    const { onClick, value } = option.props
    onClick?.(value)
    onChangeValue?.(value)
    onCloseMenu?.()
  }

  const onChangeFilter = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      setFilterValue(value)
    },
    []
  )

  const renderOption = (option: React.ReactElement, index: number) => (
    <SelectOption
      {...getPropsFromReactNode(option)}
      isSelected={selectedValue === getValue(option)}
      key={index}
      onClick={() => handleOnClickOption(option)}
      testid={`select-option-${index}`}
      value={getValue(option)}
    >
      {getChildren(option)}
    </SelectOption>
  )

  return (
    <Menu
      className={className}
      ref={menuRef}
      $setMaxHeight={options && options.length > 3}
      style={style}
    >
      {filterable && (
        <SearchbarContainer>
          <StyledSearchbar
            autoFocus
            inputContainerStyle={{ minWidth: 1 }}
            onChange={onChangeFilter}
          />
        </SearchbarContainer>
      )}

      {filterable
        ? filterableOptions?.map((option, index: number) =>
            renderOption(option, index)
          )
        : options?.map((option, index: number) => renderOption(option, index))}

      {/* {filteredOptions &&
        filteredOptions.length > 0 &&
        filteredOptions.map((option, index: number) => (
          <SelectOption
            {...getPropsFromReactNode(option)}
            isSelected={selectedValue === getValue(option)}
            key={index}
            onClick={() => handleOnClickOption(option)}
            value={getValue(option)}
          >
            {getChildren(option)}
          </SelectOption>
        ))} */}
    </Menu>
  )
}

const Menu = styled.div<{ $setMaxHeight?: boolean }>`
  background-color: ${Colors.WHITE};
  border: 1px solid ${Colors.ALTO};
  border-radius: ${BORDER_RADIUS};
  box-shadow: 0 4px 8px -2px rgb(0 0 0 / 24%);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  overflow: hidden;
  max-height: ${({ $setMaxHeight }) => ($setMaxHeight ? '120px' : undefined)};
  overflow-y: ${({ $setMaxHeight }) => ($setMaxHeight ? 'auto' : undefined)};
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 20;
`
const SearchbarContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 10px 8px;
`
const StyledSearchbar = styled(Searchbar)`
  flex: 1 1 auto;
  // margin-top: -1px;
  min-height: 1px;
`

export default SelectMenu
