import * as React from 'react'
import type { SVGProps } from 'react'
const SearchAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 33'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='m28.1455 26.9332-6.2691-6.2182c1.5127-1.7313 2.4291-3.982 2.4291-6.4635 0-5.46798-4.4655-9.89719-9.9782-9.89719-5.51272 0-9.97818 4.42921-9.97818 9.89719 0 5.468 4.46546 9.8972 9.97818 9.8972 2.4873 0 4.7709-.9089 6.5164-2.4094l6.269 6.2183c.291.2885.7419.2885 1.0328 0 .2909-.2886.2909-.7358 0-1.0244ZM14.3273 22.706c-4.69818 0-8.52363-3.78-8.52363-8.4545 0-4.67447 3.81091-8.45445 8.52363-8.45445 4.7127 0 8.5236 3.77998 8.5236 8.45445 0 4.6745-3.8109 8.4545-8.5236 8.4545Z' />
  </svg>
)
export default SearchAlt
