import * as React from 'react'
import type { SVGProps } from 'react'
const BubbleThick = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 34 34'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path
      fillRule='nonzero'
      d='M19.062 26.89l-7.796 6.207a1.86 1.86 0 0 1-1.106.403 2.003 2.003 0 0 1-.838-.186A1.872 1.872 0 0 1 8.27 31.64v-4.75H6.829A6.84 6.84 0 0 1 0 20.06V6.829A6.84 6.84 0 0 1 6.83 0h19.841A6.84 6.84 0 0 1 33.5 6.83v13.231a6.84 6.84 0 0 1-6.83 6.829h-7.608zM3.73 6.83v13.228a3.1 3.1 0 0 0 3.1 3.092h3.317A1.87 1.87 0 0 1 12 25.02v2.737l5.27-4.183a1.83 1.83 0 0 1 1.17-.424h8.27a3.1 3.1 0 0 0 3.1-3.09V6.832a3.11 3.11 0 0 0-3.1-3.102H6.831a3.11 3.11 0 0 0-3.101 3.1z'
    />
  </svg>
)
export default BubbleThick
