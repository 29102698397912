import * as React from 'react'
import type { SVGProps } from 'react'
const Upload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <g>
      <path d='M27.58 0h-3.31a1.12 1.12 0 1 0 0 2.23h3.31a2.2 2.2 0 0 1 2.19 2.19v15.43h-6.11a2.71 2.71 0 0 0-2.49 1.53l-.74 1.48a.54.54 0 0 1-.48.29h-7.89a.5.5 0 0 1-.48-.29l-.74-1.48a2.74 2.74 0 0 0-2.48-1.53H2.23V4.42a2.2 2.2 0 0 1 2.19-2.19h3.31a1.12 1.12 0 1 0 0-2.23H4.42A4.42 4.42 0 0 0 0 4.42v23.16A4.42 4.42 0 0 0 4.42 32h23.16A4.42 4.42 0 0 0 32 27.58V4.42A4.42 4.42 0 0 0 27.58 0zm2.19 27.58a2.2 2.2 0 0 1-2.19 2.19H4.42a2.2 2.2 0 0 1-2.19-2.19v-5.5h6.13a.52.52 0 0 1 .48.3l.74 1.47a2.76 2.76 0 0 0 2.47 1.54H20a2.75 2.75 0 0 0 2.47-1.54l.74-1.48a.45.45 0 0 1 .49-.29h6.12z' />
      <path d='M10.06 8.17a1.11 1.11 0 0 0 .79-.33l4-4V16a1.12 1.12 0 1 0 2.23 0V3.8l4 4a1.11 1.11 0 0 0 1.58-1.57L16.79.33a1 1 0 0 0-.37-.24A1 1 0 0 0 16 0a1.1 1.1 0 0 0-.82.38L9.27 6.27a1.12 1.12 0 0 0 .79 1.9z' />
    </g>
  </svg>
)
export default Upload
