import { useEffect } from 'react'
import styled from 'styled-components'

import SponsorRow from './SponsorRow'
import useCampaignPortal from '../UseCampaignPortal'

export default function SponsorPortalAssets() {
  const { sponsors, loadSponsors } = useCampaignPortal()

  useEffect(() => {
    loadSponsors()
  }, [loadSponsors])

  return (
    <Container>
      {sponsors.map((sponsor) => (
        <SponsorRow key={sponsor.id} sponsor={sponsor} />
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
