import React from 'react'
import styled from 'styled-components'
import { Colors } from '@sportsyou/core'

interface Props {
  /** Color of divider
   * @default ALTO
   */
  fill?: string

  /** Margin on top and bottom of divider (in em)
   * @default 0.5
   */
  gap?: number

  /** Thickness of divider line (in px)
   * @default 1px
   */
  thickness?: number
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type FabDividerProps = Props & NativeAttributes

/**
 *
 * @param {object} props
 * @returns
 */
const FabDivider: React.FC<FabDividerProps> = ({
  fill = Colors.ALTO,
  gap = 0,
  thickness = 1,
}: FabDividerProps) => {
  return (
    <Rule
      aria-orientation='horizontal'
      fill={fill}
      gap={gap}
      role='separator'
      thickness={thickness}
    />
  )
}

const Rule = styled.hr<FabDividerProps>`
  border-color: ${({ fill }) => fill};
  border-width: ${({ thickness }) => `0 0 ${thickness}px}`};
  height: 0;
  margin: ${({ gap }) => gap ?? 0.5}em 0;
  width: auto;
`

export default FabDivider
