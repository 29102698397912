import * as React from 'react'
import type { SVGProps } from 'react'
const JerseySolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M6.3 28.38A2.62 2.62 0 0 0 8.92 31h14.16a2.62 2.62 0 0 0 2.62-2.62v-2.15H6.3zm0-8.1h19.41v3.86H6.3zM28.66 3.34l-4.48-2.08a2.81 2.81 0 0 0-2.9.32L16 5.62l-5.1-3.9a3.16 3.16 0 0 0-3.28-.36l-4.28 2A5.78 5.78 0 0 0 0 8.57v4.37a2.62 2.62 0 0 0 2.62 2.62H6.3v2.63h19.4v-2.63h3.68A2.63 2.63 0 0 0 32 12.94V8.57a5.78 5.78 0 0 0-3.34-5.23z' />
  </svg>
)
export default JerseySolid
