import * as React from 'react'
import type { SVGProps } from 'react'
const Dots = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <g>
      <circle cx={4} cy={16} r={4} />
      <circle cx={16} cy={16} r={4} />
      <circle cx={28} cy={16} r={4} />
    </g>
  </svg>
)
export default Dots
