import { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import YouTube from 'react-youtube'

import { Colors, getYouTubeVideoId, isYouTubeUrl } from '@sportsyou/core'
import { PostMetadata as PostMetadataProps } from '@sportsyou/api'

export function PostMetadata(props: PostMetadataProps) {
  const isYouTube = useMemo(() => isYouTubeUrl(props.url ?? ''), [props.url])

  const onClick = useCallback(() => {
    window.open(props.url, '_blank')
  }, [props.url])

  if (!props.title && !props.description && !props.image) {
    return null
  }

  return (
    <Container onClick={onClick}>
      {isYouTube ? (
        <YouTube
          style={{ display: 'flex' }}
          videoId={getYouTubeVideoId(props.url)}
        />
      ) : props.image ? (
        <Image src={props.image} />
      ) : null}

      <Content>
        {props.title ? <Title>{props.title}</Title> : null}
        {props.description ? <Text>{props.description}</Text> : null}
      </Content>
    </Container>
  )
}

const Container = styled.div`
  border: 1px solid ${Colors.MYSTIC};
  cursor: pointer;
  margin: 10px;
`

const Content = styled.div`
  padding: 10px;
`

const Image = styled.img`
  border-bottom: 1px solid ${Colors.MYSTIC};
  height: 100%;
  max-height: 650px;
  max-width: 650px;
  object-fit: contain;
  width: 100%;
`

const Text = styled.div`
  color: ${Colors.SHUTTLE_GRAY};
  font-family: 'Figtree', sans-serif;
  font-size: 0.8rem;
  line-height: 1.4;
  margin-top: 10px;
`

const Title = styled.div`
  color: ${Colors.MINE_SHAFT};
  font-family: 'Figtree', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.2;
`
