import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  EventClickArg,
  EventContentArg,
  EventDropArg,
  EventInput as FCEventInput,
} from '@fullcalendar/core'
import FullCalendar from '@fullcalendar/react'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import adaptivePlugin from '@fullcalendar/adaptive'
import interactionPlugin, {
  DateClickArg,
  EventDragStartArg,
  EventDragStopArg,
  EventResizeDoneArg,
} from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import momentPlugin from '@fullcalendar/moment'
// import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import timeGridPlugin from '@fullcalendar/timegrid'

import moment from 'moment'
import styled, { css } from 'styled-components'

import { Colors, capitalize, darken, lighten } from '@sportsyou/core'
import {
  Event as ApiEvent,
  MutationEventUpdateRequest,
  Timezone,
} from '@sportsyou/api'

import { BORDER_RADIUS, SMALL_SCREEN_BREAKPOINT } from '../../theme'
import Activity from '../activity'
import CalendarPopover from '../calendar/calendar-popover'
import Checkbox from '../checkbox'
import Modal from '../modal'
import Button from '../button'
import Icon from '../icon'
import ButtonGroup from '../button-group'

type IMouseEvent = MouseEvent | React.MouseEvent
type AvailableCalendarViews =
  | 'timeGridDay'
  | 'timeGridWeek'
  | 'dayGridMonth'
  | 'listYear'

export type CalendarMode = 'day' | 'week' | 'month' | 'year'

type DroppedEvent = {
  eventRequest: MutationEventUpdateRequest
  newEvent: ApiEvent
  originalEvent: ApiEvent
  revert?: () => void
}

export type CalendarViewDate = {
  /** Today's Date */
  today?: Date
  /** Current Date displayed for the Calendar View */
  display?: Date
}

export interface CalendarProps {
  className?: string
  isLoading?: boolean

  initialDate?: Date
  initialMode?: CalendarMode

  canEdit?: boolean
  canSubscribe?: boolean
  style?: React.CSSProperties
  /**
   * Array of event objects from server
   */
  events?: ApiEvent[]
  /**
   * Passing an eventId to the calendar will display the CalendarPopover
   * component with the specified event.
   */
  eventId?: string
  showCreateEventInToolbar?: boolean
  // /**
  //  * Licenese Key for FullCalendar Premium
  //  */
  // licenseKey?: string
  /**
   * Default Timezone to display for CalendarPopover
   */
  defaultTimezone?: Timezone
  /**
   * Triggered when the user clicks on a date or a time.
   * @param date The Date for the clicked day/time
   * @param mouseEvent The native MouseEvent from the day that was clicked
   * @param el The HTML Element that represents the day that was clicked
   */
  onClickDay?: (date: Date, mouseEvent: MouseEvent, el: HTMLElement) => void

  onClickViewEvent?: (event: ApiEvent) => void
  onClickDeleteEvent?: (event: ApiEvent) => void

  onClickAttendance?: (event: ApiEvent, attendance: string) => void

  onClickEvent?: (
    event: ApiEvent,
    mouseEvent: MouseEvent,
    el: HTMLElement
  ) => void
  onClickNewEvent?: () => void
  onChange?: (
    mode: CalendarMode,
    date?: CalendarViewDate,
    diff?: number,
    shouldFetch?: boolean
  ) => void
  // onClickNext?: (mode: CalendarMode) => void
  // onClickPrev?: (mode: CalendarMode) => void
  onClickPrint?: (event: IMouseEvent, el?: HTMLElement) => void
  onClickSubscribe?: (event: IMouseEvent, el?: HTMLElement) => void
  /**
   * Moves the calendar to the current day/month/week
   * @param event Native MouseEvent from button click
   */
  onClickToday?: (event: IMouseEvent) => void

  onChangeView?: (view: CalendarMode) => void

  /**
   * Removes built in FullCalendar toolbar and renders custom React Component
   */
  useCustomToolbar?: boolean
  /**
   * Ignore calendar event color and use event type to distiguish between
   * events and games.
   */
  useEventType?: boolean

  onDragStart?: (
    event: ApiEvent,
    nativeEvent: MouseEvent,
    mode: CalendarMode
  ) => void
  onDragStop?: (
    event: ApiEvent,
    nativeEvent: MouseEvent,
    mode: CalendarMode
  ) => void
  onDrop?: (
    origEvent: ApiEvent,
    newEvent: ApiEvent,
    failCallback?: () => void
    // request: MutationEventUpdateRequest
  ) => void

  /**
   * Handler for dragging/dropping events in all views and resizing events in
   * Day and Week views.
   *
   * @param origEvent The original Event object that is being modified
   * @param newEvent The new Event object to update the server
   * @param shouldNotify Whether the update should be notified to invitees
   */
  onUpdateEvent?: (
    origEvent: ApiEvent,
    newEvent: ApiEvent,
    shouldNotify: boolean
  ) => void
}

const convertToFullCalendarEvent = (
  events: ApiEvent[],
  useEventType?: boolean
) => {
  // console.log({ events })
  const _events: FCEventInput[] = events.map((event: ApiEvent) => ({
    allDay: event.allDay ?? false,
    borderColor: useEventType
      ? event.type === 'game'
        ? Colors.PUNCH
        : Colors.HAVELOCK_BLUE
      : event.color ?? Colors.HAVELOCK_BLUE,
    backgroundColor: useEventType
      ? event.type === 'game'
        ? Colors.PUNCH
        : Colors.HAVELOCK_BLUE
      : event.color ?? Colors.HAVELOCK_BLUE,
    editable: event.isEditable ?? false,
    start: event.startDate ?? new Date().toString(),
    end: event.endDate ?? new Date().toString(),
    id: event.id ?? undefined,
    title: event.title ?? capitalize(event.type ?? '') ?? undefined,
    extendedProps: {
      event,
    },
  }))
  return _events
}

const convertViewToMode = (
  view: AvailableCalendarViews = 'dayGridMonth'
): CalendarMode => {
  if (view.toLowerCase().endsWith('day')) return 'day'
  if (view.toLowerCase().endsWith('week')) return 'week'
  if (view.toLowerCase().endsWith('month')) return 'month'
  return 'year'
}

const convertModeToFCView = (
  mode: CalendarMode = 'month'
): AvailableCalendarViews => {
  if (mode === 'day') return 'timeGridDay'
  if (mode === 'week') return 'timeGridWeek'
  if (mode === 'year') return 'listYear'
  return 'dayGridMonth'
}
export const Calendar: React.FC<CalendarProps> = ({
  canEdit,
  canSubscribe,
  className,
  initialDate,
  initialMode = 'month',
  style,
  defaultTimezone,
  isLoading,
  events: initialEvents = [],
  // licenseKey,

  onChange,
  onChangeView,
  onUpdateEvent,
  onClickDay,
  onClickEvent,
  onClickAttendance,
  onClickNewEvent,
  onClickToday,
  // onClickNext,
  // onClickPrev,
  onClickPrint,
  onClickSubscribe,
  onDragStart,
  onDragStop,
  onDrop,

  onClickDeleteEvent,
  onClickViewEvent,
  eventId,
  showCreateEventInToolbar,
  useCustomToolbar,
  useEventType,
}: CalendarProps) => {
  const initialDroppedEvent = {
    originalEvent: {},
    newEvent: {},
    eventRequest: {},
    revert: () => {},
  }

  type EventToUpdate = {
    origEvent: ApiEvent
    newEvent: ApiEvent
    revert: () => void
    type: 'resize' | 'drop'
  }
  const initialEventToUpdate: EventToUpdate = {
    origEvent: {},
    newEvent: {},
    // eventRequest: {},
    revert: () => {},
    type: 'drop',
  }

  const calendarRef = useRef<FullCalendar | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const eventRef = useRef<HTMLElement | null>(null)

  const today = moment()

  const [activeView, setActiveView] = useState<string>()
  const [droppedEvent, setDroppedEvent] =
    useState<DroppedEvent>(initialDroppedEvent)
  const [enablePopover, setEnablePopover] = useState(false)
  const [shouldNotifyUpdate, setShouldNotifyUpdate] = useState(true)
  const [isOpen, setIsOpen] = useState(false)

  const [eventToUpdate, setEventToUpdate] =
    useState<EventToUpdate>(initialEventToUpdate)

  const [isUpdateEventModalVisible, setIsUpdateEventModalVisible] =
    useState(false)

  const [selectedEvent, setSelectedEvent] = useState<ApiEvent>()
  const [title, setTitle] = useState(moment().format('MMMM YYYY'))

  const calendarApi = calendarRef.current?.getApi()

  const updateTitle = () => {
    if (calendarApi) {
      setTitle(calendarApi.view.title)
    }
  }

  // Resizing handler (needed for when main sidenav is toggled)
  // const element = containerRef.current
  // useResizeObserver(element, () => calendarApi?.updateSize())
  // useEffect(() => {
  //   if (!containerRef.current || !calendarApi) return

  //   const element = containerRef.current
  //   // let frame = 0
  //   // const observerCallback: ResizeObserverCallback = (
  //   //   entries: ResizeObserverEntry[],
  //   // ) => {
  //   //   window.requestAnimationFrame((): void | undefined => {
  //   //     if (!Array.isArray(entries) || !entries.length) return
  //   //     calendarApi.updateSize()
  //   //   })
  //   // }
  //   // const resizeObserver = new ResizeObserver(observerCallback)
  //   // cancelAnimationFrame(frame)
  //   // frame = window.requestAnimationFrame(calendarApi.updateSize)
  //   // resizeObserver.observe(element)

  //   // return () => {
  //   //   window.cancelAnimationFrame(frame)
  //   //   resizeObserver.unobserve(element)
  //   //   resizeObserver.disconnect()
  //   // }
  // }, [calendarApi])

  useEffect(() => {
    setTimeout(() => {
      setEnablePopover(true)
    }, 1000)
  }, [])

  const events = useMemo(
    () => convertToFullCalendarEvent(initialEvents, useEventType),
    [initialEvents, useEventType]
  )

  const handleCreateNewEvent = () => {
    // console.log({ event })
    // onClickNewEvent?.(event, element)
    onClickNewEvent?.()
    // updateTitle()
  }
  const handleOnClickPrint = (ev: IMouseEvent, element?: HTMLElement) => {
    onClickPrint?.(ev, element)
    window.print()
  }
  const handleOnClickSubscribe = (ev: IMouseEvent, element?: HTMLElement) => {
    canSubscribe && onClickSubscribe?.(ev, element)
  }
  const handleOnClickDay = (arg: DateClickArg) => {
    onClickDay?.(arg.date, arg.jsEvent, arg.dayEl)
  }
  const handleOnClickEvent = (arg: EventClickArg) => {
    const event =
      initialEvents.find((event) => event.id === arg.event.id) ?? arg.event

    onClickEvent?.(event, arg.jsEvent, arg.el)
    if (eventRef.current !== arg.el) {
      eventRef.current = arg.el
    }
    setSelectedEvent(event)
    setIsOpen(true)
  }
  /**
   * Handler for moving the calendar backward one day/month/week/year
   * @param event Native HTML click event
   */
  const handleOnClickPrev = (event: React.MouseEvent) => {
    // Step back once in calendar
    calendarApi?.prev()
    // Update title for customToolbar
    updateTitle()
    // Store date that is currently displayed
    const display = calendarApi?.getDate()
    // get calendar mode, we can also use this for determing the diff of the
    // current date and the visible date
    const mode = convertViewToMode(
      (calendarApi?.view.type ?? 'dayGridMonth') as AvailableCalendarViews
    )

    const diff = moment(display).startOf(mode).diff(today.startOf(mode), mode)

    // if (!currentDate || !visibleDate) return

    onChange?.(mode, { today: today.toDate(), display }, diff)
  }

  /**
   * Handler for moving the calendar forward one day/month/week/year
   * @param event Native HTML click event
   */
  const handleOnClickNext = (event: React.MouseEvent) => {
    calendarApi?.next()
    updateTitle()
    const display = calendarApi?.getDate()
    const mode = convertViewToMode(
      (calendarApi?.view.type ?? 'dayGridMonth') as AvailableCalendarViews
    )

    const diff = moment(display).startOf(mode).diff(today.startOf(mode), mode)

    console.log(
      moment(display).format('YYYY-MM-DD'),
      moment(today).format('YYYY-MM-DD'),
      { diff, mode },
      calendarApi?.view.type
    )

    // if (!currentDate || !visibleDate) return

    onChange?.(mode, { today: today.toDate(), display }, diff)
  }

  /**
   * Handler for moving the calendar to the current day/month/week/year
   * @param event Native HTML click event
   */
  const handleOnClickToday = (event: React.MouseEvent) => {
    calendarApi?.today()
    updateTitle()
    onClickToday?.(event)
    const today = calendarApi?.getDate()

    const mode = convertViewToMode(
      (calendarApi?.view.type ?? 'dayGridMonth') as AvailableCalendarViews
    )
    // if (!currentDate) return

    onChange?.(mode, { today, display: today }, 0)
  }

  /**
   * Handler to switch calendar to specified view
   * @param view View from FullCalendar api to change to
   */
  const handleOnClickChangeView = (view: AvailableCalendarViews) => {
    // Switch views in FullCalendar
    calendarApi?.changeView(view)
    // Store current view
    setActiveView(view)
    // Update Title for customToolbar
    updateTitle()
    // Convert view to CalendarMode
    const mode = convertViewToMode(view)
    onChange?.(mode, { display: calendarApi?.getDate(), today: today.toDate() })
    // onChangeView?.(mode)
  }

  /**
   * Handler for when event dragging begins.
   */
  const handleEventDragStart = (arg: EventDragStartArg) => {
    const event = initialEvents.find((ev) => ev.id === arg.event.id) ?? {}
    const mode = convertViewToMode(arg.view.type as AvailableCalendarViews)
    onDragStart?.(event, arg.jsEvent, mode)
  }

  /**
   * Handler for when event dragging ends. This is called before the event is
   * modified and before the eventDrop callback.
   */
  const handleEventDragStop = (arg: EventDragStopArg) => {
    const event = initialEvents.find((ev) => ev.id === arg.event.id) ?? {}
    const mode = convertViewToMode(arg.view.type as AvailableCalendarViews)
    onDragStop?.(event, arg.jsEvent, mode)
  }

  /**
   * Handler for when dragging stops and the event has moved/dropped to a
   * different day/time.
   */
  const handleEventDrop = (arg: EventDropArg) => {
    const origEvent = initialEvents.find((ev) => ev.id === arg.event.id)

    const newEvent = {
      ...origEvent,
      startDate: moment(arg.event.start).format(),
      endDate: moment(arg.event.end ? arg.event.end : arg.event.start).format(),
    }
    // if (arg.event.end) {
    //   newEvent.endDate = moment(arg.event.end).format()
    // } else {
    //   // const diff = moment(origEvent?.endDate).diff(
    //   //   origEvent?.startDate,
    //   //   'minutes',
    //   // )
    //   newEvent.endDate = newEvent.startDate
    // }

    if (!origEvent || !newEvent) return

    // arg.event.end is unreliable as it sometimes returns null.
    // We need to calculate the new endDate manually.
    // Find diff in days
    // const diffInDays = moment(origEvent.endDate).diff(
    //   origEvent.startDate,
    //   'days',
    // )

    // newEvent.endDate = moment(newEvent.startDate)
    //   .add(diffInDays, 'days')
    //   .format('MM/DD/YYYY')

    // console.log({ diffInDays }, origEvent.startDate, { newEvent })

    // return

    setEventToUpdate({
      origEvent,
      newEvent,
      revert() {
        arg.revert()
      },
      type: 'drop',
    })

    setIsUpdateEventModalVisible(true)

    // onDrop?.(origEvent, newEvent, () => arg.revert())
  }

  /**
   * Events on Day and Week views of the calendar can be resized to extend an
   * event's end date.
   */
  const handleEventResize = (arg: EventResizeDoneArg) => {
    const origEvent = initialEvents.find((ev) => ev.id === arg.event.id)
    const newEvent = {
      ...origEvent,
      startDate: moment(arg.event.start).format(),
      endDate: moment(arg.event.end).format(),
    }

    if (!origEvent || !newEvent) return

    setEventToUpdate({
      origEvent,
      newEvent,
      revert() {
        arg.revert()
      },
      type: 'resize',
    })
    setIsUpdateEventModalVisible(true)
  }

  // Calendar Popover event handlers
  const handleOnClickDeleteEvent = (event: ApiEvent) => {
    onClickDeleteEvent?.(event)
    // setIsDeleteEventModalVisible(!isDeleteEventModalVisible)
    // selectedEvent && onClickDeleteEvent?.(selectedEvent)
  }

  const handleOnClickViewEvent = () => {
    selectedEvent && onClickViewEvent?.(selectedEvent)
  }

  const handleOnCloseUpdateEventModal = () => {
    setIsUpdateEventModalVisible(false)
    setTimeout(() => {
      setDroppedEvent(initialDroppedEvent)
      setEventToUpdate(initialEventToUpdate)
      setShouldNotifyUpdate(true)
    }, 600)
  }

  const handleOnCancelUpdateEvent = () => {
    eventToUpdate.revert()
    droppedEvent.revert?.()
    handleOnCloseUpdateEventModal()
  }

  const handleOnSaveUpdateEventModal = () => {
    // const _eventRequest: MutationEventUpdateRequest = {
    //   ...droppedEvent.eventRequest,
    //   notify: notifyEventUpdate,
    // }
    // onDropEvent?.(
    //   droppedEvent.originalEvent,
    //   droppedEvent.newEvent,
    //   _eventRequest,
    // )

    // // Update UI
    // const _events = [
    //   ...events.filter(event => event.id !== droppedEvent.newEvent.id),
    //   droppedEvent.newEvent,
    // ]

    // setEvents(_events)

    onUpdateEvent?.(
      eventToUpdate.origEvent,
      eventToUpdate.newEvent,
      shouldNotifyUpdate
    )

    handleOnCloseUpdateEventModal()
  }

  const renderCustomToolbar = () => {
    return (
      <Header id='header'>
        <HeaderLeft>
          {showCreateEventInToolbar && (
            <CreateEventButton
              onClick={handleCreateNewEvent}
              textStyle={{ fontSize: 12 }}
            >
              <Icon
                fill={Colors.WHITE}
                name='Plus'
                size={10}
                style={{ marginRight: 6 }}
              />
              Create Event
            </CreateEventButton>
          )}

          <ButtonGroup>
            <ButtonGroup.Button onClick={handleOnClickPrev}>
              <Icon fill={Colors.HAVELOCK_BLUE} name='ChevronLeft' size={10} />
            </ButtonGroup.Button>
            <ButtonGroup.Button onClick={handleOnClickToday}>
              Today
            </ButtonGroup.Button>
            <ButtonGroup.Button onClick={handleOnClickNext}>
              <Icon fill={Colors.HAVELOCK_BLUE} name='ChevronRight' size={10} />
            </ButtonGroup.Button>
          </ButtonGroup>
        </HeaderLeft>
        <HeaderCenter>
          <H2>{title}</H2>
        </HeaderCenter>
        <HeaderRight>
          <IconButtons>
            {canSubscribe && (
              <IconButton onClick={handleOnClickSubscribe}>
                <Icon fill={Colors.HAVELOCK_BLUE} name='CalendarSubscribe' />
              </IconButton>
            )}
            <IconButton onClick={handleOnClickPrint}>
              <Icon fill={Colors.HAVELOCK_BLUE} name='Print' />
            </IconButton>
          </IconButtons>

          <ButtonGroup showActiveState>
            <ButtonGroup.Button
              isActive={activeView === 'timeGridDay'}
              onClick={() => handleOnClickChangeView('timeGridDay')}
            >
              Day
            </ButtonGroup.Button>
            <ButtonGroup.Button
              isActive={activeView === 'timeGridWeek'}
              onClick={() => handleOnClickChangeView('timeGridWeek')}
            >
              Week
            </ButtonGroup.Button>
            <ButtonGroup.Button
              isActive={activeView === 'dayGridMonth'}
              onClick={() => handleOnClickChangeView('dayGridMonth')}
            >
              Month
            </ButtonGroup.Button>
            <ButtonGroup.Button
              isActive={activeView === 'listYear'}
              onClick={() => handleOnClickChangeView('listYear')}
            >
              Year
            </ButtonGroup.Button>
          </ButtonGroup>
        </HeaderRight>
      </Header>
    )
  }

  /**
   * There is an open bug for using custom eventContent and the adaptivePlugin
   * where events are displaying with 0 height when using window.print()
   * https://github.com/fullcalendar/fullcalendar/issues/7419
   */
  const renderCustomContent = (arg: EventContentArg): React.ReactNode => {
    if (activeView === 'timeGridDay' || activeView === 'timeGridWeek') {
      return (
        <div className='fc-event-main-frame'>
          {arg.event.allDay ? (
            <div className='fc-event-title-container'>
              <div className='fc-event-title fc-sticky'>{arg.event.title}</div>
            </div>
          ) : (
            <>
              <div className='fc-event-time'>
                {moment(arg.event.start).format('h:mm a')} &ndash;{' '}
                {moment(arg.event.end).format('h:mm a')}
              </div>
              <div className='fc-event-title-container'>
                <div className='fc-event-title fc-sticky'>
                  {arg.event.title}
                </div>
              </div>
            </>
          )}
        </div>
      )
    } else if (activeView === 'dayGridMonth') {
      return (
        <div className='fc-event-main-frame'>
          {!arg.event.allDay && (
            <div className='fc-event-time'>
              {moment(arg.event.start).format('h:mm a')}
            </div>
          )}
          <div className='fc-event-title-container'>
            <div className='fc-event-title fc-sticky'>{arg.event.title}</div>
          </div>
        </div>
      )
    } else {
      const location = arg.event.extendedProps.event.location
      return (
        <>
          <div>{arg.event.title}</div>
          {location && <EventLocation>{location}</EventLocation>}
        </>
      )
    }
  }

  return (
    <>
      {isLoading && <Activity />}
      <Container
        className={className}
        id='calendar'
        ref={containerRef}
        style={style}
      >
        {useCustomToolbar && renderCustomToolbar()}
        <FullCalendar
          initialDate={initialDate}
          allDayText='All-day'
          // aspectRatio={1}
          customButtons={{
            'create-event': {
              click: (ev, element) => handleCreateNewEvent(),
              text: 'Create Event',
            },
            subscribe: {
              click: (ev, element) => handleOnClickSubscribe(ev, element),
              text: 'Subscribe',
            },
            print: {
              click: (ev, element) => handleOnClickPrint(ev, element),
              text: 'Print',
            },
          }}
          dateClick={handleOnClickDay}
          dayMaxEventRows
          eventClick={handleOnClickEvent}
          // eventContent={renderCustomContent}
          eventDidMount={(arg) => {
            if (eventId === arg.event.id) {
              eventRef.current = arg.el
              eventRef.current.click()
            }
          }}
          eventDisplay='block'
          eventDragStart={handleEventDragStart}
          eventDragStop={handleEventDragStop}
          eventDrop={handleEventDrop}
          // eventOverlap
          eventResize={handleEventResize}
          eventTimeFormat={'h:mm a'}
          events={events}
          // expandRows
          // fixedWeekCount={false}
          initialView={convertModeToFCView(initialMode)}
          schedulerLicenseKey='0992746828-fcs-1695999591'
          // editable={canEdit}
          plugins={[
            dayGridPlugin,
            resourceTimelinePlugin,
            interactionPlugin,
            listPlugin,
            momentPlugin,
            timeGridPlugin,
            adaptivePlugin,
          ]}
          headerToolbar={
            useCustomToolbar
              ? false
              : {
                  left: 'create-event prev,today,next',
                  center: 'title',
                  end: 'print',
                  right:
                    'subscribe print timeGridDay,timeGridWeek,dayGridMonth,listYear',
                }
          }
          buttonText={{
            day: 'Day',
            list: 'Year',
            month: 'Month',
            today: 'Today',
            week: 'Week',
          }}
          ref={calendarRef}
          viewDidMount={({ view }) => {
            activeView !== view.type && setActiveView(view.type)
            setTitle(view.title)
          }}
        />
        <CalendarPopover
          defaultTimezone={defaultTimezone}
          event={selectedEvent}
          open={enablePopover && isOpen}
          onClose={() => setIsOpen(false)}
          setOpen={() => setIsOpen(!isOpen)}
          showBorder
          onClickSaveAttendance={onClickAttendance}
          onClickDeleteEvent={handleOnClickDeleteEvent}
          onClickViewEvent={handleOnClickViewEvent}
          target={eventRef}
        />
        <Modal
          visible={isUpdateEventModalVisible}
          onClose={handleOnCancelUpdateEvent}
        >
          <Modal.Header>Update</Modal.Header>
          <Modal.Body>
            {eventToUpdate.type === 'resize' ? (
              <p>
                Are you sure you want to change the end time of{' '}
                <b>{eventToUpdate.origEvent.title}</b> to{' '}
                <b>
                  {moment(eventToUpdate.newEvent.endDate).format(
                    'MM/DD/YYYY hh:mm A'
                  )}
                </b>
                ?
              </p>
            ) : (
              <p>
                Are you sure you want to change{' '}
                <b>{eventToUpdate.newEvent.title}</b> to{' '}
                <b>
                  {moment(eventToUpdate.newEvent.startDate).format(
                    ['day', 'week'].includes(
                      convertViewToMode(
                        calendarApi?.view.type as AvailableCalendarViews
                      )
                    )
                      ? 'MM/DD/YYYY h:mma'
                      : 'MM/DD/YYYY'
                  )}
                </b>
                ?
              </p>
            )}
            <Checkbox
              checked={shouldNotifyUpdate}
              onChange={() => setShouldNotifyUpdate(!shouldNotifyUpdate)}
            >
              Notify all invitees of the change
            </Checkbox>
          </Modal.Body>
          <Modal.Footer
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <CancelButton
              appearance='minimal'
              onClick={handleOnCancelUpdateEvent}
            >
              Cancel
            </CancelButton>
            <Button onClick={handleOnSaveUpdateEventModal}>Save</Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  )
}

const primaryButtonStyles = css`
  background-color: ${Colors.HAVELOCK_BLUE};
  color: ${Colors.WHITE};
  border-width: 2px;
  border-radius: ${BORDER_RADIUS};
  &:active {
    background-color: ${darken(Colors.HAVELOCK_BLUE, 20)};
    border-color: ${darken(Colors.HAVELOCK_BLUE, 20)};
    box-shadow: none;
    color: ${Colors.WHITE};
  }

  &:focus {
    background-color: ${darken(Colors.HAVELOCK_BLUE, 20)};
    border-color: ${darken(Colors.HAVELOCK_BLUE, 20)};
    box-shadow: 0 0 0 2px ${lighten(Colors.HAVELOCK_BLUE, 40)};
  }
  &:active:focus {
    box-shadow: none;
  }
`
const secondaryButtonStyles = css`
  background-color: ${Colors.WHITE};
  color: ${Colors.HAVELOCK_BLUE};
  border-width: 2px;
  border-radius: ${BORDER_RADIUS};
  border-color: ${Colors.HAVELOCK_BLUE};
  &:active {
    background-color: ${darken(Colors.WHITE, 6)};
    border-color: ${Colors.HAVELOCK_BLUE};
    box-shadow: none;
    color: ${Colors.HAVELOCK_BLUE};
  }

  &:focus {
    background-color: ${darken(Colors.WHITE, 6)};
    border-color: ${Colors.HAVELOCK_BLUE};
    box-shadow: 0 0 0 2px ${lighten(Colors.HAVELOCK_BLUE, 40)} !important;
  }
  &:active:focus {
    box-shadow: none;
  }
`
const PrintStyles = css`
  @media print {
    // .fc,
    .fc-col-header,
    .fc-scrollgrid,
    .fc-scrollgrid-sync-table,
    .fc-daygrid-body fc-daygrid-body-balanced {
      width: 100% !important;
    }

    .fc-event-time,
    .fc-event {
      white-space: pre-wrap;
    }
    .fc-event-main-frame {
      flex-wrap: wrap;
    }
    .fc-event-title-container {
      display: inline-block;
    }
  }
`
const Container = styled.div`
  ${PrintStyles};

  box-sizing: border-box;
  padding: 10px;
  font-size: 12px;

  .fc-license-message {
    opacity: 0.25;
    bottom: auto;
    top: 100%;
  }

  .fc-event {
    // font-size: 14px;
    .fc-event-time {
      flex: 0 0 auto;
      font-weight: 500;
    }
    .fc-event-title {
      font-weight: 500;
      text-overflow: ellipsis;
    }
  }

  .fc-toolbar {
    font-size: 12px;
    font-weight: 500;
    .fc-button {
      ${secondaryButtonStyles};
      &.fc-button.fc-button-active {
        background-color: ${darken(Colors.HAVELOCK_BLUE, 20)};
        border-color: ${darken(Colors.HAVELOCK_BLUE, 20)};
        box-shadow: none;
        color: ${Colors.WHITE};
      }
    }
    .fc-create-event-button {
      ${primaryButtonStyles};
    }
    .fc-prev-button {
      ${secondaryButtonStyles};
      border-bottom-left-radius: ${BORDER_RADIUS};
      border-top-left-radius: ${BORDER_RADIUS};
    }
    .fc-next-button {
      ${secondaryButtonStyles};
      border-bottom-right-radius: ${BORDER_RADIUS};
      border-top-right-radius: ${BORDER_RADIUS};
    }
    .fc-today-button {
      ${secondaryButtonStyles};
    }
    .fc-today-button:disabled {
      opacity: 1;
    }

    .fc-toolbar-chunk:last-of-type .fc-button-group .fc-button:first-child {
      border-top-left-radius: ${BORDER_RADIUS};
      border-bottom-left-radius: ${BORDER_RADIUS};
    }

    .fc-toolbar-chunk:last-of-type .fc-button-group .fc-button:last-child {
      border-top-right-radius: ${BORDER_RADIUS};
      border-bottom-right-radius: ${BORDER_RADIUS};
    }
  }

  .fc-day {
    // &.fc-daygrid-day {
    //   aspect-ratio: 1;
    //   height: ${100 / 7}%;
    //   width: ${100 / 7}%;
    // }
    .fc-daygrid-day-number {
      align-items: center;
      display: flex;
      height: 22px;
      justify-content: center;
      padding: 0;
      text-align: center;
      width: 22px;
    }
    &.fc-day-today .fc-daygrid-day-number {
      background: ${Colors.HAVELOCK_BLUE};
      border-radius: 50%;
      color: ${Colors.WHITE};
    }
    &:not(.fc-day-today):not(.fc-col-header-cell):hover {
      background-color: ${Colors.CATSKILL_WHITE};
    }
  }
`
const Header = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 16px;
  @media all and (max-width: ${SMALL_SCREEN_BREAKPOINT}) {
    flex-wrap: wrap;
  }
`
const HeaderLeft = styled.div`
  align-items: center;
  display: flex;
  @media all and (max-width: ${SMALL_SCREEN_BREAKPOINT}) {
    flex: 1 0 50%;
  }
  @media print {
    display: none;
  }
`
const CreateEventButton = styled(Button)`
  min-height: 36px;
  padding: 4px 8px;
  margin-right: 10px;
`
const H2 = styled.h2`
  font-size: 1.6rem;
  margin: 0;
`
const HeaderCenter = styled.div`
  margin-left: auto;
  margin-right: auto;
  @media all and (max-width: ${SMALL_SCREEN_BREAKPOINT}) {
    margin-right: 0;
    // flex: 1 0 50%;
  }
`
const HeaderRight = styled.div`
  align-items: center;
  display: flex;
  @media all and (max-width: ${SMALL_SCREEN_BREAKPOINT}) {
    flex-grow: 1;
    justify-content: space-between;
    margin-top: 10px;
  }
  @media print {
    display: none;
  }
`
const IconButtons = styled.div`
  margin-right: 8px;
`
const IconButton = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 40px;
  transition: background-color 120ms ease-in-out;
  width: 40px;
  &:not(:first-child) {
    margin-left: 6px;
  }
  &:hover,
  &:active {
    background-color: ${Colors.CATSKILL_WHITE};
  }
`
const CancelButton = styled(Button)`
  margin-right: 6px;
`

const EventLocation = styled.div`
  color: ${Colors.SHUTTLE_GRAY};
`
export default Calendar
