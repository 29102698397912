import moment from 'moment'

import { Event, MutationEventUpdateRequest } from '@sportsyou/api'
import { getTimezone } from '@sportsyou/core'

import { CalendarMode, DayProps } from '../calendar-types'

export const handleOnDropEvent = (
  event: Event,
  newDay: DayProps,
  mode: CalendarMode,
  newStartTime?: string,
  newEndTime?: string
):
  | {
      event: Event
      newEvent: Event
      eventRequest: MutationEventUpdateRequest
    }
  | undefined => {
  // const _x = moment(newEndTime)
  // console.log({ _x })

  const startDate = moment(newDay.date).format('MM/DD/YYYY')

  if (mode === 'month') {
    if (moment(startDate).isSame(moment(event.startDate).format('MM/DD/YYYY')))
      return
  }

  // If this is a multi-day event we need to re-calculate the new end date
  const diffInDays = moment(event.endDate).diff(event.startDate, 'days')

  // Format new end date
  const endDate = moment(newDay.date)
    .add(diffInDays, 'days')
    .format('MM/DD/YYYY')

  // Start time
  const startTime = newStartTime
    ? moment(newStartTime, 'h:mma')
    : moment(event.startDate)

  // End time
  const endTime = newEndTime
    ? moment(newEndTime, 'h:mma')
    : moment(event.endDate)

  // endTime = moment(event.endDate)

  // Add updated time to date
  const _newStartDate = moment(startDate)
    .hour(startTime.hour())
    .minute(startTime.minute())
    .toString()

  const _newEndDate = moment(endDate)
    .hour(endTime.hour())
    .minute(endTime.minute())
    .toString()

  // const timezone = moment(event.startDate).format('ZZ')

  const newEvent = {
    ...event,
    endDate: _newEndDate,
    startDate: _newStartDate,
  }

  const eventRequest = {
    event: {
      allDay: event.allDay,
      allUsersCanViewAttendance: event.allUsersCanViewAttendance,
      endDate,
      endTime: endTime.format('h:mma'),
      formattedLocation: event.formattedLocation,
      id: event.id,
      location: event.location,
      locationLat: event.locationLat,
      locationLng: event.locationLng,
      noEndTime: event.noEndTime,
      notes: event.notes,
      repeat: event.repeat,
      repeatInfo: event.repeatInfo,
      startDate,
      startTime: startTime.format('h:mma'),
      teamId: event.teamId ?? '-1',
      teamRoles: event.teamRoles,
      timezone: getTimezone(),
      title: event.title,
      type: event.type,
      uploads: event.uploads,
      // users: '',
    },
  } as MutationEventUpdateRequest

  return {
    event,
    newEvent,
    eventRequest,
  }
}

export default handleOnDropEvent
