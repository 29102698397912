import React, { MouseEvent, useMemo } from 'react'
import { GroupedVirtuoso } from 'react-virtuoso'
import moment from 'moment'
import styled from 'styled-components'

import { Event as EventProps } from '@sportsyou/api'
import { Colors } from '@sportsyou/core'

import { DayProps } from '../calendar-types'

interface YearCalendarProps {
  events: EventProps[]
  fillChipWithGameType?: boolean
  onClickDay?: (date: DayProps, e: React.MouseEvent<HTMLDivElement>) => void
  onClickEvent?: (
    event: EventProps,
    e: React.MouseEvent<HTMLDivElement>
  ) => void
  onDoubleClickDay?: (day: DayProps, e: any) => void
  selectedDate: string
}

export default function YearCalendar(props: YearCalendarProps) {
  const groupedEvents = useMemo(() => {
    return props.events.reduce((acc, event) => {
      const date = moment(event.startDate).format('YYYY-MM-DD')
      if (!acc[date]) {
        acc[date] = []
      }
      acc[date].push(event)
      return acc
    }, {} as { [key: string]: EventProps[] })
  }, [props.events])

  const groupCounts = useMemo(() => {
    return Object.keys(groupedEvents).map((key) => groupedEvents[key].length)
  }, [groupedEvents])

  const renderSectionHeader = (index: number) => {
    const date = Object.keys(groupedEvents)[index]
    const dayProps: DayProps = {
      date: moment(date).toDate(),
      day: moment(date).format('DD'),
      events: groupedEvents[index],
      type: 'current',
    }
    return (
      <SectionHeader
        onClick={(e: MouseEvent<HTMLDivElement>) =>
          props.onClickDay?.(dayProps, e)
        }
        onDoubleClick={(e: MouseEvent<HTMLDivElement>) =>
          props.onDoubleClickDay?.(dayProps, e)
        }
      >
        {moment(date).format('dddd, MMMM D, YYYY')}
      </SectionHeader>
    )
  }

  const renderRow = (index: number, groupIndex: number) => {
    const event = props.events[index]
    if (!event) {
      return null
    }
    let fillColor = event.color ?? Colors.HAVELOCK_BLUE
    if (props.fillChipWithGameType) {
      fillColor = event.type === 'game' ? Colors.PUNCH : Colors.HAVELOCK_BLUE
    }
    return (
      <Row
        onClick={(e: MouseEvent<HTMLDivElement>) =>
          props.onClickEvent?.(event, e)
        }
      >
        <Time>
          {event.allDay ? (
            'All Day'
          ) : (
            <>
              {moment(event.startDate).format('hh:mma')} -{' '}
              {moment(event.endDate).format('hh:mma')}{' '}
            </>
          )}
        </Time>
        <CalendarCircle>
          <CalendarColor fill={fillColor} />
        </CalendarCircle>
        <div>
          <Title>{event.title}</Title>
          {!!event.location && <Location>{event.location}</Location>}
        </div>
      </Row>
    )
  }

  return (
    <Container>
      <GroupedVirtuoso
        groupCounts={groupCounts}
        groupContent={renderSectionHeader}
        itemContent={renderRow}
        useWindowScroll
      />
    </Container>
  )
}

const Container = styled.div`
  border-right: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 13.3333px;
`

const SectionHeader = styled.div`
  background: #eee;
  font-weight: bold;
  padding: 8px 14px;
`

const Row = styled.div`
  align-items: flex-start;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  padding: 8px 14px;

  &:hover {
    background-color: #f5f5f5;
  }
`

const Time = styled.div`
  width: 135px;
`

const CalendarCircle = styled.div`
  width: 25px;
`

const CalendarColor = styled.div<{ fill: string }>`
  background-color: ${({ fill }) => fill};
  border-radius: 50%;
  height: 10px;
  margin-top: 2px;
  width: 10px;
`

const Title = styled.div``

const Location = styled.div`
  color: ${Colors.DUSTY_GRAY};
`
