import * as React from 'react'
import type { SVGProps } from 'react'
const Drag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <circle cx={10.5} cy={28.5} r={3.5} />
    <circle cx={10.5} cy={3.5} r={3.5} />
    <circle cx={10.5} cy={16.5} r={3.5} />
    <circle cx={21.5} cy={28.5} r={3.5} />
    <circle cx={21.5} cy={3.5} r={3.5} />
    <circle cx={21.5} cy={16.5} r={3.5} />
  </svg>
)
export default Drag
