import ContentLayout from './content'

import ContentAside from './modules/aside'
import Content from './modules/content'
import ContentSidebar from './modules/sidebar'

export type ContentLayoutType = typeof ContentLayout & {
  Sidebar: typeof ContentSidebar
  Content: typeof Content
  Aside: typeof ContentAside
}
;(ContentLayout as ContentLayoutType).Sidebar = ContentSidebar
;(ContentLayout as ContentLayoutType).Content = Content
;(ContentLayout as ContentLayoutType).Aside = ContentAside

export type { ContentLayoutProps } from './content'

export default ContentLayout as ContentLayoutType
