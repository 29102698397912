import * as React from 'react'
import type { SVGProps } from 'react'
const Camera = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <g>
      <path d='M16 10.85a6.66 6.66 0 1 0 6.66 6.66A6.67 6.67 0 0 0 16 10.85zm0 11.32a4.66 4.66 0 1 1 4.66-4.66A4.66 4.66 0 0 1 16 22.17z' />
      <path d='M30.73 7.93a4.34 4.34 0 0 0-3.06-1.26h-2.78L22.66 3a2.66 2.66 0 0 0-2.28-1.29h-8.84A2.65 2.65 0 0 0 9.24 3L7.09 6.67H4.33A4.33 4.33 0 0 0 0 11v15a4.33 4.33 0 0 0 4.33 4.33h23.34A4.33 4.33 0 0 0 32 26V11a4.31 4.31 0 0 0-1.27-3.07zM30 26a2.33 2.33 0 0 1-2.33 2.33H4.33A2.33 2.33 0 0 1 2 26V11a2.33 2.33 0 0 1 2.33-2.33h3.34a1 1 0 0 0 .86-.5L11 4a.65.65 0 0 1 .57-.33h8.84A.67.67 0 0 1 21 4l2.52 4.19a1 1 0 0 0 .86.49h3.34a2.3 2.3 0 0 1 1.64.68A2.27 2.27 0 0 1 30 11z' />
    </g>
  </svg>
)
export default Camera
