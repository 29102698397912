import * as React from 'react'
import type { SVGProps } from 'react'
const Plus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M29.53 18.47H18.47v11.06a2.47 2.47 0 1 1-4.94 0V18.47H2.47a2.47 2.47 0 1 1 0-4.94h11.06V2.47a2.47 2.47 0 1 1 4.94 0v11.06h11.06a2.47 2.47 0 0 1 0 4.94z' />
  </svg>
)
export default Plus
