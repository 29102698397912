import { FC, PropsWithChildren, useCallback, useReducer } from 'react'
import { randomId } from '@sportsyou/core'

import { ToastContext } from '../context/toast-context'
import { toastReducer } from '../reducers/toast-reducer'

import { ToastsContainer } from '../components/toast2/toasts-container'
import type {
  ToastItemProps,
  ToastContainerPlacement,
} from '../components/toast2/toast-types'

const initialState = {
  toasts: [],
}

interface Props {
  children?: React.ReactNode
  placement?: ToastContainerPlacement
}

export const ToastProvider: FC<PropsWithChildren<Props>> = ({
  children,
  placement = 'bottomEnd',
}: Props) => {
  const [state, dispatch] = useReducer(toastReducer, initialState)

  const addToast = (props: ToastItemProps) => {
    const id = props.id?.length ? props.id : `toast-${randomId()}`
    dispatch({ type: 'ADD_TOAST', payload: { ...props, id } })
  }

  const removeToast = (id: string) => {
    dispatch({ type: 'DELETE_TOAST', payload: id })
  }

  const sendToast = useCallback((props: ToastItemProps) => {
    addToast(props)
  }, [])

  const value = {
    removeToast,
    sendToast,
  }

  return (
    <ToastContext.Provider value={value}>
      <ToastsContainer toasts={state.toasts} placement={placement} />
      {children}
    </ToastContext.Provider>
  )
}

export default ToastProvider
