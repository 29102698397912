import * as React from 'react'
import type { SVGProps } from 'react'
const NavBubbleFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M10.21 30a.9.9 0 0 1-.42-.1 1 1 0 0 1-.56-.9v-4.79H7.32A5.32 5.32 0 0 1 2 18.89V7.32A5.33 5.33 0 0 1 7.32 2h17.36A5.33 5.33 0 0 1 30 7.32v11.57a5.32 5.32 0 0 1-5.32 5.32h-6.89l-7 5.58a1 1 0 0 1-.58.21z' />
  </svg>
)
export default NavBubbleFilled
