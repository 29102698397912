import * as React from 'react'
import type { SVGProps } from 'react'
const ChevronUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 20'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path
      fillRule='evenodd'
      d='M.595843 17.7845c-.850005-.9333-.782494-2.3789.150782-3.2289L14.4609 2.06507c.8722-.79442 2.2058-.79444 3.0781-.00005L31.2533 14.555c.9333.85 1.0009 2.2956.1509 3.2289-.85.9333-2.2956 1.0009-3.2289.1509L16 6.84646 3.82475 17.9353c-.93327.85-2.3789.7825-3.228907-.1508Z'
      clipRule='evenodd'
    />
  </svg>
)
export default ChevronUp
