import * as React from 'react'
import type { SVGProps } from 'react'
const FabMedia = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M22.2 6H9.8A3.8 3.8 0 0 0 6 9.8v12.4A3.8 3.8 0 0 0 9.8 26h12.4a3.8 3.8 0 0 0 3.8-3.8V9.8A3.8 3.8 0 0 0 22.2 6zM9.8 7.4h12.4a2.39 2.39 0 0 1 2.4 2.4v6.87l-3.54-3.54a1.72 1.72 0 0 0-1.22-.5 1.72 1.72 0 0 0-1.22.5l-4.24 4.24-1-1a1.78 1.78 0 0 0-2.45 0L7.4 20V9.8a2.39 2.39 0 0 1 2.4-2.4zm12.4 17.2H9.8a2.39 2.39 0 0 1-2.4-2.4V22l4.6-4.6a.28.28 0 0 1 .47 0l1.46 1.45a.69.69 0 0 0 1 0l4.73-4.73c.18-.17.31-.17.48 0l4.52 4.53v3.55a2.39 2.39 0 0 1-2.46 2.4z' />
    <path d='M12.12 12.61A1.09 1.09 0 1 0 11 11.53a1.08 1.08 0 0 0 1.12 1.08z' />
  </svg>
)
export default FabMedia
