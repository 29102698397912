import * as React from 'react'
import type { SVGProps } from 'react'
const FileXls = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 537.7 661.8'
    width='1em'
    height='1em'
    style={props.style}
    {...props}
  >
    <path
      fill='none'
      d='M113.7 475.6h20.7v82.7h-20.7zm82.8-124h20.7v206.8h-20.7zm82.7 82.7h20.7v124.1h-20.7zm82.7 41.3h20.7v82.7h-20.7z'
    />
    <path
      fill='none'
      d='M20.7 641.1h455V268.8H227.5a20.74 20.74 0 0 1-20.7-20.7V103.4a20.74 20.74 0 0 1 20.7-20.7h248.2v-62h-455v620.4zm320.5-165.5a20.74 20.74 0 0 1 20.7-20.7h20.7a20.74 20.74 0 0 1 20.7 20.7v82.7a20.74 20.74 0 0 1-20.7 20.7h-20.7a20.74 20.74 0 0 1-20.7-20.7zm-82.7-41.3a20.74 20.74 0 0 1 20.7-20.7h20.7a20.74 20.74 0 0 1 20.7 20.7v124.1a20.74 20.74 0 0 1-20.7 20.7h-20.7a20.74 20.74 0 0 1-20.7-20.7zm-82.7-82.7a20.74 20.74 0 0 1 20.7-20.7h20.7a20.74 20.74 0 0 1 20.7 20.7v206.8a20.74 20.74 0 0 1-20.7 20.7h-20.7a20.74 20.74 0 0 1-20.7-20.7zm-82.7 124a20.74 20.74 0 0 1 20.7-20.7h20.7a20.74 20.74 0 0 1 20.7 20.7v82.7a20.74 20.74 0 0 1-20.7 20.7h-20.7a20.74 20.74 0 0 1-20.7-20.7z'
    />
    <path
      fill='#10884e'
      d='M517 82.7h-20.7v-62A20.74 20.74 0 0 0 475.6 0h-455A20.72 20.72 0 0 0 0 20.7v620.4a20.74 20.74 0 0 0 20.7 20.7h455a20.74 20.74 0 0 0 20.7-20.7V268.8H517a20.74 20.74 0 0 0 20.7-20.7V103.4A20.74 20.74 0 0 0 517 82.7zm-41.4 0H227.5a20.74 20.74 0 0 0-20.7 20.7v144.8a20.74 20.74 0 0 0 20.7 20.7h248.2v372.2h-455V20.7h455v62zm-28.8 100.8c-2.5-1.6-6.3-3.1-11.5-4.5-5.8-1.3-10.6-2.6-14.3-3.8a24.49 24.49 0 0 1-9.6-6.2c-2.7-2.9-4.1-6.8-4.1-11.7a18.62 18.62 0 0 1 3.5-10.9 22.87 22.87 0 0 1 9.9-7.7 36.84 36.84 0 0 1 15-2.8c11.9 0 21.6 3.2 29.2 9.6l-8.9 11.4a29.7 29.7 0 0 0-10.1-6.1 32.78 32.78 0 0 0-11.4-2.1c-3.5 0-6.2.7-8.1 2.2a7.3 7.3 0 0 0-2.9 6 5.86 5.86 0 0 0 3.5 5.5c2.3 1.2 6.1 2.4 11.5 3.8a100.57 100.57 0 0 1 14.2 4.1 25.52 25.52 0 0 1 9.8 6.8c2.7 3.1 4.1 7.2 4.1 12.3a22.24 22.24 0 0 1-7.7 17.5c-5.1 4.4-12.6 6.6-22.3 6.6a54.06 54.06 0 0 1-18.3-2.9 36.73 36.73 0 0 1-13.4-8.1l8.6-12a33 33 0 0 0 10.4 7.3 31.55 31.55 0 0 0 13.4 2.8q6.15 0 9.6-3a9.18 9.18 0 0 0 3.4-7.2 7.31 7.31 0 0 0-3.5-6.9zm-47.9 15.7V212h-47.6v-74.9h16v62.1zm-128.7-62.1h19l17.2 25.6 17-25.6h19.2L317 173.4l27.2 38.5h-19.1l-18.8-27.4-18.8 27.5h-19l27.2-38.4z'
    />
    <path
      fill='#10884e'
      d='M361.9 579h20.7a20.74 20.74 0 0 0 20.7-20.7v-82.7a20.74 20.74 0 0 0-20.7-20.7h-20.7a20.74 20.74 0 0 0-20.7 20.7v82.7a20.68 20.68 0 0 0 20.7 20.7zm0-103.4h20.7v82.7h-20.7zM279.2 579h20.7a20.74 20.74 0 0 0 20.7-20.7v-124a20.74 20.74 0 0 0-20.7-20.7h-20.7a20.74 20.74 0 0 0-20.7 20.7v124.1a20.66 20.66 0 0 0 20.7 20.6zm0-144.7h20.7v124.1h-20.7zM196.5 579h20.7a20.74 20.74 0 0 0 20.7-20.7V351.6a20.74 20.74 0 0 0-20.7-20.7h-20.7a20.74 20.74 0 0 0-20.7 20.7v206.8a20.59 20.59 0 0 0 20.7 20.6zm0-227.4h20.7v206.8h-20.7zM113.7 579h20.7a20.74 20.74 0 0 0 20.7-20.7v-82.7a20.74 20.74 0 0 0-20.7-20.7h-20.7A20.74 20.74 0 0 0 93 475.6v82.7a20.74 20.74 0 0 0 20.7 20.7zm0-103.4h20.7v82.7h-20.7z'
    />
  </svg>
)
export default FileXls
