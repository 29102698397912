import * as React from 'react'
import type { SVGProps } from 'react'
const FileM4v = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 537.8 661.8'
    width='1em'
    height='1em'
    style={props.style}
    {...props}
  >
    <g>
      <path
        fill='#373737'
        d='M517.1 82.7h-20.7v-62A20.74 20.74 0 0 0 475.7 0h-455A20.74 20.74 0 0 0 0 20.7v620.4a20.74 20.74 0 0 0 20.7 20.7h455a20.74 20.74 0 0 0 20.7-20.7V268.9h20.7a20.74 20.74 0 0 0 20.7-20.7V103.4a20.74 20.74 0 0 0-20.7-20.7zm-41.4 0H227.6a20.74 20.74 0 0 0-20.7 20.7v144.8a20.74 20.74 0 0 0 20.7 20.7h248.2v372.2h-455V20.7h455v62zM258.6 579a124.05 124.05 0 1 0-124.1-124 124.06 124.06 0 0 0 124.1 124zm0-227.4A103.4 103.4 0 1 1 155.2 455a103.41 103.41 0 0 1 103.4-103.4zm-30.3 138.2c.1.1.1.2.1.4a5.78 5.78 0 0 0 .5 1.4 12.88 12.88 0 0 0 1.6 1.7 10.62 10.62 0 0 0 3.1 2.1c.1 0 .2.1.3.1a12.28 12.28 0 0 0 3.8.8h.1a11.15 11.15 0 0 0 5.4-1.1l51.4-32.5a4.43 4.43 0 0 0 1.5-1.2 8.41 8.41 0 0 0 1.3-11.8 8 8 0 0 0-1.3-1.3 4.43 4.43 0 0 0-1.5-1.2l-51.4-32.5a11.15 11.15 0 0 0-5.4-1.1h-.1a10 10 0 0 0-3.8.8c-.1 0-.2.1-.3.1a9.45 9.45 0 0 0-3.1 2.1 12.88 12.88 0 0 0-1.6 1.7 12.13 12.13 0 0 0-.5 1.4.6.6 0 0 0-.1.4 9.37 9.37 0 0 0-.6 2.9c0 .3-.2.6-.2.9v62a2.92 2.92 0 0 0 .2.9 14.5 14.5 0 0 0 .6 3z'
      />
      <path
        fill='#fff'
        d='M324 158.1L302.9 212h-7l-21.1-53.9V212h-16v-74.9h22.4l18.2 46.7 18.2-46.7h22.5V212H324zm62.1 38.4h-35.8v-12.6l29.8-46.8h22v46.6h9.7v12.8h-9.7V212h-16zm-21-12.8h21v-31.2zm48.9-46.6h18.2l20.8 57.6 20.7-57.6h18.2L463 212h-20z'
      />
    </g>
  </svg>
)
export default FileM4v
