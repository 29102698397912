import * as React from 'react'
import type { SVGProps } from 'react'
const SearchLine = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path
      fillRule='evenodd'
      d='M14.0462 3.31034c-5.78951 0-10.4828 4.6933-10.4828 10.48276 0 5.7895 4.69329 10.4828 10.4828 10.4828 5.7894 0 10.4827-4.6933 10.4827-10.4828 0-5.78946-4.6933-10.48276-10.4827-10.48276ZM.253052 13.7931C.253052 6.17538 6.42843 0 14.0462 0c7.6177 0 13.7931 6.17538 13.7931 13.7931 0 3.3417-1.1884 6.4058-3.1656 8.7929l6.5885 6.5884c.6464.6464.6464 1.6944 0 2.3408-.6464.6464-1.6944.6464-2.3408 0l-6.6493-6.6493c-2.2966 1.709-5.1432 2.7203-8.2259 2.7203-7.61777 0-13.793148-6.1754-13.793148-13.7931Z'
      clipRule='evenodd'
    />
  </svg>
)
export default SearchLine
