import { DependencyList, useEffect } from 'react'

import { parseUrls } from '@sportsyou/core'
import {
  GetMetadataRequest,
  Post,
  PostMetadata,
  getMetadata,
  queryPost,
} from '@sportsyou/api'
import Subscriptions, {
  SubscriptionTimelineChangedResponse,
  TimelineEntry,
} from '@sportsyou/subscription'
import { useFetchApi, useSubscription } from './'

export interface TimelineUpdateInfo {
  post?: Post
  postMetadata?: PostMetadata[]
  timeline?: TimelineEntry
}

export interface UseTimelineSubscriptionProps {
  onUpdate?: (arg: TimelineUpdateInfo) => void
}

// combines subscription and fetch to get post changes to logged in user's timeline
export function useTimelineSubscription(
  deps?: DependencyList,
  props?: UseTimelineSubscriptionProps
) {
  // hook for getting timeline changes
  const timelineSubscription =
    useSubscription<SubscriptionTimelineChangedResponse>(
      () => {
        return Subscriptions.subscriptionTimelineChanged()
      },
      { deps }
    )

  // hook for fetching post and metadata
  const fetchHook = useFetchApi(async () => {
    // ret value, the payload to send to redux
    const payload: TimelineUpdateInfo = {
      timeline: timelineSubscription.data,
    }

    // fetch the post
    const post = await queryPost({
      id: timelineSubscription.data?.postId ?? '',
    })
    const md: PostMetadata[] = []
    if (post.ok) {
      payload.post = post.data
      // fetch the metadata for the post if any
      if (post.data?.message && !post.data.color) {
        const urls = parseUrls(post.data.message)
        if (urls?.length) {
          for (const url of urls) {
            const req: GetMetadataRequest = { url }
            const resp = await getMetadata(req)
            if (resp?.data) {
              md.push(resp.data)
            }
          }
        }
      }
    }
    payload.postMetadata = md

    // return the payload as an ApiResponse
    return {
      data: payload,
      ok: true,
    }
  })

  // when the timeline subscription comes in, fetch the data and add it to redux
  useEffect(() => {
    const fetchData = async () => {
      // get the data from the hook
      const payload = await fetchHook.fetch()

      // return the data on a callback
      props?.onUpdate?.(payload.data)
    }
    if (timelineSubscription.result?.data?.postId) {
      fetchData()
    }
  }, [
    timelineSubscription.result?.responseId,
    timelineSubscription.result?.data?.postId,
  ])

  // return state information to caller
  return {
    post: fetchHook.data?.post,
    postMetadata: fetchHook.data?.postMetadata,
    result: fetchHook.data,
    start: timelineSubscription.start,
    stop: timelineSubscription.stop,
    timeline: fetchHook.data?.timeline,
  }
}

export default {
  useTimelineSubscription,
}
