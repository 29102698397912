import React, { PropsWithChildren, useRef, useState } from 'react'
import styled from 'styled-components'

import { randomId } from '@sportsyou/core'
import { useClickAway } from '@sportsyou/react-hooks'

import { Placement } from '../../utils/prop-types'

import TooltipContent from './tooltip-content'

interface Props {
  arrowOffset?: number
  contentClassName?: string
  contentStyle?: React.CSSProperties
  className?: string
  enterDelay?: number
  hideArrow?: boolean
  hideDecorator?: boolean
  initialVisible?: boolean
  leaveDelay?: number
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  placement?: Placement
  text?: string | React.ReactNode
  tooltipClassName?: string
  tooltipOffset?: number
  tooltipStyle?: React.CSSProperties
  trigger?: 'click' | 'hover'
}

type NativeAttributes = Omit<React.HTMLAttributes<HTMLSpanElement>, keyof Props>
export type TooltipProps = Props & NativeAttributes

export const Tooltip: React.FC<PropsWithChildren<TooltipProps>> = ({
  arrowOffset = 10,
  tooltipOffset = 8,
  children,
  className = '',
  contentClassName,
  contentStyle,
  enterDelay = 300,
  hideDecorator = false,
  hideArrow = false,
  initialVisible = false,
  leaveDelay = 100,
  onMouseEnter,
  onMouseLeave,
  placement = 'top' as Placement,
  tooltipClassName,
  tooltipStyle,
  text,
  trigger = 'hover',
  ...props
}) => {
  const timer = useRef<number>()
  const ref = useRef<HTMLSpanElement>(null)

  const tooltipId = `tooltip_${randomId()}`

  const [isVisible, setIsVisible] = useState(initialVisible)

  const changeVisibility = (nextState: boolean) => {
    const clearTimer = () => {
      clearTimeout(timer.current)
      timer.current = undefined
    }

    const stateHandler = (nextState: boolean) => {
      setIsVisible(nextState)
      clearTimer()
    }
    clearTimer()

    if (nextState) {
      timer.current = window.setTimeout(() => stateHandler(true), enterDelay)
      return
    }
    const _leaveDelay = trigger === 'click' ? 0 : leaveDelay
    timer.current = window.setTimeout(() => stateHandler(false), _leaveDelay)
  }

  useClickAway(ref, () => trigger === 'click' && changeVisibility(false))

  const handleMouseEvent = (nextState: boolean) => {
    if (nextState) {
      onMouseEnter?.()
    } else {
      onMouseLeave?.()
    }
    trigger === 'hover' && changeVisibility(nextState)
  }

  const handleClickEvent = () => {
    trigger === 'click' && changeVisibility(!isVisible)
  }

  return (
    <Container
      aria-describedby={tooltipId}
      className={className}
      onClick={handleClickEvent}
      onMouseEnter={() => handleMouseEvent(true)}
      onMouseLeave={() => handleMouseEvent(false)}
      ref={ref}
      $trigger={trigger}
      {...props}
    >
      {hideDecorator ? children : <UnderlinedSpan>{children}</UnderlinedSpan>}
      <TooltipContent
        arrowOffset={arrowOffset}
        contentClassName={contentClassName}
        contentStyle={contentStyle}
        hideArrow={hideArrow}
        id={tooltipId}
        isVisible={isVisible}
        parent={ref}
        placement={placement}
        tooltipClassName={tooltipClassName}
        tooltipOffset={tooltipOffset}
        tooltipStyle={tooltipStyle}
      >
        {text}
      </TooltipContent>
    </Container>
  )
}

const Container = styled.span<{ $trigger: TooltipProps['trigger'] }>`
  cursor: ${({ $trigger }) => ($trigger === 'click' ? 'pointer' : 'default')};
  display: inline-block;
  width: max-content;
`
const UnderlinedSpan = styled.span`
  text-decoration: underline;
`

export default Tooltip
