import Tabs from './tabs'
import TabsItem from './tabs-item'
import TabsExtraContent from './tabs-extra-content'

export type TabsType = typeof Tabs & {
  Item: typeof TabsItem
  ExtraContent: typeof TabsExtraContent
}
;(Tabs as TabsType).Item = TabsItem
;(Tabs as TabsType).ExtraContent = TabsExtraContent

export type { TabsProps } from './tabs'
export type { TabsItemProps } from './tabs-item'
export type { TabsExtraContentProps } from './tabs-extra-content'

export default Tabs as TabsType
