import * as React from 'react'
import type { SVGProps } from 'react'
const BubbleSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M9.38 32a1.16 1.16 0 0 1-.48-.11 1.12 1.12 0 0 1-.63-1v-5.5H6.08A6.09 6.09 0 0 1 0 19.31V6.08A6.09 6.09 0 0 1 6.08 0h19.84A6.09 6.09 0 0 1 32 6.08v13.23a6.09 6.09 0 0 1-6.08 6.08H18l-8 6.37a1.12 1.12 0 0 1-.62.24z' />
  </svg>
)
export default BubbleSolid
