import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components'

import { Colors } from '@sportsyou/core'

import SelectMenu from './select-menu'
import SelectOption, { SelectOptionProps } from './select-option'

import {
  BORDER_RADIUS,
  FONT_SIZE_SM,
  FONT_SIZE,
  LINE_HEIGHT,
} from '../../theme'

import Chevron from '../chevron'
import { useClickAway } from '@sportsyou/react-hooks'

export interface SelectProps {
  children?: React.ReactNode
  className?: string
  disabled?: boolean
  displayValue?: string
  filterable?: boolean
  id?: string
  initialValue?: string
  label?: string
  labelStyle?: React.CSSProperties
  menuClassName?: string
  menuStyle?: React.CSSProperties
  optionClassname?: string
  optionStyle?: React.CSSProperties
  placeholder?: string
  selectedTextStyle?: React.CSSProperties
  showValidationMessage?: boolean
  status?: 'error' | 'success'
  style?: React.CSSProperties
  testId?: string
  triggerClassName?: string
  triggerStyle?: React.CSSProperties
  validationClassName?: string
  validationMessage?: string
  validationStyle?: React.CSSProperties
}

export const Select: FC<PropsWithChildren<SelectProps>> = ({
  children,
  className,
  disabled = false,
  displayValue,
  filterable = false,
  id,
  initialValue = '',
  label,
  labelStyle,
  menuClassName,
  menuStyle,
  optionClassname,
  optionStyle,
  placeholder = 'Select Option',
  selectedTextStyle,
  showValidationMessage,
  status,
  style,
  testId,
  triggerClassName,
  triggerStyle,
  validationClassName,
  validationMessage,
  validationStyle,
}: SelectProps) => {
  const selectRef = useRef<HTMLDivElement | null>(null)
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false)

  const [value, setValue] = useState<string>(initialValue)

  const isEmpty = useMemo(() => {
    if (!value) return true
    return value.length === 0
  }, [value])

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const selectOptions = useMemo(
    () =>
      React.Children.map(children, (item) =>
        React.isValidElement(item) && item.type === SelectOption
          ? React.cloneElement(item, {
              ...(item.props as SelectOptionProps),
              // className: optionClassname,
              // style: { ...item.props.style, ...optionStyle },
            })
          : null
      ),
    [children]
  )

  const handleOnChangeValue = (value: string) => {
    // console.log('select change value')
    setValue(value)
    setIsMenuVisible(!isMenuVisible)
  }

  const handleOnClickSelect = () => {
    if (disabled) return
    setIsMenuVisible(!isMenuVisible)
  }

  const getDisplayChildFromValue = (value: string) => {
    if (!value) return null
    const option = selectOptions?.find(
      (option) => (option.props as SelectOptionProps).value === value
    )
    return option ? (option.props as SelectOptionProps).children : null
  }

  useClickAway(selectRef, () => setIsMenuVisible(false))

  return (
    <Container
      className={className}
      data-status={status}
      data-testid={testId}
      ref={selectRef}
      style={style}
    >
      <SelectTrigger
        className={triggerClassName}
        disabled={disabled}
        id={id}
        onClick={handleOnClickSelect}
        style={triggerStyle}
        type='button'
      >
        {label && <LabelText style={labelStyle}>{label}</LabelText>}
        {!isEmpty || value !== '' ? (
          <ValueText style={selectedTextStyle}>
            {displayValue ?? getDisplayChildFromValue(value)}
          </ValueText>
        ) : (
          <PlaceholderText style={selectedTextStyle}>
            {placeholder}
          </PlaceholderText>
        )}
        <StyledChevron
          direction={isMenuVisible ? 'up' : 'down'}
          fill={isMenuVisible ? Colors.SHUTTLE_GRAY : Colors.DUSTY_GRAY}
        />
      </SelectTrigger>

      {showValidationMessage && validationMessage && (
        <ValidationMessage
          className={validationClassName}
          style={validationStyle}
        >
          {validationMessage}
        </ValidationMessage>
      )}

      {!disabled &&
        isMenuVisible &&
        selectOptions &&
        selectOptions.length > 0 && (
          <SelectMenu
            className={menuClassName}
            filterable={filterable}
            onChangeValue={handleOnChangeValue}
            onCloseMenu={() => setIsMenuVisible(false)}
            options={selectOptions}
            selectedValue={value}
            style={menuStyle}
          />
        )}
    </Container>
  )
}

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  max-width: 100%;
  min-height: 60px;
  position: relative;
  width: 300px;
`
const LabelText = styled.div`
  color: ${Colors.PUNCH};
  font-size: ${FONT_SIZE_SM}px;
  font-weight: 700;
  margin: 6px 10px 0;
  text-transform: uppercase;
`
const SelectTrigger = styled.button`
  align-self: stretch;
  background: none;
  border-radius: ${BORDER_RADIUS};
  border: 1px solid ${Colors.ALTO};
  box-sizing: border-box;
  color: ${Colors.MINE_SHAFT};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  font-family: inherit;
  justify-content: center;
  min-width: 300px;
  outline: none;
  overflow: hidden;

  padding-right: 50px;
  padding: 0;
  position: relative;
  text-align: left;
  width: 100%;
`
const SelectTriggerTextStyles = css`
  padding: 6px 10px;
  font-family: inherit;
  font-size: ${FONT_SIZE}px;
  font-weight: 400;
  line-height: ${LINE_HEIGHT};
`
const PlaceholderText = styled.span`
  ${SelectTriggerTextStyles};
  color: ${Colors.DUSTY_GRAY};
`
const ValueText = styled.div`
  ${SelectTriggerTextStyles};
  color: ${Colors.MINE_SHAFT};
`
const StyledChevron = styled(Chevron)`
  margin-top: -10px;
  position: absolute;
  right: 10px;
  top: 50%;
`
const ValidationMessage = styled.div`
  font-size: ${FONT_SIZE_SM}px;
  font-weight: 700;
  margin: 8px 10px 0;
  text-transform: uppercase;

  [data-status='success'] & {
    color: ${Colors.MOUNTAIN_MEADOW};
  }
  [data-status='error'] & {
    color: ${Colors.MONZA};
  }
`

export default Select
