import * as React from 'react'
import type { SVGProps } from 'react'
const FileGeneric = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 502.9 661.7'
    width='1em'
    height='1em'
    style={props.style}
    {...props}
  >
    <g fill='#4a90e2'>
      <path d='M476.6 0H26.4A26.5 26.5 0 0 0 0 26.4v608.9a26.37 26.37 0 0 0 26.4 26.4h450.1a26.37 26.37 0 0 0 26.4-26.4V26.4A26.35 26.35 0 0 0 476.6 0zm0 635.4H26.4V26.5h450.1v608.9z' />
      <path d='M132.3 238.2h238.2v26.4H132.3z' />
      <path d='M132.3 317.7h238.2v26.4H132.3z' />
      <path d='M132.3 397.1h238.2v26.4H132.3z' />
    </g>
  </svg>
)
export default FileGeneric
