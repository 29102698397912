import React, { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import styled, { css } from 'styled-components'

import { Colors, pluralize } from '@sportsyou/core'
import {
  Poster as PosterProps,
  Poll as PollProps,
  PollChoice as PollChoiceProps,
} from '@sportsyou/api'

import FormattedText from '../../formatted-text'

export type PollChoiceReturnData = {
  id: string | null
  isActive: boolean
  isCreator?: boolean | null
  votes: Array<PosterProps | null>
  hasCurrentVote?: boolean | null
}

interface Props {
  id?: string | null
  isCreator?: boolean | null
  poll?: PollProps
  style?: React.CSSProperties
  onClickPollVotes?: (postId: string) => void
  onClickPollChoice?: (data: PollChoiceReturnData, postId: string) => void
  onClickRemoveVote?: (postId: string) => void
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type PostPollProps = Props & NativeAttributes

export const PostPoll = ({
  isCreator,
  id,
  onClickPollChoice,
  onClickPollVotes,
  onClickRemoveVote,
  poll,
  style,
  ...props
}: PostPollProps) => {
  const [isPollActive, setIsPollActive] = useState(true)

  useEffect(() => {
    // check if poll has expired
    checkPollExpiration()
  }, [])

  const numberOfTotalVotes = useMemo(() => {
    if (!poll?.choices) return 0
    return (
      poll?.choices?.reduce(
        (total, choice) => total + (choice?.voteCount || 0),
        0
      ) ?? 0
    )
  }, [poll])

  const hasUserVoted = useMemo(() => {
    if (!poll?.choices) return false
    return (
      poll?.choices?.some(
        (choice: PollChoiceProps | null) => choice?.currentUserVote
      ) ?? false
    )
  }, [poll])

  const currentUserChoice = useMemo(() => {
    if (!poll?.choices) return ''
    return (
      poll?.choices.filter((choice) => choice?.currentUserVote)[0]?.id ?? ''
    )
  }, [poll])

  const checkPollExpiration = () => {
    setIsPollActive(moment().isBefore(moment(poll?.expDate || undefined)))
  }

  const canClickOnChoices = useMemo(() => {
    return !!isCreator || !isPollActive || (isPollActive && !hasUserVoted)
  }, [hasUserVoted, isCreator, isPollActive, poll])

  const handleOnClickPollChoice = useCallback(
    (choice: PollChoiceProps, i: number) => {
      /**
       * Default value on object destructuring doesn't set default value on null value
       * but only on undefined value.
       * It caused votes staying null when poll choice was made and then removed
       * which blocked user from making choice again without refreshing the page.
       */
      const { currentUserVote, id: choiceId } = choice
      let { votes } = choice
      votes ??= []

      if (!canClickOnChoices) return

      onClickPollChoice &&
        choiceId &&
        id &&
        votes &&
        onClickPollChoice(
          {
            hasCurrentVote: currentUserVote,
            id: choiceId,
            isCreator,
            isActive: isPollActive,
            votes,
          },
          id
        )
    },
    [canClickOnChoices, isCreator, id, isPollActive, onClickPollChoice]
  )

  const handleOnClickTotalVotes = () => {
    id && onClickPollVotes?.(id)
  }

  const handleOnClickRemoveVote = () => {
    id && onClickRemoveVote?.(id)
  }

  const renderRightSide = (choice: PollChoiceProps) => {
    if (isCreator || !isPollActive) {
      return (
        <span>
          {choice.voteCount} {choice.voteCount === 1 ? 'Vote' : 'Votes'}
        </span>
      )
    }
    if (!isCreator && choice.currentUserVote && isPollActive) {
      return <span>Your Vote</span>
    }
    return null
  }

  if (!poll) return null

  return (
    <Container>
      {poll.question && (
        <Question>
          <FormattedText>{poll.question}</FormattedText>
        </Question>
      )}
      <PollChoiceContainer>
        {poll.choices?.map((choice: PollChoiceProps | null, i: number) => {
          if (choice) {
            const showBars =
              !!isCreator ||
              !isPollActive ||
              (!!isPollActive && !!choice.currentUserVote)
            /**
             * Math.max() is to avoid divide by zero error
             * which caused blue background to stay when a choice was made and then removed
             */
            const width =
              currentUserChoice === choice.id && isPollActive
                ? '100%'
                : `${
                    ((choice.voteCount ?? 0) /
                      Math.max(numberOfTotalVotes, 1)) *
                    100
                  }%`

            return (
              <PollChoice
                canClick={canClickOnChoices}
                key={`${i}-${choice.id}`}
                onClick={() => handleOnClickPollChoice(choice, i)}
                type='button'
              >
                <span>{choice.desc}</span>
                {renderRightSide(choice)}
                {showBars && <PollFill style={{ width }}></PollFill>}
              </PollChoice>
            )
          }
        })}
        <Footer>
          {(isCreator || !isPollActive) && (
            <TextButton type='button' onClick={handleOnClickTotalVotes}>
              {numberOfTotalVotes} {pluralize(numberOfTotalVotes, 'Vote')}
            </TextButton>
          )}
          {!isCreator && isPollActive && hasUserVoted && (
            <RemoveVoteButton onClick={handleOnClickRemoveVote} type='button'>
              Remove Vote
            </RemoveVoteButton>
          )}
          <PollEndTime>
            {isPollActive
              ? `Ends ${moment(poll.expDate).format('M/D @ h:mm A')}`
              : 'This poll has ended'}
          </PollEndTime>
        </Footer>
      </PollChoiceContainer>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 10px;
  padding-left: 12px;
  padding-right: 12px;
`
const Question = styled.div``
const PollChoiceContainer = styled.div`
  align-items: flex-start;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
`
const PollChoice = styled.button<
  { canClick: boolean } & Pick<Props, 'isCreator'>
>`
  background-color: ${Colors.WHITE};
  border: 1px solid ${Colors.MYSTIC};
  border-radius: 40px;
  // cursor: ${({ isCreator }) => (isCreator ? 'pointer' : 'auto')};
  cursor: ${({ canClick }) => (canClick ? 'pointer' : 'default')};
  box-sizing: border-box;
  font-family: inherit;
  font-size: 14px;
  // padding: 6px 15px;
  padding-left: 15px;
  padding-right: 15px;
  height: 40px;
  align-self: stretch;
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  & + & {
    margin-top: 10px;
  }
  & span {
    position: relative;
    z-index: 1;
  }
`
const PollFill = styled.div`
  background-color: ${Colors.PERANO};
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  bottom: 0;
`
const Footer = styled.div`
  align-items: center;
  color: ${Colors.SHUTTLE_GRAY};
  display: flex;
  align-self: stretch;
  justify-content: space-between;
  font-size: 14px;
  margin-top: 20px;
  min-height: 30px;
`
const ButtonStyles = css`
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  font-family: inherit;
  font-size: 14px;
  height: 30px;
  transition: background-color 160ms ease-in-out;

  &:hover,
  &:active {
    background-color: ${Colors.CATSKILL_WHITE};
  }
`
const TextButton = styled.button`
  ${ButtonStyles};
  color: inherit;
`
const RemoveVoteButton = styled.button`
  ${ButtonStyles};

  color: ${Colors.HAVELOCK_BLUE};
  font-weight: 500;
`
const PollEndTime = styled.span`
  margin-left: auto;
`

export default PostPoll
