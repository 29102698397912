import { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { nanoid } from 'nanoid'
import moment from 'moment'
import styled from 'styled-components'

import { PollInput, Profile } from '@sportsyou/api'
import { Colors, getProfileImage } from '@sportsyou/core'

import Avatar from '../avatar'
import Button from '../button'
import DatePicker from '../date-picker'
import Icon from '../icon'
import TextInput from '../text-input'

// import { useBanner } from '../..'

export interface PollEditorProps {
  className?: string
  currentUser: Profile
  onChange: (poll: PollInput) => void
  poll: PollInput
  style?: CSSProperties
}

interface ChoiceInputProps {
  choice: OptionProps
  index: number
  onChange: (option: OptionProps) => void
}

interface OptionProps {
  key: string
  value: string
}

const ChoiceInput = (props: ChoiceInputProps) => {
  const onChange = useCallback(
    (e: any) => {
      const { value } = e.target
      props.onChange({
        key: props.choice.key,
        value,
      })
    },
    [props.onChange]
  )

  return (
    <TextInput
      containerStyle={{
        flex: 1,
        // marginLeft: 50,
        minHeight: 1,
      }}
      placeholder={'Option ' + props.index}
      value={props.choice.value ?? ''}
      onChange={onChange}
    />
  )
}

export const PollEditor: FC<PollEditorProps> = (props: PollEditorProps) => {
  // const { sendBanner } = useBanner()

  const [choices, setChoices] = useState<OptionProps[]>()
  const [choiceInputs, setChoiceInputs] = useState<string[]>()
  const [isInitialLoad, setIsInitialLoad] = useState(true)

  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false)
      const _choices = props.poll?.choices ?? ['', '']
      setChoiceInputs(_choices.map(() => nanoid()))
    }
  }, [isInitialLoad, props.poll?.choices])

  useEffect(() => {
    setChoices(
      choiceInputs?.map((key, index) => ({
        key,
        value: props.poll?.choices?.[index] ?? '',
      }))
    )
  }, [choiceInputs, props.poll?.choices])

  const onClickAddChoice = useCallback(() => {
    setChoiceInputs([...(choiceInputs ?? []), nanoid()])
    props.onChange({
      ...props.poll,
      choices: [...(props.poll?.choices ?? []), ''],
    })
  }, [props, choiceInputs])

  const onClickRemoveChoice = useCallback(
    (option: OptionProps) => {
      setChoiceInputs(choiceInputs?.filter((key) => key !== option.key))
      const newChoices =
        choices?.filter((choice) => choice.key !== option.key) ?? []
      props.onChange({
        ...props.poll,
        choices: newChoices.map((choice) => choice.value),
      })
    },
    [props, choices]
  )

  const onChangeQuestion = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      props.onChange({
        ...props.poll,
        question: e.target.value,
      })
    },
    [props]
  )

  const onChangeChoice = useCallback(
    (option: OptionProps) => {
      props.onChange({
        ...props.poll,
        choices:
          choices?.map((choice) => {
            if (choice.key === option.key) {
              return option.value
            }
            return choice.value
          }) ?? [],
      })
    },
    [props, choices]
  )

  const onChangeExpDate = useCallback(
    (date: Date) => {
      const minDate = moment().set('minutes', 0).add(2, 'hours')
      if (moment(date).isBefore(minDate)) {
        // TODO: the banner component breaks the post composer
        // sendBanner({
        //   autoDismiss: true,
        //   dismissTime: 3000,
        //   status: 'info',
        //   text: 'End date/time chosen is invalid. End date/time has been reset to minimum allowed end date/time',
        //   type: 'banner',
        // })
        date = minDate.toDate()
      }
      props.onChange({
        ...props.poll,
        expDate: date.toISOString(),
      })
    },
    [props]
  )

  // Add default minimum of 2 choices
  useEffect(() => {
    if (!props.poll.choices?.length) {
      props.onChange({
        ...props.poll,
        choices: ['', ''],
      })
    }
  }, [])

  return (
    <Container className={props.className} style={props.style}>
      <Question>
        <Avatar
          diameter={40}
          name={props.currentUser.fullName ?? ''}
          uri={getProfileImage.getProfileAvatarImageUrl(props.currentUser)}
        />
        <TextInput
          containerStyle={{ marginLeft: 10, flex: 1, minHeight: 1 }}
          onChange={onChangeQuestion}
          placeholder='Ask a question...'
          value={props.poll?.question ?? ''}
        />
      </Question>
      <Choices>
        {choices?.map((choice, index) => (
          <Choice key={choice.key}>
            <ChoiceInput
              choice={choice}
              index={index + 1}
              onChange={onChangeChoice}
            />
            {(choices?.length ?? 0) > 2 && (
              <RemoveChoice
                appearance='minimal'
                collapse
                onClick={() => onClickRemoveChoice(choice)}
                textStyle={{
                  alignItems: 'center',
                  display: 'inline-flex',
                  justifyContent: 'center',
                }}
                variant='alternate'
              >
                <Icon
                  fill={Colors.SHUTTLE_GRAY}
                  name='X'
                  height={18}
                  width={18}
                />
              </RemoveChoice>
            )}
          </Choice>
        ))}
      </Choices>
      {(props.poll?.choices?.length ?? 0) < 5 && (
        <AddChoiceButton appearance='minimal' onClick={onClickAddChoice}>
          + Add Another Option
        </AddChoiceButton>
      )}
      {/* <Options> */}
      <ExpDate>
        <ExpDatePicker
          dateFormat='MM/dd/yyyy h:mm aa'
          id='start'
          label='Poll Ends On'
          name='start'
          onChange={onChangeExpDate}
          selected={
            moment(props.poll?.expDate).toDate() ??
            moment().add(1, 'days').set('hour', 12).set('minutes', 0).toDate()
          }
          shouldCloseOnSelect
          showTimeSelect
        />
      </ExpDate>
      {/* </Options> */}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`
const Question = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
// const QuestionInput = styled(TextInput)`
//   margin-left: 10px;
//   flex: 1;
//   min-height: 1px;
// `
const Choices = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`
const Choice = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  & + & {
    margin-top: 10px;
  }
`
// const StyledChoiceInput = styled(TextInput)`
//   flex: 1;
//   // margin-left: 50px;
//   min-height: 1px;
// `
const RemoveChoice = styled(Button)`
  margin-left: 6px;
  min-height: 1px;
  min-width: 1px;
  padding: 4px;
`
// const Options = styled.div`
//   align-items: flex-start;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
// `
const AddChoiceButton = styled(Button)`
  align-self: flex-start;
  min-height: 1px;
  padding: 6px 10px;
  // cursor: pointer;
  // color: ${Colors.HAVELOCK_BLUE};
  // margin-left: 60px;
`
const ExpDate = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`
const ExpDatePicker = styled(DatePicker)`
  flex: 1;
`
export default PollEditor
