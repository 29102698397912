import ButtonGroup from './button-group'

import ButtonGroupButton from './button-group-button'

export type ButtonGroupType = typeof ButtonGroup & {
  Button: typeof ButtonGroupButton
}
;(ButtonGroup as ButtonGroupType).Button = ButtonGroupButton

export type { ButtonGroupProps } from './button-group'

export default ButtonGroup as ButtonGroupType
