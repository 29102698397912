import { isValidElement, ReactNode } from 'react'

export const getPropsFromReactNode = (item: ReactNode, prop?: string) => {
  if (!isValidElement(item)) return null
  if (prop) {
    return item.props[prop]
  }
  // remove `children` from props
  return Object.fromEntries(
    Object.entries(item.props).filter(([key, _]) => key !== 'children')
  )
}

export default getPropsFromReactNode
