import SocketIOClient from 'socket.io-client'
import { v4 as uuid } from 'uuid'
import obs, { Observable } from '../utils/observable'
import { ServerSession } from '../utils/session-util'
import { mutationLogEvent } from '../api/graphql'

export interface SubscriptionCloseArgs {
  requestId: string
  watchId: string
}

export interface SubscriptionErrorArgs {
  error?: string
  requestId: string
  watchId: string
}

export interface SubscriptionSubscribeArgs {
  requestId: string
  watchId: string
}

export interface SubscriptionData {
  error?: object
  requestId: string
  result?: object
  watchId: string
}

export interface SubscriptionRequestOptions {
  closed?: Observable<SubscriptionCloseArgs>
  data?: Observable<any>
  error?: Observable<SubscriptionErrorArgs>
  fieldList?: string
  onClose?: (response: SubscriptionCloseArgs) => void
  onConnect?: () => void
  onData?: (response: SubscriptionData) => void
  onError?: (response: SubscriptionErrorArgs) => void
  onSubscribed?: (response: SubscriptionSubscribeArgs) => void
  query?: string
  requestId?: string
  retryConnection?: boolean
  sessionState?: ServerSession
  subscribed?: Observable<SubscriptionSubscribeArgs>
  subscriptionName?: string
  watchId?: string
}

export interface SubscriptionRequest {
  requestOptions?: SubscriptionRequestOptions
}

export interface SubscriptionResponse {
  _returnType?: any
  closed: Observable<SubscriptionCloseArgs>
  data: any
  error: Observable<SubscriptionErrorArgs>
  ok: boolean
  requestId?: string | null
  requestString: string
  responseId?: string | null
  subscribed: Observable<SubscriptionSubscribeArgs>
  watchId?: string | null
}

export interface Watch {
  closed: Observable<SubscriptionCloseArgs>
  data: Observable<any>
  error: Observable<SubscriptionErrorArgs>
  onClose?: (response: SubscriptionCloseArgs) => void
  onData?: (response: any) => void
  onError?: (response: SubscriptionErrorArgs) => void
  onSubscribed?: (response?: any) => void
  query: string
  requestId: string
  subscribed: Observable<SubscriptionSubscribeArgs>
  subscriptionName: string
  watchId: string | null
}

export interface WatchMap {
  [requestId: string]: Watch
}

export interface WebSocketSubscriptionResponse {
  errors: any
  watchId: string
}

const SUBSCRIPTION_URL = process.env['NX_WEBSOCKET_SUBSCRIPTIONS_URL'] ?? ''

export class SubscriptionClient {
  private _sessionState?: ServerSession
  private _watchList: {
    index: Map<any, any>
    subs: WatchMap
  }
  private _websocket!: SocketIOClient.Socket

  private constructor() {
    this._watchList = {
      index: new Map(),
      subs: {},
    }
  }

  static getInstance(): SubscriptionClient {
    if (!subscriptions) {
      subscriptions = new SubscriptionClient()
    }
    return subscriptions
  }

  async connect(sessionState?: ServerSession, onConnect?: () => void) {
    if (!this.isConnected()) {
      const url = SUBSCRIPTION_URL
      const options: SocketIOClient.ConnectOpts = {}
      if (sessionState) {
        options.transportOptions = {
          polling: {
            extraHeaders: {
              HEADER_SET_COOKIE: sessionState.cookieString(),
            },
          },
        }
      }
      options.transports = ['websocket']

      this._sessionState = sessionState

      this._websocket = SocketIOClient(url, options)

      return new Promise((resolve, reject) => {
        this._websocket.on('connect', () => {
          console.log('==> Subscriptions', 'connect')
          this.logWatchList('on connect')
          const subs = { ...this._watchList.subs }
          this._watchList?.index?.clear()
          this._watchList.subs = {}

          for (const requestId in subs) {
            const watch = subs[requestId]
            const {
              closed,
              data,
              error,
              onClose,
              onData,
              onError,
              onSubscribed,
              query,
              subscribed,
              subscriptionName,
              watchId,
            } = watch

            if (watchId) {
              this._websocket.emit('unsubscribe', { watchId })
              this._websocket.off(watchId)
            }

            this.subscribe({
              requestOptions: {
                closed,
                data,
                error,
                onClose,
                onData,
                onError,
                onSubscribed,
                query,
                requestId: uuid(),
                retryConnection: false,
                subscribed,
                subscriptionName,
              },
            })
          }

          onConnect?.()
          resolve(null)
        })

        this._websocket.on('connect_error', (connectError: any) => {
          console.log('==> Subscriptions', 'connect_error', connectError)
          onConnect = undefined
          reject(connectError)
        })

        this._websocket.on('disconnect', () => {
          console.log('==> Subscriptions', 'disconnect')
          this.logWatchList('on disconnect')
        })
      })
    }
    return
  }

  disconnect(): void {
    for (const requestId in this._watchList.subs) {
      const watch = this._watchList.subs[requestId]
      watch.watchId && this.unsubscribe(watch.watchId)
    }

    this._watchList.index.clear()
    this._watchList.subs = {}
    this._websocket?.disconnect()
  }

  isConnected(): boolean {
    return !!this._websocket?.connected
  }

  logWatchList(calledFrom: string) {
    const subs = Object.assign({}, this._watchList.subs)
    const keys = Object.keys(subs)
    console.log(
      `this._watchList.subs: ${calledFrom} - ${keys.length} entries`,
      subs
    )
  }

  subscribe<T extends SubscriptionResponse>(
    request: SubscriptionRequest
  ): Promise<T> {
    const { requestOptions = {} } = request
    let { sessionState } = requestOptions
    const {
      closed = obs<SubscriptionCloseArgs>(),
      data = obs<T>(),
      error = obs<SubscriptionErrorArgs>(),
      onClose,
      onData,
      onError,
      onSubscribed,
      query = '',
      requestId = uuid(),
      retryConnection = true,
      subscribed = obs<SubscriptionSubscribeArgs>(),
      subscriptionName = '',
    } = requestOptions

    if (!this._sessionState && sessionState) {
      this._sessionState = sessionState
    } else if (this._sessionState && !sessionState) {
      sessionState = this._sessionState
    }

    const ret = new Promise<SubscriptionResponse>((resolve) => {
      if (this.isConnected()) {
        this._watchList.subs[requestId] = {
          closed,
          data,
          error,
          onClose,
          onData,
          onError,
          onSubscribed,
          query,
          requestId,
          subscribed,
          subscriptionName,
          watchId: null,
        }

        this._websocket.emit(
          'subscribe',
          {
            requestId,
            subscription: query,
          },
          (response: WebSocketSubscriptionResponse) => {
            const { errors, watchId } = response
            if (errors && onError) {
              const errorResp: SubscriptionErrorArgs = {
                error: errors,
                requestId,
                watchId,
              }
              onError(errorResp)
              error.set(errorResp)
              resolve({
                closed,
                data,
                error,
                ok: false,
                requestId,
                requestString: query,
                subscribed,
              })
            } else if (!watchId) {
              const errorResp: SubscriptionErrorArgs = {
                error: 'Subscription not created',
                requestId,
                watchId,
              }
              error.set(errorResp)
              resolve({
                closed,
                data,
                error,
                ok: false,
                requestId,
                requestString: query,
                subscribed,
              })
            } else if (watchId) {
              this._watchList.index.set(watchId, { requestId })
              if (this._watchList.subs[requestId] != null) {
                this._watchList.subs[requestId].watchId = watchId
                resolve({
                  closed,
                  data,
                  error,
                  ok: true,
                  requestId,
                  requestString: query,
                  subscribed,
                  watchId,
                })
              } else {
                mutationLogEvent({
                  event: 'subscriptionDeletedAlready',
                  logMessage: `${JSON.stringify({
                    requestId,
                    watchId,
                    subscription: query,
                    existingSubs: this._watchList.subs,
                  })}`,
                })
              }

              this.logWatchList('subscribe response')
              this._websocket.on(watchId, (subData: any) => {
                if (this._watchList.index.has(watchId)) {
                  if (subData.isClosed || subData.closed) {
                    const closeResp = { requestId: subData.requestId, watchId }
                    closed.set(closeResp)
                    onClose?.(closeResp)
                  } else {
                    const result: T = subData?.result
                    if (result?.data && result?.data[subscriptionName]) {
                      result.data = result.data[subscriptionName]
                    }
                    const dt: T['data'] = result.data
                    const requestId: string = subData.requestId
                    const dataResp = {
                      data: dt,
                      requestId,
                      responseId: uuid(),
                      watchId,
                    }
                    data.set(dataResp as T)
                    onData?.(dataResp)
                  }
                }
              })
              onSubscribed?.({ requestId, watchId })
              subscribed.set({ requestId, watchId })
              resolve({
                closed,
                data,
                error,
                ok: true,
                requestId,
                requestString: query,
                subscribed,
                watchId,
              })
            }
          }
        )
      } else {
        if (retryConnection) {
          if (!request.requestOptions) {
            request.requestOptions = {}
          }

          request.requestOptions.retryConnection = false
          request.requestOptions.subscribed = subscribed
          request.requestOptions.closed = closed
          request.requestOptions.data = data
          request.requestOptions.error = error
          request.requestOptions.subscriptionName = subscriptionName
          this.connect(sessionState, async () => {
            const resp = await this.subscribe<T>(request)
            resolve(resp)
          })
        } else {
          const errorResp = { error: 'Not connected', requestId, watchId: '' }
          error.set(errorResp)
          resolve({
            closed,
            data,
            error,
            ok: false,
            requestId,
            requestString: query,
            subscribed,
          })
        }
      }
    })

    return ret as Promise<T>
  }

  unsubscribe(watchId: string) {
    if (this._watchList.index.has(watchId)) {
      const { requestId } = this._watchList.index.get(watchId)
      this._websocket.emit('unsubscribe', { watchId })
      this._websocket.off(watchId)
      this._watchList.index.delete(watchId)
      requestId && delete this._watchList.subs[requestId]
      this.logWatchList('unsubscribe ' + requestId)
    }
  }
}

// eslint-disable-next-line no-var
var subscriptions = SubscriptionClient.getInstance()
export default subscriptions
