import { Children, ReactNode, isValidElement } from 'react'

/**
 * Helper function to retrieve props.children from a ReactNode
 *
 * An optional callback function can be passed to trigger a recrusive map of
 * all props.children
 * @param item ReactNode to retrieve props.children from
 * @param callback Function to apply/trigger recrusive map if necessary
 * @returns Array of children as ReactNodes
 */
const getChildrenFromReactNode = (
  item: ReactNode,
  callback?: () => void
): any[] => {
  const recrusiveMap: any = (
    children: ReactNode,
    fn: (child: ReactNode) => void
  ) =>
    Children.map(children, (child: ReactNode) => {
      if (!isValidElement(child)) return child
      return child.props.children
        ? [fn(child), recrusiveMap(child.props.children, fn)]
        : fn(child)
    })

  return callback
    ? recrusiveMap(item, callback)
    : !isValidElement(item)
    ? item
    : item.props.children
}

export default getChildrenFromReactNode
