import { StaticApiRequest, get } from '../api-base'

export interface GetQRCodeResponse {
  base64?: string
  error?: any
  ok: boolean
  response?: any
}

export async function getQRCode(teamId: string): Promise<GetQRCodeResponse> {
  // TODO: fix error with async
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const req: StaticApiRequest = {
      action: 'getTeamSignupPdf',
      qrCodeOnly: true,
      teamId,
    }
    try {
      const response = (await get(req, { isBlob: true })) as Response
      const blob = await response.blob()
      const fileReader = new FileReader()
      fileReader.onload = async () => {
        resolve({
          base64: fileReader.result as string,
          ok: true,
          response,
        })
      }
      fileReader.readAsDataURL(blob)
    } catch (ex) {
      reject({ ok: false, error: ex })
    }
  })
}

export default {
  getQRCode,
}
