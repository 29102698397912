import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'
import { Event as EventProps, Timezone } from '@sportsyou/api'
import { X } from '@sportsyou/react-icons'

import Button from '../button'
import Popover from '../popover'

type Attendance = 'yes' | 'no' | 'maybe'

interface Props {
  defaultTimezone?: Timezone
  event?: EventProps
  onClickDeleteEvent?: (event: EventProps) => void
  onClickSaveAttendance?: (event: EventProps, attendance: string) => void
  onClickViewEvent?: (event: EventProps) => void
  onClose?: () => void
  open: boolean
  setOpen: (open: boolean) => void
  showBorder?: boolean
  target: React.MutableRefObject<HTMLElement | null>
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type CalendarPopoverProps = Props & NativeAttributes

export default function CalendarPopover(props: CalendarPopoverProps) {
  const { defaultTimezone, event, open, setOpen, showBorder, target } = props

  const contentRef = useRef<HTMLDivElement | null>(null)

  const [currentAttendance, setCurrentAttendance] = useState<Attendance>()
  const [eventTimeText1, setEventTimeText1] = useState('')
  const [eventTimeText2, setEventTimeText2] = useState('')
  const [timezoneLabel, setTimezoneLabel] = useState('')

  useEffect(() => {
    function setEventTimeString() {
      if (!props.event) return

      const { allDay, endDate, noEndTime, startDate } = props.event

      if (allDay) {
        setEventTimeText1(moment(startDate).format('ddd, MMMM DD'))
      } else if (noEndTime) {
        setEventTimeText1(moment(startDate).format('ddd, MMMM DD – h:mma'))
      } else if (startDate && endDate) {
        const startDay = moment(startDate).format('YYYY-MM-DD')
        const endDay = moment(endDate).format('YYYY-MM-DD')

        if (startDay === endDay) {
          setEventTimeText1(
            `${moment(startDate).format('ddd, MMMM DD, h:mma')} – ${moment(
              endDate
            ).format('h:mma')}`
          )
        } else {
          // multi-day event
          setEventTimeText1(
            `${moment(startDate).format('ddd, MMMM DD, h:mma')} –`
          )
          setEventTimeText2(`${moment(endDate).format('ddd, MMMM DD, h:mma')}`)
        }
      } else if (startDate) {
        setEventTimeText1(moment(startDate).format('ddd, MMMM DD – h:mma'))
      }
    }

    if (props.event) {
      setCurrentAttendance(props.event.attending as Attendance)
      setEventTimeString()
    }
  }, [props.event])

  useEffect(() => {
    setTimezoneLabel(defaultTimezone?.displayName ?? event?.timezoneName ?? '')
  }, [defaultTimezone, event?.timezoneName])

  const onClickAttendanceYes = useCallback(() => {
    setCurrentAttendance('yes')
    props.onClickSaveAttendance?.(event!, 'yes')
  }, [props.onClickSaveAttendance, event])

  const onClickAttendanceNo = useCallback(() => {
    setCurrentAttendance('no')
    props.onClickSaveAttendance?.(event!, 'no')
  }, [props.onClickSaveAttendance, event])

  const onClickAttendanceMaybe = useCallback(() => {
    setCurrentAttendance('maybe')
    props.onClickSaveAttendance?.(event!, 'maybe')
  }, [props.onClickSaveAttendance, event])

  const onClickViewEvent = useCallback(() => {
    props.onClickViewEvent?.(event!)
  }, [props.onClickViewEvent, event])

  const onClickDeleteEvent = useCallback(() => {
    props.onClickDeleteEvent?.(event!)
    close()
  }, [props.onClickDeleteEvent, event])

  const close = useCallback(() => {
    props.onClose?.()
  }, [props.onClose])

  return (
    <Popover
      borderColor={event?.color ?? 'transparent'}
      open={open}
      setOpen={setOpen}
      showBorder={showBorder ?? false}
      target={target}
    >
      {!!event && (
        <Content ref={contentRef}>
          <Header>
            <CalendarDetails>
              <CalendarTitle>{event.title}</CalendarTitle>
              <CalendarNameContainer>
                <CalendarColor fill={event.color ?? 'transparent'} />
                <CalendarName>
                  {event.teamId ? event.teamName : 'Personal Calendar'}
                </CalendarName>
              </CalendarNameContainer>
            </CalendarDetails>
            <CloseButton
              appearance='minimal'
              collapse
              onClick={close}
              variant='alternate'
            >
              <X />
            </CloseButton>
          </Header>

          <Row>
            <Label>When</Label>
            <div>
              <Text>{eventTimeText1}</Text>
              <Text>{eventTimeText2}</Text>
              <Text>{timezoneLabel}</Text>
            </div>
          </Row>

          {!!event.location && (
            <Row>
              <Label>Where</Label>
              <LocationInfo>
                <Text>{event.location}</Text>
                <MapButton
                  href={`https://www.google.com/maps/search/?api=1&query=${event.location}`}
                  target={'_blank'}
                >
                  Map
                </MapButton>
              </LocationInfo>
            </Row>
          )}

          {!event.isLargeTeam && (
            <Row>
              <Label style={{ alignSelf: 'center' }}>Attending?</Label>
              <AttendanceButtons>
                <SmallTextButton
                  appearance='minimal'
                  collapse
                  onClick={onClickAttendanceYes}
                  variant={
                    currentAttendance === 'yes' ? 'primary' : 'alternate'
                  }
                >
                  Yes
                </SmallTextButton>
                <SmallTextButton
                  appearance='minimal'
                  collapse
                  onClick={onClickAttendanceNo}
                  variant={currentAttendance === 'no' ? 'primary' : 'alternate'}
                >
                  No
                </SmallTextButton>
                <SmallTextButton
                  appearance='minimal'
                  collapse
                  onClick={onClickAttendanceMaybe}
                  variant={
                    currentAttendance === 'maybe' ? 'primary' : 'alternate'
                  }
                >
                  Maybe
                </SmallTextButton>
              </AttendanceButtons>
            </Row>
          )}
          <ActionButton onClick={onClickViewEvent}>
            {event.isEditable ? 'Edit or View' : 'View'} Event
          </ActionButton>
          {event.isEditable && (
            <ActionButton
              appearance='ghost'
              data-testid='delete-event'
              onClick={onClickDeleteEvent}
              variant='danger'
            >
              Delete Event
            </ActionButton>
          )}
        </Content>
      )}
    </Popover>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
  max-width: 100%;
`

const Header = styled.header`
  border-bottom: 1px solid ${Colors.ALTO};
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 20px;
`

const CloseButton = styled(Button)`
  align-self: flex-start;
  border-radius: 50%;
  flex: 0 0 auto;
  margin-left: 10px;

  span {
    align-items: center;
    display: flex;
    justify-content: center;
  }
`

const CalendarDetails = styled.div``
const CalendarTitle = styled.h3`
  margin-bottom: 0;
  margin-top: 0;
`

const CalendarNameContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: 4px;
`

const CalendarColor = styled.div<{ fill: string }>`
  background-color: ${({ fill }) => fill};
  border-radius: 50%;
  height: 12px;
  width: 12px;
`

const CalendarName = styled.p`
  font-size: 14px;
  margin-bottom: 0;
  margin-left: 10px;
  margin-top: 0;
`

const Row = styled.div`
  align-items: flex-start;
  display: flex;
  font-size: 14px;

  & + & {
    margin-top: 10px;
  }
`

const Label = styled.p`
  color: ${Colors.PUNCH};
  flex: 0 0 38%;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
`

const Text = styled.p`
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 0;
`

const SmallTextButton = styled(Button)`
  & + & {
    margin-left: 4px;
  }
`

const ActionButton = styled(Button)`
  margin-top: 20px;
  & + & {
    margin-top: 10px;
  }
`

const AttendanceButtons = styled.div`
  align-items: 'center';
  display: 'flex';
`

const LocationInfo = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const MapButton = styled.a`
  color: ${Colors.HAVELOCK_BLUE};
  padding-left: 5px;
  text-decoration: none;
`
