import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { Button, TextArea, useDialog } from '@sportsyou/react-dom-ui'
import { Community, Upload } from '@sportsyou/api'

// import { fetchPages } from '../../../../store/slices/CommunitiesSlice'
import ProfileHeader from './Header'
import usePagesPortal from '../UsePagesPortal'

interface Props {
  page: Community
}

export default function PagesContentPortalProfileSettings(props: Props) {
  const { sendBanner } = useDialog()
  const dispatch = useDispatch()

  const {
    page,
    update: updatePage,
    updateInRedux,
  } = usePagesPortal({
    communityId: props.page?.id as string,
  })

  const [about, setAbout] = useState<string>(page.about || '')
  const [avatarImageSrc, setAvatarImageSrc] = useState(
    page.profileImage?.[0]?.viewUrl as string
  )
  const [coverImageSrc, setCoverImageSrc] = useState(
    page.coverImage?.[0]?.viewUrl as string
  )

  const onClickSave = useCallback(async () => {
    await updatePage({
      community: {
        // communityUrl: community.communityUrl,
        // postsRequireApproval: community.postsRequireApproval,
        about,
        id: page.id,
        name: page.name ?? 'Untitled',
      },
    })
    sendBanner({
      message: 'Saved',
      autoDismiss: true,
    })
  }, [about, page.id, page.name, sendBanner, updatePage])

  const onUploadTranscodeComplete = useCallback(
    (upload?: Upload, type?: string) => {
      if (upload?.viewUrl) {
        if (type === 'avatar') {
          setAvatarImageSrc(upload.viewUrl)
        } else if (type === 'cover-image') {
          setCoverImageSrc(upload.viewUrl)
        }
      }

      if (!upload?.transcodes?.[0]) return

      if (type === 'avatar') {
        updateInRedux({ profileImage: [upload.transcodes[0]] })
      } else if (type === 'cover-image') {
        updateInRedux({ coverImage: [upload.transcodes[0]] })
      }

      // dispatch(fetchPages())
    },
    [updateInRedux]
  )

  useEffect(() => {
    setAbout(page.about || '')
  }, [page.about])

  return (
    <div>
      <ProfileHeader
        avatarImageSrc={avatarImageSrc}
        canEditCoverImage={!!page.canEdit}
        coverImageSrc={coverImageSrc}
        name={page.name ?? undefined}
        onTranscodeComplete={onUploadTranscodeComplete}
        profileId={page.id!}
        type='page'
      />
      {/* <TextInput
        disabled
        label="Page Name"
        value={community.name ?? 'Untitled'}
      />
      <hr />
      <TextInput
        disabled
        label="Page URL"
        value={'/pages/' + community.communityUrl}
      /> */}
      <FormContainer>
        {page.canEdit ? (
          <>
            <TextArea
              label='About'
              onChange={(e) => setAbout(e.target.value)}
              value={about}
            />
            <Button onClick={onClickSave}>Save</Button>
          </>
        ) : (
          <div>{page.about}</div>
        )}
      </FormContainer>
    </div>
  )
}

const FormContainer = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;

  > button {
    margin-top: 20px;
    align-self: flex-start;
  }
`
