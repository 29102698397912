import * as React from 'react'
import type { SVGProps } from 'react'
const FolderLine = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path
      fillRule='evenodd'
      d='m18.8789 9.50413-3.3654-3.60438c-.1004-.10825-.2765-.19464-.4739-.19464H3.87016c-.35861 0-.65504.29642-.65504.65505V25.6398c0 .3587.29643.6551.65504.6551H28.1298c.3587 0 .6551-.2964.6551-.6551V10.1592c0-.35864-.2964-.65507-.6551-.65507h-9.2509Zm9.2509-3.21512c.0048 0 .0095.00001.0143.00003C30.2718 6.29673 32 8.02964 32 10.1592v15.4806c0 2.1343-1.7359 3.8702-3.8702 3.8702H3.87016C1.73587 29.51 0 27.7741 0 25.6398V6.36016c0-2.13429 1.73587-3.87017 3.87016-3.87017H15.0396c1.0671 0 2.1058.44109 2.8315 1.22366l2.4046 2.57536h7.8541Z'
      clipRule='evenodd'
    />
  </svg>
)
export default FolderLine
