import * as React from 'react'
import type { SVGProps } from 'react'
const BadgeVerified = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    style={props.style}
    {...props}
  >
    <g clipPath='url(#a)'>
      <path
        fill='#4A90E2'
        d='M14.2869 1.10338c.8323-1.1895976 2.5939-1.1895998 3.4261 0l1.7016 2.4322c.5042.72069 1.4055 1.04874 2.255.82075l2.8669-.76942c1.4022-.37634 2.7517.756 2.6244 2.20225l-.2599 2.95693c-.0769.87615.4027 1.70681 1.1999 2.07821l2.6908 1.2533c1.3161.6131 1.622 2.3479.5948 3.3741l-2.0996 2.0981c-.6222.6216-.7888 1.5662-.4167 2.3632l1.2555 2.6898c.6142 1.3154-.2668 2.841-1.7131 2.9671l-2.957.2573c-.8762.0763-1.6111.6929-1.8384 1.5427l-.767 2.8673c-.3753 1.4027-2.0306 2.0052-3.2196 1.1718l-2.4308-1.7034c-.7202-.5049-1.6794-.5049-2.3997 0l-2.4308 1.7034c-1.1889.8334-2.84427.2309-3.21946-1.1718l-.7671-2.8673c-.2273-.8498-.96206-1.4664-1.83827-1.5427l-2.95714-.2573c-1.44634-.1261-2.32715-1.6517-1.71305-2.9671l1.25557-2.6898c.37203-.797.20547-1.7415-.41671-2.3632L.613367 15.4517c-1.027009-1.0262-.721112-2.761.594933-3.3741l2.69074-1.2533c.79727-.3714 1.27685-1.20206 1.19985-2.07821l-.25991-2.95693c-.12713-1.44625 1.22234-2.57859 2.62454-2.20225l2.86688.76942c.8495.22799 1.7508-.10006 2.255-.82075l1.7015-2.4322Z'
      />
      <path
        fill='#fff'
        d='m22.0007 14.3105-6.4861 6.9617c-.095.1018-.2067.181-.3302.2352-.1235.0543-.2551.0813-.3882.0796-.1331-.0016-.266-.033-.3882-.0903-.1223-.0575-.2325-.1384-.3252-.2425l-4.11116-4.6063c-.0326-.0465-.0617-.0938-.08794-.1448-.13955-.211-.20511-.4679-.18581-.7273.01929-.2594.1214-.5037.29033-.6875.18958-.2002.44288-.3141.70678-.3148.1354-.0004.2702.0292.3948.0869.1247.0576.238.1432.3319.2496l2.238 2.504 1.168 1.299 5.7594-6.1946c.1446-.1489.3252-.2479.5209-.2859.1959-.0379.3981-.013.5808.0724.1827.0854.3382.2281.4478.4089.1098.1808.1685.3911.1693.6079-.0008.1468-.0288.2918-.0813.427-.0525.1351-.1279.2591-.2239.3618Z'
      />
    </g>
    <defs>
      <clipPath>
        <path fill='#fff' d='M0 0h32v32H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default BadgeVerified
