import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import {
  MutationPostCreateArgs,
  MutationPostUpdateArgs,
  MutationPostUpdateScheduledArgs,
  Team,
  Upload,
  User,
} from '@sportsyou/api'

import Backdrop from '../backdrop'
import CreatePostInput from '../create-post-input'
import Transition from '../css-transition'

import PostComposer, {
  InitialPostProps,
  PostComposerInitialView,
} from '../post-composer/post-composer'

import { getBoundingBox } from '../../utils'

export interface PostCreatorProps {
  allowScrolling?: boolean
  /** Classname of the `CreatePostInput` component */
  buttonClassName?: string
  /** Custom styles of the `CreatePostInput` component */
  buttonStyle?: React.CSSProperties
  canCreatePoll?: boolean
  /** Classname of the `PostComposer` component */
  composerClassName?: string
  /** Style of the `PostComposer` textarea component */
  composerMessageContainerStyle?: React.CSSProperties
  /** Custom styles of the `PostComposer` component */
  composerStyle?: React.CSSProperties

  /** The current `User` logged in */
  currentUser: User

  initialPost?: InitialPostProps
  initialUploads?: Array<Upload>
  initialView?: PostComposerInitialView

  onClose?: (post?: InitialPostProps) => void
  onCreatePost?: (post: MutationPostCreateArgs) => void
  /** Function to run when post composer opens */
  onOpen?: () => void
  onUpdatePost?: (post: MutationPostUpdateArgs) => void
  onUpdateScheduledPost?: (post: MutationPostUpdateScheduledArgs) => void

  /** Composer placeholder text */
  placeholder?: string

  /** ID associated with the feed that the post creator might be attached to */
  teamId?: string

  /** The teams and groups `currentUser` is a part of */
  teams: Array<Team>

  isCurrentUserProfile?: boolean
}

const SMALL_SCREEN_CONTAINER_HEIGHT = 650
const CONTAINER_HEIGHT = 550

/**
 * The `PostCreator` is a variation of `PostComposer` that combines the
 * `CreatePostInput` and the `PostComposer` into one component. It is used to
 * attempt to mimic the "growing" and "shrinking" composer on Web-v1.
 *
 * @todo Rewrite original `PostComposer` in a way that it can handle being both a standalone or combined component
 *
 * @param {User} currentUser - The current `User` logged in
 * @param {Array<Team>} teams - The teams and groups `currentUser` is a part of
 */
export const PostCreator = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<PostCreatorProps>
>(
  (
    {
      allowScrolling,
      buttonClassName,
      buttonStyle,
      canCreatePoll,
      composerClassName,
      composerMessageContainerStyle,
      composerStyle,
      currentUser,
      initialPost,
      initialUploads,
      initialView,
      isCurrentUserProfile,

      onClose,
      onCreatePost,
      onOpen,
      onUpdatePost,
      onUpdateScheduledPost,
      placeholder = 'Share a post',

      // composerOffset,
      teamId,
      teams,
    }: PostCreatorProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const createPostRef = useRef<HTMLDivElement | null>(null)
    const mQuery = window.matchMedia(`(max-width: 1199px)`)

    const [containerHeight, setContainerHeight] = useState<number>()
    const [isComposerVisible, setIsComposerVisible] = useState(false)
    const [isSmallScreen, setIsSmallScreen] = useState(mQuery.matches)
    const [isTransitioning, setIsTransitioning] = useState(false)
    const [postInitialType, setPostInitialType] = useState<
      PostComposerInitialView | undefined
    >(initialView)

    useEffect(() => {
      if (isComposerVisible) {
        // setTimeout(() => {
        //   document.getElementById('post-composer')?.scrollIntoView({
        //     behavior: 'smooth',
        //     block: 'start',
        //   })
        // }, 10)
        setContainerHeight(
          isSmallScreen ? SMALL_SCREEN_CONTAINER_HEIGHT : CONTAINER_HEIGHT
        )
        onOpen?.()
      } else {
        setContainerHeight(getBoundingBox(createPostRef).height)
      }
    }, [isComposerVisible, isSmallScreen, onOpen])

    useEffect(() => {
      mQuery.addEventListener('change', (e) => setIsSmallScreen(e.matches))
      return () => {
        mQuery.removeEventListener('change', (e) => setIsSmallScreen(e.matches))
      }
    }, [mQuery])

    const handleOnClickInput = (
      view?: PostComposerInitialView,
      id?: string
    ) => {
      if (view) {
        setPostInitialType(view)
        setIsComposerVisible(!isComposerVisible)
      } else {
        setPostInitialType(undefined)
        setIsComposerVisible(!isComposerVisible)
      }
    }

    const handleOnCreatePost = useCallback(
      (post: MutationPostCreateArgs) => {
        onCreatePost && onCreatePost(post)
        setIsComposerVisible(false)
      },
      [onCreatePost]
    )
    const handleOnUpdatePost = useCallback(
      (post: MutationPostUpdateArgs) => {
        onUpdatePost && onUpdatePost(post)
        setIsComposerVisible(false)
      },
      [onUpdatePost]
    )
    const handleOnUpdateScheduledPost = useCallback(
      (post: MutationPostUpdateScheduledArgs) => {
        onUpdateScheduledPost?.(post)
        setIsComposerVisible(false)
      },
      [onUpdateScheduledPost]
    )

    const handleOnCloseComposer = (post?: InitialPostProps) => {
      onClose && onClose(post)
      setIsComposerVisible(false)
      setIsTransitioning(false)
    }

    return (
      <Container ref={ref} style={{ height: containerHeight }}>
        <InputContainer>
          <CreatePostInput
            canCreatePoll={!!canCreatePoll}
            className={buttonClassName}
            currentUser={currentUser}
            inputButtonStyle={{ marginLeft: 7 }}
            onClickAddColor={(id) => handleOnClickInput('color', id)}
            onClickAddFile={(id) => handleOnClickInput('attachment', id)}
            onClickAddPhoto={(id) => handleOnClickInput('media', id)}
            onClickAddPoll={(id) => handleOnClickInput('poll', id)}
            onClickInput={(id) => handleOnClickInput(undefined, id)}
            ref={createPostRef}
            style={buttonStyle}
          />
        </InputContainer>

        <Backdrop
          allowScrolling={allowScrolling}
          visible={isComposerVisible}
          style={{ zIndex: 910 }}
        />

        <Transition
          name='composer'
          visible={isComposerVisible}
          clearTime={600}
          enterTime={0}
          leaveTime={0}
          onStart={() => {
            setIsTransitioning(true)
          }}
          onEnd={() => {
            setIsTransitioning(false)
          }}
        >
          <StyledPostComposer
            canCreatePoll={canCreatePoll}
            className={composerClassName}
            currentUser={currentUser}
            initialPost={initialPost}
            initialUploads={initialUploads}
            initialView={postInitialType}
            isCurrentUserProfile={isCurrentUserProfile}
            isModalVisible={isComposerVisible}
            isTransitioning={isTransitioning}
            onClose={handleOnCloseComposer}
            onCreatePost={handleOnCreatePost}
            onUpdatePost={handleOnUpdatePost}
            onUpdateScheduledPost={handleOnUpdateScheduledPost}
            placeholder={placeholder}
            postingToStyle={{ flex: '1 1 auto', overflow: 'auto' }}
            messageContainerStyle={{
              padding: '18px 10px',
              ...composerMessageContainerStyle,
            }}
            parent={createPostRef}
            style={{
              left: 0,
              position: 'relative',
              top: 0,
              marginTop:
                (createPostRef.current?.getBoundingClientRect().height ?? 0) *
                -1,
              width: 'auto',
              zIndex: 920,
              ...composerStyle,
            }}
            teamId={teamId}
            teams={teams}
          />
        </Transition>
      </Container>
    )
  }
)

const Container = styled.div`
  position: relative;
  transition: height 600ms ease-in-out;
`
const InputContainer = styled.div`
  &.input-enter {
    opacity: 0;
    height: 0;
    visibility: hidden;
  }
  &.input-enter-active {
    visibility: visible;
    height: 70px;
    opacity: 1;
  }
  &.input-leave {
    visibility: visible;
    height: 70px;
    opacity: 1;
  }
  &.input-leave-active {
    opacity: 0;
    height: 0;
    visibility: hidden;
  }
`
const StyledPostComposer = styled(PostComposer)<{ isTransitioning: boolean }>`
  overflow: ${(props) => (props.isTransitioning ? 'hidden' : 'auto')};
  transition: all 600ms ease-in-out;
  &.composer-enter {
    opacity: 0;
    height: 50px;
  }
  &.composer-enter-active {
    height: ${CONTAINER_HEIGHT}px;
    opacity: 1;
    @media all and (max-width: 1199px) {
      height: ${SMALL_SCREEN_CONTAINER_HEIGHT}px;
    }
  }
  &.composer-leave {
    height: ${CONTAINER_HEIGHT}px;
    opacity: 1;
    @media all and (max-width: 1199px) {
      height: ${SMALL_SCREEN_CONTAINER_HEIGHT}px;
    }
  }
  &.composer-leave-active {
    height: 0;
    opacity: 0;
  }
`

export default PostCreator
