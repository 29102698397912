import * as React from 'react'
import type { SVGProps } from 'react'
const FabUpload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M23.24 6h-2.07a.7.7 0 0 0 0 1.4h2.07a1.36 1.36 0 0 1 1.36 1.37v9.63h-3.81a1.73 1.73 0 0 0-1.56 1l-.46.93a.35.35 0 0 1-.3.18h-4.93a.3.3 0 0 1-.3-.19l-.46-.92a1.73 1.73 0 0 0-1.55-1H7.4V8.77A1.37 1.37 0 0 1 8.77 7.4h2.06a.7.7 0 0 0 0-1.4H8.77A2.77 2.77 0 0 0 6 8.77v14.47A2.77 2.77 0 0 0 8.77 26h14.47A2.77 2.77 0 0 0 26 23.24V8.77A2.77 2.77 0 0 0 23.24 6zm1.36 17.24a1.36 1.36 0 0 1-1.36 1.36H8.77a1.36 1.36 0 0 1-1.37-1.36V19.8h3.83a.35.35 0 0 1 .3.18l.46.93a1.72 1.72 0 0 0 1.54 1h4.94a1.73 1.73 0 0 0 1.55-1l.46-.93a.27.27 0 0 1 .3-.18h3.82z' />
    <path d='M12.29 11.11a.67.67 0 0 0 .49-.21l2.51-2.51V16a.7.7 0 0 0 1.4 0V8.37l2.53 2.53a.7.7 0 0 0 1-1l-3.73-3.7A.84.84 0 0 0 16 6a.64.64 0 0 0-.51.24L11.8 9.92a.68.68 0 0 0 0 1 .67.67 0 0 0 .49.19z' />
  </svg>
)
export default FabUpload
