import { CSSProperties, FC, PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Colors } from '@sportsyou/core'

export interface ModalFooterProps {
  children?: React.ReactNode
  className?: string
  style?: CSSProperties
}

export const ModalFooter: FC<PropsWithChildren<ModalFooterProps>> = ({
  children,
  className,
  style,
}: ModalFooterProps) => {
  return (
    <Footer className={className} style={style}>
      {children}
    </Footer>
  )
}

const Footer = styled.footer`
  border-top: 1px solid ${Colors.ALTO};
  flex: 0 0 auto;
  padding: 8px 12px;
`

export default ModalFooter
