import { MouseEvent, useMemo } from 'react'
import moment from 'moment'
import styled from 'styled-components'

import { Event as EventProps, MutationEventUpdateRequest } from '@sportsyou/api'
import { Colors } from '@sportsyou/core'

import DayEventList, {
  EventContainer,
  EventLabel,
  LeftColumnLabels,
} from './day-event-list'

import { DayProps } from '../calendar-types'

export interface DayCalendarProps {
  events: EventProps[]
  fillChipWithGameType?: boolean
  onClickDay?: (day: DayProps, e: React.MouseEvent<HTMLDivElement>) => void
  onClickEvent?: (
    event: EventProps,
    e: React.MouseEvent<HTMLDivElement>
  ) => void
  onDoubleClickDay?: (
    day: DayProps,
    e: React.MouseEvent<HTMLDivElement>
  ) => void
  onDropEvent?: (
    originalEvent: EventProps,
    newEvent: EventProps,
    newEventRequest: MutationEventUpdateRequest
  ) => void
  selectedDate: string
}

export default function DayCalendar(props: DayCalendarProps) {
  const eventsOnThisDayAllDay = useMemo(() => {
    return props.events
      .filter((event) => {
        return (
          !!event.allDay &&
          moment(event.startDate).format('YYYY-MM-DD') === props.selectedDate
        )
      })
      .sort((a, b) => {
        return moment(a.startDate).diff(moment(b.startDate))
      })
  }, [props.events, props.selectedDate])

  return (
    <Container>
      <Header>
        <HeaderRow>
          <LeftColumn></LeftColumn>
          <RightColumn>{moment(props.selectedDate).format('dddd')}</RightColumn>
        </HeaderRow>
        <HeaderRow>
          <LeftColumn>all-day</LeftColumn>
          <RightColumn>
            <AllDayRow>
              {eventsOnThisDayAllDay?.map((event) => {
                let fillColor = event.color ?? Colors.HAVELOCK_BLUE
                if (props.fillChipWithGameType) {
                  fillColor =
                    event.type === 'game' ? Colors.PUNCH : Colors.HAVELOCK_BLUE
                }
                return (
                  <EventContainer
                    key={event.id}
                    onClick={(e: MouseEvent<HTMLDivElement>) =>
                      props.onClickEvent?.(event, e)
                    }
                    fill={fillColor}
                  >
                    <EventLabel>{event.title}</EventLabel>
                  </EventContainer>
                )
              })}
            </AllDayRow>
          </RightColumn>
        </HeaderRow>
      </Header>
      <Scroll>
        <LeftColumn>
          <LeftColumnLabels />
        </LeftColumn>
        <RightColumn>
          <DayEventList
            day={{
              date: moment(props.selectedDate).toDate(),
              day: moment(props.selectedDate).format('dddd'),
              events: props.events,
              type: 'current',
            }}
            fillChipWithGameType={props.fillChipWithGameType}
            onClickDay={props.onClickDay}
            onClickEvent={props.onClickEvent}
            onDoubleClickDay={props.onDoubleClickDay}
            onDropEvent={props.onDropEvent}
            selectedDate={props.selectedDate}
          />
        </RightColumn>
      </Scroll>
    </Container>
  )
}

const Container = styled.div`
  border-right: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
`

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  border-bottom: 1px solid #ccc;
`

const AllDayRow = styled.div`
  height: 50px;
  overflow: hidden;
  overflow-y: scroll;
  border-left: 1px solid #ccc;
`

const Scroll = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-y: scroll;
  height: 100%;
`

const LeftColumn = styled.div`
  width: 60px;
`

const RightColumn = styled.div`
  flex: 1;
  position: relative;
`
