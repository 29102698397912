import urlRegex from 'url-regex'

export function isYouTubeUrl(message?: string): boolean {
  const regex = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/
  return !!(message && message.match(regex))
}

export function parseUrls(message?: string): string[] {
  // Find all urls in string
  if (!message) {
    return []
  }

  const urls = message.match(urlRegex()) ?? []

  // Prepend a protocol if missing from url
  return urls.map((url) => {
    if (!/^https?:\/\//i.test(url)) {
      return `http://${url}`
    }
    return url
  })
}

export default {
  isYouTubeUrl,
  parseUrls,
}
