import * as React from 'react'
import type { SVGProps } from 'react'
const FabFolder = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M25 24.54H7a1 1 0 0 1-1-1v-15a1 1 0 0 1 1-1h9.4a.61.61 0 0 1 .45.2l1.9 2H25a1 1 0 0 1 1 1v12.78a1 1 0 0 1-1 1.02zM7.25 8.71v14.58h17.5V10.94H18.5a.63.63 0 0 1-.46-.2l-1.9-2zM25 10.94z' />
  </svg>
)
export default FabFolder
