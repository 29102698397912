import * as React from 'react'
import type { SVGProps } from 'react'
const PlaylistSlowforward = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fillRule='evenodd'
    strokeLinejoin='round'
    strokeMiterlimit={2}
    clipRule='evenodd'
    viewBox='0 0 20 20'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path
      fillRule='nonzero'
      d='M9.841 5.66l5 3.7c.348.26.425.759.17 1.11a.912.912 0 0 1-.17.17l-5 3.7a.79.79 0 0 1-1.27-.64V6.3c0-.438.361-.8.8-.8a.743.743 0 0 1 .47.16zM6.24 5.497h.149c.769 0 1.401.633 1.401 1.402V13.1c0 .769-.632 1.402-1.401 1.402h-.15a1.408 1.408 0 0 1-1.4-1.402V6.9c0-.769.632-1.402 1.4-1.402z'
    />
  </svg>
)
export default PlaylistSlowforward
