import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import { useCurrentState, useIsMounted } from '@sportsyou/react-hooks'

import Transition from '../css-transition'

interface Props {
  allowScrolling?: boolean
  className?: string
  fullscreenContent?: boolean
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  onClickContent?: (event: React.MouseEvent<HTMLElement>) => void
  style?: React.CSSProperties
  visible?: boolean
  width?: string
}

type NativeAttributes = Omit<React.HTMLAttributes<HTMLDivElement>, keyof Props>
export type BackdropProps = Props & NativeAttributes

export const Backdrop: React.FC<React.PropsWithChildren<BackdropProps>> = ({
  allowScrolling,
  children,
  className,
  fullscreenContent = false,
  onClick,
  onClickContent,
  style,
  visible = true,
  width,
  ...props
}: BackdropProps) => {
  const isMounted = useIsMounted()

  const [, setIsContentClicked, isContentClickedRef] = useCurrentState(false)

  const [prevPosition, setPrevPosition] = useState(0)

  // Block scrolling the page if backdrop is visible
  const blockScrolling = useCallback(() => {
    if (!isMounted || allowScrolling) return

    // store current scroll position
    let currPositon = window.scrollY

    // apply no scroll styles when backdrop is visible
    if (visible) {
      if (window.scrollY !== currPositon) {
        currPositon = window.scrollY
      }
      // store scroll position to state so we can re-apply it when dismissing the backdrop
      setPrevPosition(currPositon)
      // set styles to lock scroll
      document.body.style.top = `-${currPositon}px`
      // document.body.style.overflow = 'hidden'
      document.body.style.position = 'fixed'
      document.body.style.width = '100%'
    } else {
      // remove/reset styles that lock scroll
      document.body.style.top = ''
      // document.body.style.overflow = ''
      document.body.style.position = ''
      document.body.style.width = ''
      // set new scroll position to stored position
      window.scrollTo(0, prevPosition)
    }
  }, [allowScrolling, isMounted, prevPosition, visible])

  useEffect(() => {
    blockScrolling()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClickEvent = (event: React.MouseEvent<HTMLElement>) => {
    if (isContentClickedRef.current) return
    onClick && onClick(event)
  }

  const handleContentClickEvent = (event: React.MouseEvent<HTMLElement>) => {
    onClickContent?.(event)
  }

  const handleMouseUp = () => {
    if (!isContentClickedRef.current) return
    const timer = setTimeout(() => {
      setIsContentClicked(false)
      clearTimeout(timer)
    }, 0)
  }

  const handleMouseDownContent = () => {
    setIsContentClicked(true)
  }

  return (
    <Transition
      className={className}
      clearTime={300}
      name={'backdrop-wrapper'}
      visible={visible}
    >
      <Container
        {...props}
        className='backdrop'
        onClick={handleClickEvent}
        onMouseUp={handleMouseUp}
        style={style}
      >
        <Layer />
        <Content
          $fullscreen={fullscreenContent}
          onClick={handleContentClickEvent}
          onMouseDown={handleMouseDownContent}
          role='document'
        >
          {children}
        </Content>
      </Container>
    </Transition>
  )
}

const Container = styled.div`
  align-items: center;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  // text-align: center;

  &::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    width: 0;
  }
`
const Layer = styled.div`
  background-color: rgb(0 0 0 / 25%);
  bottom: 0;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  z-index: 1000;

  ${Container}.backdrop-wrapper-enter & {
    opacity: 0;
  }
  ${Container}.backdrop-wrapper-enter-active & {
    opacity: 1;
  }
  ${Container}.backdrop-wrapper-leave & {
    opacity: 1;
  }
  ${Container}.backdrop-wrapper-leave-active & {
    opacity: 0;
  }
`
const Content = styled.div<{ $fullscreen: boolean }>`
  display: inline-block;
  margin: ${({ $fullscreen }) => ($fullscreen ? 0 : 20)}px auto;
  max-width: ${({ $fullscreen }) => ($fullscreen ? 100 : 90)}%;
  outline: none;
  position: relative;
  vertical-align: middle;
  width: ${({ $fullscreen }) => ($fullscreen ? '100%' : 'auto')};
  z-index: 1001;
`

export default Backdrop
