// TODO: Add key to render maps
import React, { FC, useCallback, useRef } from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment'

import { Post } from '@sportsyou/api'
import { Colors } from '@sportsyou/core'
import { BubbleSolid, ThumbsUpFilled } from '@sportsyou/react-icons'

import Button from '../../button'
import Spinner from '../../spinner'
import Tooltip from '../../tooltip'

// interface Props extends PostProps {
interface Props extends Post {
  disableCommentsModal?: boolean
  disableLikesModal?: boolean
  isAnnouncement?: boolean
  isColorPost?: boolean
  isFetchingCount?: boolean

  namesWhoCommented?: Array<string>
  namesWhoLiked?: Array<string>
  numberOfComments?: number
  numberOfLikes?: number
  onClickCommentButton?: (postsIndex?: number) => void
  onClickCommentCount?: (postsIndex?: number) => void
  onClickDeleteScheduledPost?: (id: string) => void
  onClickLikeButton?: (
    postId: string,
    isLiked?: boolean,
    postsIndex?: number
  ) => void
  onClickLikeCount?: (postsIndex?: number) => void
  onHoverCommentCount?: (postsIndex?: number) => void
  onHoverLikeCount?: (postsIndex?: number) => void
  postsIndex?: number
  postViewed?: boolean
  scheduleId?: string
  scheduledTime?: string
  showComments?: boolean
  showCommentTooltip?: boolean
  showLikeTooltip?: boolean
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type PostFooterProps = Props & NativeAttributes

export const PostFooter: FC<PostFooterProps> = ({
  allowComments,
  disableCommentsModal,
  disableLikesModal,
  id,
  isColorPost,
  isFetchingCount,
  isLiked = false,
  namesWhoCommented,
  namesWhoLiked,
  numberOfComments = 0,
  numberOfLikes = 0,
  onClickCommentButton,
  onClickCommentCount,
  onClickDeleteScheduledPost,
  onClickLikeButton,
  onClickLikeCount,
  onHoverCommentCount,
  onHoverLikeCount,
  postsIndex,
  scheduledTime,
  scheduleId,
  showComments,
  showCommentTooltip,
  showLikeTooltip,
}: PostFooterProps) => {
  const timer = useRef<number | undefined>()

  const handleOnClickCommentButton = () => {
    onClickCommentButton?.(postsIndex)
  }

  const handleOnClickLike = () => {
    onClickLikeButton?.(id!, !!isLiked, postsIndex)
  }

  const handleOnClickCommentCount = () => {
    if (disableCommentsModal) return
    onClickCommentCount?.(postsIndex)
  }

  const handleOnClickLikeCount = () => {
    if (disableLikesModal) return
    onClickLikeCount?.(postsIndex)
  }

  const handleOnClickDeleteScheduledPost = () => {
    scheduleId && onClickDeleteScheduledPost?.(scheduleId)
  }

  const handleMouseEvent = (
    isHovered: boolean,
    target?: 'likes' | 'comments'
  ) => {
    if (isHovered) {
      timer.current = window.setTimeout(() => {
        if (target === 'likes') onHoverLikeCount?.(postsIndex)
        else if (target === 'comments') onHoverCommentCount?.(postsIndex)
        timer.current && clearTimeout(timer.current)
      }, 300)
    } else {
      return timer.current && clearTimeout(timer.current)
    }
  }

  const renderNamesWhoLiked = useCallback(() => {
    return isFetchingCount ? (
      <Spinner fill={Colors.WHITE} size={16} />
    ) : namesWhoLiked ? (
      namesWhoLiked.map((name, index) => (
        <NameOnTooltip key={index}>{name}</NameOnTooltip>
      ))
    ) : null
  }, [isFetchingCount, namesWhoLiked])

  const renderNamesWhoCommented = useCallback(() => {
    return isFetchingCount ? (
      <Spinner fill={Colors.WHITE} size={16} />
    ) : namesWhoCommented ? (
      namesWhoCommented.map((name, index) => (
        <NameOnTooltip key={index}>{name}</NameOnTooltip>
      ))
    ) : null
  }, [isFetchingCount, namesWhoCommented])

  const renderCounts = () => {
    return (
      <CountContainer>
        {!showLikeTooltip && numberOfLikes > 0 && (
          <CountTextButton onClick={handleOnClickLikeCount} type='button'>
            <ThumbsUpFilled
              fill={Colors.SHUTTLE_GRAY}
              style={{ marginTop: -2 }} // * Added for "visual" vertical alignment
            />{' '}
            {numberOfLikes}
          </CountTextButton>
        )}
        {showLikeTooltip && numberOfLikes > 0 && (
          <Tooltip
            contentStyle={isFetchingCount ? { padding: '8px 10px' } : undefined}
            hideDecorator
            onMouseEnter={() => handleMouseEvent(true, 'likes')}
            onMouseLeave={() => handleMouseEvent(false)}
            placement={'top'}
            text={renderNamesWhoLiked()}
            trigger={'hover'}
          >
            <CountTextButton onClick={handleOnClickLikeCount} type='button'>
              <ThumbsUpFilled
                fill={Colors.SHUTTLE_GRAY}
                style={{ marginTop: -2 }} // * Added for "visual" vertical alignment
              />{' '}
              {numberOfLikes}
            </CountTextButton>
          </Tooltip>
        )}
        {allowComments && numberOfLikes > 0 && numberOfComments > 0 && (
          <Divider />
        )}
        {!showCommentTooltip && allowComments && numberOfComments > 0 && (
          <CountTextButton onClick={handleOnClickCommentCount} type='button'>
            <BubbleSolid fill={Colors.SHUTTLE_GRAY} /> {numberOfComments}
          </CountTextButton>
        )}
        {showCommentTooltip && allowComments && numberOfComments > 0 && (
          <Tooltip
            contentStyle={isFetchingCount ? { padding: '8px 10px' } : undefined}
            hideDecorator
            onMouseEnter={() => handleMouseEvent(true, 'comments')}
            onMouseLeave={() => handleMouseEvent(false)}
            placement={'top'}
            text={renderNamesWhoCommented()}
            trigger={'hover'}
          >
            <CountTextButton onClick={handleOnClickCommentCount} type='button'>
              <BubbleSolid fill={Colors.SHUTTLE_GRAY} /> {numberOfComments}
            </CountTextButton>
          </Tooltip>
        )}
      </CountContainer>
    )
  }

  return scheduleId && scheduledTime ? (
    <ScheduleContainer>
      <ScheduledTime>
        {moment(scheduledTime).format('ddd MMM D @ h:mm A')}
      </ScheduledTime>
      <TextButton
        aria-label='Delete Scheduled Post'
        onClick={handleOnClickDeleteScheduledPost}
        title='Delete Scheduled Post'
      >
        Delete
      </TextButton>
    </ScheduleContainer>
  ) : (
    <>
      {/* Likes and Comments Count */}
      <Container isColorPost={isColorPost}>
        {numberOfLikes + numberOfComments > 0 && renderCounts()}
        <ActionContainer>
          <ActionTextButton
            appearance='minimal'
            collapse
            onClick={handleOnClickLike}
            textStyle={{
              color: isLiked ? Colors.PUNCH : Colors.SHUTTLE_GRAY,
              fontSize: 12,
              fontWeight: isLiked ? 700 : 400,
            }}
            title='Like Post'
            variant='alternate'
          >
            {isLiked ? 'Liked' : 'Like'}
          </ActionTextButton>
          {allowComments && (
            <ActionTextButton
              appearance='minimal'
              collapse
              onClick={handleOnClickCommentButton}
              textStyle={{
                color: Colors.SHUTTLE_GRAY,
                fontSize: 12,
                fontWeight: 400,
              }}
              title='Comment on Post'
              variant='alternate'
            >
              Comment
            </ActionTextButton>
          )}
        </ActionContainer>
      </Container>
    </>
  )
}

const Container = styled.footer<{ isColorPost?: boolean }>`
  align-self: stretch;

  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: ${({ isColorPost }) => (isColorPost ? 0 : 10)}px;
  padding-bottom: 8px;

  @media all and (max-width: 767px) {
    flex-direction: column;
    padding-top: ${({ isColorPost }) => (isColorPost ? 0 : undefined)};
  }

  @media all and (min-width: 768px) {
    border-top: ${({ isColorPost }) =>
      isColorPost ? 'none' : `1px solid ${Colors.ALTO}`};
    flex-direction: row;
    padding-top: 8px;
  }
`
const CountContainer = styled.div`
  align-items: center;
  display: flex;
  // margin-top: 10px;
  margin-top: 0;
  @media all and (min-width: 768px) {
    order: 1;
  }
`
const Divider = styled.div`
  align-self: stretch;
  background-color: ${Colors.SHUTTLE_GRAY};
  margin: 8px 4px;
  position: relative;
  width: 1px;
`
const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media all and (max-width: 767px) {
    border-top: 1px solid ${Colors.ALTO};
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding-top: 10px;
  }
`
// TODO: focus styles
const TextButtonStyles = css`
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  font-family: inherit;
  font-size: 12px;
  height: 30px;
  // padding: 0 6px;
  // padding: 0;
  transition: background-color 160ms ease-in-out;

  &:hover,
  &:active {
    background-color: ${Colors.MYSTIC};
  }
`
const TextButton = styled.button`
  ${TextButtonStyles};
  font-size: 13px;
  color: ${Colors.MONZA};
`

// ${TextButtonStyles};
// flex: 0 0 50%;
// @media all and (min-width: 768px) {
//   flex-basis: auto;
//   min-width: 46px;
// }

// & + & {
//   margin-left: 6px;
// }
// color: ${({ isLiked }) => (isLiked ? Colors.PUNCH : Colors.SHUTTLE_GRAY)};
// font-weight: ${({ isLiked }) => (isLiked ? 700 : 400)};
// `
const ActionTextButton = styled(Button)`
  border-radius: 4px;
  flex: 0 0 50%;
  height: 30px;
  min-height: 1px;
  &:focus {
    box-shadow: 0 0 0 2px ${Colors.MYSTIC};
  }
  @media all and (min-width: 768px) {
    flex-basis: auto;
    min-width: 46px;
  }
  &:not(:first-child) {
    margin-left: 6px;
  }
`

const CountTextButton = styled.button`
  ${TextButtonStyles};
  align-items: center;
  display: inline-flex;
  & > svg {
    margin-right: 4px;
  }
`

const ScheduleContainer = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: 8px 12px;
  margin-top: 10px;
  border-top: 1px solid ${Colors.ALTO};
`
const ScheduledTime = styled.span`
  color: ${Colors.HAVELOCK_BLUE};
  font-size: 13px;
`
const NameOnTooltip = styled.div`
  padding-bottom: 5px;
  :last-child {
    padding-bottom: 0;
  }
`

PostFooter.displayName = 'PostFooter'

export default PostFooter
