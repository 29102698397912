import { formatDistance } from 'date-fns'
import React, { useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'

import { Comment as ApiCommentProps } from '@sportsyou/api'
import { Colors } from '@sportsyou/core'

import Avatar from '../avatar'
import Dropdown from '../dropdown'

type TestIds = {
  author?: string
  avatar?: string
  container?: string
  delete?: string
  menu?: string
  message?: string
  report?: string
}

export interface ReportCommentParams {
  commentId: string
  targetId: string
}

export interface CommentProps extends ApiCommentProps {
  canEdit?: boolean
  className?: string
  currentUserId?: string
  isActive?: boolean
  onClickAuthor?: (userId: string) => void
  onDeleteComment?: (postId: string, id: string) => void
  onReportComment?: (params: ReportCommentParams) => void
  postId: string
  style?: React.CSSProperties
  testId?: TestIds
}

export const Comment: React.FC<CommentProps> = ({
  canEdit,
  className,
  createdAt,
  createdBy,
  currentUserId,
  id = '',
  isActive,
  isOwner,
  message,
  onClickAuthor,
  onDeleteComment,
  onReportComment,
  postId,
  style,
  testId,
}: CommentProps) => {
  const commentRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (isActive) {
      commentRef.current?.scrollIntoView()
    }
  }, [isActive])

  const fullName = useMemo(() => {
    let name = 'SY User'
    if (createdBy?.fullName) {
      name = createdBy?.fullName
    } else {
      if (createdBy?.firstName) {
        name = createdBy.firstName
        if (createdBy.lastName) {
          name = `${name} ${createdBy.lastName}`
        }
      }
    }
    return name
  }, [createdBy])

  const timestamp = useMemo(() => {
    if (createdAt) {
      return formatDistance(new Date(createdAt), new Date(), {
        addSuffix: true,
      })
    }
    return ''
  }, [createdAt])

  const handleOnClickAuthor = () => {
    const userId = createdBy?.id ?? ''
    onClickAuthor?.(userId)
  }

  const handleOnDeleteComment = () => {
    onDeleteComment?.(postId, id as string)
  }

  const handleOnReportComment = () => {
    onReportComment?.({
      commentId: id ?? '',
      targetId: createdBy?.id ?? '',
    })
  }

  return (
    <Container
      className={className}
      isActive={isActive}
      ref={commentRef}
      style={style}
      data-testid={testId?.container}
    >
      <Avatar
        aria-label={`Profile for ${fullName}`}
        data-testid={testId?.avatar}
        diameter={30}
        name={fullName}
        onClick={handleOnClickAuthor}
        title={`Profile for ${fullName}`}
        uri={createdBy?.profileImage?.[0]?.viewUrl ?? undefined}
      />

      <Content>
        <ContentHeader>
          <Author
            aria-label={`Profile for ${fullName}`}
            data-testid={testId?.author}
            onClick={handleOnClickAuthor}
            title={`Profile for ${fullName}`}
          >
            {fullName}
          </Author>

          {createdAt && <Timestamp>{timestamp}</Timestamp>}

          <Dropdown
            aria-label='More options'
            chevronSize={12}
            collapse
            data-testid={testId?.menu}
            placement='bottomEnd'
            title=''
            triggerStyle={{
              flex: '0 0 28px',
              marginLeft: 6,
              minHeight: 28,
              minWidth: 28,
            }}
          >
            {currentUserId !== createdBy?.id && onReportComment && (
              <Dropdown.Item
                data-testid={testId?.report}
                onClick={handleOnReportComment}
              >
                Report Comment
              </Dropdown.Item>
            )}
            {(isOwner || canEdit) && (
              <Dropdown.Item
                data-testid={testId?.delete}
                onClick={handleOnDeleteComment}
                style={{ color: Colors.MONZA }}
              >
                Delete Comment
              </Dropdown.Item>
            )}
          </Dropdown>
        </ContentHeader>
        <ContentBody data-testid={testId?.message}>{message}</ContentBody>
      </Content>
    </Container>
  )
}

const Container = styled.div<{ isActive?: boolean }>`
  display: flex;
  background-color: ${({ isActive }) => (isActive ? Colors.MYSTIC : undefined)};
  &:not(:first-child) {
    border-top: 1px solid ${Colors.MYSTIC};
  }
`
const Content = styled.div`
  flex: 1;
  margin-left: 10px;
`
const ContentHeader = styled.div`
  align-items: center;
  display: flex;
`
const Author = styled.span`
  cursor: pointer;
  flex: 1 1 auto;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;

  &:hover,
  &:active {
    text-decoration: underline;
  }
`
const Timestamp = styled.span`
  color: ${Colors.SHUTTLE_GRAY};
  font-size: 12px;
`
const ContentBody = styled.p`
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 0;
  margin-top: 2px;
`

export default Comment
