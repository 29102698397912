import { useEffect, useState } from 'react'

import { Dimensions } from './use-window-dimensions'

const useWindowResize = (): Dimensions => {
  const [windowSize, setWindowSize] = useState<Dimensions>({
    height: undefined,
    width: undefined,
  })

  useEffect(() => {
    // handler to call on window resize
    const handleResize = () => {
      setWindowSize({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }

    // add event listener
    window.addEventListener('resize', handleResize)

    // initiate handler to store initial window size
    handleResize()

    // remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

export default useWindowResize
