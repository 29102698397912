/* eslint-disable @typescript-eslint/no-explicit-any */
export const mergeRefs =
  (...refs: any) =>
  (node: any) => {
    for (const ref of refs) {
      ref.current = node
    }
  }

export default mergeRefs
