import moment from 'moment'
import React from 'react'
import styled from 'styled-components'

import { Event as EventProps } from '@sportsyou/api'
import EventChip, { EventChipShape } from './month-event-chip'

export interface WeekEventsProps {
  endDate: moment.Moment
  fillChipWithGameType?: boolean
  eventChips: EventChipShape[]
  eventIdToClickOnMount?: string
  startDate: moment.Moment
  onClickEvent?: (
    event: EventProps,
    e: React.MouseEvent<HTMLDivElement>
  ) => void
  weekNumber: number
  weekHeight: number
}

export default function WeekEvents(props: WeekEventsProps) {
  return (
    <WeekEventsContainer
      weekNumber={props.weekNumber}
      weekHeight={props.weekHeight}
    >
      {props.eventChips.map((eventChip, index) => {
        if (eventChip.top === undefined) {
          return null
        }
        return (
          <EventChip
            event={eventChip.event}
            fill={eventChip.event.color!}
            fillWithGameType={props.fillChipWithGameType}
            id={eventChip.event.id!}
            key={eventChip.event.id!}
            onClick={props.onClickEvent}
            shape={eventChip}
            shouldTriggerClickOnMount={
              eventChip.event.id === props.eventIdToClickOnMount
            }
          />
        )
      })}
    </WeekEventsContainer>
  )
}

const WeekEventsContainer = styled.div<{
  weekNumber: number
  weekHeight: number
}>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 0px;
  position: absolute;
  margin-top: 24px;
  top: ${({ weekNumber, weekHeight }) => weekNumber * weekHeight}px;
`
