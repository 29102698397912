import * as React from 'react'
import type { SVGProps } from 'react'
const Pause = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 30 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M24.1 0h.53a4.98 4.98 0 0 1 4.98 4.98v22.04A4.98 4.98 0 0 1 24.63 32h-.53a4.98 4.98 0 0 1-4.98-4.98V4.98A4.98 4.98 0 0 1 24.1 0zM4.98 0h.53a4.98 4.98 0 0 1 4.98 4.98v22.04A4.98 4.98 0 0 1 5.51 32h-.53A4.98 4.98 0 0 1 0 27.02V4.98A4.98 4.98 0 0 1 4.98 0z' />
  </svg>
)
export default Pause
