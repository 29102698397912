import * as React from 'react'
import type { SVGProps } from 'react'
const BrandSy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <g>
      <g>
        <g>
          <g>
            <path d='M13.25 28.15a30 30 0 0 1-9-1.34 16.07 16.07 0 0 0 22.63 1l.08-.08a15.81 15.81 0 0 0 2.67-3.16 23.9 23.9 0 0 1-4.09 1.76 41.09 41.09 0 0 1-12.29 1.82z' />
            <path d='M13.48 24.7a21.71 21.71 0 0 0 12-3.38A23 23 0 0 0 32 14.53v-.06a16 16 0 1 0-30.18 8.86 40.56 40.56 0 0 0 11.66 1.37zm.16-16.58a.09.09 0 0 1 .12 0H18a.48.48 0 0 1 .12.16L20.67 13l2.47-4.62a.75.75 0 0 1 .1-.17h4.36a.14.14 0 0 1 0 .09.3.3 0 0 1-.14.23l-1.76 2.74-3.09 4.82v5.11c0 .28-.15.42-.47.42H19a.39.39 0 0 1-.36-.42v-5.11l-4.91-7.66a.28.28 0 0 1-.12-.23.11.11 0 0 1 0-.07zm-5 13.39a7.13 7.13 0 0 1-1.29-.31 6.5 6.5 0 0 1-1.18-.59 4.4 4.4 0 0 1-1-.86.9.9 0 0 1-.17-.26.71.71 0 0 1 0-.29.53.53 0 0 1 0-.2.55.55 0 0 1 .17-.2l1.28-1.19a.56.56 0 0 1 .45-.13 1.43 1.43 0 0 1 .49.32 2.49 2.49 0 0 0 .6.42 6 6 0 0 0 .63.27l.54.15H10a3.13 3.13 0 0 0 .59-.09 1.22 1.22 0 0 0 .48-.21.67.67 0 0 0 .25-.35.78.78 0 0 0-.12-.41.81.81 0 0 0-.33-.25 2.24 2.24 0 0 0-.54-.26l-.71-.19a8.81 8.81 0 0 1-2.06-.71 4.49 4.49 0 0 1-1.28-.92 2.9 2.9 0 0 1-.66-1.09 4.2 4.2 0 0 1-.19-1.27 2.92 2.92 0 0 1 1.28-2.45 4.66 4.66 0 0 1 1.5-.74 8 8 0 0 1 3.19-.16 5.89 5.89 0 0 1 1.09.31 5.36 5.36 0 0 1 1 .52 9.44 9.44 0 0 1 1 .78.52.52 0 0 1 .18.35c0 .11-.08.22-.23.34l-.77.59c-.26.19-.5.39-.77.62a.55.55 0 0 1-.24.12.53.53 0 0 1-.25 0 1 1 0 0 1-.22-.09.9.9 0 0 1-.19-.15 1.66 1.66 0 0 0-.79-.48 3.48 3.48 0 0 0-.9-.12 1.72 1.72 0 0 0-.81.15.48.48 0 0 0-.28.48.53.53 0 0 0 .2.37 1.35 1.35 0 0 0 .45.22 4 4 0 0 0 .57.14 3.49 3.49 0 0 1 .54.12 17.14 17.14 0 0 1 1.71.57 5.25 5.25 0 0 1 1.29.77 3.39 3.39 0 0 1 .9 1.09 3.6 3.6 0 0 1 .3 1.55 3.06 3.06 0 0 1-.41 1.62 3.54 3.54 0 0 1-1.13 1.27 5.54 5.54 0 0 1-1.69.74 8 8 0 0 1-2.12.26 8.2 8.2 0 0 1-1.21-.17z' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
export default BrandSy
