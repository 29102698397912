import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import { Colors } from '@sportsyou/core'
import { useRadioContext } from './radio-context'

export interface RadioEventTarget {
  checked: boolean
}
export interface RadioEvent {
  nativeEvent: React.ChangeEvent
  preventDefault: () => void
  stopPropagation: () => void
  target: RadioEventTarget
}

interface Props {
  checked?: boolean
  className?: string
  descriptionClassName?: string
  descriptionStyle?: React.CSSProperties
  disabled?: boolean
  hideLabel?: boolean
  onChange?: (event: RadioEvent) => void
  style?: React.CSSProperties
  testId?: string
  value?: number | string
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type RadioProps = Props & NativeAttributes

export const Radio: FC<PropsWithChildren<RadioProps>> = ({
  className,
  checked,
  children,
  descriptionClassName,
  descriptionStyle,
  disabled,
  hideLabel,
  onChange,
  style,
  testId,
  value, //: initialValue,
  ...props
}: RadioProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(!!checked)

  const {
    value: groupValue,
    disabledAll,
    inGroup,
    updateState,
  } = useRadioContext()

  if (inGroup) {
    if (checked !== undefined) {
      console.warn('[Radio]: Remove"checked" prop if in the Radio.Group.')
    }
    if (value === undefined) {
      console.warn(
        '[Radio]: "value" prop must be defined if in the Radio.Group.'
      )
    }
  }

  useEffect(() => {
    setIsChecked(groupValue === value)
  }, [groupValue, value])

  useEffect(() => {
    if (checked === undefined) return
    setIsChecked(Boolean(checked))
  }, [checked])

  const isDisabled = useMemo(
    () => disabled || disabledAll,
    [disabled, disabledAll]
  )

  const handleOnChange = (event: React.ChangeEvent) => {
    if (isDisabled) return

    const _event: RadioEvent = {
      nativeEvent: event,
      preventDefault: event.preventDefault,
      stopPropagation: event.stopPropagation,
      target: { checked: !isChecked },
    }

    setIsChecked(!isChecked)

    if (inGroup) {
      updateState && updateState(value as number | string)
    }

    onChange && onChange(_event)
  }

  return (
    <Label
      className={className}
      data-testid={testId}
      disabled={isDisabled}
      style={style}
    >
      <Input
        type='radio'
        value={value}
        checked={isChecked}
        onChange={handleOnChange}
        disabled={isDisabled}
        {...props}
      />
      {!hideLabel ? (
        <Description
          className={descriptionClassName}
          disabled={isDisabled}
          style={descriptionStyle}
        >
          {children}
        </Description>
      ) : null}
    </Label>
  )
}

const Label = styled.label<{ disabled: boolean }>`
  align-items: center;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : undefined)};
  user-select: ${({ disabled }) => (disabled ? 'none' : undefined)};
  // & + & {
  //   margin-left: 10px;
  // }
`
const Input = styled.input`
  margin: 0;
`
const Description = styled.span<{ disabled: boolean }>`
  color: ${({ disabled }) => (disabled ? Colors.DUSTY_GRAY : 'inherit')};

  margin-left: 8px;
`

export default Radio
