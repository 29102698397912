import React, { useCallback, useEffect, useState } from 'react'

import { Upload } from '@sportsyou/api'
import { ExtendedUpload, useUploads } from '@sportsyou/react-hooks'

import FileInput from '../../file-input'

interface AttachmentOptionsProps {
  className?: string
  isVisibile: boolean
  maxFiles?: number
  setIsUploading?: (isUploading: boolean) => void
  setUploads: (uploads: Upload[]) => void
  style?: React.CSSProperties
  uploads: Upload[]
  uploadsContainerStyle?: React.CSSProperties
}

export const AttachmentOptions = ({
  className,
  isVisibile,
  maxFiles,
  setIsUploading,
  setUploads,
  style,
  uploads,
  uploadsContainerStyle,
}: AttachmentOptionsProps) => {
  const uploadsHook = useUploads({ uploads: uploads as ExtendedUpload[] })

  useEffect(() => {
    if (uploads.length > 0) {
      uploadsHook.initializeExistingUploads(uploads as ExtendedUpload[])
    }
  }, [uploads, uploadsHook])

  useEffect(() => {
    setUploads(uploadsHook.allUploads)
  }, [setUploads, uploadsHook.allUploads])

  const onUploaderUploadStart = useCallback(
    (newUploads: ExtendedUpload[], currentUpload: ExtendedUpload): void => {
      setIsUploading?.(true)
      console.log('on uploader start')
    },
    [setIsUploading]
  )

  const onUploaderUploadDone = useCallback(
    (newUploads: ExtendedUpload[], currentUpload: ExtendedUpload): void => {
      uploadsHook.addNewUploads([currentUpload])
    },
    [uploadsHook]
  )

  const onUploaderCancel = useCallback(
    (cancelledUpload?: ExtendedUpload): void => {
      if (cancelledUpload) {
        uploadsHook.removeUpload(cancelledUpload.id!)
      }
    },
    [uploadsHook]
  )

  const onUploaderError = useCallback(
    (newUploads: ExtendedUpload[], cancelledUpload: ExtendedUpload): void => {
      uploadsHook.removeUpload(cancelledUpload.id!)
    },
    [uploadsHook]
  )

  const onUploaderBusy = useCallback(
    (isBusy: boolean): void => {
      setIsUploading?.(isBusy)
      console.log('on uploader busy', { isBusy })
    },
    [setIsUploading]
  )

  if (!isVisibile) {
    return null
  }

  return (
    <FileInput
      className={className}
      hideSelectFileButton
      label='Attachments'
      maxFiles={maxFiles}
      onUploaderCancel={onUploaderCancel}
      onUploaderError={onUploaderError}
      onUploaderUploadDone={onUploaderUploadDone}
      onUploaderUploadStart={onUploaderUploadStart}
      setIsBusy={onUploaderBusy}
      shouldCreateHLS
      style={style}
      uploads={uploadsHook.allUploads}
      uploadsContainerStyle={uploadsContainerStyle}
    />
  )
}

export default AttachmentOptions
