import * as React from 'react'
import type { SVGProps } from 'react'
const JoinTeam = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <g>
      <path d='M16.41 18.16a9.5 9.5 0 0 1 1.38-1.7l.18-.15H7.42v-6.23a.93.93 0 0 0-1.85 0v2.06H2.32a.47.47 0 0 1-.47-.47V7.81a3.27 3.27 0 0 1 1.88-2.95l3.78-1.75a1 1 0 0 1 1 .11l5.07 3.88a.93.93 0 0 0 1.12 0l5.23-4a.63.63 0 0 1 .67-.1l3.95 1.83a3.26 3.26 0 0 1 1.89 2.95v3.86a.47.47 0 0 1-.47.47h-3.25v-2.03a.93.93 0 0 0-1.85 0v4.38c.28-.11.57-.23.86-.32a10.05 10.05 0 0 1 2.58-.47h.77a9.3 9.3 0 0 1 1.55.21 2.32 2.32 0 0 0 1.66-2.21V7.81a5.11 5.11 0 0 0-3-4.63l-4-1.83a2.47 2.47 0 0 0-2.56.28L14.14 5.2l-4.5-3.45a2.83 2.83 0 0 0-2.91-.32L3 3.18a5.12 5.12 0 0 0-3 4.63v3.86A2.33 2.33 0 0 0 2.31 14h3.26v11.32a2.31 2.31 0 0 0 2.31 2.31h7.8a4 4 0 0 1-.22-.47 1 1 0 0 1-.07-.15 11.3 11.3 0 0 1-.39-1.23H7.88a.46.46 0 0 1-.46-.46v-1.9h7.35a9.29 9.29 0 0 1 .16-1.57c0-.09.06-.19.08-.28H7.42v-3.41z' />
      <path d='M30.12 18.76a5.55 5.55 0 0 0-.48-.49 7 7 0 0 0-1.11-.84 5.61 5.61 0 0 0-.86-.49c-.18-.08-.38-.12-.56-.18a7.12 7.12 0 0 0-2.22-.44h-.38a7 7 0 0 0-1.79.3l-.19.05a7.3 7.3 0 0 0-2.65 1.49c-.08.07-.17.13-.24.2a7.23 7.23 0 0 0-1.79 2.83c0 .13-.06.26-.1.38a7.34 7.34 0 0 0-.21.77 6.38 6.38 0 0 0-.11 1.08v.14c0 .05 0 0 0 0a6.33 6.33 0 0 0 .2 1.52c.05.23.12.45.19.67s.05.21.08.31v.05a7.07 7.07 0 0 0 .79 1.49l.06.08a7.64 7.64 0 0 0 .54.73 8.09 8.09 0 0 0 1.11 1 7.12 7.12 0 0 0 3.11 1.33 7.53 7.53 0 0 0 1.18.09 8 8 0 0 0 1.47-.14 7.3 7.3 0 0 0 5.84-7.1 7.16 7.16 0 0 0-1.88-4.83zm-.63 2.77l-5.36 5.22a.76.76 0 0 1-.54.22.76.76 0 0 1-.55-.23l-.5-.52-1.67-1.71-1-1s0-.07-.07-.1a.75.75 0 0 1 .62-1.2.75.75 0 0 1 .55.23l1.71 1.75.89.91 4.8-4.68a.78.78 0 0 1 1.1 0 .76.76 0 0 1 .21.55.73.73 0 0 1-.19.56z' />
    </g>
  </svg>
)
export default JoinTeam
