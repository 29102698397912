import React, { CSSProperties, FC, ReactElement, useMemo } from 'react'
import styled from 'styled-components'

import {
  checkColorValue,
  Colors,
  getAvatarColors,
  getInitialsFromName,
} from '@sportsyou/core'

import { colorToHex } from '../../utils'

import Icon from '../icon'
import Spinner from '../spinner'

interface Props {
  borderColor?: string
  borderRadius?: number
  canEdit?: boolean
  editLabel?: string
  className?: string
  isLoading?: boolean
  initialFill?: string
  fill?: string
  fontSize?: number
  /** Diameter in px of avatar */
  diameter?: number
  name?: string
  onClick?: () => void
  style?: CSSProperties
  textStyle?: CSSProperties
  testId?: string
  uri?: string
  useAvatar?: boolean
  useRawName?: boolean
  // value?: string
}

type NativeAttributes = Omit<React.HTMLAttributes<HTMLDivElement>, keyof Props>
export type AvatarProps = Props & NativeAttributes

const CONSTANTS = {
  AVATAR: {
    DEFAULT_SIZE: 60,
    FILL: Colors.DUSTY_GRAY,
    BORDER_DARK: 'rgba(0, 0, 0, 0.18)',
    BORDER_LIGHT: 'rgba(255, 255, 255, 0.18)',
  },
}

const DEFAULT_AVATAR_SIZE = 60

interface GenericAvatarProps extends React.HTMLAttributes<any> {
  backgroundColor?: string
  diameter?: number
}

export const Avatar: FC<AvatarProps> = ({
  borderColor,
  borderRadius,
  diameter = DEFAULT_AVATAR_SIZE,
  canEdit = false,
  className = '',
  editLabel = 'Change',
  initialFill = CONSTANTS.AVATAR.FILL,
  isLoading,
  fill,
  fontSize,
  name = 'S Y',
  onClick,
  style,
  testId,
  textStyle,
  uri,
  useAvatar = false,
  useRawName = false,
  ...props
}: AvatarProps): ReactElement => {
  const initials = useMemo(
    () => (useRawName ? name : getInitialsFromName(name)),
    [name, useRawName]
  )

  const avatarFill = useMemo(
    () => (fill && fill.length ? colorToHex(fill) : getAvatarColors(name)),

    [fill, name]
  )

  const onClickAvatar = () => {
    onClick && onClick()
  }

  const getBorderColor = useMemo(() => {
    const _borderColor = borderColor
      ? borderColor
      : uri
      ? CONSTANTS.AVATAR.BORDER_LIGHT
      : checkColorValue(avatarFill).toLowerCase() === Colors.WHITE.toLowerCase()
      ? CONSTANTS.AVATAR.BORDER_LIGHT
      : CONSTANTS.AVATAR.BORDER_DARK

    return _borderColor
  }, [avatarFill, borderColor])

  const renderGenericAvatar = () => {
    const adjustedBgColor =
      checkColorValue(avatarFill).toLowerCase() === Colors.WHITE.toLowerCase()
        ? 'rgba(255, 255, 255, 0.4)'
        : 'rgba(0, 0, 0, 0.4)'

    return (
      <GenericAvatar backgroundColor={adjustedBgColor} diameter={diameter}>
        avatar
      </GenericAvatar>
    )
  }

  return (
    <Container
      $fill={avatarFill}
      $borderColor={getBorderColor}
      $canEdit={canEdit}
      borderRadius={borderRadius}
      className={className}
      data-testid={testId}
      diameter={diameter}
      onClick={onClickAvatar}
      style={style}
      {...props}
    >
      {useAvatar ? (
        renderGenericAvatar()
      ) : uri ? (
        <img
          alt='User Avatar'
          draggable={false}
          src={uri}
          style={{
            height: diameter,
            width: diameter,
          }}
        />
      ) : (
        <Text $fill={avatarFill} style={textStyle}>
          {initials}
        </Text>
      )}
      {isLoading && <LoadingSpinner fill={checkColorValue(avatarFill)} />}
      {canEdit && (
        <EditButton
          aria-label='Change Avatar'
          diameter={diameter}
          title='Change avatar'
          type='button'
        >
          <Icon name='Camera' />
          <span style={{ marginTop: 2 }}>{editLabel}</span>
        </EditButton>
      )}
    </Container>
  )
}

const EditButton = styled.button<{ diameter: number }>`
  align-items: center;
  border: none;
  border-radius: 50%;
  background-color: rgb(200 200 200 / 80%);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  justify-content: center;
  height: ${({ diameter }) => diameter}px;
  position: absolute;
  opacity: 0;
  width: ${({ diameter }) => diameter}px;
  transition: 120ms ease-in-out;
  visibility: hidden;
`

const Container = styled.div<
  AvatarProps & { $borderColor: string; $canEdit: boolean; $fill: string }
>`
  align-items: center;
  background-color: ${({ $fill, uri, useRawName }) =>
    uri ? Colors.DUSTY_GRAY : useRawName ? Colors.MINE_SHAFT : $fill};
  border-radius: ${({ borderRadius, diameter }) => borderRadius ?? diameter}px;
  box-sizing: border-box;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  display: flex;
  flex: 0 0 auto;
  font-size: ${({ diameter, fontSize }) => fontSize ?? diameter! / 3}px;
  height: ${({ diameter }) => diameter}px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: ${({ diameter }) => diameter}px;

  &::before {
    content: '';

    border: ${({ $borderColor, diameter = DEFAULT_AVATAR_SIZE }) =>
      `calc(${diameter}px * 0.05) solid ${$borderColor}`};
    border-radius: ${({ borderRadius = DEFAULT_AVATAR_SIZE }) =>
      `${borderRadius ?? 0}px` ?? '50%'};
    bottom: 0;
    box-sizing: border-box;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  ${({ $canEdit }) =>
    $canEdit
      ? `&:hover > ${EditButton} { opacity: 1; visibility: visible; }`
      : undefined}
`
const GenericAvatar = styled.div<GenericAvatarProps>`
  background-color: ${({ backgroundColor = CONSTANTS.AVATAR.FILL }) =>
    backgroundColor};
  border-radius: 50%;
  font-size: 0;
  height: ${({ diameter }) => diameter}px;
  position: absolute;
  top: 70%;
  width: ${({ diameter }) => diameter}px;

  &::before {
    content: '';
    background-color: inherit;
    border-radius: 50%;
    bottom: 100%;
    height: ${({ diameter }) => `calc(${diameter}px / 2.5)`};
    left: 0;
    margin: auto;
    margin-bottom: 4px;
    position: absolute;
    right: 0;
    width: ${({ diameter }) => `calc(${diameter}px / 2.5)`};
  }
`
const Text = styled.span<{ $fill: string }>`
  color: ${({ $fill }) => checkColorValue($fill)};
  font-size: inherit;
  font-weight: 500;
  line-height: 100%;
  user-select: none;
`
const LoadingSpinner = styled(Spinner)`
  background-color: rgb(0 0 0 / 30%);
  height: 100%;
  left: 0;
  padding: 30%;
  position: absolute;
  top: 0;
  width: 100%;
`

export default Avatar
