import { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'
import { SponsoredPostInfo } from '@sportsyou/api'

import Button from '../../button'

type Props = SponsoredPostInfo

export const PostSponsorship = ({
  clickThroughText,
  clickThroughUrl,
  headline,
  tagline,
  buttonText,
}: Props) => {
  // Sponsored posts can sometimes not have any taglines or actionable items,
  // in this case we should not render this component.
  const hasContent = useMemo(
    () =>
      !!buttonText ||
      !!clickThroughText ||
      !!clickThroughUrl ||
      !!headline ||
      !!tagline,
    [buttonText, clickThroughText, clickThroughUrl, headline, tagline]
  )

  const onClickthrough = useCallback(() => {
    const url = clickThroughUrl
    console.log({ url })
    if (url) {
      window.open(url, '_blank')
    }
  }, [clickThroughUrl])

  return hasContent ? (
    <Container hasUrl={!!clickThroughUrl} onClick={onClickthrough}>
      <LeftColumn>
        {!!clickThroughText && (
          <ClickthroughText>{clickThroughText}</ClickthroughText>
        )}
        {!!headline && <Headline>{headline}</Headline>}
        {!!tagline && <Tagline>{tagline}</Tagline>}
      </LeftColumn>
      {!!buttonText && !!clickThroughUrl && (
        <RightColumn>
          <CTAButton>{buttonText}</CTAButton>
        </RightColumn>
      )}
    </Container>
  ) : null
}

const Container = styled.div<{ hasUrl?: boolean }>`
  cursor: ${({ hasUrl }) => (hasUrl ? 'pointer' : undefined)};
  display: flex;
  flex-direction: row;
  margin: 10px;
  border-top: 1px solid ${Colors.ALTO};
  padding-top: 10px;
`

const LeftColumn = styled.div`
  margin-right: 10px;
  flex: 1;
`

const RightColumn = styled.div`
  flex: 1;
`

const ClickthroughText = styled.div`
  color: ${Colors.SHUTTLE_GRAY};
  font-size: 14px;
`

const Headline = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  padding-bottom: 5px;
  padding-top: 5px;
`

const Tagline = styled.div`
  font-size: 14px;
`

const CTAButton = styled(Button)`
  width: 100% !important;
`

export default PostSponsorship
