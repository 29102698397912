import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { AccordionContent } from './accordion-content'
import { AccordionHeader } from './accordion-header'
import { AccordionTrigger, AccordionTriggerProps } from './accordion-trigger'
import { getChildrenFromReactNode, getPropsFromReactNode } from '../../utils'

export interface AccordionProps {
  children?: React.ReactNode
  className?: string
  id?: string
  isExpanded?: boolean
  onToggle?: () => void
  style?: React.CSSProperties
}

export const Accordion: React.FC<React.PropsWithChildren<AccordionProps>> = ({
  children,
  className,
  id,
  isExpanded: initialExpanded = false,
  onToggle,
  style,
}: AccordionProps) => {
  const [isExpanded, setIsExpanded] = useState(initialExpanded)

  const handleOnClickTrigger = useCallback(
    (callback?: () => void) => {
      setIsExpanded(!isExpanded)
      onToggle?.()
      callback?.()
    },
    [isExpanded, onToggle]
  )

  const headerNode = useMemo(
    () =>
      React.Children.map(children, (item) => {
        if (!React.isValidElement(item)) return null
        if (item.type === AccordionHeader) {
          return React.cloneElement(item, {
            ...item.props,
            children: React.Children.map(item.props.children, (child) => {
              if (!React.isValidElement(item)) return null
              return child.type === AccordionTrigger
                ? React.cloneElement(child, {
                    ...child.props,
                    isExpanded,
                    onClick: () => handleOnClickTrigger(child.props.onClick),
                  } as AccordionTriggerProps)
                : child
            }),
          })
        }
        return null
      }),
    [children, handleOnClickTrigger, isExpanded]
  )

  const triggerNode = useMemo(
    () =>
      React.Children.map(children, (item) =>
        React.isValidElement(item) && item.type === AccordionTrigger
          ? item
          : null
      ),
    [children]
  )

  const contentNode = useMemo(
    () =>
      React.Children.map(children, (item) =>
        React.isValidElement(item) && item.type === AccordionContent
          ? item
          : null
      ),
    [children]
  )

  return (
    <div className={className} id={id} style={style}>
      {headerNode?.map((node, index) => (
        <AccordionHeader key={index} {...getPropsFromReactNode(node)}>
          {getChildrenFromReactNode(node)}
        </AccordionHeader>
      ))}

      {triggerNode?.map((node, index) => (
        <AccordionTrigger
          isExpanded={isExpanded}
          key={index}
          {...getPropsFromReactNode(node)}
          onClick={handleOnClickTrigger}
        >
          {getChildrenFromReactNode(node)}
        </AccordionTrigger>
      ))}

      {isExpanded &&
        contentNode?.map((node, index) => (
          <AccordionContent
            isVisible={isExpanded}
            key={index}
            {...getPropsFromReactNode(node)}
          >
            {getChildrenFromReactNode(node)}
          </AccordionContent>
        ))}
    </div>
  )
}

export default Accordion
