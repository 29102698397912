import * as React from 'react'
import type { SVGProps } from 'react'
const MediaSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <g>
      <path d='M25.92 0H6.08A6.09 6.09 0 0 0 0 6.08v19.84A6.09 6.09 0 0 0 6.08 32h19.84A6.09 6.09 0 0 0 32 25.92V6.08A6.09 6.09 0 0 0 25.92 0zm3.85 17.08l-5.67-5.67a2.77 2.77 0 0 0-3.91 0l-6.78 6.77-1.54-1.53a2.78 2.78 0 0 0-3.92 0l-5.72 5.73V6.08a3.86 3.86 0 0 1 3.85-3.85h19.84a3.86 3.86 0 0 1 3.85 3.85z' />
      <circle cx={9.8} cy={8.84} r={1.74} />
    </g>
  </svg>
)
export default MediaSolid
