/** A component for showing only certain content in a multi-step interface.
 *
 *  @param {number} currentStep - The current step of the multi-step interface.
 *  @param {number} step - The step of this component.
 *  @param {React.ReactNode} [children] - The content to show in the current step.
 *
 */
export const Step = ({
  step,
  currentStep,
  children,
}: {
  step: number
  currentStep: number
  children?: React.ReactNode
}) => {
  if (step === currentStep) {
    return <>{children}</>
  }
  return null
}

export default Step
