import { useMemo } from 'react'
import moment from 'moment'
import styled from 'styled-components'

import { Event as EventProps, MutationEventUpdateRequest } from '@sportsyou/api'
import { Colors } from '@sportsyou/core'

import { DayProps } from '../calendar-types'

import DayEventChip from './day-event-chip'
import DayHalfHour from './day-half-hour'

interface DayEventListProps {
  day: DayProps
  fillChipWithGameType?: boolean
  onClickEvent?: (
    event: EventProps,
    e: React.MouseEvent<HTMLDivElement>
  ) => void
  onClickDay?: (date: DayProps, e: React.MouseEvent<HTMLDivElement>) => void
  onDoubleClickDay?: (
    date: DayProps,
    e: React.MouseEvent<HTMLDivElement>
  ) => void
  onDropEvent?: (
    originalEvent: EventProps,
    newEvent: EventProps,
    newEventRequest: MutationEventUpdateRequest
  ) => void
  selectedDate?: string
}

export const halfHourLabels = [...Array(48)].map((_, index) => {
  let label
  let hourNumber = index
  if (hourNumber % 2 === 0) {
    hourNumber = hourNumber / 2
  } else {
    hourNumber = (hourNumber - 1) / 2
  }
  if (hourNumber === 0) {
    label = 12 + 'am'
  } else if (hourNumber === 12) {
    label = 12 + 'pm'
  } else {
    if (hourNumber < 12) {
      label = hourNumber + 'am'
    } else {
      label = hourNumber - 12 + 'pm'
    }
  }
  return label
})

export const LeftColumnLabels = () => {
  return (
    <>
      {halfHourLabels.map((halfHour, index) => {
        if (index % 2 === 0) {
          return (
            <HalfHour key={index} dotted>
              <HourLabel>{halfHour}</HourLabel>
            </HalfHour>
          )
        } else {
          return <HalfHour key={index}></HalfHour>
        }
      })}
    </>
  )
}

export default function DayEventList(props: DayEventListProps) {
  const day = useMemo(() => {
    return moment(props.day.date)
  }, [props.day])

  const eventsOnThisDay = useMemo(() => {
    return props.day?.events
      ?.filter((event) => {
        return !event.allDay && moment(event.startDate).isSame(day, 'day')
      })
      .sort((a, b) => {
        return moment(a.startDate).diff(moment(b.startDate))
      })
  }, [day, props.day.events])

  const renderEvents = useMemo(() => {
    return eventsOnThisDay?.map((event, index) => {
      const rowHeight = 25
      const start = moment(event.startDate)
      const end = moment(event.endDate)
      const startHour = start.hour()
      const startMinute = start.minute()
      const endHour = end.hour()
      const endMinute = end.minute()
      const startOffset = startHour * 2 + startMinute / 30
      const endOffset = endHour * 2 + endMinute / 30
      const top = startOffset * rowHeight
      const height = (endOffset - startOffset) * rowHeight
      const overlappingEvents = eventsOnThisDay.filter((otherEvent) => {
        return (
          moment(otherEvent.startDate).isSameOrBefore(end) &&
          moment(otherEvent.endDate).isSameOrAfter(start)
        )
      })
      let width
      let left: string | number = 0
      if (overlappingEvents.length > 0) {
        width = 100 / overlappingEvents.length
        left = width * overlappingEvents.indexOf(event)
      }
      width = `calc(${width || 100}% - 4px)`
      left = left + '%'
      return (
        <DayEventChip
          fill={event.color!}
          fillWithGameType={props.fillChipWithGameType}
          key={index}
          onClick={props.onClickEvent}
          style={{
            height,
            left,
            position: 'absolute',
            top,
            width,
          }}
          event={event}
          time={start.format('h:mma')}
        />
      )
    })
  }, [eventsOnThisDay, props.fillChipWithGameType, props.onClickEvent])

  // const onClickDay = useCallback(
  //   (index, e) => {
  //     e.stopPropagation()
  //     // convert index to time
  //     const hour = Math.floor(index / 2)
  //     const minute = (index % 2) * 30
  //     const time = day.hour(hour).minute(minute)
  //     props.onClickDay?.(
  //       {
  //         date: time.toDate(),
  //         day: time.date().toString(),
  //         type: 'current',
  //       },
  //       e
  //     )
  //   },
  //   [day, props.onClickDay]
  // )

  // const onDoubleClickDay = useCallback(
  //   (index, e) => {
  //     e.stopPropagation()
  //     // convert index to time
  //     const hour = Math.floor(index / 2)
  //     const minute = (index % 2) * 30
  //     const time = day.hour(hour).minute(minute)
  //     props.onDoubleClickDay?.(
  //       {
  //         date: time.toDate(),
  //         day: time.date().toString(),
  //         type: 'current',
  //       },
  //       e
  //     )
  //   },
  //   [day, props.onClickDay]
  // )

  return (
    <EventList>
      {halfHourLabels.map((_, index) => {
        return (
          <DayHalfHour
            day={props.day}
            key={index}
            index={index}
            onDropEvent={props.onDropEvent}
            // dotted={index % 2 === 0}
            onClickDay={props.onClickDay}
            onDoubleClickDay={props.onDoubleClickDay}
          />
        )
      })}

      {renderEvents}
    </EventList>
  )
}

const EventList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
`

const HalfHour = styled.div<{ dotted?: boolean }>`
  border-bottom: 1px solid #ccc;
  border-bottom-style: ${({ dotted }) => (dotted ? 'dotted' : 'solid')};
  height: 25px;
  border-left: 1px solid #ccc;
`

const HourLabel = styled.div`
  color: ${Colors.SHUTTLE_GRAY};
  font-size: 14px;
  padding-right: 5px;
  padding-top: 3px;
  text-align: right;
`

export const EventContainer = styled.div<{ fill?: string }>`
  background-color: ${({ fill }) => fill || Colors.HAVELOCK_BLUE};
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 2px;
  margin-left: 2px;
  margin-right: 2px;
  overflow: hidden;
`

export const EventTime = styled.div`
  color: ${Colors.WHITE};
  font-size: 10px;
  padding: 2px 5px;
`

export const EventLabel = styled.div`
  color: ${Colors.WHITE};
  font-size: 14px;
  font-weight: 700;
  line-break: anywhere;
  padding: 2px 4px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
`
