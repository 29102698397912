import { useCallback } from 'react'
import moment from 'moment'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'

import { DatePicker } from '../'
import { FONT_SIZE_SM } from '../../theme'

interface DateRangeProps {
  className?: string
  dateFormat?: string
  datePickerStyle?: React.CSSProperties
  disabled?: boolean
  endDate: Date
  labelEnd?: string
  labelStart?: string
  maxEndDate?: Date
  maxStartDate?: Date
  minEndDate?: Date
  minStartDate?: Date
  setEndDate: (date: Date) => void
  setStartDate: (date: Date) => void
  showTimeSelect?: boolean
  startDate: Date
}

export default function DateRange(props: DateRangeProps) {
  const setEndDate = useCallback(
    (date: Date) => {
      if (props.showTimeSelect) {
        props.setEndDate(moment(date).toDate())
      } else {
        props.setEndDate(moment(date).endOf('day').toDate())
      }
    },
    [props]
  )

  const setStartDate = useCallback(
    (date: Date) => {
      if (props.showTimeSelect) {
        props.setStartDate(moment(date).toDate())
      } else {
        props.setStartDate(moment(date).startOf('day').toDate())
      }
    },
    [props]
  )

  const dateFormat =
    props.dateFormat ??
    (props.showTimeSelect ? 'MM/dd/yyyy h:mm aa' : undefined)

  return (
    <Container className={props.className}>
      <Column>
        <LabelText>{props.labelStart ?? 'Start Date'}</LabelText>
        <DatePicker
          dateFormat={dateFormat}
          datePickerStyle={props.datePickerStyle}
          disabled={props.disabled}
          maxDate={props.maxStartDate ?? props.endDate}
          minDate={props.minStartDate}
          onChange={setStartDate}
          selected={props.startDate}
          showTimeSelect={props.showTimeSelect}
        />
      </Column>
      <Column>
        <LabelText>{props.labelEnd ?? 'End Date'}</LabelText>
        <DatePicker
          dateFormat={dateFormat}
          datePickerStyle={props.datePickerStyle}
          disabled={props.disabled}
          maxDate={props.maxEndDate}
          minDate={props.minEndDate ?? props.startDate}
          onChange={setEndDate}
          selected={props.endDate}
          showTimeSelect={props.showTimeSelect}
        />
      </Column>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${Colors.ALTO};
  border-radius: 6px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  input {
    width: 120px;
  }
`

const LabelText = styled.div`
  color: ${Colors.PUNCH};
  font-size: ${FONT_SIZE_SM}px;
  font-weight: 700;
  margin: 6px 10px 0;
  text-transform: uppercase;
`
