import { CSSProperties, FC } from 'react'
import styled from 'styled-components'

import { Colors } from '../../colors'
import type { Offset, Placement, Position } from '../../utils/prop-types'

import Progress from '../progress'

type ActivityPlacement = Extract<Placement, 'top' | 'bottom'>

export interface ActivityProps {
  className?: string
  /** Primary color for activity bar */
  fill?: string
  /** Adjusts the absolute position of the component */
  offset?: Offset
  /** Placement of the bar on the page */
  placement?: ActivityPlacement
  /** CSS Position of the bar */
  position?: Position
  style?: CSSProperties
  /** Size of the bar */
  thickness?: number
  /** Background color of the bar track */
  trackColor?: string
  /** Horizontal width of the bar */
  width?: string
  testId?: string
  zIndex?: number
}

/**
 * Page-Level activity indicator for loading or progress states
 */
export const Activity: FC<ActivityProps> = ({
  className,
  fill = Colors.HAVELOCK_BLUE,
  offset = { x: 0, y: 0 },
  placement = 'top',
  position = 'fixed',
  style,
  testId,
  thickness = 2,
  trackColor = '#DAE8F9',
  width = '100%',
  zIndex = 1100,
}: ActivityProps) => {
  return (
    <Bar
      $width={width}
      className={className}
      testId={testId}
      fill={fill}
      trackColor={trackColor}
      indeterminate
      offset={offset}
      placement={placement}
      position={position}
      style={style}
      thickness={thickness}
      variant='bar'
      zIndex={zIndex}
    />
  )
}

const Bar = styled(Progress)<
  ActivityProps & {
    offset: Offset
    $width: string
  }
>`
  position: ${({ position }) => position};
  ${({ offset, placement }) =>
    offset.y ? `${placement}: ${offset.y}px` : `${placement}: 0`};
  left: ${({ offset }) => (offset.x ? `${offset.x}px` : 0)};
  width: ${({ $width }) => $width};
  z-index: ${({ zIndex }) => zIndex};
`

export default Activity
