import React from 'react'
import styled from 'styled-components'

// import { Colors } from '@mmrdigital/core'

// import { borderRadius } from '../../utils'

interface Props {
  containerClassName?: string
  containerStyle?: React.CSSProperties
  fullWidth?: boolean
  imageClassName?: string
  imageStyle?: React.CSSProperties
  src?: string
}

type NativeAttributes = Omit<React.ImgHTMLAttributes<HTMLElement>, keyof Props>
export type WidgetImageProps = Props & NativeAttributes

export const WidgetImage: React.FC<WidgetImageProps> = ({
  fullWidth = false,
  src,
  containerStyle,
  imageStyle,
  imageClassName,
  containerClassName,
}: WidgetImageProps) => {
  return (
    <Container
      className={containerClassName}
      fullWidth={fullWidth}
      style={containerStyle}
    >
      <Image className={imageClassName} src={src} style={imageStyle} />
    </Container>
  )
}

const Container = styled.div<Pick<WidgetImageProps, 'fullWidth'>>`
  padding-left: ${({ fullWidth }) => (fullWidth ? '0px' : '12px')};
  padding-right: ${({ fullWidth }) => (fullWidth ? '0px' : '12px')};
`
const Image = styled.img`
  display: block;
  height: auto;
  width: 100%;
`

export default WidgetImage
