import React from 'react'

export type DropdownConfig = {
  disableItemsAutoClose?: boolean
  onItemClick: (e: React.MouseEvent<HTMLAnchorElement | HTMLLIElement>) => void
  loading?: boolean
}

const defaultContext = {
  disableItemsAutoClose: false,
  onItemClick: () => {},
  disabled: false,
  loading: false,
}

export const DropdownContext =
  React.createContext<DropdownConfig>(defaultContext)

export const useDropdownContext = () =>
  React.useContext<DropdownConfig>(DropdownContext)
