import * as React from 'react'
import type { SVGProps } from 'react'
const PollsFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M27.65.54a4.35 4.35 0 0 0-4.35 4.35v22.22a4.35 4.35 0 0 0 8.7 0V4.89A4.35 4.35 0 0 0 27.65.54zM16 9.56a4.35 4.35 0 0 0-4.35 4.35v13.2a4.35 4.35 0 0 0 8.7 0v-13.2A4.35 4.35 0 0 0 16 9.56zM4.35 18.58A4.35 4.35 0 0 0 0 22.93v4.18a4.35 4.35 0 1 0 8.7 0v-4.18a4.35 4.35 0 0 0-4.35-4.35z' />
  </svg>
)
export default PollsFilled
