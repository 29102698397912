import FileSaver from 'file-saver'

/**
 * Converts an array of objects to a CSV string.
 *
 * @param array - The array of objects to convert.
 * @returns The CSV string representation of the array.
 */
export function arrayToCVS(array: any[]) {
  const columnDelimiter = ','
  const lineDelimiter = '\n'
  if (array.length === 0) return ''
  const keys = Object.keys(array[0])
  let result = keys.join(columnDelimiter) + lineDelimiter
  array.forEach((item) => {
    let ctr = 0
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter
      result += item[key]
      ctr++
    })
    result += lineDelimiter
  })
  return result
}

/**
 * Exports an array of data to a CSV file.
 *
 * @param array - The array of data to export.
 * @param filename - The name of the CSV file (optional).
 */
export function exportCSV(array: any[], filename?: string) {
  const csv = arrayToCVS(array)
  FileSaver.saveAs(
    encodeURI('data:text/csv;charset=utf-8,' + csv),
    filename ?? 'export.csv'
  )
}

export default { arrayToCVS, exportCSV }
