import { CSSProperties, FC, PropsWithChildren, ReactNode } from 'react'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'

export interface SelectOptionProps {
  children?: ReactNode
  className?: string
  disabled?: boolean
  isSelected: boolean
  onClick: (value: string) => void
  style?: CSSProperties
  testId?: string
  value: string
}

export const SelectOption: FC<PropsWithChildren<SelectOptionProps>> = ({
  children,
  className,
  disabled,
  isSelected,
  onClick,
  style,
  testId,
  value,
}: SelectOptionProps) => {
  const handleOnClick = () => {
    onClick?.(value)
  }
  return (
    <Option
      $isSelected={isSelected}
      aria-disabled={disabled}
      className={className}
      data-testid={testId ?? 'select-option-' + value}
      disabled={disabled}
      onClick={handleOnClick}
      style={style}
      title={value}
    >
      {children}
    </Option>
  )
}

const Option = styled.button<{ $isSelected: boolean }>`
  background: none;
  background-color: ${({ $isSelected }) =>
    $isSelected ? Colors.CATSKILL_WHITE : Colors.WHITE};
  border: none;
  cursor: pointer;
  font-family: inherit;
  min-height: 40px;

  &:hover,
  &:active {
    background-color: ${Colors.CATSKILL_WHITE};
  }

  &:not(:first-child) {
    border-top: 1px solid ${Colors.ALTO};
  }
`

export default SelectOption
