import React from 'react'
import styled from 'styled-components'
import { Colors } from '@sportsyou/core'
import Icon from '../icon'

type Direction = 'up' | 'down' | 'left' | 'right'

const getDegreeByDirection = (dir: Direction): number => {
  if (dir === 'left') return 90
  if (dir === 'up') return 180
  if (dir === 'right') return -90

  return 0
}

interface Props {
  className?: string
  direction?: Direction
  fill?: string
  size?: number
  style?: React.CSSProperties
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type ChevronProps = Props & NativeAttributes

export const Chevron: React.FC<ChevronProps> = ({
  className,
  direction = 'down',
  fill = Colors.ALTO,
  size = 12,
  style,
}: ChevronProps) => {
  return (
    <StyledIcon
      $direction={direction}
      className={className}
      fill={fill}
      name='ChevronDown'
      size={12 ?? size}
      style={style}
    />
  )
}

const StyledIcon = styled(Icon)<{
  $direction: Direction
}>`
  transform: rotateZ(
    ${({ $direction }) => getDegreeByDirection($direction)}deg
  );
  transition: transform 200ms ease;
`

export default Chevron
