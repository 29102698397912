import Accordion from './accordion'

import AccordionContent from './accordion-content'
import AccordionHeader from './accordion-header'
import AccordionTrigger from './accordion-trigger'

export type AccordionType = typeof Accordion & {
  Content: typeof AccordionContent
  Header: typeof AccordionHeader
  Trigger: typeof AccordionTrigger
}
;(Accordion as AccordionType).Content = AccordionContent
;(Accordion as AccordionType).Header = AccordionHeader
;(Accordion as AccordionType).Trigger = AccordionTrigger

export type { AccordionProps } from './accordion'

export default Accordion as AccordionType
