import { ChangeEvent, useCallback } from 'react'
import React from 'react'
import styled from 'styled-components'

export interface TextareaCenteredProps {
  backgroundColor?: string
  className?: string
  color?: string
  height?: number | string
  id?: string
  innerContainerStyle?: React.CSSProperties
  onChange?: (text: string) => void
  outerContainerStyle?: React.CSSProperties
  placeholder?: string
  textStyle?: React.CSSProperties
  value?: string
  testId?: string
  width?: number | string
}

/* A component that centers text vertically and horizontally for editing like a textarea.
 * This is mostly for use with color text post editing but may be useful elsewhere.
 *
 * @param backgroundColor - The background color of the component.
 * @param className - The class name of the component.
 * @param color - The color of the text.
 * @param height - The height of the component.
 * @param id - The id of the component.
 * @param innerContainerStyle - The style of the inner container.
 * @param onChange - The callback function when the text changes.
 * @param outerContainerStyle - The style of the outer container.
 * @param placeholder - The placeholder text.
 * @param textStyle - The style of the text.
 * @param value - The value of the text.
 * @param width - The width of the component.
 */
const TextareaCentered = React.forwardRef(
  (props: TextareaCenteredProps, ref) => {
    const {
      backgroundColor,
      className,
      color,
      height,
      id,
      innerContainerStyle,
      onChange,
      outerContainerStyle,
      placeholder,
      textStyle,
      value,
      width,
    } = props

    const onInputChange = useCallback(
      (e: ChangeEvent<HTMLDivElement>) => {
        const text = e.target.innerText || ''
        onChange?.(text)
      },
      [onChange]
    )

    const onFocus = useCallback(
      (e: React.FocusEvent<HTMLDivElement>) => {
        // remove placeholder when focused
        if (e.target.innerText === placeholder) {
          e.target.innerText = ''
        }

        // set the text to the current value
        e.target.innerText = value || ''

        // move cursor to end of text and select all
        const range = document.createRange()
        range.selectNodeContents(e.target)
        const sel = window.getSelection()
        sel?.removeAllRanges()
        sel?.addRange(range)
      },
      [placeholder, value]
    )

    const onBlur = useCallback(
      (e: React.FocusEvent<HTMLDivElement>) => {
        // set placeholder if empty
        if (e.target.innerText === '') {
          e.target.innerText = placeholder || ''
        }
      },
      [placeholder]
    )

    return (
      <OuterContainer
        backgroundColor={backgroundColor}
        className={className}
        height={height}
        style={outerContainerStyle}
        width={width}
      >
        <InnerContainer className={className} style={innerContainerStyle}>
          <ContentEditable
            color={color}
            contentEditable
            data-testid={props.testId}
            id={id}
            onBlur={onBlur}
            onFocus={onFocus}
            onInput={onInputChange}
            ref={ref as React.RefObject<HTMLDivElement>}
            style={textStyle}
          />
        </InnerContainer>
      </OuterContainer>
    )
  }
)

export default TextareaCentered

const OuterContainer = styled.div<{
  backgroundColor?: string
  height?: number | string
  width?: number | string
}>`
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  max-height: ${({ height }) => height};
  min-height: ${({ height }) => height};
  overflow: auto;
  width: ${({ width }) => width};
`

const InnerContainer = styled.div<{
  height?: number | string
  padding?: number
}>`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: calc(${({ height }) => height} - padding * 2);
  padding: ${({ padding = 0 }) => padding}px;
`

const ContentEditable = styled.div<{ color?: string }>`
  color: ${({ color }) => color};
  display: block;
  font-size: 30px;
  font-weight: 400;
  line-height: 1.4;
  min-width: 2px;
  outline: none;
  padding: 20px 0;
  resize: none;
  text-align: center;
  white-space: pre-wrap !important;
  width: 100%;
  word-break: break-word !important;
  word-wrap: break-word !important;

  &:focus span {
    display: none;
  }
`
