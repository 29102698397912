// TODO: create primary and secondary action buttons
import React from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'

import { Colors } from '@sportsyou/core'
import { IconNames } from '@sportsyou/react-icons'

import { BORDER_RADIUS } from '../../theme'

import Icon from '../icon'

type Alignment = 'center' | 'left' | 'right'
type Orientation = 'horizontal' | 'vertical'

export interface EmptyStateProps {
  alignment?: Alignment
  className?: string
  contentMaxWidth?: number | string
  /** Descriptions should add useful and relevant additional information. */
  description?: string
  descriptionClassName?: string
  descriptionStyle?: React.CSSProperties
  icon?: IconNames
  /** Ratio of container size to icon */
  iconBackgroundColor?: string
  iconContainerRatio?: number
  iconContainerStyle?: React.CSSProperties
  iconFill?: string
  iconSize?: number
  header?: string
  headerStyle?: React.CSSProperties
  headerClassName?: string
  listItems?: Array<string>
  onClick?: () => void
  orientation?: Orientation
  style?: React.CSSProperties
  testId?: string
  useMarkdown?: boolean
}

/**
 *
 * An empty state appears when there is no data to display and describes what the user can do next.
 */
export const EmptyState: React.FC<EmptyStateProps> = ({
  alignment = 'left',
  className,
  description,
  descriptionClassName,
  descriptionStyle,
  header,
  headerClassName,
  headerStyle,
  icon,
  iconBackgroundColor = Colors.MYSTIC,
  iconFill = Colors.PUNCH,
  iconSize = 30,
  iconContainerRatio = 1.8,
  iconContainerStyle,
  listItems,
  onClick,
  orientation = 'horizontal',
  style,
  testId,
  useMarkdown = false,
}: EmptyStateProps) => {
  const handleOnClick = () => {
    onClick && onClick()
  }
  return (
    <Container
      $alignment={alignment}
      className={className}
      data-testid={testId}
      onClick={handleOnClick}
      $orientation={orientation}
      role='group'
      style={style}
    >
      {icon && (
        <IconContainer
          $alignment={alignment}
          $iconBackgroundColor={iconBackgroundColor}
          $iconSize={iconSize}
          $orientation={orientation}
          $iconContainerRatio={iconContainerRatio}
          style={iconContainerStyle}
        >
          <Icon
            fill={iconFill}
            height={iconSize}
            name={icon}
            width={iconSize}
          />
        </IconContainer>
      )}
      <TextContainer>
        {header && (
          <Header className={headerClassName} style={headerStyle}>
            {useMarkdown ? (
              <ReactMarkdown children={header} components={{ p: 'span' }} />
            ) : (
              header
            )}
          </Header>
        )}
        {description && (
          <Description
            className={descriptionClassName}
            style={descriptionStyle}
          >
            {useMarkdown ? (
              <ReactMarkdown
                children={description}
                components={{ p: 'span' }}
              />
            ) : (
              description
            )}
          </Description>
        )}
        {listItems && listItems.length > 0 && (
          <List>
            {listItems.map((item, index) => (
              <ListItem key={index}>
                <Description>
                  {useMarkdown ? (
                    <ReactMarkdown children={item} components={{ p: 'span' }} />
                  ) : (
                    item
                  )}
                </Description>
              </ListItem>
            ))}
          </List>
        )}
      </TextContainer>
    </Container>
  )
}
const Container = styled.div<{
  $alignment: Alignment
  $orientation: Orientation
}>`
  align-items: ${({ $alignment, $orientation }) =>
    $alignment === 'center' || $orientation === 'horizontal'
      ? 'center'
      : $alignment === 'left'
      ? 'flex-start'
      : 'flex-end'};

  // border: 1px solid ${Colors.MINE_SHAFT};
  border-radius: ${BORDER_RADIUS};
  display: inline-flex;
  flex-direction: ${({ $orientation }) =>
    $orientation === 'horizontal' ? 'row' : 'column'};
  padding: 10px;
  background-color: ${Colors.HAVELOCK_BLUE};
  text-align: ${({ $alignment, $orientation }) =>
    $alignment
      ? $alignment
      : $orientation === 'horizontal'
      ? 'left'
      : 'center'};
`
const IconContainer = styled.div<{
  $alignment: Alignment
  $iconSize: number
  $iconBackgroundColor: string
  $iconContainerRatio: number
  $orientation: Orientation
}>`
  align-items: center;
  background-color: ${({ $iconBackgroundColor }) => $iconBackgroundColor};

  border-radius: 50%;
  box-sizing: border-box;
  flex: 0 0 auto;
  display: inline-flex;
  order: ${({ $alignment, $orientation }) =>
    $alignment === 'right' && $orientation === 'horizontal' ? 2 : undefined};
  justify-content: center;
  height: ${({ $iconContainerRatio, $iconSize }) =>
    $iconSize * $iconContainerRatio}px;
  margin-bottom: ${({ $orientation }) =>
    $orientation === 'vertical' ? '10px' : undefined};
  margin-left: ${({ $alignment, $orientation }) =>
    $alignment === 'right' && $orientation === 'horizontal'
      ? '10px'
      : undefined};
  margin-right: ${({ $alignment, $orientation }) =>
    $alignment !== 'right' && $orientation === 'horizontal'
      ? '10px'
      : undefined};
  padding: 10px;
  width: ${({ $iconContainerRatio, $iconSize }) =>
    $iconSize * $iconContainerRatio}px;
`
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const Header = styled.h2`
  color: ${Colors.WHITE};
  font-family: 'Cocogoose Pro', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
`
const List = styled.ul`
  marign-bottom: 0;
  margin-top: 0;
  padding-left: 1rem;
`
const ListItem = styled.li`
  color: ${Colors.WHITE};
`
const Description = styled.p`
  color: ${Colors.WHITE};
  // font-family: 'Figtree', sans-serif;
  font-size: 1rem;
  margin-bottom: 0;
  margin-top: 8px;
`

export default EmptyState
