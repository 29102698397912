type Colorspace = 'hex' | 'hsl' | 'hsla' | 'rgb' | 'rgba'

const getColorspace = (color: string): Colorspace =>
  color.startsWith('#')
    ? 'hex'
    : color.startsWith('rgba')
    ? 'rgba'
    : color.startsWith('hsla')
    ? 'hsla'
    : color.startsWith('hsl')
    ? 'hsl'
    : 'rgb'

export const colorToHex = (color: string): string => {
  const colorspace = getColorspace(color)
  if (colorspace === 'hex') return color

  const hasAlpha = ['rgba', 'hsla'].includes(colorspace)
  let alpha

  const regEx = new RegExp(/[a-zA-z]+[(]|[)]/)
  const colorValues = color
    .split(',')
    .map((val) => val.replace(regEx, '').trim())
  if (hasAlpha) {
    alpha = colorValues[3]
    colorValues.pop()
  }

  let hex = ''
  if (['rgb', 'rgba'].includes(colorspace)) {
    hex = convertRGBtoHex(colorValues, alpha)
  } else if (['hsl', 'hsla'].includes(colorspace)) {
    hex = convertHSLtoHex(colorValues, alpha)
  }

  return hex
}

const convertRGBtoHex = (values: Array<string>, alpha?: string) => {
  let r = Number(values[0]).toString(16)
  let g = Number(values[1]).toString(16)
  let b = Number(values[2]).toString(16)

  if (r.length === 1) {
    r = '0' + r
  }
  if (g.length === 1) {
    g = '0' + g
  }
  if (b.length === 1) {
    b = '0' + b
  }
  let a = ''
  if (alpha) {
    a = convertAlphaToHex(alpha)
    return `#${r + g + b + a}`
  }

  return `#${r + g + b}`
}

const convertHSLtoHex = (values: Array<string>, alpha?: string) => {
  const h = Number(values[0])
  let s = Number(values[1].includes('%') ? parseFloat(values[1]) : values[1])
  let l = Number(values[2].includes('%') ? parseFloat(values[2]) : values[2])

  s /= 100
  l /= 100

  const c = (1 - Math.abs(2 * l - 1)) * s
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1))
  const m = l - c / 2

  let rValue = 0
  let gValue = 0
  let bValue = 0

  if (0 <= h && h < 60) {
    rValue = c
    gValue = x
    bValue = 0
  } else if (60 <= h && h < 120) {
    rValue = x
    gValue = c
    bValue = 0
  } else if (120 <= h && h < 180) {
    rValue = 0
    gValue = c
    bValue = x
  } else if (180 <= h && h < 240) {
    rValue = 0
    gValue = x
    bValue = c
  } else if (240 <= h && h < 300) {
    rValue = x
    gValue = 0
    bValue = c
  } else if (300 <= h && h < 360) {
    rValue = c
    gValue = 0
    bValue = x
  }

  // Having obtained RGB, convert channels to hex
  rValue = Math.round((rValue + m) * 255)
  gValue = Math.round((gValue + m) * 255)
  bValue = Math.round((bValue + m) * 255)

  let r = rValue.toString(16)
  let g = gValue.toString(16)
  let b = bValue.toString(16)

  // Prepend 0s, if necessary
  if (r.length === 1) r = '0' + r
  if (g.length === 1) g = '0' + g
  if (b.length === 1) b = '0' + b

  let a = ''
  if (alpha) {
    a = convertAlphaToHex(alpha)
    return `#${r + g + b + a}`
  }

  return `#${r + g + b}`
}

const convertAlphaToHex = (alpha: string) => {
  // convert to number
  const alphaNumber = Number(alpha)
  let a = Math.round(alphaNumber * 255).toString(16)
  if (a.length === 1) {
    a = '0' + a
  }

  return a
}

export default colorToHex
