import { CSSProperties, FC, ReactNode, PropsWithChildren } from 'react'
import styled from 'styled-components'
import Button from '../button'
import NavbarBrand, { BrandProduct } from './navbar-brand'

export interface NavbarLeftProps {
  brand?: boolean
  backgroundColor?: string
  brandHref?: string
  children?: ReactNode
  className?: string
  color?: string
  mobileBreakpoint?: number
  onClickBrand?: () => void
  onClickHamburger?: () => void
  preBrandContent?: React.ReactNode
  product?: BrandProduct
  showHamburger?: boolean
  style?: CSSProperties
}

export const NavbarLeft: FC<PropsWithChildren<NavbarLeftProps>> = ({
  backgroundColor,
  brand,
  brandHref,
  children,
  className,
  color,
  mobileBreakpoint,
  onClickBrand,
  onClickHamburger,
  preBrandContent,
  product,
  showHamburger,
  style,
}: NavbarLeftProps) => (
  <Container className={className} style={style}>
    {showHamburger && (
      <HamburgerButton
        appearance='minimal'
        collapse
        onClick={onClickHamburger}
        textStyle={{
          alignItems: 'center',
          display: 'flex',
          fontSize: 24,
          justifyContent: 'center',
        }}
        variant='secondary'
      >
        <Hamburger color={color} />
      </HamburgerButton>
    )}
    {preBrandContent}
    {brand && (
      <NavbarBrand
        color={color}
        href={brandHref}
        mobileBreakpoint={mobileBreakpoint}
        onClick={onClickBrand}
        product={product}
      />
    )}
    {children && <Content>{children}</Content>}
  </Container>
)

const Container = styled.div`
  align-items: center;
  display: flex;
`
const Content = styled.div`
  margin-left: 10px;
`
const HamburgerButton = styled(Button)`
  border-radius: 50%;
  margin-right: 6px;
  @media print {
    display: none;
  }
`
const Hamburger = styled.div<{ color?: string }>`
  background-color: ${({ color }) => color ?? 'currentColor'};
  height: 2px;
  position: relative;
  width: 18px;
  &::before,
  &::after {
    content: '';
    background-color: inherit;
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
  }
  &::before {
    top: -6px;
  }
  &::after {
    bottom: -6px;
  }
`

export default NavbarLeft
