import { CSSProperties } from 'react'
import {
  BsArrowRepeat,
  BsCheck2Circle,
  BsClock,
  BsExclamationCircle,
  BsQuestionCircle,
} from 'react-icons/bs'
import { Colors } from '@sportsyou/core'
import { ActivityTypes, StatusTypes } from '../prop-types'
import Spinner from '../../components/spinner'

type StatusIconProps = {
  fill?: string
  size: number | string
  style?: CSSProperties
}

export const getStatusIcon = (
  status: StatusTypes | ActivityTypes,
  props: StatusIconProps = {
    fill: Colors.SHUTTLE_GRAY,
    size: 14,
  }
) => {
  if (status === 'success') {
    return <BsCheck2Circle {...props} />
  } else if (status === 'alert') {
    return <BsExclamationCircle {...props} />
  } else if (status === 'danger') {
    return <BsExclamationCircle {...props} />
  } else if (status === 'info') {
    return <BsQuestionCircle {...props} />
  } else if (status === 'loading') {
    return <Spinner {...props} />
  } else if (status === 'processing') {
    return <BsArrowRepeat {...props} />
  } else if (status === 'waiting') {
    return <BsClock {...props} />
  }
}

export default getStatusIcon
