import * as React from 'react'
import type { SVGProps } from 'react'
const NavJerseyFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M6.94 27.55A2.45 2.45 0 0 0 9.39 30h13.22a2.45 2.45 0 0 0 2.45-2.45v-2H6.94zM6.94 19.99h18.11v3.6H6.94zM27.81 4.18l-4.18-1.94a2.65 2.65 0 0 0-2.71.3L16 6.31l-4.76-3.64a3 3 0 0 0-3.07-.34l-4 1.85a5.4 5.4 0 0 0-3.1 4.89v4.07a2.45 2.45 0 0 0 2.44 2.45h3.43V18h18.12v-2.41h3.43a2.45 2.45 0 0 0 2.44-2.45V9.07a5.4 5.4 0 0 0-3.12-4.89z' />
  </svg>
)
export default NavJerseyFilled
