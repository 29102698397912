import { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment'

import { Colors } from '@sportsyou/core'
import { useMediaQuery } from '@sportsyou/react-hooks'
import { X } from '@sportsyou/react-icons'

import Button from '../../button'
import DatePicker from '../../date-picker'
import Icon from '../../icon'

import { SMALL_SCREEN_BREAKPOINT } from '../../../theme'
import { useDialog } from '../../../hooks'

interface SchedulePostOptionsProps {
  onDateTimeChange: (scheduledTime?: Date) => void
  scheduledTime?: Date
}

export const SchedulePostOptions = ({
  onDateTimeChange,
  scheduledTime,
}: SchedulePostOptionsProps) => {
  const { sendBanner } = useDialog()

  const isSmallScreen = useMediaQuery(`(max-width: ${SMALL_SCREEN_BREAKPOINT}`)

  const onChangeDateTime = useCallback(
    (date: Date) => {
      if (date < getMinScheduleDateTime()) {
        sendBanner({
          autoDismiss: true,
          dismissTime: 5000,
          status: 'alert',
          message:
            'Post schedule date/time chosen is invalid. Schedule date/time has been reset to minimum allowed schedule date/time',
        })
        onDateTimeChange(getMinScheduleDateTime())
      } else if (date > getMaxScheduleDateTime()) {
        sendBanner({
          autoDismiss: true,
          dismissTime: 5000,
          status: 'alert',
          message:
            'Post schedule date/time chosen is invalid. Schedule date/time has been reset to maximum allowed schedule date/time',
        })
        onDateTimeChange(getMaxScheduleDateTime())
      } else {
        onDateTimeChange(date)
      }
    },
    [onDateTimeChange]
  )

  const onClickShowScheduledTime = useCallback(() => {
    onDateTimeChange(getDefaultScheduleDateTime())
  }, [onDateTimeChange])

  const onClickToggleScheduledTime = useCallback(() => {
    onDateTimeChange(scheduledTime ? undefined : new Date())
  }, [onDateTimeChange, scheduledTime])

  /**
   * from web v1 rule to set default schedule time to 15 min from now
   * @returns
   */
  function getDefaultScheduleDateTime() {
    const defaultScheduleDateTime = moment().endOf('hour').add(1, 'ms')
    if (defaultScheduleDateTime.minutes() % 15 !== 0) {
      defaultScheduleDateTime.add(
        15 - (defaultScheduleDateTime.minutes() % 15),
        'minutes'
      )
    }

    return moment(defaultScheduleDateTime).toDate()
  }
  /**
   * from web v1 rule to set min settable schedule time to 5 min from now
   * @returns
   */
  function getMinScheduleDateTime() {
    const minScheduleDateTime = moment()
      .add(15, 'minutes')
      .endOf('minute')
      .add(1, 'ms')

    return moment(minScheduleDateTime).toDate()
  }

  /**
   * from web v1 rule to set max settable schedule time to 1 year from now
   * @returns
   */
  function getMaxScheduleDateTime() {
    const maxScheduleDateTime = moment()
      .add(1, 'year')
      .add(5, 'minutes')
      .endOf('minute')
      .add(1, 'ms')

    return moment(maxScheduleDateTime).toDate()
  }

  return (
    <SchedulePostContainer>
      <Button
        appearance='minimal'
        onClick={onClickShowScheduledTime}
        textStyle={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Icon
          name='Clock'
          fill={Colors.HAVELOCK_BLUE}
          size={20}
          style={{ marginRight: 8 }}
        />
        Schedule Post
      </Button>

      {scheduledTime && (
        <DatePickerContainer>
          <DatePicker
            dateFormat='MMMM d, yyyy h:mm a'
            datePickerStyle={{
              minWidth: 240,
              textAlign: isSmallScreen ? 'left' : 'right',
            }}
            onChange={onChangeDateTime}
            placement='top'
            selected={scheduledTime}
            showTimeSelect
          />
          <CloseButton
            appearance='minimal'
            collapse
            onClick={onClickToggleScheduledTime}
          >
            <X />
          </CloseButton>
        </DatePickerContainer>
      )}
    </SchedulePostContainer>
  )
}

const SchedulePostContainer = styled.div`
  align-items: center;
  border-top: 1px solid ${Colors.ALTO};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  margin-bottom: 6px;
  @media all and (max-width: 767px) {
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }
  @media all and (min-width: 768px) {
    margin-bottom: 10px;
    padding-top: 10px;
  }
`
const DatePickerContainer = styled.div`
  align-items: center;
  display: flex;
`
const CloseButton = styled(Button)`
  border-radius: 50%;
  margin-left: 6px;
`

export default SchedulePostOptions
