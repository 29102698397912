import Calendar from './calendar'
import { Event } from '@sportsyou/api'

export type { CalendarProps } from './calendar'

export type { DayCalendarProps } from './day/day-calendar'
export type { WeekCalendarProps } from './week/week-calendar'
export type { MonthCalendarProps } from './month/month-calendar'

export type CalendarMode = 'day' | 'week' | 'month' | 'year'

export interface DayProps {
  date: Date
  day: string
  events?: Array<Event>
  type: 'previous' | 'current' | 'next'
}

export default Calendar
