import { useEffect, useState } from 'react'

import { randomId } from '@sportsyou/core'

const createDivElement = (id: string): HTMLElement => {
  const el = document.createElement('div')
  el.setAttribute('id', id)
  return el
}

const usePortal = (
  selectId: string = randomId(),
  getContainer?: () => HTMLElement | null
): HTMLElement | null => {
  const id = `ui-${selectId}`

  const [elementSnapshot, setElementSnapshot] = useState<HTMLElement | null>(
    createDivElement(id)
  )

  useEffect(() => {
    const container = getContainer ? getContainer() : null
    const parentElem = container || document.body
    const hasElem = parentElem.querySelector<HTMLElement>(`#${id}`)
    const el = hasElem || createDivElement(id)

    if (!hasElem) {
      parentElem.appendChild(el)
    }
    setElementSnapshot(el)
  }, [])

  return elementSnapshot
}

export default usePortal
