import * as React from 'react'
import type { SVGProps } from 'react'
const FolderAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M27.6364 7.97839h-8.3491s-.0291 0-.0582-.04329l-2.5018-2.65464c-.1309-.14427-.3346-.23084-.5382-.23084H4.36363c-.40728 0-.72728.3174-.72728.72137V25.0027c0 .404.32.7214.72728.7214H27.6364c.4072 0 .7272-.3174.7272-.7214V8.69976c0-.40397-.32-.72137-.7272-.72137Zm-.7273 16.28851H5.0909V6.49236h10.7927l2.2982 2.43824h.0145c.131.14427.291.27412.4946.36068.2036.08657.4218.12985.6254.12985h7.5782V24.2669h.0146Z' />
  </svg>
)
export default FolderAlt
