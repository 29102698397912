import { useState } from 'react'
import styled from 'styled-components'

import { BORDER_RADIUS, CoverImage, useDialog } from '@sportsyou/react-dom-ui'
import { Colors } from '@sportsyou/core'
import {
  Upload,
  mutationCommunitySetPhoto,
  mutationTeamSetPhoto,
  mutationUserSetPhoto,
} from '@sportsyou/api'
import { ExtendedUpload, useFetchApi } from '@sportsyou/react-hooks'

type ImageUploadType = 'avatar' | 'cover-image'

interface ProfileHeaderProps {
  avatarImageSrc?: string
  canEditCoverImage?: boolean
  coverImageSrc?: string
  isAvatarLoading?: boolean
  isCoverImageLoading?: boolean
  name?: string
  onTranscodeComplete?: (upload?: Upload, type?: ImageUploadType) => void
  onUploadCancel?: (type: ImageUploadType) => void
  onUploadDone?: (type: ImageUploadType) => void
  onUploadError?: (type: ImageUploadType) => void
  onUploadStart?: (uploads: Upload[], type: ImageUploadType) => void
  profileId?: string
  type: 'group' | 'team' | 'page' | 'profile'
}

export default function ProfileHeader(props: ProfileHeaderProps) {
  const { sendBanner } = useDialog()

  const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(false)
  const [isCoverImageLoading, setIsCoverImageLoading] = useState<boolean>(false)

  const { fetch: setPageImage } = useFetchApi(mutationCommunitySetPhoto)
  const { fetch: setProfileImage } = useFetchApi(mutationUserSetPhoto)
  const { fetch: setTeamImage } = useFetchApi(mutationTeamSetPhoto)

  const handleOnTranscodeComplete = async (
    upload?: Upload | ExtendedUpload,
    type?: ImageUploadType
  ) => {
    let setImage = setProfileImage
    const params = {
      communityId: undefined as string | undefined,
      imageType: type === 'avatar' ? 1 : 2,
      teamId: undefined as string | undefined,
      uploadId: upload?.id,
    }
    if (props.type === 'team') {
      setImage = setTeamImage
      params['teamId'] = props.profileId
    } else if (props.type === 'page') {
      setImage = setPageImage
      params['communityId'] = props.profileId
    }

    const { data, error, ok } = await setImage(params)
    if (data && ok) {
      sendBanner({ autoDismiss: true, status: 'success', message: data })
    } else if (error) {
      sendBanner({
        message: 'There was an error, please try again later',
        status: 'danger',
      })
      console.log({ error })
    }

    if (type === 'avatar') {
      setIsAvatarLoading(false)
    }
    if (type === 'cover-image') {
      setIsCoverImageLoading(false)
    }

    props.onTranscodeComplete?.(upload as Upload, type)
  }

  const handleOnUploadCancel = (type: ImageUploadType) => {
    if (type === 'avatar') {
      setIsAvatarLoading(false)
    }
    if (type === 'cover-image') {
      setIsCoverImageLoading(false)
    }
    props.onUploadCancel?.(type)
  }

  const handleOnUploaderError = (type: ImageUploadType) => {
    if (type === 'avatar') {
      setIsAvatarLoading(false)
    }
    if (type === 'cover-image') {
      setIsCoverImageLoading(false)
    }
    props.onUploadError?.(type)
  }

  const handleOnUploadStart = (uploads: Upload[], type: ImageUploadType) => {
    if (type === 'avatar') {
      setIsAvatarLoading(true)
    }
    if (type === 'cover-image') {
      setIsCoverImageLoading(true)
    }
    props.onUploadStart?.(uploads, type)
  }

  const handleOnUploadDone = (type: ImageUploadType) => {
    props.onUploadDone?.(type)
  }

  return (
    <Header>
      <CoverImage
        avatarImageSrc={props.avatarImageSrc}
        avatarImageUploaderProps={{
          cancel: () => handleOnUploadCancel('avatar'),
          error: () => handleOnUploaderError('avatar'),
          start: (uploads) => handleOnUploadStart(uploads, 'avatar'),
          done: () => handleOnUploadDone('avatar'),
          transcode: (upload) => handleOnTranscodeComplete(upload, 'avatar'),
        }}
        canEdit={props.canEditCoverImage}
        coverImageSrc={props.coverImageSrc}
        coverImageUploaderProps={{
          cancel: () => handleOnUploadCancel('cover-image'),
          error: () => handleOnUploaderError('cover-image'),
          start: (uploads) => handleOnUploadStart(uploads, 'cover-image'),
          done: () => handleOnUploadDone('cover-image'),
          transcode: (upload) =>
            handleOnTranscodeComplete(upload, 'cover-image'),
        }}
        isUploadingAvatar={isAvatarLoading}
        isUploadingCoverImage={isCoverImageLoading}
        name={props.name}
        variant={props.type}
      />
    </Header>
  )
}

const Header = styled.header`
  border: 1px solid ${Colors.ALTO};
  border-bottom: 0;
  border-top-left-radius: ${BORDER_RADIUS};
  border-top-right-radius: ${BORDER_RADIUS};
  overflow: hidden;
`
