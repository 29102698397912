import React, { FC } from 'react'
import styled from 'styled-components'

import BreadcrumbsItem, { BreadcrumbsItemProps } from './breadcrumbs-item'

export interface BreadcrumbsProps {
  className?: string
  divider?: string | React.ReactNode
  dividerClassName?: string
  dividerStyle?: React.CSSProperties
  id?: string
  items: Array<BreadcrumbsItemProps>
  style?: React.CSSProperties
}

/**
 * Breadcrumbs provide contextual information about page hierarchy.
 */
export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  className,
  divider = '/',
  dividerClassName,
  dividerStyle,
  id,
  style,
  items,
}: BreadcrumbsProps) => {
  return (
    <Nav className={className} id={id} style={style}>
      {items.map((item, index: number) => {
        const isLastItem = index === items.length - 1
        return (
          <React.Fragment key={index}>
            <BreadcrumbsItem
              className={item.className}
              hideDecorator={item.hideDecorator}
              href={item.href}
              icon={item.icon}
              isLastItem={isLastItem}
              onClick={item.onClick}
              style={item.style}
              target={item.target}
              text={item.text}
              title={item.title}
            />

            {!isLastItem && (
              <Divider className={dividerClassName} style={dividerStyle}>
                {divider}
              </Divider>
            )}
          </React.Fragment>
        )
      })}
    </Nav>
  )
}

const Nav = styled.nav`
  align-items: center;
  display: inline-flex;
`

const Divider = styled.span`
  margin-left: 4px;
  margin-right: 4px;
`

export default Breadcrumbs
