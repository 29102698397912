import { Link, useNavigate, useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'

import { Button, Icon, Tabs, useDialog } from '@sportsyou/react-dom-ui'
import { Colors } from '@sportsyou/core'
import { Community, Post } from '@sportsyou/api'
import { Trash } from '@sportsyou/react-icons'

import { Header, HeaderTitle } from '../Profile/Profile'
import PagesPostMetrics from './Metrics'
import usePages from '../UsePagesPortal'
import {
  ContentContainer,
  HeaderTitleChevron,
} from '../../../styles/global-styles'

interface Props {
  community?: Community
}

export default function PostDetail(props: Props) {
  const { sendConfirm } = useDialog()
  const navigate = useNavigate()
  const params = useParams()

  const { deletePagePost, page, getPost } = usePages({
    communityId: params.id,
  })

  const [post, setPost] = useState<Post | undefined>(undefined)

  const handleDeletePost = useCallback(() => {
    sendConfirm({
      confirmText: 'Delete',
      isDestructive: true,
      message:
        'Do you want to delete the post? You will lose all data below once you delete it.',
      title: 'Delete Post?',
      onConfirm: async () => {
        await deletePagePost(params.postId as string)
        navigate(`/pages/${params.id}/posts`)
      },
    })
  }, [deletePagePost, navigate, params.id, params.postId, sendConfirm])

  useEffect(() => {
    const loadPost = async () => {
      const currentPost = getPost(params.postId as string)
      setPost(currentPost)
    }
    loadPost()
  }, [getPost, params.postId])

  if (!params.postId) {
    return <h1>Post not found</h1>
  }

  return (
    <ContentContainer>
      <Header>
        <HeaderTitle>
          <Link to={'/pages'} title='Pages Content Portal'>
            <Icon name='Home' fill={Colors.BLACK} width={18} />
          </Link>
          <span>
            <HeaderTitleChevron />
            <Link to={`/pages/${params.id}`} title={page.name as string}>
              {page.name}
            </Link>
          </span>
          <span>
            <HeaderTitleChevron />
            <Link to={`/pages/${params.id}/posts`} title='Posts'>
              Posts
            </Link>
          </span>
          <span>
            <HeaderTitleChevron />
            {post?.communityPostInfo?.postTitle ?? 'Post Detail'}
          </span>
        </HeaderTitle>
      </Header>

      <Tabs index={0} contentStyle={tabsContentStyle}>
        <Tabs.Item title='Metrics'>
          <PagesPostMetrics page={page} postId={params.postId} />
        </Tabs.Item>
        {/* <Tabs.Item title="Moderate">
          <PagesPostModerate postId={params.postId} />
        </Tabs.Item> */}
        <Tabs.Item
          title='Edit'
          onClick={() =>
            navigate(`/pages/${params.id}/posts/${params.postId}/edit`)
          }
        ></Tabs.Item>
        <Tabs.ExtraContent>
          {page.canPost ? (
            <Button
              appearance='ghost'
              onClick={handleDeletePost}
              style={{ marginLeft: 10 }}
              variant='danger'
            >
              <Trash style={{ marginRight: 10 }} fill={Colors.PUNCH} />
              Delete Post
            </Button>
          ) : null}
        </Tabs.ExtraContent>
      </Tabs>
    </ContentContainer>
  )
}

const tabsContentStyle = {
  padding: '20px 0',
}
