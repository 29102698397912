import * as React from 'react'
import type { SVGProps } from 'react'
const Pages = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M25.0474 6.53553c-.48-1.54374-1.9055-2.66907-3.6364-2.66907H7.44739c-2.12364 0-3.81092 1.70243-3.81092 3.77998V21.4968c0 1.9332 1.45455 3.4914 3.31637 3.7366.48 1.5438 1.90545 2.6547 3.63636 2.6547h12.4945c2.9091 0 5.2655-2.3372 5.2655-5.2227V10.2722c0-1.91882-1.4545-3.49141-3.3164-3.73667h.0146ZM7.44739 23.834c-1.3091 0-2.35637-1.0388-2.35637-2.3372V7.64644c0-1.29847 1.04727-2.33724 2.35637-2.33724H21.411c1.1927 0 2.1673.86564 2.3273 1.99098v.04328c.0291.08657.0291.20199.0291.30298V21.4968c0 1.284-1.0473 2.3372-2.3564 2.3372H7.44739ZM26.8947 10.2722v12.4076c0 2.0775-1.7019 3.78-3.811 3.78H10.5892c-.87272 0-1.62909-.4761-2.03636-1.1686H21.4256c2.1091 0 3.8109-1.7025 3.8109-3.78V8.0504c.9454.28855 1.6436 1.1542 1.6582 2.1785v.0433Z' />
  </svg>
)
export default Pages
