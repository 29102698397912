import { Image, Upload } from '@sportsyou/api'
import { isImage, isVideo } from '.'

import { getContentType } from './get-content-type'
import { getTranscodeUrl } from './get-transcode-url'

export const getThumbnail = (upload: Upload): string => {
  const _isImage = isImage(getContentType(upload))
  const _isVideo = isVideo(getContentType(upload))

  let url: string = upload.viewUrl ?? ''

  switch (true) {
    case _isVideo: {
      const videoThumb = getTranscodeUrl({
        transcodeTypes: ['THUMB'],
        upload: upload.transcodes as Array<Image>,
      })
      url = videoThumb || url
      break
    }
    case _isImage: {
      const mediaImage = getTranscodeUrl({
        transcodeTypes: ['media'],
        upload: upload.transcodes as Array<Image>,
      })
      url = mediaImage || url
      if (!mediaImage) {
        const feedImage = getTranscodeUrl({
          transcodeTypes: ['feed'],
          upload: upload.transcodes as Array<Image>,
        })
        url = feedImage || url
        if (!feedImage) {
          const feedLargeImage = getTranscodeUrl({
            transcodeTypes: ['feedLarge'],
            upload: upload.transcodes as Array<Image>,
          })
          url = feedLargeImage || url
        }
      }
      break
    }
    default:
  }

  return url
}

export default getThumbnail
