import React, {
  CSSProperties,
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
} from 'react'
import styled from 'styled-components'
import { Colors } from '@sportsyou/core'

import Backdrop from '../backdrop'
import Transition from '../css-transition'
import ModalHeader from './modal-header'
import ModalBody from './modal-body'
import ModalFooter from './modal-footer'

import { getChildrenFromReactNode, getPropsFromReactNode } from '../../utils'
import { usePortal } from '@sportsyou/react-hooks'
import { createPortal } from 'react-dom'

export interface ModalProps {
  backdropClassName?: string
  /** Prevent dismiss when clicking on backdrop */
  backdropIgnoresClicks?: boolean
  /**
   * Custom styles for modal body
   * @deprecated You can now add style prop directly to Modal.Body
   */
  bodyStyles?: CSSProperties
  className?: string
  contentWidth?: number | string
  /** Custom styles for modal footer
   * @deprecated You can now add style prop directly to Modal.Footer
   */
  footerStyles?: CSSProperties
  fullscreen?: boolean
  /** Custom styles for modal header
   * @deprecated You can now add style prop directly to Modal.Header
   */
  headerStyles?: CSSProperties
  /** Callback when modal is closed */
  onClose: () => void
  /** Callback when modal is in transition of closing */
  onClosing?: () => void
  /** Callbak when modal is opened */
  onOpen?: () => void
  /** Callback when modal is in transition of opening */
  onOpening?: () => void
  testId?: string
  transitionTime?: number
  // /** Width of modal (number in px | string) */
  /** Whether the modal is visible or not */
  visible: boolean
}

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  backdropClassName,
  backdropIgnoresClicks = false,
  bodyStyles,
  children,
  className,
  contentWidth = 560,
  footerStyles,
  fullscreen,
  headerStyles,
  onClose,
  onClosing,
  onOpen,
  onOpening,
  testId,
  transitionTime = 160,
  visible = false,
}) => {
  const el = usePortal('modal')

  const headerNode = useMemo(
    () =>
      React.Children.map(children, (item) => {
        if (!React.isValidElement(item)) return null
        if (item.type === ModalHeader) {
          return item
        }
        return null
      }),
    [children]
  )
  const bodyNode = useMemo(
    () =>
      React.Children.map(children, (item) => {
        if (!React.isValidElement(item)) return null
        if (item.type === ModalBody) {
          return item
        }
        return null
      }),
    [children]
  )
  const footerNode = useMemo(
    () =>
      React.Children.map(children, (item) => {
        if (!React.isValidElement(item)) return null
        if (item.type === ModalFooter) {
          return item
        }
        return null
      }),
    [children]
  )

  const contentWidthAsString = useMemo(
    () =>
      typeof contentWidth === 'string' ? contentWidth : `${contentWidth}px`,
    [contentWidth]
  )

  // const [isVisible, setIsVisible] = useState<boolean>(visible)

  useEffect(() => {
    if (visible) {
      onOpening && onOpening()
      setTimeout(() => {
        onOpen && onOpen()
      }, transitionTime)
    }
  }, [visible])

  const closeModal = () => {
    onClosing && onClosing()
    setTimeout(() => {
      onClose && onClose()
    }, transitionTime)
  }

  const handleOnClickBackdrop = () => {
    !backdropIgnoresClicks && closeModal()
  }

  if (!el) return null

  return createPortal(
    <Backdrop
      className={backdropClassName}
      fullscreenContent={fullscreen}
      onClick={handleOnClickBackdrop}
      visible={visible}
    >
      <Transition name='modal' enterTime={transitionTime} visible={visible}>
        <Container
          className={className}
          contentWidth={contentWidthAsString}
          data-testid={testId}
          fullscreen={fullscreen}
          role='dialog'
        >
          {headerNode?.map((node, index) => (
            <ModalHeader
              key={index}
              onClick={closeModal}
              style={headerStyles}
              {...getPropsFromReactNode(node)}
            >
              {getChildrenFromReactNode(node)}
            </ModalHeader>
          ))}

          {bodyNode?.map((node, index) => (
            <ModalBody
              key={index}
              onClickClose={closeModal}
              style={bodyStyles}
              {...getPropsFromReactNode(node)}
            >
              {getChildrenFromReactNode(node)}
            </ModalBody>
          ))}

          {footerNode?.map((node, index) => (
            <ModalFooter
              key={index}
              style={footerStyles}
              {...getPropsFromReactNode(node)}
            >
              {getChildrenFromReactNode(node)}
            </ModalFooter>
          ))}
        </Container>
      </Transition>
    </Backdrop>,
    el
  )
}

const Container = styled.div<Pick<ModalProps, 'contentWidth' | 'fullscreen'>>`
  border-radius: ${({ fullscreen }) => (fullscreen ? undefined : '6px')};
  box-shadow: 0 2px 20px rgb(0 0 0 / 20%);
  background-color: ${Colors.WHITE};
  display: flex;
  flex-direction: column;
  height: ${({ fullscreen }) => (fullscreen ? '100vh' : undefined)};
  max-height: ${({ fullscreen }) => (fullscreen ? 100 : 80)}vh;
  max-width: ${({ fullscreen }) => (fullscreen ? 100 : 90)}vw;
  width: ${({ contentWidth, fullscreen }) =>
    fullscreen ? '100%' : contentWidth};
  // font-family: inherit;

  transition: opacity 300ms ease, transform 300ms ease;

  opacity: 0;
  transform: translate3d(0, 30px, 0);
  &.modal-enter {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
  }
  &.modal-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.modal-exit-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.modal-exit {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
  }
`

export default Modal
