import * as React from 'react'
import type { SVGProps } from 'react'
const Download = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 500 500'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M463.1 331.6c-20.4 0-36.9 16.5-36.9 36.9v57.6H73.9v-57.6c0-20.4-16.5-36.9-36.9-36.9-20.5 0-37 16.5-37 36.9V463c0 20.5 16.5 37 36.9 37H463c20.4 0 36.9-16.5 36.9-36.9v-94.5c.1-20.5-16.4-37-36.8-37z' />
    <path d='M247 386.3c1.7 1.7 4.4 1.7 6 0l117-117c.5-.5.7-1.4.4-2.1-.3-.7-1-1.2-1.8-1.2h-65V17.1c0-8.9-7.2-16.1-16.1-16.1h-75.3c-8.9 0-16.1 7.2-16.1 16.1V266h-65c-.8 0-1.5.5-1.8 1.2-.3.7-.1 1.6.4 2.1l117.3 117z' />
  </svg>
)
export default Download
