import { useMemo } from 'react'
import styled from 'styled-components'

import { Campaign, CampaignAssetDisplay } from '@sportsyou/api'
import { Colors } from '@sportsyou/core'
import { screenLocationOptions } from '../CampaignForm/CampaignFormAssets'
import { Icon } from '@sportsyou/react-dom-ui'

interface CampaignPreviewProps {
  asset: CampaignAssetDisplay
  campaign: Campaign
  onClickNext: () => void
  onClickPrevious: () => void
  showNavButtons?: boolean
}

export default function CampaignPreview(props: CampaignPreviewProps) {
  const backgroundImage = useMemo(() => {
    return `/assets/images/preview-${props.asset.screenLocation}.png`
  }, [props.asset.screenLocation])

  const assetPosition = useMemo(() => {
    let top = '0px'
    const left = '0px'
    if (props.asset.screenLocation === 'loadingScreen') {
      top = '550px'
    } else if (props.asset.screenLocation === 'calendarScreen') {
      top = '80px'
    } else if (props.asset.screenLocation === 'chatListScreen') {
      top = '80px'
    } else if (props.asset.screenLocation === 'teamListScreen') {
      top = '80px'
    } else if (props.asset.screenLocation === 'mainFeedScreen') {
      top = '135px'
    }
    return {
      top,
      left,
    }
  }, [props.asset.screenLocation])

  const activeScreenLocation = useMemo(() => {
    return screenLocationOptions.find(
      (option) => option.name === props.asset.screenLocation
    )
  }, [props.asset.screenLocation])

  return (
    <Container>
      <Preview>
        <BackgroundImage src={backgroundImage} />
        {props.asset.viewUrl ? (
          <AssetImage
            alt={props.campaign.campaignName as string}
            position={assetPosition}
            src={props.asset.viewUrl}
          />
        ) : null}
      </Preview>
      <PreviewNav>
        {props.showNavButtons ? (
          <PreviewNavButton onClick={props.onClickPrevious}>
            <Icon name='ChevronLeft' />
          </PreviewNavButton>
        ) : null}
        <PreviewNavTitle>{activeScreenLocation?.label}</PreviewNavTitle>
        {props.showNavButtons ? (
          <PreviewNavButton onClick={props.onClickNext}>
            <Icon name='ChevronRight' />
          </PreviewNavButton>
        ) : null}
      </PreviewNav>
    </Container>
  )
}

const Container = styled.div`
  width: 295px;
`

const Preview = styled.div`
  border: 1px solid ${Colors.ALTO};
  overflow: hidden;
  position: relative;
  width: 100%;
`

const BackgroundImage = styled.img`
  height: 100%;
  width: 100%;
`

const AssetImage = styled.img<{ position: { top: string; left: string } }>`
  left: ${(props) => props.position.left};
  position: absolute;
  top: ${(props) => props.position.top};
  width: 100%;
`

const PreviewNav = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 40px;
  width: 100%;
`

const PreviewNavButton = styled.div`
  color: ${Colors.SHUTTLE_GRAY};
  cursor: pointer;
`

const PreviewNavTitle = styled.div`
  color: ${Colors.SHUTTLE_GRAY};
  flex: 1;
  font-size: 16px;
  text-align: center;
`
