import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { SponsoredPostStats, Post as PostProps } from '@sportsyou/api'

export interface SponsoredPostsSliceProps {
  posts: { [postId: string]: PostProps }
  postsList: PostProps[]
  stats: { [postId: string]: SponsoredPostStats[] }
}

const initialState: SponsoredPostsSliceProps = {
  posts: {},
  postsList: [],
  stats: {},
}

const SponsoredPostsSlice = createSlice({
  name: 'sponsoredPosts',
  initialState,
  reducers: {
    clearSponsoredPosts(state) {
      state.posts = initialState.posts
      state.postsList = initialState.postsList
      state.stats = initialState.stats
    },
    setSponsoredPostsList(
      state: SponsoredPostsSliceProps,
      action: PayloadAction<PostProps[]>
    ) {
      state.postsList = action.payload
    },
    updateSponsoredPostsListItem(
      state: SponsoredPostsSliceProps,
      action: PayloadAction<PostProps>
    ) {
      const index = state.postsList.findIndex(
        (post) => post.id === action.payload.id
      )
      if (index !== -1) {
        state.postsList[index] = action.payload
      }
    },
    setSponsoredPost(state, action: PayloadAction<PostProps>) {
      if (!state.posts) {
        state.posts = {}
      }
      state.posts[action.payload.id as string] = action.payload
    },
    updateSponsoredPost(
      state: SponsoredPostsSliceProps,
      action: PayloadAction<PostProps>
    ) {
      if (state.posts) {
        state.posts[action.payload.id as string] = {
          ...state.posts[action.payload.id as string],
          ...action.payload,
        }
      }
    },
    setSponsoredPostsStats(
      state: SponsoredPostsSliceProps,
      action: PayloadAction<{ postId: string; stats: SponsoredPostStats[] }>
    ) {
      if (!state.stats) {
        state.stats = {}
      }
      state.stats[action.payload.postId] = action.payload.stats
    },
  },
})

export const {
  clearSponsoredPosts,
  setSponsoredPost,
  setSponsoredPostsList,
  setSponsoredPostsStats,
  updateSponsoredPost,
  updateSponsoredPostsListItem,
} = SponsoredPostsSlice.actions
export default SponsoredPostsSlice.reducer

export const selectSponsoredPostsList = (state: any) =>
  state.sponsoredPosts.postsList

export const selectSponsoredPosts = (state: any) => state.sponsoredPosts.posts

export const selectSponsoredPostById = createSelector(
  selectSponsoredPosts,
  (state, id) => id,
  (posts: { [postId: string]: PostProps }, id) => posts?.[id as string] || {}
)

export const selectSponsoredPostsStats = (state: any) =>
  state.sponsoredPosts.stats

export const selectSponsoredPostsStatsByPostId = createSelector(
  selectSponsoredPostsStats,
  (state, id) => id,
  (stats: { [postId: string]: SponsoredPostStats[] }, id) =>
    stats?.[id as string] || []
)
