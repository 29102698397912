import { Colors } from '@sportsyou/core'
import React, { useCallback, useMemo } from 'react'
import styled, { css, keyframes } from 'styled-components'

export interface SkeletonProps {
  animated?: boolean
  avatarSize?: number | string
  backgroundColor?: string
  barColor?: string
  barCount?: number
  barGap?: number | string
  barHeight?: number | string
  bars?: Array<number | string>
  children?: React.ReactNode
  className?: string
  ready?: boolean
  showAvatar?: boolean
  showBars?: boolean
  showContainer?: boolean
  showHeader?: boolean
  style?: React.CSSProperties
  width?: number | string
}

export const Skeleton: React.FC<React.PropsWithChildren<SkeletonProps>> = ({
  animated = true,
  avatarSize = 44,
  backgroundColor = 'transparent',
  barColor = Colors.MYSTIC,
  barCount = 3,
  barGap = 12,
  barHeight = 16,
  bars = ['100%', '100%', '100%'],
  children,
  className,
  ready = false,
  showAvatar = false,
  showBars = false,
  showContainer = false,
  showHeader = true,
  style,
  width = 300,
}: SkeletonProps) => {
  const getValueAsString = useCallback(
    (value: number | string, ratio?: number) => {
      if (ratio) {
        return typeof value === 'string'
          ? `calc(${value} * ${ratio})`
          : `${value * ratio}px`
      }
      return typeof value === 'string' ? value : `${value}px`
    },
    []
  )

  const _bars = useMemo(() => {
    if (barCount > bars.length) {
      return Array.from({ length: barCount }, () => {
        if (bars.length > 0) {
          // sample the values from bars randomly
          const index = Math.floor(Math.random() * bars.length)
          return bars[index]
        } else {
          return '100%'
        }
      })
    } else {
      return bars ?? []
    }
  }, [barCount, bars])

  if (ready) {
    if (children) {
      return children
    }
    return null
  }

  return (
    <Container
      $animated={animated}
      $background={backgroundColor}
      $showContainer={showContainer}
      $width={getValueAsString(width)}
      className={className}
      style={style}
    >
      {showAvatar && (
        <Avatar $color={barColor} $size={getValueAsString(avatarSize)} />
      )}
      {showBars || showHeader ? (
        <Bars $gap={getValueAsString(barGap)}>
          {showHeader && (
            <HeaderBar
              $color={barColor}
              $height={getValueAsString(barHeight, 1.6)}
            />
          )}
          {showBars &&
            _bars?.map((barWidth, index) => (
              <Bar
                $color={barColor}
                $height={getValueAsString(barHeight)}
                $width={getValueAsString(barWidth)}
                key={index}
              />
            ))}
        </Bars>
      ) : null}
    </Container>
  )
}

const pulse = keyframes`
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1
  }
`

const Container = styled.div<{
  $animated: boolean
  $background: string
  $showContainer: boolean
  $width: string
}>`
  background-color: ${({ $background }) => $background};
  border-radius: 8px;
  display: flex;
  padding: 0;
  width: ${({ $width }) => $width};

  ${({ $showContainer }) =>
    $showContainer &&
    css`
      padding: 10px;
    `}

  ${({ $animated }) =>
    $animated &&
    css`
      animation: ${pulse} 1.2s infinite ease-in-out;
    `}
`

const Avatar = styled.div<{
  $color: string
  $size: string
}>`
  background-color: ${({ $color }) => $color};
  border-radius: 50%;
  height: ${({ $size }) => $size};
  margin-right: 10px;
  width: ${({ $size }) => $size};
`

const Bars = styled.div<{ $gap: string }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: ${({ $gap }) => $gap};
`

const BarStyles = css`
  border-radius: 6px;
`

const HeaderBar = styled.div<{
  $color: string
  $height: string
}>`
  ${BarStyles};
  background-color: ${({ $color }) => $color};
  height: ${({ $height }) => $height};
  width: 100%;
`

const Bar = styled.div<{
  $color: string
  $height: string
  $width: string
}>`
  ${BarStyles};
  background-color: ${({ $color }) => $color};
  height: ${({ $height }) => $height};
  width: ${({ $width }) => $width};
`

export default Skeleton
