import React from 'react'
import TextInput from '../text-input'

export interface SearchbarProps {
  autoFocus?: boolean
  className?: string
  containerStyle?: React.CSSProperties
  inputContainerStyle?: React.CSSProperties
  isSearching?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  /**
   * Function to call while typing
   * @deprecated Please use onKeyUp
   */
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  /**
   * Function to call while typing
   */
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  placeholder?: string
  style?: React.CSSProperties
  value?: string
}

export const Searchbar: React.FC<SearchbarProps> = (props: SearchbarProps) => {
  return (
    <TextInput
      autoFocus={props.autoFocus}
      className={props.className}
      containerStyle={props.containerStyle}
      inputContainerStyle={props.inputContainerStyle}
      isLoading={props.isSearching}
      onChange={props.onChange}
      onKeyUp={props.onKeyUp ?? props.onKeyPress}
      placeholder={props.placeholder || 'Search'}
      showSearchIcon
      style={props.style}
      value={props.value || ''}
    />
  )
}

export default Searchbar
