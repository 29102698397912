import { CSSProperties, FC, PropsWithChildren, ReactNode } from 'react'

import styled from 'styled-components'

import { Colors } from '@sportsyou/core'

export interface WidgetHeaderProps {
  children?: ReactNode
  className?: string
  style?: CSSProperties
}

export const WidgetHeader: FC<PropsWithChildren<WidgetHeaderProps>> = ({
  className,
  children,
  style,
  ...props
}) => {
  return (
    <Header className={className} style={style} {...props}>
      {children}
    </Header>
  )
}

const Header = styled.header`
  align-items: center;
  border-bottom: 1px solid ${Colors.ALTO};
  box-sizing: border-box;
  display: flex;
  padding: 6px 10px;
  height: 40px;
`

export default WidgetHeader
