import * as React from 'react'
import type { SVGProps } from 'react'
const BubbleES = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    style={props.style}
    {...props}
  >
    <path
      fill='#D94B21'
      d='M28.5135 0H3.48626C1.5621.00301985.00306933 1.53692 0 3.43006V20.5426c.00300669 1.8932 1.5621 3.4271 3.48626 3.4301h3.99521c.04057.0001.07944.016.10813.0442.02868.0282.04485.0665.04496.1064v6.1522c-.008.3268.08069.6488.25529.9269.1746.2781.42757.5002.72815.6395.22353.1046.46805.1586.7156.1581h.0191c.47459-.009.932-.1763 1.2973-.4746.4322-.3652 7.0376-5.4142 9.7929-7.5003.028-.0194.0614-.0299.0956-.0301h7.975c1.9241-.0029 3.4833-1.5367 3.4865-3.4298V3.43006C31.9996 1.53575 30.4388.00018489 28.5135 0Z'
    />
    <path
      fill='#2B2B2B'
      d='M8.3 14.505c1.27026 0 2.3-1.0297 2.3-2.3 0-1.2702-1.02974-2.29997-2.3-2.29997-1.27026 0-2.3 1.02977-2.3 2.29997 0 1.2703 1.02974 2.3 2.3 2.3ZM15.8198 14.505c1.2702 0 2.3-1.0297 2.3-2.3 0-1.2702-1.0298-2.29997-2.3-2.29997-1.2703 0-2.3 1.02977-2.3 2.29997 0 1.2703 1.0297 2.3 2.3 2.3ZM23.3398 14.505c1.2702 0 2.3-1.0297 2.3-2.3 0-1.2702-1.0298-2.29997-2.3-2.29997-1.2703 0-2.3 1.02977-2.3 2.29997 0 1.2703 1.0297 2.3 2.3 2.3Z'
    />
  </svg>
)
export default BubbleES
