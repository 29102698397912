import { useCallback, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Community,
  mutationCommunityUpdate,
  MutationCommunityUpdateRequest,
  mutationPostDelete,
  Post,
  queryCommunities,
  queryCommunity,
  queryCommunityPosts,
  QueryCommunityPostsRequest,
} from '@sportsyou/api'
import { useFetchApi } from '@sportsyou/react-hooks'

import {
  addPage,
  selectPages,
  updatePage as updatePageInRedux,
} from '../../store/slices/CommunitiesSlice'

interface UsePagesPortalProps {
  communityId?: string
  communityUrl?: string
}

export interface FetchPostCriteria {
  communityId?: string | null
  direction?: 'older' | 'newer' | null
  /**
   * Set true to force call fetch
   */
  force?: boolean
  page?: number | null
  perPage?: number
  postId?: string
  startPostId?: string | null
}

interface FetchResult {
  criteria: FetchPostCriteria
  posts?: Post[]
}

export default function UsePagesPortal(props?: UsePagesPortalProps) {
  const dispatch = useDispatch()

  const lastFetchResult = useRef<FetchResult>()

  const communities = useSelector(selectPages)

  const [adminCommunities, setAdminCommunities] = useState<Community[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { fetch: deletePost } = useFetchApi(mutationPostDelete)
  const { fetch: fetchCommunities } = useFetchApi(queryCommunities)
  const { fetch: fetchCommunity } = useFetchApi(queryCommunity)
  const { fetch: fetchPagesPosts } = useFetchApi(queryCommunityPosts)
  const { fetch: updatePage } = useFetchApi(mutationCommunityUpdate)

  const getPosts = useCallback(() => {
    return communities
      .map((community) => [{ id: 'asdf' }]) //!!!!!!!!
      .reduce((acc, val) => acc.concat(val), [])
  }, [communities])

  const getPost = useCallback(
    (postId: string) => {
      const _posts = getPosts()
      return _posts.find((post) => post.id === postId)
    },
    [getPosts]
  )

  const [posts, setPosts] = useState<Post[]>(getPosts)

  const page = useMemo(() => {
    return (
      communities.find(
        (community) =>
          community.id === props?.communityId ||
          community.communityUrl === props?.communityUrl
      ) || ({} as Community)
    )
  }, [communities, props?.communityId, props?.communityUrl])

  const fetchAdminCommunities = useCallback(async () => {
    setIsLoading(true)
    const { data } = await fetchCommunities({ adminsOnly: true })
    setAdminCommunities(data ?? [])
    setIsLoading(false)
  }, [fetchCommunities])

  const fetchPage = useCallback(
    async (idOrUrl: string) => {
      setIsLoading(true)

      const { data } = await fetchCommunity({
        idOrUrl,
      })
      dispatch(addPage(data as Community))

      setIsLoading(false)
    },
    [dispatch, fetchCommunity]
  )

  const fetchAllPosts = useCallback(
    async (criteria?: FetchPostCriteria) => {
      const direction = criteria?.direction ?? 'older'
      const perPage = criteria?.perPage ?? 10
      const startPostId = criteria?.startPostId

      setIsLoading(true)
      const { data } = await fetchPagesPosts({
        direction,
        includeFollowingPosts: true,
        perPage,
        startPostId,
      } as QueryCommunityPostsRequest)
      lastFetchResult.current = {
        criteria: { direction, perPage, startPostId },
        posts: data ?? undefined,
      }
      setPosts((prevPosts: Post[]) => {
        const _posts = !startPostId
          ? data || []
          : [...prevPosts, ...(data || [])]
        return _posts
      })
      setIsLoading(false)
    },
    [fetchPagesPosts]
  )

  const fetchPosts = useCallback(
    async ({
      communityId,
      direction,
      perPage = 10,
      startPostId,
    }: FetchPostCriteria) => {
      if (!communityId) return
      setIsLoading(true)
      const { data } = await fetchPagesPosts({
        communityId,
        direction,
        perPage,
        startPostId,
      })
      lastFetchResult.current = {
        criteria: { communityId, direction, perPage, startPostId },
        posts: data ?? undefined,
      }

      setPosts((prevPosts: Post[]) => {
        const _posts = !startPostId
          ? data || []
          : [...prevPosts, ...(data || [])]
        return _posts
      })
      setIsLoading(false)
    },
    [fetchPagesPosts]
  )

  const deletePagePost: (postId: string) => Promise<void> = useCallback(
    async (postId: string) => {
      await deletePost({
        postId,
      })
      fetchAdminCommunities()
    },
    [deletePost, fetchAdminCommunities]
  )

  const updateInRedux = useCallback(
    (options: Community) => {
      dispatch(updatePageInRedux({ ...page, ...options }))
    },
    [dispatch, page]
  )

  const update = useCallback(
    async (community: MutationCommunityUpdateRequest) => {
      const response = await updatePage(community)
      if (response.ok) {
        fetchAdminCommunities()
        updateInRedux(community.community as Community)
      }
    },
    [updatePage, fetchAdminCommunities, updateInRedux]
  )

  return {
    adminCommunities,
    communities,
    deletePagePost,
    fetchAdminCommunities,
    fetchAllPosts,
    fetchPage,
    fetchPosts,
    getPost,
    isLoading,
    lastFetchResult: lastFetchResult.current,
    page,
    posts,
    update,
    updateInRedux,
  }
}
