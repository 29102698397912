import { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import {
  Button,
  Checkbox,
  FadeInContainer,
  TextInput,
  Well,
} from '@sportsyou/react-dom-ui'
import {
  Campaign,
  CampaignAssetDisplay,
  CampaignLocation,
  Sponsor,
  Upload,
} from '@sportsyou/api'
import { campaignAssetTranscodeUrl } from '../CampaignHelpers'
import { Colors, isUrlValid } from '@sportsyou/core'
import AssetPicker from './AssetPicker'
import CampaignPreview from '../Preview/Preview'
import useCampaignPortal from '../UseCampaignPortal'
import LocationIcon from './LocationIcon'

interface CampaignFormAssetsProps {
  campaign: Campaign
  hasChanges?: boolean
  isNew?: boolean
  onClickBack: () => void
  onClickCancel: () => void
  onClickNext: () => void
  sponsor?: Sponsor
  setCampaignProp: (key: keyof Campaign, value: unknown) => void
  setCurrentCampaignScreenLocationProp: (
    locationName: string,
    key: string,
    value: unknown
  ) => void
}

export type ScreenLocationOption = {
  label: string
  name: string
  icon: string
}

export const screenLocationOptions: ScreenLocationOption[] = [
  {
    label: 'Loading Screen',
    name: 'loadingScreen',
    icon: 'SyLogoCircle',
  },
  {
    label: 'Home Feed',
    name: 'mainFeedScreen',
    icon: 'HomeFilled',
  },
  {
    label: 'Chat',
    name: 'chatListScreen',
    icon: 'BubbleSolid',
  },
  {
    label: 'Teams',
    name: 'teamListScreen',
    icon: 'JerseySolid',
  },
  {
    label: 'Calendar',
    name: 'calendarScreen',
    icon: 'CalendarSolid',
  },
]

export default function CampaignFormAssets(props: CampaignFormAssetsProps) {
  const { campaign, sponsor, setCurrentCampaignScreenLocationProp } = props
  const { screenLocations } = campaign

  const { currentAssets, loadSponsorAssets } = useCampaignPortal()

  const [selectedLocation, setSelectedLocation] = useState<CampaignLocation>(
    screenLocations?.[0] as CampaignLocation
  )

  const [placementPreview, setPlacementPreview] =
    useState<CampaignAssetDisplay>(() => {
      const screenLocation = screenLocations?.[0]
      if (screenLocation) {
        const upload = currentAssets?.find(
          (upload) => upload.id === screenLocation.assets?.[0]?.uploadId
        )
        return {
          screenLocation: screenLocation.locationName,
          viewUrl: campaignAssetTranscodeUrl(
            upload as Upload,
            screenLocation.locationName === 'loadingScreen'
          ),
        }
      } else {
        return {}
      }
    })

  const campaignLocationByName = useCallback(
    (name: string) => {
      return screenLocations?.find(
        (l) => l?.locationName === name
      ) as CampaignLocation
    },
    [screenLocations]
  )

  const handleOnChangeAsset = useCallback(
    (locationName: string, uploadId: string | number) => {
      setCurrentCampaignScreenLocationProp(locationName, 'assets', [
        {
          percentage: 100,
          transcodeType:
            locationName === 'loadingScreen'
              ? 'campaignAssetLoadingScreen'
              : 'campaignAsset',
          uploadId,
        },
      ])

      const upload = currentAssets?.find((upload) => upload.id === uploadId)
      if (upload) {
        setPlacementPreview({
          screenLocation: locationName,
          viewUrl: campaignAssetTranscodeUrl(
            upload as Upload,
            locationName === 'loadingScreen'
          ),
        })
      }

      setSelectedLocation(campaignLocationByName(locationName))
    },
    [
      campaignLocationByName,
      currentAssets,
      setCurrentCampaignScreenLocationProp,
    ]
  )

  const handleOnClickNextPreview = useCallback(() => {
    const screenLocation = screenLocations?.find(
      (l) => l?.locationName === placementPreview?.screenLocation
    )
    const index =
      screenLocations?.indexOf(screenLocation as CampaignLocation) ?? 0
    let nextScreenLocation = screenLocations?.[index + 1]
    if (!nextScreenLocation) {
      nextScreenLocation = screenLocations?.[0] as CampaignLocation
    }
    setSelectedLocation(nextScreenLocation)
  }, [placementPreview, screenLocations])

  const handleOnClickPreviousPreview = useCallback(() => {
    const screenLocation = screenLocations?.find(
      (l) => l?.locationName === placementPreview?.screenLocation
    )
    const index =
      screenLocations?.indexOf(screenLocation as CampaignLocation) ?? 0
    let previousScreenLocation = screenLocations?.[index - 1]
    if (!previousScreenLocation) {
      previousScreenLocation = screenLocations?.[
        screenLocations?.length - 1
      ] as CampaignLocation
    }
    setSelectedLocation(previousScreenLocation)
  }, [placementPreview, screenLocations])

  const screenLocationIsEnabled = useCallback(
    (screenLocationName: string) => {
      return !!screenLocations?.find(
        (l) => l?.locationName === screenLocationName
      )
    },
    [screenLocations]
  )

  const canSave = useMemo(() => {
    let _canSave = false
    if (props.hasChanges) {
      _canSave = true
    }
    if (!campaign?.sponsorId) {
      _canSave = false
    }
    if (!campaign?.campaignName) {
      _canSave = false
    }
    if (campaign?.screenLocations?.length === 0) {
      _canSave = false
    } else {
      // does each screen location have an asset?
      campaign?.screenLocations?.forEach((screenLocation) => {
        if (!screenLocation?.assets?.[0]?.uploadId) {
          _canSave = false
        }
      })
    }
    return _canSave
  }, [
    campaign?.campaignName,
    campaign?.screenLocations,
    campaign?.sponsorId,
    props.hasChanges,
  ])

  useEffect(() => {
    if (campaign?.sponsorId) {
      loadSponsorAssets(campaign.sponsorId)
    }
  }, [loadSponsorAssets, campaign?.sponsorId])

  useEffect(() => {
    if (selectedLocation) {
      const upload = currentAssets?.find(
        (upload) => upload.id === selectedLocation.assets?.[0]?.uploadId
      )
      if (upload) {
        setPlacementPreview({
          screenLocation: selectedLocation.locationName,
          viewUrl: campaignAssetTranscodeUrl(
            upload as Upload,
            selectedLocation?.locationName === 'loadingScreen'
          ),
        })
      }
    }
  }, [currentAssets, selectedLocation])

  return (
    <>
      <Columns>
        <LeftColumn>
          <WellMain noBorder label='Select Placement Locations'>
            <PlacementCheckboxes>
              {screenLocationOptions.map((option) => (
                <Checkbox
                  checked={screenLocationIsEnabled(option.name)}
                  key={option.name}
                  onChange={(e) =>
                    setCurrentCampaignScreenLocationProp(
                      option.name,
                      'enabled',
                      e.target.checked
                    )
                  }
                  title={option.label}
                  value={option.name}
                >
                  {option.label}
                </Checkbox>
              ))}
            </PlacementCheckboxes>

            <Placements>
              {screenLocations?.map((screenLocation) => {
                const option = screenLocationOptions.find(
                  (option) => option.name === screenLocation?.locationName
                )
                return (
                  <FadeInContainer
                    key={screenLocation?.locationName}
                    onMouseEnter={() =>
                      setSelectedLocation(screenLocation as CampaignLocation)
                    }
                  >
                    <WellPlacement>
                      <PlacementHeader>
                        <LocationIcon option={option as ScreenLocationOption} />
                        {option?.label}
                      </PlacementHeader>
                      <PlacementOption>
                        <PlacementOptionTitle>Asset</PlacementOptionTitle>
                        <AssetPicker
                          assets={currentAssets as Upload[]}
                          locationName={screenLocation?.locationName as string}
                          onChange={handleOnChangeAsset}
                          selectedAssetId={
                            screenLocation?.assets?.[0]?.uploadId
                          }
                        />
                      </PlacementOption>
                      <PlacementOption>
                        <PlacementOptionTitle>
                          Click-through
                        </PlacementOptionTitle>
                        <ClickThroughOptions>
                          <Checkbox
                            title='Click Through URL'
                            value='none'
                            checked={
                              !screenLocation?.clickThroughCommunityPage &&
                              !!screenLocation?.clickThroughUrl
                            }
                            onChange={(e) => {
                              const checked = e.target.checked
                              if (checked) {
                                setCurrentCampaignScreenLocationProp(
                                  screenLocation?.locationName as string,
                                  'clickThroughCommunityPage',
                                  undefined
                                )
                                setCurrentCampaignScreenLocationProp(
                                  screenLocation?.locationName as string,
                                  'clickThroughUrl',
                                  'https://'
                                )

                                // Focus the input
                                setTimeout(() => {
                                  const input =
                                    e.nativeEvent.target.parentElement?.parentElement?.parentElement?.querySelector(
                                      'input[type=text]'
                                    ) as HTMLInputElement
                                  input?.focus()
                                }, 100)
                              } else {
                                setCurrentCampaignScreenLocationProp(
                                  screenLocation?.locationName as string,
                                  'clickThroughUrl',
                                  undefined
                                )
                              }
                            }}
                          >
                            Click Through URL
                          </Checkbox>
                          <Checkbox
                            title='Go To Community Page'
                            value='none'
                            checked={
                              screenLocation?.clickThroughCommunityPage ===
                              'open'
                            }
                            onChange={(e) => {
                              setCurrentCampaignScreenLocationProp(
                                screenLocation?.locationName as string,
                                'clickThroughCommunityPage',
                                e.target.checked ? 'open' : undefined
                              )
                              setCurrentCampaignScreenLocationProp(
                                screenLocation?.locationName as string,
                                'clickThroughUrl',
                                undefined
                              )
                            }}
                          >
                            Go To Community Page
                          </Checkbox>
                          {sponsor?.communityId ? (
                            <Checkbox
                              title='Go To Community Page and Follow'
                              value='none'
                              checked={
                                screenLocation?.clickThroughCommunityPage ===
                                'follow'
                              }
                              onChange={(e) => {
                                setCurrentCampaignScreenLocationProp(
                                  screenLocation?.locationName as string,
                                  'clickThroughCommunityPage',
                                  e.target.checked ? 'follow' : undefined
                                )
                                setCurrentCampaignScreenLocationProp(
                                  screenLocation?.locationName as string,
                                  'clickThroughUrl',
                                  undefined
                                )
                              }}
                            >
                              Go To Community Page and Follow
                            </Checkbox>
                          ) : null}
                        </ClickThroughOptions>

                        {screenLocation?.clickThroughCommunityPage ||
                        !screenLocation?.clickThroughUrl ? null : (
                          <ClickThroughValue>
                            <ClickThroughTextInput
                              showValidationMessage={
                                !isUrlValid(
                                  screenLocation?.clickThroughUrl as string
                                )
                              }
                              validationMessage='Please enter a valid URL'
                              validationStyle={inputValidationStyle}
                              placeholder='To what URL should this link go?'
                              value={screenLocation?.clickThroughUrl ?? ''}
                              onChange={(e) => {
                                // require the value to start with http or https
                                let value = e.target.value
                                value = (value as string).trim()
                                if (
                                  value &&
                                  !isUrlValid(value as string) &&
                                  !(value as string).startsWith('http')
                                ) {
                                  value = `https://${value}`
                                }

                                setCurrentCampaignScreenLocationProp(
                                  screenLocation?.locationName as string,
                                  'clickThroughUrl',
                                  value
                                )
                              }}
                            />
                          </ClickThroughValue>
                        )}
                      </PlacementOption>
                    </WellPlacement>
                  </FadeInContainer>
                )
              })}
            </Placements>
          </WellMain>
        </LeftColumn>
        {placementPreview?.viewUrl ? (
          <RightColumn>
            <CampaignPreview
              asset={placementPreview}
              campaign={campaign}
              onClickNext={handleOnClickNextPreview}
              onClickPrevious={handleOnClickPreviousPreview}
              showNavButtons={(screenLocations?.length ?? 0) > 1}
            />
          </RightColumn>
        ) : null}
      </Columns>
      <NavButtons>
        <Button
          appearance='ghost'
          onClick={props.onClickBack}
          variant='alternate'
        >
          Previous
        </Button>
        <Button disabled={!canSave} onClick={props.onClickNext}>
          Save Campaign
        </Button>
      </NavButtons>
    </>
  )
}

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`

const LeftColumn = styled.div`
  flex: 1;
`

const RightColumn = styled.div`
  width: 300px;
`

const WellMain = styled(Well)`
  background-color: ${Colors.WHITE};
`

const PlacementCheckboxes = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  padding-bottom: 20px;
`

const WellPlacement = styled(Well)`
  background-color: ${Colors.CATSKILL_WHITE};
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const Placements = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const PlacementHeader = styled.div`
  align-items: center;
  color: ${Colors.PUNCH};
  display: flex;
  font-size: 13px;
  font-weight: 700;
  gap: 10px;
  text-transform: uppercase;
`

const PlacementOption = styled.div`
  border-top: 1px solid ${Colors.ALTO};
  padding-top: 10px;
`

const PlacementOptionTitle = styled.div`
  color: ${Colors.MINE_SHAFT};
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: uppercase;
`

const ClickThroughOptions = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
`

const ClickThroughTextInput = styled(TextInput)`
  width: 100%;
  min-height: 0;
`

const ClickThroughValue = styled.div`
  padding-top: 10px;
`

const NavButtons = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  margin-top: 20px;

  button {
    border-width: 1px;
  }
`

const inputValidationStyle = {
  color: Colors.PUNCH,
}
