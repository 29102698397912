import { Helmet } from 'react-helmet-async'
import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import styled from 'styled-components'

import {
  Avatar,
  Button,
  Icon,
  Skeleton,
  Table,
  Tabs,
  useDialog,
} from '@sportsyou/react-dom-ui'
import { Campaign } from '@sportsyou/api'
import {
  campaignStatusIndicatorData,
  campaignStatusString,
  isCampaignActive,
  isCampaignEnded,
} from './CampaignHelpers'
import { Colors } from '@sportsyou/core'
import { ContentContainer } from '../../styles/global-styles'
import AssetManager from './Assets/AssetManager'
import useCampaignPortal from './UseCampaignPortal'

interface SponsorPortalCampaignsIndexProps {
  tab?: string
}

export default function SponsorPortalCampaignsIndex(
  props: SponsorPortalCampaignsIndexProps
) {
  const { sendConfirm } = useDialog()
  const navigate = useNavigate()

  const {
    approveCampaign,
    campaigns,
    deleteCampaign,
    endCampaign,
    findSponsorById,
    isLoading,
    loadAllCampaigns,
    loadSponsors,
    pathCampaignDetail,
    pathCampaignNew,
    sponsors,
  } = useCampaignPortal()

  const handleOnClickDeleteCampaign = useCallback(
    (campaign: Campaign) => {
      sendConfirm({
        title: 'Delete Campaign?',
        message: `Are you sure you want to delete this campaign? You will lose all data once you delete it.`,
        confirmText: 'Delete',
        cancelText: 'Cancel',
        onConfirm: async () => {
          await deleteCampaign(campaign.id as string)
          loadAllCampaigns()
        },
      })
    },
    [deleteCampaign, loadAllCampaigns, sendConfirm]
  )

  const handleOnClickApproveCampaign = useCallback(
    async (campaign: Campaign) => {
      const response = await approveCampaign(campaign)
      console.log('handleOnClickApproveCampaign', response)
      if (response) {
        loadAllCampaigns()
      }
    },
    [approveCampaign, loadAllCampaigns]
  )

  const handleOnClickEndCampaign = useCallback(
    async (campaign: Campaign) => {
      const response = await endCampaign(campaign)
      console.log('handleOnClickEndCampaign', response)
      if (response) {
        loadAllCampaigns()
      }
    },
    [endCampaign, loadAllCampaigns]
  )

  const onCampaignRowClicked = useCallback(
    (campaign: Campaign) => {
      navigate(pathCampaignDetail(campaign))
    },
    [navigate, pathCampaignDetail]
  )

  const dataColumns = useMemo(() => {
    return [
      {
        name: 'Sponsor',
        selector: (campaign: Campaign) =>
          findSponsorById(campaign.sponsorId as string)?.name ?? '',
        cell: (campaign: Campaign) => {
          const name = findSponsorById(campaign.sponsorId as string)?.name ?? ''
          return (
            <SponsorName
              onClick={() => onCampaignRowClicked(campaign)}
              title={name}
            >
              <StyledAvatar name={name} />
              {name}
            </SponsorName>
          )
        },
        sortable: true,
      },
      {
        grow: 2,
        name: 'Campaign Title',
        selector: (campaign: Campaign) => campaign.campaignName ?? '',
        sortable: true,
        cell: (campaign: Campaign) => {
          return (
            <div
              onClick={() => onCampaignRowClicked(campaign)}
              title={campaign.campaignName as string}
            >
              {campaign.campaignName}
            </div>
          )
        },
      },
      {
        name: 'Start Date',
        selector: (campaign: Campaign) => campaign.startDate ?? '',
        cell: (campaign: Campaign) => (
          <CampaignListDate
            active={isCampaignActive(campaign)}
            date={campaign.startDate as string}
          >
            {moment(campaign.startDate).format('MM/DD/YYYY hh:mm A')}
          </CampaignListDate>
        ),
        sortable: true,
      },
      {
        name: 'End Date',
        selector: (campaign: Campaign) => campaign.endDate ?? '',
        cell: (campaign: Campaign) => (
          <CampaignListDate
            active={isCampaignActive(campaign)}
            date={campaign.endDate as string}
          >
            {moment(campaign.endDate).format('MM/DD/YYYY hh:mm A')}
          </CampaignListDate>
        ),
        sortable: true,
      },
      {
        name: 'Status',
        selector: (campaign: Campaign) => campaignStatusString(campaign),
        cell: (campaign: Campaign) => {
          const status = campaignStatusIndicatorData(campaign)
          return (
            <>
              <StatusCircle color={status.color} />
              {status.label}
            </>
          )
        },
        sortable: true,
      },
      {
        name: 'Actions',
        cell: (campaign: Campaign) => {
          return (
            <ActionButtons>
              {!isCampaignActive(campaign) && !isCampaignEnded(campaign) ? (
                <ActionButton
                  appearance='ghost'
                  onClick={() => handleOnClickApproveCampaign(campaign)}
                  variant='primary'
                >
                  Approve
                </ActionButton>
              ) : null}
              {isCampaignActive(campaign) ? (
                <ActionButton
                  appearance='ghost'
                  onClick={() => handleOnClickEndCampaign(campaign)}
                  variant='info'
                >
                  End
                </ActionButton>
              ) : (
                <ActionButton
                  appearance='ghost'
                  onClick={() => handleOnClickDeleteCampaign(campaign)}
                  variant='info'
                >
                  Delete
                </ActionButton>
              )}
            </ActionButtons>
          )
        },
      },
    ]
  }, [
    findSponsorById,
    handleOnClickApproveCampaign,
    handleOnClickDeleteCampaign,
    handleOnClickEndCampaign,
    onCampaignRowClicked,
  ])

  useEffect(() => {
    loadSponsors()
  }, [loadSponsors])

  useEffect(() => {
    if (sponsors?.length) {
      loadAllCampaigns()
    }
  }, [loadAllCampaigns, sponsors])

  return (
    <ContentContainer>
      <Helmet>
        <title>sportsYou - Campaigns</title>
      </Helmet>

      <Tabs
        contentStyle={tabsContentStyle}
        index={props.tab === 'settings' ? 1 : 0}
      >
        <Tabs.Item title='Campaigns'>
          <Table
            // selectableRows
            columns={dataColumns}
            customStyles={customTableStyles}
            data={campaigns}
            defaultSortAsc={true}
            highlightOnHover
            noDataComponent='No campaigns found'
            onRowClicked={onCampaignRowClicked}
            pointerOnHover
            progressPending={isLoading}
            striped
            progressComponent={
              <StyledSkeleton
                barCount={5}
                barGap={8}
                barHeight={45}
                showBars
                showHeader={false}
                width={'100%'}
              />
            }
          />
        </Tabs.Item>
        <Tabs.Item title='Manage Sponsors Assets'>
          <AssetManager />
        </Tabs.Item>
        <Tabs.ExtraContent>
          <Button
            id='create-campaign-button'
            onClick={() => navigate(pathCampaignNew())}
          >
            <ButtonIcon name='Plus' size={10} fill={Colors.WHITE} />
            Create a Campaign
          </Button>
        </Tabs.ExtraContent>
      </Tabs>
    </ContentContainer>
  )
}

const tabsContentStyle = {
  padding: '20px 0',
}

const customTableStyles = {
  headCells: {
    style: {
      fontSize: '14px',
      fontWeight: 'bold',
    },
  },
  cells: {
    style: {},
  },
}

const ButtonIcon = styled(Icon)`
  margin-right: 5px;
`

const SponsorName = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
`

const CampaignListDate = styled.span<{ active: boolean; date: string }>`
  padding-bottom: 3px;

  border-bottom: ${({ active }) => {
    return active ? `1px solid ${Colors.MOUNTAIN_MEADOW}` : '0'
  }};

  color: ${({ date }) => {
    return moment(date).isBefore(moment()) ? Colors.DUSTY_GRAY : Colors.BLACK
  }};
`

const StatusCircle = styled.span<{ color: string }>`
  background-color: ${({ color }) => color};
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin-right: 5px;
  width: 10px;
`

const ActionButtons = styled.div`
  display: flex;
  gap: 5px;
`

const ActionButton = styled(Button)`
  border-width: 1px;
  min-height: 0;
  min-width: 0;
  padding: 4px 5px;
`

const StyledAvatar = styled(Avatar)`
  height: 28px;
  width: 28px;
`

const StyledSkeleton = styled(Skeleton)`
  margin-top: 20px;
`
