import Card from './card'
import CardBody from './card-body'
import CardHeader from './card-header'
import CardFooter from './card-footer'

export type CardType = typeof Card & {
  Body: typeof CardBody
  Header: typeof CardHeader
  Footer: typeof CardFooter
}
;(Card as CardType).Body = CardBody
;(Card as CardType).Header = CardHeader
;(Card as CardType).Footer = CardFooter

export type { CardProps } from './card'

export default Card as CardType
