import * as React from 'react'
import type { SVGProps } from 'react'
const GearSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <g>
      <path d='M31.05 12.86a3.31 3.31 0 0 0-2.41-.95.84.84 0 0 1-.84-.56l-.12-.3a1.06 1.06 0 0 1 .23-1.17 3.25 3.25 0 0 0 0-4.6l-1.19-1.19a3.25 3.25 0 0 0-4.6 0 1.07 1.07 0 0 1-1.19.22l-.27-.11a1 1 0 0 1-.57-1A3.24 3.24 0 0 0 16.84 0h-1.68a3.28 3.28 0 0 0-3.25 3.37.9.9 0 0 1-.57.84l-.28.11a1.07 1.07 0 0 1-1.18-.23 3.24 3.24 0 0 0-4.59 0l-1.2 1.19a3.25 3.25 0 0 0 0 4.6 1.07 1.07 0 0 1 .23 1.18l-.12.28a1 1 0 0 1-1 .57A3.25 3.25 0 0 0 0 15.16v1.69a3.28 3.28 0 0 0 3.37 3.24.89.89 0 0 1 .83.55l.12.3a1.07 1.07 0 0 1-.23 1.18 3.25 3.25 0 0 0 0 4.6l1.19 1.19a3.24 3.24 0 0 0 4.59 0 1.06 1.06 0 0 1 1.17-.23l.29.12a.93.93 0 0 1 .57.95A3.26 3.26 0 0 0 15.16 32h1.68a3.28 3.28 0 0 0 3.25-3.36.88.88 0 0 1 .57-.84l.28-.12a1.07 1.07 0 0 1 1.18.23 3.24 3.24 0 0 0 4.59 0l1.2-1.19a3.25 3.25 0 0 0 0-4.6 1.07 1.07 0 0 1-.23-1.18l.12-.28a.89.89 0 0 1 .95-.57A3.25 3.25 0 0 0 32 16.84v-1.68a3.24 3.24 0 0 0-.95-2.3zM16 22.08A6.08 6.08 0 1 1 22.08 16 6.09 6.09 0 0 1 16 22.08z' />
      <circle cx={16} cy={16} r={3.85} />
    </g>
  </svg>
)
export default GearSolid
