import * as React from 'react'
import type { SVGProps } from 'react'
const FileZip = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 496.4 661.8'
    width='1em'
    height='1em'
    style={props.style}
    {...props}
  >
    <path
      fill='#d102d1'
      d='M496.3 155.1a10.25 10.25 0 0 0-2.9-6.8L348.1 3a10 10 0 0 0-6.7-2.9.35.35 0 0 1-.2-.1H20.7A20.74 20.74 0 0 0 0 20.7v620.4a20.74 20.74 0 0 0 20.7 20.7h455a20.74 20.74 0 0 0 20.7-20.7v-486c-.1.1-.1 0-.1 0zm-144.7-120l109.8 109.8H351.6zm124 606.1H258.5V393h10.3a20.74 20.74 0 0 0 20.7-20.7v-82.7a20.74 20.74 0 0 0-20.7-20.7v-20.7h-20.7v20.7h-20.7a20.74 20.74 0 0 0-20.7 20.7v82.7a20.74 20.74 0 0 0 20.7 20.7h10.3v248.2h-217V20.8h206.8v20.7h20.7V20.8h82.7v144.8h144.8v475.6zM268.8 331v41.4h-41.4V331zm-41.3-20.7v-20.7h41.4v20.7zm41.3-248.2V41.4h-20.7v20.7zm-41.3 20.7h20.7V62.1h-20.7zm41.3 20.7V82.8h-20.7v20.7zm-41.3 20.7h20.7v-20.7h-20.7zm41.3 20.7v-20.7h-20.7v20.7zm-41.3 20.6h20.7v-20.7h-20.7zm41.3 20.7v-20.7h-20.7v20.7zm-41.3 20.7h20.7v-20.7h-20.7zm41.3 20.7v-20.7h-20.7v20.7zm-41.3 0v20.7h20.7v-20.7z'
    />
  </svg>
)
export default FileZip
