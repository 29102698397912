import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { checkColorValue, Colors } from '@sportsyou/core'
import Tooltip from '../tooltip'

type Offset = {
  x?: number
  y?: number
}

interface Props {
  className?: string
  /** Number to show in badge */
  count?: number
  /** Whether to display a red dot instead of count */
  dot?: boolean
  offset?: Offset
  dotSize?: number
  /** Customize Badge fill color */
  fill?: string
  isInteractive?: boolean
  onClick?: () => void
  /** Max count to show */
  overflowCount?: number
  size?: number
  style?: React.CSSProperties
  testIds?: { badge?: string; dot?: string; text?: string; tooltip?: string }
  textClassName?: string
  textStyle?: React.CSSProperties
  /** Text to show when hovering over the badge */
  title?: string
  /** Whether to display the title inside a tooltip component */
  useTooltip?: boolean
}

type NativeAttributes = Omit<React.HTMLAttributes<HTMLElement>, keyof Props>
export type BadgeProps = Props & NativeAttributes

export const Badge: React.FC<BadgeProps> = ({
  className,
  count,
  dot = false,
  offset = { x: 0, y: 0 },
  dotSize = 4,
  fill = Colors.PUNCH,
  isInteractive = false,
  onClick,
  overflowCount = 99,
  size = 20,
  style,
  testIds,
  textClassName,
  textStyle,
  title,
  useTooltip = false,
}: BadgeProps) => {
  const handleOnClick = useCallback(() => {
    onClick?.()
  }, [onClick])

  const renderBadge = useMemo(
    () => (
      <Container
        $fill={fill}
        $offset={offset}
        className={className}
        data-testid={testIds?.badge}
        isClickable={typeof onClick === 'function'}
        isInteractive={isInteractive}
        onClick={handleOnClick}
        size={size}
        style={style}
        title={!useTooltip ? title : undefined}
        aria-label={title}
      >
        {dot ? (
          <Dot $fill={fill} data-testid={testIds?.dot} size={dotSize} />
        ) : count ? (
          <Count
            $fill={fill}
            className={textClassName}
            data-testid={testIds?.text}
            style={textStyle}
          >
            {count > overflowCount ? `${overflowCount}+` : count}
          </Count>
        ) : null}
      </Container>
    ),
    [
      className,
      count,
      dot,
      offset,
      dotSize,
      fill,
      handleOnClick,
      isInteractive,
      onClick,
      overflowCount,
      size,
      style,
      testIds?.badge,
      testIds?.dot,
      testIds?.text,
      textClassName,
      textStyle,
      title,
      useTooltip,
    ]
  )

  return useTooltip ? (
    <Tooltip data-testid={testIds?.tooltip} text={title}>
      {renderBadge}
    </Tooltip>
  ) : (
    renderBadge
  )
}

const Container = styled.sup<
  BadgeProps & { $fill: string; $offset: Offset; isClickable: boolean }
>`
  align-items: center;
  background-color: ${({ $fill }) => $fill};
  border-radius: 10px;
  box-sizing: border-box;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};
  display: inline-flex;
  // font-weight: 400;
  height: ${({ size }) => size}px;
  justify-content: center;
  margin-bottom: ${({ $offset }) => ($offset.y ?? 0) * -1}px;
  margin-left: ${({ $offset }) => $offset.x ?? 0}px;
  min-width: ${({ size }) => size}px;
  padding: 0px 6px;
  position: relative;
  transition: opacity 120ms ease-in-out;
  vertical-align: bottom;

  &:hover,
  &:active {
    opacity: ${({ isInteractive }) => (isInteractive ? 0.8 : 1)};
  }
`
const Dot = styled.span<{ $fill: string; size: number }>`
  background-color: ${({ $fill }) => checkColorValue($fill)};
  border-radius: 50%;
  height: ${({ size }) => size}px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: ${({ size }) => size}px;
`
const Count = styled.span<{ $fill: string }>`
  color: ${({ $fill }) => checkColorValue($fill)};
  font-size: 12px;
  line-height: 1;
  pointer-events: none;
  user-select: none;
  text-align: center;
`

export default Badge
