//Utility functions for generating GraphQL queries, mutations and subscriptions

const TYPE_STRING = 'string'
const TYPE_NUMBER = 'number'
const TYPE_BOOLEAN = 'boolean'
const TYPE_OBJECT = 'object'

const KIND_SCALAR = 'SCALAR'

const GRAPHQL_QUERY = 'query'
const GRAPHQL_MUTATION = 'mutation'
const GRAPHQL_SUBSCRIPTION = 'subscription'

const KEYNAME_REQUEST_OPTIONS = 'requestOptions'

export interface BuildQueryResult {
  query: string
  name: string
}

//converts an typescript object to a graphql string
export function objectToGQLString(
  input: any,
  keysOnly?: boolean,
  sortKeys?: boolean
): string {
  let ret = ''
  let keys = Object.keys(input)
  if (sortKeys) keys = keys.sort()
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    const val = input[key]
    const type = typeof val
    if (key === KEYNAME_REQUEST_OPTIONS) continue
    if (keysOnly) {
      ret += key
      if (type === TYPE_OBJECT) {
        if (Array.isArray(val) && val.length > 0) {
          ret += ' {' + objectToGQLString(val[0], keysOnly) + '}'
        } else ret += ' {' + objectToGQLString(val, keysOnly) + '}'
      }
      ret += ', '
    } else {
      if (type === TYPE_STRING) {
        // Replace double universal quotes in the string to work correctly with graphql
        // and try to use reasonably correct quote direction for most situations.
        const string = val
          .replace(/"(^[^$]|\b)/gi, '“')
          .replace(/"($|\B)/gi, '”')
        ret += key + ':"' + JSON.stringify(string).replace(/"/g, '') + '", '
      } else if (type === TYPE_NUMBER || type === TYPE_BOOLEAN) {
        ret += key + ':' + val + ', '
      } else if (type === TYPE_OBJECT && val) {
        if (Array.isArray(val)) {
          ret += key + ':['
          for (let j = 0; j < val.length; j++) {
            const arrayType = typeof val[j]
            const arrayVal = val[j]
            if (arrayType === TYPE_STRING) {
              ret += '"' + arrayVal + '", '
            } else if (
              arrayType === TYPE_NUMBER ||
              arrayType === TYPE_BOOLEAN
            ) {
              ret += arrayVal + ', '
            } else {
              ret += '{' + objectToGQLString(arrayVal, keysOnly) + '}, '
            }
          }
          if (ret.endsWith(',')) ret = ret.substring(0, ret.length - 1)
          ret += '] '
        } else {
          ret += key + ':{' + objectToGQLString(val, keysOnly) + '}, '
        }
      }
    }
  }

  if (ret.endsWith(', ')) ret = ret.substring(0, ret.length - 2)
  return ret
}

//builds an executable string for a query, subscription or mutation
export function buildGQLString(
  type: string,
  name: string,
  parms?: any,
  fields?: string | object
): string {
  let ret: string = type + ' {' + name
  let parmsString = ''
  if (parms) {
    if (typeof parms === TYPE_STRING) {
      parmsString = parms
    } else {
      parmsString = objectToGQLString(
        parms,
        false,
        type === GRAPHQL_QUERY ? true : false
      )
    }
  }
  if (parmsString) {
    ret += '(' + parmsString + ') '
  } else {
    ret += ' '
  }

  if (fields) {
    if (typeof fields === TYPE_STRING) {
      ret += fields
    } else {
      ret += '{' + objectToGQLString(fields, true) + '}'
    }
  }

  ret += '}'

  return ret
}

//builds a return field list for a graphql query, mutation or subscription
export function buildFieldList(
  fieldMetadata: any,
  schemaTypes: { [index: string]: any }
): string {
  let md: any
  if (typeof fieldMetadata === TYPE_STRING) {
    md = schemaTypes[fieldMetadata].fields
  } else {
    md = fieldMetadata.fields
  }

  let ret = '{'
  for (let i = 0; i < md.length; i++) {
    const field = md[i]
    if (field.type.kind === KIND_SCALAR) {
      ret += field.name + ', '
    } else {
      const ofType = field.type.ofType
      if (ofType && ofType.kind !== KIND_SCALAR) {
        const subType = ofType.name
        ret += field.name + ' ' + buildFieldList(subType, schemaTypes) + ', '
      }
    }
  }
  if (ret.endsWith(', ')) {
    ret = ret.substring(0, ret.length - 2)
  }
  ret += '}'
  return ret
}

/**
 * Checks for overrides in the graphqlFieldList
 * @param fieldList
 * @param requestOptions
 */
export function checkFieldList(
  fieldList: string,
  requestOptionsFieldList?: string
): string {
  if (requestOptionsFieldList) {
    let fl = requestOptionsFieldList
    if (!fl.startsWith('{')) fl = '{' + fl + '}'
    return fl
  } else return fieldList
}

export function buildQuery(
  name: string,
  parms: any,
  fields: string | object
): BuildQueryResult {
  return {
    name,
    query: buildGQLString(GRAPHQL_QUERY, name, parms, fields),
  }
}

export default {
  buildFieldList,
  buildQuery,
  buildMutation,
  buildGQLString,
  buildSubscription,
  checkFieldList,
}

export function buildMutation(
  name: string,
  parms: any,
  fields: string | object
): string {
  return buildGQLString(GRAPHQL_MUTATION, name, parms, fields)
}

export function buildSubscription(
  name: string,
  parms: any,
  fields: string | object
): string {
  return buildGQLString(GRAPHQL_SUBSCRIPTION, name, parms, fields)
}
