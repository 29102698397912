import React, { useCallback, useMemo } from 'react'
import moment from 'moment'
import { useDrop } from 'react-dnd'
import styled from 'styled-components'

import { Event, MutationEventUpdateRequest } from '@sportsyou/api'
import { Colors } from '@sportsyou/core'
import { DayProps } from '../calendar-types'
import { handleOnDropEvent } from '../helpers'

interface Props {
  day: DayProps
  index: number
  onClickDay?: (date: DayProps, e: React.MouseEvent<HTMLDivElement>) => void
  onDoubleClickDay?: (
    data: DayProps,
    e: React.MouseEvent<HTMLDivElement>
  ) => void
  onDropEvent?: (
    originalEvent: Event,
    newEvent: Event,
    newEventRequest: MutationEventUpdateRequest
  ) => void
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type DayHalfHourProps = Props & NativeAttributes

export const DayHalfHour: React.FC<DayHalfHourProps> = ({
  day,
  index,
  onClickDay,
  onDoubleClickDay,
  onDropEvent,
}: DayHalfHourProps) => {
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: 'EVENT',
      drop: (event: Event) => {
        _handleOnDropEvent(event, day)
      },
      collect: (monitor) => ({
        canDrop: !!monitor.canDrop(),
        isOver: !!monitor.isOver(),
      }),
    }),
    [day]
  )

  const momentDay = useMemo(() => {
    return moment(day.date)
  }, [day])

  const convertIndexToTime = (i: number) => {
    const hour = Math.floor(i / 2)
    const minute = (i % 2) * 30
    return momentDay.hour(hour).minute(minute)
  }

  const _handleOnDropEvent = (event: Event, newDay: DayProps) => {
    const diffInMinutes = moment(event.endDate).diff(event.startDate, 'minutes')

    const startTime = moment(convertIndexToTime(index)).format('h:mma')
    const endTime = moment(convertIndexToTime(index))
      .add(diffInMinutes, 'minutes')
      .format('h:mma')

    const dropEventData = handleOnDropEvent(
      event,
      newDay,
      'day',
      startTime,
      endTime
    )

    if (!dropEventData) return

    const { event: _event, newEvent, eventRequest } = dropEventData

    onDropEvent?.(_event, newEvent, eventRequest)
  }

  const handleOnClickDay = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      // convert index to time
      const time = convertIndexToTime(index)

      onClickDay?.(
        {
          date: time.toDate(),
          day: time.date().toString(),
          type: 'current',
        },
        e
      )
    },
    [convertIndexToTime, index, onClickDay]
  )

  const handleOnDoubleClickDay = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      // convert index to time
      const time = convertIndexToTime(index)

      onDoubleClickDay?.(
        {
          date: time.toDate(),
          day: time.date().toString(),
          type: 'current',
        },
        e
      )
    },
    [convertIndexToTime, index, onDoubleClickDay]
  )

  return (
    <HalfHour
      ref={drop}
      style={{
        backgroundColor: canDrop && isOver ? Colors.MYSTIC : undefined,
      }}
      dotted={index % 2 === 0}
      onClick={handleOnClickDay}
      onDoubleClick={handleOnDoubleClickDay}
    />
  )
}

const HalfHour = styled.div<{ dotted?: boolean }>`
  border-bottom: 1px solid #ccc;
  border-bottom-style: ${({ dotted }) => (dotted ? 'dotted' : 'solid')};
  height: 25px;
  border-left: 1px solid #ccc;
`

export default DayHalfHour
