import * as React from 'react'
import type { SVGProps } from 'react'
const Code = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <g fillRule='nonzero'>
      <path d='M27.576 11.576A4.43 4.43 0 0 0 23.15 16c.005.785.22 1.553.624 2.226l-5.549 5.549a4.377 4.377 0 0 0-2.226-.622 4.424 4.424 0 1 0 4.422 4.424 4.372 4.372 0 0 0-.619-2.221l5.55-5.552c.672.402 1.44.616 2.222.621a4.424 4.424 0 0 0 0-8.848l.002-.001zM16 29.768a2.192 2.192 0 1 1-.004-4.384A2.192 2.192 0 0 1 16 29.768zM27.576 18.19a2.191 2.191 0 1 1 .002-4.382 2.191 2.191 0 0 1-.002 4.382z' />
      <path d='M27.576 23.152a4.424 4.424 0 1 0 0 8.848 4.424 4.424 0 0 0 0-8.848zm0 6.616a2.192 2.192 0 1 1 0-4.384 2.192 2.192 0 0 1 0 4.384zM13.778 19.8a4.384 4.384 0 0 0 2.222.624A4.429 4.429 0 0 0 20.422 16a4.372 4.372 0 0 0-.619-2.221l5.55-5.552c.672.402 1.44.616 2.222.621a4.424 4.424 0 1 0-4.426-4.424c.005.785.22 1.553.624 2.226l-5.547 5.55A4.377 4.377 0 0 0 16 11.576 4.429 4.429 0 0 0 11.576 16c.004.784.219 1.553.622 2.226L6.65 23.774a4.382 4.382 0 0 0-2.227-.622 4.424 4.424 0 1 0 4.426 4.424 4.384 4.384 0 0 0-.621-2.222l5.55-5.554zm13.8-17.571a2.191 2.191 0 1 1-2.192 2.192 2.194 2.194 0 0 1 2.19-2.189l.002-.003zM16 13.808a2.191 2.191 0 1 1-.002 4.382A2.191 2.191 0 0 1 16 13.808zM4.422 29.768a2.192 2.192 0 1 1 0-4.384 2.192 2.192 0 0 1 0 4.384z' />
      <path d='M4.422 20.424A4.431 4.431 0 0 0 8.848 16a4.384 4.384 0 0 0-.621-2.222l5.55-5.55c.672.401 1.44.616 2.223.62a4.424 4.424 0 1 0-4.424-4.424c.005.785.22 1.553.624 2.226L6.65 12.2a4.382 4.382 0 0 0-2.227-.622 4.424 4.424 0 0 0 0 8.848l-.001-.002zM16 2.232a2.191 2.191 0 1 1-.002 4.382A2.191 2.191 0 0 1 16 2.232zM4.422 13.808a2.191 2.191 0 1 1 .002 4.382 2.191 2.191 0 0 1-.002-4.382z' />
      <path d='M4.422 8.848A4.424 4.424 0 1 0 0 4.424a4.431 4.431 0 0 0 4.422 4.424zm0-6.616a2.191 2.191 0 1 1 .002 4.382 2.191 2.191 0 0 1-.002-4.382z' />
    </g>
  </svg>
)
export default Code
