import * as React from 'react'
import type { SVGProps } from 'react'
const Hamburger = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <g>
      <path d='M29.85 9.55H2.15a2.16 2.16 0 0 1 0-4.31h27.7a2.16 2.16 0 0 1 0 4.31z' />
      <path d='M29.85 18.15H2.15a2.15 2.15 0 1 1 0-4.3h27.7a2.15 2.15 0 0 1 0 4.3z' />
      <path d='M29.85 26.76H2.15a2.16 2.16 0 0 1 0-4.31h27.7a2.16 2.16 0 0 1 0 4.31z' />
    </g>
  </svg>
)
export default Hamburger
