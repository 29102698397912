import * as React from 'react'
import type { SVGProps } from 'react'
const FabJoinTeam = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M16.29 17.55a6.92 6.92 0 0 1 1-1.22l.12-.11H9.83v-4.47a.67.67 0 1 0-1.33 0v1.48H6.16a.33.33 0 0 1-.33-.33v-2.79A2.36 2.36 0 0 1 7.18 8L9.9 6.74a.7.7 0 0 1 .72.08l3.64 2.78a.67.67 0 0 0 .81 0l3.75-2.87a.44.44 0 0 1 .48-.05L22.15 8a2.36 2.36 0 0 1 1.35 2.12v2.78a.33.33 0 0 1-.33.33h-2.34v-1.48a.67.67 0 1 0-1.33 0v3.15a6.62 6.62 0 0 1 .62-.24 6.88 6.88 0 0 1 1.88-.33h.28a1.23 1.23 0 0 1 .27 0 6 6 0 0 1 1.11.14 1.64 1.64 0 0 0 1.19-1.58v-2.78a3.67 3.67 0 0 0-2.12-3.32l-2.87-1.32a1.78 1.78 0 0 0-1.84.2l-3.35 2.57-3.24-2.48a2 2 0 0 0-2.09-.23L6.62 6.79a3.67 3.67 0 0 0-2.12 3.32v2.78a1.67 1.67 0 0 0 1.66 1.66H8.5v8.15a1.66 1.66 0 0 0 1.67 1.66h5.6a3.54 3.54 0 0 1-.16-.34 1.17 1.17 0 0 1 0-.11 8.09 8.09 0 0 1-.25-.88h-5.19a.34.34 0 0 1-.34-.33v-1.37h5.29a6.27 6.27 0 0 1 .11-1.13l.06-.2H9.83v-2.45z' />
    <path d='M26.15 18c-.11-.12-.22-.24-.34-.35A6.76 6.76 0 0 0 25 17a6.37 6.37 0 0 0-.63-.35c-.12-.05-.26-.08-.39-.13a5.35 5.35 0 0 0-1.6-.31h-.28a5 5 0 0 0-1.28.22h-.14a5.17 5.17 0 0 0-1.9 1.07l-.18.15a5.32 5.32 0 0 0-1.28 2 2.68 2.68 0 0 0-.07.27 3.87 3.87 0 0 0-.15.56 4.9 4.9 0 0 0-.08.77v.1a4.4 4.4 0 0 0 .14 1.09 4.27 4.27 0 0 0 .13.48l.06.22a5.22 5.22 0 0 0 .57 1.07v.06a5 5 0 0 0 .39.52 6.05 6.05 0 0 0 .79.73 5.22 5.22 0 0 0 2.24.95 6 6 0 0 0 .85.07A5.22 5.22 0 0 0 26.15 18zm-.45 2l-3.85 3.75a.59.59 0 0 1-.39.16.56.56 0 0 1-.4-.17l-.36-.37-1.2-1.23-.69-.71a.56.56 0 0 1 0-.08.55.55 0 0 1 .06-.71.54.54 0 0 1 .39-.15.55.55 0 0 1 .39.16l1.23 1.26.64.66 3.45-3.37a.56.56 0 0 1 .39-.15.59.59 0 0 1 .4.16.54.54 0 0 1 .15.4.57.57 0 0 1-.21.39z' />
  </svg>
)
export default FabJoinTeam
