import * as React from 'react'
import type { SVGProps } from 'react'
const Location = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 22.56 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <g>
      <path d='M11.28 0A11.27 11.27 0 0 0 1.15 16.23a2.26 2.26 0 0 0 .11.22v.08c.18.32.37.64.57.95l8.2 13.83a1.4 1.4 0 0 0 1.92.5 1.38 1.38 0 0 0 .49-.5l8.2-13.83a10.38 10.38 0 0 0 .57-.95l.05-.08a2.14 2.14 0 0 0 .1-.22A11.26 11.26 0 0 0 11.28 0zm0 4.68a6.59 6.59 0 1 1-6.59 6.59 6.59 6.59 0 0 1 6.59-6.59z' />
    </g>
  </svg>
)
export default Location
