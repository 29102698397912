import { isImage } from './'

export const isGif = (contentType = '') => {
  const _isImage = isImage(contentType)
  let ret = false
  if (_isImage) {
    const gifContentTest = /\/gif$/
    ret = gifContentTest.test(contentType)
  }
  return ret
}

export default isGif
