import { configureStore, StoreEnhancer } from '@reduxjs/toolkit'
import { createInjectorsEnhancer } from 'redux-injectors'
import { save, load } from 'redux-localstorage-simple'
import createSagaMiddleware from 'redux-saga'

import { createReducer } from './reducers'
import communitiesReducer from './slices/CommunitiesSlice'
import sponsoredPostsReducer from './slices/SponsoredPostsSlice'
import userReducer from './slices/UserSlice'

export function configureAppStore() {
  const reduxSagaMonitorOptions = {}
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions)
  const { run: runSaga } = sagaMiddleware

  // Create the store with saga middleware
  const middlewares = [sagaMiddleware]

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ] as StoreEnhancer[]

  const localStorageConfig = {
    disableWarnings: true,
    namespace: 'sy-content-portal',
    namespaceSeparator: '::',
    states: ['communities', 'sponsoredPosts', 'user'],
  }

  const store = configureStore({
    devTools: process.env.NODE_ENV !== 'production',
    enhancers,
    middleware: [...middlewares, save(localStorageConfig)],
    preloadedState: load(localStorageConfig),
    reducer: createReducer(sliceReducers),
  })

  return store
}

const sliceReducers: any = {
  communities: communitiesReducer,
  sponsoredPosts: sponsoredPostsReducer,
  user: userReducer,
}

export type RootState = ReturnType<typeof sliceReducers>
