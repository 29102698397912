import * as React from 'react'
import type { SVGProps } from 'react'
const Pin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24.57 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M23.33 17.33l-5.28-3.76V9.15l4.26-2.83a3 3 0 0 0 1.33-2.48V3a3 3 0 0 0-3-3H3.91a3 3 0 0 0-3 3v.87a3 3 0 0 0 1.35 2.45l4.25 2.83v4.42l-5.27 3.76A3 3 0 0 0 3 22.69h8.21v8.19a1.12 1.12 0 1 0 2.23 0v-8.19h8.21a3 3 0 0 0 1.72-5.36zm-1.72 3.13H3a.73.73 0 0 1-.73-.73.74.74 0 0 1 .31-.59l5.74-4.09a1.13 1.13 0 0 0 .47-.91V8.56a1.1 1.1 0 0 0-.5-.93L3.5 4.46a.75.75 0 0 1-.34-.62V3a.76.76 0 0 1 .75-.75h16.75a.76.76 0 0 1 .75.75v.86a.76.76 0 0 1-.33.62l-4.76 3.15a1.1 1.1 0 0 0-.5.93v5.58a1.13 1.13 0 0 0 .47.91L22 19.14a.74.74 0 0 1 .31.59.73.73 0 0 1-.7.73z' />
  </svg>
)
export default Pin
