import * as React from 'react'
import type { SVGProps } from 'react'
const FabCreateGame = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M14.67 22.46H7.94A1.49 1.49 0 0 1 6.45 21v-9.37h16.44v1.48a.76.76 0 0 0 1.52 0V8.62a3 3 0 0 0-3-3h-1.49v-.36a.76.76 0 1 0-1.52 0v.36h-7.46v-.36a.76.76 0 0 0-.76-.76.75.75 0 0 0-.75.76v.36H7.94a3 3 0 0 0-3 3V21a3 3 0 0 0 3 3h6.73a.76.76 0 1 0 0-1.51zM6.45 8.62a1.49 1.49 0 0 1 1.49-1.48h1.49v.36a.75.75 0 0 0 .75.76.76.76 0 0 0 .76-.76v-.36h7.46v.36a.76.76 0 0 0 1.52 0v-.36h1.49a1.48 1.48 0 0 1 1.48 1.48v1.49H6.45z' />
    <path d='M14.6 15.89a.24.24 0 0 0-.19-.16l-1.94-.29-.87-1.77a.25.25 0 0 0-.43 0l-.87 1.77-1.94.29a.24.24 0 0 0-.19.16.23.23 0 0 0 .06.24l1.41 1.38-.34 1.95a.25.25 0 0 0 .1.23.23.23 0 0 0 .25 0l1.73-.92 1.74.92a.23.23 0 0 0 .11 0 .24.24 0 0 0 .14 0 .23.23 0 0 0 .09-.23l-.33-1.95 1.41-1.38a.23.23 0 0 0 .06-.24zM28.42 17.9l-2.1-2.1a2.21 2.21 0 0 0-3.12 0l-5.83 5.83a2.23 2.23 0 0 0-.65 1.57v3.41a.89.89 0 0 0 .89.89H21a2.19 2.19 0 0 0 1.56-.65L28.42 21a2.21 2.21 0 0 0 0-3.1zm-1.26 1.86l-5.83 5.83a.43.43 0 0 1-.3.13H18.5V23.2a.44.44 0 0 1 .13-.31l5.83-5.82a.43.43 0 0 1 .6 0l2.1 2.1a.43.43 0 0 1 0 .59z' />
  </svg>
)
export default FabCreateGame
