import { Colors } from '@sportsyou/core'
import React, { useMemo } from 'react'
import styled from 'styled-components'

type DividerOrientation = 'horizontal' | 'vertical'
type DividerTextAlign = 'center' | 'end' | 'start'
type DividerVariant = 'center' | 'stretch'

export type DividerProps = {
  /**
   * Optional content of component
   */
  children?: React.ReactNode
  className?: string
  /**
   * Fill color of divider
   */
  fill?: string
  /**
   * Amount of space between divider and content
   */
  gap?: number | string
  /**
   * The component orientation
   */
  orientation?: DividerOrientation
  style?: React.CSSProperties
  testId?: string
  /**
   * The text alignment when `children` is provided to component
   */
  textAlign?: DividerTextAlign
  /**
   * Thickness of the divider
   */
  thickness?: number | string
  variant?: DividerVariant
}

export const Divider: React.FC<React.PropsWithChildren<DividerProps>> = ({
  children,
  className,
  fill = Colors.ALTO,
  gap = 0,
  orientation = 'horizontal',
  testId,
  textAlign = 'center',
  style,
  thickness = 1,
  variant = 'stretch',
}: DividerProps) => {
  const gapAsString = useMemo(
    () => (typeof gap === 'number' ? `${gap}px` : gap),
    [gap]
  )

  const thicknessAsString = useMemo(
    () => (typeof thickness === 'number' ? `${thickness}px` : thickness),
    [thickness]
  )

  if (children) {
    return (
      <Container
        aria-orientation={orientation}
        $fill={fill}
        isVertical={orientation === 'vertical'}
        gap={gapAsString}
        role='separator'
        textAlign={textAlign}
        thickness={thicknessAsString}
        variant={variant}
      >
        <Child isVertical={orientation === 'vertical'}>{children}</Child>
      </Container>
    )
  }

  return (
    <Rule
      aria-orientation={orientation}
      className={className}
      $fill={fill}
      gap={gapAsString}
      isVertical={orientation === 'vertical'}
      role='separator'
      style={style}
      data-testid={testId}
      thickness={thicknessAsString}
      variant={variant}
    />
  )
}

const Container = styled.div<{
  $fill: string
  gap: string
  isVertical?: boolean
  thickness: string
  textAlign: DividerTextAlign
  variant: DividerVariant
}>`
  display: flex;
  flex-direction: ${({ isVertical }) => (isVertical ? 'column' : 'row')};

  align-self: ${({ isVertical }) => (isVertical ? 'stretch' : undefined)};
  height: ${({ isVertical }) => (isVertical ? 'auto' : undefined)};

  margin: ${({ gap, isVertical, variant }) =>
    variant === 'center'
      ? isVertical
        ? `0 ${gap}`
        : `${gap} 0`
      : isVertical
      ? `0 ${gap}`
      : `${gap} 0`};

  text-align: ${({ textAlign }) => textAlign};
  white-space: nowrap;

  &::before,
  &::after {
    content: '';
    align-self: center;

    border-color: ${({ $fill }) => $fill};
    border-style: solid;
    border-width: ${({ isVertical, thickness }) =>
      isVertical ? `0 0 0 ${thickness}` : `${thickness} 0 0`};
  }
  &::before {
    height: ${({ isVertical, textAlign }) =>
      isVertical ? (textAlign === 'start' ? '10%' : '100%') : undefined};
    margin-left: ${({ isVertical, variant }) =>
      !isVertical && variant === 'center' ? '12px' : undefined};
    margin-top: ${({ isVertical, variant }) =>
      isVertical && variant === 'center' ? '12px' : undefined};
    width: ${({ isVertical, textAlign }) =>
      !isVertical ? (textAlign === 'start' ? '10%' : '100%') : undefined};
  }
  &::after {
    height: ${({ isVertical, textAlign }) =>
      isVertical ? (textAlign === 'end' ? '10%' : '100%') : undefined};
    margin-bottom: ${({ isVertical, variant }) =>
      isVertical && variant === 'center' ? '12px' : undefined};
    margin-right: ${({ isVertical, variant }) =>
      !isVertical && variant === 'center' ? '12px' : undefined};
    width: ${({ isVertical, textAlign }) =>
      !isVertical ? (textAlign === 'end' ? '10%' : '100%') : undefined};
  }
`
const Child = styled.span<{ isVertical?: boolean }>`
  display: inline-block;
  padding: ${({ isVertical }) => (isVertical ? '12px 0' : '0 12px')};
`

const Rule = styled.hr<{
  $fill: string
  gap: string
  isVertical?: boolean
  thickness: string
  variant: DividerVariant
}>`
  border-color: ${({ $fill }) => $fill};
  border-style: solid;
  border-width: ${({ isVertical, thickness }) =>
    isVertical ? `0 ${thickness} 0 0` : `0 0 ${thickness}`};

  height: ${({ isVertical, variant }) =>
    isVertical
      ? variant === 'center'
        ? `calc(100% - 12px - 12px)`
        : '100%'
      : undefined};

  margin: ${({ gap, isVertical, variant }) =>
    variant === 'center'
      ? isVertical
        ? `12px ${gap}`
        : `${gap} 12px`
      : isVertical
      ? `0 ${gap}`
      : `${gap} 0`};

    };

  width: ${({ isVertical, variant }) =>
    !isVertical
      ? variant === 'center'
        ? `calc(100% - 12px - 12px)`
        : '100%'
      : undefined};
`

export default Divider
