import { useDrop } from 'react-dnd'
import moment from 'moment'
import React, { forwardRef, useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { Event as EventProps, MutationEventUpdateRequest } from '@sportsyou/api'
import { Colors } from '@sportsyou/core'
import { handleOnDropEvent } from '../helpers'
import Button from '../../button'
import Dropdown from '../../dropdown'
import EventChip from './month-event-chip'
import { DayProps } from '../calendar-types'

interface MonthCalendarDayProps {
  allEvents: EventProps[]
  day: DayProps
  eventIdToClickOnMount?: string
  fillChipWithGameType?: boolean
  maxEventChipsPerDay: number
  onClickEvent?: (
    event: EventProps,
    e: React.MouseEvent<HTMLDivElement>
  ) => void
  onClickDay?: (date: DayProps, e: React.MouseEvent<HTMLDivElement>) => void
  onDoubleClickDay?: (
    date: DayProps,
    e: React.MouseEvent<HTMLDivElement>
  ) => void
  onDropEvent?: (
    originalEvent: EventProps,
    newEvent: EventProps,
    newEventRequest: MutationEventUpdateRequest
  ) => void
}

export const MonthCalendarDay = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<MonthCalendarDayProps>
>(
  (
    {
      allEvents,
      day,
      eventIdToClickOnMount,
      fillChipWithGameType,
      maxEventChipsPerDay,
      onClickDay,
      onClickEvent,
      onDoubleClickDay,
      onDropEvent,
    }: MonthCalendarDayProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const isToday = useMemo(
      () =>
        moment(day.date).format('MM/DD/YYYY') === moment().format('MM/DD/YYYY'),
      [day.date]
    )

    const [{ canDrop, isOver }, drop] = useDrop(
      () => ({
        accept: 'EVENT',
        drop: (event: EventProps) => {
          _handleOnDropEvent(event, day)
        },
        collect: (monitor) => ({
          isOver: !!monitor.isOver(),
          canDrop: !!monitor.canDrop(),
        }),
      }),
      [day]
    )

    const _handleOnDropEvent = (event: EventProps, newDay: DayProps) => {
      const dropEventData = handleOnDropEvent(event, newDay, 'month')

      if (!dropEventData) return

      const { event: _event, newEvent, eventRequest } = dropEventData

      onDropEvent && onDropEvent(_event, newEvent, eventRequest)
    }

    const _onClickDay = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        onClickDay?.(day, e)
      },
      [day, onClickDay]
    )

    const _onDoubleClickDay = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        onDoubleClickDay?.(day, e)
      },
      [day, onDoubleClickDay]
    )

    return (
      <Wrapper
        isCurrentMonth={day.type === 'current'}
        ref={drop}
        style={{
          backgroundColor:
            isOver && canDrop
              ? Colors.MYSTIC
              : isToday
              ? '#fffbe5' //'#f3f4cf'
              : undefined,
        }}
        onClick={_onClickDay}
        onDoubleClick={_onDoubleClickDay}
      >
        <DayNumber isCurrentMonth={day.type === 'current'} isToday={isToday}>
          <span>{day.day}</span>
        </DayNumber>
        <Container>
          {(allEvents?.length ?? 0) > maxEventChipsPerDay ? (
            <ShowMoreEventsChip>
              <Dropdown hideChevron preventMenuClipping>
                <Dropdown.Toggle>
                  <ShowMoreEventsChipButton
                    appearance='minimal'
                    variant='primary'
                  >
                    {allEvents.length - maxEventChipsPerDay} more
                  </ShowMoreEventsChipButton>
                </Dropdown.Toggle>

                <Dropdown.Header>
                  {moment(day.date).format('MMMM D, YYYY')}
                </Dropdown.Header>
                {allEvents.map((event) => (
                  <Dropdown.Item key={event.id} style={{ padding: '10px 0' }}>
                    <EventChip
                      event={event}
                      fill={event.color!}
                      fillWithGameType={fillChipWithGameType}
                      id={event.id!}
                      key={event.id!}
                      onClick={onClickEvent}
                      shouldTriggerClickOnMount={
                        event.id === eventIdToClickOnMount
                      }
                      style={{ width: '100%' }}
                    />
                  </Dropdown.Item>
                ))}
              </Dropdown>
            </ShowMoreEventsChip>
          ) : null}
        </Container>
      </Wrapper>
    )
  }
)

const Wrapper = styled.div<{ isCurrentMonth: boolean }>`
  background-color: ${({ isCurrentMonth }) =>
    isCurrentMonth ? Colors.WHITE : '#f5f5f5'};
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${Colors.ALTO};
  border-top: 1px solid ${Colors.ALTO};

  position: relative;
  user-select: none;

  aspect-ratio: 1;
`
const Container = styled.div`
  border-bottom: none;
  border-right: none;
  height: 100%;
  position: relative;
  width: 100%;
`

const DayNumber = styled.div<{ isCurrentMonth: boolean; isToday: boolean }>`
  align-self: flex-end;
  color: ${({ isCurrentMonth, isToday }) =>
    isToday ? Colors.WHITE : isCurrentMonth ? Colors.BLACK : Colors.DUSTY_GRAY};
  margin: 4px;
  & > span {
    align-items: center;
    background-color: ${({ isToday }) =>
      isToday ? Colors.HAVELOCK_BLUE : undefined};

    border-radius: 50%;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    height: 22px;
    font-size: 14px;
    padding: 4px;
    width: 22px;
  }
`

const ShowMoreEventsChip = styled.div`
  bottom: 0;
  color: #111;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  left: 0;
  padding: 2px 4px;
  position: absolute;
`
const ShowMoreEventsChipButton = styled(Button)`
  min-height: 0;
  min-width: 0;
  padding: 0;
  width: 100%;
  color: ${Colors.BLACK};
`

export default MonthCalendarDay
