import { useCallback, useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import styled from 'styled-components'

import { Colors, parseUrls } from '@sportsyou/core'
import { Icon, Post as PostComponent, Spinner } from '@sportsyou/react-dom-ui'
import {
  CacheOptions,
  GetMetadataRequest,
  GetMetadataResponse,
  PostMetadata,
  Post as PostType,
  User,
  getMetadata,
  queryPost,
  queryUser,
} from '@sportsyou/api'
import { useFetchApi } from '@sportsyou/react-hooks'

import {
  Header,
  HeaderTitle,
  TableTools,
  TableToolsLeft,
  TableToolsRight,
} from '.'
import {
  ContentContainer,
  HeaderTitleChevron,
} from '../../styles/global-styles'
import { useDebouncedCallback } from 'use-debounce'

export const SponsoredPostsDetail = () => {
  const params = useParams()

  const [currentUser, setCurrentUser] = useState<User>()
  const [isLoading, setIsLoading] = useState(true)
  const [post, setPost] = useState<PostType>()
  const [metadata, setMetadata] = useState<PostMetadata[]>([])

  const { fetch: getPost } = useFetchApi(queryPost)
  const { fetch: loadUser } = useFetchApi(queryUser)

  const fetchData = useCallback(async () => {
    const { data } = await getPost({ id: params.id })
    data && setPost(data)
    setIsLoading(false)
  }, [getPost, params.id])

  const onClickUpload = useCallback(() => {
    if (post?.sponsoredPostInfo?.clickThroughUrl) {
      window.open(post?.sponsoredPostInfo.clickThroughUrl, '_blank')
    }
  }, [post])

  const fetchMetadata = useDebouncedCallback(async () => {
    const urls = parseUrls(post?.message as string)
    const md: PostMetadata[] = []
    urls.length && setIsLoading(true)
    for (const url of urls) {
      const req: GetMetadataRequest = {
        url,
        requestOptions: {
          useCache: CacheOptions.ALWAYS,
        },
      }
      const resp: GetMetadataResponse = await getMetadata(req)
      if (!resp?.error) {
        resp?.data && md.push(resp.data)
      }
    }
    setMetadata(md)
    setIsLoading(false)
  }, 1000)

  useEffect(() => {
    if (post?.message && !post?.color) {
      fetchMetadata()
    }
  }, [fetchMetadata, post?.color, post?.message])

  useEffect(() => {
    async function checkUser() {
      const res = await loadUser().catch((err) => console.log(err))
      const userData: User = res?.data as User
      setCurrentUser(userData)
    }

    if (currentUser) {
      fetchData()
    } else {
      checkUser()
    }
  }, [currentUser, loadUser, fetchData])

  return (
    <ContentContainer>
      <Header>
        <HeaderTitle>
          <Link to={'/sponsored-posts'} title='Sponsored Posts Home'>
            <Icon name='Home' fill={Colors.BLACK} width={18} />
          </Link>
          <span>
            <HeaderTitleChevron />
            {isLoading ? '...' : post?.sponsoredPostInfo?.adName ?? 'Untitled'}
            <HeaderTitleChevron />
            Preview
          </span>
        </HeaderTitle>
      </Header>
      <TableTools>
        <TableToolsLeft></TableToolsLeft>
        <TableToolsRight></TableToolsRight>
      </TableTools>
      {!isLoading && (
        <PostContainer>
          <PostWrapper>
            <PostComponent
              {...post}
              metadata={metadata}
              onClickUpload={onClickUpload}
            />
          </PostWrapper>
        </PostContainer>
      )}
      {!!isLoading && <Spinner />}
    </ContentContainer>
  )
}

const PostContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: ${Colors.ALTO};
`
const PostWrapper = styled.div`
  max-width: 500px;
`

export default SponsoredPostsDetail
