export const SITE_MAX_WIDTH = '1200px'

export const SMALL_SCREEN_BREAKPOINT = '900px'

// Modular scale ratio
export const MS_RATIO = 1.25

export const FONT_SIZE = 16
export const FONT_SIZE_SM = FONT_SIZE / MS_RATIO
export const FONT_SIZE_XS = FONT_SIZE_SM / MS_RATIO
export const FONT_SIZE_LG = FONT_SIZE * MS_RATIO

export const FONT_SIZE_H5 = FONT_SIZE * MS_RATIO
export const FONT_SIZE_H4 = FONT_SIZE_H5 * MS_RATIO
export const FONT_SIZE_H3 = FONT_SIZE_H4 * MS_RATIO
export const FONT_SIZE_H2 = FONT_SIZE_H3 * MS_RATIO
export const FONT_SIZE_H1 = FONT_SIZE_H2 * MS_RATIO

export const LINE_HEIGHT = 1.4

export const TRANSITION_EASING = '150ms ease-in-out'

export const BORDER_RADIUS = '6px'
