import * as React from 'react'
import type { SVGProps } from 'react'
const Paperclip = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 18.91 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M9.38 0A9.4 9.4 0 0 0 0 9.39V25.1a6.91 6.91 0 0 0 13.81 0V11a4.44 4.44 0 0 0-4.42-4.4 4.63 4.63 0 0 0-3.14 1.29A4.45 4.45 0 0 0 4.93 11v11.62a1.12 1.12 0 0 0 2.24 0V11a2.2 2.2 0 0 1 2.2-2.2 2.22 2.22 0 0 1 2.21 2.2v14.1a4.68 4.68 0 0 1-9.35 0V9.39a7.16 7.16 0 1 1 14.31 0V21a1.12 1.12 0 1 0 2.23 0V9.39A9.4 9.4 0 0 0 9.38 0z' />
  </svg>
)
export default Paperclip
