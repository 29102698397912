import Radio from './radio'
import RadioGroup from './radio-group'

export type RadioComponentType = typeof Radio & {
  Group: typeof RadioGroup
}
;(Radio as RadioComponentType).Group = RadioGroup

export type { RadioProps, RadioEvent, RadioEventTarget } from './radio'
export type { RadioGroupProps } from './radio-group'

export default Radio as RadioComponentType
