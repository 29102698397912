import React, { CSSProperties, useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { User } from '@sportsyou/api'
import { Colors } from '@sportsyou/core'

import { BORDER_RADIUS } from '../../theme'

import Avatar from '../avatar'
import Button, { ButtonProps } from '../button'
import Icon, { IconProps } from '../icon'

type TestIds = {
  avatar?: string
  container?: string
  inputButton?: string
  buttons?: {
    color?: string
    file?: string
    photo?: string
    poll?: string
  }
}
export interface CreatePostInputProps {
  canCreatePoll?: boolean
  className?: string
  currentUser?: User
  inputButtonStyle?: CSSProperties
  onClickAddColor?: (teamId?: string) => void
  onClickAddFile?: (teamId?: string) => void
  onClickAddPhoto?: (teamId?: string) => void
  onClickAddPoll?: (teamId?: string) => void
  onClickAvatar?: () => void
  onClickInput?: (teamId?: string) => void
  placeholder?: string
  style?: CSSProperties
  teamId?: string
  testId?: TestIds
}

type PostAction = {
  button: ButtonProps
  icon: IconProps
}

export const CreatePostInput = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<CreatePostInputProps>
>(
  (
    {
      canCreatePoll,
      className,
      currentUser,
      inputButtonStyle,
      onClickAddColor,
      onClickAddFile,
      onClickAddPhoto,
      onClickAddPoll,
      onClickAvatar,
      onClickInput,
      placeholder = 'Share a post',
      style,
      teamId,
      testId,
      ...props
    }: CreatePostInputProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { profileImage, fullName } = currentUser || {}

    const handleOnClickInput = useCallback(() => {
      onClickInput?.(teamId)
    }, [onClickInput, teamId])

    const handleOnClickAvatar = () => {
      onClickAvatar && onClickAvatar()
    }

    const handleOnClickAddPhoto = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        onClickAddPhoto?.(teamId)
      },
      [onClickAddPhoto, teamId]
    )

    const handleOnClickAddFile = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        onClickAddFile?.(teamId)
      },
      [onClickAddFile, teamId]
    )

    const handleOnClickAddPoll = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        onClickAddPoll?.(teamId)
      },
      [onClickAddPoll, teamId]
    )

    const handleOnClickAddColor = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        onClickAddColor?.(teamId)
      },
      [onClickAddColor, teamId]
    )

    const PostActions: Array<PostAction> = useMemo(
      () => [
        {
          button: {
            'data-testid': testId?.buttons?.photo,
            onClick: handleOnClickAddPhoto,
            textStyle: { display: 'flex' },
            tooltip: 'Post a Photo/Video',
          },
          icon: { fill: Colors.HAVELOCK_BLUE, name: 'CameraSolid' },
        },
        {
          button: {
            'data-testid': testId?.buttons?.file,
            onClick: handleOnClickAddFile,
            tooltip: 'Post a File',
          },
          icon: { fill: Colors.HAVELOCK_BLUE, name: 'Paperclip' },
        },
        ...(canCreatePoll
          ? [
              {
                button: {
                  'data-testid': testId?.buttons?.poll,
                  onClick: handleOnClickAddPoll,
                  tooltip: 'Create a Poll',
                },
                icon: { fill: Colors.HAVELOCK_BLUE, name: 'PollsFilled' },
              } as PostAction,
            ]
          : []),
        {
          button: {
            'data-testid': testId?.buttons?.color,
            onClick: handleOnClickAddColor,
            tooltip: 'Create a Color Post',
          },
          //TODO: Swap Icon for colorful icon
          icon: { fill: Colors.SHUTTLE_GRAY, name: 'ColorPost' },
        },
      ],
      [
        canCreatePoll,
        handleOnClickAddColor,
        handleOnClickAddFile,
        handleOnClickAddPhoto,
        handleOnClickAddPoll,
        testId?.buttons?.color,
        testId?.buttons?.file,
        testId?.buttons?.photo,
        testId?.buttons?.poll,
      ]
    )

    return (
      <Container
        {...props}
        className={className}
        data-testid={testId?.container}
        ref={ref}
        style={style}
      >
        <Avatar
          diameter={32}
          name={fullName ?? undefined}
          onClick={handleOnClickAvatar}
          testId={testId?.avatar}
          uri={profileImage?.[0]?.viewUrl ?? undefined}
        />
        <InputButton
          data-testid={testId?.inputButton}
          onClick={handleOnClickInput}
          style={inputButtonStyle}
        >
          <Placeholder>{placeholder}</Placeholder>
          <ButtonContainer>
            {PostActions.map(({ button, icon }, index: number) => (
              <StyledButton
                {...(button as ButtonProps)}
                key={index}
                appearance='minimal'
                collapse
                textStyle={{ display: 'flex' }}
              >
                <Icon {...icon} size={20} />
              </StyledButton>
            ))}
          </ButtonContainer>
        </InputButton>
      </Container>
    )
  }
)

const Container = styled.div`
  align-items: center;
  background-color: ${Colors.WHITE};
  border: 1px solid ${Colors.ALTO};
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  padding: 6px 10px;
  position: relative;
`
// backgroundColor: '#f3f3f3',
const InputButton = styled.div`
  align-items: center;
  // border: 1px solid ${Colors.ALTO};
  // border-radius: ${BORDER_RADIUS};
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex: 1 1 auto;
  // margin-left: 10px;
  padding: 6px 10px;
`
const Placeholder = styled.span`
  color: ${Colors.DUSTY_GRAY};
  // margin-top: 1px;
  font-size: 20px;
`
const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
  margin-left: auto;
`
const StyledButton = styled(Button)`
  border-radius: 50%;
  min-height: 34px;
  min-width: 34px;
  & + & {
    margin-left: 4px;
  }
`

export default CreatePostInput
