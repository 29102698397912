export const UPLOAD_DEFAULTS = Object.freeze({
  acceptAny: '*',
  acceptImage: 'image/*',
  acceptVideo: 'video/*',
  acceptAudio: 'audio/*',
  acceptedImageTypes: Object.freeze(['image/*']),
  acceptedVideoTypes: Object.freeze([
    'video/*',
    '.avi',
    '.m4v',
    '.mkv',
    '.mov',
    '.mp4',
    '.mpeg',
    '.mpg',
    '.mts',
    '.vob',
    '.webm',
  ]),
  acceptedAudioTypes: Object.freeze(['audio/*']),

  campaignAssetTranscodings: ['campaignAsset', 'campaignAssetLoadingScreen'],
  coverImageTranscodings: ['coverImage', 'feed', 'feedLarge'],
  gifTranscodings: ['media'],
  hlsTranscodings: ['HLS', 'THUMB'],
  imageTranscodings: ['media', 'feed', 'feedLarge'],
  mp4Transcodings: ['VIDEO', 'THUMB'],
  profileImageTranscodings: ['profileImage', 'feed', 'feedLarge'],

  calculateClientSideChecksum: true,

  uploaderMaxImageSize: 50 * 1024 * 1024, // 50MB
  uploaderMaxVideoSize: 50 * 1024 * 1024 * 1024, // 50GB
  uploaderMaxAudioSize: 50 * 1024 * 1024, // 50MB
  uploaderMaxNonMediaSize: 500 * 1024 * 1024, // 500MB

  chunkSize: 100 * 1024 * 1024, // 100MB
  minChunkSize: 1 * 1024 * 1024, // 1MB
  maxRetry: 5,

  maxVideosToMerge: 150, // MediaConvert can only merge up to 150 videos
  uploadTypesToShowOnMedia: Object.freeze(['chat', 'post']),
})
