import * as React from 'react'
import type { SVGProps } from 'react'
const NavCalendarFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M24.68 3.61h-1.91V3a1 1 0 0 0-2 0v.63H11.2V3a1 1 0 0 0-2 0v.63H7.32A5.31 5.31 0 0 0 2 8.9v15.81A5.31 5.31 0 0 0 7.32 30h17.36A5.31 5.31 0 0 0 30 24.71V8.9a5.31 5.31 0 0 0-5.32-5.29zM15.23 22.1a2.87 2.87 0 0 1-5.64.78 1 1 0 0 1 .68-1.21 1 1 0 0 1 1.21.67.91.91 0 0 0 .86.68.93.93 0 0 0 .93-.92.73.73 0 0 0-.73-.72 1 1 0 1 1 0-2 .73.73 0 0 0 .73-.72.93.93 0 0 0-.93-.92.91.91 0 0 0-.86.68 1 1 0 0 1-1.89-.55 2.88 2.88 0 0 1 5.64.79 2.66 2.66 0 0 1-.61 1.7 2.66 2.66 0 0 1 .61 1.74zm6.23 2.9h-3.88a1 1 0 1 1 0-1.95h1V19l-.47.43a1 1 0 0 1-1.39-.07 1 1 0 0 1 .03-1.36l2.11-1.9a1 1 0 0 1 1.06-.17 1 1 0 0 1 .59.9V23h.95a1 1 0 1 1 0 1.95zM28 10.8H4V8.9a3.35 3.35 0 0 1 3.32-3.34h1.91v.64a1 1 0 0 0 2 0v-.64h9.6v.64a1 1 0 0 0 2 0v-.64h1.91A3.35 3.35 0 0 1 28 8.9z' />
  </svg>
)
export default NavCalendarFilled
