import * as React from 'react'
import type { SVGProps } from 'react'
const Trash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path
      fillRule='evenodd'
      d='M20 28h2V14h-2v14Zm-4 0h2V14h-2v14Zm-4 0h2V14h-2v14Zm-4 0h2V14H8v14ZM4 10v20c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V10H4Zm22.5-6H20V1.5c0-.824-.676-1.5-1.5-1.5h-7c-.824 0-1.5.676-1.5 1.5V4H3.5C2.674 4 2 4.674 2 5.5V8h26V5.5c0-.826-.676-1.5-1.5-1.5ZM12 4h6V2.026h-6V4Z'
      clipRule='evenodd'
    />
  </svg>
)
export default Trash
