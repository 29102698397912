import { FC } from 'react'
import ReactMarkdown from 'react-markdown'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'
import { usePortal } from '@sportsyou/react-hooks'

import { useDialog } from '../../hooks/use-dialog'

import Backdrop from '../backdrop'
import Button from '../button'
import CloseButton from '../close-button'
import Transition from '../css-transition'

import { AlertProps as Props } from './alert-types'

type NativeAttributes = Omit<React.HTMLAttributes<HTMLDivElement>, keyof Props>
export type AlertProps = Props & NativeAttributes

/**
 * Simple alert dialog using react context
 */
export const Alert: FC<AlertProps> = ({
  actionAppearance = 'solid',
  actionClassName,
  actionStatus = 'primary',
  actionStyle,
  actionText = 'Dismiss',
  backdropIgnoresClicks,
  className,
  clickBackdropToClose = true,
  id,
  isVisible = false,
  message,
  onClickAction,
  onClickBackdrop,
  onClickContent,
  onClose,
  style,
  testIds,
  title,
  useMarkdown,
}: AlertProps) => {
  const portal = usePortal('dialog')

  const { alert, reset } = useDialog()

  // const { alert: alertDialog, resetAlert } = useAlert()

  const handleOnClose = () => {
    onClose?.()
    alert.onClose?.()
    reset?.('alert')
    // resetAlert()
  }

  const handleOnClickAction = () => {
    alert.onClickAction?.()
    onClickAction?.()
    handleOnClose()
  }

  const handleOnClickBackdrop = () => {
    if (alert.backdropIgnoresClicks || backdropIgnoresClicks) return
    // if (alertDialog.disableBackdropClick) return
    alert.onClickBackdrop?.()
    onClickBackdrop?.()
    if (alert.clickBackdropToClose || clickBackdropToClose) {
      handleOnClose()
    }
  }

  return portal
    ? createPortal(
        <Backdrop
          onClick={handleOnClickBackdrop}
          onClickContent={alert.onClickContent ?? onClickContent}
          visible={alert.isVisible || isVisible}
        >
          <Transition
            clearTime={300}
            name='alert'
            visible={alert.isVisible || isVisible}
          >
            <Container
              className={alert.className ?? className}
              role='dialog'
              tabIndex={-1}
              style={style}
              data-testid={testIds?.container}
              // {...props}
            >
              {(alert.title || title) && (
                <Header data-testid={testIds?.header}>
                  {alert.title ?? title}
                  <CloseButton
                    appearance='circular'
                    hideBackground
                    onClick={handleOnClose}
                    size={24}
                  />
                </Header>
              )}
              {(alert.message || message) && ( //alertdialog.text
                <Body>
                  {alert.useMarkdown || useMarkdown ? (
                    <ReactMarkdown
                      children={alert.message ?? message}
                      components={{ p: 'span' }}
                    />
                  ) : (
                    alert.message ?? message
                  )}
                </Body>
              )}

              <Footer data-testid={testIds?.footer}>
                <ActionButton
                  collapse
                  onClick={handleOnClickAction}
                  appearance={alert.actionAppearance ?? actionAppearance}
                  variant={alert.actionStatus ?? actionStatus}
                  className={alert.actionClassName ?? actionClassName}
                  style={alert.actionStyle ?? actionStyle}
                >
                  {alert.actionText ?? actionText}
                </ActionButton>
              </Footer>
              {/* {children} */}
            </Container>
          </Transition>
        </Backdrop>,
        portal
      )
    : null
}
const Container = styled.div`
  background-color: ${Colors.WHITE};
  border-radius: 4px;
  box-shadow: 0 4px 20px rgb(0 0 0 / 14%);
  box-sizing: border-box;
  color: ${Colors.MINE_SHAFT};
  display: flex;
  height: auto;
  max-width: 90vw;
  flex-direction: column;
  font-size: 1rem;
  opacity: 0;
  outline: none;
  overflow: hidden;
  position: relative;
  transform: translate3d(0, -30px, 0);
  transition: opacity 0.35s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    transform 0.35s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  vertical-align: middle;
  width: 420px;
  will-change: opacity, transform;

  &.alert-enter {
    opacity: 0;
    transform: translate3d(0, -30px, 0);
  }
  &.alert-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.alert-leave {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.alert-leave-active {
    opacity: 0;
    transform: translate3d(0, -30px, 0);
  }
`
const Header = styled.div`
  align-items: center;
  border-bottom: 1px solid ${Colors.ALTO};
  display: flex;
  font-size: 14px;
  font-weight: 700;
  justify-content: space-between;
  padding: 10px 15px;
`
const Body = styled.div`
  padding: 10px 15px;
`
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 15px;
`
const ActionButton = styled(Button)`
  padding: 8px 12px;
`

export default Alert
