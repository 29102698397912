import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import styled from 'styled-components'

import {
  Image,
  mutationPostApprove,
  mutationPostDelete,
  mutationPostUpdate,
  Post,
  querySponsoredPosts,
} from '@sportsyou/api'
import {
  Avatar,
  Button,
  Icon,
  Searchbar,
  Spinner,
  Table,
  useDialog,
} from '@sportsyou/react-dom-ui'
import { Colors, pluralize } from '@sportsyou/core'
import { useFetchApi } from '@sportsyou/react-hooks'
import { ContentContainer } from '../../styles/global-styles'
import {
  selectSponsoredPosts,
  selectSponsoredPostsList,
  setSponsoredPostsList,
  updateSponsoredPostsListItem,
} from '../../store/slices/SponsoredPostsSlice'
import getLargeImageUrl from 'web/utils/GetLargeImageUrl'

export const SponsoredPostsIndex = () => {
  const { sendConfirm } = useDialog()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const posts = useSelector(selectSponsoredPostsList)

  const [canCreateSponsoredPosts, setCanCreateSponsoredPosts] = useState(false)
  const [canViewStats, setCanViewStats] = useState(false)
  const [isLoading, setIsLoading] = useState(!posts?.length)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedRows, setSelectedRows] = useState<Post[]>([])
  const [userFeatures] = useState<string[]>(() =>
    JSON.parse(localStorage.getItem('user-features') ?? '[]')
  )

  const { fetch: approvePost } = useFetchApi(mutationPostApprove)
  const { fetch: deletePost } = useFetchApi(mutationPostDelete)
  const { fetch: getSponsoredPosts } = useFetchApi(querySponsoredPosts)
  const { fetch: updatePost } = useFetchApi(mutationPostUpdate)

  useEffect(() => {
    if (userFeatures.includes('SPONSORED_POST_STATS')) {
      setCanViewStats(true)
    }
    if (userFeatures.includes('SPONSORED_POST_CREATE')) {
      setCanCreateSponsoredPosts(true)
    }
  }, [userFeatures])

  const onChangeFilterText = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearchTerm(e.target.value)
    },
    []
  )

  const fetchPosts = useCallback(async () => {
    const { data: _posts } = await getSponsoredPosts({
      filter: searchTerm,
    })
    _posts && dispatch(setSponsoredPostsList(_posts))
    setIsLoading(false)
  }, [dispatch, getSponsoredPosts, searchTerm])

  const onRowClicked = useCallback(
    (post: Post) => {
      navigate(`/sponsored-posts/${post.id}`)
    },
    [navigate]
  )

  const onClickNewPost = useCallback(() => {
    navigate('/sponsored-posts/new')
  }, [navigate])

  const onClickDeletePost = useCallback(
    async (post: Post) => {
      sendConfirm({
        confirmText: 'Deactivate',
        isDestructive: true,
        message: `Are you sure you want to deactivate "${
          post.sponsoredPostInfo?.adName ?? 'Unititled'
        }"?`,

        onConfirm: async () => {
          await deletePost({ postId: post.id })
          fetchPosts()
        },
      })
    },
    [deletePost, fetchPosts, sendConfirm]
  )

  const onClickDeletePosts = useCallback(
    async (posts: Post[]) => {
      sendConfirm({
        confirmText: 'Deactivate',
        isDestructive: true,
        message: `Are you sure you want to deactivate ${
          posts.length
        } ${pluralize(posts.length, 'post', 'posts')}?`,
        onConfirm: async () => {
          await Promise.all(
            posts.map(async (post) => {
              await deletePost({ postId: post.id })
            })
          )
          fetchPosts()
        },
      })
    },
    [deletePost, fetchPosts, sendConfirm]
  )

  const onClickApprovePost = useCallback(
    async (post: Post) => {
      sendConfirm({
        confirmText: 'Approve',
        isDestructive: false,
        message: `Are you sure you want to approve "${
          post.sponsoredPostInfo?.adName ?? 'Unititled'
        }"?`,
        onConfirm: async () => {
          await approvePost({ postId: post.id })
          fetchPosts()
        },
      })
    },
    [approvePost, fetchPosts, sendConfirm]
  )

  const onSelectedRowsChange = useCallback(
    ({
      allSelected,
      selectedCount,
      selectedRows: _selectedRows,
    }: {
      allSelected: boolean
      selectedCount: number
      selectedRows: any[]
    }) => {
      setSelectedRows(_selectedRows)
    },
    []
  )

  function postStatusString(post: Post) {
    if (post.deletedAt) {
      return 'Ended'
    } else if (post.sponsoredPostInfo?.expiredAt) {
      return 'Completed'
    } else if (post.approvedAt) {
      return 'Active'
    } else {
      return 'Pending approval'
    }
  }

  function postStatusIndicatorData(post: Post) {
    const label = postStatusString(post)
    let color = Colors.BLACK
    switch (label) {
      case 'Active':
        color = Colors.MOUNTAIN_MEADOW
        break
      case 'Pending approval':
        color = Colors.GOLD
        break
      case 'Completed':
        color = Colors.HAVELOCK_BLUE
        break
      case 'Ended':
        color = Colors.PUNCH
        break
    }
    return { color, label }
  }

  function isSponsoredPostActive(sponsoredPost: Post) {
    return postStatusString(sponsoredPost) === 'Active'
  }

  const sponsoredPostsDataColumns = useMemo(() => {
    const statusSort = (rowA: Post, rowB: Post) => {
      const a = postStatusString(rowA) || ''
      const b = postStatusString(rowB) || ''
      return a.localeCompare(b)
    }

    const startDateSort = (rowA: Post, rowB: Post) => {
      const a = moment(rowA.sponsoredPostInfo?.startDate)
      const b = moment(rowB.sponsoredPostInfo?.startDate)
      return a.diff(b, 'seconds')
    }

    const endDateSort = (rowA: Post, rowB: Post) => {
      const a = moment(rowA.sponsoredPostInfo?.endDate)
      const b = moment(rowB.sponsoredPostInfo?.endDate)
      return a.diff(b, 'seconds')
    }

    const createdBySort = (rowA: Post, rowB: Post) => {
      const a = rowA.createdBy?.fullName || ''
      const b = rowB.createdBy?.fullName || ''
      return a.localeCompare(b)
    }

    const PreviewImage = ({ row }: { row: Post }) => {
      const uploads = row?.uploads ?? []
      const firstUpload = uploads[0]
      const transcodes = firstUpload?.transcodes ?? []
      const imageUrl = getLargeImageUrl(transcodes as Image[])
      if (imageUrl) {
        return (
          <PreviewImageContainer>
            <PreviewImageImg
              alt={row?.sponsoredPostInfo?.adName ?? ''}
              src={imageUrl}
            />
          </PreviewImageContainer>
        )
      } else {
        return null
      }
    }

    return [
      {
        name: 'Account',
        cell: (row: Post) => {
          const createdByName =
            `${row?.createdBy?.firstName} ${row?.createdBy?.lastName}` ?? ''
          return (
            <PreviewCreatedBy onClick={() => onRowClicked(row)}>
              <Avatar
                diameter={28}
                name={createdByName}
                uri={row?.createdBy?.profileImage?.[0]?.viewUrl ?? ''}
              />
              {createdByName}
            </PreviewCreatedBy>
          )
        },
        reorder: true,
        sortable: true,
        sortFunction: createdBySort,
      },
      {
        name: 'Sponsored Post Title',
        selector: (row: Post) => row?.sponsoredPostInfo?.adName as string,
        format: (row: Post) => {
          return (
            <AdName onClick={() => onRowClicked(row)}>
              <PreviewImage row={row} />
              {row.sponsoredPostInfo?.adName || 'Untitled'}
            </AdName>
          )
        },
        sortable: true,
        reorder: true,
        wrap: true,
      },

      {
        name: 'Start Date',
        selector: (row: Post) => row?.sponsoredPostInfo?.startDate ?? '',
        sortable: true,
        sortFunction: startDateSort,
        reorder: true,
        cell: (post: Post) => (
          <SponsoredPostListDate
            active={isSponsoredPostActive(post)}
            date={post.sponsoredPostInfo?.startDate as string}
            onClick={() => onRowClicked(post)}
          >
            {moment(post?.sponsoredPostInfo?.startDate).format(
              'MM/DD/YYYY hh:mm A'
            )}
          </SponsoredPostListDate>
        ),
      },
      {
        name: 'End Date',
        selector: (row: Post) => row?.sponsoredPostInfo?.endDate ?? '',
        sortable: true,
        sortFunction: endDateSort,
        reorder: true,
        cell: (post: Post) => (
          <SponsoredPostListDate
            active={isSponsoredPostActive(post)}
            date={post.sponsoredPostInfo?.endDate as string}
            onClick={() => onRowClicked(post)}
          >
            {post?.sponsoredPostInfo?.endDate
              ? moment(post?.sponsoredPostInfo?.endDate).format(
                  'MM/DD/YYYY hh:mm A'
                )
              : 'N/A'}
          </SponsoredPostListDate>
        ),
      },
      {
        name: 'Status',
        selector: (row: Post) => !!row.isPending,
        sortable: true,
        sortFunction: statusSort,
        reorder: true,
        maxWidth: '150px',
        cell: (post: Post) => {
          const status = postStatusIndicatorData(post)
          return (
            <>
              <StatusCircle color={status.color} />
              {status.label}
            </>
          )
        },
      },
      {
        name: 'Actions',
        cell: (row: Post) => {
          return (
            <ActionButtons>
              <ActionButton
                appearance='ghost'
                onClick={() => navigate(`/sponsored-posts/${row.id}`)}
                useWhiteBase
                variant='info'
              >
                Metrics
              </ActionButton>
              {!row.approvedAt &&
                !!row.isPending &&
                !row.deletedAt &&
                !row.sponsoredPostInfo?.expiredAt && (
                  <ActionButton
                    appearance='ghost'
                    onClick={() => onClickApprovePost(row)}
                    useWhiteBase
                    variant='primary'
                  >
                    Approve
                  </ActionButton>
                )}
              {!row.deletedAt && !!row.canDelete && (
                <ActionButton
                  appearance='ghost'
                  onClick={() => onClickDeletePost(row)}
                  useWhiteBase
                  variant='info'
                >
                  Deactivate
                </ActionButton>
              )}

              {/* {canViewStats && (
                <RowLink to={`/sponsored-posts/${row.id}/metrics`}>
                  <RowLinkButton appearance="ghost" variant="primary">
                    Metrics
                  </RowLinkButton>
                </RowLink>
              )}
              {!row.deletedAt && (
                <>
                  {row.canEdit && (
                    <RowLink to={`/sponsored-posts/${row.id}/edit`}>
                      <RowLinkButton appearance="ghost" variant="alternate">
                        Edit
                      </RowLinkButton>
                    </RowLink>
                  )}
                  {!row.canEdit && (
                    <RowLink to={`/sponsored-posts/${row.id}/edit`}>
                      <RowLinkButton appearance="ghost" variant="alternate">
                        Details
                      </RowLinkButton>
                    </RowLink>
                  )}
                  {!row.deletedAt && row.canDelete && (
                    <RowLink to={'#'} onClick={() => onClickDeletePost(row)}>
                      <RowLinkButton appearance="ghost" variant="danger">
                        Deactivate
                      </RowLinkButton>
                    </RowLink>
                  )}
                  {!row.approvedAt && row.isPending && (
                    <>
                      <RowLink to={'#'} onClick={() => onClickApprovePost(row)}>
                        <RowLinkButton appearance="ghost" variant="success">
                          Approve
                        </RowLinkButton>
                      </RowLink>
                    </>
                  )}
                </>
              )}
              {!!row.deletedAt && !row.canEdit && (
                <RowLink to={`/sponsored-posts/${row.id}/edit`}>
                  <RowLinkButton appearance="ghost" variant="alternate">
                    Details
                  </RowLinkButton>
                </RowLink>
              )} */}
            </ActionButtons>
          )
        },
        reorder: true,
      },
    ]
  }, [fetchPosts, navigate, onClickApprovePost, onClickDeletePost, updatePost])

  useEffect(() => {
    fetchPosts()
  }, [fetchPosts])

  return (
    <ContentContainer>
      <Helmet>
        <title>sportsYou - Sponsored Posts</title>
      </Helmet>
      <TableTools>
        <TableToolsLeft>
          <Searchbar
            onChange={onChangeFilterText}
            placeholder='Search'
            containerStyle={{ minHeight: 0 }}
          />
          <PostCount>
            {posts?.length} {pluralize(posts?.length, 'post', 'posts')}
          </PostCount>
        </TableToolsLeft>
        <TableToolsRight>
          {canCreateSponsoredPosts && (
            <Button
              className='createSponsoredPostButton'
              onClick={onClickNewPost}
            >
              <ButtonIcon name='Plus' size={10} fill={Colors.WHITE} />
              Create a Sponsored Post
            </Button>
          )}
        </TableToolsRight>
      </TableTools>
      {!!selectedRows?.length && (
        <TableTools active>
          <TableToolsLeft>{selectedRows?.length} selected</TableToolsLeft>
          <TableToolsRight>
            <Button
              onClick={() => onClickDeletePosts(selectedRows)}
              variant='danger'
            >
              Deactivate {selectedRows?.length}{' '}
              {pluralize(selectedRows?.length, 'post', 'posts')}
            </Button>
          </TableToolsRight>
        </TableTools>
      )}
      {!isLoading && (
        <Table
          columns={sponsoredPostsDataColumns}
          customStyles={customTableStyles}
          data={posts}
          defaultSortAsc={false}
          defaultSortFieldId={3}
          highlightOnHover
          noDataComponent='No sponsored posts found'
          onRowClicked={onRowClicked}
          onSelectedRowsChange={onSelectedRowsChange}
          pointerOnHover={canViewStats}
          progressPending={isLoading}
          selectableRows
          striped
        />
      )}
      {!!isLoading && <Spinner />}
    </ContentContainer>
  )
}

const customTableStyles = {
  headCells: {
    style: {
      fontSize: '14px',
      fontWeight: 'bold',
    },
  },
  cells: {
    style: {},
  },
}

export const Header = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  z-index: 10;
  background-color: #fff;
`

export const HeaderTitle = styled.div`
  align-items: center;
  color: ${Colors.BLACK};
  display: flex;
  flex-direction: row;
  font-weight: bold;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  gap: 10px;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const TableTools = styled.div<{ active?: boolean }>`
  align-items: center;
  border-radius: 8px;
  border: ${(props) => (props.active ? `1px solid ${Colors.SHUTTLE_GRAY}` : 0)};
  padding: ${(props) => (props.active ? '0.5rem 1rem' : 0)};
  color: ${Colors.SHUTTLE_GRAY};
  display: flex;
  flex-direction: row;
  font-weight: bold;
  justify-content: space-between;
  margin-bottom: 1rem;

  small {
    margin-left: 0.5rem;
  }
`

export const TableToolsLeft = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 10px;
`

export const TableToolsRight = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button {
    margin-left: 0.5rem;
  }
`

const PostCount = styled.span`
  margin-left: 1rem;
`

const PreviewCreatedBy = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

const AdName = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 1.3em;
  font-weight: 900;
  margin-bottom: 0.5em;
  margin-top: 10px;

  a {
    color: ${Colors.BLACK};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

const PreviewImageContainer = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #efefef;
  display: flex;
  flex-direction: column;
  height: 60px;
  justify-content: center;
  margin-right: 10px;
  overflow: hidden;
  width: 60px;
`

const PreviewImageImg = styled.img`
  max-height: 100%;
  object-fit: cover;
`

const SetCustomNameButton = styled.div`
  cursor: pointer;
  padding-left: 4px;

  & svg {
    margin-left: 8px;
    margin-top: -2px;
  }
`

const StatusCircle = styled.span<{ color: string }>`
  background-color: ${({ color }) => color};
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin-right: 5px;
  width: 10px;
`

const ActionButtons = styled.div`
  display: flex;
  gap: 5px;
`

const ActionButton = styled(Button)`
  border-width: 1px;
  min-height: 0;
  min-width: 0;
  padding: 4px 5px;
`

const ButtonIcon = styled(Icon)`
  margin-right: 5px;
`

const SponsoredPostListDate = styled.span<{ active: boolean; date: string }>`
  padding-bottom: 3px;

  border-bottom: ${({ active }) => {
    return active ? `1px solid ${Colors.MOUNTAIN_MEADOW}` : '0'
  }};

  color: ${({ date }) => {
    return moment(date).isBefore(moment()) ? Colors.DUSTY_GRAY : Colors.BLACK
  }};
`

export default SponsoredPostsIndex
