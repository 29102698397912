import * as React from 'react'
import type { SVGProps } from 'react'
const PlaylistNextClip = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 20 20'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M4.63 5.64l5 3.72a.78.78 0 0 1 .17 1.11.55.55 0 0 1-.17.17l-5 3.72a.79.79 0 0 1-1.11-.18.72.72 0 0 1-.16-.47V6.29a.8.8 0 0 1 .8-.8.78.78 0 0 1 .47.15z' />
    <path d='M11.27 5.64l5 3.72a.79.79 0 0 1 .18 1.11.73.73 0 0 1-.18.17l-5 3.72a.8.8 0 0 1-1.12-.18.78.78 0 0 1-.15-.47V6.29a.8.8 0 0 1 1.27-.65z' />
  </svg>
)
export default PlaylistNextClip
