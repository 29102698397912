import * as React from 'react'
import type { SVGProps } from 'react'
const SyVideo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 119.238 13.928'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <g transform='translate(0 0)'>
      <path
        d='M107.394,540.426a3.648,3.648,0,0,0-.923-.523,12.041,12.041,0,0,0-1.17-.393q-.17-.043-.372-.077a2.919,2.919,0,0,1-.383-.09,1.028,1.028,0,0,1-.305-.147.337.337,0,0,1-.139-.255.323.323,0,0,1,.19-.328,1.2,1.2,0,0,1,.552-.109,2.323,2.323,0,0,1,.6.083,1.258,1.258,0,0,1,.568.329.578.578,0,0,0,.128.107.5.5,0,0,0,.149.062.384.384,0,0,0,.163,0,.414.414,0,0,0,.17-.079q.268-.241.519-.436t.526-.4c.109-.084.161-.163.159-.237a.377.377,0,0,0-.125-.236,5.331,5.331,0,0,0-.671-.53,3.789,3.789,0,0,0-.692-.363,3.64,3.64,0,0,0-.744-.205,5.406,5.406,0,0,0-2.175.108,3.315,3.315,0,0,0-1.033.476,2.173,2.173,0,0,0-.663.736,1.956,1.956,0,0,0-.23.952,2.8,2.8,0,0,0,.13.85,1.994,1.994,0,0,0,.453.751,3.1,3.1,0,0,0,.877.63,6.276,6.276,0,0,0,1.4.48q.269.075.486.131a2.035,2.035,0,0,1,.365.128.613.613,0,0,1,.227.175.456.456,0,0,1,.079.28.36.36,0,0,1-.137.3.867.867,0,0,1-.329.155,2.046,2.046,0,0,1-.4.058q-.209.01-.344.011a1.291,1.291,0,0,1-.213-.031,2.792,2.792,0,0,1-.362-.1,3.317,3.317,0,0,1-.429-.188,2.055,2.055,0,0,1-.416-.285,1.017,1.017,0,0,0-.337-.213.35.35,0,0,0-.3.089l-.911.816a.35.35,0,0,0-.124.136.421.421,0,0,0-.042.173.468.468,0,0,0,.036.192.631.631,0,0,0,.118.185,3.027,3.027,0,0,0,.682.587,4.158,4.158,0,0,0,.809.394,4.625,4.625,0,0,0,.879.221,5.932,5.932,0,0,0,.893.066,5.66,5.66,0,0,0,1.441-.181,3.717,3.717,0,0,0,1.157-.514,2.48,2.48,0,0,0,.766-.828,2.211,2.211,0,0,0,.276-1.116,2.361,2.361,0,0,0-.22-1.058A2.21,2.21,0,0,0,107.394,540.426Z'
        transform='translate(-101.165 -533.216)'
      />
      <path
        d='M138.2,537.746a4.3,4.3,0,0,0-1.413-.871,4.895,4.895,0,0,0-1.79-.312,3.018,3.018,0,0,0-.935.129,2.556,2.556,0,0,0-.658.32,2.518,2.518,0,0,0-.477.43q-.2.237-.4.464l-.3-.761c-.024-.067-.049-.129-.075-.186a.627.627,0,0,0-.1-.151.388.388,0,0,0-.145-.1.573.573,0,0,0-.224-.037l-1.07,0a1.008,1.008,0,0,0-.191.02.456.456,0,0,0-.191.089.739.739,0,0,0-.131.151.376.376,0,0,0-.052.208l.033,9.569a.339.339,0,0,0,.057.192.509.509,0,0,0,.142.141.73.73,0,0,0,.185.088.609.609,0,0,0,.184.031l1.623-.006a.529.529,0,0,0,.4-.134.446.446,0,0,0,.126-.322l-.01-2.936q.192.189.391.377a2.667,2.667,0,0,0,.458.345,2.457,2.457,0,0,0,.592.253,2.837,2.837,0,0,0,.794.095,4.852,4.852,0,0,0,1.788-.327,4.346,4.346,0,0,0,1.407-.884,4.04,4.04,0,0,0-.021-5.877Zm-1.479,3.725a1.879,1.879,0,0,1-.416.635,2,2,0,0,1-.643.427,2.126,2.126,0,0,1-.829.161,2.415,2.415,0,0,1-.872-.154,2.339,2.339,0,0,1-.717-.423,1.99,1.99,0,0,1-.484-.631,1.764,1.764,0,0,1-.18-.781v-.094a1.724,1.724,0,0,1,.206-.738,2.08,2.08,0,0,1,.487-.6,2.313,2.313,0,0,1,.7-.406,2.422,2.422,0,0,1,.846-.151,2.125,2.125,0,0,1,.83.155,1.978,1.978,0,0,1,.646.426,1.926,1.926,0,0,1,.42.632,2,2,0,0,1,.152.765A2.052,2.052,0,0,1,136.721,541.47Z'
        transform='translate(-122.347 -533.233)'
      />
      <path
        d='M175.434,537.619a4.659,4.659,0,0,0-1.477-.87,5.1,5.1,0,0,0-1.811-.312,5.3,5.3,0,0,0-1.25.149,5.047,5.047,0,0,0-1.114.413,4.471,4.471,0,0,0-.937.643,4.224,4.224,0,0,0-.72.834,3.91,3.91,0,0,0-.464.994,3.739,3.739,0,0,0-.162,1.116,3.7,3.7,0,0,0,.17,1.111,3.962,3.962,0,0,0,.471.99,4.172,4.172,0,0,0,.726.832,4.544,4.544,0,0,0,.941.639,4.938,4.938,0,0,0,1.117.409,5.3,5.3,0,0,0,1.251.141,5.171,5.171,0,0,0,1.236-.149,4.967,4.967,0,0,0,1.107-.416,4.573,4.573,0,0,0,.937-.646,4.167,4.167,0,0,0,.72-.837,3.961,3.961,0,0,0,.464-.994,3.807,3.807,0,0,0-.208-2.74A4.175,4.175,0,0,0,175.434,537.619ZM174,541.341a1.808,1.808,0,0,1-.395.628,1.876,1.876,0,0,1-.629.421,2.156,2.156,0,0,1-.832.157,2.245,2.245,0,0,1-.854-.151,1.937,1.937,0,0,1-.646-.417,1.841,1.841,0,0,1-.413-.625,2.133,2.133,0,0,1-.005-1.547,1.97,1.97,0,0,1,.409-.644,1.909,1.909,0,0,1,.643-.44,2.165,2.165,0,0,1,.853-.164,2.076,2.076,0,0,1,.833.158,1.857,1.857,0,0,1,.632.436,1.94,1.94,0,0,1,.4.641,2.236,2.236,0,0,1,.005,1.547Z'
        transform='translate(-149.804 -533.141)'
      />
      <path
        d='M211.214,537.1q-.313-.2-.579-.351a3.5,3.5,0,0,0-.536-.247,3.244,3.244,0,0,0-.571-.146,4.13,4.13,0,0,0-.684-.048,1.492,1.492,0,0,0-.637.128,2.024,2.024,0,0,0-.488.323,4.2,4.2,0,0,0-.413.427q-.194.231-.406.439l-.061-.135c-.031-.069-.063-.142-.1-.22s-.065-.149-.093-.214-.048-.106-.057-.123a1.259,1.259,0,0,1-.1-.176.784.784,0,0,0-.093-.157.389.389,0,0,0-.344-.156l-1.07,0a.75.75,0,0,0-.382.109.459.459,0,0,0-.131.155.425.425,0,0,0-.052.2l.025,7.049q0,.485.554.483l1.594-.005a.628.628,0,0,0,.435-.125.472.472,0,0,0,.13-.363l-.016-4.561q.148-.215.3-.392a1.916,1.916,0,0,1,.318-.3,1.343,1.343,0,0,1,.379-.2,1.541,1.541,0,0,1,.485-.071,1.88,1.88,0,0,1,.719.127,2.264,2.264,0,0,1,.622.391.742.742,0,0,0,.447.055.579.579,0,0,0,.346-.234l.642-.979A.415.415,0,0,0,211.214,537.1Z'
        transform='translate(-176.881 -533.049)'
      />
      <path
        d='M238.384,530.927a.554.554,0,0,0-.369-.118l-1.183,0v-1.984a.64.64,0,0,0-.09-.356.39.39,0,0,0-.351-.134h-.06a.406.406,0,0,0-.067.007l-1.607.5a1.724,1.724,0,0,0-.414.187.329.329,0,0,0-.144.293l0,1.493-.517,0q-.546,0-.544.481l0,1.216q0,.51.547.508l.517,0,.025,5.065q0,.485.547.483l1.6-.006a.681.681,0,0,0,.432-.124.433.433,0,0,0,.155-.363l-.025-5.064,1.183,0a.541.541,0,0,0,.368-.124.486.486,0,0,0,.14-.388l0-1.216A.444.444,0,0,0,238.384,530.927Z'
        transform='translate(-197.86 -527.203)'
      />
      <path
        d='M260.6,539.894a3.648,3.648,0,0,0-.923-.523,11.954,11.954,0,0,0-1.171-.393q-.17-.044-.372-.078a2.913,2.913,0,0,1-.383-.09,1.03,1.03,0,0,1-.305-.147.338.338,0,0,1-.139-.255.322.322,0,0,1,.19-.328,1.2,1.2,0,0,1,.552-.109,2.325,2.325,0,0,1,.6.083,1.257,1.257,0,0,1,.568.329.6.6,0,0,0,.128.107.516.516,0,0,0,.149.062.389.389,0,0,0,.163,0,.413.413,0,0,0,.17-.079q.268-.24.519-.436t.527-.4q.163-.126.158-.237a.377.377,0,0,0-.125-.235,5.308,5.308,0,0,0-.671-.53,3.8,3.8,0,0,0-.692-.363,3.623,3.623,0,0,0-.745-.205,5.392,5.392,0,0,0-2.175.108,3.3,3.3,0,0,0-1.033.476,2.168,2.168,0,0,0-.663.736,1.95,1.95,0,0,0-.23.952,2.8,2.8,0,0,0,.13.85,1.994,1.994,0,0,0,.452.751,3.1,3.1,0,0,0,.877.63,6.278,6.278,0,0,0,1.4.48q.27.075.486.131a2.027,2.027,0,0,1,.365.128.615.615,0,0,1,.228.176.459.459,0,0,1,.079.28.361.361,0,0,1-.137.3.872.872,0,0,1-.329.156,2.07,2.07,0,0,1-.4.058c-.139.007-.254.01-.344.01a1.258,1.258,0,0,1-.213-.031,2.739,2.739,0,0,1-.362-.1,3.28,3.28,0,0,1-.429-.187,2.039,2.039,0,0,1-.415-.285,1.016,1.016,0,0,0-.337-.213.349.349,0,0,0-.3.089l-.911.816a.354.354,0,0,0-.124.136.419.419,0,0,0-.042.173.465.465,0,0,0,.036.192.633.633,0,0,0,.118.185,3.024,3.024,0,0,0,.682.587,4.146,4.146,0,0,0,.809.394,4.617,4.617,0,0,0,.879.221,5.887,5.887,0,0,0,.893.066,5.655,5.655,0,0,0,1.441-.181,3.71,3.71,0,0,0,1.157-.514,2.472,2.472,0,0,0,.766-.828,2.213,2.213,0,0,0,.276-1.116,2.362,2.362,0,0,0-.22-1.058A2.208,2.208,0,0,0,260.6,539.894Z'
        transform='translate(-213.504 -532.826)'
      />
      <path
        d='M286.913,531.639a.237.237,0,0,0,.1-.158.087.087,0,0,0-.032-.066.12.12,0,0,0-.081-.028l-2.728.01a.431.431,0,0,1-.074.016.154.154,0,0,0-.06.019.205.205,0,0,0-.057.051.819.819,0,0,0-.07.111l-1.7,3.262-1.737-3.251q-.05-.076-.078-.11a.208.208,0,0,0-.057-.05.154.154,0,0,0-.057-.019.559.559,0,0,1-.078-.016l-2.728.01a.106.106,0,0,0-.078.029.09.09,0,0,0-.028.066.222.222,0,0,0,.093.157l3.37,5.261.012,3.534a.272.272,0,0,0,.3.295l2.12-.008q.325,0,.324-.3l-.012-3.521Z'
        transform='translate(-230.366 -529.438)'
      />
      <path
        d='M315.308,537.133a4.659,4.659,0,0,0-1.477-.871,5.11,5.11,0,0,0-1.811-.312,5.31,5.31,0,0,0-1.25.149,5.065,5.065,0,0,0-1.115.413,4.469,4.469,0,0,0-.937.643,4.219,4.219,0,0,0-.72.834,3.9,3.9,0,0,0-.464.994,3.738,3.738,0,0,0-.162,1.116,3.7,3.7,0,0,0,.17,1.111,3.96,3.96,0,0,0,.471.991,4.184,4.184,0,0,0,.726.832,4.559,4.559,0,0,0,.941.639,4.963,4.963,0,0,0,1.117.409,5.3,5.3,0,0,0,1.251.141,5.167,5.167,0,0,0,1.236-.149,4.973,4.973,0,0,0,1.108-.416,4.55,4.55,0,0,0,.937-.646,4.175,4.175,0,0,0,.72-.837,3.973,3.973,0,0,0,.464-.994,3.807,3.807,0,0,0-.208-2.74A4.174,4.174,0,0,0,315.308,537.133Zm-1.432,3.721a1.8,1.8,0,0,1-.395.628,1.873,1.873,0,0,1-.629.421,2.153,2.153,0,0,1-.832.157,2.248,2.248,0,0,1-.854-.151,1.943,1.943,0,0,1-.646-.417,1.843,1.843,0,0,1-.413-.626,2.133,2.133,0,0,1-.005-1.546,1.978,1.978,0,0,1,.409-.644,1.92,1.92,0,0,1,.643-.44,2.165,2.165,0,0,1,.853-.163,2.079,2.079,0,0,1,.833.158,1.857,1.857,0,0,1,.632.436,1.939,1.939,0,0,1,.4.641,2.236,2.236,0,0,1,.005,1.546Z'
        transform='translate(-252.366 -532.784)'
      />
      <path
        d='M352.7,536.383a.611.611,0,0,0-.44-.131l-1.6.006a.824.824,0,0,0-.187.02.675.675,0,0,0-.173.07.346.346,0,0,0-.138.164.536.536,0,0,0-.045.214l.014,4.1a.966.966,0,0,1-.147.514,1.583,1.583,0,0,1-.377.424,2.008,2.008,0,0,1-.5.289,1.44,1.44,0,0,1-.528.109,1.656,1.656,0,0,1-.585-.105,1.786,1.786,0,0,1-.515-.292,1.529,1.529,0,0,1-.366-.428,1.023,1.023,0,0,1-.14-.514l-.014-4.105a.443.443,0,0,0-.114-.356.7.7,0,0,0-.419-.09l-1.637.006a.5.5,0,0,0-.372.124.506.506,0,0,0-.116.362l.014,4.126a3.653,3.653,0,0,0,.26,1.363,3.4,3.4,0,0,0,.723,1.116,3.357,3.357,0,0,0,1.126.752,3.748,3.748,0,0,0,1.468.272,3.069,3.069,0,0,0,.992-.151,2.988,2.988,0,0,0,.743-.367,2.49,2.49,0,0,0,.512-.466,3.048,3.048,0,0,0,.307-.444l.222.8a1.224,1.224,0,0,0,.071.182.644.644,0,0,0,.107.157.465.465,0,0,0,.156.109.536.536,0,0,0,.22.04l1.07,0a1.061,1.061,0,0,0,.195-.02.457.457,0,0,0,.195-.089l-.042.019a.424.424,0,0,0,.225-.379l-.025-7.049A.472.472,0,0,0,352.7,536.383Z'
        transform='translate(-279.444 -533.005)'
      />
      <path
        d='M381.34,534.761v-.011a.236.236,0,0,0,.19-.214.254.254,0,0,0-.09-.2.571.571,0,0,0-.319-.066,1.862,1.862,0,0,0-.353.029l0,.919h.213v-.37h.1c.118,0,.174.044.191.145a.781.781,0,0,0,.074.224h.23a.689.689,0,0,1-.068-.229A.271.271,0,0,0,381.34,534.761Zm-.252-.066h-.1v-.263a.5.5,0,0,1,.118-.012c.134,0,.2.055.2.139S381.205,534.694,381.088,534.695Z'
        transform='translate(-306.183 -531.551)'
      />
      <path
        d='M379.512,532.568a.924.924,0,1,0,.944.915A.929.929,0,0,0,379.512,532.568Zm.011,1.652a.734.734,0,1,1,.7-.731A.7.7,0,0,1,379.523,534.22Z'
        transform='translate(-304.572 -530.304)'
      />
      <path
        d='M407.806,530.949h-2.047a.372.372,0,0,0-.376.284l-1.928,5.677-1.948-5.677a.363.363,0,0,0-.376-.284l-2.02-.013c-.138,0-.211.046-.211.145a.509.509,0,0,0,.033.139l3.4,8.866a.391.391,0,0,0,.383.277h1.492a.391.391,0,0,0,.383-.277l3.393-8.853a.512.512,0,0,0,.033-.139C408.018,531,407.945,530.949,407.806,530.949Z'
        transform='translate(-319.48 -529.107)'
      />
      <path
        d='M437.7,535.96H435.82a.27.27,0,0,0-.277.277v7.533a.245.245,0,0,0,.277.277H437.7a.245.245,0,0,0,.277-.277v-7.533A.267.267,0,0,0,437.7,535.96Z'
        transform='translate(-346.348 -532.791)'
      />
      <path
        d='M437.493,524.08H436.04a.464.464,0,0,0-.521.528v1.208c0,.343.178.456.521.456h1.452c.324,0,.475-.112.475-.462v-1.2C437.968,524.258,437.81,524.08,437.493,524.08Z'
        transform='translate(-346.331 -524.08)'
      />
      <path
        d='M456.517,527.124h-1.855a.249.249,0,0,0-.284.284v2.773a2.832,2.832,0,0,0-1.994-.779,4.155,4.155,0,0,0,0,8.3,2.767,2.767,0,0,0,2.06-.937l.079.2c.139.363.238.6.469.6h1.525a.249.249,0,0,0,.284-.284v-9.877A.249.249,0,0,0,456.517,527.124Zm-3.968,8.418a1.877,1.877,0,0,1-1.829-2,1.908,1.908,0,0,1,1.829-2.014,2.007,2.007,0,1,1,0,4.014Z'
        transform='translate(-355.811 -526.312)'
      />
      <path
        d='M489.96,536.121a3.793,3.793,0,0,0-1.7-.436,4.02,4.02,0,0,0-4.08,4.146,3.94,3.94,0,0,0,.574,2.073,4.311,4.311,0,0,0,1.531,1.512,3.962,3.962,0,0,0,2.04.561,4.849,4.849,0,0,0,1.564-.244,4.376,4.376,0,0,0,1.472-.9.26.26,0,0,0,.112-.2.385.385,0,0,0-.1-.231l-.812-1.122a.231.231,0,0,0-.185-.093.252.252,0,0,0-.165.06c-.033.026-.086.073-.158.132a3.269,3.269,0,0,1-.416.3,2.557,2.557,0,0,1-1.327.29,1.847,1.847,0,0,1-1.809-1.4h4.569c.733-.013.924-.106.944-.8a4.151,4.151,0,0,0-.594-2.291A3.836,3.836,0,0,0,489.96,536.121Zm-3.334,2.647a1.753,1.753,0,0,1,1.558-1.169,1.78,1.78,0,0,1,1.644,1.169Z'
        transform='translate(-382.009 -532.589)'
      />
      <path
        d='M524.65,537.729a4.219,4.219,0,0,0-1.518-1.512,4.239,4.239,0,0,0-4.192,0,4.219,4.219,0,0,0-1.518,1.512,4.134,4.134,0,0,0,0,4.159,4.123,4.123,0,0,0,1.518,1.512,4.185,4.185,0,0,0,4.186,0,4.14,4.14,0,0,0,1.525-1.512,4.134,4.134,0,0,0,0-4.159Zm-3.618,4.093a2.018,2.018,0,1,1,1.842-2.013A1.906,1.906,0,0,1,521.032,541.823Z'
        transform='translate(-405.974 -532.573)'
      />
    </g>
  </svg>
)
export default SyVideo
