import * as React from 'react'
import type { SVGProps } from 'react'
const MediaES = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    style={props.style}
    {...props}
  >
    <g clipPath='url(#a)'>
      <path
        fill='#D74B20'
        d='M2.17217 24.8643V3.03358c0-.4667.37618-.84129.84338-.84743l21.82475-.00614c.4005 0 .7524.28862.8313.68163l.0303.14738h2.1661l-.0121-.20265c-.0546-.72462-.3641-1.40011-.8798-1.915945C26.4058.319325 25.6473.00614086 24.8464 0H3.01555C2.20857.00614086 1.4562.319325.885855.890425.31551 1.46152 0 2.21685 0 3.0213v21.8308c.0060675 1.5904 1.24991 2.9169 2.83352 3.0151l.20023.0123v-2.1677l-.15169-.0246c-.41259-.0614-.70989-.4114-.70989-.8229Z'
      />
      <path
        fill='#D74B20'
        d='M31.1029 5.01713c-.5704-.5711-1.3288-.88428-2.1297-.89042H7.14836c-.80698.00614-1.55935.31932-2.12969.89042-.57035.5711-.88586 1.32643-.88586 2.13088V28.9788c.00607 1.658 1.35912 3.0151 3.02162 3.0213H28.9792c1.6625 0 3.0216-1.3572 3.0216-3.0213V7.14801c-.0121-.80445-.3276-1.55978-.8979-2.13088Z'
      />
      <path
        fill='#2B2B2B'
        d='M22.0384 15.8251c1.8129 0 3.2825-1.4709 3.2825-3.2853 0-1.8145-1.4696-3.28541-3.2825-3.28541-1.8129 0-3.2825 1.47091-3.2825 3.28541 0 1.8144 1.4696 3.2853 3.2825 3.2853ZM23.2328 20.3138c-.1153-.1351-.2791-.2211-.4611-.2334-.176-.0122-.3519.0553-.4854.1781l-1.8567 1.7686-5.1027-6.4786c-.1335-.172-.3459-.2641-.5643-.2456-.2184.0184-.4126.1473-.5157.3438l-5.16956 9.5921c-.16989.3193-.05461.7184.2609.8965.09708.0553.2063.0799.30945.0799.23056 0 .45501-.1229.57031-.3439l4.7023-8.7262 4.9329 6.2637c.1153.1412.2791.2333.4612.2456.182.0123.3579-.0491.4914-.1781l1.8809-1.7808 3.5738 4.2863c.2306.2764.6432.3132.9162.0798.273-.2333.3094-.6509.0789-.9272l-4.0228-4.8206Z'
      />
    </g>
    <defs>
      <clipPath>
        <path fill='#fff' d='M0 0h32v32H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default MediaES
