import React from 'react'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'

interface Props {
  active?: boolean
  activeTabColor?: string
  className?: string
  disabled?: boolean
  title?: string
  // initialValue?: string
  value?: number
  onClick?: () => void
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type TabsItemProps = Props & NativeAttributes

const TabsItem: React.FC<React.PropsWithChildren<TabsItemProps>> = ({
  active,
  activeTabColor,
  children,
  className,
  disabled,
  style,
  title,
  value,
  onClick,
  ...props
}: TabsItemProps) => {
  const handleClickEvent = () => {
    onClick && onClick()
  }

  return (
    <div role='presentation' className={className} {...props}>
      <Button
        active={active}
        aria-selected={active}
        aria-disabled={disabled}
        disabled={disabled}
        onClick={handleClickEvent}
        role='tab'
        style={style}
        title={title}
        type='button'
      >
        {title}
      </Button>
    </div>
  )
}

const Button = styled.button<TabsItemProps>`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  min-height: 46px;
  min-width: 60px;
  padding: 10px;
  transition: color, background-color 300ms ease;

  &:hover:not(:disabled) {
    background-color: rgb(0 0 0 / 5%);
  }
  // font-weight: ${({ active }) => (active ? 500 : 500)};
  font-family: inherit;
  font-weight: 500;

  &:disabled {
    cursor: not-allowed;
  }

  color: ${({ active, activeTabColor, disabled }) =>
    active && activeTabColor
      ? activeTabColor
      : active
      ? Colors.HAVELOCK_BLUE
      : Colors.MINE_SHAFT};

  &:disabled {
    color: ${Colors.DUSTY_GRAY};
  }
`

export default TabsItem
