import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { Colors } from '@sportsyou/core'

export interface BreadcrumbsItemProps {
  className?: string
  hideDecorator?: boolean
  href?: string
  icon?: React.ReactElement
  id?: string
  isLastItem?: boolean
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  style?: React.CSSProperties
  target?: '_self' | '_blank' | '_parent' | '_top'
  text?: string | React.ReactNode
  title?: string
}

export const BreadcrumbsItem: FC<BreadcrumbsItemProps> = ({
  className,
  hideDecorator = false,
  href,
  icon,
  id,
  isLastItem,
  onClick,
  style,
  target,
  text,
  title,
}: BreadcrumbsItemProps) => {
  const handleOnClickItem = (event: React.MouseEvent<HTMLElement>) => {
    onClick && onClick(event)
  }
  const renderAsSpan = () => (
    <Span
      aria-label={title}
      className={className}
      id={id}
      style={style}
      title={title}
    >
      {text}
    </Span>
  )

  const renderAsButton = () => {
    return (
      <Button
        aria-label={title}
        className={className}
        hideDecorator={hideDecorator}
        id={id}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
          handleOnClickItem(event)
        }
        style={style}
        title={title}
        type='button'
      >
        {icon && icon}
        {text && text}
      </Button>
    )
  }

  const renderAsLink = () => (
    <Link
      aria-label={title}
      className={className}
      id={id}
      hideDecorator={hideDecorator}
      href={href}
      onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
        handleOnClickItem(event)
      }
      style={style}
      target={target ?? '_self'}
      title={title}
    >
      {text}
    </Link>
  )

  const renderItem = () => {
    if (!isLastItem) {
      if (href) {
        return renderAsLink()
      } else if (onClick) {
        return renderAsButton()
      }
    }
    return renderAsSpan()
  }

  return renderItem()
}

const ClickableStyles = css`
  border-radius: 6px;
  color: ${Colors.HAVELOCK_BLUE};
  cursor: pointer;
  text-decoration: underline;
  transition: background-color 120ms ease-in-out;

  &:hover,
  &:active {
    background-color: ${Colors.CATSKILL_WHITE};
  }
`
const SharedStyles = css`
  align-items: center;
  align-self: stretch;
  box-sizing: border-box;
  display: inline-flex;
  font-family: inherit;
  font-size: 16px;
  font-weight: 700;
  // line-height: 1;
  justify-content: center;
  padding: 2px 6px;
  min-height: 24px;
`

const Button = styled.button<{ hideDecorator: boolean }>`
  ${ClickableStyles};
  ${SharedStyles};
  background: none;
  border: none;
  ${({ hideDecorator }) =>
    hideDecorator ? `text-decoration: none;` : undefined};
`
const Link = styled.a<{ hideDecorator: boolean }>`
  ${ClickableStyles};
  ${SharedStyles};
  ${({ hideDecorator }) =>
    hideDecorator ? `text-decoration: none;` : undefined};
`
const Span = styled.span`
  ${SharedStyles};
`

export default BreadcrumbsItem
