import { useCallback, useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'

import {
  BORDER_RADIUS,
  Button,
  FadeInContainer,
  Icon,
  RenameInput,
  Spinner,
} from '@sportsyou/react-dom-ui'
import { campaignAssetTranscodeUrl } from '../CampaignHelpers'
import { Colors } from '@sportsyou/core'
import { ProgressProps } from '@sportsyou/react-hooks'
// import useLightbox from '../../../components/Lightbox/useLightbox'
import { Upload } from '@sportsyou/api'

interface AssetManagerItemRowProps {
  asset: Upload
  onClickDelete: (assetId: string) => void
  onSubmitSetName: (assetId: string, name: string) => void
  uploadProgress?: ProgressProps
}

export default function AssetManagerItemRow(props: AssetManagerItemRowProps) {
  const { asset, onClickDelete, onSubmitSetName } = props

  // const { showWithItems: showLightboxWithItems } = useLightbox()

  const [isRenaming, setIsRenaming] = useState(false)

  const handleOnCancelSetName = useCallback(() => {
    setIsRenaming(false)
  }, [])

  const handleOnSubmitSetName = useCallback(
    (name: string) => {
      setIsRenaming(false)
      onSubmitSetName(asset.id as string, name)
    },
    [asset, onSubmitSetName]
  )

  const handleOnClickDelete = useCallback(() => {
    onClickDelete(asset.id as string)
  }, [asset, onClickDelete])

  const handleOnClickImage = useCallback(() => {
    const items = [asset]
    const index = 0
    // showLightboxWithItems({ items, index })
  }, [
    asset,
    // showLightboxWithItems
  ])

  // Render upload in progress row
  if (props.uploadProgress) {
    return (
      <AssetListItem key={asset.id}>
        <AssetListItemImageContainer>
          <Spinner size={30} color={Colors.SHUTTLE_GRAY} />
        </AssetListItemImageContainer>
        <AssetListItemName>
          {asset.fileName}: {props.uploadProgress?.uploadProgress ?? 0}%
          {props.uploadProgress?.uploadProgress === 100 ? (
            <FadeInContainer fadeInSpeed={2}>
              <Icon name='Checkmark' size={15} color={Colors.MOUNTAIN_MEADOW} />
            </FadeInContainer>
          ) : null}
        </AssetListItemName>
      </AssetListItem>
    )
  }

  return (
    <AssetListItem key={asset.id}>
      <AssetListItemImageContainer>
        <AssetListItemImage
          alt={asset.fileName as string}
          onClick={handleOnClickImage}
          src={campaignAssetTranscodeUrl(asset)}
        />
      </AssetListItemImageContainer>
      <AssetListItemName>
        {isRenaming ? (
          <RenameInput
            onCancel={handleOnCancelSetName}
            onSubmit={handleOnSubmitSetName}
            value={asset.sponsorInfo?.assetName ?? ''}
          />
        ) : (
          <AssetListItemNameEditable
            onClick={() => setIsRenaming(true)}
            title='Click to rename'
          >
            {asset.sponsorInfo?.assetName ?? 'Untitled'}
            <Icon name='Pencil' size={13} color={Colors.HAVELOCK_BLUE} />
          </AssetListItemNameEditable>
        )}
      </AssetListItemName>
      <AssetListItemModifiedDate>
        {moment(asset.createdAt).format('MM/DD/YYYY hh:mm A')}
      </AssetListItemModifiedDate>
      <AssetListItemDelete>
        <Button
          appearance='minimal'
          variant='danger'
          onClick={handleOnClickDelete}
        >
          <Icon name='Trash' size={20} color={Colors.SHUTTLE_GRAY} />
        </Button>
      </AssetListItemDelete>
    </AssetListItem>
  )
}

const AssetListItem = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 20px;
`

const AssetListItemImageContainer = styled.div`
  width: 200px;
`
const AssetListItemImage = styled.img`
  border-radius: ${BORDER_RADIUS};
  border: 1px solid ${Colors.SHUTTLE_GRAY};
  cursor: pointer;
  max-height: 100px;
  max-width: 200px;
`

const AssetListItemName = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 10px;
`

const AssetListItemNameEditable = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: flex-start;
`

const AssetListItemModifiedDate = styled.div``

const AssetListItemDelete = styled.div``
