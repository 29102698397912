import * as React from 'react'
import type { SVGProps } from 'react'
const CalendarSubscribe = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <g>
      <path d='M16.67 30.3a1.17 1.17 0 0 0 1.17 1.17h9.1a4.82 4.82 0 0 0 4.81-4.81v-20a4.82 4.82 0 0 0-4.81-4.81h-2.48v-.69a1.16 1.16 0 0 0-2.32 0v.66H9.89v-.66a1.16 1.16 0 0 0-2.32 0v.66H5.09A4.82 4.82 0 0 0 .28 6.63v7.28a1.16 1.16 0 0 0 2.32 0v-2.48h26.82v15.23a2.48 2.48 0 0 1-2.48 2.48h-9.1a1.16 1.16 0 0 0-1.17 1.16zM29.42 9.11H2.6V6.63a2.5 2.5 0 0 1 2.49-2.49h2.48v.66a1.16 1.16 0 1 0 2.32 0v-.66h12.25v.66a1.16 1.16 0 0 0 2.32 0v-.66h2.48a2.49 2.49 0 0 1 2.48 2.49zM2.16 28.52a1.57 1.57 0 1 1-1.57 1.57 1.58 1.58 0 0 1 1.57-1.57z' />
      <path d='M1.06 24.4a6 6 0 0 1 5.27 1.53 6 6 0 0 1 1.52 5.27.91.91 0 0 1-.37.61.93.93 0 0 1-.7.18 1 1 0 0 1-.62-.37.93.93 0 0 1-.17-.7 4.18 4.18 0 0 0-1-3.66 4.13 4.13 0 0 0-3.65-1 1 1 0 0 1-.71-.18.91.91 0 0 1-.36-.61.78.78 0 0 1 0-.14 1 1 0 0 1 .79-.93z' />
      <path d='M7.52 24.73a7.57 7.57 0 0 0-6.23-2 .94.94 0 0 1-1-.86v-.08a.94.94 0 0 1 .85-.94 9.4 9.4 0 0 1 7.71 2.55 9.35 9.35 0 0 1 2.58 7.75.89.89 0 0 1-.34.63.91.91 0 0 1-.69.22 1 1 0 0 1-.64-.35.88.88 0 0 1-.2-.68 7.58 7.58 0 0 0-2.04-6.24z' />
      <path d='M10.05 22.2a11.06 11.06 0 0 0-8.79-3.08.93.93 0 0 1-.68-.22.92.92 0 0 1-.32-.64v-.08a1 1 0 0 1 .88-.94 12.89 12.89 0 0 1 10.25 3.63A12.93 12.93 0 0 1 15 31.13a1 1 0 0 1-1 .87.93.93 0 0 1-.87-1 11 11 0 0 0-3.08-8.8z' />
    </g>
  </svg>
)
export default CalendarSubscribe
