import {
  AnchorHTMLAttributes,
  ComponentProps,
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'

interface Props {
  className?: string
  color?: string
  /** The URL that the link points to */
  href?: string
  /** The relationship of the link as space-separated link types. */
  rel?: string
  /** Where to display the linked URL */
  target?: ComponentProps<'a'>['target']
  testId?: string
  title?: string
  variant?: 'primary' | 'secondary' | 'navbar'
}

type NativeAttributes = Omit<AnchorHTMLAttributes<any>, keyof Props>
export type LinkProps = Props & NativeAttributes

const Anchor = styled.a<Pick<Props, 'color'>>`
  color: ${({ color }) => color};
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  &:hover,
  &:active {
    text-decoration: underline;
  }
`

export const Link: FC<PropsWithChildren<LinkProps>> = ({
  children,
  className,
  color,
  href,
  rel,
  target,
  testId,
  title = '',
  variant = 'primary',
  ...props
}: LinkProps) => {
  const [_rel, setRel] = useState(rel)

  useEffect(() => {
    if (rel) {
      setRel(rel)
    } else if (target === '_blank') {
      setRel('noreferrer noopener')
    }
  }, [rel, target])

  const getAnchorColor = useMemo(() => {
    // custom color prop takes precedent over variant prop
    if (color) return color

    if (variant === 'secondary') {
      return Colors.PUNCH
    } else if (variant === 'navbar') {
      return Colors.SHUTTLE_GRAY
    }

    // default color
    return Colors.HAVELOCK_BLUE
  }, [color, variant])

  return (
    <Anchor
      {...props}
      aria-label={title}
      className={className}
      color={getAnchorColor}
      data-testid={testId}
      href={href}
      rel={_rel}
      target={target}
      title={title}
    >
      {children}
    </Anchor>
  )
}

export default Link
