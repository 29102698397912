import React, { PropsWithChildren, useMemo, useState } from 'react'

import ButtonGroupButton, {
  ButtonGroupButtonProps,
} from './button-group-button'
import { getChildrenFromReactNode, getPropsFromReactNode } from '../../utils'
import styled from 'styled-components'

export interface ButtonGroupProps {
  children?: React.ReactNode
  buttons?: ButtonGroupButtonProps[]
  className?: string
  style?: React.CSSProperties
  showActiveState?: boolean
  testId?: string
}

export const ButtonGroup: React.FC<PropsWithChildren<ButtonGroupProps>> = ({
  buttons: initialButtons,
  children,
  className,
  showActiveState = false,
  style,
  testId,
}: ButtonGroupProps) => {
  const [activeIndex, setActiveIndex] = useState<number>()

  const buttons = useMemo(() => {
    if (initialButtons) {
      return initialButtons.map((button, index) => {
        if (button.isActive) {
          setActiveIndex(index)
        }
        return (
          <ButtonGroupButton
            {...button}
            onClick={(event) => {
              button.onClick(event)
              showActiveState && setActiveIndex(index)
            }}
            isActive={activeIndex ? index === activeIndex : button.isActive}
            key={index}
            isFirst={index === 0}
            isLast={index === initialButtons.length - 1}
          />
        )
      })
    } else if (children) {
      return React.Children.map(children, (item, index) =>
        React.isValidElement(item) && item.type === ButtonGroupButton ? (
          <ButtonGroupButton
            {...getPropsFromReactNode(item)}
            key={index}
            isActive={activeIndex ? index === activeIndex : item.props.isActive}
            onClick={(event) => {
              item.props.onClick(event)
              showActiveState && setActiveIndex(index)
            }}
            isFirst={index === 0}
            isLast={index === React.Children.count(children) - 1}
          >
            {getChildrenFromReactNode(item)}
          </ButtonGroupButton>
        ) : null
      )
    }
  }, [activeIndex, children, initialButtons, showActiveState])

  return (
    <Container
      className={className}
      data-testid={testId}
      role='group'
      style={style}
    >
      {buttons}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
`

export default ButtonGroup
