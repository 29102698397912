import * as React from 'react'
import type { SVGProps } from 'react'
const ThumbsUpFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M31.26 14.72l-.62-.92A4.34 4.34 0 0 0 27 11.86l-7.63-.12a19 19 0 0 0-1.15-6.88c-1-2.62-3.18-3.56-4.77-3.15-1.43.37-2.8 1.87-2.39 4.86.05 1.44-.24 5-1.92 6.75a3 3 0 0 0-2.64-1.56H3a2.92 2.92 0 0 0-3 2.84v12.94a2.92 2.92 0 0 0 3 2.84h3.52a3 3 0 0 0 2.87-2.14l.69.74a4.37 4.37 0 0 0 3.2 1.4h11.8a4.39 4.39 0 0 0 4.2-3.16l2.55-8.86a4.34 4.34 0 0 0-.57-3.64zM6.52 28.39H3a.93.93 0 0 1-1-.85V14.6a.93.93 0 0 1 1-.85h3.52a.92.92 0 0 1 1 .85v12.94a.92.92 0 0 1-1 .85z' />
  </svg>
)
export default ThumbsUpFilled
