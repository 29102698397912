import { useRef } from 'react'

export type BrowserNotificationOptions = NotificationOptions & {
  /** The body string of the Notification */
  body?: string
  /** Used to close if the user clicks on the Notification. */
  closeOnClick?: boolean
  id?: string
  /** The title string of the Notification */
  title?: string
  onClick?: (event: Event) => void
  onClose?: (event: Event) => void
  onError?: (event: Event) => void
  onShow?: (event: Event) => void
}

/** test */
export const useBrowserNotifications = () => {
  const notification = useRef<Notification | null>(null)

  const isSupported = () => 'PushManager' in window

  /**
   * Ask for user consent to recieve push notifications
   * @returns 'granted' | 'default' | 'denied
   */
  const ask = async () => await Notification.requestPermission()

  /**
   * Close Notification interface
   */
  const close = () => {
    notification.current?.close()
  }

  /**
   * Sends push notification is supported and access is granted.
   */
  const send = async ({
    badge,
    body,
    closeOnClick = false,
    data,
    dir = 'auto',
    icon,
    id,
    renotify = false,
    requireInteraction = false,
    silent = false,
    title = 'sportsYou',
    onClick,
    onClose,
    onError,
    onShow,
  }: BrowserNotificationOptions) => {
    if (!isSupported) {
      window.alert('Your browser does not support notifications')
      return
    }

    const permission = await ask()

    if (permission === 'granted') {
      notification.current = new Notification(title, {
        badge,
        body,
        icon,
        data,
        dir,
        renotify,
        requireInteraction,
        silent,
        tag: id,
      })

      if (closeOnClick) {
        notification.current.onclick = (event) => {
          onClick?.(event)
          close()
        }
      } else {
        if (onClick) {
          notification.current.onclick = (event) => onClick(event)
        }
      }

      if (onClose) notification.current.onclose = onClose
      if (onError) notification.current.onerror = onError
      if (onShow) notification.current.onshow = onShow
    }
  }

  return { ask, close, isSupported, send }
}

export default useBrowserNotifications
