import React from 'react'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'

import Button from '../button/button'
import DropdownIcon from './dropdown-icon'

export interface DropdownToggleProps {
  chevronColor?: string
  chevronSize?: number
  chevronStyle?: React.CSSProperties
  className?: string
  collapse?: boolean
  customToggle?: React.ReactNode
  disabled?: boolean
  hideChevron?: boolean
  loading?: boolean
  onClick?: React.MouseEventHandler
  style?: React.CSSProperties
  title?: string | React.ReactNode
  textAlign?: 'left' | 'center' | 'right'
  visible?: boolean
}

const DropdownToggle: React.FC<
  React.PropsWithChildren<DropdownToggleProps>
> = ({
  chevronColor,
  chevronSize,
  chevronStyle,
  className,
  collapse,
  customToggle,
  disabled,
  hideChevron,
  loading,
  onClick,
  style,
  title,
  visible = false,
}: DropdownToggleProps) => {
  const handleOnClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>
  ) => {
    onClick?.(event)
  }

  // Custom title element wrapped in div with onClick handler
  if (React.isValidElement(title)) {
    return <Toggle onClick={handleOnClick}>{title}</Toggle>
  }

  if (customToggle) return customToggle

  return (
    <Button
      appearance={'minimal'}
      aria-expanded={visible}
      aria-haspopup='menu'
      className={className}
      collapse={collapse}
      color={Colors.MINE_SHAFT}
      disabled={disabled}
      loading={loading}
      onClick={handleOnClick}
      textStyle={{
        flex: '1 0 auto',
        justifyContent: title !== '' ? 'space-between' : 'center',
      }}
      style={{
        backgroundColor: visible ? 'rgb(0 0 0 / 4%)' : undefined,
        paddingLeft: 8,
        paddingRight: 8,
        ...style,
      }}
    >
      {title}
      {!hideChevron && (
        <DropdownIcon
          size={chevronSize}
          fill={disabled ? Colors.DUSTY_GRAY : chevronColor}
          style={{
            marginLeft: title !== '' ? 6 : undefined,
            ...chevronStyle,
          }}
          visible={visible}
        />
      )}
    </Button>
  )
}

const Toggle = styled.div`
  cursor: pointer;
`

DropdownToggle.displayName = 'DropdownToggle'

export default DropdownToggle
