import * as React from 'react'
import type { SVGProps } from 'react'
const Search = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M31.54 30.1l-10-10.17a12.09 12.09 0 1 0-1.59 1.57L30 31.67a1.09 1.09 0 0 0 .79.33 1.12 1.12 0 0 0 .8-1.9zm-29.16-18a9.89 9.89 0 1 1 9.88 9.9 9.9 9.9 0 0 1-9.88-9.88z' />
  </svg>
)
export default Search
