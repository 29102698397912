import * as React from 'react'
import type { SVGProps } from 'react'
const CreateGame = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <g>
      <path d='M12.91 24.56H4a2 2 0 0 1-2-2V10.2h21.82v2a1 1 0 1 0 2 0V6.22a4 4 0 0 0-4-4h-2v-.47a1 1 0 0 0-2 0v.49H8v-.49a1 1 0 0 0-2 0v.49H4a4 4 0 0 0-4 4v16.35a4 4 0 0 0 4 4h8.93a1 1 0 1 0 0-2zM2 6.22a2 2 0 0 1 2-2h2v.48a1 1 0 0 0 2 0v-.45h9.9v.48a1 1 0 0 0 2 0v-.48h2a2 2 0 0 1 2 2v2H2z' />
      <path d='M12.81 15.86a.3.3 0 0 0-.25-.22L10 15.26l-1.17-2.35a.33.33 0 0 0-.56 0l-1.15 2.35-2.58.38a.31.31 0 0 0-.25.22.3.3 0 0 0 .08.32L6.23 18l-.44 2.58a.31.31 0 0 0 .13.31.3.3 0 0 0 .33 0l2.3-1.22 2.31 1.22A.23.23 0 0 0 11 21a.31.31 0 0 0 .31-.37L10.87 18l1.87-1.83a.32.32 0 0 0 .07-.31zm18.33 2.66l-2.77-2.78a3 3 0 0 0-4.15 0l-7.73 7.73a2.92 2.92 0 0 0-.86 2.07v4.53a1.18 1.18 0 0 0 1.18 1.18h4.53a2.88 2.88 0 0 0 2.07-.86l7.73-7.73a2.92 2.92 0 0 0 0-4.14zM29.47 21l-7.73 7.73a.56.56 0 0 1-.4.17H18v-3.36a.56.56 0 0 1 .17-.4l7.73-7.73a.57.57 0 0 1 .8 0l2.78 2.78a.57.57 0 0 1-.01.81z' />
    </g>
  </svg>
)
export default CreateGame
