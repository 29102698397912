import * as React from 'react'
import type { SVGProps } from 'react'
const CalendarAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 33'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M27.651 6.74779c-.7418-.7358-1.76-1.15419-2.8072-1.15419h-2.1237v-.70695c0-.40396-.32-.72137-.7272-.72137-.4073 0-.7273.31741-.7273.72137v.70695h-9.6291v-.70695c0-.40396-.32-.72137-.7273-.72137-.4072 0-.7272.31741-.7272.72137v.70695H8.05832c-1.06182 0-2.06546.41839-2.80728 1.16862-.74181.7358-1.14909 1.74571-1.16364 2.77006V24.8253c0 1.0532.42182 2.0487 1.14909 2.7845.74182.7358 1.76001 1.1542 2.80728 1.1542H24.8292c1.0618 0 2.0655-.4184 2.8073-1.1397.7418-.7503 1.1491-1.7602 1.1491-2.799V9.53228c0-1.0532-.4218-2.04869-1.1491-2.78449h.0145Zm-.3054 18.06311c0 .6781-.2618 1.3129-.7273 1.7746-.48.4761-1.12.7358-1.7891.7358H8.04377c-.66909 0-1.3091-.2597-1.7891-.7214-.48-.4761-.74181-1.1109-.74181-1.7746V12.9227H27.331v11.8882h.0146Zm0-13.3309H5.5274V9.54671c0-.66366.27637-1.29847.74182-1.77457.46546-.47611 1.09091-.7358 1.77455-.7358h2.12363v.25969c0 .40397.32.72137.7273.72137.4072 0 .7272-.3174.7272-.72137v-.25969h9.6291v.25969c0 .40397.32.72137.7273.72137.4073 0 .7273-.3174.7273-.72137v-.25969h2.1236c.6691 0 1.3091.25969 1.7746.72137.48.4761.7418 1.11091.7418 1.77457V11.48Z' />
    <path d='M17.7891 24.6522h3.6218c.4073 0 .7273-.3174.7273-.7214s-.32-.7214-.7273-.7214h-.9891v-6.0306c0-.2597-.1455-.505-.3782-.6348-.2327-.1299-.5091-.1155-.7418.0144l-1.6873 1.0532c-.3345.2164-.4363.6636-.2327.9955.2036.3318.6691.4328 1.0036.2308l.5819-.3607v4.7178h-1.1782c-.4073 0-.7273.3174-.7273.7214 0 .4039.32.7213.7273.7213v.0145ZM13.1929 23.3247c-.5527 0-1.0036-.4472-1.0036-.9954 0-.404-.32-.7214-.7273-.7214-.4073 0-.7273.3174-.7273.7214 0 1.3417 1.1055 2.4382 2.4582 2.4382 1.3527 0 2.4582-1.0965 2.4582-2.4382 0-.6637-.2764-1.2697-.7127-1.7169.4363-.4473.7127-1.0532.7127-1.7169 0-1.3417-1.1055-2.4382-2.4582-2.4382-1.3527 0-2.4582 1.0821-2.4582 2.4382 0 .404.32.7214.7273.7214.4073 0 .7273-.3174.7273-.7214 0-.5482.4509-.9955 1.0036-.9955s1.0036.4473 1.0036.9955c0 .5483-.4509.9955-1.0036.9955-.4073 0-.7273.3174-.7273.7214s.32.7214.7273.7214c.5527 0 1.0036.4472 1.0036.9955 0 .5482-.4509.9954-1.0036.9954Z' />
  </svg>
)
export default CalendarAlt
