import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import styled from 'styled-components'

import { Community, Post } from '@sportsyou/api'
import { Post as PostComponent, Skeleton, Table } from '@sportsyou/react-dom-ui'

import usePagesPortal from '../UsePagesPortal'

interface Props {
  page: Community
}

const PostThumbnail = ({
  onClick,
  page,
  post,
}: {
  onClick: (post: Post) => void
  page: Community
  post: Post
}) => {
  const handleOnClick = useCallback(() => {
    onClick(post)
  }, [onClick, post])

  return (
    <PostThumbContainer onClick={handleOnClick}>
      <PostThumbCover />
      <PostThumbWrapper>
        <PostComponent {...post} community={page} hideFollowButton />
      </PostThumbWrapper>
    </PostThumbContainer>
  )
}

const tableCustomStyles = {
  headCells: {
    style: {
      fontWeight: 'bold',
      fontSize: '16px',
    },
  },
}

export default function PagesContentPortalProfilePostList(props: Props) {
  const navigate = useNavigate()

  const { page, fetchPosts, isLoading, posts } = usePagesPortal({
    communityId: props.page.id as string,
  })

  const onRowClicked = useCallback(
    (post: Post) => {
      navigate(`/pages/${page.id}/posts/${post.id}`)
    },
    [page.id, navigate]
  )

  const dataColumns = useMemo(() => {
    return [
      {
        format: (post: Post) => (
          <PostThumbnail page={page} post={post} onClick={onRowClicked} />
        ),
        name: 'Post',
        reorder: true,
        selector: (post: Post) => moment(post.createdAt).format('MM/DD/YYYY'),
        sortable: true,
        style: {
          cursor: 'pointer',
          height: '120px',
        },
        width: '200px',
      },
      {
        name: 'Posted Date',
        reorder: true,
        selector: (post: Post) => moment(post.createdAt).format('MM/DD/YYYY'),
        sortable: true,
        width: '200px',
      },
      {
        name: 'Title',
        reorder: true,
        selector: (post: Post) => {
          let message = post.communityPostInfo?.postTitle
          if (!message) {
            if ((post.message?.length ?? 0) > 80) {
              message = `${post.message?.slice(0, 80)}...`
            } else {
              message = post.message
            }
          }
          return message
        },
        sortable: true,
        style: {
          cursor: 'pointer',
          fontWeight: 'bold',
        },
      },
    ]
  }, [onRowClicked, page])

  useEffect(() => {
    if (!page.id) return
    fetchPosts({
      communityId: page.id,
    })
  }, [fetchPosts, page.id])

  return (
    <Table
      // selectableRows
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      columns={dataColumns}
      customStyles={tableCustomStyles}
      data={posts}
      defaultSortAsc={true}
      highlightOnHover
      onRowClicked={onRowClicked}
      progressPending={isLoading}
      striped
      progressComponent={
        <Skeleton
          barGap={8}
          barHeight={120}
          showBars
          showHeader={false}
          width={'100%'}
        />
      }
    />
  )
}

const PostThumbWrapper = styled.div`
  border: 6px solid #ccc;
  height: 250px;
  left: 0;
  top: 0;
  transform: scale(0.5) translate(-50%, -30%);
  transition: all 0.2s ease-in-out;
  width: 300px;
`

const PostThumbCover = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
`

const PostThumbContainer = styled.div`
  height: 120px;
  overflow: hidden;
  position: relative;
  width: 200px;

  &:hover ${PostThumbWrapper} {
    transform: scale(0.5) translate(-50%, -40%) rotate(-1deg);
  }
`
