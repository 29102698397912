import { useRef, useState, useEffect, useMemo } from 'react'
import moment, { Moment } from 'moment'
import styled from 'styled-components'

import { Event as EventProps, MutationEventUpdateRequest } from '@sportsyou/api'
import { Colors } from '@sportsyou/core'

import { DayProps } from '../calendar-types'
import { EventChipShape } from './month-event-chip'
import Day from './month-calendar-day'
import WeekEvents from './month-calendar-week-events'

const BORDER_WIDTH_HORIZONTAL = 7
const CHIP_HEIGHT = 18
const CHIP_TOP_OFFSET = 24
const HEADER_HEIGHT = 22

const initialEventChipShape = (
  dayWidth: number,
  event: EventProps,
  weekEnd: Moment,
  weekStart: Moment
): EventChipShape => {
  const start = moment(event.startDate)
  const end = moment(event.endDate)

  let continuedFromPrevious = false
  let continuesToNext = false
  if (!start.isSame(end, 'day')) {
    if (start.isBefore(weekStart)) {
      continuedFromPrevious = true
    }
    if (end.isAfter(weekEnd)) {
      continuesToNext = true
    }
  }
  const weekEndDay = continuesToNext ? 6 : end.day()
  const weekStartDay = continuedFromPrevious ? 0 : start.day()
  const daysLong = weekEndDay - weekStartDay + 1

  return {
    continuedFromPrevious,
    continuesToNext,
    daysLong,
    event,
    left: dayWidth * weekStartDay + weekStartDay,
    multiday: daysLong > 1,
    top: 0,
    weekEndDay,
    weekStartDay,
    width: dayWidth * daysLong + weekEndDay - weekStartDay - 3,
  }
}

function dateRangesOverlap({
  start1,
  end1,
  start2,
  end2,
  granularity = 'hour',
  inclusivity = '[]',
}: {
  start1: Moment
  end1: Moment
  start2: Moment
  end2: Moment
  granularity?: moment.unitOfTime.StartOf
  inclusivity?: '[]' | '()' | '[)' | '(]'
}) {
  const startFirst = start1.isBetween(start2, end2, granularity, inclusivity)
  const endFirst = end1.isBetween(start2, end2, granularity, inclusivity)
  const startLast = start2.isBetween(start1, end1, granularity, inclusivity)
  const endLast = end2.isBetween(start1, end1, granularity, inclusivity)
  return startFirst || endFirst || startLast || endLast
}

export function maxEventChipsPerDay(weekHeight: number) {
  return Math.floor((weekHeight - CHIP_TOP_OFFSET) / CHIP_HEIGHT) - 1
}

const buildEventChipsShapes = (
  dayWidth: number,
  events: EventProps[],
  weekEnd: Moment,
  weekHeight: number,
  weekStart: Moment
): EventChipShape[] => {
  const eventChips: EventChipShape[] = events.map((event, i) => {
    return initialEventChipShape(dayWidth, event, weekEnd, weekStart)
  })

  // move the events by weekStartDay into arrays for each day
  // note: this step may not be necessary anymore
  // also some of these steps could be combined
  const eventChipsByDay = Array.from({ length: 7 }, (_, i) => i).map(
    (dayNumber) => {
      return eventChips.filter((eventShape) => {
        return eventShape.weekStartDay === dayNumber
      })
    }
  )

  // for each day, sort events by length, events that are longer will go first, events starting on previous week/day will go first
  const eventChipsByDaySorted = eventChipsByDay.map((chips) => {
    return [...chips].sort((a, b) => {
      if (a.daysLong === b.daysLong) {
        if (a.continuedFromPrevious === b.continuedFromPrevious) {
          return 0
        }
        return a.continuedFromPrevious ? -1 : 1
      }
      return b.daysLong - a.daysLong
    })
  })

  // sort events into rows, check for overlap. if there is overlap, move the event to the next row
  const eventChipsByDaySortedInRows = eventChipsByDaySorted.map(
    (eventChips) => {
      return eventChips.map((eventChip, index) => {
        let overlappingChips = 0

        const chipsThatEnterThisDay = eventChipsByDaySorted
          .flat(2)
          .filter((otherEventChip) => {
            const otherWeekEndDay = otherEventChip.continuesToNext
              ? 6
              : otherEventChip.weekEndDay
            return otherEventChip.weekEndDay >= otherWeekEndDay
          })

        chipsThatEnterThisDay.forEach((otherEventChip) => {
          // if the other event enters the start day of this event, it overlaps
          if (otherEventChip.weekEndDay >= eventChip.weekStartDay) {
            if (otherEventChip.row !== undefined) {
              if (otherEventChip.row === overlappingChips) {
                overlappingChips = otherEventChip.row + 1
              }
            }
          }
        })
        eventChip.row = overlappingChips
        if (overlappingChips >= maxEventChipsPerDay(weekHeight)) {
          eventChip.row = undefined
        }
        return eventChip
      })
    }
  )

  // set the top of each event based on the row
  eventChipsByDaySortedInRows.forEach((eventChips) => {
    eventChips.forEach((eventChip) => {
      if (eventChip.row === undefined) {
        return
      }
      eventChip.top = eventChip.row * CHIP_HEIGHT
    })
  })

  return eventChipsByDaySortedInRows.flat()
}

export interface MonthCalendarProps {
  days: DayProps[]
  eventIdToClickOnMount?: string
  fillChipWithGameType?: boolean
  onClickEvent?: (
    event: EventProps,
    e: React.MouseEvent<HTMLDivElement>
  ) => void
  onClickDay?: (date: DayProps, e: React.MouseEvent<HTMLDivElement>) => void
  onDoubleClickDay?: (
    date: DayProps,
    e: React.MouseEvent<HTMLDivElement>
  ) => void
  onDropEvent?: (
    originalEvent: EventProps,
    newEvent: EventProps,
    newEventRequest: MutationEventUpdateRequest
  ) => void
  selectedDate: string
}

export default function MonthCalendar(props: MonthCalendarProps) {
  const containerRef = useRef<HTMLDivElement | null>(null)

  const [calendarSize, setCalendarSize] = useState({ width: 0, height: 0 })

  useEffect(() => {
    if (!containerRef.current) return
    const resizeObserver = new ResizeObserver(() => {
      handleResize()
    })
    resizeObserver.observe(containerRef.current)
    return () => resizeObserver.disconnect() // clean up
  }, [])

  const firstDayOfCurrentMonth = useMemo(
    () => moment(props.selectedDate).startOf('month'),
    [props.selectedDate]
  )

  const calendarHeight = useMemo(
    () => (calendarSize?.height ?? HEADER_HEIGHT) - HEADER_HEIGHT,
    [calendarSize]
  )
  const daysInTheMonth = firstDayOfCurrentMonth.clone().endOf('month').date()
  const weekCount = useMemo(() => props.days.length / 7, [props.days])
  const weekHeight = useMemo(
    () => calendarHeight / weekCount,
    [calendarHeight, weekCount]
  )
  const calendarWidth = useMemo(
    () =>
      (calendarSize?.width ?? BORDER_WIDTH_HORIZONTAL) -
      BORDER_WIDTH_HORIZONTAL,
    [calendarSize]
  )
  const dayWidth = useMemo(() => calendarWidth / 7, [calendarWidth])

  const events = useMemo(() => {
    return props.days
      .map((day) => {
        return day.events
      })
      .flat() as EventProps[]
  }, [props.days])

  const eventChipsByWeek: EventChipShape[][] = useMemo(() => {
    return Array.from({ length: weekCount }, (_, i) => i).map((weekNumber) => {
      const weekStart = firstDayOfCurrentMonth
        .clone()
        .add(weekNumber, 'weeks')
        .startOf('week')
      const weekEnd = firstDayOfCurrentMonth
        .clone()
        .add(weekNumber, 'weeks')
        .endOf('week')
      const eventsWithinWeek = events.filter((event) => {
        return dateRangesOverlap({
          start1: moment(event.startDate),
          end1: moment(event.endDate),
          start2: weekStart,
          end2: weekEnd,
        })
      })
      return buildEventChipsShapes(
        dayWidth,
        eventsWithinWeek,
        weekEnd,
        weekHeight,
        weekStart
      )
    })
  }, [dayWidth, events, firstDayOfCurrentMonth, weekHeight, weekCount])

  const eventsOnEachDayOfTheMonth: EventProps[][] = useMemo(() => {
    return Array.from({ length: daysInTheMonth }, (_, i) => i).map(
      (dayNumber) => {
        const dayStart = firstDayOfCurrentMonth
          .clone()
          .day(dayNumber)
          .startOf('day')
        const dayEnd = firstDayOfCurrentMonth
          .clone()
          .day(dayNumber)
          .endOf('day')
        const eventsWithinDay = []
        eventsWithinDay.push(
          events.filter((event) => {
            return dateRangesOverlap({
              start1: moment(event.startDate),
              end1: moment(event.endDate),
              start2: dayStart,
              end2: dayEnd,
            })
          })
        )
        return eventsWithinDay.flat()
      }
    )
  }, [daysInTheMonth, events, firstDayOfCurrentMonth])

  const handleResize = () => {
    if (containerRef.current) {
      const { width, height } = containerRef.current.getBoundingClientRect()
      if (width && height) {
        setCalendarSize({ width, height })
      }
    }
  }

  return (
    <Container ref={containerRef}>
      <Weekdays>
        <Weekday>Sun</Weekday>
        <Weekday>Mon</Weekday>
        <Weekday>Tue</Weekday>
        <Weekday>Wed</Weekday>
        <Weekday>Thu</Weekday>
        <Weekday>Fri</Weekday>
        <Weekday>Sat</Weekday>
      </Weekdays>
      <Days>
        {/* Render day boxes */}
        {props.days.map((day, index) => {
          return (
            <Day
              allEvents={eventsOnEachDayOfTheMonth?.[index] ?? []}
              day={day}
              eventIdToClickOnMount={props.eventIdToClickOnMount}
              fillChipWithGameType={props.fillChipWithGameType}
              key={index}
              maxEventChipsPerDay={maxEventChipsPerDay(weekHeight)}
              onClickDay={props.onClickDay}
              onClickEvent={props.onClickEvent}
              onDoubleClickDay={props.onDoubleClickDay}
              onDropEvent={props.onDropEvent}
            />
          )
        })}

        {/* Render event chips */}
        {eventChipsByWeek.map((_eventChips, index) => {
          const weekStart = firstDayOfCurrentMonth
            .clone()
            .add(index, 'week')
            .startOf('week')
          const weekEnd = weekStart.clone().add(index, 'week').endOf('week')
          return (
            <WeekEvents
              endDate={weekEnd}
              eventChips={_eventChips}
              eventIdToClickOnMount={props.eventIdToClickOnMount}
              fillChipWithGameType={props.fillChipWithGameType}
              key={index}
              onClickEvent={props.onClickEvent}
              startDate={weekStart}
              weekHeight={weekHeight}
              weekNumber={index}
            />
          )
        })}
      </Days>
    </Container>
  )
}

const Container = styled.div`
  border-bottom: 1px solid ${Colors.ALTO};
  border-right: 1px solid ${Colors.ALTO};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
`

const Weekdays = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`
const Weekday = styled.div`
  align-items: center;
  border-bottom: none;
  border-right: none;
  border: 1px solid ${Colors.ALTO};
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 700;
  justify-content: center;
  padding: 2px 4px;
  width: 100%;
`
const Days = styled.div`
  display: grid;
  gap: 0;
  grid-template-columns: repeat(7, 1fr);
  position: relative;
`
