export enum Colors {
  HAVELOCK_BLUE = '#4A90E2', // SY Blue
  PUNCH = '#fa5831', // '#D7, // '#D74C2B'range

  WHITE = '#FFFFFF',
  BLACK = '#000000',

  TRANSPARENT = 'transparent',

  // GRAYS
  SHUTTLE_GRAY = '#5C6875', // Dark BluGray
  MYSTIC = '#E0E7EE', // Light BluGray
  ALTO = '#DDDDDD', // Light Gray
  DUSTY_GRAY = '#9B9B9B', // Medium Gray
  MINE_SHAFT = '#2B2B2B', // Dark Gray

  // STATUS & NOTIFICATIONS
  PERANO = '#B6D3F3', // Unread Blue (Used ONLY for unread alerts)
  CATSKILL_WHITE = '#F0F4F8', // Received Blue (Used ONLY for chat conversations)
  GOLD = '#ffd700',
  MOUNTAIN_MEADOW = '#1FD583', // Valid Green
  MONZA = '#DD0707', // Invalid Red
  VIVID_TANGERINE = '#FF8181', // Reject Pink (Used ONLY to approve pending posts)
}

export const AVATAR_AND_CALENDAR_COLORS = [
  '#275FC3',
  '#DD254D',
  '#501F87',
  '#07874F',
  '#DA2DB0',
  '#373737',

  '#5C6BC0',
  '#000063',
  '#8E44AD',
  '#20A185',
  '#8F805E',
  '#D65323',
  '#1D507D',

  '#252525',
  '#00675B',
  '#5C6875',
  '#C51162',
  '#FC6152',
  '#2C3E50',
  '#869899',
]

export enum POST_COLORS {
  BLUE = '#275fc3',
  PURPLE = '#501f87',
  RED = '#dd254d',
  PINK = '#da2db0',
  ORANGE = '#d65323',
  GREEN = '#07874f',
  NAVY = '#1d5070',
  GOLD = '#8f805e',
  BLACK = '#252525',
}

export enum FILE_COLORS {
  GENERIC = '#4a90e2',
  DOC = '#2962ff',
  PDF = '#e6175d',
  PPT = '#e6175d',
  XLS = '#10884e',
  ZIP = '#d102d1',
  MP3 = '#5c6bc0',
  INAGE = '#00675b',
  JPG = '#00675b',
  PNG = '#00675b',
  VIDEO = '#373737',
  AVI = '#373737',
  GIF = '#373737',
  M4V = '#373737',
  MKV = '#373737',
  MOV = '#373737',
  MP4 = '#373737',
}
