import { format } from 'date-fns'
import React, { useCallback, useMemo } from 'react'
import styled, { css } from 'styled-components'

import { capitalize, Colors } from '@sportsyou/core'
import { Event } from '@sportsyou/api'

interface ExtdCalendarEvent extends Event {
  agendaDate?: string
  customTime?: string
}

interface Props {
  className?: string
  event: ExtdCalendarEvent
  onClickEvent?: (id?: string) => void
  style?: React.CSSProperties
}

type NativeAttributes = Omit<React.HTMLAttributes<HTMLElement>, keyof Props>
export type AgendaItemProps = Props & NativeAttributes

export const AgendaItem: React.FC<AgendaItemProps> = ({
  className,
  event,
  onClickEvent,
  style,
}: AgendaItemProps) => {
  const formattedStart = useMemo(
    () => format(new Date(event.startDate as string), 'h:mma'),
    [event.startDate]
  )

  const formattedEnd = useMemo(
    () => format(new Date(event.endDate as string), 'h:mma'),
    [event.endDate]
  )

  const eventTime = useMemo(() => {
    // using – (en dash) character directly rather than &ndash; to avoid using dangerouslySetInnerHTML
    return event.customTime ?? `${formattedStart} – ${formattedEnd}`
  }, [event.customTime, formattedEnd, formattedStart])

  const title = useMemo(
    () =>
      `${capitalize(event.type ?? 'event')}: ${event.title} (${
        event.teamName ?? 'Personal Calendar'
      })`,
    [event.teamName, event.title, event.type]
  )

  const handleOnClick = useCallback(() => {
    onClickEvent?.(event.id as string)
  }, [event.id, onClickEvent])

  return (
    <Container
      aria-label={title}
      className={className}
      data-event-type={event.type ?? 'event'}
      onClick={handleOnClick}
      style={style}
      title={title}
    >
      <EventTitleContainer>
        <EventTitleAndCalendar>
          <EventTitle>{event.title}</EventTitle>
          <CalendarName>{event.teamName ?? 'Personal Calendar'}</CalendarName>
        </EventTitleAndCalendar>
        <EventType>{event.type}</EventType>
      </EventTitleContainer>
      <EventTime>{eventTime}</EventTime>
      <Location>{event.location}</Location>
    </Container>
  )
}

const TruncatedText = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const Container = styled.div`
  cursor: pointer;
  padding: 10px 0 10px 10px;
  transition: background-color 160ms ease-in-out;
  &:hover,
  &:active {
    background-color: ${Colors.CATSKILL_WHITE};
  }
  &:not(:first-of-type) {
    border-top: 1px solid ${Colors.ALTO};
  }
`
const EventTitleContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`
const EventTitleAndCalendar = styled.div`
  flex: 1 1 auto;
  margin-right: 8px;
  overflow: hidden;
`
const EventTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  ${TruncatedText};
`
const CalendarName = styled.div`
  color: ${Colors.PUNCH};
  font-size: 12px;
`
const EventType = styled.div<{ eventType?: 'event' | 'game' }>`
  align-items: center;
  background-color: ${Colors.DUSTY_GRAY};
  color: ${Colors.WHITE};
  display: flex;
  flex: 0 0 46px;
  font-size: 10px;
  font-weight: 700;
  height: 22px;
  justify-content: center;
  text-transform: uppercase;

  [data-event-type='event'] & {
    background-color: ${Colors.HAVELOCK_BLUE};
  }
  [data-event-type='game'] & {
    background-color: ${Colors.PUNCH};
  }
`

const EventTime = styled.div`
  color: ${Colors.MINE_SHAFT};
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
`
const Location = styled.div`
  color: ${Colors.SHUTTLE_GRAY};
  font-size: 14px;
  ${TruncatedText};
`
export default AgendaItem
