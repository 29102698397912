import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts'
import { Fragment } from 'react'

import { Colors, humanNumber, readableNumber } from '@sportsyou/core'

export interface ActivityGraphDataPoint {
  name: string
  [key: string]: number | string
}

export interface ActivityGraphProps {
  data?: ActivityGraphDataPoint[]
  keys?: string[]
  colors?: string[]
}

export default function ActivityGraph(props: ActivityGraphProps) {
  const { data, keys, colors } = props

  const tooltipFormatter = (value: any, name: any) => {
    return [`${readableNumber(value)} ${name}`]
  }

  return (
    <ResponsiveContainer>
      <BarChart width={150} height={0} data={data}>
        <XAxis
          axisLine={false}
          dataKey='name'
          fontFamily='Figtree'
          fontSize={14}
          fontWeight={500}
          tickLine={false}
          type='category'
          tickMargin={10}
        />
        <YAxis
          axisLine={false}
          fontFamily='Figtree'
          fontSize={14}
          fontWeight={500}
          tickLine={false}
          type='number'
          tickFormatter={humanNumber}
        />
        <Tooltip
          cursor={false}
          formatter={tooltipFormatter}
          isAnimationActive={false}
        />

        {keys?.map((key, index) => (
          <Fragment key={key}>
            <Bar
              barSize={10}
              dataKey={key}
              fill={colors?.[index] ?? Colors.PUNCH}
              radius={10}
            />
          </Fragment>
        ))}
      </BarChart>
    </ResponsiveContainer>
  )
}
