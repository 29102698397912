import { StrictMode } from 'react'
import * as ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'

import { DialogProvider, ToastProvider } from '@sportsyou/react-dom-ui'
// import { AppFeaturesProvider } from './app/hooks/useAppFeatures'
import { GlobalStyle } from './app/styles/global-styles'

import { Colors } from '@sportsyou/core'
import { configureAppStore } from './app/store/configureStore'
import { environment } from './app/environments/environment'

import App from './app/App'

const INTERCOM_APP_ID = environment.intercomKey ?? ''
const INTERCOM_AUTOBOOT_PROPS = {
  backgroundColor: Colors.PUNCH,
}

const store = configureAppStore()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <HelmetProvider>
      <StrictMode>
        <IntercomProvider
          appId={INTERCOM_APP_ID}
          autoBoot
          autoBootProps={INTERCOM_AUTOBOOT_PROPS}
        >
          <BrowserRouter>
            <DialogProvider>
              <ToastProvider placement='bottomStart'>
                <App />
                <GlobalStyle />
              </ToastProvider>
            </DialogProvider>
          </BrowserRouter>
        </IntercomProvider>
      </StrictMode>
    </HelmetProvider>
  </Provider>
)
