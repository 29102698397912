import * as React from 'react'
import type { SVGProps } from 'react'
const User = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 28.26 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M14.13 18.05A9 9 0 0 1 7.75 2.64a9 9 0 1 1 6.38 15.41zm0-15.82a6.79 6.79 0 1 0 4.81 2 6.78 6.78 0 0 0-4.81-2zM27.16 32H1.1A1.1 1.1 0 0 1 0 30.9a9.39 9.39 0 0 1 9.38-9.38h9.5a9.38 9.38 0 0 1 9.38 9.38 1.1 1.1 0 0 1-1.1 1.1zM2.29 29.79H26a7.19 7.19 0 0 0-7.09-6.07H9.38a7.19 7.19 0 0 0-7.09 6.07z' />
  </svg>
)
export default User
