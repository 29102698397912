import {
  Children,
  CSSProperties,
  FC,
  HTMLAttributes,
  isValidElement,
  PropsWithChildren,
  useMemo,
} from 'react'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'

import CardBody from './card-body'
import CardHeader from './card-header'
import CardFooter from './card-footer'

import { getChildrenFromReactNode, getPropsFromReactNode } from '../../utils'
import { BORDER_RADIUS } from '../../theme'

interface Props {
  className?: string
  /** Styles for card content
   * @deprecated attach styles directly to <Card.Body> with `style` prop
   */
  contentStyles?: CSSProperties
  /** Background color for card */
  fill?: string
  /** Adds hover styles onHover */
  hoverable?: boolean
  style?: CSSProperties
}

type NativeAttributes = Omit<HTMLAttributes<HTMLElement>, keyof Props>
export type CardProps = NativeAttributes & Props

/**
 * Cards are used to group together similar content or create smaller
 * components such as Widgets
 */
export const Card: FC<PropsWithChildren<CardProps>> = ({
  children,
  className,
  contentStyles,
  fill = Colors.WHITE,
  hoverable = false,
  style,
  ...props
}) => {
  const headerNode = useMemo(
    () =>
      Children.map(children, (item) => {
        if (!isValidElement(item)) return null
        if (item.type === CardHeader) {
          return item
        }
        return null
      }),
    [children]
  )

  const bodyNode = useMemo(
    () =>
      Children.map(children, (item) => {
        if (!isValidElement(item)) return null
        if (item.type === CardBody) {
          return item
        }
        return null
      }),
    [children]
  )

  const footerNode = useMemo(
    () =>
      Children.map(children, (item) => {
        if (!isValidElement(item)) return null
        if (item.type === CardFooter) {
          return item
        }
        return null
      }),
    [children]
  )

  return (
    <Container
      className={className}
      $fill={fill}
      hoverable={hoverable}
      style={style}
      {...props}
    >
      {headerNode &&
        headerNode.map((node, index) => (
          <CardHeader key={index} {...getPropsFromReactNode(node)}>
            {getChildrenFromReactNode(node)}
          </CardHeader>
        ))}

      {bodyNode &&
        bodyNode.map((node, index) => (
          <CardBody key={index} {...getPropsFromReactNode(node)}>
            {getChildrenFromReactNode(node)}
          </CardBody>
        ))}

      {footerNode &&
        footerNode.map((node, index) => (
          <CardFooter key={index} {...getPropsFromReactNode(node)}>
            {getChildrenFromReactNode(node)}
          </CardFooter>
        ))}
    </Container>
  )
}

const Container = styled.div<{ $fill?: string } & Pick<CardProps, 'hoverable'>>`
  background-color: ${({ $fill }) => $fill};
  border: 1px solid ${Colors.ALTO};
  border-radius: ${BORDER_RADIUS};
  box-shadow: none;
  box-sizing: border-box;
  color: ${Colors.MINE_SHAFT};
  height: auto;
  margin: 0;
  min-width: 120px;
  padding: 0;
  width: auto;
  transition: ${({ hoverable }) =>
    hoverable ? 'box-shadow 300ms ease' : 'none'};

  &:hover {
    box-shadow: ${({ hoverable }) =>
      hoverable ? '0 6px 14px -2px rgb(0 0 0 / 20%)' : 'none'};
  }
`

export default Card
