import Icon from '../icon'

interface Props {
  fill?: string
  size?: number
  style?: React.CSSProperties
  visible: boolean
}

export type DropdownIconsProps = Props

const DropdownIcon: React.FC<Props> = ({
  fill,
  size,
  style,
  visible,
}: Props) => {
  return (
    <Icon
      fill={fill}
      name='ChevronDown'
      size={size}
      style={{
        transition: 'transform 300ms ease',
        transform: `rotate(${visible ? '180' : '0'}deg)`,
        ...style,
      }}
    />
  )
}

export default DropdownIcon
