import * as React from 'react'
import type { SVGProps } from 'react'
const NavCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M24.68 3.61h-1.91V3a1 1 0 0 0-2 0v.63H11.2V3a1 1 0 0 0-2 0v.63H7.32A5.31 5.31 0 0 0 2 8.9v15.81A5.31 5.31 0 0 0 7.32 30h17.36A5.31 5.31 0 0 0 30 24.71V8.9a5.31 5.31 0 0 0-5.32-5.29zM7.32 5.56h1.91v.64a1 1 0 0 0 2 0v-.64h9.6v.64a1 1 0 0 0 2 0v-.64h1.91A3.35 3.35 0 0 1 28 8.9v1.9H4V8.9a3.35 3.35 0 0 1 3.32-3.34zm17.36 22.49H7.32A3.35 3.35 0 0 1 4 24.71v-12h24v12a3.35 3.35 0 0 1-3.32 3.34z' />
    <path d='M21.46 23h-.95v-6.19a1 1 0 0 0-.59-.9 1 1 0 0 0-1.06.17L16.75 18a1 1 0 0 0-.07 1.38 1 1 0 0 0 1.39.07l.47-.42v4h-1a1 1 0 1 0 0 1.95h3.88a1 1 0 1 0 0-1.95zM12.34 15.83a2.84 2.84 0 0 0-2.74 2.08 1 1 0 0 0 .67 1.21 1 1 0 0 0 1.21-.66.91.91 0 0 1 .86-.68.93.93 0 0 1 .93.92.73.73 0 0 1-.73.72 1 1 0 1 0 0 2 .73.73 0 0 1 .73.72.93.93 0 0 1-.93.92.91.91 0 0 1-.86-.68 1 1 0 0 0-1.88.54A2.84 2.84 0 0 0 12.34 25a2.88 2.88 0 0 0 2.89-2.87 2.66 2.66 0 0 0-.61-1.7 2.66 2.66 0 0 0 .61-1.7 2.88 2.88 0 0 0-2.89-2.9z' />
  </svg>
)
export default NavCalendar
