// onFocus={(e: React.FocusEvent<HTMLTextAreaElement, Element>) =>
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { Colors, lighten } from '@sportsyou/core'

import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_SIZE_SM,
  LINE_HEIGHT,
} from '../../theme'
import { mergeRefs } from '../../utils'

interface Props {
  /** Control of the textarea grows with content */
  autoGrow?: boolean
  className?: string
  containerStyle?: React.CSSProperties
  defaultValue?: string
  disabled?: boolean
  disableFocusStyles?: boolean
  hideBorder?: boolean
  /** Number of rows the textarea initially renders with */
  initialRows?: number
  label?: string
  labelStyle?: React.CSSProperties
  maxLength?: number
  /** Maximum number of rows the textarea can grow with autoGrow prop */
  maximumRows?: number
  /** Minimum number of rows the textarea can shrink with autoGrow prop */
  minimumRows?: number
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  onKeyPress?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void
  placeholder?: string
  showValidationMessage?: boolean
  status?: 'error' | 'success'
  textareaStyle?: React.CSSProperties
  validationMessage?: string
  validationStyle?: React.CSSProperties
  value?: string
}

type NativeAttributes = Omit<React.TextareaHTMLAttributes<any>, keyof Props>
export type TextAreaProps = Props & NativeAttributes

export const TextArea = React.forwardRef<
  HTMLTextAreaElement,
  React.PropsWithRef<TextAreaProps>
>(
  (
    {
      autoGrow = false,
      className,
      containerStyle,
      defaultValue,
      disableFocusStyles,
      disabled,
      hideBorder = false,
      id,
      initialRows = 3,
      label,
      labelStyle,
      maxLength,
      maximumRows = 10,
      minimumRows = 3,
      name,
      onBlur,
      onChange,
      onFocus,
      onKeyPress,
      placeholder,
      showValidationMessage,
      status,
      textareaStyle,
      validationMessage,
      validationStyle,
      value = '',
    }: TextAreaProps,
    ref: React.Ref<HTMLTextAreaElement>
  ) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null)

    const [currentState, setCurrentState] = useState<string>()
    const [textareaValue, setTextareaValue] = useState(value)

    const [rows, setRows] = useState<number>(initialRows)
    const [maxRows, setMaxRows] = useState<number>(maximumRows)
    const [minRows, setMinRows] = useState<number>(minimumRows)

    useEffect(() => {
      if (!textAreaRef.current || !value) return
      // If the value of the textarea is taller than the allowed amount of  rows,
      // update the rows accordingly.
      const initialRows = ~~(
        textAreaRef.current.scrollHeight /
        (FONT_SIZE * LINE_HEIGHT)
      )
      if (initialRows === rows) return
      setRows(initialRows < maxRows ? initialRows : maxRows)
    }, [])

    useEffect(() => {
      setMaxRows(maxRows)
      setMinRows(minRows)
    }, [maxRows, minRows])

    useEffect(() => {
      setTextareaValue(value)
    }, [value])

    const updateRows = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const previousRows = event.target.rows
      event.target.rows = minRows // reset textarea rows

      const _currentRows = ~~(
        event.target.scrollHeight /
        (FONT_SIZE * LINE_HEIGHT)
      )

      if (_currentRows === previousRows) {
        event.target.rows = _currentRows
      }

      if (_currentRows >= maxRows) {
        event.target.rows = maxRows
        event.target.scrollTop = event.target.scrollHeight
      }

      setRows(_currentRows < maxRows ? _currentRows : maxRows)
    }

    const handleOnStateChange = (
      event: React.FocusEvent<HTMLTextAreaElement>,
      state?: string
    ) => {
      setCurrentState(state)
      if (state == 'focus') {
        onFocus && onFocus(event)
      } else {
        onBlur && onBlur(event)
      }
    }

    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange && onChange(event)
      autoGrow && updateRows(event)
      setTextareaValue(event.target.value)
    }

    return (
      <Container
        className={className}
        data-state={currentState}
        data-status={status}
        style={containerStyle}
      >
        <Label disableFocusStyles={disableFocusStyles} hideBorder={hideBorder}>
          {label && <LabelText style={labelStyle}>{label}</LabelText>}
          <TextAreaContainer>
            <TA
              defaultValue={defaultValue}
              disabled={disabled}
              id={id}
              maxLength={maxLength}
              name={name}
              onBlur={(e) => handleOnStateChange(e)}
              onChange={handleOnChange}
              onFocus={(e) => handleOnStateChange(e, 'focus')}
              onKeyPress={onKeyPress}
              placeholder={placeholder}
              // ref && ref.current = node;
              ref={ref ? mergeRefs(textAreaRef, ref) : textAreaRef}
              rows={rows}
              style={textareaStyle}
              value={textareaValue}
            ></TA>
          </TextAreaContainer>
        </Label>

        {showValidationMessage && validationMessage && (
          <ValidationMessage style={validationStyle}>
            {validationMessage}
          </ValidationMessage>
        )}
      </Container>
    )
  }
)

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  max-width: 100%;
  position: relative;
`
const Label = styled.label<
  Pick<TextAreaProps, 'disableFocusStyles' | 'hideBorder'>
>`
  background-color: ${Colors.WHITE};
  border: ${({ hideBorder }) =>
    hideBorder ? 'none' : `1px solid ${Colors.ALTO}`};
  border-radius: ${BORDER_RADIUS};
  box-sizing: border-box;
  outline: none;

  [data-state='focus'] > & {
    box-shadow: ${({ disableFocusStyles }) =>
      disableFocusStyles
        ? 'none'
        : `0 0 0 2px ${lighten(Colors.HAVELOCK_BLUE, 40)}`};
  }

  [data-status='success'] > & {
    border-color: ${Colors.MOUNTAIN_MEADOW};
  }
  [data-status='success'][data-state='focus'] > & {
    box-shadow: ${({ disableFocusStyles }) =>
      disableFocusStyles
        ? 'none'
        : `0 0 0 2px ${lighten(Colors.MOUNTAIN_MEADOW, 40)}`};
  }

  [data-status='error'] > & {
    border-color: ${Colors.MONZA};
  }
  [data-status='error'][data-state='focus'] > & {
    box-shadow: 0 0 0 2px ${lighten(Colors.MONZA, 40)};
  }
`
const LabelText = styled.div`
  color: ${Colors.PUNCH};
  font-size: ${FONT_SIZE_SM}px;
  font-weight: 700;
  margin: 6px 10px 0;
  text-transform: uppercase;
`
const TextAreaContainer = styled.div`
  align-items: center;
  display: flex;
`
const TA = styled.textarea`
  align-self: stretch;
  background: none;
  border: none;
  box-sizing: border-box;
  color: ${Colors.MINE_SHAFT};
  flex: 1 1 auto;
  font-family: inherit;
  font-size: ${FONT_SIZE}px;
  font-weight: 400;
  line-height: ${LINE_HEIGHT};
  max-width: 100%;
  min-width: 300px;
  outline: none;
  padding: 6px 10px;
  resize: none;
`
const ValidationMessage = styled.div`
  font-size: ${FONT_SIZE_SM}px;
  font-weight: 700;
  margin: 8px 10px 0;
  text-transform: uppercase;

  [data-status='success'] & {
    color: ${Colors.MOUNTAIN_MEADOW};
  }
  [data-status='error'] & {
    color: ${Colors.MONZA};
  }
`

TextArea.displayName = 'TextArea'

export default TextArea
