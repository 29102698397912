import * as React from 'react'
import type { SVGProps } from 'react'
const Gear = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <g>
      <path d='M16 9.92A6.08 6.08 0 1 0 22.08 16 6.09 6.09 0 0 0 16 9.92zm0 9.93A3.85 3.85 0 1 1 19.84 16 3.86 3.86 0 0 1 16 19.85z' />
      <path d='M31.05 12.86a3.31 3.31 0 0 0-2.41-.95.88.88 0 0 1-.83-.56l-.12-.29a1.07 1.07 0 0 1 .23-1.18 3.25 3.25 0 0 0 0-4.6l-1.2-1.19a3.25 3.25 0 0 0-4.6 0 1.07 1.07 0 0 1-1.19.22l-.27-.11a.93.93 0 0 1-.57-1A3.24 3.24 0 0 0 16.85 0h-1.69a3.28 3.28 0 0 0-3.25 3.37.9.9 0 0 1-.57.84l-.28.11a1.07 1.07 0 0 1-1.18-.23 3.24 3.24 0 0 0-4.59 0l-1.2 1.19a3.25 3.25 0 0 0 0 4.6 1.07 1.07 0 0 1 .23 1.18l-.12.28a1 1 0 0 1-1 .57A3.25 3.25 0 0 0 0 15.16v1.69a3.28 3.28 0 0 0 3.37 3.24.88.88 0 0 1 .83.56l.12.29a1.07 1.07 0 0 1-.23 1.18 3.25 3.25 0 0 0 0 4.6l1.19 1.19a3.25 3.25 0 0 0 4.6 0 1.06 1.06 0 0 1 1.17-.23l.29.12a.94.94 0 0 1 .57.95A3.26 3.26 0 0 0 15.16 32h1.68a3.28 3.28 0 0 0 3.25-3.36.89.89 0 0 1 .56-.84l.29-.12a1.07 1.07 0 0 1 1.18.23 3.24 3.24 0 0 0 2.3.95 3.19 3.19 0 0 0 2.29-.95l1.2-1.19a3.25 3.25 0 0 0 0-4.6 1.07 1.07 0 0 1-.23-1.18l.12-.28a.9.9 0 0 1 .95-.57A3.25 3.25 0 0 0 32 16.85v-1.69a3.24 3.24 0 0 0-.95-2.3zm-1.28 4a1 1 0 0 1-1.14 1 3.11 3.11 0 0 0-2.91 2l-.09.23a3.29 3.29 0 0 0 .7 3.63 1 1 0 0 1 0 1.44l-1.19 1.19a1 1 0 0 1-.72.3 1 1 0 0 1-.72-.3 3.28 3.28 0 0 0-3.64-.7l-.23.09a3.16 3.16 0 0 0-2 3 1 1 0 0 1-1 1h-1.67a1.05 1.05 0 0 1-1-1.12 3.12 3.12 0 0 0-2-2.93l-.24-.1a3.47 3.47 0 0 0-1.3-.26 3.31 3.31 0 0 0-2.33 1 1 1 0 0 1-1.44 0l-1.18-1.19a1 1 0 0 1 0-1.44 3.29 3.29 0 0 0 .71-3.62l-.1-.25a3.16 3.16 0 0 0-3-2 1.05 1.05 0 0 1-1-1v-1.67a1.05 1.05 0 0 1 1.12-1 3.11 3.11 0 0 0 2.91-2l.1-.23a3.29 3.29 0 0 0-.74-3.63 1 1 0 0 1 0-1.44l1.19-1.19a1 1 0 0 1 1.44 0 3.28 3.28 0 0 0 3.61.71l.26-.1a3.16 3.16 0 0 0 2-3 1 1 0 0 1 1-1h1.69a1 1 0 0 1 .71.3 1.07 1.07 0 0 1 .3.83 3.1 3.1 0 0 0 2 2.91l.25.11a3.3 3.3 0 0 0 3.63-.71 1 1 0 0 1 1.44 0l1.19 1.19a1 1 0 0 1 0 1.44 3.28 3.28 0 0 0-.72 3.62l.1.25a3.15 3.15 0 0 0 3 2 1 1 0 0 1 .74.3 1 1 0 0 1 .3.72z' />
    </g>
  </svg>
)
export default Gear
