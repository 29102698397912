import * as React from 'react'
import type { SVGProps } from 'react'
const XLg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M19.34 16l12-12a2.37 2.37 0 0 0 0-3.34A2.39 2.39 0 0 0 28 .68l-12 12-12-12a2.39 2.39 0 0 0-3.31 0A2.37 2.37 0 0 0 .69 4l12 12-12 12a2.37 2.37 0 0 0 0 3.34 2.42 2.42 0 0 0 3.34 0l12-12 12 12A2.36 2.36 0 0 0 31.31 28z' />
  </svg>
)
export default XLg
