import * as React from 'react'
import type { SVGProps } from 'react'
const Folder = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M30.37 29.67H1.63A1.63 1.63 0 0 1 0 28V4a1.63 1.63 0 0 1 1.63-1.67h15a1 1 0 0 1 .73.32l3 3.25h9.94A1.63 1.63 0 0 1 32 7.53V28a1.63 1.63 0 0 1-1.63 1.67zM2 4.33v23.34h28V7.9H20a1 1 0 0 1-.73-.32l-3-3.25zM30.37 7.9z' />
  </svg>
)
export default Folder
