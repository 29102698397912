import React, { CSSProperties } from 'react'
import styled from 'styled-components'

import { checkColorValue, Colors } from '@sportsyou/core'

interface Props {
  className?: string
  fill?: string
  index?: number
  maxWidth?: number
  outlined?: boolean
  style?: CSSProperties
  type?: string
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type TagProps = Props & NativeAttributes

const Tag: React.FC<React.PropsWithChildren<TagProps>> = ({
  children,
  className = '',
  fill,
  index = 0,
  maxWidth,
  outlined = false,
  style,
  type,
  ...props
}: React.PropsWithChildren<TagProps>) => {
  return (
    <Container
      $maxWidth={maxWidth}
      className={className}
      fill={fill}
      outlined={outlined}
      index={index}
      style={style}
      {...props}
    >
      {maxWidth ? (
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {children}
        </div>
      ) : (
        children
      )}
    </Container>
  )
}

const Container = styled.span<TagProps & { $maxWidth?: number }>`
  align-items: center;
  border: ${({ fill, outlined }) =>
    outlined ? `1px solid ${fill ?? Colors.DUSTY_GRAY}` : 'none'};
  background-color: ${({ fill, outlined }) =>
    outlined ? Colors.WHITE : fill ?? Colors.DUSTY_GRAY};

  border-radius: 0.4em;
  box-sizing: border-box;

  color: ${({ fill, outlined }) =>
    outlined
      ? fill ?? Colors.DUSTY_GRAY
      : checkColorValue(fill ?? Colors.DUSTY_GRAY)};

  display: inline-flex;
  font-size: 0.8rem;

  height: 1.5em;
  justify-content: center;

  line-height: 1em;

  margin: 0;
  margin-left: ${({ index = 0 }) => (index > 0 ? '4px' : undefined)};

  max-width ${({ $maxWidth }) => ($maxWidth ? `${$maxWidth}px` : undefined)};

  padding-left: 0.6em;
  padding-right: 0.6em;

  user-select: none;
`

export default Tag
