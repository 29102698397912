import { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'
import { Sponsor } from '@sportsyou/api'
import {
  Avatar,
  BORDER_RADIUS,
  FadeInContainer,
  Icon,
  Link,
  useDialog,
  Well,
} from '@sportsyou/react-dom-ui'
import {
  ExtendedFile,
  ExtendedUpload,
  UploaderProps,
  useUploader,
} from '@sportsyou/react-hooks'
import ItemRow from './AssetRow'
import useCampaignPortal from '../UseCampaignPortal'

interface AssetManagerSponsorRowProps {
  sponsor: Sponsor
}

export default function AssetManagerSponsorRow(
  props: AssetManagerSponsorRowProps
) {
  const { sponsor } = props

  const { sendConfirm } = useDialog()
  const { loadSponsorAssets, renameSponsorAsset, currentAssets, deleteAsset } =
    useCampaignPortal()
  const uploader = useUploader.useUploader()

  const [isDragging, setIsDragging] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [uploads, setUploads] = useState<ExtendedUpload[]>([])

  const fileInputRef = useRef<HTMLInputElement>(null)

  const updateUploads = useCallback(
    (newUploads?: ExtendedUpload[], failedUpload?: ExtendedUpload) => {
      setUploads((prevUploads) => {
        let _uploads: ExtendedUpload[] = [...(prevUploads ?? [])]
        if (newUploads && newUploads.length > 0) {
          newUploads.forEach((newUpload) => {
            const index = _uploads.findIndex(
              (_upload) => _upload.fileName === newUpload.fileName
            )
            if (index > -1) {
              _uploads[index] = {
                ..._uploads[index],
                id: newUpload.id,
                progress: newUpload.progress,
              }
            } else {
              _uploads.push(newUpload)
            }
          })
        }

        _uploads = _uploads.filter((upload) => {
          const uploadProgress = upload?.progress?.clientProgress ?? 0
          const isDone = uploadProgress >= 100
          return !isDone
        })

        if (failedUpload) {
          _uploads = _uploads.filter((up) => up.id !== failedUpload.id)
        }

        return _uploads
      })
    },
    []
  )

  const onUploaderUploadStart = useCallback(
    (_uploads: ExtendedUpload[], currentUpload: ExtendedUpload): void => {
      updateUploads(_uploads)
    },
    [updateUploads]
  )

  const onUploaderUploadProgress = useCallback(
    (_uploads: ExtendedUpload[], currentUpload: ExtendedUpload): void => {
      updateUploads([currentUpload])
    },
    [updateUploads]
  )

  const onUploaderUploadDone = useCallback(
    async (
      _uploads: ExtendedUpload[],
      currentUpload: ExtendedUpload
    ): Promise<void> => {
      updateUploads([currentUpload])
      loadSponsorAssets(sponsor.id as string)
    },
    [loadSponsorAssets, sponsor.id, updateUploads]
  )

  const onUploaderComplete = useCallback(
    async (_uploads: ExtendedUpload[], currentUpload: ExtendedUpload) => {
      loadSponsorAssets(sponsor.id as string)
    },
    [loadSponsorAssets, sponsor.id]
  )

  const onUploaderCancel = useCallback(
    (_uploads: ExtendedUpload[], cancelledUpload: ExtendedUpload): void => {
      updateUploads(undefined, cancelledUpload)
      deleteAsset(cancelledUpload.id as string)
    },
    [deleteAsset, updateUploads]
  )

  const onUploaderError = useCallback(
    async (
      _uploads: ExtendedUpload[],
      cancelledUpload: ExtendedUpload
    ): Promise<void> => {
      const { progress } = cancelledUpload
      const failedStep = progress?.clientProgress !== 100 ? 'upload' : 'process'
      updateUploads(undefined, cancelledUpload)
      await deleteAsset(cancelledUpload.id as string)
    },
    [deleteAsset, updateUploads]
  )

  const upload = useCallback(
    async (sponsorId: string, files: ExtendedFile[]): Promise<void> => {
      uploader.reset()
      const uploadPreviews: any[] = []
      files.forEach((file) => {
        const uploadParams: UploaderProps = {
          enableChunk: false,
          files: [file],
          onCancel: onUploaderCancel,
          onComplete: onUploaderComplete,
          onError: onUploaderError,
          onProgress: onUploaderUploadProgress,
          onUploadDone: onUploaderUploadDone,
          onUploadStart: onUploaderUploadStart,
          shouldCreateHLS: true,
          uploadType: 'campaign',
          sponsorInfo: {
            assetName: file.name,
            sponsorId,
          },
        }
        uploader.create(uploadParams)
        const uploadPreview = {
          contentType: file.type,
          fileName: file.name,
          viewUrl: /^image\/(jp?eg|png|gif|webp)/.test(file.type ?? '')
            ? window.URL.createObjectURL(file as Blob)
            : undefined,
          file,
          progress: {
            percentDone: 0,
            uploadProgress: 0,
          },
          uploaderProp: uploadParams,
        }
        uploadPreviews.push(uploadPreview)
      })
      setUploads(uploadPreviews)
    },
    [
      onUploaderComplete,
      onUploaderCancel,
      onUploaderError,
      onUploaderUploadDone,
      onUploaderUploadProgress,
      onUploaderUploadStart,
      uploader,
    ]
  )

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      setIsDragging(true)
    },
    []
  )

  const handleDragLeave = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      setIsDragging(false)
    },
    []
  )

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      setIsDragging(false)
      const { files } = event.dataTransfer
      upload(sponsor.id as string, Array.from(files))
    },
    [sponsor.id, upload]
  )

  const handleOnClickFileInput = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }, [])

  const handleOnClickDeleteAsset = useCallback(
    async (assetId: string) => {
      sendConfirm({
        cancelText: 'Cancel',
        confirmText: 'Delete',
        message: 'Are you sure you want to delete this asset?',
        title: 'Delete asset?',
        onConfirm: async () => {
          await deleteAsset(assetId)
          loadSponsorAssets(sponsor.id as string)
        },
      })
    },
    [sendConfirm, deleteAsset, loadSponsorAssets, sponsor.id]
  )

  const handleOnClickHeader = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen)
  }, [])

  const handleOnSubmitSetAssetName = useCallback(
    async (assetId: string, assetName: string) => {
      await renameSponsorAsset(assetId, assetName)
      loadSponsorAssets(sponsor.id as string)
    },
    [loadSponsorAssets, renameSponsorAsset, sponsor.id]
  )

  useEffect(() => {
    loadSponsorAssets(sponsor.id as string)
  }, [loadSponsorAssets, sponsor.id])

  return (
    <SponsorWell key={sponsor.id}>
      <Header onClick={handleOnClickHeader}>
        <SponsorName>
          <Avatar name={sponsor.name as string} diameter={20} />
          {sponsor.name}
        </SponsorName>
        <Icon
          name={isOpen ? 'ChevronDown' : 'ChevronUp'}
          size={12}
          color={Colors.DUSTY_GRAY}
        />
      </Header>
      {isOpen ? (
        <FadeInContainer>
          <AssetList>
            {currentAssets?.length ? (
              <AssetListHeader>
                <AssetListHeaderCell>Asset image</AssetListHeaderCell>
                <AssetListHeaderCell>Name</AssetListHeaderCell>
                <AssetListHeaderCell>Last Modified</AssetListHeaderCell>
              </AssetListHeader>
            ) : null}
            {currentAssets?.map((asset, index) => (
              <ItemRow
                asset={asset}
                key={asset.id ?? index}
                onClickDelete={handleOnClickDeleteAsset}
                onSubmitSetName={handleOnSubmitSetAssetName}
              />
            ))}

            {uploads?.map((upload) => (
              <ItemRow
                asset={upload}
                key={upload.id}
                onClickDelete={handleOnClickDeleteAsset}
                onSubmitSetName={handleOnSubmitSetAssetName}
                uploadProgress={upload.progress}
              />
            ))}

            <UploadArea
              isDragging={isDragging}
              onClick={handleOnClickFileInput}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <input
                accept={'image/*,.heic,.heif,.avif'}
                hidden
                multiple
                onChange={(e) => {
                  if (e.target.files) {
                    upload(sponsor.id as string, Array.from(e.target.files))
                  }
                  e.target.value = ''
                }}
                ref={fileInputRef}
                title={'Upload Media'}
                type={'file'}
              />
              <UploadAreaInstructions>
                <UploadAreaIcon>
                  <Icon name='Plus' size={12} color={Colors.DUSTY_GRAY} />
                </UploadAreaIcon>
                <div>
                  Drag and drop your file here, or <Link>click to select</Link>
                </div>
              </UploadAreaInstructions>
            </UploadArea>
          </AssetList>
        </FadeInContainer>
      ) : null}
    </SponsorWell>
  )
}

const SponsorWell = styled(Well)`
  background-color: rgba(221, 221, 221, 0.15);
`

const AssetList = styled(Well)`
  background-color: ${Colors.WHITE};
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;

  & > * {
    border-bottom: 1px solid ${Colors.MYSTIC};
    padding-bottom: 10px;
  }
`

const AssetListHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 10px 0;
`

const AssetListHeaderCell = styled.div`
  font-size: 14px;
  font-weight: bold;

  &:first-child {
    width: 200px;
  }

  &:nth-child(2) {
    flex: 1;
  }

  &:last-child {
    width: 200px;
  }
`

const Header = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const SponsorName = styled(Header)`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  gap: 10px;
`

const UploadArea = styled.div<{ isDragging?: boolean }>`
  border-radius: ${BORDER_RADIUS};
  border: 1px dashed
    ${({ isDragging }) => (isDragging ? Colors.HAVELOCK_BLUE : Colors.ALTO)};
  cursor: pointer;
  display: inline-flex;
  padding: 20px;
`

const UploadAreaInstructions = styled.div`
  align-items: center;
  color: ${Colors.DUSTY_GRAY};
  display: flex;
  flex-direction: row;
  font-size: 16px;
  gap: 10px;
`

const UploadAreaIcon = styled.div`
  align-items: center;
  background-color: ${Colors.ALTO};
  border-radius: 30px;
  display: inline-flex;
  height: 30px;
  justify-content: center;
  width: 30px;
`
