import * as React from 'react'
import type { SVGProps } from 'react'
const Checkmark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 33 33'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M13.0174 29.6803c-.2932-.0025-.5829-.0637-.8521-.18-.2691-.1164-.5122-.2855-.7149-.4974L1.13902 18.4287c-.330951-.427-.4927-.9611-.454256-1.5.038444-.5389.274378-1.0446.662596-1.4203.38821-.3757.90141-.5949 1.44125-.6157.53984-.0207 1.06835.1584 1.48427.5032l8.78492 9.0276L29.1416 3.72984c.427-.33095.9611-.4927 1.5-.45426.5389.03845 1.0446.27438 1.4203.6626.3757.38821.5949.90141.6157 1.44125.0207.53984-.1584 1.06835-.5032 1.48427L14.5135 29.104c-.4065.3767-.942.5829-1.4961.5763Z' />
  </svg>
)
export default Checkmark
