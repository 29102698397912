import * as React from 'react'
import type { SVGProps } from 'react'
const FabBubble = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M11.87 26a.67.67 0 0 1-.31-.07.69.69 0 0 1-.39-.63v-3.43H9.8a3.8 3.8 0 0 1-3.8-3.8V9.8A3.8 3.8 0 0 1 9.8 6h12.4A3.8 3.8 0 0 1 26 9.8v8.27a3.8 3.8 0 0 1-3.8 3.8h-4.92l-5 4a.68.68 0 0 1-.41.13zM9.8 7.4a2.39 2.39 0 0 0-2.4 2.4v8.27a2.4 2.4 0 0 0 2.4 2.4h2.07a.69.69 0 0 1 .69.7v2.68l4-3.23a.68.68 0 0 1 .43-.15h5.21a2.4 2.4 0 0 0 2.4-2.4V9.8a2.39 2.39 0 0 0-2.4-2.4z' />
  </svg>
)
export default FabBubble
