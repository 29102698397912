import { Profile, Image } from '@sportsyou/api'

import { getTranscodeUrl } from './get-transcode-url'

export const getProfileAvatarImageUrl = (profile: Profile) =>
  profile.profileImage
    ? getTranscodeUrl({
        transcodeTypes: ['profileImage'],
        upload: profile.profileImage as Image[],
      })
    : ''

export default getProfileAvatarImageUrl
