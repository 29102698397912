export const pluralize = (
  count: number,
  string: string,
  pluralString?: string
) => {
  if (string?.trim() && count !== 1) {
    return pluralString || string + 's'
  }
  return string
}

export default pluralize
