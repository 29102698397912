import * as React from 'react'
import type { SVGProps } from 'react'
const Pencil = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M31 4.52L27.49 1a3.55 3.55 0 0 0-5 0L1 22.46A3.58 3.58 0 0 0 0 25v5.55A1.48 1.48 0 0 0 1.48 32H7a3.58 3.58 0 0 0 2.51-1L31 9.54a3.54 3.54 0 0 0 0-5.02zm-7 7.85L7.44 28.87A.59.59 0 0 1 7 29H3v-4a.62.62 0 0 1 .18-.42l16.5-16.5zm4.92-4.92L26 10.27 21.73 6l2.83-2.83A.58.58 0 0 1 25 3a.61.61 0 0 1 .43.17l3.47 3.48a.6.6 0 0 1-.03.8z' />
  </svg>
)
export default Pencil
