import * as React from 'react'
import type { SVGProps } from 'react'
const FileMp3 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 537.7 661.8'
    width='1em'
    height='1em'
    style={props.style}
    {...props}
  >
    <path
      fill='none'
      d='M206.8 248.2V103.4a20.74 20.74 0 0 1 20.7-20.7h248.2v-62h-455v620.4h455V268.8H227.5a20.66 20.66 0 0 1-20.7-20.6zm155.4 82.7a10.16 10.16 0 0 1 10 10.3v155.1a11.34 11.34 0 0 1-.6 3.2c1.8 15.1-11.9 30.8-32.6 36.1-22.3 5.7-43.9-2.6-48.4-18.7s9.9-33.7 32.2-39.4a52.25 52.25 0 0 1 28.8.6v-82.7l-144.8 36.2v106a8.76 8.76 0 0 1-.7 3.2c1.8 15.1-11.9 30.8-32.6 36.2-22.2 5.7-43.9-2.6-48.4-18.7s9.9-33.7 32.2-39.4a52.25 52.25 0 0 1 28.8.6v-137a10.29 10.29 0 0 1 10.3-10.3l161-40.2a10 10 0 0 1 4.8-1.1z'
    />
    <path
      fill='none'
      d='M351.6 354.1l-144.8 36.2V411l144.8-36.2v-20.7m-23.5 143.5c-11.4 2.7-19.4 9-17.9 14s12 6.9 23.4 4.2c11.4-2.7 19.4-9 17.9-14s-12-7-23.4-4.2zm-165.4 41.3c-11.4 2.7-19.4 9-17.9 14s12 6.9 23.4 4.2 19.4-9 17.9-14-12-6.9-23.4-4.2z'
    />
    <path
      fill='#5c6bc0'
      d='M186.1 382.6v137a52.21 52.21 0 0 0-28.8-.6c-22.2 5.7-36.6 23.4-32.2 39.4s26.1 24.4 48.4 18.7c20.7-5.4 34.5-21 32.6-36.2a11.43 11.43 0 0 0 .7-3.2v-106l144.8-36.2v82.7a52.21 52.21 0 0 0-28.8-.6c-22.2 5.7-36.6 23.4-32.2 39.4s26.1 24.4 48.4 18.7c20.7-5.3 34.5-21 32.6-36.1a8.66 8.66 0 0 0 .6-3.2V341.2a10.1 10.1 0 0 0-10-10.3 11.25 11.25 0 0 0-4.8 1.1l-161 40.2a10.44 10.44 0 0 0-10.3 10.4zm-17.9 174.5c-11.4 2.7-21.9.8-23.4-4.2s6.5-11.3 17.9-14 21.9-.8 23.4 4.2-6.5 11.4-17.9 14zm165.5-41.3c-11.4 2.7-21.8.8-23.4-4.2s6.5-11.3 17.9-14 21.9-.8 23.4 4.2-6.6 11.3-17.9 14zM206.8 390.3l144.8-36.2v20.7L206.8 411z'
    />
    <path
      fill='#5c6bc0'
      d='M517 82.7h-20.7v-62A20.74 20.74 0 0 0 475.6 0h-455A20.72 20.72 0 0 0 0 20.7v620.4a20.74 20.74 0 0 0 20.7 20.7h455a20.74 20.74 0 0 0 20.7-20.7V268.8H517a20.74 20.74 0 0 0 20.7-20.7V103.4A20.74 20.74 0 0 0 517 82.7zm-41.4 558.4h-455V20.7h455v62H227.5a20.74 20.74 0 0 0-20.7 20.7v144.8a20.74 20.74 0 0 0 20.7 20.7h248.2v372.2zm-52.7-479.9a25 25 0 0 1-3 12.1 22.21 22.21 0 0 1-8.6 8.6 27.5 27.5 0 0 1-13.5 3.2h-19.1V212h-16v-74.9h35a26.86 26.86 0 0 1 13.6 3.3 21.79 21.79 0 0 1 8.6 8.7 24.13 24.13 0 0 1 3 12.1zM310.7 212h-7l-21.1-53.9V212h-16v-74.9H289l18.2 46.7 18.2-46.7h22.5V212h-16.1v-53.9zm172.3-9.3a23.75 23.75 0 0 1-10.3 7.9 39.51 39.51 0 0 1-15.6 2.8 47.54 47.54 0 0 1-17.5-3 30.45 30.45 0 0 1-12-8l8.3-10.3a27 27 0 0 0 9.4 6.2 29.92 29.92 0 0 0 11.2 2.3c4.5 0 8-1 10.4-2.9a9.48 9.48 0 0 0 3.7-7.8c0-3.4-1.2-5.9-3.7-7.5s-6.2-2.4-11.4-2.4c-5.5 0-8.6 0-9.3.1v-13c.9.1 4 .1 9.3.1 4.3 0 7.6-.7 10.1-2.2a7.25 7.25 0 0 0 3.7-6.7 8.07 8.07 0 0 0-3.9-7.1c-2.6-1.7-5.9-2.5-10.1-2.5a27.77 27.77 0 0 0-10.3 2 27 27 0 0 0-8.8 5.8l-7.9-9.7a33.67 33.67 0 0 1 12.1-8 43.48 43.48 0 0 1 16.6-2.9c8.8 0 15.7 1.8 20.7 5.5a17 17 0 0 1 7.6 14.7 14.67 14.67 0 0 1-4.8 11.2 22.33 22.33 0 0 1-11.6 5.7 21.42 21.42 0 0 1 8.2 2.7 18.89 18.89 0 0 1 6.7 6.2 16.31 16.31 0 0 1 2.6 9.3 16.85 16.85 0 0 1-3.4 11.5z'
    />
    <path
      fill='#5c6bc0'
      d='M395.6 149.9h-17v22.5h17a10.47 10.47 0 0 0 7.9-3.1 12.13 12.13 0 0 0 0-16.2 10.63 10.63 0 0 0-7.9-3.2z'
    />
  </svg>
)
export default FileMp3
