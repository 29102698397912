import { createContext } from 'react'
import type { AlertProps } from '../components/alert/alert-types'
import type { BannerProps } from '../components/banner/banner-types'
import type { ConfirmProps } from '../components/confirm/confirm-types'
import type { PromptProps } from '../components/prompt'
import type { ToastProps } from '../components/toast/toast-types'

export type DialogType = 'alert' | 'banner' | 'confirm' | 'prompt' | 'toast'

type DialogAttributes = {
  isVisible?: boolean
  reset?: (type: DialogType) => void
  message: string
}

export type AlertDialogProps = AlertProps & DialogAttributes
export type BannerDialogProps = BannerProps & DialogAttributes
export type ConfirmDialogProps = ConfirmProps & DialogAttributes
export type PromptDialogProps = PromptProps & DialogAttributes
export type ToastDialogProps = ToastProps & DialogAttributes

export const initialAlertValues = {
  alert: { isVisible: false } as AlertDialogProps,
  setAlert: (_: AlertDialogProps) => console.log('Set Alert'),
  sendAlert: (_: AlertDialogProps) => console.log('Send Alert'),
  reset: (_: DialogType) => console.log('Reset Alert'),
}

export const initialBannerValues = {
  banner: { isVisible: false } as BannerDialogProps,
  setBanner: (_: BannerDialogProps) => console.log('Set Banner'),
  sendBanner: (_: BannerDialogProps) => console.log('Send Banner'),
  reset: (_: DialogType) => console.log('Reset Banner'),
}

export const initialConfirmValues = {
  confirm: {
    isVisible: false,
    title: 'Confirm',
  } as ConfirmDialogProps,
  setConfirm: (_: ConfirmDialogProps) => console.log('Set Confirm'),
  sendConfirm: (_: ConfirmDialogProps) => console.log('Send Confirm'),
  reset: (_: DialogType) => console.log('Reset Confirm'),
}

export const initialPromptValues = {
  prompt: { isVisible: false } as PromptDialogProps,
  setPrompt: (_: PromptDialogProps) => console.log('Set Prompt'),
  sendPrompt: (_: PromptDialogProps) => console.log('Send Prompt'),
  reset: (_: DialogType) => console.log('Reset Prompt'),
}

export const initialToastValues = {
  toast: { isVisible: false } as ToastDialogProps,
  setToast: (_: ToastDialogProps) => console.log('Set Toast'),
  sendToast: (_: ToastDialogProps) => console.log('Send Toast'),
  reset: (_: DialogType) => console.log('Reset Toast'),
}

export const DialogContext = createContext({
  ...initialAlertValues,
  ...initialBannerValues,
  ...initialConfirmValues,
  // ...initialPromptValues,
  ...initialToastValues,
})

export default DialogContext
