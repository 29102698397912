import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'
import { Button, Icon, Well } from '@sportsyou/react-dom-ui'

interface HomePageProps {
  canUseCampaigns: boolean
  canUsePages: boolean
  canUseSponsoredPosts: boolean
}

export default function Home(props: HomePageProps) {
  const navigate = useNavigate()

  return (
    <StyledWell>
      {props.canUseCampaigns ? (
        <Button appearance='ghost' onClick={() => navigate('/campaigns')}>
          <Icon name='Jersey' fill={Colors.HAVELOCK_BLUE} />
          Campaigns
        </Button>
      ) : null}
      {props.canUsePages ? (
        <Button appearance='ghost' onClick={() => navigate('/pages')}>
          <Icon name='Pages' fill={Colors.HAVELOCK_BLUE} />
          Pages
        </Button>
      ) : null}
      {props.canUseSponsoredPosts ? (
        <Button appearance='ghost' onClick={() => navigate('/sponsored-posts')}>
          <Icon name='Post' fill={Colors.HAVELOCK_BLUE} />
          Sponsored Posts
        </Button>
      ) : null}
    </StyledWell>
  )
}

const StyledWell = styled(Well)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 20px;
  margin-top: 20px;
  justify-content: center;

  button {
    span {
      gap: 10px;
    }
  }
`
