import { FriendRequest, Notification } from '@sportsyou/api'
import moment from 'moment-timezone'

import getTimezone from './get-timezone'

export const generateRequestText = (request: FriendRequest) => {
  if (!request.status) console.log({ request })
  let messageText = request.firstName ?? ''
  if (request.lastName) {
    messageText = `${messageText} ${request.lastName}`
  }
  if (request.status === 'accepted') {
    // messageText = `${sourceUserName} has accepted your friend request`
  }
  return messageText
}

export const generateNotificationText = (alert: Notification) => {
  const {
    // albumName,
    eventDetail,
    eventTitle,
    eventTypeId: eventType,
    message,
    sourceName: sourceUserName,
    // status,
    teamName: teamGroupName,
    teamId,
    teamType,
    type,
  } = alert

  const timezone = getTimezone()

  const dateString = moment(alert.eventStart ?? '')
    .tz(timezone)
    .format('LL')
  const timeString = moment(alert.eventStart ?? '')
    .tz(timezone)
    .format('h:mm a (z)')

  // console.log({ timezone })

  const eventDate =
    eventDetail && !eventDetail.allDay
      ? `${dateString} @ ${timeString}`
      : dateString

  const isTeamGroup = !!teamId

  let messageText: string = message ?? ''

  switch (type) {
    case 'friend':
      messageText = `${sourceUserName} made a friend request`
      break
    case 'friend-accept':
      break
    case 'team':
      messageText = `${sourceUserName} invited you to the ${teamType} ${teamGroupName}`
      break
    case 'team-setAdmin':
      break
    case 'eventCreate':
      messageText = isTeamGroup
        ? `${sourceUserName} invited you to ${teamGroupName} ${eventType} ${eventTitle}, ${eventDate}`
        : `${sourceUserName} invited you to ${eventTitle}, ${eventDate}`
      break

    default:
      messageText = message ?? ''
  }

  // if (type === 'friend-accept') {
  // } else if (type === 'friend') {
  //   messageText = `${sourceUserName} made a friend request`
  // } else if (type === 'team') {
  //   messageText = `${sourceUserName} invited you to the ${teamType} ${teamGroupName}`
  // } else if (type === 'team-setAdmin') {
  // } else if (type === 'eventCreate') {
  //   if (isTeamGroup) {
  //     messageText = `${sourceUserName} invited you to ${teamGroupName} ${eventType} ${eventTitle}, ${eventDate}`
  //   } else {
  //     messageText = `${sourceUserName} invited you to ${eventTitle}, ${eventDate}`
  //   }
  // }
  return messageText
}

/*
const messageText = useMemo(() => {
      const item = props.item
      const sourceUserName = escapeMarkdownCharacters(item.sourceName ?? '')
      const teamGroupName = escapeMarkdownCharacters(item.teamName ?? '')
      const teamGroupType = item.teamType
      const teamId = item.teamId
      const _acceptedTeamGroupInvite = !!item.joined
      const isNotiForTeamGroup = !!teamId
      const eventType = item.eventTypeId
      const eventTitle = escapeMarkdownCharacters(item.eventTitle ?? '')
      const dateString = moment(item.eventStart ?? '')
        .tz(props.timezone)
        .format('LL')
      const timeString = moment(item.eventStart ?? '')
        .tz(props.timezone)
        .format('h:mm a (z)')
      const albumName = escapeMarkdownCharacters(item.albumName ?? '')
      const eventDate =
        item.eventDetail && !item.eventDetail.allDay
          ? `${dateString} @ ${timeString}`
          : dateString
      const eventDetail = item.eventDetail
      const type = item.type
      let message = item.message ?? ''
      let _messageText = null

      let options: any = {
        sourceUserName,
        teamGroupName,
        teamGroupType: getTeamType(teamGroupType),
      }

      switch (type) {
        case 'eventCreate':
          options = {
            sourceUserName,
            teamGroupName: setStringPossessive(teamGroupName),
            eventTitle,
            eventType: t('notification-event-field-' + eventType),
            eventDate,
          }
          if (isNotiForTeamGroup) {
            _messageText = t(I18N.NOTIFICATION_TITLE_ADD_TEAM_EVENT, options)
          } else {
            _messageText = t(I18N.NOTIFICATION_TITLE_ADD_EVENT, options)
          }
          break
        case 'eventUpdate':
          const updateCnt =
            (eventDetail &&
              eventDetail.eventUpdates &&
              eventUpdateUpdatesCount(eventDetail.eventUpdates)) ||
            0
          const updated =
            (eventDetail &&
              eventDetail.eventUpdates &&
              eventUpdateUpdatedItems(eventDetail.eventUpdates)) ||
            ''
          const updatedDetail =
            (eventDetail &&
              eventDetail.eventUpdates &&
              eventUpdateUpdatedDetail(eventDetail.eventUpdates, item)) ||
            ''
          options = {
            sourceUserName,
            teamGroupName: setStringPossessive(teamGroupName),
            eventTitle,
            eventType: t('notification-event-field-' + eventType),
            eventDate,
            updated,
            dateString,
            updatedDetail,
          }
          if (updateCnt === 1) {
            if (updated === t(I18N.NOTIFICATION_EVENT_FIELD_REPEAT_INFORMATION)) {
              if (isNotiForTeamGroup) {
                _messageText = t(I18N.NOTIFICATION_TITLE_UPDATE_TEAM_EVENT_SHORT, options)
              } else {
                _messageText = t(I18N.NOTIFICATION_TITLE_UPDATE_EVENT_SHORT, options)
              }
            } else if (updated === t(I18N.NOTIFICATION_EVENT_FIELD_DATE)) {
              if (isNotiForTeamGroup) {
                _messageText = t(I18N.NOTIFICATION_TITLE_UPDATE_TEAM_EVENT_DATE, options)
              } else {
                _messageText = t(I18N.NOTIFICATION_TITLE_UPDATE_EVENT_DATE, options)
              }
            } else {
              if (isNotiForTeamGroup) {
                _messageText = t(I18N.NOTIFICATION_TITLE_UPDATE_TEAM_EVENT, options)
              } else {
                _messageText = t(I18N.NOTIFICATION_TITLE_UPDATE_EVENT, options)
              }
            }
          } else if (updateCnt === 2) {
            if (
              updated.indexOf(t(I18N.NOTIFICATION_EVENT_FIELD_START_TIME)) !== -1 &&
              updated.indexOf(t(I18N.NOTIFICATION_EVENT_FIELD_END_TIME)) !== -1
            ) {
              if (isNotiForTeamGroup) {
                _messageText = t(I18N.NOTIFICATION_TITLE_UPDATE_TEAM_EVENT_TIME, options)
              } else {
                _messageText = t(I18N.NOTIFICATION_TITLE_UPDATE_EVENT_TIME, options)
              }
            } else {
              if (isNotiForTeamGroup) {
                _messageText = t(I18N.NOTIFICATION_TITLE_UPDATE_TEAM_EVENT, options)
              } else {
                _messageText = t(I18N.NOTIFICATION_TITLE_UPDATE_EVENT, options)
              }
            }
          } else if (updateCnt === 3) {
            if (isNotiForTeamGroup) {
              _messageText = t(I18N.NOTIFICATION_TITLE_UPDATE_TEAM_EVENT_SHORT, options)
            } else {
              _messageText = t(I18N.NOTIFICATION_TITLE_UPDATE_EVENT_SHORT, options)
            }
          } else {
            if (isNotiForTeamGroup) {
              _messageText = t(
                I18N.NOTIFICATION_TITLE_UPDATE_TEAM_EVENT_NO_FIELDS,
                options,
              )
            } else {
              _messageText = t(I18N.NOTIFICATION_TITLE_UPDATE_EVENT_NO_FIELDS, options)
            }
          }
          break
        case 'eventDelete':
          options = {
            eventOwnerName: setStringPossessive(
              isNotiForTeamGroup ? teamGroupName : sourceUserName,
            ),
            eventType,
            eventTitle,
          }
          _messageText = t(I18N.NOTIFICATION_TITLE_DELETE_EVENT, options)
          break
        case 'album':
          options = {
            sourceUserName,
            teamGroupName,
            albumName,
          }
          _messageText = t(I18N.NOTIFICATION_TITLE_ALBUM_TEAM_CREATE, options)
          break
        case 'poll':
          let expTime = ''
          let msg = message || ''
          const expPrefix = 'Poll will close at'
          if (msg.startsWith(expPrefix)) {
            expTime = msg.substring(expPrefix.length + 1)
            msg = expPrefix
          }
          options = { sourceUserName, expTime }
          if (msg === 'Poll will close at') {
            _messageText = t(I18N.NOTIFICATION_TITLE_POLL_WILL_CLOSE, options)
          } else {
            _messageText = t(I18N.NOTIFICATION_TITLE_POLL_CLOSED, options)
          }
          break
        case 'post':
          switch (message) {
            case 'Posted a poll in the team':
            case 'Posted a poll in the group':
              _messageText = t(I18N.NOTIFICATION_TITLE_POLL_TEAM_CREATE, options)
              break
            case 'Posted in the team':
            case 'Posted in the group':
              _messageText = t(I18N.NOTIFICATION_TITLE_POST_TEAM_CREATE, options)
              break
            case 'Liked your post':
              if (isNotiForTeamGroup) {
                _messageText = t(I18N.NOTIFICATION_TITLE_POST_TEAM_LIKE, options)
              } else {
                _messageText = t(I18N.NOTIFICATION_TITLE_POST_LIKE, options)
              }
              break
            case 'Commented on your post':
              _messageText = t(I18N.NOTIFICATION_TITLE_POST_COMMENT, options)
              break
            case 'Your post has been approved':
              _messageText = t(I18N.NOTIFICATION_TITLE_POST_APPROVED, options)
              break
            case 'Posted a playlist':
              _messageText = t(I18N.NOTIFICATION_TITLE_VIDEO_POSTED_PLAYLIST, options)
              break
            case 'Liked your playlist':
              _messageText = t(I18N.NOTIFICATION_TITLE_VIDEO_LIKED_PLAYLIST, options)
              break
            default:
              if (message?.includes('Your scheduled post')) {
                if (!message?.includes('team')) {
                  _messageText = t(
                    I18N.NOTIFICATION_TITLE_POST_TEAM_CREATE_SCHEDULED_1,
                    options,
                  )
                } else {
                  if (message?.includes('teams')) {
                    let search = ' ' + teamGroupName + ' '
                    let ndx = message?.indexOf(search)
                    if (ndx > -1) {
                      ndx += teamGroupName?.length || 0
                      ndx += 6
                      let ndx2 = message?.indexOf(' ', ndx)
                      options.count = message?.substring(ndx, ndx2)
                    }
                    _messageText = t(
                      I18N.NOTIFICATION_TITLE_POST_TEAM_CREATE_SCHEDULED_3,
                      options,
                    )
                  } else {
                    _messageText = t(
                      I18N.NOTIFICATION_TITLE_POST_TEAM_CREATE_SCHEDULED_2,
                      options,
                    )
                  }
                }
              } else if (message?.includes('Commented on your post in the')) {
                _messageText = t(I18N.NOTIFICATION_TITLE_POST_TEAM_COMMENT, options)
              } else if (message?.includes('Commented on post by')) {
                if (isNotiForTeamGroup) {
                  options.postBy = setStringPossessive(
                    message?.split('Commented on post by ')[1]?.split(' in the ')[0] ??
                      '',
                  )
                  _messageText = t(
                    I18N.NOTIFICATION_TITLE_POST_TEAM_COMMENT_POSTBY,
                    options,
                  )
                } else {
                  options.postBy = setStringPossessive(
                    message?.split('Commented on post by ')[1] ?? '',
                  )
                  _messageText = t(I18N.NOTIFICATION_TITLE_POST_COMMENT_POSTBY, options)
                }
              } else if (message?.includes('Commented on your playlist on')) {
                _messageText = t(
                  I18N.NOTIFICATION_TITLE_VIDEO_COMMENTED_ON_YOUR_PLAYLIST,
                  options,
                )
              } else if (message?.includes('Commented on playlist by')) {
                options.postBy = setStringPossessive(
                  message?.split('Commented on playlist by')[1]?.split(' on ')[0] ?? '',
                )
                _messageText = t(
                  I18N.NOTIFICATION_TITLE_VIDEO_COMMENTED_ON_PLAYLIST,
                  options,
                )
              } else {
                _messageText = message
              }
          }
          break
        case 'team-postComplaint':
          _messageText = t(I18N.NOTIFICATION_TITLE_POST_COMPLAINT, options)
          break
        case 'reminder':
          options.startsIn = message?.split(' starts in ')[1] ?? ''
          options.eventType = t('notification-event-field-' + eventType)
          options.eventTitle = eventTitle

          if (isNotiForTeamGroup) {
            options.eventOwnerName = setStringPossessive(teamGroupName)
          } else if (item.sourceId === userState.id) {
            options.eventOwnerName = null
          } else {
            options.eventOwnerName = setStringPossessive(sourceUserName)
          }

          if (options.eventOwnerName) {
            _messageText = t(I18N.NOTIFICATION_TITLE_OTHER_REMINDER, options)
          } else {
            _messageText = t(I18N.NOTIFICATION_TITLE_YOUR_REMINDER, options)
          }
          break
        case 'team':
          if (message?.includes('Added you to')) {
            if (_acceptedTeamGroupInvite) {
              _messageText = t(I18N.NOTIFICATION_TITLE_TEAM_ADD, options)
            } else {
              _messageText = t(I18N.NOTIFICATION_TITLE_TEAM_INVITE, options)
            }
          } else if (message?.includes('access to sportsYou Video')) {
            _messageText = t(I18N.NOTIFICATION_TITLE_VIDEO_ACCESS, options)
          }
          break
        case 'team-setAdmin':
          if (message && message.indexOf('photographer') > -1) {
            _messageText = t(I18N.NOTIFICATION_TITLE_MADE_PHOTOGRAPHER, options)
          } else {
            _messageText = t(I18N.NOTIFICATION_TITLE_MADE_ADMIN, options)
          }
          break
        case 'friend-accept':
          _messageText = t(I18N.NOTIFICATION_TITLE_FRIEND_ACCEPT, options)
          break
        case 'friend':
          if (item.friendRequestStatus === 'accepted') {
            _messageText = t(I18N.NOTIFICATION_TITLE_FRIEND_REQUEST_ACCEPT, options)
          } else if (item.friendRequestStatus === 'rejected') {
            _messageText = t(I18N.NOTIFICATION_TITLE_FRIEND_REQUEST_REJECT, options)
          } else {
            _messageText = t(I18N.NOTIFICATION_TITLE_FRIEND_REQUEST, options)
          }
          break
        case 'file':
          _messageText = t(I18N.NOTIFICATION_TITLE_FILE_SHARE, options)
          break
        default:
          _messageText = `${escapeMarkdownCharacters(item.sourceName ?? '')} ${
            item.message
          } ${escapeMarkdownCharacters(item.teamName ?? '')}`
      }

      _messageText = _messageText?.replace(' **** ', ' ')

      return _messageText
    }, [])
*/
