import * as React from 'react'
import type { SVGProps } from 'react'
const EmailArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <g>
      <path d='M13.31 23.15H4.53a2.34 2.34 0 0 1-2.34-2.34V9.28l9 6.1a8 8 0 0 0 9 0l9-6.1v8.1a1.1 1.1 0 0 0 2.19 0v-12A4.55 4.55 0 0 0 26.83.83H4.55A4.56 4.56 0 0 0 0 5.36v15.45a4.53 4.53 0 0 0 4.53 4.53h8.78a1.1 1.1 0 1 0 0-2.19zM2.88 3.71A2.38 2.38 0 0 1 4.55 3h22.28a2.36 2.36 0 0 1 2.36 2.35 2.36 2.36 0 0 1-1 1.93L19 13.57a5.82 5.82 0 0 1-6.54 0L3.23 7.33a2.36 2.36 0 0 1-.35-3.62z' />
      <path d='M31.7 23.49l-5.53-5.82a1.1 1.1 0 1 0-1.59 1.52l3.76 4h-9.46a1.1 1.1 0 1 0 0 2.2h9.46l-3.76 4a1.09 1.09 0 0 0 0 1.55 1.06 1.06 0 0 0 .76.31 1.07 1.07 0 0 0 .79-.35L31.7 25a1.11 1.11 0 0 0 0-1.51z' />
    </g>
  </svg>
)
export default EmailArrow
