import * as React from 'react'
import type { SVGProps } from 'react'
const HomeES = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    style={props.style}
    {...props}
  >
    <g clipPath='url(#a)'>
      <path
        fill='#D94B21'
        d='M31.9801 17.9447 16.0548 2.0226c-.0145-.0147-.0343-.02286-.0549-.02259-.0204.00062-.0399.00865-.0549.02259L.0226946 17.9447c-.02255598.0218-.02901441.0554-.01613908.0839.01235938.0285.03998748.0473.07101178.0484H6.23817c.07099.0004.12847.0578.12884.1288V29.349c.00056.0425.03492.0769.07746.0775h7.74163c.0425-.0006.0769-.035.0775-.0775v-5.3569c.0002-.0712.0578-.1288.129-.129h3.0979c.0712.0002.1289.0578.1291.129v5.3537c.0006.0426.0349.0769.0775.0775h7.8483c.0426-.0006.0769-.0349.0775-.0775V18.2093c.0002-.0712.0579-.1288.129-.129h6.1604c.0316.0007.0601-.0188.071-.0484.0113-.012.0173-.0279.0167-.0443-.0006-.0164-.0078-.0318-.0199-.0429Z'
      />
      <path
        fill='#2B2B2B'
        d='M13.6904 14.0786H9.18262v4.7494h4.50778v-4.7494ZM22.7617 14.0786h-4.5078v4.7494h4.5078v-4.7494Z'
      />
    </g>
    <defs>
      <clipPath>
        <path fill='#fff' d='M0 0h32v32H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default HomeES
