import { Colors } from '@sportsyou/core'
import { ChevronRight } from '@sportsyou/react-icons'
import styled, { createGlobalStyle } from 'styled-components'

// import { Colors } from '@mmrdigital/core'
// import { ChevronRight } from '@mmrdigital/icons'

// import '@sportsyou/fonts'

export const GlobalStyle = createGlobalStyle`
  :root {
    --main-site-width: 900px;
    --site-background-color: #f3f3f3;

    --font-system-fallback: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

    --font-header: 'Cocogoose Pro', var(--font-system-fallback);
    --font-body: 'Figtree', var(--font-system-fallback);

    --color-twitter: #1da1f2;
    --color-instagram: #e1306c;
    --color-facebook: #4267b2;
    --color-youtube: #ff0000;

    --fc-small-font-size: 12px;

  }

  html {
    box-sizing: border-box;
  }

  *, *::before, *::after {
    box-sizing: inherit;
  }

  html,
  body {
    height: 100%;
    width: 100%;
  }

  html {
    width: 100vw;
    overflow-x:hidden;
  }

  body {
    background-color: var(--site-background-color);
    font-family: var(--font-body);
    font-weight: 400;
    margin: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    line-height: 1.5em;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

  h1 {
    font-weight: 700;
    font-size: 23px;
  }

  `
// .react-datepicker-wrapper {
//   width: initial !important;
// }

// .multi-select__label {
//   color: #D74C2B;
//   font-family: var(--font-body);
//   font-size: 13px;
//   font-weight: 400;
//   left: 12px;
//   position: relative;
//   text-transform: uppercase;
// }

// .multi-select__control {
//   background-color: transparent !important;
//   padding-top: 30px;
//   margin-top: -30px;
//   border: 1px solid #DDDDDD !important;
//   border-radius: 6px !important;
//   margin-bottom: 20px;
// }

// .multi-select__value-container {
//   max-height: 100px !important;
//   overflow-y: scroll !important;
// }

// .multi-select__multi-value {
//   border-radius: 6px !important;
// }

// .multi-select__menu {
//   z-index: 20;
// }

export const ContentContainer = styled.div`
  background-color: ${Colors.WHITE};
  border-radius: 8px;
  margin: 16px 0 32px 0;
  padding: 16px;
`

export const HeaderTitleChevron = styled(ChevronRight)`
  font-size: 0.8rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`
