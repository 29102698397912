import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { Post, Upload, Upload as UploadProps } from '@sportsyou/api'
import { Colors, isFile } from '@sportsyou/core'

import PostAttachments, { PostAttachment } from './attachments'
import FormattedText from '../../formatted-text'
import PostPoll, { PollChoiceReturnData } from './poll'
import PostUploads, { CurrentUploadProps } from './uploads'

export interface PostContentProps extends Post {
  // attachments?: Array<PostAttachmentsProps>
  // uploads?: Array<UploadProps>
  onClickUpload?: (data: CurrentUploadProps) => void
  onClickPollChoice?: (data: PollChoiceReturnData, id: string) => void
  onClickPollVotes?: (postId: string) => void
  onClickRemoveVote?: (postId: string) => void
  onClickDownloadUpload?: (upload: Upload) => void
  onClickSaveUpload?: (upload: Upload) => void
  onClickViewUpload?: (upload: Upload) => void
}

export const PostContent: React.FC<PostContentProps> = ({
  // attachments,
  color,
  id,
  isCreator,
  message,
  onClickPollChoice,
  onClickPollVotes,
  onClickRemoveVote,
  onClickUpload,
  onClickViewUpload,
  onClickDownloadUpload,
  onClickSaveUpload,
  poll,
  sponsoredPostInfo,
  uploads,
}: PostContentProps) => {
  const [files, setFiles] = useState<Array<UploadProps>>([])
  const [media, setMedia] = useState<Array<UploadProps>>([])

  useEffect(() => {
    uploads && splitMediaAndFiles()
  }, [uploads])

  const splitMediaAndFiles = (
    _uploads?: Array<UploadProps | PostAttachment>
  ) => {
    if (!uploads) return
    const _files: PostAttachment[] = []
    const _media: UploadProps[] = []
    uploads.forEach((upload: UploadProps | PostAttachment | null) => {
      // Skip deleted media
      if (upload?.contentType && !upload.deletedAt) {
        if (isFile(upload.contentType)) {
          // Add events for files
          const _upload: PostAttachment = {
            ...upload,
            onClickView() {
              onClickViewUpload && onClickViewUpload(upload)
            },
            onClickDownload() {
              onClickDownloadUpload && onClickDownloadUpload(upload)
            },
            onClickSave() {
              onClickSaveUpload && onClickSaveUpload(upload)
            },
          }
          _files.push(_upload)
        } else {
          _media.push(upload)
        }
      }
    })

    setFiles(_files)
    setMedia(_media)
  }

  const renderPoll = () => {
    if (!poll) return null
    return (
      <PostPoll
        id={id}
        isCreator={isCreator}
        onClickPollChoice={onClickPollChoice}
        onClickPollVotes={onClickPollVotes}
        onClickRemoveVote={onClickRemoveVote}
        poll={poll!}
      />
    )
  }

  const renderContent = () => (
    <>
      {message && (
        <Container style={color ? { marginTop: 0 } : undefined}>
          <FormattedMessageContainer
            color={color ?? undefined}
            data-condensed={color ? message.length > 145 : undefined}
          >
            <FormattedText linkColor={color ? Colors.WHITE : undefined}>
              {message}
            </FormattedText>
          </FormattedMessageContainer>
        </Container>
      )}
      {media.length > 0 && (
        <Container>
          <PostUploads
            onClickUpload={onClickUpload}
            postId={id}
            sponsoredPostInfo={sponsoredPostInfo ?? undefined}
            uploads={media}
          />
        </Container>
      )}

      {files.length > 0 && (
        <Container>
          <PostAttachments attachments={files} />
        </Container>
      )}
    </>
  )

  return poll ? renderPoll() : renderContent()
}

const Container = styled.div`
  margin-top: 10px;
`
const ColorStyles = css`
  align-items: center;
  color: ${Colors.WHITE};
  display: flex;
  font-size: 40px;
  font-weight: bold;
  justify-content: center;
  line-height: 48px;
  min-height: 250px;
  padding: 30px;
  text-align: center;
  word-break: break-word;
`
const ColorStylesSmall = css`
  ${ColorStyles};
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.3;
  text-align: left;
`
const FormattedMessageContainer = styled.div`
  background-color: ${({ color }) => color ?? Colors.WHITE};
  line-height: 1.4;
  padding-left: 12px;
  padding-right: 12px;

  &[data-condensed='true'] {
    ${ColorStylesSmall};
  }
  &[data-condensed='false'] {
    ${ColorStyles};
  }
`

export default PostContent
