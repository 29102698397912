// TODO:
// - add diagonal direction

import { useEffect, useState } from 'react'

import Avatar from '../avatar'
import AvatarGroupGrid from './avatar-group-grid'
import AvatarGroupStack from './avatar-group-stack'
import {
  AvatarDataTypes,
  AvatarGroupItemProps,
  formatAvatarItems,
} from '../avatar/helpers'

export interface AvatarGroupProps {
  backgroundColor?: string
  borderRadius?: number
  items: Array<AvatarGroupItemProps>
  diameter?: number
  hideBackground?: boolean

  names?: Array<string>
  layout?: 'grid' | 'stack'
  stackDirection?: 'horizontal' | 'vertical' | 'diagonal'
  testId?: string
}

// const Container = styled.div

export const AvatarGroup: React.FC<AvatarGroupProps> = ({
  backgroundColor,
  borderRadius,
  diameter = 60,
  hideBackground = false,
  items,
  layout = 'grid',
  names = [],
  stackDirection = 'vertical',
  testId,
}: AvatarGroupProps) => {
  // setup layout

  const [formattedNames, setFormattedNames] = useState<Array<string>>([])
  const [avatarData, setAvatarData] = useState<Array<AvatarDataTypes>>([])
  const [useRawString, setUseRawString] = useState<boolean>(false)

  useEffect(() => {
    const x = formatAvatarItems(items)
    const _names = items.map((item) => item.name)
    // console.log({ x })
    setAvatarData(x)
    if (_names.length > 4) {
      const avatarsToRender = _names.filter((_, i) => i < 3)
      const remainingNames = _names.length - avatarsToRender.length

      if (remainingNames > 1) {
        setUseRawString(true)
      }

      const remainingString = `+${remainingNames}`

      const namesWithRemainingString = [...avatarsToRender, remainingString]

      setFormattedNames(namesWithRemainingString)
    } else {
      setFormattedNames(_names)
    }
  }, [items])

  if (formattedNames.length === 1) {
    return (
      <Avatar
        borderRadius={borderRadius}
        diameter={diameter}
        name={formattedNames[0]}
        uri={avatarData?.[0]?.uri}
      />
    )
  }

  const CommonProps = {
    data: avatarData,
    diameter,
    items,
    names: formattedNames,
    testId,
    useRawString,
  }

  const AvatarGridProps = {
    backgroundColor,
    borderRadius,
    hideBackground,
    ...CommonProps,
  }

  const AvatarStackProps = {
    borderRadius,
    stackDirection,
    ...CommonProps,
  }

  return layout === 'grid' ? (
    <AvatarGroupGrid {...AvatarGridProps} />
  ) : (
    <AvatarGroupStack {...AvatarStackProps} />
  )
}

export default AvatarGroup
