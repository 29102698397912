import { Colors } from '@sportsyou/core'
import type { Theme } from './'

// export con
export const Dark: Theme = {
  displayName: 'Dark',
  key: 'dark',
  palette: {
    primary: Colors.HAVELOCK_BLUE,
    content_background: '#363645',
    site_background: '#242730',
    nav: '#212121',
    border: '#606060',
    text: '#fafafa',
    text_anchor: Colors.HAVELOCK_BLUE,
    text_placeholder: '#757575',
  },
}
export default Dark
