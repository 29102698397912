// TODO: Add transitions

import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

interface Props {
  className?: string
  delay?: number
  isVisible?: boolean
  style?: React.CSSProperties
}

type NativeAttributes = Omit<React.HTMLAttributes<HTMLElement>, keyof Props>
export type AccordionContentProps = Props & NativeAttributes

export const AccordionContent: React.FC<
  React.PropsWithChildren<AccordionContentProps>
> = ({
  children,
  className,
  isVisible: isExpanded = false,
  delay = 200,
  style,
  ...props
}: AccordionContentProps) => {
  const [height, setHeight] = useState<string>(isExpanded ? 'auto' : '0')

  const [isVisible, setIsVisible] = useState<boolean>(isExpanded)

  const contentRef = useRef<HTMLDivElement>(null)
  const enterTimer = useRef<number>()
  const leaveTimer = useRef<number>()
  const resetTimer = useRef<number>()

  useEffect(() => {
    // show elem/reset height

    if (isExpanded) {
      setIsVisible(isExpanded)
    } else {
      // updateShape
      // setHeight()
    }

    // show animation
    enterTimer.current = window.setTimeout(() => {
      setHeight('auto')
      clearTimeout(enterTimer.current)
    }, 30)

    // reset height after animation
    if (isExpanded) {
      resetTimer.current = window.setTimeout(() => {
        setHeight('auto')
        clearTimeout(resetTimer.current)
      }, delay)
    } else {
      leaveTimer.current = window.setTimeout(() => {
        setIsVisible(isExpanded)
        clearTimeout(leaveTimer.current)
      }, delay / 2)
    }

    return () => {
      clearTimeout(enterTimer.current)
      clearTimeout(leaveTimer.current)
      clearTimeout(resetTimer.current)
    }
  }, [delay, isExpanded])

  return (
    <Container
      {...props}
      $height={height}
      $isVisible={isVisible}
      className={className}
      ref={contentRef}
      style={style}
    >
      {children}
    </Container>
  )
}

const Container = styled.div<{ $height: string; $isVisible: boolean }>`
  height: ${({ $height }) => $height};
  overflow: hidden;
  line-height: 1.2;
  padding: 10px;
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
`

export default AccordionContent
