import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Spinner, Tabs, useDialog } from '@sportsyou/react-dom-ui'

import CampaignFormAssets from '../CampaignForm/CampaignFormAssets'
import CampaignFormDetails from '../CampaignForm/CampaignFormDetails'
import useCampaignPortal from '../UseCampaignPortal'

interface CampaignEditTabProps {
  campaignId?: string
}

export default function CampaignEditTab(props: CampaignEditTabProps) {
  const { sendBanner } = useDialog()
  const navigate = useNavigate()

  const {
    currentCampaign,
    currentCampaignHasChanges,
    currentCampaignSponsor,
    isLoading,
    loadCampaign,
    loadSponsors,
    pathCampaigns,
    pathIndex,
    saveCurrentCampaign,
    setCurrentCampaignProp,
    setCurrentCampaignScreenLocationProp,
    sponsors,
  } = useCampaignPortal()

  const campaign = useMemo(() => {
    return currentCampaign ?? {}
  }, [currentCampaign])

  const [step, setStep] = useState(0)

  const isNew = useMemo(() => {
    return !props.campaignId
  }, [props.campaignId])

  const handleCancel = useCallback(() => {
    navigate(pathCampaigns())
  }, [navigate, pathCampaigns])

  const handleSubmitForm = useCallback(async () => {
    const response = await saveCurrentCampaign()
    if (response) {
      sendBanner({
        autoDismiss: true,
        message: 'Campaign saved',
      })
      navigate(pathIndex())
    }
  }, [saveCurrentCampaign, sendBanner, navigate, pathIndex])

  const handleOnChangeTab = useCallback(
    (index: number) => {
      setStep(index)
    },
    [setStep]
  )

  useEffect(() => {
    loadSponsors()
  }, [])

  useEffect(() => {
    if (props.campaignId) {
      loadCampaign(props.campaignId)
    }
  }, [loadCampaign, props.campaignId])

  return (
    <CampaignForm>
      <Tabs
        contentStyle={tabsContentStyle}
        index={step}
        onChange={handleOnChangeTab}
      >
        <Tabs.Item title='Campaign Details'>
          <CampaignFormDetails
            campaign={campaign}
            campaignSponsor={currentCampaignSponsor}
            hasChanges={currentCampaignHasChanges}
            isNew={isNew}
            onClickCancel={handleCancel}
            onClickNext={() => {
              setStep(1)
            }}
            setCampaignProp={setCurrentCampaignProp}
            sponsors={sponsors}
          />
        </Tabs.Item>
        <Tabs.Item title='Assets'>
          <CampaignFormAssets
            campaign={campaign}
            hasChanges={currentCampaignHasChanges}
            isNew={isNew}
            onClickBack={() => {
              setStep(0)
            }}
            onClickCancel={handleCancel}
            onClickNext={handleSubmitForm}
            sponsor={currentCampaignSponsor}
            setCampaignProp={setCurrentCampaignProp}
            setCurrentCampaignScreenLocationProp={
              setCurrentCampaignScreenLocationProp
            }
          />
        </Tabs.Item>
        <Tabs.ExtraContent>{isLoading && <Spinner />}</Tabs.ExtraContent>
      </Tabs>
    </CampaignForm>
  )
}

const tabsContentStyle = {
  padding: '20px 0',
}

const CampaignForm = styled.div``
