import * as React from 'react'
import type { SVGProps } from 'react'
const Print = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 300 300'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <title>{'Print'}</title>
    <path d='M277 64.7h-33.2V9.4c0-5.2-4.2-9.4-9.4-9.4H65.9c-5.2 0-9.4 4.2-9.4 9.4v55.3H23c-5.2 0-9.4 4.2-9.4 9.4v147.1c0 5.2 4.2 9.4 9.4 9.4h33.6v59.9c0 5.2 4.2 9.4 9.4 9.4h168.4c5.2 0 9.4-4.2 9.4-9.4v-59.9H277c5.2 0 9.4-4.2 9.4-9.4v-147c0-5.2-4.2-9.5-9.4-9.5zM75.3 18.8H225v45.9H75.3V18.8zM225 211.9v69.3H75.3V167.4H225v44.5zm42.6 0h-23.8v-54c0-5.2-4.2-9.4-9.4-9.4H65.9c-5.2 0-9.4 4.2-9.4 9.4v54H32.4V84.3h235.2v-.8h.1v128.4z' />
    <circle cx={226.5} cy={115.6} r={14.7} />
    <path d='M88.8 201.9h116.7c3.5 0 6.3-2.8 6.3-6.3s-2.8-6.3-6.3-6.3H88.8c-3.5 0-6.3 2.8-6.3 6.3s2.9 6.3 6.3 6.3zM88.8 230.8h116.7c3.5 0 6.3-2.8 6.3-6.3s-2.8-6.3-6.3-6.3H88.8c-3.5 0-6.3 2.8-6.3 6.3.1 3.5 2.9 6.3 6.3 6.3zM88.8 256.8h74.3c3.5 0 6.3-2.8 6.3-6.3s-2.8-6.3-6.3-6.3H88.8c-3.5 0-6.3 2.8-6.3 6.3.1 3.5 2.9 6.3 6.3 6.3z' />
  </svg>
)
export default Print
