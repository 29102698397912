// TODO:
// add prop for size, color, background color
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'

interface SwitchEventTarget {
  checked: boolean
}

export interface SwitchEvent {
  target: SwitchEventTarget
  stopPropagation: () => void
  preventDefault: () => void
  nativeEvent: React.ChangeEvent
}

interface Props {
  checked?: boolean
  className?: string
  disabled?: boolean
  height?: number
  initialChecked?: boolean
  label?: string
  labelAfter?: boolean
  labelStyles?: React.CSSProperties
  labelTextSize?: number
  title?: string
  width?: number
  onChange?: (event: SwitchEvent) => void
  // type?:
}

type NativeAttributes = Omit<
  React.LabelHTMLAttributes<HTMLElement>,
  keyof Props
>
export type SwitchProps = Props & NativeAttributes

const Switch: React.FC<SwitchProps> = ({
  checked,
  className = '',
  disabled = false,
  initialChecked = false,
  height = 30,
  label,
  labelAfter = false,
  labelStyles,
  labelTextSize = 14,
  onChange,
  title,
  width = 52,
  ...props
}: SwitchProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(initialChecked)

  useEffect(() => {
    if (checked === undefined) return
    setIsChecked(checked)
  }, [checked])

  const handleChangeEvent = useCallback(
    (event: React.ChangeEvent) => {
      if (disabled) return
      const _event: SwitchEvent = {
        target: {
          checked: !isChecked,
        },
        stopPropagation: event.stopPropagation,
        preventDefault: event.preventDefault,
        nativeEvent: event,
      }

      setIsChecked(!isChecked)
      onChange && onChange(_event)
    },
    [disabled, isChecked, onChange]
  )

  return (
    <Label {...props} title={title}>
      {label && !labelAfter && (
        <Text
          disabled={disabled}
          labelTextSize={labelTextSize}
          style={labelStyles}
        >
          {label}
        </Text>
      )}
      <HiddenInput
        checked={isChecked}
        disabled={disabled}
        onChange={handleChangeEvent}
        type='checkbox'
      />

      <Checkbox $height={height} $width={width} className={className} />
      {label && labelAfter && (
        <Text
          disabled={disabled}
          labelAfter={labelAfter}
          labelTextSize={labelTextSize}
          style={labelStyles}
        >
          {label}
        </Text>
      )}
    </Label>
  )
}

const Label = styled.label`
  align-items: center;
  display: inline-flex;
`
const Text = styled.span<
  Pick<SwitchProps, 'disabled' | 'labelAfter' | 'labelTextSize'>
>`
  color: ${({ disabled }) =>
    disabled ? Colors.DUSTY_GRAY : Colors.MINE_SHAFT};
  font-size: ${({ labelTextSize }) => labelTextSize}px;

  margin-left: ${({ labelAfter }) => (labelAfter ? '6px' : undefined)};
  margin-right: ${({ labelAfter }) => (labelAfter ? undefined : '6px')};
`
const HiddenInput = styled.input`
  background-color: transparent;
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  visibility: hidden;
  width: 0;
  z-index: -1;
`
const Checkbox = styled.div<{
  $height: number
  $width: number
}>`
  align-items: flex-start;
  display: inline-flex;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: normal;
  line-height: normal;

  input[type='checkbox'] + & {
    background-color: #e8e8e8;
    border: 0;
    border-radius: ${({ $height }) => $height}px;
    cursor: pointer;
    height: ${({ $height }) => $height}px;
    margin: 0;
    padding: 0;
    position: relative;
    transition: all 0.3s ease;
    width: ${({ $width }) => $width}px;
    z-index: 0;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 2px;
    right: 0;
    bottom: 0;
    left: 2px;
    background-color: ${Colors.WHITE};
    height: ${({ $height }) => $height - 4}px;
    transition: all 0.3s ease;
  }

  &::before {
    border-radius: ${({ $height }) => $height}px;
    transform: scale(1);
    width: ${({ $width }) => $width - 4}px;

    z-index: 1;
  }

  // knob
  &::after {
    border-radius: ${({ $height }) => $height - 4}px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 40%);
    width: ${({ $height }) => $height - 4}px;
    z-index: 2;
  }

  input[type='checkbox']:disabled + & {
    background-color: rgb(200 200 200);
    cursor: not-allowed;

    &::before {
      background-color: ${Colors.ALTO};
    }
    &::after {
      background-color: rgb(255 255 255 / 20%);
      box-shadow: 0 0 2px 2px rgb(0 0 0 / 10%);
    }
  }

  input[type='checkbox']:checked + && {
    background-color: ${Colors.HAVELOCK_BLUE};
  }

  input[type='checkbox']:checked + &&::before {
    transform: scale(0);
  }
  input[type='checkbox']:checked + &&::after {
    left: ${({ $height, $width }) => $width - $height + 2}px;
  }
`

export default Switch
