import * as React from 'react'
import type { SVGProps } from 'react'
const FabCreateTeam = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M15.84 17.61a7.43 7.43 0 0 1 1-1.27l.14-.11h-7v-4.66a.7.7 0 0 0-1.39 0v1.54H6.23a.35.35 0 0 1-.35-.35V9.87a2.45 2.45 0 0 1 1.41-2.2l2.83-1.31a.75.75 0 0 1 .75.08l3.79 2.9a.69.69 0 0 0 .84 0l3.91-3a.46.46 0 0 1 .49 0l3 1.37a2.43 2.43 0 0 1 1.41 2.2v2.89a.35.35 0 0 1-.35.35h-2.47v-1.58a.69.69 0 0 0-1.38 0v3a6.88 6.88 0 0 1 1.64-.28H22.33a7.53 7.53 0 0 1 1.45.23h.14a1.73 1.73 0 0 0 1.74-1.73V9.87a3.84 3.84 0 0 0-2.21-3.46L20.49 5a1.87 1.87 0 0 0-1.92.21l-3.49 2.71-3.37-2.58a2.12 2.12 0 0 0-2.18-.24L6.71 6.41A3.84 3.84 0 0 0 4.5 9.87v2.89a1.74 1.74 0 0 0 1.73 1.73h2.43V23a1.74 1.74 0 0 0 1.74 1.7h4.89c0-.12-.12-.23-.16-.35a.61.61 0 0 1-.05-.12 6.78 6.78 0 0 1-.26-.91H10.4a.35.35 0 0 1-.35-.35v-1.42h4.56a6.87 6.87 0 0 1 .13-1.18v-.2h-4.69v-2.56z' />
    <path d='M26.1 18.06c-.12-.12-.24-.25-.36-.36a5.5 5.5 0 0 0-.83-.63 4.18 4.18 0 0 0-.65-.37c-.13-.06-.28-.09-.42-.14a5.32 5.32 0 0 0-1.66-.32h-.69a5.59 5.59 0 0 0-1.08.22 5.45 5.45 0 0 0-2 1.11l-.18.16a5.42 5.42 0 0 0-1.33 2.11l-.08.29c-.06.19-.12.38-.16.57a5.56 5.56 0 0 0-.08.81v.1a5.39 5.39 0 0 0 .14 1.14c0 .17.1.33.15.5s0 .15.06.23a5.61 5.61 0 0 0 .59 1.11l.05.07a5 5 0 0 0 .4.54 5.36 5.36 0 0 0 .83.75 5.3 5.3 0 0 0 2.32 1 5.18 5.18 0 0 0 .89.07 5.6 5.6 0 0 0 1.13-.02 5.43 5.43 0 0 0 3-9zm-.63 4a.57.57 0 0 1-.47.25h-2.36v2.37a.7.7 0 0 1 0 .14.6.6 0 0 1-.56.45.58.58 0 0 1-.56-.45v-.07-2.48h-2.43a.59.59 0 0 1-.59-.59.51.51 0 0 1 0-.13.59.59 0 0 1 .57-.46h2.37v-2.37a.58.58 0 0 1 0-.19.59.59 0 0 1 1.15.19v2.37H25a.59.59 0 0 1 .59.56.58.58 0 0 1-.12.35z' />
  </svg>
)
export default FabCreateTeam
