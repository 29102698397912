import * as React from 'react'
import type { SVGProps } from 'react'
const FabUser = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M16 17.28a5.63 5.63 0 0 1-5.64-5.64 5.64 5.64 0 1 1 11.28 0A5.63 5.63 0 0 1 16 17.28zm0-9.88a4.25 4.25 0 0 0 0 8.49 4.24 4.24 0 0 0 3-7.25 4.2 4.2 0 0 0-3-1.24zM24.14 26H7.86a.69.69 0 0 1-.69-.69A5.87 5.87 0 0 1 13 19.45h6a5.87 5.87 0 0 1 5.86 5.86.69.69 0 0 1-.72.69zM8.6 24.62h14.8a4.48 4.48 0 0 0-4.4-3.79h-6a4.48 4.48 0 0 0-4.4 3.79z' />
  </svg>
)
export default FabUser
