import { useCallback, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { Colors, POST_COLORS } from '@sportsyou/core'

import TextareaCentered from '../../textarea-centered'

interface ColorTextPostInputProps {
  color?: string
  message?: string
  onChangeColor: (color: string) => void
  onChangeMessage: (text: string) => void
}

const MAX_MESSAGE_LENGTH = 140
const MESSAGE_LINE_HEIGHT = 1.4
const SMALL_MESSAGE_FONT_SIZE = 24
const LARGE_MESSAGE_FONT_SIZE = 40

export const ColorTextPostInput = ({
  color,
  message = '',
  onChangeColor,
  onChangeMessage,
}: ColorTextPostInputProps) => {
  const inputRef = useRef<HTMLDivElement>(null)

  const [isSmall, setIsSmall] = useState<boolean>(
    message.length > MAX_MESSAGE_LENGTH
  )

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  const handleOnClickBackground = () => {
    inputRef.current && inputRef.current.focus()
  }

  const handleOnChangeColor = (color: keyof typeof POST_COLORS) => {
    onChangeColor && onChangeColor(POST_COLORS[color])
  }

  const handleOnChangeMessage = (text = '') => {
    onChangeMessage?.(text)
    // set small state if we reach message limit
    setIsSmall(text.length >= MAX_MESSAGE_LENGTH)
  }

  const onClickContainer = useCallback(() => {
    inputRef.current && inputRef.current.focus()
  }, [])

  return (
    <Container onClick={onClickContainer}>
      <ScrollerableInput>
        <Background $color={color} onClick={handleOnClickBackground}>
          <StyledTextarea
            $isMinimized={message === ''}
            $isSmall={isSmall}
            color={Colors.WHITE}
            height={isSmall ? 24 : 40}
            onChange={handleOnChangeMessage}
            placeholder='Share a post'
            ref={inputRef}
            textStyle={{
              fontWeight: isSmall ? 400 : 700,
              fontSize: isSmall
                ? SMALL_MESSAGE_FONT_SIZE
                : LARGE_MESSAGE_FONT_SIZE,
              textAlign: isSmall ? 'left' : 'center',
            }}
            value={message}
          />
        </Background>
      </ScrollerableInput>
      <ColorsContainer>
        {Object.keys(POST_COLORS).map((_color, index: number) => {
          return (
            <ColorOption
              aria-selected={
                color === POST_COLORS[_color as keyof typeof POST_COLORS]
              }
              $color={POST_COLORS[_color as keyof typeof POST_COLORS]}
              key={`${index}_color-option-${color}`}
              onClick={() =>
                handleOnChangeColor(_color as keyof typeof POST_COLORS)
              }
            />
          )
        })}
      </ColorsContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  max-height: 500px;
`
const ScrollerableInput = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  max-height: 313px;
  overflow-y: auto;
`
const Background = styled.div<{ $color?: string }>`
  align-items: center;
  background-color: ${({ $color = Colors.WHITE }) => $color};
  box-sizing: border-box;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  // padding-bottom: 30px;
  // padding-top: 30px;
  transition: 300ms ease-in-out;
  width: 100%;
  cursor: text;
  // @media all and (max-width: 639px) {
  //   height: 200px;
  // }
  @media all and (min-width: 640px) {
    //   max-height: 500px;
    min-height: 275px;
  }
`
const SharedTextStyles = css`
  font-size: ${LARGE_MESSAGE_FONT_SIZE}px;
  // line-height: 48px;
  line-height: ${MESSAGE_LINE_HEIGHT};
  word-break: break-word;
`
const StyledTextarea = styled(TextareaCentered)<{
  $isMinimized: boolean
  $isSmall: boolean
}>`
  ${SharedTextStyles};
  align-items: center;
  background: none;
  border: none;
  box-sizing: border-box;
  font-family: inherit;
  // height: 48px;
  justify-content: center;
  // max-height: calc(443px - 148px);
  outline: none;
  padding: 0;

  // margin-bottom: ${({ $isMinimized }) => ($isMinimized ? 0 : '30px')};
  // margin-top: ${({ $isMinimized }) => ($isMinimized ? 0 : '30px')};
  padding-left: ${({ $isMinimized }) => ($isMinimized ? 0 : '30px')};
  padding-right: ${({ $isMinimized }) => ($isMinimized ? 0 : '30px')};

  resize: none;
  // width: 100%;
  width: ${({ $isMinimized }) => ($isMinimized ? '1px' : '100%')};
  overflow-y: auto;
  overflow-x: hidden;
  color: white;
`
// const Placeholder = styled.span`
//   ${SharedTextStyles};
//   color: rgb(255 255 255 / 40%);
//   font-weight: 700;
// `
const ColorsContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  justify-content: space-around;
  padding: 10px 4px;
  @media all and (min-width: 640px) {
    padding: 10px 0;
  }
`
const ColorOption = styled.div<{ $color: string }>`
  align-items: center;
  background-color: ${({ $color }) => $color};
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;
  transition: background-color 0.2s ease-in-out;
  height: 28px;
  width: 28px;

  @media all and (min-width: 640px) {
    height: 38px;
    width: 38px;
  }

  &::before {
    content: '';
    border: 2px solid transparent;
    border-radius: 50%;
    box-sizing: inherit;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    position: absolute;
    height: 38px;
    width: 38px;
    @media all and (min-width: 640px) {
      height: 48px;
      width: 48px;
    }
  }

  &[aria-selected='false']:active::before,
  &[aria-selected='false']:hover::before {
    border-color: ${Colors.DUSTY_GRAY};
  }

  &[aria-selected='true']::before {
    border-color: ${Colors.MINE_SHAFT};
  }
`

export default ColorTextPostInput
