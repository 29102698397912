import { DependencyList, useState } from 'react'
import Subscriptions, {
  SubscriptionNotificationChangedResponse,
} from '@sportsyou/subscription'
import {
  queryNumberOfAlerts,
  QueryNumberOfAlertsRequest,
  queryNumberOfRequests,
  QueryNumberOfRequestsRequest,
} from '@sportsyou/api'

import { useFetchApi } from './use-fetch-api'
import { useSubscription } from './use-subscription'

export interface UseNotificationSubscriptionProps {
  onUpdate?: (arg: any) => void
}

export const useNotificationSubscription = (
  deps?: DependencyList,
  props?: UseNotificationSubscriptionProps
) => {
  const { fetch: getAlertsCount } = useFetchApi(queryNumberOfAlerts)
  const { fetch: getRequestsCount } = useFetchApi(queryNumberOfRequests)

  const [count, setCount] = useState(0)

  const subscription = useSubscription<SubscriptionNotificationChangedResponse>(
    () => Subscriptions.subscriptionNotificationChanged(),
    { deps }
  )

  const fetchCounts = async (rememberLastAsOfDate: boolean) => {
    const { data: alertsCount = 0 } = await getAlertsCount({
      asOfDate: 'SINCE_LAST_CHECK',
      rememberLastAsOfDate,
    } as QueryNumberOfAlertsRequest)

    const { data: requestsCount = 0 } = await getRequestsCount({
      asOfDate: 'SINCE_LAST_CHECK',
      rememberLastAsOfDate,
    } as QueryNumberOfRequestsRequest)

    const numberOfAlerts = rememberLastAsOfDate
      ? 0
      : alertsCount + requestsCount

    setCount(numberOfAlerts)
  }

  return {
    fetchCounts,
    notification: subscription.data,
    start: subscription.start,
    stop: subscription.stop,
    count,
  }
}

export default useNotificationSubscription
