import * as React from 'react'
import type { SVGProps } from 'react'
const UserCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M27.928 26.63A15.761 15.761 0 0 0 32 16.014C32 7.182 24.824 0 16 0S0 7.182 0 16.014C0 19.98 1.443 23.74 4.071 26.63 7.054 30.038 11.384 32 15.973 32c4.589 0 8.946-1.962 11.901-5.37v-.013l.055.013ZM16 2.044c7.694 0 13.957 6.27 13.957 13.97 0 2.97-.94 5.833-2.682 8.19-.041-.068-.095-.122-.136-.19-.082-.11-.15-.218-.232-.314-.286-.381-.599-.763-.926-1.117-.054-.055-.109-.123-.163-.177a13.246 13.246 0 0 0-1.117-1.023c-.122-.095-.231-.19-.354-.286-.395-.313-.803-.6-1.225-.859-.11-.068-.218-.122-.327-.19a12.72 12.72 0 0 0-1.212-.627c-.095-.041-.177-.082-.272-.123a11.29 11.29 0 0 0-1.13-.422c1.742-1.295 2.886-3.367 2.886-5.697 0-3.884-3.172-7.046-7.08-7.046a7.035 7.035 0 0 0-7.04 7.046c0 2.33 1.13 4.402 2.859 5.697-.395.122-.79.286-1.171.45-.041.013-.082.04-.123.054-.49.218-.966.463-1.416.736a1.018 1.018 0 0 0-.163.095c-.477.287-.926.6-1.348.94l-.205.164a14.059 14.059 0 0 0-2.314 2.44c-.055.082-.123.163-.177.245a3.029 3.029 0 0 0-.164.218 13.782 13.782 0 0 1-2.682-8.19c0-7.7 6.263-13.97 13.957-13.97v-.014ZM11.003 13.18A5.003 5.003 0 0 1 16 8.177c2.75 0 5.038 2.249 5.038 5.002 0 2.753-2.26 5.042-5.038 5.042-2.778 0-4.997-2.262-4.997-5.042ZM6.128 25.854c.081-.123.15-.26.231-.382.15-.218.286-.423.45-.627.15-.19.313-.382.476-.572.177-.205.354-.41.545-.6.163-.164.34-.314.517-.477.204-.177.409-.368.627-.532.19-.136.38-.272.572-.395.217-.15.449-.3.68-.436.205-.123.422-.218.64-.327.232-.11.463-.232.695-.34.245-.096.49-.178.749-.273.218-.082.45-.164.68-.232.3-.082.613-.136.927-.19.19-.042.367-.083.572-.11a11.51 11.51 0 0 1 1.538-.109c.518 0 1.035.041 1.553.11.15.013.3.054.435.08.354.07.722.124 1.062.219.164.04.314.109.477.163.327.11.654.205.967.341.163.068.313.15.463.218.3.15.599.287.899.464.15.081.286.19.435.286.273.177.559.368.817.572.136.11.273.232.409.355.245.218.49.422.721.667.137.137.26.287.395.423.205.232.409.477.6.736.122.163.245.34.367.518.096.15.19.3.273.45-2.547 2.643-6.114 4.143-9.873 4.143-3.758 0-7.312-1.5-9.872-4.143h-.027Z' />
  </svg>
)
export default UserCircle
