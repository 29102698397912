import Fab from './fab'
import FabItem from './fab-item'
import FabDivider from './fab-divider'

export type FabType = typeof Fab & {
  Divider: typeof FabDivider
  Item: typeof FabItem
}
;(Fab as FabType).Item = FabItem
;(Fab as FabType).Divider = FabDivider

export type { FabProps } from './fab'
export type { FabItemProps } from './fab-item'

export default Fab as FabType
