import * as React from 'react'
import type { SVGProps } from 'react'
const FileDoc = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 537.7 661.8'
    width='1em'
    height='1em'
    style={props.style}
    {...props}
  >
    <path
      fill='none'
      d='M206.8 248.2V103.4a20.74 20.74 0 0 1 20.7-20.7h248.2v-62h-455v620.4h455V268.8H227.5a20.66 20.66 0 0 1-20.7-20.6zm51.7 310.2H103.4v-20.7h155.1zm134.4-62.1H103.4v-20.7h289.5zm0-62H103.4v-20.7h289.5zm0-62.1H103.4v-20.7h289.5z'
    />
    <path
      fill='#2962ff'
      d='M383.7 152a21.32 21.32 0 0 0-12-3.4 20.77 20.77 0 0 0-11.9 3.4 23.41 23.41 0 0 0-8 9.3 33 33 0 0 0 0 26.6 21.46 21.46 0 0 0 8 9.3 20.77 20.77 0 0 0 11.9 3.4 21.32 21.32 0 0 0 12-3.4 22 22 0 0 0 8-9.3 33 33 0 0 0 0-26.6 22 22 0 0 0-8-9.3zM103.4 413.6h289.5v20.7H103.4zm0-62h289.5v20.7H103.4z'
    />
    <path
      fill='#2962ff'
      d='M517 82.7h-20.7v-62A20.74 20.74 0 0 0 475.6 0h-455A20.72 20.72 0 0 0 0 20.7v620.4a20.74 20.74 0 0 0 20.7 20.7h455a20.74 20.74 0 0 0 20.7-20.7V268.8H517a20.74 20.74 0 0 0 20.7-20.7V103.4A20.74 20.74 0 0 0 517 82.7zm-41.4 558.4h-455V20.7h455v62H227.5a20.74 20.74 0 0 0-20.7 20.7v144.8a20.74 20.74 0 0 0 20.7 20.7h248.2v372.2zM411 174.6a39.41 39.41 0 0 1-5.1 20 35.72 35.72 0 0 1-14 13.8 43.1 43.1 0 0 1-40.3 0 35.72 35.72 0 0 1-14-13.8 41.77 41.77 0 0 1 0-40 35.72 35.72 0 0 1 14-13.8 43.1 43.1 0 0 1 40.3 0 36.4 36.4 0 0 1 14 13.8 39.15 39.15 0 0 1 5.1 20zm-86.1 0a37.44 37.44 0 0 1-5.1 19.6 34.65 34.65 0 0 1-14 13.1 44.48 44.48 0 0 1-20.7 4.7h-29.5v-74.9h29.5a44.48 44.48 0 0 1 20.7 4.7 33.66 33.66 0 0 1 14 13.2 37.18 37.18 0 0 1 5.1 19.6zM438 187.9a22.09 22.09 0 0 0 8.3 9.3 20.8 20.8 0 0 0 11.7 3.4 20.3 20.3 0 0 0 10.9-3 19.13 19.13 0 0 0 7.3-7.9l13.7 6.6c-7.1 11.4-17.7 17.1-31.9 17.1a40.48 40.48 0 0 1-19.9-4.9 36.91 36.91 0 0 1-14.2-13.8 41.45 41.45 0 0 1 0-40.2 36.91 36.91 0 0 1 14.2-13.8 40.48 40.48 0 0 1 19.9-4.9c7.9 0 14.4 1.5 19.5 4.6a35.62 35.62 0 0 1 12.4 12.3l-13.7 6.7a20.39 20.39 0 0 0-7.4-7.8 19.68 19.68 0 0 0-10.8-3.1 20.8 20.8 0 0 0-11.7 3.4 24.12 24.12 0 0 0-8.3 9.3 31.2 31.2 0 0 0 0 26.7z'
    />
    <path
      fill='#2962ff'
      d='M103.4 475.6h289.5v20.7H103.4zm0 62.1h155.1v20.7H103.4zm181.8-387.8h-13.6v49.3h13.6a23.08 23.08 0 0 0 12.5-3.3 21.06 21.06 0 0 0 8.1-8.9 26.91 26.91 0 0 0 2.8-12.4c0-7.3-2.1-13.2-6.2-17.8s-9.8-6.9-17.2-6.9z'
    />
  </svg>
)
export default FileDoc
