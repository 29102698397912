export class Observable<T> {
  private _value?: T
  private _listeners: Array<(value: T) => void> = []

  constructor(value?: T) {
    this._value = value
  }

  set(value: T): void {
    this._value = value
    for (let i = 0; i < this._listeners.length; i++) {
      this._listeners[i](value)
    }
  }

  get(): T | undefined {
    return this._value
  }

  addListener(listener: (value: T) => void): () => void {
    this._listeners.push(listener)

    return () => {
      for (let i = this._listeners.length - 1; i >= 0; i--) {
        if (this._listeners[i] === listener) {
          this._listeners.splice(i, 1)
        }
      }
    }
  }
}

export default function observable<T>(value?: T) {
  return new Observable<T>(value)
}
