import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'
import { useWindowDimensions } from '@sportsyou/react-hooks'

import { getPosition } from '../../utils'

import DropdownItem from './dropdown-item'
import DropdownDivider from './dropdown-divider'
import { BORDER_RADIUS } from '../../theme'
import { Placement } from '../../utils/prop-types'

export interface DropdownMenuProps {
  children?: React.ReactNode
  itemStyle?: React.CSSProperties
  maxHeight?: number
  menuWidth?: string
  offset?: number
  placement?: Placement
  preventClipping?: boolean
  style?: React.CSSProperties
  textAlign?: 'left' | 'center' | 'right'
}

interface MenuItemProps {
  item: React.ReactNode
  type: 'header' | 'item'
}

export const DropdownMenu: React.FC<
  React.PropsWithChildren<DropdownMenuProps>
> = ({
  children,
  itemStyle,
  maxHeight,
  menuWidth = '160px',
  offset,
  placement = 'bottomStart',
  preventClipping,
  style,
  textAlign,
  ...props
}: DropdownMenuProps) => {
  const menuRef = useRef<HTMLDivElement | null>(null)

  const [menuItems, setMenuItems] = useState<MenuItemProps['item'][]>([])
  const [menuHeader, setMenuHeader] = useState<MenuItemProps['item']>()

  // Fn to Split React.Children into header item and list items
  const getMenuData = useMemo(() => {
    return (
      React.Children.map(children, (item) => {
        if (!React.isValidElement(item)) return null

        if (item.type === DropdownItem || item.type === DropdownDivider) {
          const _props = {
            ...item.props,
            style:
              item.type === DropdownItem
                ? { ...item.props.style, ...itemStyle }
                : item.props.style,
            textAlign,
          }
          const _item = {
            ...item,
            props: _props,
          }

          return {
            item: _item,
            type: 'item',
          }
        }
        return {
          item,
          type: 'header',
        }
      }) ?? []
    )
  }, [children, itemStyle, textAlign])

  // On mount, let's get any menu items isolated into it's own data set
  useEffect(() => {
    const _menuItems = getMenuData
      .filter((item) => item.type === 'item')
      .flat()
      .map((item) => item.item) //as Array<MenuItemProps>

    const _menuHeader = getMenuData
      .filter((item) => item.type === 'header')
      .flat()
      .map((item) => item.item)[0] // as MenuItemProps

    setMenuItems(_menuItems)
    setMenuHeader(_menuHeader)
  }, [getMenuData])

  // If `placement` is changed, update the position of the menu
  const position = useMemo(
    () => getPosition(placement, offset),
    [offset, placement]
  )

  // Let's make sure the menu isn't rendering offscreen!
  // Check screen width with simple hook
  const { width: screenWidth = 0 } = useWindowDimensions()
  /**
   * Attempt to use css transforms to prevent the menu from rendering
   * horizontally out of the viewport.
   */
  const positionOverrides = useMemo(() => {
    const overrides: React.CSSProperties = {}
    if (menuRef.current) {
      const { width: elWidth, x } = menuRef.current.getBoundingClientRect()

      // Check if menu is positioned offscreen to the left of the viewport
      if (placement === 'bottomEnd' || placement === 'topEnd') {
        if (x < 0) {
          const absoluteX = Math.abs(x)
          overrides.transform = `translate3d(${absoluteX + 10}px, 0, 0)`
        }
        // Check if menu positioned offscreen to the right of the viewport
      } else if (placement === 'bottomStart' || placement === 'topStart') {
        const offset = elWidth - (screenWidth - x)
        overrides.transform = `translate3d(-${offset + 10}px, 0, 0)`
      }
    }

    return overrides
  }, [placement, screenWidth])

  return (
    <Container
      menuWidth={menuWidth}
      ref={menuRef}
      style={{
        ...position,
        ...(preventClipping ? positionOverrides : {}),
        ...style,
      }}
      {...props}
    >
      {menuHeader && menuHeader}
      {menuItems.length > 0 && (
        <Menu
          aria-orientation='vertical'
          maxHeight={maxHeight}
          role='menu'
          style={{ textAlign: 'center' }}
        >
          {menuItems}
        </Menu>
      )}
    </Container>
  )
}

const Container = styled.div<Pick<DropdownMenuProps, 'menuWidth'>>`
  background-clip: padding-box;
  background-color: ${Colors.WHITE};
  border: 1px solid rgb(0 0 0 / 15%);
  border-radius: ${BORDER_RADIUS};
  box-shadow: rgb(0 0 0 / 12%) 0px 2px 10px;
  box-sizing: border-box;
  color: ${Colors.MINE_SHAFT};
  font-size: 1rem;
  margin: 0;
  max-width: calc(100vw - 20px);
  overflow: hidden;
  position: absolute;
  text-align: left;
  width: ${({ menuWidth }) => menuWidth};
  z-index: 1000;
  &:focus {
    background: red;
  }
`
const Menu = styled.ul<Pick<DropdownMenuProps, 'maxHeight'>>`
  margin-bottom: 0;
  margin-top: 0;
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : undefined)};
  overflow-y: ${({ maxHeight }) => (maxHeight ? 'auto' : undefined)};
  padding-left: 0;
`

export default DropdownMenu
