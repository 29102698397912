import * as React from 'react'
import type { SVGProps } from 'react'
const ColorPost = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 500 500'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M218.1 266.3h55.2l-27.5-76.5z' />
    <path d='M250 0C111.9 0 0 111.9 0 250s111.9 250 250 250 250-111.9 250-250S388.1 0 250 0zm43.6 325.8L283.1 297h-74.5l-10.5 28.8h-39l64.5-166.7h44.5l64.3 166.7h-38.8z' />
  </svg>
)
export default ColorPost
