/**
 * Hook to handle sportsYou subscriptions
 */

import { DependencyList, useEffect, useRef, useState } from 'react'
import {
  SubscriptionClient,
  SubscriptionCloseArgs,
  SubscriptionErrorArgs,
  SubscriptionResponse,
  SubscriptionSubscribeArgs,
} from '@sportsyou/subscription'

// useSubscription return type
export interface UseSubscriptionResult<T extends SubscriptionResponse> {
  closed?: SubscriptionCloseArgs // state object, holds any information when the subscription closes
  data?: T['data'] // state object, holds any data that the subscription returns
  error?: SubscriptionErrorArgs // state object, holds any errors that the subscription returns
  result?: T // full subscription response
  start: (passThrough?: any) => void // function to kick off the subscription
  stop: (shouldDisconnect?: boolean) => void // function to stop the subscription
  subscribed?: SubscriptionSubscribeArgs // state object, holds any information when the subscription is subscribed
}

export interface UseSubscriptionOptions {
  deps?: DependencyList
}

export function useSubscription<T extends SubscriptionResponse>(
  subscriptionFunction: (passThrough?: any) => Promise<T> | undefined,
  options?: UseSubscriptionOptions
): UseSubscriptionResult<T> {
  const [closed, setClosed] = useState<SubscriptionCloseArgs>()
  const [data, setData] = useState<T['data']>()
  const [error, setError] = useState<SubscriptionErrorArgs>()
  const [result, setResult] = useState<T>()
  const [subscribed, setSubscribed] = useState<SubscriptionSubscribeArgs>()

  const started = useRef(false)

  // create a watch id variable so we can unsubscribe later
  const watchId = useRef<string>()

  // unsubscribe on unmount
  useEffect(() => {
    return stop
  }, [])

  /**
   * automatically kick off the subscription on mount if deps are specified,
   * otherwise it must be started with the start function
   */
  useEffect(() => {
    function checkConnectedAndStart() {
      if (SubscriptionClient.isConnected()) {
        setTimeout(start, 10)
      } else {
        SubscriptionClient.connect(undefined, start)
        setTimeout(checkConnectedAndStart, 5_000)
      }
    }

    options?.deps && checkConnectedAndStart()
  }, options?.deps)

  // kick off the subscription, returned to the caller
  function start(passThrough?: any) {
    started.current = true
    watchId.current && SubscriptionClient.unsubscribe(watchId.current)
    const res = subscriptionFunction(passThrough)

    if (res) {
      res.then((response) => {
        if (response.watchId) {
          watchId.current = response.watchId
          response.data.addListener((value: T) => {
            setData(value.data)
            setResult(value)
          })
          response.error.addListener((value) => setError(value))
          response.closed.addListener((value) => setClosed(value))
          response.subscribed.addListener((value) => {
            watchId.current = value.watchId
            setSubscribed(value)
          })
        } else {
          started.current = false
        }
      })
    }
  }

  // manually stop the subscription, returned to the caller.
  function stop(shouldDisconnect?: boolean) {
    watchId.current && SubscriptionClient.unsubscribe(watchId.current)
    shouldDisconnect && SubscriptionClient.disconnect()
    watchId.current = undefined
    started.current = false
    setData(undefined)
  }

  return {
    closed,
    data,
    error,
    result,
    start,
    stop,
    subscribed,
  }
}

export default {
  useSubscription,
}
