import * as React from 'react'
import type { SVGProps } from 'react'
const FilePng = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 537.7 661.8'
    width='1em'
    height='1em'
    style={props.style}
    {...props}
  >
    <path
      fill='#00675b'
      d='M165.4 496.3H124v41.4h41.4V579h41.4v-41.4h-41.4v-41.3z'
    />
    <path
      fill='#00675b'
      d='M206.8 330.9v41.3h-41.3v-41.3h-41.4v41.4h41.3v41.3h41.4v-41.3h41.3v41.3h-41.3V455h41.4v-41.4h41.3v-41.3h41.3v41.3h-41.3V455h-41.3v41.3h41.3v41.3h-41.3v-41.3h-41.4V455h-41.3v-41.4h-41.4V455h41.3v41.4h41.4v41.3h41.4V579h41.4v-41.3h41.2V579h41.4v-41.4h-41.3v-41.2h41.3V455h-41.3v-41.4h41.3v-41.4h-41.3v-41.3h-41.4v41.3h-41.3v-41.3zm124 165.4h-41.2V455h41.2z'
    />
    <path
      fill='#00675b'
      d='M517 82.7h-20.7v-62A20.74 20.74 0 0 0 475.6 0h-455A20.74 20.74 0 0 0-.1 20.7v620.4a20.74 20.74 0 0 0 20.7 20.7h455a20.74 20.74 0 0 0 20.7-20.7V268.9H517a20.74 20.74 0 0 0 20.7-20.7V103.4A20.74 20.74 0 0 0 517 82.7zm-41.4 558.4h-455V20.7h455v62H227.4a20.74 20.74 0 0 0-20.7 20.7v144.8a20.74 20.74 0 0 0 20.7 20.7h248.2zM403 138.4V213h-15.3l-35.6-50.2V213h-15.9v-74.6h16.3l34.6 48.3v-48.3zm-76.7 23.9a24.29 24.29 0 0 1-3 12 21.56 21.56 0 0 1-8.6 8.6 27.5 27.5 0 0 1-13.5 3.2h-19V213h-15.9v-74.7h35a27.5 27.5 0 0 1 13.5 3.2 21.79 21.79 0 0 1 8.6 8.7 25.74 25.74 0 0 1 2.9 12.1zm159.5 37.9a42.65 42.65 0 0 1-13.9 10.4 44.57 44.57 0 0 1-37.9-1.1 37.15 37.15 0 0 1-14.4-13.7 38.88 38.88 0 0 1-5.3-20.3 37.68 37.68 0 0 1 5.2-20.1 34.71 34.71 0 0 1 14.4-13.5 41.92 41.92 0 0 1 20-4.8c7.2 0 13.4 1.3 18.5 4a34.38 34.38 0 0 1 12.4 10.9l-13.2 7.2a22 22 0 0 0-7.4-6.8 19.87 19.87 0 0 0-10.2-2.6 21.32 21.32 0 0 0-12 3.4 22.77 22.77 0 0 0-8.3 9.3 28.8 28.8 0 0 0-3 13.2 28.51 28.51 0 0 0 3 13.2 24.12 24.12 0 0 0 8.3 9.3 22.39 22.39 0 0 0 12 3.4 21.85 21.85 0 0 0 9.1-1.9 23.17 23.17 0 0 0 7-4.7v-10.5h-19.9v-12.8h35.6z'
    />
    <path
      fill='#00675b'
      d='M299.2 151.1h-16.9v22.4h16.9a10.62 10.62 0 0 0 7.9-3.1 10.93 10.93 0 0 0 3.1-8.1 11.41 11.41 0 0 0-3.2-8.1 10.55 10.55 0 0 0-7.8-3.1z'
    />
  </svg>
)
export default FilePng
