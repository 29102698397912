import { Colors } from '@sportsyou/core'
import { ActivityTypes, StatusTypes } from '../prop-types'

export const getStatusColor = (status: StatusTypes | ActivityTypes) => {
  if (status === 'success') {
    return Colors.MOUNTAIN_MEADOW
  } else if (status === 'alert') {
    return Colors.VIVID_TANGERINE
  } else if (status === 'danger') {
    return Colors.MONZA
  } else if (status === 'info') {
    return Colors.SHUTTLE_GRAY
  }
  return Colors.HAVELOCK_BLUE
}

export default getStatusColor
