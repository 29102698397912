import { useContext } from 'react'
import { DialogContext } from '../context/dialog-context'

export const useDialog = () => {
  const {
    alert,
    banner,
    confirm,
    reset,
    sendAlert,
    sendBanner,
    sendConfirm,
    sendToast,
    setAlert,
    setBanner,
    setConfirm,
    setToast,
    toast,
  } = useContext(DialogContext)
  return {
    alert,
    banner,
    confirm,
    reset,
    sendAlert,
    sendConfirm,
    sendBanner,
    sendToast,
    setAlert,
    setConfirm,
    setBanner,
    setToast,
    toast,
  }
}

export default useDialog
