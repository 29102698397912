import React from 'react'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'

interface Props {
  className?: string
  style?: React.CSSProperties
}

type NativeAttributes = Omit<React.HTMLAttributes<HTMLElement>, keyof Props>
export type CardFooterProps = Props & NativeAttributes

export const CardFooter: React.FC<React.PropsWithChildren<CardFooterProps>> = ({
  children,
  className,
  style,
}: CardFooterProps) => {
  return (
    <Footer className={className} style={style}>
      {children}
    </Footer>
  )
}

const Footer = styled.footer`
  align-items: center;
  border-top: 1px solid ${Colors.ALTO};
  display: flex;
  flex: 0 0 auto;
  font-size: 14px;
  justify-content: space-between;
  padding: 10px 12px;
`

export default CardFooter
