import * as React from 'react'
import type { SVGProps } from 'react'
const ChevronLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 20 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path
      fillRule='evenodd'
      d='M17.7845 31.4042c-.9333.85-2.3789.7824-3.2289-.1508L2.06507 17.5391c-.79442-.8722-.79444-2.2058-.00005-3.0781L14.555.746671c.85-.933301 2.2956-1.000848 3.2289-.15087.9333.849979 1.0009 2.295609.1509 3.228909L6.84646 16 17.9353 28.1752c.85.9333.7825 2.379-.1508 3.229Z'
      clipRule='evenodd'
    />
  </svg>
)
export default ChevronLeft
