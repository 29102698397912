import Widget from './widget'

import WidgetButton from './widget-button'
import WidgetFooter from './widget-footer'
import WidgetHeader from './widget-header'
import WidgetImage from './widget-image'
import WidgetText from './widget-text'

export type WidgetType = typeof Widget & {
  Button: typeof WidgetButton
  Header: typeof WidgetHeader
  Text: typeof WidgetText
  Footer: typeof WidgetFooter
  Image: typeof WidgetImage
}
;(Widget as WidgetType).Button = WidgetButton
;(Widget as WidgetType).Footer = WidgetFooter
;(Widget as WidgetType).Header = WidgetHeader
;(Widget as WidgetType).Image = WidgetImage
;(Widget as WidgetType).Text = WidgetText

export type { WidgetProps } from './widget'

export default Widget as WidgetType
