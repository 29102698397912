import { Outlet, useNavigate } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import React, { useEffect } from 'react'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'
import { Navbar } from '@sportsyou/react-dom-ui'

// import { Footer } from '../components'

/**
 * Auth Layout
 *
 * This is layout includes our static landing page `Footer` and intercom action
 * button for customer support.
 *
 * The Navbar component displays a 'Schedule Demo' button and Log In/Sign Up
 * buttons, depending on the urls path.
 */
export const AuthLayout: React.FC = () => {
  const { boot, shutdown } = useIntercom()

  const navigate = useNavigate()

  useEffect(() => {
    boot({ actionColor: Colors.PUNCH })
    return () => {
      shutdown()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Navbar contentWidth='900px'>
        <Navbar.Left
          product={{ name: 'Content Portal' }}
          brand
          onClickBrand={() => navigate('/')}
        />
      </Navbar>
      <Content>
        <Outlet />
      </Content>
      {/* <Footer /> */}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`
const Content = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
`

export default AuthLayout
