import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'

import { BORDER_RADIUS, FONT_SIZE_SM } from '../../theme'

import { RadioContext } from './radio-context'

interface Props {
  className?: string
  disabled?: boolean
  horizontal?: boolean
  // initialValue?: number | string
  label?: string
  onChange?: (value: number | string) => void
  radioContainerStyle?: React.CSSProperties
  style?: React.CSSProperties
  value?: number | string
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type RadioGroupProps = Props & NativeAttributes

export const RadioGroup: React.FC<React.PropsWithChildren<RadioGroupProps>> = ({
  children,
  className,
  disabled = false,
  horizontal,
  label,
  onChange,
  radioContainerStyle,
  style,
  value: initialValue,
  ...props
}: React.PropsWithChildren<RadioGroupProps>) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    if (initialValue === undefined) return
    setValue(initialValue)
  }, [initialValue])

  const updateState = useCallback(
    (nextState: number | string) => {
      setValue(nextState)
      onChange && onChange(nextState)
    },
    [onChange]
  )

  const providerValue = useMemo(
    () => ({
      updateState,
      disabledAll: disabled,
      inGroup: true,
      value,
    }),
    [disabled, updateState, value]
  )

  return (
    <RadioContext.Provider value={providerValue}>
      <Container className={className} style={style} {...props}>
        {label && <Label>{label}</Label>}
        <Group horizontal={horizontal} style={radioContainerStyle}>
          {children}
        </Group>
      </Container>
    </RadioContext.Provider>
  )
}

const Container = styled.div<Pick<RadioGroupProps, 'horizontal'>>`
  border: 1px solid ${Colors.ALTO};
  border-radius: ${BORDER_RADIUS};
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 60px;
  min-width: 300px;

  flex-direction: column;

  & > label + label {
    margin-left: ${({ horizontal }) => (horizontal ? '10px' : undefined)};
    margin-top: ${({ horizontal }) => (!horizontal ? '10px' : undefined)};
  }
`
const Group = styled.div<Pick<RadioGroupProps, 'horizontal'>>`
  display: flex;
  flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
  padding-top: 6px;
  padding-bottom: 6px;
`
const Label = styled.span`
  color: ${Colors.PUNCH};
  font-size: ${FONT_SIZE_SM}px;
  margin: 6px 10px 0;
  text-transform: uppercase;
`

export default RadioGroup
