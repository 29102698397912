import { useState, useEffect, useRef } from 'react'

/**
 * A hook that returns a boolean state value when a div appears on screen or leaves the screen
 * @param divRef Reference to the div to check
 * @param intersectionRatio The percent of the div that needs to be visible (1=100%, default .75 (75%))
 * @param insersectionMs The time in milliseconds that the div needs to be visible before returning a true, default 1000 milliseconds
 */
export const useOnScreen = (
  divRef: any,
  intersectionRatio = 0.75,
  insersectionMs = 1000
) => {
  const [isIntersecting, setIntersecting] = useState(false)
  const intersectingRef = useRef(false)

  const intersectionTest = (entry: IntersectionObserverEntry) => {
    return entry.isIntersecting && entry.intersectionRatio >= intersectionRatio
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        intersectingRef.current = intersectionTest(entry)
        if (intersectingRef.current) {
          setTimeout(() => {
            setIntersecting(intersectingRef.current)
          }, insersectionMs)
        } else {
          setIntersecting(false)
        }
      },
      {
        rootMargin: '0px',
        threshold: intersectionRatio,
      }
    )
    if (divRef.current) {
      observer.observe(divRef.current)
    }
    return () => {
      try {
        if (divRef.current) {
          observer.unobserve(divRef.current)
        }
      } catch (err) {
        console.log({ err })
      }
    }
  }, [])
  return isIntersecting
}

export default useOnScreen
