import * as React from 'react'
import type { SVGProps } from 'react'
const Bubble = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path
      fillRule='evenodd'
      d='m6.568 0 18.8639.00000103C27.1726.0028581 28.8425.695623 30.0734 1.9265c1.2309 1.23088 1.9236 2.89949 1.9265 4.64021V19.1471c-.0029 1.7407-.6956 3.4106-1.9265 4.6415-1.2309 1.2309-2.8995 1.9236-4.6402 1.9265h-7.209l-7.3912 5.8852c-.3125.2479-.698.3886-1.09668.3993L9.7211 32l-.01523-.0002c-.27099-.0032-.5383-.0632-.78465-.1761l-.01641-.0075c-.31056-.1511-.57259-.3862-.75667-.6784-.18409-.2922-.28281-.6301-.28501-.9754l-.00002-.0051v-4.4422H6.568c-1.74072-.0029-3.41062-.6956-4.6415-1.9265C.695622 22.5577.00285707 20.8891.00000108 19.1484L0 6.568c.00285599-1.74072.695622-3.41062 1.9265-4.6415C3.15738.695623 4.82728.00285599 6.568 0Zm.00109 3.69457c-.76168.00222-1.49154.30577-2.03014.84438-.53861.5386-.84216 1.26846-.84439 2.03014V19.146c.00223.7604.30581 1.489.84425 2.026.53866.5373 1.2684.839 2.02919.839h3.15418c.48832.0044.95512.2015 1.29892.5483.3437.3469.5366.8154.5366 1.3038v2.449l4.8975-3.8879c.3241-.2667.7309-.4128 1.1509-.4132h7.8638c.7608 0 1.4905-.3017 2.0292-.839.5384-.537.842-1.2655.8442-2.0259V6.57004c-.0022-.76163-.3058-1.49252-.8443-2.03109-.5386-.53861-1.2685-.84216-2.0302-.84438H6.56909Z'
      clipRule='evenodd'
    />
  </svg>
)
export default Bubble
