import { Colors } from '../../theme/colors'
import { limitToRange } from '../misc'

export const darken = (color: string, amount: number) => {
  return shift(color, -amount)
}

export const lighten = (color: string, amount: number) => {
  return shift(color, amount)
}

export const shift = (color: string, amount: number) => {
  const defaultColor = '#000000'
  let usePound = false
  let colorCode = ''

  if (color) {
    if (color[0] === '#') {
      colorCode = color.slice(1)
      usePound = true
    } else if (Object.keys(Colors).includes(color)) {
      colorCode = Colors[color as keyof typeof Colors].slice(1)
      usePound = true
    }
  } else {
    console.warn(
      `No color specified, falling back to default color (${defaultColor})`
    )
    return defaultColor
  }

  if (colorCode.length === 3) {
    colorCode = colorCode
      .split('')
      .map((v) => v + v)
      .join('')
  }

  const num = parseInt(colorCode, 16)
  const red = limitToRange((num >> 16) + amount, 0, 255)
  const green = limitToRange(((num >> 8) & 0x00ff) + amount, 0, 255)
  const blue = limitToRange((num & 0x0000ff) + amount, 0, 255)
  const redHex = red.toString(16).padStart(2, '0')
  const greenHex = green.toString(16).padStart(2, '0')
  const blueHex = blue.toString(16).padStart(2, '0')

  return `${usePound ? '#' : ''}${redHex}${greenHex}${blueHex}`
}
