import * as React from 'react'
import type { SVGProps } from 'react'
const ShareAndroid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M25.92 19.85a6 6 0 0 0-4.81 2.41l-9.19-4.6a6.25 6.25 0 0 0 .26-1.66 6.25 6.25 0 0 0-.26-1.66l9.19-4.6a6.06 6.06 0 1 0-1.25-3.66 5.44 5.44 0 0 0 .26 1.66l-9.2 4.6a6.08 6.08 0 1 0 0 7.32l9.2 4.59a5.53 5.53 0 0 0-.26 1.66 6.07 6.07 0 1 0 6.08-6.06zm0-17.61a3.86 3.86 0 1 1-1.73.43 3.76 3.76 0 0 1 1.74-.4zM6.11 19.85A3.85 3.85 0 1 1 9.94 16a3.74 3.74 0 0 1-.41 1.7 3.84 3.84 0 0 1-3.42 2.15zm19.81 9.91a3.85 3.85 0 0 1-3.84-3.85 3.73 3.73 0 0 1 .41-1.69 3.84 3.84 0 1 1 3.42 5.57z' />
  </svg>
)
export default ShareAndroid
