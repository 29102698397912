import * as React from 'react'
import type { SVGProps } from 'react'
const Gif = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 300 300'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M285.13 262.3h-37.74l7.06-7.01c27.89-27.67 43.88-66.05 43.88-105.3C298.33 68.21 231.79 1.67 150 1.67S1.67 68.21 1.67 150 68.21 298.33 150 298.33c4.52 0 8.19-3.68 8.19-8.19s-3.68-8.19-8.19-8.19c-72.75 0-131.94-59.19-131.94-131.94S77.25 18.06 150 18.06 281.94 77.25 281.94 150c0 35.52-14.65 70.07-40.18 94.8l-6.95 6.73v-36.47c0-4.52-3.68-8.19-8.19-8.19-4.52 0-8.19 3.68-8.19 8.19v55.44c0 4.52 3.68 8.19 8.19 8.19h58.52c4.52 0 8.19-3.68 8.19-8.19s-3.68-8.2-8.2-8.2z' />
    <path d='M116.19 121.41c12.1 0 19.56 5.97 23.62 12.85l-11.93 6.3c-2.24-3.65-6.47-6.55-11.69-6.55-9.03 0-15.42 6.96-15.42 16s6.38 16 15.42 16c4.31 0 8.21-1.49 10.44-3.32v-4.64h-12.68V146.2h26.69v21.63c-5.97 6.55-14.01 10.77-24.45 10.77-16.49 0-29.92-11.02-29.92-28.59s13.42-28.6 29.92-28.6zm32.81.91h14.26v55.28H149v-55.28zm24.2 0h40.53v12.02h-26.27v9.2h25.69v12.1h-25.69v21.96H173.2v-55.28z' />
  </svg>
)
export default Gif
