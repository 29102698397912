import * as React from 'react'
import type { SVGProps } from 'react'
const CreateTeam = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <g>
      <path d='M15.78 18.25a9.47 9.47 0 0 1 1.43-1.77l.19-.16H7.72V9.84a1 1 0 0 0-1.93 0V12H2.41a.48.48 0 0 1-.48-.49v-4a3.38 3.38 0 0 1 2-3.07l3.88-1.85a1 1 0 0 1 1 .11l5.27 4a1 1 0 0 0 1.17 0l5.44-4.16a.64.64 0 0 1 .69-.08l4.12 1.91a3.39 3.39 0 0 1 2 3.07v4a.49.49 0 0 1-.5.56h-3.36V9.84a1 1 0 0 0-1.92 0V14a10.25 10.25 0 0 1 2.28-.42 2.83 2.83 0 0 1 .41 0h.39a9.64 9.64 0 0 1 2 .32h.2a2.41 2.41 0 0 0 2.41-2.41v-4a5.33 5.33 0 0 0-3.07-4.82L22.24.75a2.6 2.6 0 0 0-2.67.3l-4.85 3.71L10 1.17a3 3 0 0 0-3-.33L3.08 2.66A5.34 5.34 0 0 0 0 7.48v4a2.41 2.41 0 0 0 2.41 2.42h3.38v11.8a2.41 2.41 0 0 0 2.41 2.41H15c-.07-.17-.16-.33-.22-.49a1.22 1.22 0 0 1-.07-.17 10.18 10.18 0 0 1-.37-1.27H8.2a.48.48 0 0 1-.48-.48v-2h6.35a10.26 10.26 0 0 1 .17-1.64c0-.09.06-.19.08-.28h-6.6v-3.53z' />
      <path d='M30.05 18.87c-.16-.18-.33-.35-.5-.51a8.23 8.23 0 0 0-1.16-.88 7.14 7.14 0 0 0-.9-.5 5.62 5.62 0 0 0-.58-.19 7.36 7.36 0 0 0-2.31-.46h-.4c-.19 0-.37 0-.56.06a8.3 8.3 0 0 0-1.5.3 7.65 7.65 0 0 0-2.75 1.56 2.65 2.65 0 0 0-.26.21 7.55 7.55 0 0 0-1.85 2.95c-.05.12-.07.26-.11.39a7.27 7.27 0 0 0-.22.8 6.86 6.86 0 0 0-.11 1.12v.15a7.43 7.43 0 0 0 .2 1.58c.06.24.13.47.2.7a3 3 0 0 0 .09.32.17.17 0 0 1 0 .05 7.52 7.52 0 0 0 .83 1.56l.06.09a8.36 8.36 0 0 0 .57.76A7.61 7.61 0 0 0 20 30a7.49 7.49 0 0 0 3.23 1.38 8.28 8.28 0 0 0 1.23.1 7 7 0 0 0 1.53-.16A7.58 7.58 0 0 0 32 23.9a7.49 7.49 0 0 0-1.95-5.03zm-.87 5.51a.81.81 0 0 1-.65.34h-3.3V28a.65.65 0 0 1 0 .19.79.79 0 0 1-1.55 0V24.7h-3.3a.82.82 0 0 1-.82-.82 1.16 1.16 0 0 1 0-.18.8.8 0 0 1 .78-.64h3.3v-3.3a1 1 0 0 1 .05-.25.81.81 0 0 1 1.59.25v3.3h3.3a.81.81 0 0 1 .81.77.82.82 0 0 1-.21.55z' />
    </g>
  </svg>
)
export default CreateTeam
