import * as React from 'react'
import type { SVGProps } from 'react'
const FabEmailArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M14.23 20.72H8.44a1.56 1.56 0 0 1-1.55-1.55v-7.6l5.94 4a5.29 5.29 0 0 0 5.93 0l5.94-4v5.34a.72.72 0 0 0 1.44 0V9a3 3 0 0 0-3-3H8.45a3 3 0 0 0-2.12.88A3 3 0 0 0 5.45 9v10.17a3 3 0 0 0 3 3h5.79a.72.72 0 1 0 0-1.44zM7.35 7.9a1.51 1.51 0 0 1 1.1-.46h14.69A1.57 1.57 0 0 1 24.7 9a1.54 1.54 0 0 1-.7 1.28l-6 4.12a3.85 3.85 0 0 1-4.31 0l-6.11-4.11A1.56 1.56 0 0 1 6.9 9a1.54 1.54 0 0 1 .45-1.1z' />
    <path d='M26.35 20.94l-3.64-3.84a.73.73 0 0 0-1 0 .72.72 0 0 0 0 1l2.48 2.61H17.9a.73.73 0 0 0-.73.73.73.73 0 0 0 .73.72h6.24l-2.48 2.62a.72.72 0 0 0 0 1 .74.74 0 0 0 .5.2.75.75 0 0 0 .53-.23l3.64-3.83a.72.72 0 0 0 .02-.98z' />
  </svg>
)
export default FabEmailArrow
