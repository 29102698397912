import { CSSProperties, FC, PropsWithChildren, ReactNode } from 'react'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'

export interface DropdownHeaderProps {
  children?: ReactNode
  className?: string
  style?: CSSProperties
  testId?: string
}

export const DropdownHeader: FC<PropsWithChildren<DropdownHeaderProps>> = ({
  children,
  testId,
  ...props
}: PropsWithChildren<DropdownHeaderProps>) => {
  return (
    <Header data-test={testId} {...props}>
      {children}
    </Header>
  )
}

const Header = styled.header`
  border-bottom: 1px solid ${Colors.ALTO};
  box-sizing: border-box;
  padding: 10px;
`

DropdownHeader.displayName = 'DropdownHeader'

export default DropdownHeader
