import { Helmet } from 'react-helmet-async'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import {
  MutationLoginArgs,
  MutationLoginRequest,
  User,
  mutationLogin,
  mutationLogout,
  queryActiveFeatures,
  queryUser,
} from '@sportsyou/api'
import {
  Button,
  Card,
  Spinner,
  TextInput,
  useDialog,
} from '@sportsyou/react-dom-ui'
import { useFetchApi, useTimelineSubscription } from '@sportsyou/react-hooks'

import {
  clearUser,
  initializeUser,
  setUserLoggedIn,
} from '../../store/slices/UserSlice'
import { clearSponsoredPosts } from '../../store/slices/SponsoredPostsSlice'
import { clearPages } from '../../store/slices/CommunitiesSlice'
import useContentPortal from '../../UseContentPortal'

interface LoginProps {
  logout?: boolean
}

export const Login = (props: LoginProps) => {
  const [searchParams] = useSearchParams()
  // const { logout: analyticsLogout } = useAnalytics()
  const { sendBanner } = useDialog()
  const { state: locationState } = useLocation()
  const { stop: stopSubscription } = useTimelineSubscription()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const contentPortal = useContentPortal()

  // const { refetch: refetchAppFeatures } = useAppFeatures()

  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [password, setPassword] = useState('')
  const [showPasswordValidation, setShowPasswordValidation] = useState(false)
  const [showUserNameValidation, setShowUserNameValidation] = useState(false)
  const [user, setUser] = useState<User>()
  const [userNameValue, setUserNameValue] = useState('')

  const { fetch: getFeatures } = useFetchApi(queryActiveFeatures)
  const { fetch: getUser } = useFetchApi(queryUser)
  const { fetch: login } = useFetchApi(mutationLogin)
  const { fetch: logout } = useFetchApi(mutationLogout)

  function clearUserData() {
    dispatch(clearPages())
    dispatch(clearSponsoredPosts())
    dispatch(clearUser())
    dispatch(setUserLoggedIn(false))
    sessionStorage.clear()
    if (window.location.pathname !== '/verification.html') {
      const localStorageItemsToSave = [
        'signupCode', // save signupCode in case it was redirected from qr code page
        'redirect', // preserve redirect url
      ]
      const savedStorage = localStorageItemsToSave
        .map((key) => {
          const keyData = localStorage.getItem(key)
          if (keyData) {
            return {
              key,
              keyData,
            }
          }
          return null
        })
        .filter(Boolean)
      localStorage.clear()
      for (const storageData of savedStorage) {
        if (storageData?.key && storageData?.keyData) {
          localStorage.setItem(storageData.key, storageData.keyData)
        }
      }
    }
  }

  const checkUser = useCallback(async () => {
    const { data: _user, ok } = await getUser()
    if (ok && _user) {
      const { data: _features } = await getFeatures()
      localStorage.setItem('user-features', JSON.stringify(_features))

      const hasAccess = await contentPortal.verifyHasPortalAccess()
      if (hasAccess) {
        dispatch(initializeUser(_user))
        dispatch(setUserLoggedIn(true))
        setUser(_user)
      } else {
        sendBanner({
          message: 'You do not have access to any portal',
          status: 'danger',
        })
        clearUserData()
      }
    } else {
      clearUserData()
    }
    setIsLoading(false)
    setIsSubmitting(false)
  }, [dispatch, getFeatures, getUser])

  const handleLogout = useCallback(async () => {
    await logout()
    clearUserData()
    stopSubscription(true)
    // analyticsLogout()
    navigate('/login')
    // refetchAppFeatures()
  }, [dispatch, logout, navigate])

  const onSubmitLogIn = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      const params: MutationLoginArgs = {
        password,
      }

      // Check if username is email or phone-number
      if (userNameValue.includes('@')) {
        params.userId = userNameValue
      } else {
        params.phoneNumber = userNameValue
        params.phoneNumberCountryCode = 'US'
      }

      setIsSubmitting(true)
      const { error } = await login(params as MutationLoginRequest)
      if (error) {
        setShowUserNameValidation(true)
        setShowPasswordValidation(true)
        setIsSubmitting(false)
      } else {
        checkUser()
      }
    },
    [password, userNameValue, login, dispatch, checkUser]
  )

  const onChangeEmail = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setUserNameValue(e.target.value)
      if (showUserNameValidation) {
        setShowUserNameValidation(false)
      }
    },
    [showUserNameValidation]
  )

  const onChangePassword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setPassword(e.target.value)
      if (showPasswordValidation) {
        setShowPasswordValidation(false)
      }
    },
    [showPasswordValidation]
  )

  const handleLogInOut = useCallback(async () => {
    if (props.logout) {
      handleLogout()
    } else {
      if (!user) {
        checkUser()
      } else {
        if (locationState?.redirectTo) {
          navigate(locationState?.redirectTo)
        } else {
          navigate('/home')
        }
        // refetchAppFeatures()
      }
    }
  }, [props.logout, locationState, handleLogout, user, checkUser, navigate])

  useEffect(() => {
    handleLogInOut()
  }, [handleLogInOut])

  const renderCenterColumn = useCallback(() => {
    return (
      <Card style={{ marginBottom: 20 }}>
        <Card.Body style={{ padding: 20 }}>
          <LogInFormContainer>
            <h2>Log In</h2>
            <LogInForm onSubmit={onSubmitLogIn}>
              <TextInput
                autoComplete='username'
                containerStyle={{ width: '100%' }}
                data-testid='username'
                id='username'
                label='Email or Phone Number'
                onChange={onChangeEmail}
                placeholder={'Enter email or phone number'}
                showValidationMessage={showUserNameValidation}
                status={showUserNameValidation ? 'error' : undefined}
                type='text'
                value={userNameValue}
                validationMessage={
                  userNameValue.length === 0
                    ? 'Please Enter your user id'
                    : 'Invalid User Id or password'
                }
              />
              <TextInput
                autoCapitalize='off'
                autoComplete='current-password'
                containerStyle={{ marginTop: 20, width: '100%' }}
                data-testid='password'
                id='password'
                label='Password'
                onChange={onChangePassword}
                placeholder={'Enter password'}
                showPasswordToggle
                showValidationMessage={showPasswordValidation}
                status={showPasswordValidation ? 'error' : undefined}
                type='password'
                value={password}
                validationMessage={
                  password.length === 0
                    ? 'Please enter your password'
                    : 'Invalid User Id or password'
                }
              />

              <StyledButton
                disabled={isSubmitting}
                fullWidth
                id='login-button'
                loading={isSubmitting}
                type='submit'
              >
                Log in to your account
              </StyledButton>
            </LogInForm>
          </LogInFormContainer>
        </Card.Body>
      </Card>
    )
  }, [
    userNameValue,
    isSubmitting,
    onChangeEmail,
    onChangePassword,
    onSubmitLogIn,
    password,
    showUserNameValidation,
    showPasswordValidation,
  ])

  const renderLoading = useCallback(() => {
    return (
      <LogInFormContainer>
        <Spinner />
      </LogInFormContainer>
    )
  }, [])

  return (
    <>
      <Helmet>
        <title>sportsYou Log In</title>
      </Helmet>
      <Layout>{isLoading ? renderLoading() : renderCenterColumn()}</Layout>
    </>
  )
}

const Layout = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-top: 20px;
  width: 100%;
  @media all and (min-width: 768px) {
    padding-bottom: 40px;
  }
`
const LogInFormContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  // width: 300px;
`
const LogInForm = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 300px;
`
const StyledButton = styled(Button)`
  margin-top: 20px;
`

export default Login
