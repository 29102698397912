/**
 * Convert a number to an abbreviated human readable string
 * @param number The number to convert
 * @returns The human readable string
 * @example humanNumber(1000) // '1K'
 * @example humanNumber(1500000) // '1.5M'
 * @example humanNumber(-12345) // '-12.3K'
 * @example humanNumber('12') // '12'
 */
export default function humanNumber(number?: number | string | null): string {
  if (number === undefined || number === null) {
    return ''
  }
  const num = Number(number)
  if (isNaN(num)) {
    return ''
  }
  if (num === 0) {
    return '0'
  }
  if (num < 0) {
    return `-${humanNumber(-num)}`
  }
  if (num >= 1e12) {
    return `${(num / 1e12).toFixed(1).replace(/\.0$/, '')}T`
  }
  if (num >= 1e9) {
    return `${(num / 1e9).toFixed(1).replace(/\.0$/, '')}B`
  }
  if (num >= 1e6) {
    return `${(num / 1e6).toFixed(1).replace(/\.0$/, '')}M`
  }
  if (num >= 1e3) {
    return `${(num / 1e3).toFixed(1).replace(/\.0$/, '')}K`
  }
  return num.toFixed(1).replace(/\.0$/, '')
}
