export const checkColorValue = (color: string): string => {
  let colorHex: string = color
  if (colorHex[0] === '#') {
    colorHex = colorHex.slice(1)
  }

  if (colorHex.length === 3) {
    colorHex = colorHex
      .split('')
      .map((v) => v + v)
      .join('')
  }

  const rVal = parseInt(colorHex.substring(0, 2), 16)
  const gVal = parseInt(colorHex.substring(2, 4), 16)
  const bVal = parseInt(colorHex.substring(4, 6), 16)

  return rVal * 0.299 + gVal * 0.587 + bVal * 0.114 > 186
    ? '#000000'
    : '#ffffff'
}
