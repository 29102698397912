import * as React from 'react'
import type { SVGProps } from 'react'
const PlaylistPlay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 20 20'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <g>
      <g>
        <path d='M8 5.66l5 3.7a.8.8 0 0 1 .17 1.11.91.91 0 0 1-.17.17l-5 3.7a.79.79 0 0 1-1.12-.17.78.78 0 0 1-.15-.47V6.3a.8.8 0 0 1 .8-.8.74.74 0 0 1 .47.16z' />
      </g>
    </g>
  </svg>
)
export default PlaylistPlay
