import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

//@ts-ignore
// Importing this for google types
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'

import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
// import { usePlacesWidget } from 'react-google-autocomplete'

import { Colors } from '@sportsyou/core'
import { Location } from '@sportsyou/react-icons'
import { useClickAway, useWindowDimensions } from '@sportsyou/react-hooks'

import { BORDER_RADIUS } from '../../theme'
import { getBoundingBox } from '../../utils'
import TextInput from '../text-input'

interface Props {
  apiKey?: string
  className?: string

  containerStyle?: React.CSSProperties
  debounce?: number
  disabled?: boolean
  id?: string

  label?: string
  name?: string
  onClear?: () => void
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onPlaceSelected?: (place: google.maps.places.AutocompletePrediction) => void
  placeholder?: string
  /** Distance in px the places container will be from the input */
  placesGap?: number
  style?: React.CSSProperties
  inputStyle?: React.CSSProperties
  labelStyle?: React.CSSProperties
  inputContainerStyle?: React.CSSProperties
  validationStyle?: React.CSSProperties

  value?: string
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type LocationPickerProps = Props & NativeAttributes

export const LocationPicker: React.FC<LocationPickerProps> = ({
  apiKey,
  className,
  containerStyle,
  debounce = 500,
  disabled,
  id,
  label,
  name,
  onClear,
  onInputChange,
  onPlaceSelected,
  placeholder = 'Select a location',
  placesGap = 10,
  style,
  value = '',
  inputStyle,
  labelStyle,
  inputContainerStyle,
  validationStyle,
}: LocationPickerProps) => {
  const {
    // placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey,
    debounce,
  })

  const { height: windowHeight, width: windowWidth } = useWindowDimensions()

  const [inputValue, setInputValue] = useState<string>(value)
  const [isPlacesVisible, setIsPlacesVisible] = useState<boolean>(false)
  const [places, setPlaces] =
    useState<Array<google.maps.places.AutocompletePrediction>>(placePredictions)

  useEffect(() => {
    if (placePredictions.length) {
      setPlaces(placePredictions)
    }
  }, [placePredictions])

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const inputRef = useRef<HTMLInputElement | null>(null)

  const positionStyles: React.CSSProperties = useMemo(() => {
    if (!inputRef.current) return {}
    const { bottom, left, width: inputWidth } = getBoundingBox(inputRef)
    const width = inputRef.current.closest('label')?.clientWidth ?? inputWidth
    return {
      left,
      top: bottom + placesGap,
      width,
    }
  }, [inputRef.current, windowHeight, windowWidth, isPlacesVisible])

  // fetch place details for the first element in placePredictions array
  // useEffect(() => {
  //   if (placePredictions.length)
  //     placesService?.getDetails(
  //       {
  //         placeId: placePredictions[0].place_id,
  //       },
  //       (placeDetails) => {
  //         console.log({ placeDetails })
  //       }
  //     )
  // }, [placePredictions])

  const handleOnBlur = () => {
    setIsPlacesVisible(false)
  }

  const onFocus = () => {
    if (places.length > 0) {
      setIsPlacesVisible(true)
    }
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onInputChange?.(e)

    getPlacePredictions({ input: e.target.value })

    // TODO: wait for initial fetch to show results
    setTimeout(() => {
      if (!isPlacePredictionsLoading) setIsPlacesVisible(true)
    }, debounce)
    if (e.target.value === '') {
      setIsPlacesVisible(false)
    }
  }

  const handleOnClear = () => {
    onClear?.()
    setPlaces([])
    setIsPlacesVisible(false)
  }
  const handleOnClickPlace = (
    e: React.MouseEvent<HTMLDivElement>,
    place: google.maps.places.AutocompletePrediction
  ) => {
    e.stopPropagation()
    setInputValue(place.description)
    onPlaceSelected?.(place)
    setIsPlacesVisible(false)
  }

  useClickAway(inputRef, handleOnBlur)

  const renderPlace = (place: google.maps.places.AutocompletePrediction) => {
    return (
      <Place
        key={place.place_id}
        onClick={(e: React.MouseEvent<HTMLDivElement>) =>
          handleOnClickPlace(e, place)
        }
      >
        <Location fill={Colors.SHUTTLE_GRAY} style={{ marginRight: 6 }} />
        <span>{place.description}</span>
      </Place>
    )
  }

  return (
    <>
      <TextInput
        className={className}
        containerStyle={{ minWidth: 300, ...containerStyle }}
        disabled={disabled}
        isClearable
        inputContainerStyle={inputContainerStyle}
        inputStyle={{ textOverflow: 'ellipsis', ...inputStyle }}
        isLoading={isPlacePredictionsLoading}
        label={label}
        labelStyle={labelStyle}
        onChange={onChange}
        onClear={handleOnClear}
        onFocus={onFocus}
        placeholder={placeholder}
        ref={inputRef}
        style={style}
        type='text'
        validationStyle={validationStyle}
        value={inputValue}
      />
      {isPlacesVisible && (
        <PlacesContainer
          isLoading={isPlacePredictionsLoading}
          style={positionStyles}
        >
          {places.length === 0 && !isPlacePredictionsLoading ? (
            <Place>No results found</Place>
          ) : (
            places.map((item) => renderPlace(item))
          )}
        </PlacesContainer>
      )}
    </>
  )
}

const PlacesContainer = styled.div<{ isLoading: boolean }>`
  background-color: ${Colors.WHITE};
  border: 1px solid ${Colors.ALTO};
  box-sizing: border-box;
  box-shadow: 0 4px 8px -2px rgb(0 0 0 / 24%);
  border-radius: ${BORDER_RADIUS};
  margin-top: 0;
  max-height: 180px;
  overflow-y: auto;
  position: absolute;
  z-index: 10;
`
// ${({ isLoading }) =>
//   isLoading
//     ? {
//         alignItems: 'center',
//         display: 'flex',
//         justifyContent: 'center',
//       }
//     : undefined};
const Place = styled.div`
  align-items: center;
  cursor: pointer;

  display: flex;
  font-size: 14px;
  padding: 6px 10px;
  & + & {
    border-top: 1px solid ${Colors.ALTO};
  }

  &:hover,
  &:active {
    background-color: ${Colors.CATSKILL_WHITE};
  }
`

export default LocationPicker
