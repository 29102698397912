import * as React from 'react'
import type { SVGProps } from 'react'
const HomeAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M29.5128 12.7971 16.3637 3.73672c-.2473-.17313-.5819-.17313-.8291 0L2.4873 12.7971c-.33455.2309-.40728.6781-.17455 1.01.23273.3318.68364.4039 1.01818.1731l1.46909-1.0244v14.7449c0 .4039.32.7213.72728.7213h6.3273c.4072 0 .7272-.3174.7272-.7213v-4.5591c0-1.4139 1.1491-2.5392 2.56-2.5392h1.7455c1.4254 0 2.56 1.1397 2.56 2.5392v4.5591c0 .4039.32.7213.7273.7213h6.3127c.4073 0 .7273-.3174.7273-.7213V12.9703l1.4691 1.0099c.3345.2308.7854.1443 1.0181-.1876.2328-.3318.1455-.7791-.189-1.0099v.0144ZM25.76 26.9793h-4.8582v-3.8377c0-2.2074-1.7891-3.982-4.0145-3.982h-1.7455c-2.2254 0-4.0145 1.7746-4.0145 3.982v3.8377H6.25457V11.9459l9.68723-6.73758 9.8037 6.75198v15.019h.0145Z' />
  </svg>
)
export default HomeAlt
