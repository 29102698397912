import React from 'react'

import Tag, { TagProps } from './tag'

export interface SingleTagProps {
  fill?: string
  style?: React.CSSProperties
  title?: string
}

interface Props {
  className?: string
  items: SingleTagProps[]
  maxWidth?: number
  style?: React.CSSProperties
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type TagGroupProps = Props & NativeAttributes

const TagGroup: React.FC<TagGroupProps> = ({
  className,
  maxWidth,
  items = [],
  style,
}: TagGroupProps) => {
  return (
    <div className={className} style={style}>
      {items.length &&
        items.map((item: TagProps, index: number) => (
          <Tag
            fill={item.fill}
            index={index}
            key={index}
            maxWidth={maxWidth ?? item.maxWidth}
            style={item.style}
          >
            {item.title}
          </Tag>
        ))}
    </div>
  )
}

export default TagGroup
