import * as React from 'react'
import type { SVGProps } from 'react'
const NavHomeFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M31 12.31L16.56 2.18a1 1 0 0 0-1.12 0L1 12.31a1 1 0 0 0 1.12 1.6L3.45 13v16a1 1 0 0 0 1 1h7.24a1 1 0 0 0 1-1v-5.76a1.92 1.92 0 0 1 1.91-1.92h2.9a1.92 1.92 0 0 1 1.91 1.92V29a1 1 0 0 0 1 1h7.24a1 1 0 0 0 1-1V13l1.36.95a1 1 0 0 0 1.36-.24 1 1 0 0 0-.37-1.4z' />
  </svg>
)
export default NavHomeFilled
