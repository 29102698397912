import moment from 'moment'

export interface BirthdayValidationProps {
  birthday: string
  checkAge?: boolean
  dateFormat: string
}

export interface BirthdayValidationsReturnProps {
  error?: string
  validated: boolean
}

export const isBirthdayValid = ({
  birthday,
  dateFormat,
  checkAge,
}: BirthdayValidationProps): BirthdayValidationsReturnProps => {
  let validated = true
  let error: string | undefined

  const mDate = moment(birthday, dateFormat)

  // console.log({ birthday, isValid: mDate.isValid() })
  if (!mDate.isValid()) {
    const flags = mDate.parsingFlags()
    // console.log({ flags })
    switch (flags.overflow) {
      /**
       * although moment.js list 0 as overflow year, I couldn't actually trigger it
       * as moment truncates the yar beyond 9999 to 9999
       */
      // case 0:
      //   error = `Invalid year. Please use ${dateFormat} format.`
      //   break
      case 1:
        error = `Invalid month. Please use ${dateFormat} format.`
        break
      case 2:
        error = `Invalid day. Please use ${dateFormat} format.`
        break
      default: // -1
        error = `Invalid date. Please use ${dateFormat} format.`
    }
    validated = false
  } else {
    // console.log(mDate.toDate())
  }

  if (checkAge && validated) {
    const thirteenYearsOld = moment().subtract(13, 'years')
    const oneHundredTwentyYearsOld = moment().subtract(120, 'years')
    if (
      !mDate.isBetween(oneHundredTwentyYearsOld, thirteenYearsOld, 'day', '[]')
    ) {
      validated = false
      if (mDate.isAfter(thirteenYearsOld)) {
        error = 'YOU MUST BE OLDER THAN 13 TO JOIN'
      } else {
        error = `Invalid year. Please use ${dateFormat} format.`
      }
    }
  }

  return { error, validated }
}
