import * as React from 'react'
import type { SVGProps } from 'react'
const Calendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <g>
      <path d='M25.92 1.84h-2.18v-.72a1.13 1.13 0 0 0-2.25 0v.72h-11v-.72a1.13 1.13 0 0 0-2.25 0v.72H6.08A6.07 6.07 0 0 0 0 7.89V26a6.07 6.07 0 0 0 6.08 6h19.84A6.07 6.07 0 0 0 32 26V7.89a6.07 6.07 0 0 0-6.08-6.05zM6.08 4.07h2.18v.73a1.13 1.13 0 0 0 2.25 0v-.73h11v.73a1.13 1.13 0 0 0 2.25 0v-.73h2.18a3.83 3.83 0 0 1 3.83 3.82v2.16H2.25V7.89a3.83 3.83 0 0 1 3.83-3.82zm19.84 25.7H6.08A3.83 3.83 0 0 1 2.25 26V12.29h27.5V26a3.83 3.83 0 0 1-3.83 3.77z' />
      <path d='M22.24 24h-1.09v-7.1a1.13 1.13 0 0 0-1.88-.83l-2.41 2.17a1.11 1.11 0 0 0-.08 1.58 1.13 1.13 0 0 0 1.59.08l.53-.49V24h-1.09a1.12 1.12 0 1 0 0 2.23h4.43a1.12 1.12 0 1 0 0-2.23zm-10.42-8.2a3.26 3.26 0 0 0-3.14 2.39 1.11 1.11 0 0 0 .77 1.38 1.13 1.13 0 0 0 1.39-.76 1 1 0 0 1 1-.77 1.06 1.06 0 0 1 1.06 1.05.83.83 0 0 1-.83.82 1.12 1.12 0 1 0 0 2.23.83.83 0 0 1 .83.83A1.06 1.06 0 0 1 11.82 24a1 1 0 0 1-1-.77 1.12 1.12 0 0 0-2.16.62 3.28 3.28 0 0 0 6.44-.9 3 3 0 0 0-.7-1.94 3 3 0 0 0 .7-1.94 3.3 3.3 0 0 0-3.28-3.27z' />
    </g>
  </svg>
)
export default Calendar
