import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { Team, User } from '@sportsyou/api'
import { Colors } from '@sportsyou/core'

import Avatar from '../../avatar'
import Button from '../../button'
import Checkbox, { CheckboxEvent } from '../../checkbox'

// Posting to target type
export type PostTarget = {
  disabled?: boolean
  data?: User | Team
  label?: string
}

export interface PostingToProps {
  className?: string
  currentUser: User
  featured?: Array<PostTarget>
  isCurrentUserProfile?: boolean
  isPoll?: boolean
  isScheduledPost?: boolean
  onChange: (targetIds: string[]) => void
  style?: React.CSSProperties
  targetIds: string[]
  teamId?: string
  teams: Team[]
}

const INITIAL_LIST_LIMIT = 4

export const PostingTo: React.FC<PostingToProps> = ({
  className,
  currentUser,
  featured,
  isCurrentUserProfile,
  isPoll,
  isScheduledPost,
  onChange,
  style,
  targetIds,
  teamId,
  teams: allTeams = [],
}: PostingToProps) => {
  const [isGroupListExpanded, setIsGroupListExpanded] = useState<boolean>(false)
  const [isTeamListExpanded, setIsTeamListExpanded] = useState<boolean>(false)

  const isMounted = useRef(false)

  useEffect(() => {
    if (!targetIds.length && !isMounted.current) {
      if (teamId) {
        const isAdmin = allTeams?.filter((t) => t.id === teamId)[0].isAdmin
        if (isScheduledPost) {
          if (isAdmin) {
            onChange([teamId])
          }
        } else {
          onChange([teamId])
        }
      } else {
        onChange(
          isPoll || isScheduledPost
            ? []
            : isCurrentUserProfile
            ? [currentUser.id as string]
            : []
        )
      }
      isMounted.current = true
    }
  }, [
    allTeams,
    currentUser,
    isCurrentUserProfile,
    isPoll,
    isScheduledPost,
    onChange,
    targetIds.length,
    teamId,
  ])

  // List of all teams
  // - if teamId is defined, this will filter out that team
  // - if isScheduledPost this will disable all teams you are not admin of
  // - if isPoll this will filter out large teams
  const teams = useMemo(() => {
    const _teams = allTeams
      .filter((team) => team.id !== teamId && team.type === 'team')
      .filter((team) => (isPoll ? !team.isLargeTeam : team))
    return _teams.map((t) => ({
      data: t,
      disabled: isScheduledPost ? !t.isAdmin : false,
      label: t.name ?? undefined,
    })) as Array<PostTarget>
  }, [allTeams, isPoll, isScheduledPost, teamId])

  // List of all groups
  // - if teamId is defined, this will filter out that group
  // - if isScheduledPost this will disable all groups you are not admin of
  // - if isPoll this will filter out large groups
  const groups = useMemo(() => {
    const _groups = allTeams
      .filter((group) => group.id !== teamId && group.type === 'group')
      .filter((group) => (isPoll ? !group.isLargeTeam : group))
    return _groups.map((g) => ({
      data: g,
      disabled: isScheduledPost ? !g.isAdmin : false,
      label: g.name ?? undefined,
    })) as Array<PostTarget>
  }, [allTeams, isPoll, isScheduledPost, teamId])

  const handleOnToggleList = (type: 'team' | 'group') => {
    if (type === 'group') {
      setIsGroupListExpanded(!isGroupListExpanded)
    }
    if (type === 'team') {
      setIsTeamListExpanded(!isTeamListExpanded)
    }
  }

  const handleOnChangeItem = (e: CheckboxEvent, id: string) => {
    const newSelectedTeamIds = targetIds.includes(id)
      ? targetIds.filter((_id) => _id !== id)
      : [...targetIds, id]
    onChange(newSelectedTeamIds)
  }

  const getViewUrl = (item: any) => {
    return item.profileImage?.[0]?.viewUrl
  }

  const allGroupsSelected = useMemo(() => {
    if (!groups) return false
    const ids = groups.map((g) => g.data?.id as string)
    return ids.every((id) => targetIds.includes(id))
  }, [targetIds, groups])

  const allTeamsSelected = useMemo(() => {
    if (!teams) return false
    const ids = teams.map((t) => t.data?.id as string)
    return ids.every((id) => targetIds.includes(id))
  }, [targetIds, teams])

  const handleOnClickSelectAll = (type: 'groups' | 'teams') => {
    let newIds: Array<string> = []
    if (type === 'teams') {
      const ids = teams.map((t) => t.data?.id as string)
      if (allTeamsSelected) {
        newIds = targetIds.filter((id) => !ids.includes(id))
      } else {
        const newIdsToAdd = ids.filter((id) => id && !targetIds.includes(id))
        newIds = [...targetIds, ...newIdsToAdd]
      }
    } else {
      const ids = groups.map((g) => g.data?.id as string)
      if (allGroupsSelected) {
        newIds = targetIds.filter((id) => !ids.includes(id))
      } else {
        const newIdsToAdd = ids.filter((id) => id && !targetIds.includes(id))
        newIds = [...targetIds, ...newIdsToAdd]
      }
    }
    onChange(newIds)
  }

  const renderList = (type: 'team' | 'group') => {
    if (type === 'group') {
      const limit = isGroupListExpanded ? groups.length : INITIAL_LIST_LIMIT
      return groups.length === 0
        ? null
        : groups
            .filter((_, index: number) => index < limit)
            .map((g) => renderItem(g))
    }
    if (type === 'team') {
      const limit = isTeamListExpanded ? teams.length : INITIAL_LIST_LIMIT
      return teams.length === 0
        ? null
        : teams
            .filter((_, index: number) => index < limit)
            .map((t) => renderItem(t))
    }

    return null
  }

  const renderItem = ({
    disabled,
    data: item,
    label,
  }: {
    disabled?: boolean
    data?: Team | User
    label?: string
  }) => {
    if (!item) return null
    const { id } = item
    return (
      <StyledCheckbox
        checked={targetIds.includes(id!)}
        disabled={disabled}
        onChange={(e) => handleOnChangeItem(e, id!)}
        key={id}
        size={16}
        textContainerStyle={{
          maxWidth: 'calc(100% - 22px)',
        }}
      >
        <CheckboxContent>
          <StyledAvatar
            $disabled={!!disabled}
            diameter={32}
            name={(item as Team).name ?? (item as User).fullName ?? undefined}
            uri={getViewUrl(item)}
          />
          <RowText $disabled={!!disabled}>
            {(item as Team).name ?? label ?? 'Your Feed'}
          </RowText>
        </CheckboxContent>
      </StyledCheckbox>
    )
  }

  return (
    <div className={className} style={style}>
      <Section>{featured?.map((item) => renderItem(item))}</Section>
      {teams.length > 0 && (
        <Section>
          <SectionTitle>
            <Title>
              Teams <Count>{teams.length}</Count>
            </Title>
            <SelectAllButton
              appearance='minimal'
              collapse
              variant='secondary'
              onClick={() => handleOnClickSelectAll('teams')}
            >
              {allTeamsSelected ? 'Deselect' : 'Select'} All
            </SelectAllButton>
          </SectionTitle>
          {renderList('team')}
          {teams.length > INITIAL_LIST_LIMIT && (
            <ExpandButton
              appearance='minimal'
              collapse
              onClick={() => handleOnToggleList('team')}
              variant='secondary'
            >
              {isTeamListExpanded ? 'Less' : 'More'}
            </ExpandButton>
          )}
        </Section>
      )}
      {groups.length > 0 && (
        <Section>
          <SectionTitle>
            <Title>
              Groups <Count>{groups.length}</Count>
            </Title>
            <SelectAllButton
              appearance='minimal'
              collapse
              variant='secondary'
              onClick={() => handleOnClickSelectAll('groups')}
            >
              {allGroupsSelected ? 'Deselect' : 'Select'} All
            </SelectAllButton>
          </SectionTitle>
          {renderList('group')}
          {groups.length > INITIAL_LIST_LIMIT && (
            <ExpandButton
              appearance='minimal'
              collapse
              onClick={() => handleOnToggleList('group')}
              variant='secondary'
            >
              {isGroupListExpanded ? 'Less' : 'More'}
            </ExpandButton>
          )}
        </Section>
      )}
    </div>
  )
}

const Section = styled.div`
  border-bottom: 1px solid ${Colors.ALTO};
`
const SectionTitle = styled.div`
  align-items: center;
  color: ${Colors.PUNCH};
  display: flex;
  font-size: 14px;
  padding: 10px 10px 5px;
`
const Title = styled.span`
  font-weight: 700;
`
const Count = styled.span`
  color: ${Colors.DUSTY_GRAY};
  font-weight: 400;
`
const SelectAllButton = styled(Button)`
  margin-left: auto;
  min-height: 1px;
  padding: 4px;
`
const StyledCheckbox = styled(Checkbox)`
  display: flex;
  margin-right: 10px;
  max-width: 608px;
  padding: 10px 0 10px 10px;
  transition: background-color 120ms ease-in-out;

  &:hover,
  &:active {
    background-color: ${Colors.CATSKILL_WHITE};
  }
`
const CheckboxContent = styled.span`
  align-items: center;
  display: flex;
  margin-left: 4px;
`
const StyledAvatar = styled(Avatar)<{ $disabled: boolean }>`
  filter: ${({ $disabled }) => ($disabled ? 'grayscale(1)' : undefined)};
`
const RowText = styled.div<{ $disabled: boolean }>`
  color: ${({ $disabled }) => ($disabled ? Colors.DUSTY_GRAY : undefined)};
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const ExpandButton = styled(Button)`
  // margin-left: 10px;
  margin: 6px 10px 10px;
  min-height: 1px;
  min-width: 1px;
  padding: 6px;

  &:hover,
  &:active {
    text-decoration: underline;
  }
`

export default PostingTo
