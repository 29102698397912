import {
  ApiRequestOptions,
  ApiResponse,
  CacheOptions,
  StaticApiRequest,
  post,
} from '../api-base'

export interface GetMetadataRequest {
  requestOptions?: ApiRequestOptions
  url: string
}

export interface PostMetadata {
  description: string
  displayMetaData: boolean
  image: string
  status: string
  title: string
  url: string
  version: number
}

export interface GetMetadataResponse extends ApiResponse {
  data?: PostMetadata
  error?: any
}

export async function getMetadata(
  request: GetMetadataRequest
): Promise<GetMetadataResponse> {
  const req: StaticApiRequest = {
    action: 'getMetadata',
    url: request.url,
  }
  const reqString = JSON.stringify(req)
  let options = request?.requestOptions
  if (!options) {
    options = {
      useCache: CacheOptions.ALWAYS,
    }
  }
  try {
    const response = await post(req, options)
    return {
      data: response.data,
      error: response.error,
      ok: response.ok,
      requestString: reqString,
    }
  } catch (ex: any) {
    return { ok: false, error: ex, requestString: reqString }
  }
}

export default {
  getMetadata,
}
