import Modal from './modal'
import ModalHeader from './modal-header'
import ModalBody from './modal-body'
import ModalFooter from './modal-footer'

export type ModalType = typeof Modal & {
  Header: typeof ModalHeader
  Body: typeof ModalBody
  Footer: typeof ModalFooter
}
;(Modal as ModalType).Header = ModalHeader
;(Modal as ModalType).Body = ModalBody
;(Modal as ModalType).Footer = ModalFooter

export type { ModalProps } from './modal'

export default Modal as ModalType
