import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { createPortal } from 'react-dom'
import styled, { css, keyframes } from 'styled-components'

import { Colors, randomId } from '@sportsyou/core'
import { usePortal } from '@sportsyou/react-hooks'

import Button from '../button'
import Transition from '../css-transition'

import { getStatusColor, getStatusIcon } from '../../utils'
import { ActivityTypes, Placement, StatusTypes } from '../../utils/prop-types'
import hexToRGBA from '../../utils/color/hex-to-rgba'
import { useDialog } from '../../hooks/use-dialog'
import { BsX } from 'react-icons/bs'

import {
  ToastProps as Props,
  ToastAction,
  ToastPlacement,
  ToastTransition,
} from './toast-types'

type NativeAttributes = Omit<React.HTMLAttributes<HTMLElement>, keyof Props>
export type ToastProps = Props & NativeAttributes

export const Toast: React.FC<ToastProps> = ({
  actions,
  autoDismiss,
  className,
  delay = 1600,
  id = `toast-${randomId()}`,
  isVisible = false,
  light,
  status,
  onClose,
  placement = 'bottomEnd',
  style,
  message = 'Toast',
  transition = 'slide',
}: ToastProps) => {
  const portal = usePortal('dialog')

  const { toast, reset } = useDialog()

  const timer = useRef<number | undefined>()

  useEffect(() => {
    document.addEventListener('keyup', handleOnClose)

    return () => {
      document.removeEventListener('keyup', handleOnClose)
    }
  }, [])

  useEffect(() => {
    if (!toast.isVisible || !isVisible) return
    if (toast.autoDismiss || autoDismiss) {
      timer.current = window.setTimeout(() => {
        handleOnClose()
        console.log('Close')
        timer.current && clearTimeout(timer.current)
      }, toast.delay ?? delay)
    }
  }, [
    autoDismiss,
    delay,
    isVisible,
    toast.autoDismiss,
    toast.delay,
    toast.isVisible,
  ])

  const hasActionOrCloseButtons = useMemo(() => {
    const _actions =
      (toast.actions && toast.actions.length > 0) ||
      (actions && actions.length > 0)
    const _autoDismiss = toast.autoDismiss || autoDismiss
    return _actions || !_autoDismiss
  }, [actions, autoDismiss, toast.actions, toast.autoDismiss])

  const hasActions = useMemo(
    () =>
      (toast.actions && toast.actions.length > 0) ||
      (actions && actions.length > 0),
    [actions, toast.actions]
  )

  const toastStatus = useMemo(
    () => toast.status ?? status,
    [status, toast.status]
  ) as ActivityTypes | StatusTypes

  const handleOnClickAction = (
    event: React.MouseEvent<HTMLButtonElement>,
    action: ToastAction
  ) => {
    action.onClick(event)
    handleOnClose()
  }

  const handleOnClose = () => {
    onClose?.()
    toast.onClose?.()
    reset?.('toast')
  }

  const handleOnHover = (isHovered: boolean) => {
    if (toast.autoDismiss || autoDismiss) {
      if (isHovered) {
        return timer.current && clearTimeout(timer.current)
      }
      timer.current = window.setTimeout(() => {
        handleOnClose()
        timer.current && clearTimeout(timer.current)
      }, toast.delay ?? delay)
    }
  }

  if (!portal) return null

  return createPortal(
    <Transition
      clearTime={300}
      name={transition}
      visible={toast.isVisible || isVisible}
    >
      <Container
        $light={light}
        $placement={placement}
        $transition={transition}
        $slideDirection={placement.endsWith('End') ? 'left' : 'right'}
        aria-describedby={id}
        className={className}
        onMouseEnter={() => handleOnHover(true)}
        onMouseLeave={() => handleOnHover(false)}
        role='alert'
        style={{
          backgroundColor:
            toast.status || status
              ? hexToRGBA(getStatusColor(toastStatus), 10)
              : undefined,
          borderColor:
            toast.status || status ? getStatusColor(toastStatus) : undefined,
          color: toast.status || status ? Colors.MINE_SHAFT : undefined,

          ...style,
        }}
      >
        <TextContainer>
          {(toast.status || status) &&
            (toast.status === 'processing' || status === 'processing' ? (
              <ProcessingIconContainer>
                {getStatusIcon(toastStatus, {
                  fill: getStatusColor(toastStatus),
                  size: 16,
                })}
              </ProcessingIconContainer>
            ) : (
              getStatusIcon(toastStatus, {
                fill: getStatusColor(toastStatus),
                size: 16,
                style: {
                  marginRight: 6,
                },
              })
            ))}
          <Text id={id}>{toast.message || message}</Text>
        </TextContainer>
        {hasActionOrCloseButtons &&
          (hasActions ? (
            <div>
              {(toast.actions ?? actions)?.map(
                (action: ToastAction, index: number) => (
                  <ActionButton
                    appearance='minimal'
                    // collapse
                    hasStatus={!!status}
                    key={`${index}-toast-action`}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                      handleOnClickAction(event, action)
                    }
                    textStyle={{
                      color: light ? Colors.MINE_SHAFT : Colors.WHITE,
                    }}
                  >
                    {action.name}
                  </ActionButton>
                )
              )}
            </div>
          ) : (
            <CloseButton
              aria-label='Dismiss'
              type='button'
              onClick={handleOnClose}
            >
              <BsX size={22} />
            </CloseButton>
          ))}
      </Container>
    </Transition>,

    portal
  )
}

const Container = styled.div<{
  $placement: ToastPlacement
  $slideDirection: 'left' | 'right'
  $transition: ToastTransition
  $light?: boolean
}>`
  align-items: center;

  background-color: ${({ $light }) =>
    $light ? Colors.WHITE : Colors.MINE_SHAFT};
  border: 1px solid ${({ $light }) => ($light ? Colors.ALTO : Colors.BLACK)};
  border-radius: 4px;

  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;

  box-sizing: border-box;

  color: ${({ $light }) => ($light ? Colors.MINE_SHAFT : Colors.WHITE)};

  display: flex;
  justify-content: space-between;

  max-width: 100%;

  padding: 6px 16px;
  position: fixed;

  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  width: 400px;

  will-change: opacity, visibility;

  ${({ $placement }) => {
    if ($placement.startsWith('bottom')) {
      return $placement.endsWith('Start')
        ? {
            bottom: '20px',
            left: '20px',
          }
        : {
            bottom: '20px',
            right: '20px',
          }
    }
    return $placement.endsWith('Start')
      ? {
          left: '20px',
          top: '20px',
        }
      : {
          right: '20px',
          top: '20px',
        }
  }};

  ${({ $slideDirection, $transition }) => {
    // Initial transition styles
    if ($transition === 'slide') {
      return {
        opacity: 0,
        transform: `translate3d(${
          $slideDirection === 'left' ? '120%' : '-120%'
        }, 0, 0)`,
      }
    } else if ($transition === 'grow') {
      return {
        opacity: 0,
        transform: 'scale(0.4)',
      }
    } else {
      return {
        opacity: 0,
        visibility: 'hidden',
      }
    }
  }};

  &.fade-enter {
    opacity: 0;
    visibility: hidden;
  }
  &.fade-enter-active {
    opacity: 1;
    visibility: visible;
  }
  &.fade-leave {
    opacity: 1;
    visibility: visible;
  }
  &.fade-leave-active {
    opacity: 0;
    visibility: hidden;
  }

  &.grow-enter {
    opacity: 0;
    transform: scale(0.4);
  }
  &.grow-enter-active {
    opacity: 1;
    transform: scale(1);
  }
  &.grow-leave {
    opacity: 1;
    transform: scale(1);
  }
  &.grow-leave-active {
    opacity: 0;
    transform: scale(0.4);
  }
  &.slide-enter {
    opacity: 0;
    transform: translate3d(
      ${({ $slideDirection }) =>
        $slideDirection === 'left' ? '120%' : '-120%'},
      0,
      0
    );
  }
  &.slide-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.slide-leave {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.slide-leave-active {
    opacity: 0;
    transform: translate3d(
      ${({ $slideDirection }) =>
        $slideDirection === 'left' ? '120%' : '-120%'},
      0,
      0
    );
  }
`
const TextContainer = styled.div`
  align-items: center;
  display: flex;
`
const Text = styled.p`
  font-size: 0.85rem;
  line-height: 1.4;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  margin-right: 0.2rem;
`
const ActionButton = styled(Button)<{ hasStatus: boolean }>`
  // border-radius: 4px;
  min-height: 1px;
  min-width: 1px;
  padding-top: 6px;
  padding-bottom: 6px;
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.05);
    border-color: transparent;
  }
`
const SpinAnim = keyframes`
from { transform: rotate(0)};
to { transform: rotate(360deg)};
`
const ProcessingIconContainer = styled.div`
  animation: ${SpinAnim} 1200ms linear infinite;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 6px;
`

const CloseButton = styled.button`
  align-items: center;

  background: none;
  border: none;
  border-radius: 50%;

  color: inherit;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 10px;
  transition: background-color 160ms ease-in-out;
  width: 30px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  &:active {
    background-color: rgba(0, 0, 0, 0.12);
  }
`

export default Toast
