import React from 'react'
import styled from 'styled-components'

import { Upload as UploadProps } from '@sportsyou/api'
import { Colors } from '@sportsyou/core'

import Button from '../../button'
import FileTypeIcon from '../../file-type-icon'
import Icon, { IconProps } from '../../icon'

export interface PostAttachment extends UploadProps {
  onClickDownload?: (file: PostAttachment) => void
  onClickFile?: (file: PostAttachment) => void
  onClickSave?: (file: PostAttachment) => void
  onClickView?: (file: PostAttachment) => void
  renderRightContent?: React.ReactElement
}

interface Props {
  attachments?: Array<PostAttachment>
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type PostAttachmentsProps = Props & NativeAttributes

export const PostAttachments: React.FC<PostAttachmentsProps> = ({
  attachments,
}: PostAttachmentsProps) => {
  if (!attachments || attachments.length === 0) return null

  const handleOnClickDownload = (attachment: PostAttachment) => {
    attachment.onClickDownload && attachment.onClickDownload(attachment)
  }

  const handleOnClickSave = (attachment: PostAttachment) => {
    attachment.onClickSave && attachment.onClickSave(attachment)
  }

  const handleOnClickView = (attachment: PostAttachment) => {
    attachment.onClickView && attachment.onClickView(attachment)
  }

  const SHARED_ICON_PROPS = {
    fill: Colors.MINE_SHAFT,
    height: 16,
    width: 16,
  } as IconProps

  return attachments.map((attachment: PostAttachment) => (
    <Container key={attachment.id}>
      <FileContainer>
        <StyledFileTypeIcon
          contentType={attachment.contentType ?? ''}
          fileName={attachment.fileName ?? ''}
          nameCase='pascal'
          size={36}
        />
        <Filename
          onClick={() => handleOnClickView(attachment)}
          title={`View ${attachment.fileName}` ?? 'View File'}
        >
          {attachment.fileName}
        </Filename>
      </FileContainer>
      <ActionsContainer>
        {attachment.renderRightContent ? attachment.renderRightContent : null}
        {attachment.onClickView ? (
          <ActionButton
            appearance='minimal'
            collapse
            onClick={() => handleOnClickView(attachment)}
            textStyle={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
            title='View file'
            tooltip='View'
            variant='alternate'
          >
            <Icon {...SHARED_ICON_PROPS} name='SearchLine' />
          </ActionButton>
        ) : null}
        {attachment.onClickSave ? (
          <ActionButton
            appearance='minimal'
            collapse
            onClick={() => handleOnClickSave(attachment)}
            textStyle={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
            title='Save file'
            tooltip='Save to Folders'
            variant='alternate'
          >
            <Icon {...SHARED_ICON_PROPS} name='FolderLine' />
          </ActionButton>
        ) : null}
        {attachment.onClickDownload ? (
          <ActionButton
            appearance='minimal'
            collapse
            onClick={() => handleOnClickDownload(attachment)}
            textStyle={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
            title='Download File'
            tooltip='Download'
            variant='alternate'
          >
            <Icon {...SHARED_ICON_PROPS} name='DownloadLine' />
          </ActionButton>
        ) : null}
      </ActionsContainer>
    </Container>
  ))
}

const StyledFileTypeIcon = styled(FileTypeIcon)`
  flex: 0 0 36px;
`

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: space-between;
  margin-left: 10px;
  margin-top: 20px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;

  &:not(:first-child) {
    margin-top: 10px;
  }
`
const FileContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1 0 auto;
  flex-shrink: 1;
  margin-right: 10px;
  overflow: hidden;
`
const Filename = styled.span`
  color: ${Colors.HAVELOCK_BLUE};
  cursor: pointer;
  display: block;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover,
  &:active {
    text-decoration: underline;
  }
`

const ActionsContainer = styled.div`
  align-items: center;
  display: flex;
`
const ActionButton = styled(Button)`
  border-radius: 50%;
  height: 30px;
  width: 30px;
  &:not(:first-child) {
    margin-left: 15px;
  }
`

export default PostAttachments
