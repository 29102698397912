import type { ToastItemProps } from '../components/toast2/toast-types'

type ToastReducerAction = {
  type: 'ADD_TOAST' | 'DELETE_TOAST'
  payload?: string | ToastItemProps
}

export const toastReducer = (
  state: {
    toasts: any[]
  },
  action: ToastReducerAction
) => {
  switch (action.type) {
    case 'ADD_TOAST':
      return {
        ...state,
        toasts: [...state.toasts, action.payload],
      }
    case 'DELETE_TOAST': {
      const updatedToasts = state.toasts.filter(
        (toast: ToastItemProps) => toast.id !== action.payload
      )
      return {
        ...state,
        toasts: updatedToasts,
      }
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}
