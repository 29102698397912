import React, { useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import styled from 'styled-components'

import { Colors } from '@sportsyou/core'
import { useClickAnywhere, usePortal, useResize } from '@sportsyou/react-hooks'

import {
  getBoundingBox,
  getIconPosition,
  getPositionWithParent,
} from '../../utils'
import { Placement, PositionProps } from '../../utils/prop-types'

interface Props {
  arrowOffset?: number
  contentClassName?: string
  contentStyle?: React.CSSProperties
  hideArrow?: boolean
  id: string
  isVisible: boolean
  offset?: number
  parent?: React.MutableRefObject<HTMLElement | null> | undefined
  placement?: Placement
  tooltipClassName?: string
  tooltipOffset?: number
  tooltipStyle?: React.CSSProperties
}

export const TooltipContent: React.FC<React.PropsWithChildren<Props>> = ({
  arrowOffset,
  children,
  contentClassName,
  contentStyle,
  id,
  isVisible,
  hideArrow,
  parent,
  placement = 'top' as Placement,
  tooltipClassName,
  tooltipStyle,
  tooltipOffset,
}) => {
  const selfRef = useRef<HTMLDivElement>(null)

  const el = usePortal('tooltip')

  const defaultPosition = {
    top: '-1000px',
    left: '-1000px',
    transform: 'none',
  }

  const [boundingBox, setBoundingBox] = useState<PositionProps>(defaultPosition)

  const { bottom, left, right, top, transform } = useMemo(
    () => getIconPosition(placement, arrowOffset),
    [arrowOffset, placement]
  )

  useEffect(() => {
    updateBoundingBox()
  }, [isVisible])

  const updateBoundingBox = () => {
    if (!parent) return //null
    const position = getPositionWithParent(
      placement,
      getBoundingBox(parent),
      tooltipOffset
    )
    setBoundingBox(position)
  }

  useResize(updateBoundingBox)

  useClickAnywhere(() => updateBoundingBox())

  const handleOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }

  if (!el) return null

  return createPortal(
    isVisible ? (
      <Tooltip
        $boundingBox={boundingBox}
        aria-hidden={isVisible}
        className={tooltipClassName}
        id={id}
        ref={selfRef}
        style={tooltipStyle}
        onClick={handleOnClick}
      >
        {!hideArrow && (
          <Arrow
            style={{
              bottom,
              left,
              right,
              top,
              transform,
            }}
          />
        )}
        <Content className={contentClassName} style={contentStyle}>
          {children}
        </Content>
      </Tooltip>
    ) : null,
    el
  )
}

const Tooltip = styled.div<{ $boundingBox: PositionProps }>`
  box-shadow: none;
  background-color: rgb(0 0 0 / 90%);
  border-radius: 4px;
  box-sizing: border-box;
  color: ${Colors.WHITE};
  height: auto;

  left: ${({ $boundingBox }) => $boundingBox.left};
  padding: 0;
  position: absolute;
  top: ${({ $boundingBox }) => $boundingBox.top};
  transform: ${({ $boundingBox }) => $boundingBox.transform};

  width: auto;
  z-index: 1000;
`

const Arrow = styled.span`
  border-color: transparent rgb(0 0 0 / 90%) transparent transparent;
  border-style: solid;
  border-width: 6px 7px 6px 0;
  height: 0;
  position: absolute;
  width: 0;
`

const Content = styled.div`
  box-sizing: border-box;
  font-family: inherit;
  position: relative;
  font-size: 0.8rem;

  padding: 10px 12px;
  height: 100%;
`

export default TooltipContent
