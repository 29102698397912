import { Colors } from '@sportsyou/core'
import * as Icons from '@sportsyou/react-icons'
import { useMemo } from 'react'

interface Props {
  className?: string
  height?: number
  fill?: string
  name: Icons.IconNames
  props?: React.SVGProps<SVGSVGElement>
  size?: number | string
  style?: React.CSSProperties
  testId?: string
  width?: number
}

type NativeAttributes = Omit<
  React.HTMLAttributes<HTMLOrSVGElement>,
  keyof Props
>
export type IconProps = Props & NativeAttributes

export const Icon = ({
  className,
  height = 20,
  fill,
  name,
  size,
  style,
  testId,
  width = 20,
  ...props
}: IconProps) => {
  const _size = useMemo(() => {
    if (size) {
      return typeof size === 'number'
        ? {
            height: `${size}px`,
            width: `${size}px`,
          }
        : {
            height: size,
            width: size,
          }
    }
    return { height, width }
  }, [height, size, width])

  const IconComponent = Icons[name]
  if (!IconComponent) {
    // Show a warning for missing icons
    return (
      <Icons.X
        className={className}
        data-testid={testId}
        height={_size.height}
        style={style}
        width={_size.width}
        {...props}
        fill={Colors.MONZA}
      />
    )
  }

  return (
    <IconComponent
      className={className}
      data-testid={testId}
      fill={fill}
      height={_size.height}
      style={style}
      width={_size.width}
      {...props}
    />
  )
}

export default Icon
