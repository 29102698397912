import * as React from 'react'
import type { SVGProps } from 'react'
const FabUsers = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path
      stroke='#2e3191'
      strokeMiterlimit={10}
      strokeWidth='.25px'
      d='M23 16.89a2.75 2.75 0 1 0-2.74-2.75A2.75 2.75 0 0 0 23 16.89zm0-4.36a1.62 1.62 0 1 1-1.61 1.61A1.62 1.62 0 0 1 23 12.53zM16 15.48a3.93 3.93 0 1 0-4-3.93 3.93 3.93 0 0 0 4 3.93zm0-6.72a2.8 2.8 0 1 1-2.8 2.79A2.79 2.79 0 0 1 16 8.76zM9 16.89a2.75 2.75 0 1 0-2.75-2.75A2.75 2.75 0 0 0 9 16.89zm0-4.36a1.62 1.62 0 0 1 0 3.23 1.62 1.62 0 1 1 0-3.23zM24.24 18.37h-.86a.57.57 0 1 0 0 1.13h.86a2.13 2.13 0 0 1 2.13 2.13v1.18a.57.57 0 1 0 1.13 0v-1.18a3.26 3.26 0 0 0-3.26-3.26zM8.62 18.37h-.86a3.26 3.26 0 0 0-3.26 3.26v1.18a.57.57 0 1 0 1.13 0v-1.18a2.13 2.13 0 0 1 2.13-2.13h.86a.57.57 0 1 0 0-1.13zM17.93 17.23H14a4.34 4.34 0 0 0-4.34 4.33v1.73a.57.57 0 0 0 1.14 0v-1.73a3.2 3.2 0 0 1 3.2-3.2h4a3.21 3.21 0 0 1 3.21 3.2v1.73a.56.56 0 0 0 .56.56.57.57 0 0 0 .57-.56v-1.73a4.34 4.34 0 0 0-4.41-4.33z'
    />
  </svg>
)
export default FabUsers
