import React from 'react'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'

interface Props {
  className?: string
  style?: React.CSSProperties
}

type NativeAttributes = Omit<React.HTMLAttributes<HTMLElement>, keyof Props>
export type AccordionHeaderProps = Props & NativeAttributes

export const AccordionHeader: React.FC<
  React.PropsWithChildren<AccordionHeaderProps>
> = ({ children, className, style }: AccordionHeaderProps) => {
  return (
    <Container className={className} style={style}>
      {children}
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  border-bottom: 1px solid ${Colors.ALTO};
  display: flex;
  justify-content: space-between;
  min-height: 52px;
  padding-left: 10px;
  padding-right: 10px;
`

export default AccordionHeader
