/**
 * Utility to read session cookies from http response headers
 * and combine them into an object that can be passed around
 */

const COOKIE_SESSION_STATE = 'session_state'
const COOKIE_SOCK_STATE = 'sock_state'

export const HEADER_SET_COOKIE = 'set-cookie'

export class ServerSession {
  private _sessionState?: string
  private _sockState?: string

  constructor(sessionState?: string, sockState?: string) {
    this._sessionState = sessionState
    this._sockState = sockState
  }

  cookieString(): string {
    let ret = ''
    if (this._sessionState) {
      ret += `${COOKIE_SESSION_STATE}=${this._sessionState};`
    }
    if (this._sockState) {
      ret += `${COOKIE_SOCK_STATE}=${this._sockState};`
    }
    return ret
  }
}

function getCookieValue(
  cookieString: string,
  cookieName: string
): string | undefined {
  let ndx = cookieString.indexOf(cookieName + '=')
  if (ndx === -1) {
    return undefined
  }
  ndx += cookieName.length + 1
  let ndx2 = cookieString.indexOf(';', ndx)
  if (ndx2 === -1) {
    ndx2 = cookieString.length
  }
  return cookieString.substring(ndx, ndx2)
}

export function getServerSession(headers: Headers): ServerSession {
  const cookieString = headers.get(HEADER_SET_COOKIE) ?? ''
  const sockStateCookie = getCookieValue(cookieString, COOKIE_SOCK_STATE)
  const sessionStateCookie = getCookieValue(cookieString, COOKIE_SESSION_STATE)
  return new ServerSession(sessionStateCookie, sockStateCookie)
}

export default {
  ServerSession,
  getServerSession,
}
