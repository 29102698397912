import * as React from 'react'
import type { SVGProps } from 'react'
const NavBellFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M26.33 19.71l-.92-.93a1.93 1.93 0 0 1-.57-1.35v-4a7.54 7.54 0 0 0-5.21-7.17v-.63a3.63 3.63 0 1 0-7.26 0v.58a7.54 7.54 0 0 0-5.21 7.17v4a1.93 1.93 0 0 1-.57 1.35l-.92.93a3.86 3.86 0 0 0-1.14 2.73 3.72 3.72 0 0 0 3.71 3.72h3.51a4.27 4.27 0 0 0 8.5 0h3.51a3.72 3.72 0 0 0 3.71-3.72 3.86 3.86 0 0 0-1.14-2.68zm-12-14.08a1.68 1.68 0 1 1 3.36 0v.23h-3.37zM16 28.05a2.35 2.35 0 0 1-2.3-1.89h4.59A2.34 2.34 0 0 1 16 28.05z' />
  </svg>
)
export default NavBellFilled
