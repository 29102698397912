import * as React from 'react'
import type { SVGProps } from 'react'
const PlaylistPause = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fillRule='evenodd'
    strokeLinejoin='round'
    strokeMiterlimit={2}
    clipRule='evenodd'
    viewBox='0 0 20 20'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path
      fillRule='nonzero'
      d='M12.63 5.474h.15c.772 0 1.408.636 1.408 1.409v6.234c0 .773-.636 1.41-1.409 1.41h-.15a1.415 1.415 0 0 1-1.408-1.41V6.883c0-.773.636-1.41 1.408-1.41zm-5.41 0h.15c.773 0 1.41.636 1.41 1.409v6.234c0 .773-.637 1.41-1.41 1.41h-.15a1.415 1.415 0 0 1-1.408-1.41V6.883c0-.773.636-1.41 1.409-1.41z'
    />
  </svg>
)
export default PlaylistPause
