import _ from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { Colors, getThumbnail, getTranscodeUrl } from '@sportsyou/core'
import { Button, FileInput, Modal, Spinner } from '@sportsyou/react-dom-ui'
import { ExtendedUpload, useFetchApi } from '@sportsyou/react-hooks'
import {
  Image as ImageProps,
  mutationUploadDelete,
  MutationUploadDeleteRequest,
  mutationUploadUpdateVideoPosterImage,
  MutationUploadUpdateVideoPosterImageRequest,
  queryUploadUrl,
  QueryUploadUrlRequest,
  Upload,
} from '@sportsyou/api'

import { X } from '@sportsyou/react-icons'

interface VideoThumbnailUpdateModalProps {
  isOpen: boolean
  isPostMedia?: boolean
  onClose?: Function
  videoFile?: Upload
}

export default function VideoThumbnailUpdateModal(
  props: VideoThumbnailUpdateModalProps
) {
  const { isOpen, isPostMedia, videoFile } = props

  const [image, setImage] = useState<Upload | undefined>()
  const [isUpdating, setisUpdating] = useState(false)
  const [pendingUpload, setPendingUpload] = useState<Upload | undefined>()
  const [shouldCloseModal, setShouldCloseModal] = useState(!isOpen)
  const [video, setVideo] = useState<Upload | undefined>(videoFile)

  const imageToUpdate = useRef<Upload | undefined>(undefined)

  const { fetch: deleteImage } = useFetchApi(mutationUploadDelete)
  const { fetch: updatePosterImage } = useFetchApi(
    mutationUploadUpdateVideoPosterImage
  )
  const { fetch: getUploadInfo } = useFetchApi(queryUploadUrl)

  const isThumbnailUpdated = useCallback(
    (originalVideo?: Upload, newVideo?: Upload, _image?: Upload): boolean => {
      const propsThumb: string | null | undefined =
        originalVideo?.transcodes?.find(
          (transcode) => transcode?.transcodeType === 'THUMB'
        )?.viewUrl
      let newThumb: string | null | undefined
      if (isPostMedia) {
        newThumb =
          _image &&
          getTranscodeUrl({
            upload: (_image.transcodes ?? []) as ImageProps[],
            transcodeTypes: ['feedLarge'],
          })
      } else {
        newThumb = newVideo?.transcodes?.find(
          (transcode) => transcode?.transcodeType === 'THUMB'
        )?.viewUrl
      }

      return propsThumb !== newThumb
    },
    [isPostMedia]
  )

  const onModalClose = useCallback(() => {
    setPendingUpload(undefined)
    setImage(undefined)
    const thumbIsUpdated = isThumbnailUpdated(
      videoFile,
      video,
      imageToUpdate.current
    )
    props.onClose?.(thumbIsUpdated, video, imageToUpdate.current)
    setShouldCloseModal(false)
  }, [isThumbnailUpdated, props, video, videoFile])

  function onTranscodeComplete(upload: Upload | undefined): void {
    console.log('onTranscodeComplete', { upload })
    if (upload) {
      setPendingUpload(undefined)
      setTimeout(() => setImage(upload), 1)
    }
  }

  function onClickCancelButton() {
    setVideo(videoFile)
    imageToUpdate.current = undefined
    setShouldCloseModal(true)
  }

  async function onClickUpdatePosterImageButton() {
    if (!video?.id || !image?.id || isUpdating) return

    if (isPostMedia) {
      const thumbUrl = getTranscodeUrl({
        upload: (image.transcodes ?? []) as ImageProps[],
        transcodeTypes: ['feedLarge'],
      })
      const _video = _.cloneDeep(video)
      const thumbTranscode = _video.transcodes?.find(
        (transcode) => transcode?.transcodeType === 'THUMB'
      )
      if (thumbUrl && thumbTranscode) {
        thumbTranscode.viewUrl = thumbUrl
        setVideo(_video)
        imageToUpdate.current = image
        setTimeout(() => setImage(undefined), 1)
      }
    } else {
      setisUpdating(true)

      const res = await updatePosterImage({
        uploadId: video.id,
        posterImageId: image.id,
      } as MutationUploadUpdateVideoPosterImageRequest).catch((error) =>
        console.error(error)
      )

      if (res?.ok) {
        setImage(undefined)
        await reloadVideo()
      }

      setisUpdating(false)
    }

    // setShouldCloseModal(true)
  }

  function onUploaderCancel(cancelledUpload: ExtendedUpload | undefined): void {
    console.log('NICK onUploaderCancel', { cancelledUpload })
    if (cancelledUpload?.id) {
      deleteImage({ id: cancelledUpload.id } as MutationUploadDeleteRequest)
      setImage(undefined)
      setPendingUpload(undefined)
    }
  }

  function onUploaderUploadDone(
    newUploads: ExtendedUpload[],
    currentUpload: ExtendedUpload
  ): void {
    console.log('onUploaderUploadDone', { newUploads, currentUpload })
    // Upload is actually done when the transcoding is complete
  }

  function onUploaderError(
    newUploads: ExtendedUpload[],
    cancelledUpload: ExtendedUpload
  ): void {
    console.log('onUploaderError', { newUploads, cancelledUpload })
    if (cancelledUpload?.id) {
      deleteImage({ id: cancelledUpload.id } as MutationUploadDeleteRequest)
      setImage(undefined)
      setPendingUpload(undefined)
    }
  }

  function onUploaderUploadProgress(
    _uploads: ExtendedUpload[],
    currentUpload: ExtendedUpload
  ) {
    // setPendingUpload(currentUpload)
  }

  function onUploaderUploadStart(
    newUploads: ExtendedUpload[],
    currentUpload: ExtendedUpload
  ): void {
    console.log('onUploaderUploadStart', { newUploads, currentUpload })
    setPendingUpload(currentUpload)
  }

  async function reloadVideo() {
    if (video?.id) {
      const res = await getUploadInfo({
        uploadId: video.id,
      } as QueryUploadUrlRequest).catch((error) => console.error(error))
      if (res?.data) {
        setVideo(res.data)
      }
    }
  }

  useEffect(() => {
    videoFile && setVideo(videoFile)
  }, [videoFile])

  useEffect(() => {
    shouldCloseModal && onModalClose()
  }, [onModalClose, shouldCloseModal])

  return (
    <Modal contentWidth={'medium'} onClose={onModalClose} visible={isOpen}>
      <Modal.Header>
        <b>Video Poster Image Update</b>
      </Modal.Header>
      <Modal.Body>
        <Columns>
          <ColumnLeft>
            {!!video && (
              <UploadContainer key={video.id}>
                <img alt={video.fileName!} src={getThumbnail(video)} />
              </UploadContainer>
            )}
          </ColumnLeft>
          <ColumnRight>
            {(!!image || !!pendingUpload) && (
              <>
                {!!pendingUpload && (
                  <UploadContainer key={pendingUpload.id}>
                    <Spinner size={14} fill={Colors.WHITE} />
                    <div
                      className='remove'
                      onClick={() => {
                        onUploaderCancel(pendingUpload as ExtendedUpload)
                      }}
                      title='Remove'
                    >
                      <X fontSize={20} />
                    </div>
                  </UploadContainer>
                )}
                {!!image && (
                  <UploadContainer key={image.id}>
                    <img alt={image.fileName!} src={getThumbnail(image)} />
                    <div
                      className='remove'
                      onClick={() => {
                        onUploaderCancel(image as ExtendedUpload)
                      }}
                      title='Remove'
                    >
                      <X />
                    </div>
                  </UploadContainer>
                )}
              </>
            )}
          </ColumnRight>
        </Columns>
      </Modal.Body>
      <Modal.Footer>
        <FooterContainer>
          {/* {!!pendingUpload?.id ||
            (!image?.id && ( */}
          <FileInput
            acceptedFileTypes={
              'image/jpeg,image/png,image/heic,image/heif,image/webp,image/tiff'
            }
            // hideSelectFileButton={!!image?.id}
            maxFiles={1}
            onTranscodeComplete={onTranscodeComplete}
            onUploaderCancel={onUploaderCancel}
            onUploaderError={onUploaderError}
            onUploaderUploadDone={onUploaderUploadDone}
            onUploaderUploadProgress={onUploaderUploadProgress}
            onUploaderUploadStart={onUploaderUploadStart}
            onlySelectFileButton
            uploads={image && ([image] as ExtendedUpload[])}
          />
          {/* ))} */}
          <Button
            disabled={!image?.id}
            onClick={onClickUpdatePosterImageButton}
            variant='primary'
          >
            Update Poster Image
          </Button>
          {isPostMedia && (
            <Button
              disabled={!image?.id}
              onClick={onClickCancelButton}
              variant='secondary'
            >
              Cancel
            </Button>
          )}
        </FooterContainer>
      </Modal.Footer>
    </Modal>
  )
}

const Columns = styled.div`
  display: flex;
  // flex-direction: row;
  // width: 100%;
  // width: 700px;
  width: 650px;
`

const ColumnLeft = styled.div`
  display: flex;
  // flex-direction: column;
  margin-right: 5px;
  width: 100%;
`

const ColumnRight = styled.div`
  display: flex;
  // flex-direction: column;
  margin-left: 5px;
  width: 100%;
`

const UploadContainer = styled.div`
  align-items: center;
  background-color: ${Colors.BLACK};
  border-radius: 8px;
  color: ${Colors.DUSTY_GRAY};
  display: flex;
  height: 320px;
  justify-content: center;
  // margin-bottom: 10px;
  // margin-right: 10px;
  overflow: hidden;
  position: relative;
  width: 320px;

  .remove {
    align-items: center;
    background-color: ${Colors.BLACK};
    border-radius: 50%;
    color: ${Colors.WHITE};
    cursor: pointer;
    display: none;
    height: 30px;
    justify-content: center;
    position: absolute;
    padding: 2px;
    right: 0;
    top: 0;
    width: 30px;
  }

  &:hover {
    .remove {
      display: flex;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 0;
`
