// TODO: Add close icon?
import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react'

import styled, { css } from 'styled-components'

import { checkColorValue, Colors, darken } from '@sportsyou/core'
import { useClickAway } from '@sportsyou/react-hooks'

import FabItem from './fab-item'
import FabDivider from './fab-divider'
import Transition from '../css-transition'
import Icon from '../icon'

interface Props {
  buttonClassName?: string
  buttonStyle?: React.CSSProperties
  containerClassName?: string
  /**
   * Style of overall container of Floating Action Button
   */
  containerStyle?: React.CSSProperties
  /**
   * Whether or not the floating action button is disabled
   * @default false
   */
  disabled?: boolean
  /**
   * Background color of floating action button
   * @default HAVELOCK_BLUE
   */
  fill?: string
  /** TODO: how do we handle custom icons? */
  icon?: string | React.ReactNode
  /** Label for floating action button */
  label?: string
  /** Function called when floating action button is clicked */
  onClick?: () => void
  /** Function called when menu is closed */
  onMenuClose?: () => void
  /** Function called when menu is opened */
  onMenuOpen?: () => void
  /** Display label at all times */
  persistentLabel?: boolean
  title?: string
}

type NativeAttributes = Omit<React.ButtonHTMLAttributes<any>, keyof Props>
export type FabProps = Props & NativeAttributes

export type FabItemProps = React.ReactNode | string

/**
 * The floating action button (Fab) can be used for the primary action on a
 * screen or as a menu with multiple actions.
 *
 * Adding the `onClick` prop to the Fab will allow it to be used as a primary
 * button, while adding `Fab.Item` as children will build a menu to hold
 * multiple actions.
 *
 * Each `Item` will have an `onClick` prop to handle events. Props for the
 * `Fab.Item` can be found [here](#fabitem-props).
 */
export const Fab: FC<PropsWithChildren<FabProps>> = ({
  buttonStyle,
  buttonClassName,
  children,
  className,
  containerClassName,
  containerStyle,
  disabled = false,
  fill = Colors.HAVELOCK_BLUE,
  icon,
  label,
  onClick,
  onMenuClose,
  onMenuOpen,
  persistentLabel = false,
  style,
  title,
}: FabProps): React.ReactElement => {
  const menuRef = useRef<HTMLDivElement>(null)

  const [actions, setActions] = useState<Array<FabItemProps>>([])
  const [isLabelVisible, setIsLabelVisible] = useState<boolean>(persistentLabel)
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false)
  const [isVisible, setIsVisible] = useState<boolean>(true)

  const getActions =
    React.Children.map(children, (item) => {
      if (!React.isValidElement(item)) return null
      if (item.type === FabItem) {
        return item
      } else if (item.type === FabDivider) {
        return 'divider'
      }
      return null
    }) ?? []

  useEffect(() => {
    setIsVisible(true)
    if (React.Children) setupFabActions()
  }, [])

  useEffect(() => {
    setupFabActions()
  }, [children])

  const setupFabActions = () => {
    if (React.Children) {
      const currentActions = getActions as FabItemProps[]
      setActions(currentActions)
    }
  }

  const handleOnClick = () => {
    if (actions.length && !isMenuVisible) {
      setIsMenuVisible(true)
      onMenuOpen && onMenuOpen()
    }
    onClick && onClick()
  }

  const handleOnClickAction = (clickEvent: () => void) => {
    clickEvent && clickEvent()
    setIsMenuVisible(false)
    onMenuClose && onMenuClose()
  }

  const handleMouseEvent = (hovered: boolean) => {
    if (persistentLabel) return
    setIsLabelVisible(hovered)
  }

  const hideFabMenu = (nextState: boolean) => {
    if (isMenuVisible) return
    setIsMenuVisible(nextState)
  }

  useClickAway(menuRef, () => hideFabMenu(false))

  return (
    <Transition name='fab' enterTime={0} visible={isVisible}>
      <Container className={containerClassName} style={containerStyle}>
        {label && (
          <Transition
            name='fab-label'
            visible={persistentLabel || (isLabelVisible && !isMenuVisible)}
          >
            <Label>
              <span>{label}</span>
            </Label>
          </Transition>
        )}
        <Button
          aria-label={title}
          className={`${buttonClassName}${disabled ? ' disabled' : ''}`}
          disabled={disabled}
          fill={fill}
          onClick={handleOnClick}
          onMouseEnter={() => handleMouseEvent(true)}
          onMouseLeave={() => handleMouseEvent(false)}
          style={buttonStyle}
          title={title}
          type='button'
        >
          {icon ? (
            icon
          ) : (
            <Icon fill={Colors.WHITE} name='Plus' height={18} width={18} />
          )}
        </Button>

        {actions.length > 0 && (
          <Transition name='fab-menu' visible={isMenuVisible}>
            <ActionsMenu ref={menuRef}>
              {actions.map((item, index: number) => {
                if (typeof item === 'string' && item === 'divider') {
                  return <FabDivider key={`fab-divider-${index}`} />
                }
                if (!React.isValidElement(item)) return null

                const onClick = item.props.onClick

                return (
                  <FabItem
                    key={`fab-item-${index}`}
                    {...item.props}
                    onClick={() => handleOnClickAction(onClick)}
                  >
                    {item.props.children}
                  </FabItem>
                )
              })}
            </ActionsMenu>
          </Transition>
        )}
      </Container>
    </Transition>
  )
}

const BoxShadow = css`
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px,
    rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px;
`
const CssTransition = css`
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`
const Container = styled.div`
  bottom: 20px;
  opacity: 0;
  position: fixed;
  right: 20px;
  transform: scale(0);
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 1000;

  &.fab-enter {
    opacity: 0;
    transform: scale(0);
  }
  &.fab-enter-active {
    opacity: 1;
    transform: scale(1);
  }
  &.fab-leave {
    opacity: 1;
    transform: scale(1);
  }
  &.fab-leave-active {
    opacity: 0;
    transform: scale(0);
  }
`
const Button = styled.button<FabProps>`
  align-items: center;
  appearance: none;
  z-index: 1002;

  background-color: ${({ fill = Colors.HAVELOCK_BLUE }) => fill};
  border: none;
  border-radius: 50%;

  ${BoxShadow};
  box-sizing: border-box;
  color: ${({ fill = Colors.HAVELOCK_BLUE }) => checkColorValue(fill)};
  cursor: pointer;
  display: inline-flex;

  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;

  height: 56px;
  justify-content: center;
  margin: 0;
  min-height: 36px;
  min-width: 0;
  outline: 0;
  padding: 0;
  position: relative;

  width: 56px;

  -webkit-tap-highlight-color: transparent;

  text-decoration: none;
  user-select: none;
  vertical-align: middle;

  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;

  border-radius: 50%;
  // border-top-left-radius: 50%;
  // border-top-right-radius: 50%;
  // border-bottom-right-radius: 50%;
  // border-bottom-left-radius: 50%;

  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover {
    background-color: ${({ fill = Colors.HAVELOCK_BLUE }) => darken(fill, 15)};
  }

  &:active {
    box-shadow: rgb(0 0 0 / 20%) 0px 7px 8px -4px,
      rgb(0 0 0 / 14%) 0px 12px 17px 2px, rgb(0 0 0 / 12%) 0px 5px 22px 4px;
  }

  &.disabled {
    box-shadow: none;
    background-color: ${Colors.ALTO};
    color: ${Colors.DUSTY_GRAY};
    cursor: default;
  }
`
const Label = styled.div`
  align-items: center;
  background-color: ${Colors.WHITE};
  border: 1px solid ${Colors.ALTO};
  border-radius: 4px;
  ${BoxShadow};
  box-sizing: border-box;
  display: inline-flex;
  font-size: 14px;
  justify-content: center;
  margin-right: 10px;
  opacity: 0;
  overflow: hidden;
  padding: 6px 8px;
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translate3d(30px, -50%, 0);
  ${CssTransition};
  user-select: none;
  white-space: nowrap;
  &.fab-label-enter {
    opacity: 0;
    transform: translate3d(30px, -50%, 0);
  }
  &.fab-label-enter-active {
    opacity: 1;
    transform: translate3d(0, -50%, 0);
  }
  &.fab-label-exit-active {
    opacity: 1;
    transform: translate3d(0, -50%, 0);
  }
  &.fab-label-exit {
    opacity: 0;
    transform: translate3d(30px, -50%, 0);
  }
`
const ActionsMenu = styled.div`
  background-color: ${Colors.WHITE};
  border: 1px solid ${Colors.ALTO};
  border-radius: 6px;
  ${BoxShadow};
  bottom: 100%;
  margin-bottom: 10px;
  min-width: 180px;
  position: absolute;
  overflow: hidden;
  opacity: 0;
  right: 0;
  transform: translate3d(0, 30px, 0);
  ${CssTransition};
  z-index: 1001;
  &.fab-menu-enter {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
  }
  &.fab-menu-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.fab-menu-exit-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.fab-menu-exit {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
  }
`

export default Fab
