import React, { useMemo } from 'react'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'
import { useProportions } from '@sportsyou/react-hooks'

export interface ProgressCircleProps {
  className?: string
  fill: Colors | string
  labelClassName?: string
  labelColor?: string
  labelStyle?: React.CSSProperties
  max: number
  showLabel?: boolean
  size: number
  testId?: string
  thickness: number
  trackColor?: Colors | string
  style?: React.CSSProperties
  value: number
}

export const ProgressCircle: React.FC<ProgressCircleProps> = ({
  className,
  fill,
  labelClassName,
  labelColor,
  labelStyle,
  max,
  showLabel,
  size,
  style,
  testId,
  thickness,
  trackColor,
  value,
}: ProgressCircleProps) => {
  type SVGType = {
    indicator: React.SVGProps<SVGCircleElement>
    track: React.SVGProps<SVGCircleElement>
  }

  const percentValue = useProportions(value, max)

  const extraProps: SVGType = useMemo(() => {
    const center = size / 2
    const radius = center - thickness
    const arcLength = 2 * 3.14 * radius
    const arcOffset = arcLength * ((100 - value) / 100)
    const commonProps = { cx: center, cy: center, r: radius }

    return {
      indicator: {
        ...commonProps,
        strokeDasharray: arcLength,
        strokeDashoffset: arcOffset,
        strokeWidth: value <= 0 ? 0 : thickness,
      },
      track: {
        ...commonProps,
        strokeWidth: thickness,
      },
    }
  }, [size, thickness, value])

  return (
    <Container className={className} data-testid={testId} style={style}>
      <Circle $size={size}>
        {/* Track */}
        <circle fill='transparent' stroke={trackColor} {...extraProps.track} />
        {/* Circle */}
        <circle
          fill='transparent'
          stroke={fill}
          strokeLinecap='round'
          {...extraProps.indicator}
        />
      </Circle>
      {showLabel && (
        <Text
          className={labelClassName}
          labelColor={labelColor}
          style={labelStyle}
        >
          {percentValue}%
        </Text>
      )}
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  position: relative;
`
const Circle = styled.svg<{ $size: number }>`
  // height: 60px;
  // width: 60px;
  // border-radius: 50%;
  box-sizing: border-box;
  // border: '1px solid',
  height: ${({ $size }) => $size}px;
  width: ${({ $size }) => $size}px;
  transform: rotate(-90deg);
`

const Text = styled.span<{ labelColor?: string }>`
  color: ${({ labelColor }) => labelColor ?? Colors.MINE_SHAFT};
  font-size: 1.2em;
  font-weight: 500;
  left: 50%;
  position: absolute;
  top: 50%;
  text-align: center;
  transform: translate3d(-50%, -50%, 0);
  z-index: 20;
`

export default ProgressCircle
