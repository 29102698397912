import { Colors } from '@sportsyou/core'

import type { Theme } from './'

export const Light: Theme = {
  displayName: 'Light',
  key: 'light',
  palette: {
    primary: Colors.HAVELOCK_BLUE,
    content_background: Colors.WHITE,
    site_background: '#f3f3f3',
    nav: Colors.WHITE,
    border: Colors.ALTO,
    text: Colors.MINE_SHAFT,
    text_anchor: Colors.HAVELOCK_BLUE,
    text_placeholder: '#757575',
    status: {
      alert: '',
      danger: '',
      info: '',
      success: '',
    },
  },
}

// export const dark = {
//   primary: Colors.HAVELOCK_BLUE,
//   background: Colors.MINE_SHAFT,
//   nav: Colors.MINE_SHAFT,
//   border: Colors.BLACK,
//   text: Colors.CATSKILL_WHITE,
// }

export default Light
