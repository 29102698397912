import { useCallback } from 'react'
import styled from 'styled-components'

import { Button, TextInput } from '@sportsyou/react-dom-ui'
import { Location } from '@sportsyou/api'
import { X } from '@sportsyou/react-icons'

interface LocationPickerItemProps {
  index: number
  location?: Location | null
  onChange: (location: Location, index: number) => void
  onRemove?: (index: number) => void
}

export const LocationPickerItem = (props: LocationPickerItemProps) => {
  const onChange = useCallback(
    (key: string, value: string) => {
      if (props.onChange) {
        props.onChange({ ...props.location, [key]: value }, props.index)
      }
    },
    [props]
  )

  const onRemove = useCallback(() => {
    if (props.onRemove) {
      props.onRemove(props.index)
    }
  }, [props])

  return (
    <LocationPickerItemContainer>
      <TextInputStyled
        placeholder='City'
        onChange={(e) => onChange('city', e.target.value)}
        type='text'
        value={props.location?.city ?? ''}
        containerStyle={{ width: '80px' }}
      />
      <TextInputStyled
        placeholder='State'
        onChange={(e) => onChange('stateProvince', e.target.value)}
        type='text'
        value={props.location?.stateProvince ?? ''}
        containerStyle={{ width: '80px' }}
      />
      <TextInputStyled
        placeholder='Zip'
        onChange={(e) => onChange('postalCode', e.target.value)}
        type='text'
        value={props.location?.postalCode ?? ''}
        containerStyle={{ width: '80px' }}
      />
      <TextInputStyled
        placeholder='Country'
        onChange={(e) => onChange('country', e.target.value)}
        type='text'
        value={props.location?.country ?? ''}
        containerStyle={{ width: '80px', overflow: 'hidden' }}
      />
      <RemoveButton variant='alternate' onClick={onRemove}>
        <X />
      </RemoveButton>
    </LocationPickerItemContainer>
  )
}

const LocationPickerItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 5px;
  gap: 5px;
`

const TextInputStyled = styled(TextInput)`
  flex-grow: 1;
  overflow: hidden;
`

const RemoveButton = styled(Button)`
  min-width: 10px !important;
`

export default LocationPickerItem
