import * as React from 'react'
import type { SVGProps } from 'react'
const ChevronDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 20'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path
      fillRule='evenodd'
      d='M31.4042 2.21584c.85.93328.7825 2.37891-.1508 3.22891L17.5391 17.9353c-.8722.7944-2.2058.7944-3.0781 0L.746672 5.44536c-.9333-.84998-1.000847-2.29561-.15087-3.22891.849978-.9333 2.295608-1.00085 3.228908-.15087L16 13.1539 28.1752 2.06506c.9333-.85001 2.379-.7825 3.229.15078Z'
      clipRule='evenodd'
    />
  </svg>
)
export default ChevronDown
