import React from 'react'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'

export interface WidgetFooterProps {
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

export const WidgetFooter: React.FC<
  React.PropsWithChildren<WidgetFooterProps>
> = ({ className, children, style, ...props }: WidgetFooterProps) => {
  return (
    <Footer className={className} style={style} {...props}>
      {children}
    </Footer>
  )
}

const Footer = styled.footer`
  border-top: 1px solid ${Colors.ALTO};
  box-sizing: border-box;
  padding: 6px 10px;
`

export default WidgetFooter
