import { build } from 'search-params'
import { ServerSession, getServerSession } from '../utils/session-util'
import { API_CACHE_PREFIX, BATCH_CACHE_PREFIX } from '../utils/constants'
import { BuildQueryResult } from '../utils/graphql'
import GraphQLError from '../api/graphql/graphql-error'

interface RequestHeader {
  [propName: string]: any
}

export interface StaticApiRequest {
  action: string
  [propName: string]: any
}

export interface GraphqlApiRequest {
  graphql: string
}

export type ApiRequest = StaticApiRequest | GraphqlApiRequest

export enum CacheOptions {
  ALWAYS = 'always', //always take the item from the cache if possible
  BATCH_PRELOAD = 'batch', //check if any batch queries were run leaving this item in cache
  NEVER = 'never', //default options for graphql mutations
  ON_NETWORK_ERROR = 'on_network_error', //default option for graphql queries
}

export interface ApiRequestOptions {
  cacheTimeout?: number //milliseconds to keep something in cache, default caching is for one hour (3600000 milliseconds)
  fieldList?: string
  headers?: object
  isBlob?: boolean
  request?: object
  requestTimeout?: number
  url?: string
  useCache?: CacheOptions
}

export interface ApiResponse {
  data?: any
  duration?: number
  error?: object | string
  errorCodes?: number[]
  errorType?: ErrorType
  errors?: GraphQLError[]
  headers?: Headers
  isFromCache?: boolean
  ok: boolean
  requestString?: string
  responses?: ApiResponse[]
  serverSession?: ServerSession
}

export enum ErrorType {
  API_ERROR = 'api_error',
  NETWORK_ERROR = 'network_error',
  NONE = 'none',
}

export function getErrorType(error: any): ErrorType {
  const NETWORK_ERROR = 'Network request failed'

  if (!error) {
    return ErrorType.NONE
  }

  if (
    (error.message && error.message === NETWORK_ERROR) ||
    error === NETWORK_ERROR
  ) {
    return ErrorType.NETWORK_ERROR
  } else if (error === '' || (error.message && error.message === '')) {
    return ErrorType.NONE
  } else {
    if (typeof error === 'object' && Object.keys(error).length === 0) {
      return ErrorType.NONE
    } else {
      return ErrorType.API_ERROR
    }
  }
}

async function checkCache(
  body: ApiRequest,
  options?: ApiRequestOptions,
  response?: ApiResponse
) {
  let isMutation = false
  if (body.graphql) {
    isMutation = body.graphql.indexOf('mutation') > -1
  }

  // default is to cache only on network error for queries, never for mutations
  const useCache =
    options?.useCache ??
    (isMutation ? CacheOptions.NEVER : CacheOptions.ON_NETWORK_ERROR)
  // default caching is for one hour
  const cacheTimeout = options?.cacheTimeout ?? 1 * 60 * 60 * 1_000

  if (response) {
    response.isFromCache = false
  }

  if (useCache === CacheOptions.NEVER) {
    return response
  }

  let cacheKey = convertBodyToQueryString(body)
  if (!cacheKey) {
    return response
  }

  const batchKey = BATCH_CACHE_PREFIX + cacheKey
  cacheKey = API_CACHE_PREFIX + cacheKey

  if (response && !response.error) {
    const cacheEntry = {
      cacheDate: new Date().getTime(),
      cacheValue: response,
    }
    try {
      addToCache(cacheKey, cacheEntry)
    } catch (ex) {
      console.warn('Error writing entry to cache for ', cacheEntry, ex)
    }
    return response
  } else {
    try {
      if (
        useCache === CacheOptions.ALWAYS ||
        useCache === CacheOptions.BATCH_PRELOAD ||
        ((useCache === CacheOptions.ON_NETWORK_ERROR ||
          useCache === CacheOptions.BATCH_PRELOAD) &&
          response?.errorType === ErrorType.NETWORK_ERROR)
      ) {
        let data = null
        if (useCache === CacheOptions.BATCH_PRELOAD) {
          data = await getFromCache(batchKey)
        } else {
          data = await getFromCache(cacheKey)
        }
        if (data) {
          const cacheEntry = data
          const timeInCache = new Date().getTime() - cacheEntry.cacheDate
          if (timeInCache < cacheTimeout) {
            cacheEntry.cacheValue.isFromCache = true
            if (response?.errorType === ErrorType.NETWORK_ERROR) {
              cacheEntry.cacheValue.errorType = ErrorType.NETWORK_ERROR
            }
            const ret = cacheEntry.cacheValue
            if (!ret.errorType) {
              ret.errorType = ErrorType.NONE
            }
            if (!ret.errors) {
              ret.errors = []
            }
            useCache === CacheOptions.BATCH_PRELOAD && removeFromCache(batchKey)
            return ret
          } else {
            if (useCache === CacheOptions.BATCH_PRELOAD) {
              removeFromCache(batchKey)
            } else {
              removeFromCache(cacheKey)
            }
            return null
          }
        } else {
          return response
        }
      } else {
        return response
      }
    } catch (ex) {
      console.log('Error getting entry from to cache for ', cacheKey, ex)
    }
  }
}

interface ApiFunctionInterface {
  (body: ApiRequest, options?: ApiRequestOptions): Promise<ApiResponse>
}

interface ApiMethodInterface {
  ({
    body,
    headers,
    isBlob,
    method,
    request,
    timeout,
    url,
  }: {
    body: string
    headers: object
    isBlob?: boolean
    method: string
    request: object
    timeout: number
    url: string
  }): Promise<ApiResponse>
}

const defaultHeaders: RequestHeader = {
  'Content-Type': 'application/json',
}
let additionalInfoHeaders: RequestHeader = {}

const STATIC_API_URL = process.env['NX_STATIC_API_URL'] ?? ''
const METASCRAPER_API_URL = process.env['NX_METASCRAPER_API_URL'] ?? ''
const GRAPHQL_API_URL = process.env['NX_GRAPHQL_API_URL'] ?? ''

const ACTIONS_FOR_METASCRAPER_API_URL: string[] = ['getMetadata']

const apiMethod: ApiMethodInterface = async ({
  body,
  headers: _headers = {},
  isBlob = false,
  method = 'POST',
  request: _request = {},
  timeout,
  url = STATIC_API_URL,
}) => {
  let response: ApiResponse = {
    ok: false,
    error: undefined,
    errorType: ErrorType.NONE,
  }
  let request: Request

  // additionalInfoHeaders['sy-client-language'] = i18next.language

  const headers: Headers = new Headers({
    ...defaultHeaders,
    ...additionalInfoHeaders,
    ..._headers,
  })

  if (method === 'GET') {
    request = new Request(url, {
      method,
      headers,
      credentials: 'include',
      ..._request,
    })
  } else {
    request = new Request(url, {
      method,
      headers,
      credentials: 'include',
      body,
      ..._request,
    })
  }

  const _controller = new AbortController()
  const _signal = _controller.signal
  let _canAbort = true
  if (timeout && timeout > 0) {
    setTimeout(() => {
      if (_canAbort) {
        _controller.abort()
      }
    }, timeout)
  }
  const _start = new Date().getTime()
  const _response = await fetch(request, { signal: _signal }).catch((err) => {
    response = {
      ok: false,
      error: err,
      errorType: getErrorType(err),
    }
  })
  _canAbort = false

  if (_response) {
    /**
     * TODO: else isn't handled here
     * because it is assumed to taken care by fetch.catch()
     * if it is possible to have undefined/null success response,
     * else should be handled
     */

    if (isBlob) {
      return _response
    }

    const result = await _response.text().catch((err) => {
      response = {
        ok: false,
        error: err,
        errorType: getErrorType(err),
      }
    })

    let apiResponse = null
    if (result) {
      try {
        apiResponse = JSON.parse(result)
      } catch (e: any) {
        console.log('Error processing JSON response ', result, e)
        response = {
          ok: false,
          error: e,
          errorType: getErrorType(e),
        }
      }
    }

    if (apiResponse) {
      if (url === GRAPHQL_API_URL) {
        const ok = !apiResponse.errors
        let errorMsg: any = {}
        let errorCodes: number[] | undefined = undefined
        const errors = []
        if (!ok) {
          if (Array.isArray(apiResponse.errors)) {
            for (let i = 0; i < apiResponse.errors.length; i++) {
              errors.push(new GraphQLError(apiResponse.errors[i]))
            }
          } else {
            errors.push(new GraphQLError(apiResponse.errors))
          }
          if (errors.length > 0) {
            errorMsg = errors[0]
            errorCodes = []
            for (let i = 0; i < errors.length; i++) {
              if (errors[i].getCode()) {
                const code: number = parseInt(errors[i].getCode() ?? '0')
                errorCodes.push(code)
              }
            }
          }
        }

        if (errorMsg?.message?.message) {
          errorMsg.message = errorMsg.message.message
        }

        response = {
          data: apiResponse.data,
          error: errorMsg.message,
          errorCodes: errorCodes,
          errorType: getErrorType(errorMsg),
          errors: errors,
          headers: _response.headers,
          ok,
          serverSession: getServerSession(_response.headers),
        }
      } else {
        const ok = apiResponse.status === 'OK'
        const { status, msgs, ...staticApiData } = apiResponse
        response = {
          data: staticApiData,
          error: msgs,
          errorType: getErrorType(msgs),
          headers: _response.headers,
          ok,
          serverSession: getServerSession(_response.headers),
        }
      }
    }
  }

  const _end = new Date().getTime()
  const duration = _end - _start
  response.duration = duration
  console.log({ body, request, _response, response })

  return response
}

function isStaticApiRequest(
  apiRequest: ApiRequest
): apiRequest is StaticApiRequest {
  return (apiRequest as StaticApiRequest).action !== undefined
}

function isGraphqlApiRequest(
  apiRequest: ApiRequest
): apiRequest is GraphqlApiRequest {
  return (apiRequest as GraphqlApiRequest).graphql !== undefined
}

function convertBodyToQueryString(body: ApiRequest): string {
  const paramObject: any = {}
  if (isStaticApiRequest(body)) {
    for (const key in body) {
      const keyValue = body[key]
      paramObject[key] = keyValue
    }
  } else {
    const query: string = body.graphql
    paramObject.query = query
  }

  const qs: string = build(paramObject)
  return qs
}

export const get: ApiFunctionInterface = async function get(
  body: ApiRequest,
  options?: ApiRequestOptions
): Promise<ApiResponse> {
  const headers: object = {
    ...options?.headers,
  }
  const request: object = {
    ...options?.request,
  }
  const method = 'GET'
  let url = options?.url ?? STATIC_API_URL

  if (
    isStaticApiRequest(body) &&
    ACTIONS_FOR_METASCRAPER_API_URL.includes(body.action) &&
    url !== METASCRAPER_API_URL
  ) {
    url = METASCRAPER_API_URL
  }

  url += `?${convertBodyToQueryString(body)}`

  const cacheValue = await checkCache(body, options)
  if (cacheValue) {
    return cacheValue
  } else {
    const response = await apiMethod({
      body: '',
      headers,
      isBlob: options?.isBlob,
      method,
      request,
      timeout: options?.requestTimeout ?? -1,
      url,
    })
    return await checkCache(body, options, response)
  }
}

// export const head: ApiFunctionInterface = async function head(body: ApiRequest, options?: ApiRequestOptions): Promise<ApiResponse> {
// }

export const batchQuery = async function batchQuery(
  body: BuildQueryResult[],
  options?: ApiRequestOptions
): Promise<ApiResponse> {
  interface ErrorMap {
    [key: string]: GraphQLError
  }

  const requestString = `{${body.map(
    ({ query: req }, i) => `q${i}:${req.substring(7, req.length - 1)}\r\n`
  )}}`
  const res = await post({ graphql: requestString }, options)
  const ret: ApiResponse[] = []
  const errorList: ErrorMap = {}
  if (res.errors) {
    for (let i = 0; i < res.errors.length; i++) {
      const err = res.errors[i]
      if (err && err.path) {
        errorList[err.path] = err
      }
    }
  }

  let ok = true
  let firstError = undefined
  let firstErrorCodes = undefined
  let firstErrorType = undefined
  let firstFromCache = false

  if (res.error && !res.data) {
    ok = false
    firstError = res.error
  } else {
    for (let i = 0; i < body.length; i++) {
      const ndx = `q${i}`
      const retData: any = {}
      retData[body[i].name] = res.data && res.data[ndx]
      const error = errorList[ndx]
      const apiRes: ApiResponse = {
        data: retData,
        headers: res.headers,
        isFromCache: res.isFromCache,
        ok: true,
        requestString: body[i].query,
        serverSession: res.serverSession,
      }

      if (error) {
        apiRes.ok = false
        apiRes.error = error.getMessage()
        const code = error.getCode()
        if (code) {
          apiRes.errorCodes = [parseInt(code)]
        }
        if (!firstError) {
          firstError = apiRes.error
          firstErrorCodes = apiRes.errorCodes
          firstErrorType = apiRes.errorType
          ok = false
        }
      } else if (res.errorType && !firstErrorType) {
        firstErrorType = res.errorType
      }

      if (!error) {
        if (options?.useCache !== CacheOptions.NEVER) {
          let cacheKey = convertBodyToQueryString({ graphql: body[i].query })
          if (cacheKey) {
            cacheKey = BATCH_CACHE_PREFIX + cacheKey
            const cacheEntry = {
              cacheDate: new Date().getTime(),
              cacheValue: apiRes,
            }
            try {
              addToCache(cacheKey, cacheEntry)
            } catch (ex) {
              console.log('Error writing entry to cache for ', cacheEntry, ex)
            }
          }
        }
      }

      ret.push(apiRes)

      if (!firstFromCache && res.isFromCache) {
        firstFromCache = true
      }
    }
  }

  return {
    ok,
    error: firstError,
    errorCodes: firstErrorCodes,
    errorType: firstErrorType,
    isFromCache: firstFromCache,
    responses: ret,
  }
}

export const clearCache = async function clearCache() {
  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i) ?? ''
    if (
      key.startsWith(API_CACHE_PREFIX) ||
      key.startsWith(BATCH_CACHE_PREFIX)
    ) {
      removeFromCache(key)
    }
  }
}

const addToCache = function (cacheKey: string, cacheEntry: any) {
  const res = JSON.stringify(cacheEntry)
  return sessionStorage.setItem(cacheKey, res)
}

const getFromCache = async function (cacheKey: string) {
  const data = sessionStorage.getItem(cacheKey)
  if (data) {
    return JSON.parse(data)
  } else {
    return null
  }
}

const removeFromCache = function (cacheKey: string) {
  return sessionStorage.removeItem(cacheKey)
}

export const post: ApiFunctionInterface = async function post(
  body: ApiRequest,
  options?: ApiRequestOptions
): Promise<ApiResponse> {
  const headers: object = {
    ...options?.headers,
  }
  const request: object = {
    ...options?.request,
  }
  const method = 'POST'
  let url = options?.url ?? STATIC_API_URL
  let postData = ''

  if (isGraphqlApiRequest(body)) {
    url = GRAPHQL_API_URL
    const query: string = body.graphql
    postData = JSON.stringify({ query })
  } else {
    if (ACTIONS_FOR_METASCRAPER_API_URL.includes(body.action)) {
      return get(body, { ...options, url: METASCRAPER_API_URL })
    }
    postData = JSON.stringify(body)
  }

  const cacheValue = await checkCache(body, options)
  if (cacheValue) {
    return cacheValue
  } else {
    const response = await apiMethod({
      body: postData,
      headers,
      method,
      request,
      timeout: options?.requestTimeout ?? -1,
      url,
    })
    const ret = await checkCache(body, options, response)
    return ret
  }
}

export const setHeader = (headers: RequestHeader = {}) => {
  additionalInfoHeaders = {
    ...additionalInfoHeaders,
    ...headers,
  }
}

const ApiBase: object = {
  get,
  post,
  setHeader,
}

export default ApiBase // export methods as default
