import * as React from 'react'
import type { SVGProps } from 'react'
const Media = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <g>
      <path d='M25.92 0H6.08A6.09 6.09 0 0 0 0 6.08v19.84A6.09 6.09 0 0 0 6.08 32h19.84A6.09 6.09 0 0 0 32 25.92V6.08A6.09 6.09 0 0 0 25.92 0zM6.08 2.23h19.84a3.86 3.86 0 0 1 3.85 3.85v11l-5.67-5.67a2.74 2.74 0 0 0-2-.81 2.71 2.71 0 0 0-1.95.82l-6.78 6.77-1.54-1.53a2.83 2.83 0 0 0-3.92 0l-5.68 5.72V6.08a3.86 3.86 0 0 1 3.85-3.85zm19.84 27.54H6.08a3.86 3.86 0 0 1-3.85-3.85v-.39l7.3-7.29c.28-.28.49-.28.76 0l2.33 2.32a1.11 1.11 0 0 0 1.58 0L21.77 13a.46.46 0 0 1 .76 0l7.24 7.24v5.69a3.86 3.86 0 0 1-3.85 3.84z' />
      <path d='M9.8 10.58a1.74 1.74 0 1 0-1.74-1.74 1.73 1.73 0 0 0 1.74 1.74z' />
    </g>
  </svg>
)
export default Media
