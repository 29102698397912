import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { Colors } from '@sportsyou/core'

import Button, { ButtonProps } from '../button'
import Icon from '../icon'
import Transition from '../css-transition'

export interface FollowButtonProps extends ButtonProps {
  isFollowing: boolean
}

export const FollowButton: React.FC<FollowButtonProps> = (
  props: FollowButtonProps
) => {
  const [initialized, setInitialized] = useState<boolean>(false)

  useEffect(() => {
    setInitialized(true)
  }, [])

  return (
    <StyledButton {...props}>
      <Transition
        enterTime={initialized ? undefined : 0}
        name='text'
        visible={props.isFollowing}
      >
        <FollowingContainer>
          <StyledIcon name='Checkmark' fill={Colors.HAVELOCK_BLUE} size={14} />{' '}
          Following
        </FollowingContainer>
      </Transition>
      <Transition
        enterTime={initialized ? undefined : 0}
        name='text'
        visible={!props.isFollowing}
      >
        <NotFollowingContainer>
          <StyledIcon name='Plus' fill={Colors.WHITE} size={14} /> Follow
        </NotFollowingContainer>
      </Transition>
    </StyledButton>
  )
}

const StyledButton = styled(Button)<FollowButtonProps>`
  position: relative;
  min-width: 130px;

  ${(props) =>
    props.isFollowing
      ? css`
          background-color: ${Colors.WHITE};
          color: ${Colors.HAVELOCK_BLUE};

          &:hover {
            background-color: ${Colors.WHITE};
            color: ${Colors.HAVELOCK_BLUE};
          }
        `
      : css`
          background-color: ${Colors.HAVELOCK_BLUE};
          color: ${Colors.WHITE};

          &:hover {
            background-color: ${Colors.HAVELOCK_BLUE};
            color: ${Colors.WHITE};
          }
        `}
`

const StyledIcon = styled(Icon)`
  margin-right: 5px;
`

const TextContainer = styled.div`
  align-items: center;
  display: flex;
  font-size: 16px;
  justify-content: center;
  position: absolute;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);

  svg {
    transition: all 350ms linear;
  }

  &.text-enter {
    opacity: 0;
    transform: translateX(30px);

    svg {
      transform: scale(0);
    }
  }
  &.text-enter-active {
    opacity: 1;
    transform: translateX(0);

    svg {
      transform: scale(1);
    }
  }
  &.text-leave {
    opacity: 1;
    transform: translateX(0);
  }
  &.text-leave-active {
    opacity: 0;
    transform: translateX(-30px);
  }
`

const FollowingContainer = styled(TextContainer)`
  left: 20px;
`

const NotFollowingContainer = styled(TextContainer)`
  left: 30px;
`

export default FollowButton
