import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react'
import copyToClipboard from 'copy-to-clipboard'

const DEFAULT_TIMEOUT = 2000

export interface CopyToClipboardProps {
  /** Optional content to always show. */
  children?: React.ReactNode
  /** Optional class name for the component. */
  className?: string
  /** Callback when copy is clicked. */
  onCopy?: (success: boolean) => void
  /**
   * Optional content to show before the text has been copied.
   * */
  content?: () => React.ReactNode
  /**
   * Optional content to show after the text has been copied.
   * */
  contentOnCopy?: () => React.ReactNode
  /**
   * Milliseconds to wait before resetting the copied state. Defaults to 2000.
   * */
  successTimeout?: number
  /**
   * Can be a string or a function that returns a string.
   * @param text string | (() => string)
   * */
  text: string | (() => string)
  testId?: string
}

/**
 * CopyToClipboard is a component that will copy text to the clipboard.
 * It wraps any content passed in as children or as content props and makes it
 * clickable. When clicked, it will copy the text to the clipboard and call
 * onCopy if it is defined.
 *
 * Example:
 * ```
 * <CopyToClipboard
 *  text={'text to copy'}
 *  onCopy={callback}
 *  content={() => <button>Click to copy</button>}
 *  contentOnCopy={() => <button>Copied!</button>}
 * />
 * ```
 * or
 * ```
 * <CopyToClipboard
 *  text={'text to copy'}
 *  onCopy={callback}
 * >
 *   <Button>Copy to clipboard</Button>
 * </CopyToClipboard>
 * ```
 */
export const CopyToClipboard: FC<PropsWithChildren<CopyToClipboardProps>> = ({
  children,
  className,
  content,
  contentOnCopy,
  onCopy,
  successTimeout,
  text,
  testId,
}: CopyToClipboardProps) => {
  const [copied, setCopied] = useState(false)

  const timeout = useRef<number>()

  useEffect(() => {
    if (copied) {
      timeout.current = window.setTimeout(() => {
        setCopied(false)
      }, successTimeout || DEFAULT_TIMEOUT)
    }
    return () => {
      clearTimeout(timeout.current ?? 0)
    }
  }, [copied, successTimeout])

  const onClick = useCallback(() => {
    const textToCopy = typeof text === 'function' ? text() : text
    const success = copyToClipboard(textToCopy) || !!testId
    setCopied(success)
    onCopy?.(success)
  }, [text, testId, onCopy])

  return (
    <span className={className} data-testid={testId} onClick={onClick}>
      {copied ? contentOnCopy?.() : content?.()}
      {children}
    </span>
  )
}

export default CopyToClipboard
