import { MouseEvent } from 'react'
import moment from 'moment'
import styled from 'styled-components'

import { Event as EventProps, MutationEventUpdateRequest } from '@sportsyou/api'
import { Colors } from '@sportsyou/core'

import { DayProps } from '../calendar-types'

import DayEventList, {
  EventLabel,
  LeftColumnLabels,
} from '../day/day-event-list'

export interface WeekCalendarProps {
  days: DayProps[]
  fillChipWithGameType?: boolean
  onClickDay?: (date: DayProps, e: MouseEvent<HTMLDivElement>) => void
  onClickEvent?: (event: EventProps, e: MouseEvent<HTMLDivElement>) => void
  onDoubleClickDay?: (date: DayProps, e: MouseEvent<HTMLDivElement>) => void
  onDropEvent?: (
    event: EventProps,
    newEvent: EventProps,
    eventRequest: MutationEventUpdateRequest
  ) => void
  selectedDate: string
}

export default function WeekCalendar(props: WeekCalendarProps) {
  const renderAllDayColumn = (day: DayProps, index: number) => {
    const isToday =
      moment(day.date).format('MM/DD/YYYY') === moment().format('MM/DD/YYYY')
    const events = day?.events
      ?.filter((event) => {
        return !!event.allDay
      })
      .sort((a, b) => {
        return moment(a.startDate).diff(moment(b.startDate))
      })

    return (
      <AllDayList isToday={isToday} key={index}>
        {events?.map((event) => {
          let fillColor = event.color ?? Colors.HAVELOCK_BLUE
          if (props.fillChipWithGameType) {
            fillColor =
              event.type === 'game' ? Colors.PUNCH : Colors.HAVELOCK_BLUE
          }
          return (
            <EventContainer
              calendarColor={fillColor}
              key={event.id}
              onClick={(e: MouseEvent<HTMLDivElement>) =>
                props.onClickEvent?.(event, e)
              }
            >
              <EventLabel>{event.title}</EventLabel>
            </EventContainer>
          )
        })}
      </AllDayList>
    )
  }

  return (
    <Container>
      <Header>
        <HeaderRow>
          <LeftColumn></LeftColumn>
          <RightColumn>
            {props.days.map((day, index) => (
              <DayHeader key={index}>
                {moment(day.date).format('ddd M/DD')}
              </DayHeader>
            ))}
          </RightColumn>
        </HeaderRow>
        <HeaderRow>
          <LeftColumn>all-day</LeftColumn>
          <RightColumn>
            <AllDayRow>{props.days.map(renderAllDayColumn)}</AllDayRow>
          </RightColumn>
        </HeaderRow>
      </Header>
      <Scroll>
        <LeftColumn>
          <LeftColumnLabels />
        </LeftColumn>
        <RightColumn>
          {props.days.map((day, index) => {
            const isToday =
              moment(day.date).format('MM/DD/YYYY') ===
              moment().format('MM/DD/YYYY')
            return (
              <WeekdayColumn isToday={isToday} key={index}>
                <DayEventList
                  day={day}
                  fillChipWithGameType={props.fillChipWithGameType}
                  onClickDay={props.onClickDay}
                  onClickEvent={props.onClickEvent}
                  onDoubleClickDay={props.onDoubleClickDay}
                  onDropEvent={props.onDropEvent}
                  selectedDate={props.selectedDate}
                />
              </WeekdayColumn>
            )
          })}
        </RightColumn>
      </Scroll>
    </Container>
  )
}

const Container = styled.div`
  border-right: 1px solid ${Colors.ALTO};
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  border-bottom: 1px solid ${Colors.ALTO};
  border-top: 1px solid ${Colors.ALTO};
`

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  border-bottom: 1px solid ${Colors.ALTO};
  border-left: 1px solid ${Colors.ALTO};
`

const AllDayRow = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const Scroll = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-y: scroll;
  height: 100%;
`

const LeftColumn = styled.div`
  width: 60px;
`

const RightColumn = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  position: relative;
`

const DayHeader = styled.div`
  border-left: 1px solid ${Colors.ALTO};
  color: ${Colors.SHUTTLE_GRAY};
  font-size: 14px;
  font-weight: 700;
  padding: 5px 0;
  width: 100%;
`

const WeekdayColumn = styled.div<{ isToday: boolean }>`
  background-color: ${({ isToday }) => (isToday ? '#fffbe5' : undefined)};
  width: 100%;
`

const AllDayList = styled.div<{ isToday: boolean }>`
  background-color: ${({ isToday }) => (isToday ? '#fffbe5' : undefined)};

  border-left: 1px solid ${Colors.ALTO};
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
`
const EventContainer = styled.div<{ calendarColor: string }>`
  background-color: ${({ calendarColor }) => calendarColor};
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 2px;
  margin-left: 2px;
  margin-right: 2px;
  overflow: hidden;
`
