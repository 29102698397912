import * as React from 'react'
import type { SVGProps } from 'react'
const Home = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M31.57 12.17l-15-10.49a1 1 0 0 0-1.16 0L.43 12.17a1 1 0 1 0 1.16 1.66l1.41-1v16.66a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1v-6a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2v6a1 1 0 0 0 1 1H28a1 1 0 0 0 1-1V12.85l1.41 1a1 1 0 0 0 1.16-1.66zM27 28.48h-5.49v-5a4 4 0 0 0-4-4h-3a4 4 0 0 0-4 4v5H5v-17l11-7.73 11 7.68z' />
  </svg>
)
export default Home
