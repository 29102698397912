import { Placement, PositionProps, TooltipIconPosition } from '../prop-types'

interface ParentDomRect {
  top: number
  left: number
  right: number
  bottom: number
  width: number
  height: number
}

export const defaultPosition: PositionProps = {
  left: '-1000px',
  top: '-1000px',
  transform: 'none',
}
export const getIconPosition = (
  placement = 'top' as Placement,
  offset = 4
): TooltipIconPosition => {
  const offsetAbs = 3
  const positions: { [key in Placement]: TooltipIconPosition } = {
    top: {
      bottom: `${offsetAbs}px`,
      left: '50%',
      right: 'auto',
      top: 'auto',
      transform: 'translate(-50%, 100%) rotate(-90deg)',
    },
    topStart: {
      bottom: `${offsetAbs}px`,
      left: `${offset}px`,
      right: 'auto',
      top: 'auto',
      transform: 'translate(0, 100%) rotate(-90deg)',
    },
    topEnd: {
      bottom: `${offsetAbs}px`,
      left: 'auto',
      right: `${offset}px`,
      top: 'auto',
      transform: 'translate(0, 100%) rotate(-90deg)',
    },
    bottom: {
      bottom: 'auto',
      left: '50%',
      right: 'auto',
      top: `${offsetAbs}px`,
      transform: 'translate(-50%, -100%) rotate(90deg)',
    },
    bottomStart: {
      bottom: 'auto',
      left: `${offset}px`,
      right: 'auto',
      top: `${offsetAbs}px`,
      transform: 'translate(0, -100%) rotate(90deg)',
    },
    bottomEnd: {
      bottom: 'auto',
      left: 'auto',
      right: `${offset}px`,
      top: `${offsetAbs}px`,
      transform: 'translate(0, -100%) rotate(90deg)',
    },
    left: {
      bottom: 'auto',
      left: 'auto',
      right: '0',
      top: '50%',
      transform: 'translate(100%, -50%) rotate(180deg)',
    },
    leftStart: {
      bottom: 'auto',
      left: 'auto',
      right: '0',
      top: `${offset}px`,
      transform: 'translate(100%, -50%) rotate(180deg)',
    },
    leftEnd: {
      bottom: `${offset}px`,
      left: 'auto',
      right: '0',
      top: 'auto',
      transform: 'translate(100%, 50%) rotate(180deg)',
    },
    right: {
      bottom: 'auto',
      left: '0',
      right: 'auto',
      top: '50%',
      transform: 'translate(-100%, -50%) rotate(0deg)',
    },
    rightStart: {
      bottom: 'auto',
      left: '0',
      right: 'auto',
      top: `${offset}px`,
      transform: 'translate(-100%, -50%) rotate(0deg)',
    },
    rightEnd: {
      bottom: `${offset}px`,
      left: '0',
      right: 'auto',
      top: 'auto',
      transform: 'translate(-100%, 50%) rotate(0deg)',
    },
  }
  return positions[placement]
}

export const getPosition = (
  placement = 'top' as Placement,
  offset = 4
): PositionProps => {
  const positions: { [key in Placement]: PositionProps } = {
    top: {
      bottom: '100%',
      left: '50%',
      margin: `0 0 ${offset}px`,
      transform: 'translate(-50%, 0)',
    },
    topStart: {
      bottom: '100%',
      left: '0',
      margin: `0 0 ${offset}px`,
      transform: 'translate(0, 0)',
    },
    topEnd: {
      bottom: '100%',
      margin: `0 0 ${offset}px`,
      right: '0',
      transform: 'translate(0, 0)',
    },
    bottom: {
      left: '50%',
      margin: `${offset}px 0 0`,
      top: '100%',
      transform: 'translate(-50%, 0)',
    },
    bottomStart: {
      left: '0',
      margin: `${offset}px 0 0`,
      top: '100%',
      transform: 'translate(0, 0)',
    },
    bottomEnd: {
      margin: `${offset}px 0 0`,
      right: '0',
      top: '100%',
      transform: 'translate(0, 0)',
    },
    left: {
      margin: `0 ${offset}px 0 0`,
      right: '100%',
      top: '50%',
      transform: 'translate(0, -50%)',
    },
    leftStart: {
      margin: `0 ${offset}px 0 0`,
      right: '100%',
      top: '0',
      transform: 'translate(0, 0)',
    },
    leftEnd: {
      bottom: '0',
      margin: `0 ${offset}px 0 0`,
      right: '100%',
      transform: 'translate(0, 0)',
    },
    right: {
      left: '100%',
      margin: `0 0 0 ${offset}px`,
      top: '50%',
      transform: 'translate(0, -50%)',
    },
    rightStart: {
      left: '100%',
      margin: `0 0 0 ${offset}px`,
      top: '0',
      transform: 'translate(0, 0)',
    },
    rightEnd: {
      bottom: '0',
      left: '100%',
      margin: `0 0 0 ${offset}px`,
      transform: 'translate(0, 0)',
    },
  }
  return positions[placement]
}

export const getPositionWithParent = (
  placement = 'top' as Placement,
  rect: ParentDomRect,
  offset = 4
): PositionProps => {
  const positions: { [key in Placement]: PositionProps } = {
    top: {
      top: `${rect.top - offset}px`,
      left: `${rect.left + rect.width / 2}px`,
      transform: 'translate(-50%, -100%)',
    },
    topStart: {
      top: `${rect.top - offset}px`,
      left: `${rect.left}px`,
      transform: 'translate(0, -100%)',
    },
    topEnd: {
      top: `${rect.top - offset}px`,
      left: `${rect.left + rect.width}px`,
      transform: 'translate(-100%, -100%)',
    },
    bottom: {
      top: `${rect.bottom + offset}px`,
      left: `${rect.left + rect.width / 2}px`,
      transform: 'translate(-50%, 0)',
    },
    bottomStart: {
      top: `${rect.bottom + offset}px`,
      left: `${rect.left}px`,
      transform: 'translate(0, 0)',
    },
    bottomEnd: {
      top: `${rect.bottom + offset}px`,
      left: `${rect.left + rect.width}px`,
      transform: 'translate(-100%, 0)',
    },
    left: {
      top: `${rect.top + rect.height / 2}px`,
      left: `${rect.left - offset}px`,
      transform: 'translate(-100%, -50%)',
    },
    leftStart: {
      top: `${rect.top}px`,
      left: `${rect.left - offset}px`,
      transform: 'translate(-100%, 0)',
    },
    leftEnd: {
      top: `${rect.top + rect.height}px`,
      left: `${rect.left - offset}px`,
      transform: 'translate(-100%, -100%)',
    },
    right: {
      top: `${rect.top + rect.height / 2}px`,
      left: `${rect.right + offset}px`,
      transform: 'translate(0, -50%)',
    },
    rightStart: {
      top: `${rect.top}px`,
      left: `${rect.right + offset}px`,
      transform: 'translate(0, 0)',
    },
    rightEnd: {
      top: `${rect.top + rect.height}px`,
      left: `${rect.right + offset}px`,
      transform: 'translate(0, -100%)',
    },
  }
  return positions[placement]
}
