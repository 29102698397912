import React, { Key, useMemo } from 'react'
import styled from 'styled-components'

import { useDropdownContext } from './dropdown-context'

import { Colors } from '@sportsyou/core'

interface Props {
  className?: string
  disabled?: boolean
  href?: string
  isDestructive?: boolean
  line?: boolean
  onClick?: React.MouseEventHandler<HTMLAnchorElement | HTMLLIElement>
  target?: '_self' | '_blank' | '_parent' | '_top'
  testId?: string
  title?: string
  textAlign?: 'right' | 'center' | 'left'
}

type NativeAttributes = Omit<React.LiHTMLAttributes<any>, keyof Props>
export type DropdownItemProps = Props & NativeAttributes

const getTextAlignment = (textAlign: 'left' | 'center' | 'right') => {
  let _alignment = 'flex-start'
  if (textAlign === 'center') {
    _alignment = 'center'
  } else if (textAlign === 'right') {
    _alignment = 'flex-end'
  }
  return _alignment
}

export const DropdownItem: React.FC<
  React.PropsWithChildren<DropdownItemProps>
> = ({
  children,
  className,
  disabled,
  href,
  isDestructive,
  onClick,
  target,
  testId,
  textAlign,
  title,
  ...props
}: React.PropsWithChildren<DropdownItemProps>) => {
  const {
    // disabled,
    onItemClick,
    loading,
  } = useDropdownContext()

  const handleOnClick = (
    event: React.MouseEvent<HTMLAnchorElement | HTMLLIElement>
  ) => {
    if (disabled || loading) return
    onClick?.(event)
    onItemClick(event)
  }

  const getClassNames = useMemo(() => {
    const _classNames: Array<string> = []
    className && _classNames.push(className)
    disabled && _classNames.push('disabled')
    return _classNames.join(' ')
  }, [className, disabled])

  const renderAnchor = () => (
    <Item
      {...props}
      aria-disabled={disabled}
      className={getClassNames}
      data-testid={testId}
      isDestructive={isDestructive}
      textAlign={textAlign}
      title={title}
    >
      <Anchor
        aria-label={title}
        href={href}
        onClick={handleOnClick}
        rel={target === '_blank' ? 'noreferrer noopener' : undefined}
        target={target}
        title={title}
      >
        {!loading && children}
      </Anchor>
    </Item>
  )

  const renderItem = () => (
    <Item
      {...props}
      aria-disabled={disabled}
      aria-label={title}
      className={getClassNames}
      data-testid={testId}
      disabled={disabled}
      isDestructive={isDestructive}
      onClick={handleOnClick}
      textAlign={textAlign}
      title={title}
    >
      {!loading && children}
    </Item>
  )

  return href ? renderAnchor() : renderItem()
}

const Item = styled.li<
  Pick<Props, 'disabled' | 'href' | 'isDestructive' | 'onClick' | 'textAlign'>
>`
  align-items: center;
  border: none;
  box-sizing: border-box;
  background-color: ${({ disabled }) =>
    disabled ? Colors.ALTO : Colors.WHITE};
  color: ${({ disabled, isDestructive }) =>
    disabled
      ? Colors.DUSTY_GRAY
      : isDestructive
      ? Colors.MONZA
      : Colors.MINE_SHAFT};
  cursor: ${({ href, onClick }) =>
    href || typeof onClick === 'function' ? 'pointer' : 'default'};
  display: flex;
  font-size: 14px;
  font-weight: 400;
  justify-content: ${({ textAlign = 'left' }) => getTextAlignment(textAlign)};
  line-height: 1.4;
  margin: 0;
  padding: 10px; // ? Should this be a 'min-height' instead of vertical padding
  text-align: ${({ textAlign }) => textAlign};
  transition: color, background-color 150ms linear;
  width: 100%;

  &:hover:not(.disabled) {
    background-color: ${Colors.MYSTIC};
  }

  &.disabled {
    background-color: ${Colors.ALTO};
    color: ${Colors.DUSTY_GRAY};
    cursor: not-allowed;
  }
`
const Anchor = styled.a<Pick<Props, 'disabled'>>`
  color: inherit;
  cursor: inherit;
  text-decoration: none;
`

DropdownItem.displayName = 'DropdownItem'

export default DropdownItem
