import Navbar from './navbar'
import NavbarCenter from './navbar-center'
import NavbarLeft from './navbar-left'
import NavbarRight from './navbar-right'

export type NavbarType = typeof Navbar & {
  Center: typeof NavbarCenter
  Left: typeof NavbarLeft
  Right: typeof NavbarRight
}
;(Navbar as NavbarType).Center = NavbarCenter
;(Navbar as NavbarType).Left = NavbarLeft
;(Navbar as NavbarType).Right = NavbarRight

export type { NavbarProps } from './navbar'

export default Navbar as NavbarType
