import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  Friend,
  Profile,
  queryChatableUsers,
  queryFriends,
  queryTeammates,
  Teammate,
  User,
} from '@sportsyou/api'

import { RootState } from '../rootReducer'
import { ContentPortalId } from '../../UseContentPortal'

type ProfilesList = {
  [key: string]: Profile
}

type PortalAccess = {
  [key: string]: boolean
}

export interface UserSliceProps {
  chatableUsers: User[]
  friends: Friend[]
  groupmates: Teammate[]
  portalAccess?: PortalAccess
  isUserLoggedIn: boolean
  profiles: ProfilesList
  settings: {
    // local device user preferences
    language?: string
    userUpdatedAt?: string
  }
  shouldNavigateToLogin?: boolean
  teammates: Teammate[]
  user: User
}

const initialState: UserSliceProps = {
  chatableUsers: [],
  friends: [],
  groupmates: [],
  isUserLoggedIn: false,
  portalAccess: {},
  profiles: {},
  settings: {},
  shouldNavigateToLogin: false,
  teammates: [],
  user: {},
}

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    initializeUser(state: UserSliceProps, action: PayloadAction<User>) {
      state.user = action.payload
      state.settings.userUpdatedAt = new Date().toISOString()
    },
    clearUser(state) {
      state.chatableUsers = []
      state.friends = []
      state.groupmates = []
      state.isUserLoggedIn = false
      state.portalAccess = {}
      state.profiles = {}
      state.settings = {}
      state.shouldNavigateToLogin = false
      state.teammates = []
      state.user = {}
    },
    setProfile(state, action: PayloadAction<Profile>) {
      if (action?.payload?.id) {
        state.profiles[action.payload.id] = action.payload
      }
    },
    setShouldNavigateToLogin: (state, action: PayloadAction<boolean>) => {
      state.shouldNavigateToLogin = action.payload
    },
    setUserLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isUserLoggedIn = action.payload
    },
    updateChatableUsers(state: UserSliceProps, action: PayloadAction<User[]>) {
      state.chatableUsers = action.payload
    },
    updateFriends(state: UserSliceProps, action: PayloadAction<Friend[]>) {
      state.friends = action.payload
    },
    updateTeammates(state: UserSliceProps, action: PayloadAction<Teammate[]>) {
      state.teammates = action.payload
    },
    updateGroupmates(state: UserSliceProps, action: PayloadAction<Teammate[]>) {
      state.groupmates = action.payload
    },
    updateUser(state, action: PayloadAction<User>) {
      const payload = {
        ...action.payload,
      }
      delete payload.isAnnouncer
      state.user = {
        ...state.user,
        ...payload,
      }
      state.settings.userUpdatedAt = new Date().toISOString()
    },
    updateUserPreference(state, action) {
      state.user.preferences = {
        ...state.user.preferences,
        ...action.payload,
      }
    },
    updatePortalAccess(state, action: PayloadAction<PortalAccess>) {
      state.portalAccess = {
        ...state.portalAccess,
        ...action.payload,
      }
    },
  },
})

export const {
  clearUser,
  initializeUser,
  setProfile,
  setShouldNavigateToLogin,
  setUserLoggedIn,
  updateChatableUsers,
  updateFriends,
  updateGroupmates,
  updatePortalAccess,
  updateTeammates,
  updateUser,
  updateUserPreference,
} = userSlice.actions
export default userSlice.reducer

export const selectCurrentUser = (state: RootState) => state.user.user

export const selectCurrentUserChatableUsers = (state: RootState) =>
  state.user.chatableUsers ?? []
export const selectCurrentUserFriends = (state: RootState) => state.user.friends
export const selectCurrentUserTeammates = (state: RootState) =>
  state.user.teammates
export const selectCurrentUserGroupmates = (state: RootState) =>
  state.user.groupmates

export const selectAllProfiles = (state: RootState) => state.user.profiles

export const selectProfileById = createSelector(
  [selectAllProfiles, (state, profileId = '') => profileId],
  (profiles, profileId) => profiles?.[profileId] ?? {}
)

export const selectPortalAccess = (state: RootState) =>
  state.user.portalAccess ?? {}

export const fetchChatableUsers =
  () =>
  async (
    dispatch: ({ payload, type }: { payload: User[]; type: string }) => void
  ) => {
    const { data: chatableUsers = [] } = await queryChatableUsers()
    dispatch(updateChatableUsers(chatableUsers))
  }
export const fetchFriends =
  () =>
  async (
    dispatch: ({ payload, type }: { payload: Friend[]; type: string }) => void
  ) => {
    const { data: friends = [] } = await queryFriends()
    dispatch(updateFriends(friends))
  }

export const fetchTeammates =
  () =>
  async (
    dispatch: ({ payload, type }: { payload: Teammate[]; type: string }) => void
  ) => {
    const { data: teammates = [] } = await queryTeammates({
      teamType: 'team',
    })
    dispatch(updateTeammates(teammates))
    const { data: groupmates = [] } = await queryTeammates({
      teamType: 'group',
    })
    dispatch(updateGroupmates(groupmates))
  }
