import { Image } from '@sportsyou/api'

/** get largest image from a list of images */
export const getLargeImageUrl = (images: Image[]): string | undefined => {
  const largeImage = images?.reduce((prev, current) => {
    if (
      /^image/.test(prev?.contentType ?? '') &&
      /^image/.test(current?.contentType ?? '')
    ) {
      // both prev and current are image so compare their sizes
      if ((prev.width ?? 0) > (current?.width ?? 0)) {
        return prev
      } else {
        return current
      }
    } else if (/^image/.test(prev?.contentType ?? '')) {
      // current is not an image
      return prev
    } else {
      // either only current is an iamge or both prev and current are not images
      return current
    }
  }, {})
  return largeImage?.viewUrl as string
}

export default getLargeImageUrl
