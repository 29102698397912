import * as React from 'react'
import type { SVGProps } from 'react'
const BubbleAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 33'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M28.0582 6.07123c-.2037-.49053-.4946-.90893-.8582-1.28404-.3782-.37511-.8-.66366-1.2946-.85122-.4945-.20198-1.0036-.30297-1.5272-.30297H7.62181c-1.04727 0-2.06546.41839-2.82182 1.15419-.74182.7358-1.16364 1.74571-1.16364 2.79892V18.6808c0 1.0388.42182 2.0487 1.16364 2.7845.74182.7358 1.76 1.1542 2.80727 1.1542H10.4s.1309.0288.16.0577c.0291.0288.0582.0721.0582.1587v3.6068c0 .2742.16.5338.4072.6493.2473.1154.5528.0865.771-.0866l5.4545-4.3282s.1164-.0577.1455-.0577h6.9818c.5236 0 1.0327-.101 1.5272-.303.4946-.202.9164-.4905 1.2946-.8512.3782-.3751.6691-.7935.8582-1.2841.2036-.4905.3054-.9955.3054-1.5148V7.58611c0-.51939-.1018-1.02435-.3054-1.51488ZM26.9091 18.6664c0 .3318-.0582.6492-.1891.9666-.1309.303-.3055.5771-.5527.8079-.2473.2309-.5091.4184-.8146.5483-.3054.1298-.64.1875-.9745.1875h-6.9818c-.3928 0-.7564.1443-1.0328.3607l-4.2763 3.3905v-2.1064c0-.4329-.16-.8513-.48-1.1687-.32-.3174-.7564-.4761-1.1782-.4761H7.63635c-.66909 0-1.30909-.2597-1.77454-.7358-.46546-.4761-.74182-1.1109-.74182-1.7601V7.58611c0-.67809.26182-1.29847.74182-1.77457.48-.47611 1.11999-.7358 1.78909-.7358h16.7564c.3345 0 .6545.05771.9745.18755.3055.12985.5818.30298.8145.54825.2328.24526.4219.50495.5528.80793.1309.30298.1891.63481.1891.96664V18.6664h-.0291Z' />
  </svg>
)
export default BubbleAlt
