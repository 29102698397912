import { CSSProperties, FC, HTMLAttributes, useEffect, useMemo } from 'react'
import styled, { keyframes } from 'styled-components'

import { Colors } from '@sportsyou/core'

interface Props {
  className?: string
  /** The color of the component. */
  fill?: string
  /**
   * The size of the component. If using a number, the pixel unit is assumed.
   * If using a string, you must to provide the CSS unit, e.g '2rem'.
   */
  size?: number | string
  style?: CSSProperties
}

type NativeAttributes = Omit<HTMLAttributes<HTMLElement>, keyof Props>
export type SpinnerProps = Props & NativeAttributes

const createSpans = (fill?: string) =>
  [...new Array(12).fill(undefined)].map((_, index) => (
    <Span style={{ backgroundColor: fill }} key={index} />
  ))

/**
 * A progress indicator expressing an unspecified wait time.
 */
const Spinner: FC<SpinnerProps> = ({
  className,
  fill = Colors.MINE_SHAFT,
  size = 30,
  style,
  ...props
}: SpinnerProps) => {
  const sizeString = useMemo(
    () => (typeof size === 'number' ? `${size}px` : `${size}`),
    [size]
  )
  return (
    <SpinnerContainer
      className={className}
      sizeString={sizeString}
      style={style}
      {...props}
    >
      <SpanContainer>{createSpans(fill)}</SpanContainer>
    </SpinnerContainer>
  )
}

const spinAnimation = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.15;
  }
`
const SpinnerContainer = styled.div<{ sizeString: string }>`
  box-sizing: border-box;
  display: block;
  height: ${({ sizeString }) => sizeString};
  margin: 0;
  padding: 0;
  width: ${({ sizeString }) => sizeString};
`
const SpanContainer = styled.div`
  height: 100%;
  left: 50%;
  position: relative;
  top: 50%;
  width: 100%;
`
const Span = styled.span`
  animation: ${spinAnimation} 1.2s linear 0s infinite normal none running;
  border-radius: 4px;
  height: 8%;
  left: -10%;
  position: absolute;
  top: -3.9%;
  width: 24%;

  &:nth-child(1) {
    animation-delay: -1.2s;
    transform: rotate(0deg) translate(146%);
  }
  &:nth-child(2) {
    animation-delay: -1.1s;
    transform: rotate(30deg) translate(146%);
  }
  &:nth-child(3) {
    animation-delay: -1s;
    transform: rotate(60deg) translate(146%);
  }
  &:nth-child(4) {
    animation-delay: -0.9s;
    transform: rotate(90deg) translate(146%);
  }
  &:nth-child(5) {
    animation-delay: -0.8s;
    transform: rotate(120deg) translate(146%);
  }
  &:nth-child(6) {
    animation-delay: -0.7s;
    transform: rotate(150deg) translate(146%);
  }
  &:nth-child(7) {
    animation-delay: -0.6s;
    transform: rotate(180deg) translate(146%);
  }
  &:nth-child(8) {
    animation-delay: -0.5s;
    transform: rotate(210deg) translate(146%);
  }
  &:nth-child(9) {
    animation-delay: -0.4s;
    transform: rotate(240deg) translate(146%);
  }
  &:nth-child(10) {
    animation-delay: -0.3s;
    transform: rotate(270deg) translate(146%);
  }
  &:nth-child(11) {
    animation-delay: -0.2s;
    transform: rotate(300deg) translate(146%);
  }
  &:nth-child(12) {
    animation-delay: -0.1s;
    transform: rotate(330deg) translate(146%);
  }
`

Spinner.displayName = 'Spinner'

export default Spinner
