import chalk from 'chalk'

interface Warning {
  /**
   * The component that threw the error
   */
  component?: string
  /**
   * The library that threw the error
   */
  lib?: string
  /**
   * The error message
   */
  message: string
  /**
   * The prop that threw the error
   */
  prop?: string
  /**
   * Whether to use a console.trace or console.warn
   */
  useTrace?: boolean
}

export const warning = ({
  message,
  lib,
  component,
  prop,
  useTrace,
}: Warning) => {
  // console.log(lib)
  // const tag = component ? chalk.blueBright.italic(`[${component}]`) : ' '

  const buildError = () => {
    const type = chalk.redBright.bold('Error')

    let message = ''

    if (lib) {
      message = component
        ? `The ${chalk.bold.underline(
            component
          )} component in ${chalk.bold.underline(lib)} threw in error`
        : `There was an error in the ${chalk.bold.underline(lib)} library.`
    } else {
      message = component
        ? `The ${chalk.bold.underline(component)} threw in error.`
        : `There was an error.`
    }
    message = `${type}: ${message}`
    return message
  }

  const buildMessage = () =>
    prop ? `${message} (prop: ${chalk.bold(prop)})` : message

  const lines = [buildError(), buildMessage()]
  // lines.push( lineOne = buildError()
  // const lineTwo = buildMessage()
  if (prop) {
    lines.push(`Please look at the prop ${chalk.bold(prop)}`)
  }

  // const prefix = chalk.bgRedBright.whiteBright.bold(
  //   component ? `[UI | ${chalk.italic(component)}]` : '[UI]'
  // )
  // const log = prop
  //   ? `${prefix}: ${message} (prop: ${chalk.bold(prop)})`
  //   : `${prefix}: ${message}`

  const log = lines.join('\n\t')
  // const log = `${lineOne}\n\n\t${lineTwo}\n\n\t${lineThree}`
  // const log = prop ? `${message} (prop: ${chalk.bold(prop)})` : message

  if (typeof console === 'undefined') return

  if (process.env.NODE_ENV !== 'production') {
    return console.error(log)
  }

  useTrace ? console.trace(log) : console.warn(log)

  // console.warn(log)
}

export default warning
