import * as React from 'react'
import type { SVGProps } from 'react'
const CustomerService = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <g>
      <path d='M16 0C7.18 0 0 6.59 0 14.7a14.13 14.13 0 0 0 6 11.42v4.76a1.11 1.11 0 0 0 .53.95 1.12 1.12 0 0 0 .59.17 1.07 1.07 0 0 0 .49-.12l5.51-2.73a18.11 18.11 0 0 0 2.88.25c8.82 0 16-6.6 16-14.7S24.82 0 16 0zm0 27.16a15.52 15.52 0 0 1-2.88-.27 1.12 1.12 0 0 0-.7.09l-4.24 2.11v-3.54a1.12 1.12 0 0 0-.49-.93 12 12 0 0 1-5.47-9.92C2.22 7.82 8.4 2.23 16 2.23S29.78 7.82 29.78 14.7 23.6 27.16 16 27.16z' />
      <path d='M15.83 7.85A3.86 3.86 0 0 0 12 11.71a1.11 1.11 0 1 0 2.22 0 1.64 1.64 0 1 1 3.27 0c0 .48-.22.79-.87 1.23a3.71 3.71 0 0 0-1.88 3.13v.34a1.12 1.12 0 1 0 2.23 0v-.34c0-.51.22-.83.89-1.28a3.66 3.66 0 0 0 1.85-3.08 3.86 3.86 0 0 0-3.88-3.86zm0 11.34a1.35 1.35 0 0 0-1.36 1.36 1.36 1.36 0 1 0 1.36-1.36z' />
    </g>
  </svg>
)
export default CustomerService
