/**
 * hook for loading timelines
 * @refresh reset
 */

import { cloneDeep, isEqual } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import {
  ApiRequestOptions,
  CacheOptions,
  GetMetadataRequest,
  MutationCommentCreateRequest,
  MutationCommentDeleteRequest,
  MutationComplaintCreateRequest,
  MutationPollVoteRequest,
  MutationPostApproveRequest,
  MutationPostCommentsEnableRequest,
  MutationPostDeleteRequest,
  MutationPostPinRequest,
  MutationPostRemoveFromTimelineRequest,
  MutationPostUpdateRequest,
  MutationSetLikeDeleteRequest,
  MutationSetLikeRequest,
  MutationSetPostViewedRequest,
  Post,
  PostMetadata,
  QueryCommunityPostsRequest,
  QueryLikeUsersRequest,
  QueryPollVotesRequest,
  QueryPostCommentsRequest,
  QueryPostsRequest,
  QueryTeamRequest,
  Team,
  User,
  getMetadata,
  mutationCommentCreate,
  mutationCommentDelete,
  mutationComplaintCreate,
  mutationPollVote,
  mutationPostApprove,
  mutationPostCommentsEnable,
  mutationPostDelete,
  mutationPostPin,
  mutationPostRemoveFromTimeline,
  mutationPostUpdate,
  mutationSetLike,
  mutationSetLikeDelete,
  mutationSetPostViewed,
  queryCommunityPosts,
  queryLikeUsers,
  queryPollVotes,
  queryPost,
  queryPostComments,
  queryPosts,
  queryTeam,
} from '@sportsyou/api'
import { parseUrls } from '@sportsyou/core'
import { useFetchApi } from './use-fetch-api'
import {
  TimelineUpdateInfo,
  useTimelineSubscription,
} from './use-timeline-subscription'

export type Status = 'error' | 'ok' | undefined

export interface FetchPostCriteria {
  complaintId?: string
  direction?: 'older' | 'newer' | null
  multiPostId?: string | null
  page?: number | null
  postId?: string
  startPostId?: string | null
  teamId?: string | null
  userId?: string | null
  force?: boolean //true to force call to fetch, override optomization preventing the fetch
}

export type PostData = Post & {
  extraDataLoaded?: boolean
  likeUsers?: User[]
  metadata: PostMetadata[] | undefined
}

export interface PostElement {
  firstId: string
  lastId: string
  multiPost?: PostData[]
  post?: PostData
}

export interface FetchResult {
  criteria: FetchPostCriteria
  posts?: Post[]
}

interface Map {
  [key: string]: any
}

export const PER_PAGE = 10

export const FIELD_LIST = `
  allowComments
  canEdit
  canPin
  canPost
  color
  comments {
    createdAt
    createdBy {
      firstName
      fullName
      id
      lastName
      profileImage {
        contentType
        transcodeType
        viewUrl
      }
    }
    id
    isOwner
    message
  }
  community {
    about
    admins {
      adminRoles
      email
      firstName
      fullName
      isDeleted
      lastName
      phoneNumber
      userId
    }
    canApprovePosts
    canEdit
    canPost
    communityUrl
    coverImage {
      contentType
      durationInMs
      fileSize
      fileSizeLong
      height
      id
      multiUploadId
      signedUrl
      transcodeType
      viewUrl
      width
    }
    id
    isAdmin
    isApprover
    isFavorite
    isFollowing
    isModerator
    name
    preferences {
      postsRequireApproval
    }
    profileImage {
      contentType
      durationInMs
      fileSize
      fileSizeLong
      height
      id
      multiUploadId
      signedUrl
      transcodeType
      viewUrl
      width
    }
    searchCategories {
      description
      id
      type
    }
  }
  communityPostInfo {
    buttonText
    canViewStats
    clickThroughText
    clickThroughUrl
    headline
    openWebLinkForImages
    origClickThroughUrl
    tagline
  }
  createdAt
  createdBy {
    firstName
    fullName
    id
    lastName
    profileImage {
      contentType
      transcodeType
      viewUrl
    }
  }
  id
  isAnnouncement
  isCreator
  isEdited
  isLiked
  isOwner
  isPending
  isPinned
  message
  multiPostId
  numberComments
  numberLikes
  origPostId
  origDeleted
  playlists {
    clipIds
    createdAt
    createdBy {
      aliases
      firstName
      fullName
      id
      isActive
      lastName
      profileImage {
        contentType
        transcodeType
        viewUrl
      }
    }
    deletedAt
    duration
    eventIds
    id
    isActive
    thumbnailSrc
    title
    updatedAt
  }
  poll {
    choices {
      currentUserVote
      desc
      id
      voteCount
    }
    expDate
    question
  }
  postViewed
  team {
    id
    isLargeTeam
    name
    profileImage {
      contentType
      transcodeType
      viewUrl
    }
    type
    uniqueMembersCount
  }
  reportedAt
  reportedByFullName
  reportedById
  reportedReason
  sponsoredPostInfo {
    aliasUser {
      id
    }
    buttonText
    canViewStats
    clickThroughText
    clickThroughUrl
    headline
    openWebLinkForImages
    origClickThroughUrl
    selections {
       genders
       locations {
          city
          country
          postalCode
          stateProvince
       }
       sportIds
       teamRoles
       userTypes
    }
    tagline
  }
  uploads {
    contentType
    deletedAt
    durationInMs
    fileName
    height
    id
    isAnimated
    transcodes {
      contentType
      height
      transcodeType
      viewUrl
      width
    }
    viewUrl
    width
  }
  viewCount
  viewed {
    aliasFirstName
    aliasLastName
    aliases
    firstName
    fullName
    isActive
    lastName
    profileImage {
      contentType
      transcodeType
      viewUrl
    }
    userId
  }
`

export interface UseTimelineProps {
  onSinglePostUpdate?: (post: PostData) => void
  onTimelineUpdate?: (arg: TimelineUpdateInfo) => void
  isCommunity?: boolean
  communityId?: string
  perPage?: number
  reloadTimeline?: boolean
  subscriptionPaused?: boolean
  teamMemberFieldList: string
  teams: Team[]
  updateReloadTimeline?: (args: any) => void
}

export function useTimeline(props: UseTimelineProps) {
  const [currentPost, setCurrentPost] = useState<PostData>()
  const [fetchPostCriteria, _setFetchPostCriteria] =
    useState<FetchPostCriteria | null>(null)
  const setFetchPostCriteria = (crit: FetchPostCriteria | null) => {
    const _crit = structuredClone(crit ?? {})
    delete _crit.force
    const isSameCriteria = isEqual(_crit, fetchPostCriteria)
    if (crit?.direction === 'newer' || crit?.force || !isSameCriteria) {
      isSameCriteria && _setFetchPostCriteria(null)
      _setFetchPostCriteria(_crit)
    }
  }
  const [isInitialFetch, setIsIntialFetch] = useState(true)
  const [isNewPostAvailable, setNewPostAvailable] = useState(false)
  const [posts, _setPosts] = useState<PostElement[]>()
  const setPosts = (newPosts: PostElement[]) => {
    if (!isEqual(newPosts, posts)) {
      _setPosts(newPosts)
    } else {
      _setFetchPostCriteria(null)
    }
  }
  const [operationType, setOperationType] = useState('')
  const [operationPostId, setOperationPostId] = useState('')
  const [operationTimestamp, setOperationTimestamp] = useState('')

  const isRefreshRequired = useRef(false)
  const isSubscriptionPaused = useRef(false)
  const lastFetchResult = useRef<FetchResult>()

  const getPollVotes = useFetchApi(queryPollVotes)
  const getPostLikes = useFetchApi(queryLikeUsers)
  const getTeam = useFetchApi(queryTeam)
  const setAllowComments = useFetchApi(mutationPostCommentsEnable)
  const setComplaintCreate = useFetchApi(mutationComplaintCreate)
  const setLike = useFetchApi(mutationSetLike)
  const setLikeDelete = useFetchApi(mutationSetLikeDelete)
  const { fetch: setPollVote } = useFetchApi(mutationPollVote)
  const setPostApprove = useFetchApi(mutationPostApprove)
  const setPostDisapprove = useFetchApi(mutationPostDelete)
  const setPostHide = useFetchApi(mutationPostRemoveFromTimeline)
  const setPostPinned = useFetchApi(mutationPostPin)
  const setPostUpdate = useFetchApi(mutationPostUpdate)
  const setPostViewed = useFetchApi(mutationSetPostViewed)
  const { fetch: createComment } = useFetchApi(mutationCommentCreate)
  const { fetch: deleteComment } = useFetchApi(mutationCommentDelete)
  const { fetch: getComments } = useFetchApi(queryPostComments)

  // check redux to see if the timeline needs reloading or if we should pause the subscription
  useEffect(() => {
    if (props.reloadTimeline !== undefined) {
      isRefreshRequired.current = props.reloadTimeline ? true : false
    }
    if (props.subscriptionPaused !== undefined) {
      isSubscriptionPaused.current = props.subscriptionPaused ? true : false
    }
    if (
      props.reloadTimeline !== undefined ||
      props.subscriptionPaused !== undefined
    ) {
      props.updateReloadTimeline?.({
        reloadTimeline: undefined,
        subscriptionPaused: undefined,
      })
    }
  }, [props.reloadTimeline, props.subscriptionPaused])

  const getSinglePostHook = useFetchApi(() => {
    if (fetchPostCriteria?.postId) {
      return queryPost({
        id: fetchPostCriteria.postId,
        complaintId: fetchPostCriteria.complaintId,
        requestOptions: { fieldList: FIELD_LIST },
      })
    }
    return
  })

  const getPostsHook = useFetchApi(() => {
    if (
      fetchPostCriteria?.startPostId ||
      fetchPostCriteria?.multiPostId ||
      fetchPostCriteria?.page ||
      fetchPostCriteria?.userId ||
      fetchPostCriteria?.teamId ||
      fetchPostCriteria?.direction
    ) {
      const page = fetchPostCriteria.page ?? 0
      const startPostId = fetchPostCriteria.startPostId ?? null
      const multiPostId = fetchPostCriteria.multiPostId ?? null
      const teamId = fetchPostCriteria.teamId ?? null
      const userId = fetchPostCriteria.userId ?? null
      const direction = fetchPostCriteria.direction ?? 'older'
      const perPage =
        multiPostId || direction === 'newer' ? 999 : props.perPage ?? PER_PAGE
      const requestOptions: ApiRequestOptions = { fieldList: FIELD_LIST }
      if (page === 1) {
        requestOptions.useCache = CacheOptions.BATCH_PRELOAD
      }

      if (props.isCommunity) {
        return queryCommunityPosts({
          communityId: props.communityId,
          direction,
          includeFollowingPosts: true,
          perPage,
          requestOptions,
          startPostId,
        } as QueryCommunityPostsRequest)
      }

      return queryPosts({
        startPostId,
        multiPostId,
        page,
        userId,
        teamId,
        direction,
        perPage,
        includeFriends: !teamId,
        includeTeams: !userId,
        requestOptions,
      } as QueryPostsRequest)
    }
    return
  })

  function sortMultiPost(mp: Post[]): PostData[] {
    const ret: PostData[] = []

    if (!mp) return ret

    ret.push(...mp.map((post) => ({ ...post, metadata: undefined })))

    return ret.sort((a: PostData, b: PostData) => {
      const teamNameA = (a.team?.name || '').toUpperCase()
      const teamNameB = (b.team?.name || '').toUpperCase()
      if (teamNameA > teamNameB) return 1
      if (teamNameA < teamNameB) return -1
      return 0
    })
  }

  async function fetchMetadata(post: PostData | null | undefined) {
    const md: PostMetadata[] = []
    if (post?.message && !post.color) {
      const urls = parseUrls(post.message)
      if (urls && urls.length > 0) {
        for (let i = 0; i < urls.length; i++) {
          const req: GetMetadataRequest = {
            url: urls[i],
            requestOptions: {
              useCache: CacheOptions.ALWAYS,
            },
          }
          const { data, error } = await getMetadata(req)
          if (error) {
            console.log(error)
          }
          if (data && data.displayMetaData) {
            md.push(data)
          }
        }
      }
    }
    return md
  }

  async function fetchExtraData(post: PostData) {
    if (post.extraDataLoaded) return false

    // load the post likes
    if (post.numberLikes || (0 > 0 && !post.likeUsers)) {
      const res = await getPostLikes.fetch({
        postId: post.id,
      } as QueryLikeUsersRequest)
      if (res.data) {
        post.likeUsers = res.data
      }
    }

    // if it's a poll, load the choices
    if (post.poll) {
      const choices = post.poll.choices
      if (choices) {
        let loadVotes = false
        for (let i = 0; i < choices.length; i++) {
          const choice = choices[i] ?? { voteCount: 0 }
          if (choice.voteCount! > 0 && !choice.votes) {
            loadVotes = true
            break
          }
        }
        if (loadVotes) {
          const res = await getPollVotes.fetch({
            postId: post.id,
          } as QueryPollVotesRequest)
          if (res.data) {
            post.poll.choices = res.data
          }
        }
      }
    }

    post.extraDataLoaded = true

    return true
  }

  // get metadata for the posts
  useEffect(() => {
    //@ts-ignore
    async function doExtraDataFetch() {
      const teamsMap: Map = {}
      // disabled preloading data because it was make the post list load too slowly
      const preloadData = false

      if (posts?.length) {
        const _posts = cloneDeep(posts)
        let modified = false
        for (let i = 0; i < _posts.length; i++) {
          const post = _posts[i].post
          const multiPost = _posts[i].multiPost
          if (post) {
            if (!post.metadata?.length) {
              const res = await fetchMetadata(post)
              if (res.length) {
                post.metadata = res
                modified = true
              }
            }
            if (preloadData && (await fetchExtraData(post))) modified = true
            if (post.team) {
              teamsMap[post.team.id!] = post.team
            }
          } else if (multiPost) {
            for (let j = 0; j < multiPost.length; j++) {
              const mp = multiPost[j]
              if (mp) {
                if (!mp.metadata?.length) {
                  const res = await fetchMetadata(mp)
                  if (res.length) {
                    mp.metadata = res
                    modified = true
                  }
                }
                if (preloadData && (await fetchExtraData(mp))) modified = true
                if (mp.team) {
                  teamsMap[mp.team.id!] = mp.team
                }
              }
            }
          }
        }
        if (modified) {
          setPosts([..._posts])
        }

        if (preloadData) {
          const teamIds = Object.keys(teamsMap)
          for (let i = 0; i < teamIds.length; i++) {
            // query team for post viewed by. Just load the team data into the cache to speed up post viewed if the user clicks on it later
            getTeam.fetch({
              id: teamIds[i],
              includeDeactivatedUsers: false,
              includeLargeTeamMembers: false,
              requestOptions: {
                useCache: CacheOptions.ALWAYS,
                cacheTimeout: 600000,
                fieldList: props.teamMemberFieldList,
              },
            } as QueryTeamRequest)
          }
        }
      }
    }

    doExtraDataFetch()
  }, [posts])

  // add the posts to the post list when the post fetch hook comes in
  useEffect(() => {
    function handlePostEffect() {
      let res: Post[] = []
      if (getPostsHook.data) {
        res = [...getPostsHook.data]
      } else if (getSinglePostHook.data) {
        res = [getSinglePostHook.data]
      }

      // remove and pinned posts from the list if we aren't loading the first page
      if (fetchPostCriteria?.startPostId && fetchPostCriteria.teamId) {
        for (let i = res.length - 1; i >= 0; i--) {
          if (res[i].isPinned) {
            res.pop()
          }
        }
      }

      // if the list ends with a multipost id then we have to do another query to make sure we've loaded all of them
      let mpId = null
      if (
        res.length > 0 &&
        res[res.length - 1].multiPostId &&
        res[res.length - 1].multiPostId !== fetchPostCriteria?.multiPostId &&
        !fetchPostCriteria?.userId &&
        !fetchPostCriteria?.teamId &&
        !fetchPostCriteria?.postId
      ) {
        mpId = res[res.length - 1].multiPostId || null

        // remove the multi posts from the current list. We'll load those up later
        for (let i = res.length - 1; i >= 0; i--) {
          if (res[i].multiPostId === mpId) {
            res.pop()
          } else {
            break
          }
        }

        if (res.length === 0) {
          setFetchPostCriteria({
            multiPostId: mpId,
            direction: fetchPostCriteria?.direction,
          })
          return
        }
      }

      // build list of newly returned items
      const newPosts: PostElement[] = []
      if (fetchPostCriteria?.multiPostId) {
        // if we queried a multipostt push it to the multipost part of the data structure, put the id of the last post so if we fetch more it will work
        if (res.length !== 0) {
          newPosts.push({
            lastId: res[res.length - 1]?.id ?? '',
            firstId: res[0]?.id ?? '',
            multiPost: sortMultiPost(res),
          })
        }
      } else {
        // if we queried a multipost we could get regular posts or multiposts so handle both
        let lastMultipost: PostData[] = []

        const addMultiPostToScreen = () => {
          if (lastMultipost.length === 1) {
            newPosts.push({
              lastId: lastMultipost[0].id ?? '',
              firstId: lastMultipost[0].id ?? '',
              post: lastMultipost[0],
            })
          } else if (lastMultipost.length > 0) {
            lastMultipost = sortMultiPost(lastMultipost)
            newPosts.push({
              lastId: lastMultipost[lastMultipost.length - 1].id ?? '',
              firstId: lastMultipost[0].id ?? '',
              multiPost: lastMultipost,
            })
          }
          lastMultipost = []
          mpId = null
        }

        for (let i = 0; i < res.length; i++) {
          if (
            res[i].multiPostId &&
            !fetchPostCriteria?.teamId &&
            !fetchPostCriteria?.userId
          ) {
            if (lastMultipost.length === 0) {
              // if the current multipost we are working on is empty then this will be the
              // first item
              lastMultipost.push({ ...res[i], metadata: undefined })
            } else if (
              lastMultipost[lastMultipost.length - 1].multiPostId !==
              res[i].multiPostId
            ) {
              // if it's a different multi post then the one we are working on then add it
              // to the list to display and start a new multipost array
              addMultiPostToScreen()
              lastMultipost = [{ ...res[i], metadata: undefined }]
            } else {
              // part of a currently running multipost, just add it
              lastMultipost.push({ ...res[i], metadata: undefined })
            }
          } else {
            addMultiPostToScreen()
            newPosts.push({
              lastId: res[i].id ?? '',
              firstId: res[i].id ?? '',
              post: { ...res[i], metadata: undefined },
            })
          }
        }
        addMultiPostToScreen()
      }

      if (fetchPostCriteria?.page === 1 || !posts || !posts.length) {
        // for clearing the posts or setting the initial page
        setPosts(newPosts)
        if (newPosts.length) setTimeout(() => setIsIntialFetch(false), 3000)
        else setIsIntialFetch(false)
      } else if (res.length > 0) {
        // figure out where to insert the new posts into the list of posts
        const firstPost = res[0].id
        const lastPost = res[res.length - 1].id
        let firstNdx = -1
        if (fetchPostCriteria?.direction === 'newer') {
          firstNdx = 0
        } else {
          for (let i = 0; i < posts.length; i++) {
            if (posts[i].lastId === firstPost) {
              firstNdx = i
            } else if (posts[i].lastId === lastPost) {
              break
            }
          }
        }
        if (firstNdx === -1) {
          setPosts([...posts, ...newPosts])
          setIsIntialFetch(false)
        } else {
          // Get all ids from previous post arr
          const previousPostIds = posts.map((p) => {
            if (p.multiPost?.length) {
              return p.multiPost[0].id
            }
            return p.post?.id
          })

          // Filter out any dupes (this may happen on pinned posts)
          const filteredNewPosts = newPosts.filter((p) => {
            if (p.multiPost?.length) {
              return !previousPostIds.includes(p.multiPost[0].id)
            }
            return !previousPostIds.includes(p.post?.id)
          })

          if (fetchPostCriteria?.direction === 'newer') {
            setPosts([...filteredNewPosts, ...posts])
          } else {
            setPosts([...posts, ...filteredNewPosts])
          }
          setIsIntialFetch(false)
        }
      }

      if (mpId) {
        setFetchPostCriteria({ multiPostId: mpId })
      }
    }

    if (
      getPostsHook.response !== undefined ||
      getSinglePostHook.response !== undefined
    ) {
      handlePostEffect()
    }
  }, [getPostsHook.response, getSinglePostHook.response])

  function isMultipostOnScreen(multipostId: string) {
    if (posts) {
      for (let i = 0; i < posts.length; i++) {
        const mp = posts[i].multiPost
        if (mp && mp.length > 0) {
          if (mp[0].multiPostId === multipostId) {
            return true
          }
        }
      }
    }
    return false
  }

  useEffect(() => {
    async function handleCriteriaEffect() {
      if (fetchPostCriteria) {
        if (fetchPostCriteria.postId) {
          const criteria = fetchPostCriteria
          const res = await getSinglePostHook.fetch()
          lastFetchResult.current = {
            criteria,
            posts: res.data ? [res.data] : undefined,
          }
        } else if (fetchPostCriteria?.page === 0) {
          setPosts([])
        } else {
          const criteria = fetchPostCriteria
          if (
            criteria.multiPostId &&
            isMultipostOnScreen(criteria.multiPostId)
          ) {
            return
          }
          const res = await getPostsHook.fetch()
          lastFetchResult.current = {
            criteria,
            posts: [...(res.data ?? [])],
          }
        }
      }
    }
    handleCriteriaEffect()
  }, [fetchPostCriteria])

  function fetch(criteria?: FetchPostCriteria) {
    setNewPostAvailable(false)
    isRefreshRequired.current = false
    if (!criteria) {
      setFetchPostCriteria({
        startPostId: null,
        multiPostId: null,
        page: 1,
      })
    } else {
      setFetchPostCriteria(criteria)
    }
  }

  // hook for timeline changes, find and update the post on the screen when timeline changes
  const timelineSubscription = useTimelineSubscription([], {
    onUpdate: props.onTimelineUpdate,
  })

  useEffect(() => {
    function handleTimelineSubscription() {
      if (isSubscriptionPaused.current) {
        setTimeout(() => (isSubscriptionPaused.current = false), 5000)
        return
      }
      if (timelineSubscription.result) {
        const insertOperation =
          timelineSubscription.timeline?.operationType === 'insert'
        const deleteOperation =
          timelineSubscription.timeline?.operationType === 'delete'
        setOperationType(
          timelineSubscription.timeline?.operationType ?? 'unknown'
        )
        const operationPostId = timelineSubscription.timeline?.postId
        setOperationPostId(timelineSubscription.timeline?.postId ?? 'unknown')
        setOperationTimestamp(new Date().toString())
        const metadata = timelineSubscription.postMetadata ?? undefined
        if (insertOperation) {
          if (fetchPostCriteria?.teamId) {
            if (
              timelineSubscription.post?.team?.id === fetchPostCriteria.teamId
            )
              setNewPostAvailable(true)
          } else if (fetchPostCriteria?.userId) {
            if (
              timelineSubscription.post?.createdBy?.id ===
              fetchPostCriteria.userId
            )
              setNewPostAvailable(true)
          } else {
            setNewPostAvailable(true)
          }
        } else if (posts) {
          /**
           * make sure we're not changing properties by referencing existing state properties
           * doing so will nullify equality test inside setPosts function as it will always
           * return true on updates
           */
          for (let i = 0; i < posts.length; i++) {
            let post: PostElement = {
              ...structuredClone(posts[i]),
            }

            if (post.lastId === operationPostId && post.post) {
              if (deleteOperation) {
                const temp = [...posts]
                temp?.splice(i, 1)
                setPosts(temp)
              } else {
                const postData: Post = timelineSubscription.post
                  ? { ...timelineSubscription.post }
                  : post.post

                post = {
                  ...post,
                  post: {
                    ...postData,
                    metadata,
                  },
                }

                const temp = [...posts]
                temp?.splice(i, 1, post)
                setPosts(temp)
              }
              break
            } else if (post.multiPost?.length) {
              const mp = post.multiPost ?? []
              let postFound = false
              for (let j = 0; j < mp.length; j++) {
                if (mp[j].id === operationPostId) {
                  postFound = true
                  if (deleteOperation) {
                    mp.splice(j, 1)
                    if (mp.length === 0) {
                      const temp = [...posts]
                      temp?.splice(i, 1)
                      setPosts(temp)
                    } else {
                      if (mp.length === 1) {
                        post.post = mp[0]
                        post.firstId = mp[0].id ?? ''
                        post.lastId = mp[0].id ?? ''
                        delete post.multiPost
                      }

                      const temp = [...posts]
                      temp?.splice(i, 1, post)
                      setPosts(temp)
                    }
                  } else {
                    let mPost: Post = mp[j] ?? {}
                    if (timelineSubscription.post)
                      mPost = timelineSubscription.post
                    mp[j] = {
                      ...mPost,
                      metadata,
                    }

                    const temp = [...posts]
                    temp?.splice(i, 1, post)
                    setPosts(temp)
                  }
                  break
                }
              }
              if (postFound) break
            }
          }
        }
      }
    }
    handleTimelineSubscription()
  }, [timelineSubscription.result])

  const pollVote = async (postId: string, choiceId = '') => {
    const { error, ok } = await setPollVote({
      choiceId,
      postId,
    } as MutationPollVoteRequest)
    if (ok) {
      const post = await getPost(postId)

      if (!post) return

      // index of poll choice
      // We use this index to update the correct choice in the array
      const choiceIndex =
        post?.poll?.choices?.findIndex(
          (item) => item?.id === choiceId || item?.currentUserVote
        ) ?? -1

      // Make a copy of post.poll and update the user's vote
      const _poll =
        {
          ...post.poll,
          choices: post.poll?.choices?.map((c, i) => {
            // Check if the user is adding a vote
            if (choiceIndex !== -1) {
              return {
                ...c,
                // Manipulate the data only if the indexes match
                currentUserVote:
                  choiceIndex === i ? !c?.currentUserVote : c?.currentUserVote,
              }
            }

            // Handle for removing a vote
            return { ...c, currentUserVote: false }
          }),
        } ?? {}

      // Update posts with new poll data
      updatePosts(postId, { poll: _poll })
    }
    if (error) {
      console.log({ error })
    }
  }

  const postApprove = useDebouncedCallback(
    (postId: string, teamId?: string) => {
      postViewed(postId)
      return setPostApprove.fetch({
        postId,
        teamId,
      } as MutationPostApproveRequest)
    },
    2000,
    { leading: true, trailing: false }
  )

  async function postDelete(postId: string, shouldSetViewed = true) {
    if (shouldSetViewed) {
      await postViewed(postId, true)
    }
    posts?.length && setPosts(posts?.filter((post) => post.post?.id !== postId))
    return setPostDisapprove.fetch({ postId } as MutationPostDeleteRequest)
  }

  function postDeleteScheduled(scheduleId: string) {
    return setPostDisapprove.fetch({ scheduleId } as MutationPostDeleteRequest)
  }

  async function postHide(postId: string) {
    await postViewed(postId, true)
    posts?.length && setPosts(posts?.filter((post) => post.post?.id !== postId))
    return setPostHide.fetch({
      postId,
    } as MutationPostRemoveFromTimelineRequest)
  }

  function allowComments(postId: string, enabled: boolean) {
    postViewed(postId)
    return setAllowComments.fetch({
      postId,
      enabled,
    } as MutationPostCommentsEnableRequest)
  }

  async function postViewed(postId: string, apiOnly?: boolean) {
    const _posts = structuredClone(posts ?? [])
    const post = _posts
      ?.map((post) => {
        if (post.multiPost) {
          return post.multiPost.filter((mp) => mp.id === postId).at(0)
        }
        return post.post?.id === postId ? post.post : undefined
      })
      .filter(Boolean)
      .at(0)

    if (apiOnly) {
      if (!post || (post && !post.isCreator && !post.postViewed)) {
        await setPostViewed.fetch({ postId } as MutationSetPostViewedRequest)
      }
    } else if (posts?.length) {
      if (post && !post.isCreator && !post.postViewed) {
        post.postViewed = true
        setPosts(_posts)
        await setPostViewed.fetch({ postId } as MutationSetPostViewedRequest)
      }
    }
  }

  async function pinPost(postId: string, isPinned: boolean) {
    isSubscriptionPaused.current = true
    const res = await setPostPinned.fetch({
      postId,
      isPinned,
    } as MutationPostPinRequest)
    setTimeout(() => (isSubscriptionPaused.current = false), 10000)
    return res
  }

  async function postLiked(postId: string, isLiked: boolean) {
    const post = await getPost(postId)
    if (isLiked) {
      postViewed(postId)
      updatePosts(postId, {
        isLiked: true,
        numberLikes: (post?.numberLikes ?? 0) + 1,
      })
      return setLike.fetch({ postId } as MutationSetLikeRequest)
    } else {
      const numberLikes = post?.numberLikes ?? 0
      updatePosts(postId, {
        isLiked: false,
        numberLikes: numberLikes > 0 ? numberLikes - 1 : 0,
      })
      return setLikeDelete.fetch({
        postId: postId,
      } as MutationSetLikeDeleteRequest)
    }
  }

  function postUpdated(postId: string, newMessage: string) {
    return setPostUpdate.fetch({
      postId: postId,
      message: newMessage,
    } as MutationPostUpdateRequest)
  }

  function createComplaint(
    targetId: string,
    postId: string,
    commentId: string | undefined,
    reason: string
  ) {
    return setComplaintCreate.fetch({
      targetId,
      postId,
      commentId,
      reason,
    } as MutationComplaintCreateRequest)
  }

  function getPostIndex(postId: string, posts?: Array<PostElement>) {
    return (
      posts?.findIndex((el) => {
        if (el.multiPost) {
          return (
            el.multiPost.filter((p) => p.id === postId).map((p) => p.id)[0] ===
            postId
          )
        }
        return el.post?.id === postId
      }) ?? -1
    )
  }

  /**
   * Get post from stored state
   * @param postId Id of specific post
   * @returns PostData | undefined
   */
  async function getPost(postId: string): Promise<PostData | undefined> {
    if (currentPost?.id === postId) {
      return currentPost
    }
    const postIndex = getPostIndex(postId, posts)
    let post
    if (posts && postIndex > -1 && postIndex < posts.length) {
      const postEl = posts[postIndex]
      post = postEl.post
        ? postEl.post
        : postEl.multiPost?.filter((p) => p.id === postId)[0]
      setCurrentPost(post)
      return post
    }
    if (!post) {
      post = await getPostFromServer(postId)
      setCurrentPost(post)
      return post
    }
    return
  }

  // fetch post from server
  async function getPostFromServer(
    postId: string
  ): Promise<PostData | undefined> {
    setFetchPostCriteria({ postId })

    const { data } = await queryPost({
      id: postId,
      requestOptions: { fieldList: FIELD_LIST },
    })
    if (data) {
      const _post = data as PostData
      // attach metadata
      const res = await fetchMetadata(_post as PostData)
      if (res.length) {
        _post.metadata = res
      }
      return _post
    }
    return
  }

  async function updatePosts(postId: string, options: Post) {
    const postIndex = getPostIndex(postId, posts)
    if (posts && postIndex > -1) {
      const postEl = posts[postIndex]
      const _post = postEl.post
        ? postEl.post
        : postEl.multiPost?.filter((p) => p.id === postId)[0]

      if (_post) {
        let newPost: PostElement = postEl
        if (postEl.post) {
          newPost = {
            ...postEl,
            post: { ...postEl.post, ...options },
          }
        } else if (postEl.multiPost) {
          newPost = {
            ...postEl,
            multiPost: postEl.multiPost?.map((p) =>
              p.id === postId
                ? {
                    ...p,
                    ...options,
                  }
                : p
            ),
          }
        }

        const _posts: PostElement[] = [...posts]
        _posts.splice(postIndex, 1, newPost)
        setPosts(_posts)
        setCurrentPost(newPost.post as PostData)
        props.onSinglePostUpdate?.(newPost.post as PostData)
      }
    } else {
      if (currentPost?.id === postId) {
        setCurrentPost({ ...currentPost, ...options })
        props.onSinglePostUpdate?.({ ...currentPost, ...options })
      } else {
        let _post = await getPost(postId)
        _post = { ..._post, ...options } as PostData
        setCurrentPost(_post)
        props.onSinglePostUpdate?.(_post)
      }
    }
  }

  async function addComment(message: string, postId: string) {
    let status
    try {
      const { data } = await createComment({
        message,
        postId,
      } as MutationCommentCreateRequest)
      if (data) {
        const post = await getPost(postId)
        if (post) {
          // updatePosts
          // if (posts && postIndex > -1) {
          // const postEl = posts[postIndex]

          // increment numberComments on post
          // if (_post) {
          const numberComments = (post?.numberComments ?? 0) + 1
          const _comments = await fetchComments(postId, 1, numberComments)

          updatePosts(postId, { comments: _comments, numberComments })
        }

        status = 'ok'
      }
    } catch (error) {
      console.log(error)
      status = 'error'
    }
    return status
  }

  async function removeComment(
    postId: string,
    commentId: string,
    loadAllComments = false
  ): Promise<Status> {
    let status: Status
    try {
      const { data } = await deleteComment({
        id: commentId,
      } as MutationCommentDeleteRequest)

      if (data) {
        const post = await getPost(postId)
        if (post) {
          let comments =
            post?.comments?.filter((c) => c?.id !== commentId) ??
            post?.comments ??
            []
          if (loadAllComments) {
            comments = await fetchComments(postId, 1, post?.numberComments ?? 5)
          } else {
            if (comments.length < 5) {
              comments = await fetchComments(postId, 1, 5)
            }
          }
          const numberComments =
            post?.numberComments && post?.numberComments > 0
              ? post?.numberComments - 1
              : 0

          updatePosts(postId, { comments, numberComments })
        }

        status = 'ok'
      }
    } catch (error) {
      console.log(error)
      status = 'error'
    }

    return status
  }

  async function fetchComments(postId: string, page: number, perPage: number) {
    try {
      const { data } = await getComments({
        postId,
        page,
        perPage,
      })
      if (data) {
        return data ?? []
      }
    } catch (error) {
      console.log({ error })
    }
    return []
  }

  async function loadAllComments(postId: string) {
    const post = await getPost(postId)
    if (post) {
      const numOfComments = post.numberComments ?? 0
      const commentsLength = post.comments?.length ?? 0

      if (numOfComments > 5 && numOfComments !== commentsLength) {
        const comments = await fetchComments(postId, 1, numOfComments)
        if (comments.length > 0) {
          updatePosts(postId, { comments })
        }
      }
    }
  }

  async function loadComments(postId: string, page = 1, perPage = 5) {
    let post = await getPost(postId)
    if (!post && postId === currentPost?.id) {
      post = currentPost
    }
    console.log('loadComments', { post, currentPost })

    if (post) {
      const numOfComments = post.numberComments ?? 0
      const commentsLength = post.comments?.length ?? 0

      if (numOfComments > 5 && numOfComments !== commentsLength) {
        try {
          const { data } = await getComments({
            postId,
            page,
            perPage,
          } as QueryPostCommentsRequest)
          if (data) {
            updatePosts(postId, {
              comments: page === 1 ? data : [...data, ...(post.comments ?? [])],
            })
          }
        } catch (error) {
          console.log({ error })
        }
        // }
      }
    }
  }

  function reset() {
    setNewPostAvailable(false)
    setFetchPostCriteria({})
    setPosts([])
  }

  return {
    FIELD_LIST,
    PER_PAGE,
    addComment,
    allowComments,
    createComplaint,
    currentPost,
    fetch,
    isFetching: getPostsHook.isFetching || getSinglePostHook.isFetching,
    isInitialFetch,
    isNewPostAvailable,
    isRefreshRequired: isRefreshRequired.current,
    isSubscriptionPaused: isSubscriptionPaused.current,
    lastFetchResult: lastFetchResult.current,
    loadAllComments,
    loadComments,
    operationPostId,
    operationTimestamp,
    operationType,
    pinPost,
    pollVote,
    postApprove,
    postDelete,
    postDeleteScheduled,
    postDisapprove: postDelete,
    postHide,
    postLiked,
    postUpdated,
    postViewed,
    posts,
    removeComment,
    reset,
    setIsIntialFetch,
    setPosts,
  }
}

export default {
  FIELD_LIST,
  PER_PAGE,
  useTimeline,
}
