import 'react-image-crop/dist/ReactCrop.css'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactCrop, { centerCrop, makeAspectCrop, Crop } from 'react-image-crop'

import Button from '../button'
import Modal from '../modal'

export interface ImageCropperProps {
  aspect?: number // fixed scaling ratio - for example: 16/9 or 1/1 or maxWidth/maxHeight
  circular?: boolean
  height?: number
  inputFile?: File
  inputUrl?: string
  modalTitle?: string
  modalWidth?: number
  onClose: () => void
  onCrop: (objectUrl: string) => void
  width?: number
}

export default function ImageCropper(props: ImageCropperProps) {
  const [crop, setCrop] = useState<Crop>()
  const [imgSrc, setImgSrc] = useState(props.inputUrl)
  const [isLoaded, setIsLoaded] = useState(false)

  const imgRef = useRef<HTMLImageElement>(null)

  const aspect = useMemo(() => {
    if (props.aspect) {
      return props.aspect
    }
    if (props.width && props.height) {
      return props.width / props.height
    }
    return undefined
  }, [props.aspect, props.width, props.height])

  const isOpen = useMemo(() => {
    return !!props.inputUrl || !!props.inputFile
  }, [props.inputUrl, props.inputFile])

  const onImageLoad = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      if (aspect) {
        const { width, height } = e.currentTarget
        setCrop(centerAspectCrop(width, height, aspect))
      }
      setIsLoaded(true)
    },
    []
  )

  const onClose = useCallback(() => {
    props.onClose()
  }, [props])

  const onCrop = useCallback(async () => {
    const objectUrl: string = await cropImage(
      imgRef.current!,
      crop!,
      props.width,
      props.height
    )
    props.onCrop(objectUrl)
  }, [crop, props])

  const onCropChange = useCallback((_: any, percentCrop: any) => {
    setCrop(percentCrop)
  }, [])

  const onCropComplete = useCallback((c: any) => {
    setCrop(c)
  }, [])

  useEffect(() => {
    if (props.inputFile) {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setImgSrc(reader.result?.toString())
      })
      reader.readAsDataURL(props.inputFile)
    }
  }, [props.inputFile])

  return (
    <Modal
      backdropIgnoresClicks
      onClose={onClose}
      contentWidth={props.modalWidth}
      visible={isOpen}
    >
      <Modal.Header>{props.modalTitle}</Modal.Header>
      <Modal.Body>
        <ReactCrop
          aspect={aspect}
          circularCrop={props.circular}
          crop={crop}
          onChange={onCropChange}
          onComplete={onCropComplete}
        >
          <img
            style={{
              border: '1px solid black',
              // transform: `scale(${scale}) rotate(${rotate}deg)`
            }}
            alt='Crop'
            crossOrigin='anonymous'
            onLoad={onImageLoad}
            ref={imgRef}
            src={imgSrc}
          />
        </ReactCrop>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={!isLoaded} onClick={onCrop}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export const centerAspectCrop = (
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

export const scaleImage = (
  image: HTMLImageElement,
  newWidth: number,
  newHeight: number
) => {
  return new Promise<string>((resolve, reject) => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    if (!ctx) {
      reject('Could not get canvas context')
      return
    }
    canvas.width = newWidth
    canvas.height = newHeight
    ctx.drawImage(image, 0, 0, newWidth, newHeight)
    canvas.toBlob((blob) => {
      if (!blob) {
        reject('Could not get blob')
        return
      }
      const objectUrl = URL.createObjectURL(blob)
      resolve(objectUrl)
    })
  })
}

/**
 * crop image to the crop box area of the image
 * - cropped image will scaled down to scaleWidth/Height if cropped image is larger
 * - cropped image will not be scaled up from original image resolution
 * @param image
 * @param crop
 * @param scaleWidth
 * @param scaleHeight
 * @returns
 */
export const cropImage = async (
  image: HTMLImageElement,
  crop: Crop,
  scaleWidth?: number,
  scaleHeight?: number
): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (crop) {
      if (image && crop.width && crop.height) {
        const canvas = document.createElement('canvas')
        const scaleAspectRatio =
          (scaleWidth && scaleHeight && scaleHeight / scaleWidth) || 1
        const canvasWidth = Math.min(
          scaleWidth ?? image.naturalWidth,
          image.naturalWidth
        )
        /**
         * canvasHeight is smaller of scaleHeight or image.naturalWidth scaled down by
         * ratio of scaleWidth and image.naturalWidth
         */
        const canvasHeight = Math.min(
          scaleHeight ?? image.naturalWidth * scaleAspectRatio,
          image.naturalWidth * scaleAspectRatio
        )
        /**
         * 1px border for both sides = 2
         * This is needed to correct image.width and image.height
         * as the crop container is set to image element size including the border
         * which is currently set to 1px all around.
         * So, if the crop box is full width/height,
         * the crop box would be 2px more than image.width/height.
         */
        const imageBorderDelta = 2
        const scaleFactor =
          image.naturalWidth / (image.width + imageBorderDelta)
        /**
         * canvas.width/height is smaller of canvasWidth/Height or
         * crop.width/height multiplied by scaleFactor of
         * image.naturalWidth and image.width displayed on the page
         */
        canvas.width = Math.min(crop.width * scaleFactor, canvasWidth)
        canvas.height = Math.min(crop.height * scaleFactor, canvasHeight)
        const ctx = canvas.getContext('2d')
        ctx?.drawImage(
          image,
          crop.x * scaleFactor,
          crop.y * scaleFactor,
          crop.width * scaleFactor,
          crop.height * scaleFactor,
          0,
          0,
          Math.min(crop.width * scaleFactor, canvasWidth),
          Math.min(crop.height * scaleFactor, canvasHeight)
        )
        canvas.toBlob((blob) => {
          console.log({ blob })
          if (blob) {
            const url = window.URL.createObjectURL(blob)
            resolve(url)
          } else {
            reject('Could not create blob')
          }
        }, 'image/jpeg')
      } else {
        reject('Image or crop not defined')
      }
    } else {
      reject('No crop data')
    }
  })
}
