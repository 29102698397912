import React, { useMemo } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import { usePortal } from '@sportsyou/react-hooks'

import Toast from './toast-item'
import type { ToastContainerPlacement, ToastItemProps } from './toast-types'

interface Props {
  placement: ToastContainerPlacement
  toasts?: ToastItemProps[]
}

export const ToastsContainer: React.FC<React.PropsWithChildren<Props>> = ({
  placement,
  toasts = [],
}) => {
  const portal = usePortal('toast')

  const toastElements = useMemo(
    () =>
      toasts.map((toast: ToastItemProps) => (
        <Toast
          key={toast.id}
          {...toast}
          slideFromDirection={placement.endsWith('End') ? 'right' : 'left'}
        />
      )),
    [placement, toasts]
  )

  if (!portal) return null
  if (!toasts || toasts.length === 0) return null
  return createPortal(
    <Container $placement={placement}>{toastElements}</Container>,
    portal
  )
}

const Container = styled.div<{ $placement: ToastContainerPlacement }>`
  position: fixed;
  width: auto;
  max-width: 100%;
  // right: 15px;
  // bottom: 15px;
  z-index: 2000;
  transition: all 400ms ease;
  box-sizing: border-box;
  display: flex;
  flex-direction: ${({ $placement }) =>
    $placement.startsWith('bottom') ? 'column' : 'column-reverse'};

  ${({ $placement }) => {
    if ($placement.startsWith('bottom')) {
      return $placement.endsWith('Start')
        ? {
            bottom: '20px',
            left: '20px',
          }
        : {
            bottom: '20px',
            right: '20px',
          }
    }
    return $placement.endsWith('Start')
      ? {
          left: '20px',
          top: '20px',
        }
      : {
          right: '20px',
          top: '20px',
        }
  }};
`

// .top {
//   bottom: unset;
//   flex-direction: column-reverse;
//   top: ${theme.layout.gap};
// }
// .left {
//   right: unset;
//   left: ${theme.layout.gap};
// }

export default ToastsContainer
