import { ButtonHTMLAttributes, FC, PropsWithChildren, useMemo } from 'react'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'

interface Props {
  /**
   * Wether or not the action is disabled
   */
  disabled?: boolean
  /**
   * Styles button as 'destructive'
   * @default false
   */
  isDestructive?: boolean
  /**
   * Accessibility label for button
   * @default ''
   */
  label?: string
  /**
   * Function called when action is clicked
   */
  onClick?: () => void
}

type item = Props

type NativeAttributes = Omit<ButtonHTMLAttributes<any>, keyof Props>
export type FabItemProps = item & NativeAttributes

export const FabItem: FC<PropsWithChildren<FabItemProps>> = ({
  children,
  disabled = false,
  isDestructive = false,
  label,
  onClick,
}) => {
  const handleOnClick = () => {
    onClick && onClick()
  }
  const getTitle: string = useMemo(() => {
    if (typeof children === 'string') {
      return children
    }
    return label ?? ''
  }, [label, children])

  return (
    <Container
      aria-label={getTitle}
      className={isDestructive ? 'is-destructive' : ''}
      disabled={disabled}
      onClick={handleOnClick}
      title={getTitle}
      type='button'
    >
      {children}
    </Container>
  )
}

const Container = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;

  display: inline-flex;
  font-family: inherit;
  font-size: 14px;
  justify-content: flex-start;
  padding-left: 15px;
  padding-right: 15px;
  min-height: 40px;
  width: 100%;
  transition: background-color 180ms ease;
  &:hover:not(:disabled) {
    background-color: ${Colors.CATSKILL_WHITE};
  }
  &:disabled {
    background-color: none;
    cursor: default;
  }

  &.is-destructive {
    color: ${Colors.MONZA};
  }
`

export default FabItem
