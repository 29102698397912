import { useCallback, useState } from 'react'
import styled, { css } from 'styled-components'

import { Button, TextInput } from '..'

interface RenameInputProps {
  cancelButtonText?: string
  onCancel: () => void
  onSubmit: (name: string) => void
  placeholder?: string
  saveButtonText?: string
  value: string
}

/**
 * RenameInput
 *
 * a component that replaces text with an input field.
 * on enter keypress, the onChange event is triggered.
 * on esc keypress, the onCancel event is triggered.
 *
 */
export default function RenameInput({
  cancelButtonText = 'Cancel',
  onCancel,
  onSubmit,
  placeholder = 'Rename',
  saveButtonText = 'Save',
  value: initialValue,
}: RenameInputProps) {
  const [value, setValue] = useState(initialValue)

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }, [])

  const handleOnSubmit = useCallback(() => {
    onSubmit(value)
  }, [onSubmit, value])

  const handleOnCancel = useCallback(() => {
    onCancel()
  }, [onCancel])

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onSubmit(value)
      } else if (e.key === 'Escape') {
        onCancel()
      }
    },
    [onSubmit, onCancel, value]
  )

  return (
    <Container>
      <TextInput
        containerStyle={{ marginRight: 6, minHeight: 1 }}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        value={value}
      />

      <SaveButton collapse onClick={handleOnSubmit}>
        {saveButtonText}
      </SaveButton>
      <CancelButton appearance='minimal' collapse onClick={handleOnCancel}>
        {cancelButtonText}
      </CancelButton>
    </Container>
  )
}
const Container = styled.div`
  align-items: center;
  display: inline-flex;
  gap: 6px;
`
const ButtonStyles = css`
  && {
    min-width: 70px;
    padding: 4px 8px;
  }
`
const CancelButton = styled(Button)`
  ${ButtonStyles};
`
const SaveButton = styled(Button)`
  ${ButtonStyles};
`
