import * as React from 'react'
import type { SVGProps } from 'react'
const Play = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 28 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M27.45 15L1.67.15a1.1 1.1 0 0 0-1.11 0 1.11 1.11 0 0 0-.56 1v29.73a1.11 1.11 0 0 0 .56 1 1.1 1.1 0 0 0 1.11 0L27.45 17a1.12 1.12 0 0 0 0-1.94z' />
  </svg>
)
export default Play
