import { FC, ProgressHTMLAttributes } from 'react'

import { Colors } from '@sportsyou/core'

import ProgressBar from './progress-bar'
import ProgressCircle from './progress-circle'

type ProgressTypes = 'bar' | 'circle'

interface Props {
  circleSize?: number
  className?: string
  /** Color of progress bar */
  fill?: string
  indeterminate?: boolean
  labelClassName?: string
  labelColor?: string
  labelStyle?: React.CSSProperties
  /** Max amount progress can be */
  max?: number
  /** thickness of bar or stroke of circle in px */
  thickness?: number
  /** Displays current value in center of bar */
  showLabel?: boolean
  style?: React.CSSProperties
  testId?: string
  trackColor?: string
  /** Current value of progress */
  value?: number
  variant?: ProgressTypes
}

type NativeAttributes = Omit<ProgressHTMLAttributes<HTMLElement>, keyof Props>
export type ProgressProps = Props & NativeAttributes

export const Progress: FC<ProgressProps> = ({
  circleSize = 100,
  className = '',
  // colorized,
  fill = Colors.HAVELOCK_BLUE,
  indeterminate = false,
  labelClassName,
  labelColor,
  labelStyle,
  showLabel = false,
  style,
  testId,
  thickness = 10,
  trackColor = Colors.ALTO,
  value = 0,
  variant = 'bar',
  max = 100,
  ...props
}) => {
  return (
    <>
      {variant === 'circle' && (
        <ProgressCircle
          className={className}
          fill={fill}
          labelClassName={labelClassName}
          labelColor={labelColor}
          labelStyle={labelStyle}
          max={max}
          showLabel={showLabel}
          size={circleSize}
          style={style}
          testId={testId}
          thickness={thickness}
          trackColor={Colors.ALTO}
          value={value}
          {...props}
        />
      )}
      {variant === 'bar' && (
        <ProgressBar
          className={className}
          fill={fill}
          indeterminate={indeterminate}
          labelClassName={labelClassName}
          labelColor={labelColor}
          labelStyle={labelStyle}
          max={max}
          showLabel={showLabel}
          style={style}
          testId={testId}
          trackColor={trackColor}
          thickness={thickness}
          value={value}
          {...props}
        />
      )}
    </>
  )
}

export default Progress
