import { useRef } from 'react'

import SparkMD5 from 'spark-md5'
import { v4 as uuidv4 } from 'uuid'

import { isGif, sleep } from '@sportsyou/core'
import {
  MutationUploadCreateRequest,
  MutationUploadPartCompleteRequest,
  mutationUploadCreate,
  mutationUploadDelete,
  mutationUploadPartComplete,
  mutationVideoClipDelete,
  mutationVideoClipUpdate,
  queryUploadUrl,
} from '@sportsyou/api'

import { Observable } from '@sportsyou/services/utils'
import {
  SubscriptionClient as subscriptions,
  SubscriptionUploadTranscodingStatusInfoResponse,
  subscriptionUploadTranscodingStatusInfo,
  TranscodeStatus,
} from '@sportsyou/subscription'

import {
  ExtendedFile,
  ExtendedImage,
  ExtendedUpload,
  ProgressProps,
  UploaderProps,
} from '../definitions'
import { UPLOAD_DEFAULTS } from '../constants'
import { useFetchApi } from '../use-fetch-api'

export interface CurrentProgressProps {
  uploadId?: string | null
  partNumber?: number
  xhr?: XMLHttpRequest | null
}

type UploadHandlerMap = Record<string, ExtendedUpload>

/**
 * returns uuids joined by joinCharacter
 * @param numberUUIDs number of uuids to create
 * @param joinCharacter chacter to join uuids created
 * @returns
 */
export function generateUUID(
  numberUUIDs: number = 1,
  joinCharacter: string = '-'
) {
  return Array.from({ length: numberUUIDs }, () => uuidv4()).join(joinCharacter)
}

export function useUploader() {
  const { fetch: completeChunkUpload } = useFetchApi(mutationUploadPartComplete)
  const { fetch: createUpload } = useFetchApi(mutationUploadCreate)
  const { fetch: deleteUpload } = useFetchApi(mutationUploadDelete)
  const { fetch: deleteVideoClip } = useFetchApi(mutationVideoClipDelete)
  const { fetch: getUploadUrl } = useFetchApi(queryUploadUrl)
  const { fetch: updateClip } = useFetchApi(mutationVideoClipUpdate)

  const uploadHandler = useRef<UploadHandlerMap>({})
  const currentUpload = useRef<CurrentProgressProps>({
    uploadId: null,
    partNumber: -1,
    xhr: null,
  })
  const allMerged = useRef(false)
  // const isPaused = useRef(false)

  let randSeq = 0

  function randomInRange(min: number, max: number): number {
    return Math.round(Math.random() * (max - min) + min)
  }

  // function randomInRangeDecimal(min: number, max: number, digits: number): number {
  //   return parseFloat((Math.random() * (max - min) + min).toFixed(digits))
  // }

  function getSid(): number {
    return randomInRange(1, 10)
  }

  // function getFakeProgress(): number {
  //   return randomInRangeDecimal(0, 1, 2)
  // }

  function isUploadVideo(upload: ExtendedUpload): boolean {
    const canCheckFileExtension =
      !!upload.fileName && UPLOAD_DEFAULTS.acceptedVideoTypes.length > 1
    return (
      /^video/.test(upload.contentType ?? '') ||
      (canCheckFileExtension &&
        UPLOAD_DEFAULTS.acceptedVideoTypes
          .slice(1)
          .includes(`.${upload.fileName?.split('.').pop()}`))
    )
  }

  // function isUploadAudio(upload: ExtendedUpload): boolean {
  //   return /^audio/.test(upload.contentType)
  // }

  function getUploadHandlerAsArray(): ExtendedUpload[] {
    return Object.entries(uploadHandler.current).flatMap(([key, up]) => {
      if (key.startsWith('up-')) {
        return [up]
      } else {
        return []
      }
    })
  }

  function createRandomFilename(contentType: string, extension = ''): string {
    const randomName = generateUUID(2)
    if (!extension) {
      switch (contentType) {
        case 'audio/mpeg':
          extension = 'mp3'
          break
        case 'image/jpeg':
          extension = 'jpg'
          break
        case 'image/png':
          extension = 'png'
          break
        default:
      }
    }

    return `${randomName}${extension && `.${extension}`}`.toLowerCase()
  }

  function generateFilename(contentType: string, extension = ''): string {
    let newFileName = ''
    const uploads = getUploadHandlerAsArray()
    if (uploads.length) {
      const fileNames = uploads.map((up) => {
        const nameChunks = up.fileName?.split('.') ?? []
        const nameChunksExtensionRemoved = nameChunks.slice(
          0,
          nameChunks.length - 1
        )
        return nameChunksExtensionRemoved.join('.')
      })
      newFileName = `${fileNames.join(', ')}.${extension}`
    } else {
      newFileName = createRandomFilename(contentType, extension)
    }
    return newFileName
  }

  async function create(props: UploaderProps): Promise<ExtendedUpload[]> {
    if (!props.files?.length) {
      throw new Error('You have to supply at least 1 file')
    }

    return processFiles(props)
  }

  async function abort(uploadId: string): Promise<void> {
    const upload = uploadHandler.current[uploadId]
    if (upload) {
      const { progress } = upload
      if (!progress?.isCancelled) {
        upload.progress = {
          ...progress,
          isCancelled: true,
        }
        await deleteAbortedUpload(upload)
      }
      const uploads = getUploadHandlerAsArray()
      upload?.uploaderProps?.onCancel?.(uploads, upload)
    } else {
      console.error(`uploadId "${uploadId}" not found in the uploader!`)
      deleteUpload({ id: uploadId }).catch((err) => console.error({ err }))
    }
  }

  // TODO: finish implementation
  // function pause() {
  //   isPaused.current = true
  // }

  // TODO: finish implementation
  // function resume() {
  //   isPaused.current = false
  // }

  async function prepareUploads() {
    const uploads = getUploadHandlerAsArray()

    for (const entry of Object.entries(uploadHandler.current)) {
      const [key, upload] = entry
      if (
        key.startsWith('up-') &&
        upload.response &&
        !upload.progress?.isCancelled
      ) {
        if (uploads?.[0].uploaderProps?.enableChunk) {
          await prepareUpload(upload).catch(() => {
            //@ts-ignore
            uploadHandler.current[upload.file.name].progress.isError = true
            //@ts-ignore
            uploadHandler.current[upload.id].progress.isError = true
          })
        } else {
          prepareUpload(upload).catch(() => {
            //@ts-ignore
            uploadHandler.current[upload.file.name].progress.isError = true
            //@ts-ignore
            uploadHandler.current[upload.id].progress.isError = true
          })
        }
      }
    }
  }

  async function processFiles(props: UploaderProps): Promise<ExtendedUpload[]> {
    // chunk is enabled by default
    props.enableChunk ??= true

    // turn off mergeUplaods flag if only one file is added
    if (props.mergeUploads && props.files.length === 1) {
      props.mergeUploads = false
    }

    if (props.mergeUploads) {
      // make sure all files are video files
      const areAllFilesVideo = props.files.every((file) =>
        isUploadVideo({ contentType: file.type, fileName: file.name })
      )
      if (!areAllFilesVideo) {
        throw new Error('You can only merge video files!')
      }

      if (props.files.length > UPLOAD_DEFAULTS.maxVideosToMerge) {
        throw new Error(
          `You can only merge up to ${UPLOAD_DEFAULTS.maxVideosToMerge} videos!`
        )
      }

      // make sure all files belong to same team
      const firstTeamId = props.files?.[0].teamId
      const areAllFilesSameTeam = props.files.every(
        (file) => file.teamId === firstTeamId
      )
      if (!areAllFilesSameTeam) {
        throw new Error('All videos should belong to same team!')
      }
    }

    for (const file of props.files) {
      const upload = await createUploadInfo(file, props)
      uploadHandler.current[file.name] = {
        ...upload,
        uploaderProps: props,
      }
      if (upload.response && upload.id) {
        uploadHandler.current[upload.id] = uploadHandler.current[file.name]
        const uploadFromHandler = uploadHandler.current[upload.id]
        if (
          isUploadVideo({ contentType: file.type, fileName: file.name }) &&
          !props.mergeUploads &&
          !file.teamId
        ) {
          setClipId(uploadFromHandler)
        }
      }
    }
    prepareUploads()
    return getUploadHandlerAsArray()
  }

  function guessMimeTypeFromExtension(fileName: string): string {
    const extension = fileName.split('.').pop()
    let mimeType = 'application/octet-stream'

    switch (extension) {
      // image file types
      case 'hiec':
        mimeType = 'image/heic'
        break
      case 'hief':
        mimeType = 'image/heif'
        break

      // video file types
      case 'avi':
        mimeType = 'video/avi'
        break
      case 'mjpeg':
        mimeType = 'video/x-jpeg'
        break
      case 'mkv':
        mimeType = 'video/x-matroska'
        break
      case 'mpeg':
      case 'mpg':
        mimeType = 'video/mpeg'
        break
      case 'mts':
        mimeType = 'video/MP2T'
        break
      case 'vob':
        mimeType = 'video/x-ms-vob'
        break
      case 'webm':
        mimeType = 'video/webm'
        break
      case 'wmv':
        mimeType = '"video/x-ms-wmv"'
        break

      // default for all unknown types
      default:
        mimeType = 'application/octet-stream'
    }

    return mimeType
  }

  async function createUploadInfo(
    file: ExtendedFile,
    props: UploaderProps
  ): Promise<ExtendedUpload> {
    const { size: sizeLong, teamId } = file
    let { name: fileName, type: contentType } = file
    if (!fileName) {
      // if the upload is from Blob, not File
      fileName = createRandomFilename(file.type)
    }
    if (!contentType || contentType === 'application/octet-stream') {
      contentType = guessMimeTypeFromExtension(fileName)
    }
    const isVideo = isUploadVideo({ contentType, fileName })
    const isImage = /^image/i.test(contentType)

    const transcodeSizes = props.transcodings ?? []

    randSeq += getSid()
    const createParams: MutationUploadCreateRequest = {
      contentType,
      fileName,
      showOnMedia: props.uploadType
        ? !UPLOAD_DEFAULTS.uploadTypesToShowOnMedia.includes(props.uploadType)
        : true,
      sid: randSeq,
      sizeLong: sizeLong.toString(),
      skipTranscoding: !!props.mergeUploads,
      sponsorInfo: props.sponsorInfo,
      transcodeSizes,
      uploadType: props.uploadType,
    }

    if (props.enableChunk) {
      createParams.maxChunkSize = props.chunkSize
        ? Math.max(props.chunkSize, UPLOAD_DEFAULTS.minChunkSize)
        : UPLOAD_DEFAULTS.chunkSize
      createParams.chunkUpload = sizeLong > createParams.maxChunkSize
    }

    if (teamId) {
      createParams.teamId = teamId
      if (!createParams.maxChunkSize) {
        createParams.maxChunkSize = UPLOAD_DEFAULTS.chunkSize
        createParams.chunkUpload = sizeLong > createParams.maxChunkSize
      }
    }

    if (isVideo && !props.mergeUploads) {
      if (props.shouldCreateHLS || (props.shouldCreateHLS == null && teamId)) {
        createParams.transcodeSizes = UPLOAD_DEFAULTS.hlsTranscodings
      } else {
        createParams.transcodeSizes = UPLOAD_DEFAULTS.mp4Transcodings
      }
    } else if (createParams.uploadType === 'campaign') {
      createParams.transcodeSizes = UPLOAD_DEFAULTS.campaignAssetTranscodings
      createParams.showOnMedia = false
    } else if (
      createParams.uploadType === 'coverImage' ||
      createParams.transcodeSizes?.includes('coverImage')
    ) {
      createParams.uploadType = 'profilePicture'
      createParams.transcodeSizes = UPLOAD_DEFAULTS.coverImageTranscodings
    } else if (
      createParams.uploadType === 'profileImage' ||
      createParams.transcodeSizes?.includes('profileImage')
    ) {
      createParams.uploadType = 'profilePicture'
      createParams.transcodeSizes = UPLOAD_DEFAULTS.profileImageTranscodings
    } else if (isImage && !createParams.transcodeSizes?.length) {
      createParams.transcodeSizes = isGif(contentType)
        ? UPLOAD_DEFAULTS.gifTranscodings
        : UPLOAD_DEFAULTS.imageTranscodings
    }

    const { ok, data: createUploadData } = await createUpload(createParams)
    if (ok) {
      const { id, viewUrl } = createUploadData as ExtendedImage
      return {
        contentType,
        id,
        file,
        fileName,
        localId: file.localId,
        progress: {
          loaded: 0,
          retryCount: 0,
          total: file.size,
        },
        request: createParams,
        response: createUploadData as ExtendedImage,
        viewUrl,
      }
    } else {
      return {
        contentType,
        file,
        fileName,
        localId: file.localId,
        progress: {
          isError: true,
        },
        request: createParams,
      }
    }
  }

  async function setClipId(upload: ExtendedUpload): Promise<void> {
    const { id: uploadId } = upload
    const transcodeType = 'HLS'
    const getUploadUrlParams = {
      transcodeType,
      uploadId,
    }
    const delay = 250
    const maxTries = 5
    let numTries = 0
    let clipId: string = ''

    while (uploadId && !clipId?.length && numTries < maxTries) {
      numTries++
      await sleep(delay)
      const res = await getUploadUrl(getUploadUrlParams)
      clipId = res.data?.clipId as string
    }

    if (clipId) {
      upload.clipId = clipId
      try {
        // if creating a playlist of videos, mark clips to not show on video library
        const shouldRemoveTheClipFromTheLibrary =
          !upload.uploaderProps?.mergeUploads &&
          (upload.uploaderProps?.files?.length ?? 0) > 1
        if (shouldRemoveTheClipFromTheLibrary) {
          updateClip({ clip: { id: clipId, showOnLibrary: false } })
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  async function prepareUpload(upload: ExtendedUpload): Promise<void> {
    if (!upload.progress?.isCancelled) {
      if (upload.request?.chunkUpload && upload.response) {
        const { chunkUploads } = upload.response
        chunkUploads?.forEach((chunk: any) => {
          chunk.chunkStart = (chunk.partNumber - 1) * UPLOAD_DEFAULTS.chunkSize
          chunk.chunkEnd = Math.min(
            chunk.partNumber * UPLOAD_DEFAULTS.chunkSize,
            upload.file?.size ?? 0
          )
          chunk.progress = {}
        })
        // upload.progress.sendStartTime = new Date().getTime()
        return uploadPart(upload, 1)
      } else {
        // upload.progress.sendStartTime = new Date().getTime()
        return uploadWhole(upload)
      }
    }
  }

  async function uploadPart(
    upload: ExtendedUpload,
    partNumber: number
  ): Promise<void> {
    if (!upload.progress) return

    const currentChunkInfo =
      (upload.response?.chunkUploads ?? [])
        .filter((chunk) => chunk.partNumber === partNumber)
        .shift() ?? {}
    upload.progress.currentChunkInProgress = partNumber

    if (!currentChunkInfo || upload.progress.isCancelled) return

    const blob =
      upload.file?.slice(
        currentChunkInfo.chunkStart,
        currentChunkInfo.chunkEnd,
        upload?.contentType as string
      ) ?? new Blob()
    if (currentChunkInfo.progress) {
      currentChunkInfo.progress.total = blob.size
      currentChunkInfo.progress.loaded = 0
    }

    if (
      currentChunkInfo.progress &&
      currentChunkInfo.progress?.retryCount == null
    ) {
      currentChunkInfo.progress.retryCount = 0
    }

    if (currentChunkInfo.signedUrl) {
      await sendToUrl(
        upload,
        blob as File,
        partNumber,
        currentChunkInfo.signedUrl
      )
    }
    if (partNumber < (upload?.response?.chunkUploads?.length ?? 0)) {
      return uploadPart(upload, ++partNumber)
    } else {
      upload.progress.currentChunkInProgress = -1
      return await completeMultipartUpload(upload)
    }
  }

  async function uploadWhole(upload: ExtendedUpload): Promise<void> {
    if (upload.progress) {
      upload.progress.total = upload.file?.size
      upload.progress.loaded = 0
      upload.progress.currentChunkInProgress = 0
      await sendToUrl(
        upload,
        upload?.file as File,
        0,
        upload?.response?.signedUrl as string
      )
      upload.progress.currentChunkInProgress = -1
      return await completeMultipartUpload(upload)
    }
  }

  async function sendToUrl(
    upload: ExtendedUpload,
    blob: File,
    partNumber: number,
    url: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest()
      currentUpload.current = {
        uploadId: upload.id as string,
        partNumber,
        xhr: request,
      }

      request.onreadystatechange = async function () {
        // readyState: 4 => Done
        if (this.readyState === 4) {
          // upload failure - retry
          if (this.status !== 200 && !upload?.progress?.isCancelled) {
            onProgress(upload, partNumber)
            try {
              await onUploadError(upload, request, partNumber)
              resolve()
            } catch (err) {
              reject(err)
            }
          }

          // upload success
          onProgress(upload, partNumber)
          onChunkDone(upload, blob, partNumber)
          resolve()
        }
      }

      request.onabort = async function (event: ProgressEvent) {
        // console.log('onabort', { event })
      }
      request.onerror = function (event: ProgressEvent) {
        if (upload.progress?.isCancelled) {
          request.abort()
          return
        }
        reject(event)
      }
      request.onloadstart = function (event: ProgressEvent) {
        if (partNumber < 2 && upload.progress) {
          upload.progress.sendStartTime = new Date().getTime()
        }
        const uploads = getUploadHandlerAsArray()
        upload.uploaderProps?.onUploadStart?.(uploads, upload)
      }
      request.onload = function (event: ProgressEvent) {
        // console.log('onload', { event })
      }
      request.onloadend = function (event: ProgressEvent) {
        // console.log('onloadend', { event })
        // if (partNumber === 0 || partNumber === upload.response.chunkUploads.length) {
        //   upload.progress.sendEndTime = new Date().getTime()
        // }
      }
      request.ontimeout = function (event: ProgressEvent) {
        // console.log('onaontimeoutbort', { event })
      }

      request.upload.onprogress = function (event: ProgressEvent) {
        // console.log({ event })
        // console.log('onprogress')
        if (upload.progress?.isCancelled) {
          request.abort()
          return
        }
        if (event.lengthComputable) {
          const progress = upload?.request?.chunkUpload
            ? (upload?.response?.chunkUploads ?? [])
                .filter((chunk) => chunk.partNumber === partNumber)
                .shift()?.progress ?? {}
            : (upload.progress as ProgressProps) ?? {}
          progress.loaded = event.loaded
          if (progress.lastUploadedTime) {
            const timeDiff =
              (new Date().getTime() - progress.lastUploadedTime) / 1000
            if (timeDiff > 0.005) {
              // at least 5ms passed
              // if (timeDiff > 0.1) {
              // at least 0.1s passed
              const byterate =
                (progress.loaded - (progress?.lastUploadedSize ?? 0)) / timeDiff
              progress.byterate = byterate
              progress.lastUploadedTime = new Date().getTime()
              progress.lastUploadedSize = progress.loaded
            }
          } else {
            progress.byterate = 0
            progress.lastUploadedTime = new Date().getTime()
            progress.lastUploadedSize = progress.loaded
          }
          if (
            (event.loaded === progress.total && partNumber === 0) ||
            partNumber === upload.response?.chunkUploads?.length
          ) {
            if (upload.progress?.sendEndTime) {
              upload.progress.sendEndTime = new Date().getTime()
            }
          }
          onProgress(upload, partNumber)
        }
      }

      request.open('PUT', url, true)
      console.log({
        fileName: upload.fileName,
        blob_type: upload.file?.type,
        req_type: upload.request?.contentType,
        res_type: upload.response?.contentType,
        partNumber,
      })
      if (partNumber > 0) {
        request.setRequestHeader('Content-Type', '')
      } else {
        // ;('')
        request.setRequestHeader(
          'Content-Type',
          upload.response?.contentType ?? ''
        )
      }
      request.send(blob)
    })
  }

  function onProgress(upload: ExtendedUpload, partNumber: number): void {
    if (!upload.progress) return
    let uploadProgress = 0
    let clientProgress = 0
    let transcodingProgress = 0
    if (upload.request?.chunkUpload && partNumber !== 0) {
      const fileProgress = upload.progress
      const chunkProgress =
        (upload?.response?.chunkUploads ?? [])
          .filter((chunk) => chunk.partNumber === partNumber)
          .shift()?.progress ?? {}
      const { loaded: fileLoaded } = fileProgress
      const { loaded: chunkLoaded } = chunkProgress
      const total = upload.file?.size ?? 0
      const loaded = (fileLoaded ?? 0) + (chunkLoaded ?? 0)
      uploadProgress = Math.round((loaded / total) * 100 * 10) / 10 // round to 1 decimal point
      clientProgress = uploadProgress / 2
      upload.progress.loadProgress = loaded
      upload.progress.uploadProgress = uploadProgress
      upload.progress.clientProgress = clientProgress
      upload.progress.transcodingProgress = transcodingProgress
    } else {
      const { total, loaded } = upload.progress
      uploadProgress =
        Math.round(((loaded ?? 0) / (total ?? 1)) * 100 * 10) / 10 // round to 1 decimal point
      clientProgress = uploadProgress / 2
      upload.progress.loadProgress = loaded
      upload.progress.uploadProgress = uploadProgress
      upload.progress.clientProgress = clientProgress
      upload.progress.transcodingProgress = transcodingProgress
    }
    uploadProgress = upload.progress.uploadProgress ?? 0

    if (uploadProgress === 100) {
      transcodingProgress = upload.progress.transcodingPercentDone ?? 0
      clientProgress = (uploadProgress + transcodingProgress) / 2
      upload.progress.transcodingProgress = transcodingProgress
      upload.progress.clientProgress = clientProgress
    }
    const uploads = getUploadHandlerAsArray()
    const _upload = uploads.filter((up) => up.id === upload.id).shift()
    _upload && upload.uploaderProps?.onProgress?.(uploads, _upload)
    _upload?.progress?.uploadProgress === 100 &&
      console.log('onProgress', _upload.progress)
  }

  async function onChunkDone(
    upload: ExtendedUpload,
    blob: Blob,
    partNumber: number
  ): Promise<void> {
    if (upload.request?.chunkUpload) {
      const fileProgress = upload.progress ?? {}
      const chunkInfo =
        (upload.response?.chunkUploads ?? [])
          .filter((chunk) => chunk.partNumber === partNumber)
          .shift() ?? {}
      const chunkProgress = chunkInfo?.progress ?? {}
      const { loaded: fileLoaded } = fileProgress
      const { loaded: chunkLoaded } = chunkProgress
      const loaded = (fileLoaded ?? 0) + (chunkLoaded ?? 0)
      fileProgress.loaded = loaded
      try {
        chunkInfo.eTagPromise = setChecksum(upload, blob, partNumber)
        await chunkInfo.eTagPromise
      } catch (err) {
        console.log({ err })
      }
    }
  }

  async function onUploadError(
    upload: ExtendedUpload,
    request: XMLHttpRequest,
    partNumber: number
  ): Promise<void> {
    console.log({ upload, request, partNumber })
    if (upload.request?.chunkUpload) {
      const chunkInfo = (upload.response?.chunkUploads ?? [])
        .filter((chunk) => chunk.partNumber === partNumber)
        .shift()
      if (
        chunkInfo?.progress &&
        (chunkInfo.progress.retryCount ?? 0) < UPLOAD_DEFAULTS.maxRetry
      ) {
        chunkInfo.progress = {
          retryCount: (chunkInfo.progress?.retryCount ?? 0) + 1,
        }
        await sleep(randomInRange(500, 2000)) // wait before retry
        return uploadPart(upload, partNumber)
      } else {
        throw new Error(`File upload failed: [${upload.id}]`)
      }
    } else {
      if ((upload.progress?.retryCount ?? 0) < UPLOAD_DEFAULTS.maxRetry) {
        upload.progress = { retryCount: (upload.progress?.retryCount ?? 0) + 1 }
        await sleep(randomInRange(500, 2000)) // wait before retry
        return uploadWhole(upload)
      } else {
        throw new Error(`File upload failed: [${upload.id}]`)
      }
    }
  }

  async function completeMultipartUpload(
    upload: ExtendedUpload
  ): Promise<void> {
    if (upload.request?.chunkUpload) {
      const { id } = upload
      const completeChunkUploadParams: MutationUploadPartCompleteRequest = {
        id: id as string,
        parts: [],
      }
      try {
        if (upload?.response?.chunkUploads) {
          await Promise.all(
            upload?.response?.chunkUploads?.map?.((chunk) => chunk?.eTagPromise)
          )
        }
        if (UPLOAD_DEFAULTS.calculateClientSideChecksum) {
          completeChunkUploadParams.parts = upload?.response?.chunkUploads?.map(
            (chunk) => ({
              eTag: chunk.eTag,
              partNumber: chunk.partNumber,
            })
          )
        }
        const res = await completeChunkUpload(completeChunkUploadParams)
        if (res.ok) {
          const { data: _completeChunkUploadData } = res
          const completeChunkUploadData = JSON.parse(
            _completeChunkUploadData as string
          )
          console.log({ completeChunkUploadData })
        }
      } catch (err) {
        console.log({ err })
      }
    }
    const uploads = getUploadHandlerAsArray()
    const _upload = uploads
      .filter((up) => up.id === upload.id)
      .shift() as ExtendedUpload
    if (upload.uploaderProps?.skipWaitForTranscoding) {
      if (upload.uploaderProps?.mergeUploads) {
        await mergeVideosOnAllUploadComplete()
      } else {
        upload.uploaderProps?.onUploadDone?.(uploads, _upload)
        checkAllComplete() &&
          upload.uploaderProps?.onComplete?.(uploads, _upload)
      }
    } else {
      waitForTranscodingComplete(upload).then(async (status) => {
        if (upload.uploaderProps?.mergeUploads) {
          await mergeVideosOnAllUploadComplete()
        } else {
          const _uploads = getUploadHandlerAsArray()
          const __upload = uploads
            .filter((up) => up.id === upload.id)
            .shift() as ExtendedUpload
          if (__upload.progress?.isCancelled) {
            // noop as onCancel should have been called already when the reqeust was made
          } else if (__upload.progress?.isError || status.transcodingsFailed) {
            upload.uploaderProps?.onError?.(_uploads, __upload)
          } else {
            upload.uploaderProps?.onUploadDone?.(_uploads, __upload)
          }
          if (checkAllComplete()) {
            await sleep(250)
            upload.uploaderProps?.onComplete?.(_uploads, __upload)
          }
        }
      })
    }
  }

  async function waitForTranscodingComplete(
    upload: ExtendedUpload
  ): Promise<TranscodeStatus> {
    const uploadId = upload.id
    const progress = upload.progress ?? {}
    progress.transcodingPercentDone = 0

    await new Promise((resolve) => {
      async function checkConnectedAndStart() {
        if (subscriptions.isConnected()) {
          await sleep(16)
          resolve(null)
        } else {
          subscriptions.connect(undefined, async () => {
            await sleep(33)
            checkConnectedAndStart()
          })
          setTimeout(checkConnectedAndStart, 5_000)
        }
      }
      checkConnectedAndStart()
    })

    return new Promise(async (resolve, reject) => {
      const res = await subscriptionUploadTranscodingStatusInfo({
        id: uploadId,
      })
      const data =
        res.data as Observable<SubscriptionUploadTranscodingStatusInfoResponse>
      console.log('subscribe to transcoding status', upload.fileName, res.data)

      res.error.addListener((err) => {
        subscriptions.unsubscribe(err.watchId)
      })

      data.addListener(async (subValue: any) => {
        const { progress } =
          getUploadHandlerAsArray()
            .filter((up) => up.id === upload.id)
            .shift() ?? {}

        if (progress) {
          if (progress.isCancelled || progress.isError) {
            if (subValue.watchId) {
              subscriptions.unsubscribe(subValue.watchId)
            }
            resolve(subValue.data)
          }
          const percentDone = subValue.data?.percentDone ?? 0
          const transcodingsDone = (subValue.data?.transcodingsDone ??
            []) as string[]
          if (subValue.watchId && progress.transcodingWatchId == null) {
            progress.transcodingWatchId = subValue.watchId
          }

          if (percentDone !== progress.transcodingPercentDone) {
            progress.transcodingPercentDone = percentDone
            progress.transcodingsDone = transcodingsDone
            if (subValue.data?.transcodingsFailed) {
              progress.transcodingsFailed = subValue.data.transcodingsFailed
              progress.isError = !!subValue.data.transcodingsFailed
            }
            onProgress(upload, 0)

            if (percentDone === 100) {
              if (subValue.watchId) {
                subscriptions.unsubscribe(subValue.watchId)
              }
              resolve(subValue.data)
            }
          }
        }
      })
    })
  }

  function calculateChecksumViaSparkMD5(blob: Blob): Promise<string> {
    const sparkArrayBuffer = new SparkMD5.ArrayBuffer()
    const fileReader = new FileReader()

    return new Promise((resolve, reject) => {
      fileReader.onload = function (event: ProgressEvent<FileReader>) {
        sparkArrayBuffer.append(event?.target?.result as ArrayBuffer)
        const md5hash = sparkArrayBuffer.end()
        resolve(md5hash)
      }
      fileReader.readAsArrayBuffer(blob)
    })
  }

  function calculateChecksum(blob: Blob): Promise<string> {
    return calculateChecksumViaSparkMD5(blob)
  }

  async function setChecksum(
    upload: ExtendedUpload,
    blob: Blob,
    partNumber: number
  ): Promise<void> {
    if (upload.request?.chunkUpload) {
      const uploadChunk = (upload?.response?.chunkUploads ?? [])
        .filter((chunk) => chunk.partNumber === partNumber)
        .shift()
      if (uploadChunk) {
        if (uploadChunk.partNumber !== upload.response?.chunkUploads?.length) {
          await sleep(500)
        }
        const eTag = await calculateChecksum(blob)
        uploadChunk.eTag = eTag
        // console.log({ eTag })
      }
    }
  }

  function checkAllUploadsComplete(): boolean {
    const uploads = getUploadHandlerAsArray()
    return uploads.every(
      (up) =>
        !!(
          up.progress?.isError ||
          up.progress?.isCancelled ||
          (up.uploaderProps?.skipWaitForTranscoding &&
            up.progress?.uploadProgress === 100) ||
          (!up.uploaderProps?.skipWaitForTranscoding &&
            up.progress?.clientProgress === 100)
        )
    )
  }

  function checkAllComplete(): boolean {
    const uploads = getUploadHandlerAsArray()
    const firstUpload = uploads.slice(0, 1).shift()
    const uploadCompleted = checkAllUploadsComplete()
    const allCompleted =
      uploadCompleted &&
      (firstUpload?.uploaderProps?.mergeUploads ? allMerged.current : true)

    console.log({
      uploadCompleted,
      mergeUploads: firstUpload?.uploaderProps?.mergeUploads,
      allCompleted,
    })
    return allCompleted
  }

  function onReset(): boolean {
    const allCompleted = checkAllComplete()
    if (allCompleted) {
      randSeq = 0
      uploadHandler.current = {}
      allMerged.current = false
    }
    return allCompleted
  }

  async function deleteAbortedUpload(upload: ExtendedUpload): Promise<void> {
    const { clipId, id } = upload
    if (upload.progress?.isCancelled) {
      await Promise.all([
        deleteUpload({ id }).catch((err) => console.log({ err })),
        clipId &&
          deleteVideoClip({ id: clipId }).catch((err) => console.log({ err })),
      ])
    }
  }

  async function mergeVideosOnAllUploadComplete(): Promise<void> {
    if (checkAllUploadsComplete()) {
      const uploads = getUploadHandlerAsArray()
      if (uploads.length) {
        const lastUpload = uploads.slice(-1).shift()
        const fileName =
          lastUpload?.uploaderProps?.mergedUploadFilename ??
          generateFilename('video/mp4', 'mp4')

        const createUploadParams: MutationUploadCreateRequest = {
          contentType: 'video/mp4',
          fileName,
          // showOnMedia: false,
          // sid: randSeq,
          // sizeLong: sizeLong.toString(),
          // teamId: uploads[0].teamId,
          transcodeSizes: ['HLS', 'THUMB'],
          uploadIdsToMerge: uploads.map((up) => up.id) as string[],
          // uploadType: 'media',
        }

        const { ok, data: mergedUpload } = await createUpload(
          createUploadParams
        )

        if (ok) {
          if (lastUpload) {
            await setClipId(mergedUpload as ExtendedUpload)
            lastUpload.mergedUploadId = mergedUpload?.id as string
            lastUpload.mergedUploadClipId = (
              mergedUpload as ExtendedUpload
            ).clipId
            allMerged.current = true
            lastUpload?.uploaderProps?.onUploadDone?.(uploads, lastUpload)
            lastUpload?.uploaderProps?.onComplete?.(uploads, lastUpload)
          }
        } else {
          // TODO: api error handling
        }
      }
    }
  }

  function getUploadsInProgress() {
    return getUploadHandlerAsArray().filter(
      (up) =>
        !(
          up.progress?.isError ||
          up.progress?.isCancelled ||
          (up.uploaderProps?.skipWaitForTranscoding &&
            up.progress?.uploadProgress === 100) ||
          (!up.uploaderProps?.skipWaitForTranscoding &&
            up.progress?.clientProgress === 100)
        )
    )
  }

  return {
    abort,
    cancel: abort,
    checkAllComplete,
    create,
    getUploadsInProgress,
    // pause,
    reset: onReset,
    // resume,
  }
}

export default {
  generateUUID,
  useUploader,
}
