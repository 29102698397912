/**
 * This is very much a work in progress, but I hope this could be used to
 * easily handle global styles and fonts for web apps.
 */
import { FC, PropsWithChildren, useState } from 'react'
import { createGlobalStyle } from 'styled-components'
import { ThemeContext } from '../context/theme-context'
import { getThemeByKey } from '../theme/themes'

import '@sportsyou/fonts'

const GlobalStyles = createGlobalStyle`
:root {
    --font-system-fallback: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

    --font-header: 'Cocogoose Pro', var(--font-system-fallback);
    --font-body: 'Figtree', var(--font-system-fallback);
}

html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

body {
    font-family: var(--font-body);
    font-weight: 400;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}`

interface Props {}

export const ThemeProvider: FC<PropsWithChildren<Props>> = ({ children }) => {
  const [theme, setNewTheme] = useState(getThemeByKey('light'))

  const setTheme = (key: string) => {
    const theme = getThemeByKey(key)
    setNewTheme(theme)
  }

  const value = { theme, setTheme }

  return (
    <ThemeContext.Provider value={value}>
      <>
        <GlobalStyles />
        {children}
      </>
    </ThemeContext.Provider>
  )
}
export default ThemeProvider
