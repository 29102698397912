import React from 'react'
import styled, { css, keyframes } from 'styled-components'

import { Colors } from '@sportsyou/core'
import { useProportions } from '@sportsyou/react-hooks'

export interface ProgressBarProps {
  className?: string
  /** Color of progress bar */
  fill: string
  indeterminate: boolean
  labelClassName?: string
  labelColor?: string
  labelStyle?: React.CSSProperties
  /** Max amount progress can be */
  max: number
  /** Displays current value in center of bar */
  showLabel: boolean
  style?: React.CSSProperties
  testId?: string
  /** thickness of bar or stroke of circle in px */
  thickness: number
  trackColor: string
  /** Current value of progress */
  value: number
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  className,
  // colorized,
  fill,
  indeterminate,
  labelClassName,
  labelColor = Colors.WHITE,
  labelStyle,
  max,
  showLabel,
  style,
  testId,
  thickness,
  trackColor,
  value,
  ...props
}: ProgressBarProps) => {
  const percentValue = useProportions(value, max)

  return (
    <Container
      className={className}
      data-testid={testId}
      role='progressbar'
      style={style}
      thickness={thickness}
      trackColor={trackColor}
    >
      {indeterminate ? (
        <>
          <AnimatedBar1 $fill={fill} />
          <AnimatedBar2 $fill={fill} />
        </>
      ) : (
        <>
          <Bar
            fill={fill}
            style={{ width: `${percentValue}%` }}
            title={'percent value'}
          />
          {showLabel && (
            <Text
              className={labelClassName}
              labelColor={labelColor}
              style={labelStyle}
            >
              {percentValue}%
            </Text>
          )}

          <progress
            className={className}
            value={value}
            max={max}
            style={{
              opacity: 0,
              pointerEvents: 'none',
              position: 'fixed',
              top: '-1000px',
              visibility: 'hidden',
            }}
            {...props}
          />
        </>
      )}
    </Container>
  )
}

const Container = styled.div<{
  trackColor: string
  thickness: number
}>`
  background-color: ${({ trackColor }) => trackColor};
  border-radius: 4px;
  height: ${({ thickness }) => thickness}px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
  z-index: 0;
`
const Bar1Anim = keyframes`
  0% { left: -200%; right: 100%; }
  60% { left: 107%; right: -8%; }
  100% { left: 107%; right: -8%; }
`
const Bar2Anim = keyframes`
  0% { left: -35%; right: 100%; }
  60% { left: 100%; right: -90%; }
  100% { left: 100%; right: -90%; }
`
const CommonBarStyles = css`
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  transform-origin: left;
  transition: transform 0.2s linear;
  width: auto;
`
const AnimatedBar1 = styled.span<{ $fill: string }>`
  animation: ${Bar1Anim} 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite;
  background-color: ${({ $fill }) => $fill};
  ${CommonBarStyles};
`
const AnimatedBar2 = styled.span<{ $fill: string }>`
  animation: ${Bar2Anim} 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  background-color: ${({ $fill }) => $fill};
  ${CommonBarStyles};
`

const Bar = styled.div<{ fill: string }>`
  background-color: ${({ fill }) => fill || Colors.HAVELOCK_BLUE};
  border-radius: 4px;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 100ms ease-in;
`
const Text = styled.span<{ labelColor: string }>`
  color: ${({ labelColor }) => labelColor};
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  position: absolute;
  top: 50%;
  text-align: center;
  text-shadow: 0 0 3px rgb(0 0 0 / 40%);
  transform: translate3d(-50%, -50%, 0);
  z-index: 20;
`

export default ProgressBar
