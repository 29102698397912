import * as React from 'react'
import type { SVGProps } from 'react'
const FabJersey = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M24.44 7.36L21.45 6a1.87 1.87 0 0 0-1.93.22L16 8.88l-3.4-2.6A2.12 2.12 0 0 0 10.41 6L7.56 7.36a3.87 3.87 0 0 0-2.23 3.49v2.91a1.76 1.76 0 0 0 1.75 1.75h2.45v8.54a1.75 1.75 0 0 0 1.75 1.75h9.44a1.75 1.75 0 0 0 1.75-1.75v-8.54h2.45a1.76 1.76 0 0 0 1.75-1.75v-2.91a3.87 3.87 0 0 0-2.23-3.49zm-3.37 13.87H10.93v-2.58h10.14zm-.35 3.17h-9.44a.34.34 0 0 1-.35-.35v-1.43h10.14v1.43a.34.34 0 0 1-.35.35zm4.55-10.64a.35.35 0 0 1-.35.35h-2.45v-1.55a.7.7 0 1 0-1.4 0v4.7H10.93v-4.7a.7.7 0 0 0-1.4 0v1.55H7.08a.35.35 0 0 1-.35-.35v-2.91a2.47 2.47 0 0 1 1.42-2.23L11 7.3a.73.73 0 0 1 .75.09l3.83 2.92a.69.69 0 0 0 .84 0l3.94-3a.49.49 0 0 1 .51 0l3 1.38a2.47 2.47 0 0 1 1.42 2.23z' />
  </svg>
)
export default FabJersey
