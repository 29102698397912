import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { isEqual } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'

import { Event as EventProps, MutationEventUpdateRequest } from '@sportsyou/api'
import { Colors } from '@sportsyou/core'
import { usePrevious } from '@sportsyou/react-hooks'

import Button from '../button'
import Checkbox, { CheckboxEvent } from '../checkbox'
import Icon from '../icon'
import Modal from '../modal'

import type { CalendarMode, DayProps } from './calendar-types'

import { DayCalendar } from './day'
import { MonthCalendar } from './month'
import { WeekCalendar } from './week'
import { YearCalendar } from './year'

type DroppedEvent = {
  eventRequest: MutationEventUpdateRequest
  newEvent: EventProps
  originalEvent: EventProps
}

interface Props {
  className?: string
  events?: EventProps[]
  eventIdToClickOnMount?: string
  fillChipWithGameType?: boolean
  mode?: CalendarMode
  onChange?: (date?: Date) => void
  onClickDay?: (day: DayProps, e: React.MouseEvent<HTMLDivElement>) => void
  onClickEvent?: (
    event: EventProps,
    e: React.MouseEvent<HTMLDivElement>
  ) => void
  onClickMonth?: () => void
  onClickNewEvent?: () => void
  onClickNext?: () => void
  onClickPrevious?: () => void
  onClickPrint?: () => void
  onClickSubscribe?: () => void
  onClickToday?: () => void
  onClickWeek?: () => void
  onClickYear?: () => void
  onDoubleClickDay?: (
    day: DayProps,
    e: React.MouseEvent<HTMLDivElement>
  ) => void
  onDropEvent?: (
    originalEvent: EventProps,
    newEvent: EventProps,
    newEventRequest: MutationEventUpdateRequest
  ) => void
  onModeChange?: (mode: CalendarMode) => void
  navigate?: Function
  selectedDate?: Date
  style?: React.CSSProperties
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type CalendarProps = NativeAttributes & Props

export const Calendar: React.FC<CalendarProps> = ({
  className,
  events: initialEvents = [],
  eventIdToClickOnMount,
  fillChipWithGameType,
  mode: initialMode = 'month',
  onChange,
  onClickDay,
  onClickEvent,
  onClickMonth,
  onClickNewEvent,
  onClickNext,
  onClickPrevious,
  onClickPrint,
  onClickSubscribe,
  onClickToday,
  onClickWeek,
  onClickYear,
  onDoubleClickDay,
  onDropEvent,
  onModeChange,
  navigate,
  selectedDate: selfSelcetedDate,
  style,
}: CalendarProps) => {
  const initialDroppedEvent = {
    originalEvent: {},
    newEvent: {},
    eventRequest: {},
  }

  const [droppedEvent, setDroppedEvent] =
    useState<DroppedEvent>(initialDroppedEvent)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [events, setEvents] = useState<Array<EventProps>>(initialEvents)
  const [mode, setMode] = useState(initialMode)
  const [notifyEventUpdate, setNotifyEventUpdate] = useState<boolean>(true)
  const [selectedDate, setSelectedDate] = useState(() =>
    moment(selfSelcetedDate).format('YYYY-MM-DD')
  )

  const prevMode = usePrevious(mode, undefined)
  const prevSelectedDate = usePrevious(selectedDate, undefined)

  useEffect(() => {
    if (!isEqual(initialEvents, events)) {
      setEvents(initialEvents)
    }
  }, [initialEvents])

  useEffect(() => {
    setSelectedDate(moment(selfSelcetedDate).format('YYYY-MM-DD'))
  }, [selfSelcetedDate])

  useEffect(() => {
    onChange?.(moment(selectedDate).toDate())
  }, [selectedDate])

  useEffect(() => {
    onModeChange?.(mode)
  }, [mode])

  useEffect(() => {
    function setCalendarUrl() {
      const { pathname } = window.location
      const calendarBasePathName = '/calendar'
      const calendarBasePath = pathname.substring(
        0,
        pathname.indexOf(calendarBasePathName) + calendarBasePathName.length
      )
      let formattedDate: string

      onModeChange?.(mode)
      switch (mode) {
        case 'day':
          formattedDate = moment(selectedDate).format('YYYY-MM-DD')
          break
        case 'week':
          formattedDate = moment(selectedDate).format('YYYY-MM-DD')
          break
        case 'year':
          formattedDate = moment(selectedDate).format('YYYY-MM-DD')
          break
        case 'month':
        default:
          formattedDate = moment(selectedDate).format('YYYY-MM-DD')
      }
      navigate?.(`${calendarBasePath}/${mode}/${formattedDate}`)
    }

    if (
      prevMode &&
      prevSelectedDate &&
      (mode != prevMode || selectedDate !== prevSelectedDate)
    ) {
      setCalendarUrl()
    }
  }, [mode, selectedDate])

  const monthCalendarDays = useMemo(() => {
    let days: DayProps[] = []
    const startOfMonth = moment(selectedDate).startOf('month')
    // Add previous month days
    for (let i = 0; i < startOfMonth.day(); i++) {
      days.push({
        date: moment(startOfMonth)
          .subtract(startOfMonth.day(), 'days')
          .add(i, 'days')
          .toDate(),
        day: moment(startOfMonth)
          .subtract(startOfMonth.day(), 'days')
          .add(i, 'days')
          .format('D'),
        type: 'previous',
      })
    }
    // Add current month days
    for (let i = 0; i < startOfMonth.daysInMonth(); i++) {
      days.push({
        date: moment(startOfMonth).add(i, 'days').toDate(),
        day: moment(startOfMonth).add(i, 'days').format('D'),
        type: 'current',
      })
    }
    // Add next month days
    for (let i = 0; i < 60 - days.length; i++) {
      days.push({
        date: moment(startOfMonth).add(1, 'month').add(i, 'days').toDate(),
        day: moment(startOfMonth).add(1, 'month').add(i, 'days').format('D'),
        type: 'next',
      })
    }

    // remove last week if it's empty
    if (days.slice(35).every((day) => day.type === 'next')) {
      days = days.slice(0, 35)
    } else {
      // limit calendar to 6 weeks
      days = days.slice(0, 42)
    }

    // add events
    if (events) {
      days.forEach((day) => {
        day.events =
          events?.filter(
            (event) =>
              moment(event.startDate).format('YYYY-MM-DD') ===
              moment(day.date).format('YYYY-MM-DD')
          ) ?? []
      })
    }
    return days
  }, [selectedDate, events])

  const weekCalendarDays = useMemo(() => {
    const days: DayProps[] = []
    const startOfWeek = moment(selectedDate).startOf('week')
    // Add current month days
    for (let i = 0; i < 7; i++) {
      days.push({
        date: moment(startOfWeek).add(i, 'days').toDate(),
        day: moment(startOfWeek).add(i, 'days').format('D'),
        type: 'current',
      })
    }
    // add events
    if (events) {
      days.forEach((day) => {
        day.events =
          events?.filter(
            (event) =>
              moment(event.startDate).format('YYYY-MM-DD') ===
              moment(day.date).format('YYYY-MM-DD')
          ) ?? []
      })
    }
    return days
  }, [selectedDate, events])

  const handleOnClickPrevious = useCallback(() => {
    setSelectedDate(moment(selectedDate).subtract(1, mode).format('YYYY-MM-DD'))
  }, [selectedDate, mode])

  const handleOnClickNext = useCallback(() => {
    setSelectedDate(moment(selectedDate).add(1, mode).format('YYYY-MM-DD'))
  }, [selectedDate, mode])

  const handleOnClickToday = useCallback(() => {
    onClickToday?.()
    setSelectedDate(moment().format('YYYY-MM-DD'))
  }, [])

  const handleOnClickPrint = useCallback(() => {
    if (onClickPrint) {
      onClickPrint()
    } else {
      window.print()
    }
  }, [])

  const handleOnClickSubscribe = useCallback(() => {
    onClickSubscribe?.()
  }, [])

  const handleSetMode = (mode: CalendarMode) => {
    setMode(mode)
  }

  // const handleOnClickDay = useCallback((day: DayProps, e: any) => {
  //   onClickDay?.(day, e)
  // }, [])

  const handleOnDropEvent = useCallback(
    (
      originalEvent: EventProps,
      newEvent: EventProps,
      eventRequest: MutationEventUpdateRequest
    ) => {
      setDroppedEvent({ originalEvent, newEvent, eventRequest })
      setIsModalVisible(true)
      // props.onDropEvent?.(event, newEvent)
    },
    []
  )

  const handleOnChangeNotify = (e: CheckboxEvent) => {
    const { checked } = e.target
    setNotifyEventUpdate(checked)
  }

  const handleOnCloseModal = () => {
    setIsModalVisible(false)
    setTimeout(() => {
      setDroppedEvent(initialDroppedEvent)
      setNotifyEventUpdate(true)
    }, 600)
  }

  const handleOnSave = () => {
    const _eventRequest: MutationEventUpdateRequest = {
      ...droppedEvent.eventRequest,
      notify: notifyEventUpdate,
    }
    onDropEvent?.(
      droppedEvent.originalEvent,
      droppedEvent.newEvent,
      _eventRequest
    )

    // Update UI
    const _events = [
      ...events.filter((event) => event.id !== droppedEvent.newEvent.id),
      droppedEvent.newEvent,
    ]

    setEvents(_events)

    handleOnCloseModal()
  }

  // const handleOnDoubleClickDay = useCallback((day: DayProps, e: any) => {
  //   onDoubleClickDay?.(day, e)
  // }, [])

  return (
    <>
      <Container className={className} style={style}>
        <Header>
          <LeftColumn>
            {onClickNewEvent ? (
              <CreateEventButton onClick={onClickNewEvent}>
                <Icon name='Plus' height={12} fill={Colors.WHITE} />
                Create Event
              </CreateEventButton>
            ) : null}
            <ButtonSet>
              <ModeButton appearance='ghost' onClick={handleOnClickPrevious}>
                <Icon
                  name='ChevronLeft'
                  fill={Colors.HAVELOCK_BLUE}
                  height={15}
                />
              </ModeButton>
              <ModeButton appearance='ghost' onClick={handleOnClickToday}>
                Today
              </ModeButton>
              <ModeButton appearance='ghost' onClick={handleOnClickNext}>
                <Icon
                  name='ChevronRight'
                  fill={Colors.HAVELOCK_BLUE}
                  height={15}
                />
              </ModeButton>
            </ButtonSet>
            <CurrentMonth>
              {mode === 'day' && moment(selectedDate).format('MMMM, DD YYYY')}
              {mode === 'week' &&
                moment(selectedDate).startOf('week').format('MMM D - ') +
                  moment(selectedDate).endOf('week').format('MMM D, YYYY')}
              {mode === 'month' && moment(selectedDate).format('MMMM YYYY')}
              {mode === 'year' && moment(selectedDate).format('YYYY')}
            </CurrentMonth>
          </LeftColumn>
          <RightColumn>
            {onClickSubscribe ? (
              <SubscribeButton
                appearance='minimal'
                collapse
                onClick={handleOnClickSubscribe}
                title='Subscribe to your sportsYou Calendar'
              >
                <Icon
                  name='CalendarSubscribe'
                  fill={Colors.HAVELOCK_BLUE}
                  height={30}
                />
              </SubscribeButton>
            ) : null}
            <PrintButton
              appearance='minimal'
              collapse
              onClick={handleOnClickPrint}
              title='Print'
            >
              <Icon name='Print' fill={Colors.HAVELOCK_BLUE} height={30} />
            </PrintButton>
            <ButtonSet>
              <ModeButton
                onClick={() => handleSetMode('day')}
                appearance={mode === 'day' ? 'solid' : 'ghost'}
              >
                Day
              </ModeButton>
              <ModeButton
                onClick={() => handleSetMode('week')}
                appearance={mode === 'week' ? 'solid' : 'ghost'}
              >
                Week
              </ModeButton>
              <ModeButton
                onClick={() => handleSetMode('month')}
                appearance={mode === 'month' ? 'solid' : 'ghost'}
              >
                Month
              </ModeButton>
              <ModeButton
                onClick={() => handleSetMode('year')}
                appearance={mode === 'year' ? 'solid' : 'ghost'}
              >
                Year
              </ModeButton>
            </ButtonSet>
          </RightColumn>
        </Header>
        <DndProvider backend={HTML5Backend}>
          <Body>
            {mode === 'year' && (
              <YearCalendar
                events={events}
                fillChipWithGameType={fillChipWithGameType}
                onClickDay={onClickDay}
                onClickEvent={onClickEvent}
                onDoubleClickDay={onDoubleClickDay}
                selectedDate={selectedDate}
              />
            )}
            {mode === 'month' && (
              <MonthCalendar
                days={monthCalendarDays}
                eventIdToClickOnMount={eventIdToClickOnMount}
                fillChipWithGameType={fillChipWithGameType}
                onClickDay={onClickDay}
                onClickEvent={onClickEvent}
                onDoubleClickDay={onDoubleClickDay}
                onDropEvent={handleOnDropEvent}
                selectedDate={selectedDate}
              />
            )}
            {mode === 'week' && (
              <WeekCalendar
                days={weekCalendarDays}
                fillChipWithGameType={fillChipWithGameType}
                onClickDay={onClickDay}
                onClickEvent={onClickEvent}
                onDoubleClickDay={onDoubleClickDay}
                onDropEvent={handleOnDropEvent}
                selectedDate={selectedDate}
              />
            )}
            {mode === 'day' && (
              <DayCalendar
                events={events}
                fillChipWithGameType={fillChipWithGameType}
                onClickDay={onClickDay}
                onClickEvent={onClickEvent}
                onDoubleClickDay={onDoubleClickDay}
                onDropEvent={handleOnDropEvent}
                selectedDate={selectedDate}
              />
            )}
          </Body>
        </DndProvider>
      </Container>
      <Modal visible={isModalVisible} onClose={handleOnCloseModal}>
        <Modal.Header>Update</Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to change <b>{droppedEvent.newEvent.title}</b>{' '}
            to{' '}
            <b>
              {moment(droppedEvent.newEvent.startDate).format('MM/DD/YYYY')}
            </b>
            ?
          </p>
          <Checkbox checked={notifyEventUpdate} onChange={handleOnChangeNotify}>
            Notify all invitees of the change
          </Checkbox>
        </Modal.Body>
        <Modal.Footer
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <CancelButton appearance='minimal' onClick={handleOnCloseModal}>
            Cancel
          </CancelButton>
          <Button onClick={handleOnSave}>Save</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const Container = styled.div`
  background-color: ${Colors.WHITE};
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  height: calc(100vh - 75px);
  overflow: scroll;
  padding: 15px;
  width: 100%;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;

  @media all and (min-width: 780px) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
`
const LeftColumn = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  @media all and (min-width: 780px) {
    justify-content: flex-start;
  }
`
const RightColumn = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  @media all and (max-width: 779px) {
    margin-top: 10px;
  }
  @media all and (min-width: 780px) {
    justify-content: flex-end;
  }
`

const ButtonSet = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media print {
    display: none;
  }
`

const ModeButton = styled(Button)`
  border-left-width: 0;
  border-radius: 0;
  min-width: 40px;

  &:first-child {
    border-bottom-left-radius: 8px;
    border-left-width: 2px;
    border-top-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`

const CurrentMonth = styled.h2`
  margin: 0;
  font-size: 22px;
  font-weight: 700;
  @media all and (min-width: 440px) {
    margin-left: 20px;
    font-size: 28px;
  }
`

const Body = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  width: 100%;
`

const StyledButton = css`
  border-radius: 50%;
  min-height: 38px;
  min-width: 38px;
  @media print {
    display: none;
  }
`

const SubscribeButton = styled(Button)`
  ${StyledButton};
`

const PrintButton = styled(Button)`
  ${StyledButton};
  margin-left: 10px;
  margin-right: 10px;
  @media all and (min-width: 390px) {
    margin-right: auto;
  }
  @media all and (min-width: 780px) {
    margin-right: 10px;
  }
`
const CancelButton = styled(Button)`
  margin-right: 6px;
`
const CreateEventButton = styled(Button)`
  margin-right: 6px;
`

export default Calendar
