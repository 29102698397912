import React, { useCallback, useMemo, useState } from 'react'
import { Virtuoso } from 'react-virtuoso'
import moment from 'moment'
import styled from 'styled-components'

import { File } from '@sportsyou/api'
import { Colors } from '@sportsyou/core'
import { ExtendedUpload } from '@sportsyou/react-hooks'

import Widget from '../widget'
import FileTypeIcon from '../file-type-icon'
import Icon from '../icon'
import Button from '../button'
import Spinner from '../spinner'
import FileInput from '../file-input'
import Activity from '../activity'

type TestIds = {
  container?: string
  footer?: string
  header?: string
  list?: string
}

export interface FilesWidgetProps {
  className?: string
  emptyState?: React.ReactNode
  /** Array of Files */
  files: Array<File>
  /**
   * Display extra actions only admins can call
   */
  isAdmin?: boolean
  /**
   * Displays activity bar
   */
  isLoading?: boolean
  /**
   * Displays activity spinner
   */
  isUploading?: boolean
  /**
   * Specifies which component to use for loading state
   */
  loadingComponent?: 'activity' | 'spinner'
  /**
   * Maximum number of items to display
   */
  maxToDisplay?: number
  /**
   * Minimum number of items to display
   */
  minToDisplay?: number
  onClickDeleteFile?: (file: File) => void
  onClickDownloadFile?: (file: File) => void
  onClickViewFile?: (file: File) => void
  onUploaderCancel?: (upload?: ExtendedUpload) => void
  onUploaderError?: (
    uploads: ExtendedUpload[],
    currentUpload: ExtendedUpload
  ) => void
  onUploaderUploadComplete?: (
    uploads: ExtendedUpload[],
    currentUpload: ExtendedUpload
  ) => void
  onUploaderUploadDone?: (
    uploads: ExtendedUpload[],
    currentUpload: ExtendedUpload
  ) => void
  onUploaderUploadProgress?: (
    uploads: ExtendedUpload[],
    currentUpload: ExtendedUpload
  ) => void
  onUploaderUploadStart?: (
    uploads: ExtendedUpload[],
    currentUpload: ExtendedUpload
  ) => void
  onEndReached?: () => void
  showCount?: boolean
  sortByDate?: boolean
  style?: React.CSSProperties
  /**
   * Optional testIds for unit testing
   */
  testIds?: TestIds
}

const FILE_ROW_HEIGHT = 50

export const FilesWidget: React.FC<FilesWidgetProps> = ({
  className,
  emptyState,
  isAdmin,
  isLoading,
  isUploading,
  loadingComponent = 'spinner',
  maxToDisplay = 12,
  minToDisplay = 6,

  onClickDeleteFile,
  onClickDownloadFile,
  onClickViewFile,
  onEndReached,

  onUploaderCancel,
  onUploaderError,
  onUploaderUploadComplete,
  onUploaderUploadDone,
  onUploaderUploadProgress,
  onUploaderUploadStart,
  showCount,
  sortByDate,
  files: initialFiles,
  style,
  testIds,
}: FilesWidgetProps) => {
  const files = useMemo(
    () =>
      sortByDate
        ? initialFiles.sort((a: File, b: File) => {
            const aDate = a?.createdAt ? new Date(a.createdAt) : new Date()
            const bDate = b?.createdAt ? new Date(b.createdAt) : new Date()
            if (aDate > bDate) return -1
            if (aDate < bDate) return 1
            return 0
          })
        : initialFiles,
    [initialFiles, sortByDate]
  )

  const [isExpanded, setIsExpanded] = useState(false)

  const renderEmptyState = useCallback(() => {
    return isLoading && loadingComponent === 'spinner' ? (
      <Spinner fill={Colors.SHUTTLE_GRAY} style={{ margin: 'auto' }} />
    ) : (
      emptyState ?? (
        <EmptyContainer>
          <P>No files to display.</P>
          <P>Posted files will appear here.</P>
        </EmptyContainer>
      )
    )
  }, [emptyState, isLoading, loadingComponent])

  /**
   * Event Handlers
   */
  const handleEndReached = () => {
    onEndReached?.()
  }

  const handleOnClickDeleteFile = (event: React.MouseEvent, file: File) => {
    event.stopPropagation()
    console.log('files --> delete file')
    onClickDeleteFile?.(file)
  }
  const handleOnClickDownloadFile = (event: React.MouseEvent, file: File) => {
    event.stopPropagation()
    console.log('files --> download file')
    onClickDownloadFile?.(file)
  }
  const handleOnClickViewFile = (event: React.MouseEvent, file: File) => {
    event.stopPropagation()
    console.log('files --> view file')
    onClickViewFile?.(file)
  }
  const handleOnClickMoreButton = () => {
    setIsExpanded(!isExpanded)
  }

  const renderFileRow = (index: number, file: File) => {
    return (
      <FileContainer
        $height={FILE_ROW_HEIGHT}
        key={file.id}
        onClick={(event) => handleOnClickViewFile(event, file)}
      >
        <FileIcon
          contentType={file.contentType as string}
          fileName={file.fileName as string}
          nameCase='pascal'
          size={24}
        />
        <FileDetails>
          <FileName>{file.fileName}</FileName>
          <FileDate>{moment(file.createdAt).format('MM/DD/YYYY')}</FileDate>
        </FileDetails>
        <FileActions>
          <FileActionButton
            appearance='minimal'
            collapse
            onClick={(event) => handleOnClickDownloadFile(event, file)}
            title='Download'
          >
            <Icon
              fill={Colors.SHUTTLE_GRAY}
              height={14}
              name='Download'
              width={14}
            />
          </FileActionButton>
          {isAdmin && (
            <FileActionButton
              appearance='minimal'
              collapse
              onClick={(event) => handleOnClickDeleteFile(event, file)}
              title='Delete'
            >
              <Icon
                fill={Colors.SHUTTLE_GRAY}
                height={14}
                name='Trash'
                width={14}
              />
            </FileActionButton>
          )}
        </FileActions>
      </FileContainer>
    )
  }

  return (
    <StyledWidget
      bodyStyle={{ padding: 0 }}
      className={className}
      containerStyle={style}
      data-testid={testIds?.container}
    >
      <Widget.Header
        data-testid={testIds?.header}
        style={{
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <span>
          <b>Files</b> {showCount && <>({files.length})</>}
        </span>
        {isUploading ? (
          <FileUploaderProgress>
            Uploading <Spinner size={14} />
          </FileUploaderProgress>
        ) : null}
        {isAdmin ? (
          <FileInput
            onlySelectFileButton
            onUploaderCancel={onUploaderCancel}
            onUploaderError={onUploaderError}
            onUploaderUploadComplete={onUploaderUploadComplete}
            onUploaderUploadDone={onUploaderUploadDone}
            onUploaderUploadProgress={onUploaderUploadProgress}
            onUploaderUploadStart={onUploaderUploadStart}
            selectFileButton={
              <AddFileButton appearance='minimal' collapse>
                <Icon fill={Colors.HAVELOCK_BLUE} name='Plus' size={10} /> Add
              </AddFileButton>
            }
          />
        ) : null}
        {isLoading && loadingComponent === 'activity' && (
          <Activity placement='bottom' position='absolute' />
        )}
      </Widget.Header>
      <Container data-testid={testIds?.list}>
        {files.length === 0 ? (
          renderEmptyState()
        ) : (
          <Virtuoso
            data={files}
            endReached={handleEndReached}
            overscan={12}
            // atBottomThreshold={}
            itemContent={(index, file) => renderFileRow(index, file)}
            style={{
              height:
                files.length >= minToDisplay
                  ? (isExpanded
                      ? FILE_ROW_HEIGHT * maxToDisplay
                      : FILE_ROW_HEIGHT * minToDisplay) -
                    FILE_ROW_HEIGHT / 2
                  : FILE_ROW_HEIGHT * files.length,
            }}
          />
        )}
      </Container>
      {files?.length > 6 && (
        <Widget.Footer data-testid={testIds?.footer}>
          <MoreButton
            appearance='minimal'
            collapse
            onClick={handleOnClickMoreButton}
            variant='secondary'
          >
            {isExpanded ? 'Less' : 'More'}
          </MoreButton>
        </Widget.Footer>
      )}
    </StyledWidget>
  )
}

const StyledWidget = styled(Widget)`
  min-width: 240px;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${FILE_ROW_HEIGHT}px;
`
const EmptyContainer = styled.div`
  padding: 6px 10px;
`
const FileUploaderProgress = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  color: ${Colors.SHUTTLE_GRAY};
`
const FileContainer = styled.div<{ $height: number }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 10px;
  height: ${({ $height }) => $height}px;
  justify-content: space-between;
  transition: 120ms background-color ease-in-out;
  &:hover,
  &:active {
    background-color: ${Colors.CATSKILL_WHITE};
  }
`
const FileIcon = styled(FileTypeIcon)`
  flex: 0 0 24px;
  margin-right: 4px;
`
const FileDetails = styled.div`
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const FileName = styled.div`
  flex: 1 1 auto;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover,
  &:active {
    text-decoration: underline;
  }
`
const FileDate = styled.div`
  color: ${Colors.SHUTTLE_GRAY};
  flex-wrap: wrap;
  flex: 1 1 auto;
  font-size: 11px;
`
const FileActions = styled.div`
  flex: 0 0 auto;
`

const FileActionButton = styled(Button)`
  border-radius: 50%;
  min-height: 32px;
  min-width: 32px;
  &:not(:first-child) {
    margin-left: 4px;
  }
`
const AddFileButton = styled(Button)`
  margin-left: auto;
  min-height: 1px;
  min-width: 1px;
  padding: 2px 10px;
  span {
    gap: 4px;
  }
`

const MoreButton = styled(Button)`
  min-height: 1px;
  min-width: 1px;
  padding: 2px 10px;
`
const P = styled.p`
  color: ${Colors.SHUTTLE_GRAY};
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 10px;
  &:last-child {
    margin-bottom: 10px;
  }
`

export default FilesWidget
