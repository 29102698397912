import { Colors, darken, lighten } from '@sportsyou/core'
import React, { PropsWithChildren, useState } from 'react'
import styled from 'styled-components'

export interface ButtonGroupButtonProps {
  children?: React.ReactNode
  className?: string
  disabled?: boolean
  style?: React.CSSProperties
  isActive?: boolean
  isFirst?: boolean
  isLast?: boolean
  onClick: (event: React.MouseEvent) => void
  text?: React.ReactNode | string
  testId?: string
}

export const ButtonGroupButton: React.FC<
  PropsWithChildren<ButtonGroupButtonProps>
> = ({
  children,
  className,
  isActive,
  isFirst,
  isLast,
  onClick,
  style,
  text,
  testId,
}: ButtonGroupButtonProps) => {
  const handleOnClick = (event: React.MouseEvent) => {
    !isActive && onClick?.(event)
  }
  return (
    <Button
      className={className}
      data-testid={testId}
      isActive={!!isActive}
      isFirst={!!isFirst}
      isLast={!!isLast}
      onClick={handleOnClick}
      style={style}
      type='button'
    >
      {text ?? children}
    </Button>
  )
}

const Button = styled.button<{
  isActive: boolean
  isFirst: boolean
  isLast: boolean
}>`
  background-color: ${({ isActive }) =>
    isActive ? Colors.HAVELOCK_BLUE : Colors.WHITE};
  color: ${({ isActive }) => (isActive ? Colors.WHITE : Colors.HAVELOCK_BLUE)};
  cursor: pointer;
  border: 2px solid ${Colors.HAVELOCK_BLUE};
  border-top-left-radius: ${({ isFirst }) => (isFirst ? '6px' : undefined)};
  border-bottom-left-radius: ${({ isFirst }) => (isFirst ? '6px' : undefined)};
  border-top-right-radius: ${({ isLast }) => (isLast ? '6px' : undefined)};
  border-bottom-right-radius: ${({ isLast }) => (isLast ? '6px' : undefined)};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: ${({ isFirst }) => (!isFirst ? '-2px' : undefined)};
  font-family: inherit;
  font-size: 12px;
  outline: none;
  padding: 7px 12px;
  position: relative;
  min-height: 36px;
  min-width: 36px;

  &:hover,
  &:active,
  &:focus {
    z-index: 1;
  }

  &:active {
    background-color: ${({ isActive }) =>
      isActive ? darken(Colors.HAVELOCK_BLUE, 20) : darken(Colors.WHITE, 6)};
    border-color: ${({ isActive }) =>
      isActive ? darken(Colors.HAVELOCK_BLUE, 20) : Colors.HAVELOCK_BLUE};
    box-shadow: none;
    color: ${({ isActive }) =>
      isActive ? Colors.WHITE : Colors.HAVELOCK_BLUE};
  }

  &:focus {
    background-color: ${({ isActive }) =>
      isActive ? darken(Colors.HAVELOCK_BLUE, 20) : darken(Colors.WHITE, 6)};
    border-color: ${({ isActive }) =>
      isActive ? Colors.HAVELOCK_BLUE : darken(Colors.HAVELOCK_BLUE, 20)};
    box-shadow: 0 0 0 2px ${lighten(Colors.HAVELOCK_BLUE, 40)};
  }
`

export default ButtonGroupButton
