import { CSSProperties, FC, PropsWithChildren, ReactNode } from 'react'
import styled from 'styled-components'

export interface NavbarRightProps {
  children?: ReactNode
  className?: string
  style?: CSSProperties
}

export const NavbarRight: FC<PropsWithChildren<NavbarRightProps>> = ({
  children,
  className,
  style,
}: NavbarRightProps) => (
  <Container className={className} style={style}>
    {children}
  </Container>
)

const Container = styled.div`
  align-items: center;
  display: flex;
  @media print {
    display: none;
  }
`

export default NavbarRight
