import { inRange } from 'lodash'

import {
  US_PHONE_NUMBER_LENGTH,
  US_PHONE_NUMBER_WITH_COUNTRY_CODE_LENGTH,
} from '../constants'

interface PhoneNumberValidationResultInterface {
  phoneNumber: string
  valid: boolean
}

export const isPhoneNumberValid = (
  phoneNumber: string
): PhoneNumberValidationResultInterface => {
  // make sure none of the characters outside of allowed (commonly used) characters
  // numeric digits, dot[.], dash[-], parenthesis[()], plus[+], space[ ], star/asterisk[*], sharp/pound sign[#]
  // const invalidCharactersMatch = phoneNumber.match(/[^\d\.\-\(\)\s\+\*\#]/g)
  const invalidCharactersMatch = phoneNumber.match(/[^\d.\-(\\s+*#]/g)

  const numberMatch = phoneNumber.replace(/\D/g, '')
  const ret: PhoneNumberValidationResultInterface = {
    phoneNumber: '',
    valid: false,
  }

  if (phoneNumber.length && (!invalidCharactersMatch || !invalidCharactersMatch.length)) {
    if (
      !!numberMatch &&
      inRange(
        numberMatch.length,
        US_PHONE_NUMBER_LENGTH,
        US_PHONE_NUMBER_WITH_COUNTRY_CODE_LENGTH + 1
      )
    ) {
      if (
        (numberMatch.substring(0, 1) === '1' &&
          numberMatch.length === US_PHONE_NUMBER_WITH_COUNTRY_CODE_LENGTH) ||
        numberMatch.length === US_PHONE_NUMBER_LENGTH
      ) {
        ret.valid = true
        ret.phoneNumber = numberMatch.slice(-US_PHONE_NUMBER_LENGTH) // return phone number portion only
      }
    }
  }

  return ret
}
