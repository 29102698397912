import React, { useEffect, useMemo, useState } from 'react'
import { Colors } from '@sportsyou/core'

import styled, { css } from 'styled-components'

import { CloseButton, Transition } from '../../../components'

interface Props {
  className?: string
  disableAnimation?: boolean
  fixed?: boolean
  gap?: number
  style?: React.CSSProperties
  isVisible?: boolean
  isMinimized?: boolean
  minimizedWidth?: number
  onClose?: (isVisible: boolean) => void
  showCloseButton?: boolean
  width?: number
  yOffset?: number
}
type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type ContentSidebarProps = Props & NativeAttributes

export const ContentSidebar = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<ContentSidebarProps>
>(
  (
    {
      children,
      className,
      disableAnimation,
      fixed,
      isVisible: selfVisible = true,
      isMinimized = false,
      minimizedWidth = 60,
      gap,
      onClose,
      showCloseButton,
      style,
      width = 230,
      yOffset = 0,
      ...props
    }: ContentSidebarProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const [isVisible, setIsVisible] = useState<boolean>(selfVisible)

    // isVisible is being updated from parent.
    useEffect(() => {
      setIsVisible(selfVisible)
    }, [selfVisible])

    const widthAsString = useMemo(
      () => (isMinimized ? `${minimizedWidth}px` : `${width}px`),
      [isMinimized, minimizedWidth, width]
    )

    // const shouldRender = useMemo(() => {
    //   if (typeof isVisible !== 'undefined') return isVisible
    //   if (matches) return matches
    //   return false
    // }, [isVisible, matches])

    // useEffect(() => {
    //   console.log(typeof isVisible)
    //   console.log({ isVisible, shouldRender })
    // }, [isVisible, matches])

    const handleOnClose = () => {
      const newState = !isVisible
      setIsVisible(newState)
      onClose && onClose(newState)
    }

    const renderSidebar = () => (
      <Container
        className={className}
        disableAnimation={disableAnimation}
        fixed={fixed}
        gap={gap}
        isMinimized={isMinimized}
        ref={ref}
        style={style}
        $width={widthAsString}
        minimizedWidth={minimizedWidth}
        maximizedWidth={width}
        yOffset={yOffset}
        {...props}
      >
        {showCloseButton && (
          <CloseButtonContainer>
            <CloseButton
              appearance='circular'
              hideBackground
              onClick={handleOnClose}
              size={38}
              style={{ marginRight: 4 }}
              xRatio={0.36}
            />
          </CloseButtonContainer>
        )}
        {children}
      </Container>
    )

    // return isVisible ? (
    return fixed ? (
      <Transition enterTime={180} name='sidebar' visible={isVisible}>
        {renderSidebar()}
      </Transition>
    ) : isVisible ? (
      renderSidebar()
    ) : null
    // ) : null
  }
)

const fixedStyles = css`
  background-color: ${Colors.WHITE};
  border-right: 1px solid ${Colors.ALTO};
  bottom: 0;
  box-shadow: 6px 0 10px 0 rgb(0 0 0 / 20%);
  flex-grow: 0;
  height: 100vh;
  flex: 1 1 auto;
  max-width: 90vw;
  left: 0;
  overflow-y: auto;
  padding-left: 10px;
  padding-right: 10px;
  position: fixed;
  top: 0;
  z-index: 900;
`

const stickyStyles = css`
  flex: 1 1 auto;
  max-width: 100%;
  position: sticky;
  z-index: 2;
`
// box-shadow: ${({ fixed }) =>
//   fixed ? '8px 0 10px -2px rgb(0 0 0 / 20%)' : undefined};
// flex-grow: ${({ fixed }) => (fixed ? 0 : undefined)};

const Container = styled.div<
  Pick<
    ContentSidebarProps,
    'disableAnimation' | 'fixed' | 'gap' | 'isMinimized' | 'yOffset'
  > & {
    $width: string
    minimizedWidth: number
    maximizedWidth: number
  }
>`
  align-self: stretch;
  box-sizing: border-box;
  // display: none;
  width: ${({ $width }) => $width};

  ${({ fixed }) => (fixed ? fixedStyles : stickyStyles)};

  @media print {
    display: none;
  }

  @media all and (min-width: 768px) {
    flex: 0 0 ${({ $width }) => $width};
    height: ${({ fixed, yOffset = 0 }) =>
      fixed || yOffset === 0 ? '100vh' : `calc(100vh - ${yOffset}px)`};
    margin-right: ${({ gap }) => gap}px;
    overflow-y: auto;
    // padding-left: ${({ gap }) => gap}px;
    // padding-right: ${({ gap }) => gap}px;
    top: ${({ fixed, yOffset = 0 }) => (fixed ? undefined : `${yOffset}px`)};
    width: auto;

  @media all and (min-width: 900px) {
    display: block;
  }

  // opacity: 0;

  transition: transform 180ms ease;
  transform: ${({ fixed }) => (fixed ? 'translate3d(-100%, 0, 0)' : undefined)};


  &.sidebar-enter {
    // opacity: 0;
    transform: translate3d(-120%, 0, 0);
  }
  &.sidebar-enter-active {
    // opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.sidebar-leaveleave-active {
    // opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.sidebar-leave {
    // opacity: 0;
    transform: translate3d(-120%, 0, 0);
  }

  @media print {
    display: none;
  }
`
const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-top: 10px;
`

export default ContentSidebar
