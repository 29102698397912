import * as React from 'react'
import type { SVGProps } from 'react'
const BadgeVerifiedOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    style={props.style}
    {...props}
  >
    <g clipPath='url(#a)'>
      <path
        fill='#4A90E2'
        d='M14.485 2.82452c.736-1.0521 2.294-1.0521 3.0301 0L19.02 4.9756c.446.63738 1.2431.92751 1.9943.72588l2.5356-.68049c1.2401-.33284 2.4336.66862 2.321 1.9477l-.2298 2.61516c-.0681.77485.3561 1.50955 1.0611 1.83795l2.3799 1.1085c1.1639.5422 1.4344 2.0765.526 2.9841l-1.8569 1.8555c-.5503.5499-.6977 1.3853-.3685 2.0901l1.1103 2.3789c.5432 1.1634-.236 2.5127-1.5151 2.6242l-2.6152.2276c-.7749.0675-1.4249.6127-1.6259 1.3644l-.6784 2.5359c-.3319 1.2405-1.7959 1.7733-2.8474 1.0363l-2.1498-1.5065c-.637-.4466-1.4853-.4466-2.1223 0l-2.1499 1.5065c-1.0515.737-2.5155.2042-2.84736-1.0363l-.67844-2.5359c-.20102-.7517-.85086-1.2969-1.62579-1.3644l-2.61535-.2276c-1.27916-.1115-2.05816-1.4608-1.51503-2.6242L4.61746 19.46c.32904-.7048.18172-1.5402-.36854-2.0901l-1.85707-1.8555c-.90831-.9076-.63777-2.4419.52616-2.9841l2.37974-1.1085c.70512-.3284 1.12926-1.0631 1.06116-1.83795l-.22987-2.61516c-.11243-1.27908 1.08106-2.28054 2.32119-1.9477l2.53547.68049c.7513.20163 1.5485-.0885 1.9944-.72588l1.5049-2.15108Z'
      />
      <path
        fill='#fff'
        d='M21.3065 14.505 15.57 20.6621c-.0839.09-.1828.1601-.292.208-.1092.048-.2256.0719-.3433.0704-.1178-.0015-.2352-.0292-.3434-.0799-.1081-.0508-.2056-.1224-.2876-.2144l-3.6359-4.074c-.0289-.041-.0546-.0829-.0778-.128-.1234-.1867-.1814-.4138-.1643-.6432.017-.2294.1073-.4455.2567-.6081.1677-.177.3917-.2778.6251-.2784.1198-.0004.2389.0258.3492.0768.1103.051.2105.1267.2935.2208l1.9794 2.2146 1.0329 1.1489 5.0938-5.4787c.1278-.1316.2876-.2192.4606-.2528.1733-.0335.3521-.0115.5137.064.1616.0756.2992.2017.396.3617.0971.1599.1491.3458.1498.5376-.0007.1298-.0255.2581-.0719.3776-.0464.1195-.1132.2292-.198.32Z'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M12.9699 1.76459c1.4721-2.104212 4.5881-2.104212 6.0602 0l1.5049 2.15103 2.5356-.68044c2.4802-.66567 4.8671 1.3373 4.6423 3.89541l-.2299 2.61508 2.3798 1.10863c2.3277 1.0843 2.8689 4.153 1.0522 5.9681l-1.8569 1.8554 1.1104 2.379c1.0863 2.3269-.4719 5.0254-3.0301 5.2483l-2.6154.2278-.6784 2.5359c-.6636 2.4808-3.5917 3.5466-5.6947 2.0728l-2.1498-1.5067-2.1499 1.5067c-2.103 1.4738-5.03112.408-5.69476-2.0728l-.67842-2.5359-2.61537-.2278c-2.55825-.2229-4.116348-2.9214-3.03008-5.2483L2.942 18.6779l-1.85705-1.8555c-1.816607-1.8151-1.275541-4.8838 1.05232-5.9682l2.37974-1.10845-.22987-2.61515C4.06228 4.57244 6.44925 2.56951 8.9295 3.23518l2.5355.68049 1.5049-2.15108ZM19.211 29.6273c1.0515.737 2.5155.2042 2.8474-1.0363l.6784-2.5359c.201-.7517.851-1.2969 1.6259-1.3644m0 0 2.6152-.2276c1.2791-.1115 2.0583-1.4608 1.5151-2.6242L27.3827 19.46c-.3292-.7048-.1818-1.5402.3685-2.0901l1.8569-1.8555c.9084-.9076.6379-2.4419-.526-2.9841l-2.3799-1.1085c-.705-.3284-1.1292-1.0631-1.0611-1.83795l.2298-2.61516c.1126-1.27908-1.0809-2.28054-2.321-1.9477l-2.5356.68049c-.7512.20163-1.5483-.0885-1.9943-.72588l-1.5049-2.15108c-.7361-1.0521-2.2941-1.0521-3.0301 0L12.9801 4.9756c-.4459.63738-1.2431.92751-1.9944.72588l-2.53547-.68049c-1.24013-.33284-2.43362.66862-2.32119 1.9477l.22987 2.61516c.0681.77485-.35604 1.50955-1.06116 1.83795l-2.37974 1.1085c-1.16393.5422-1.43447 2.0765-.52616 2.9841l1.85707 1.8555c.55026.5499.69758 1.3853.36854 2.0901l-1.11043 2.3789c-.54313 1.1634.23587 2.5127 1.51503 2.6242l2.61535.2276c.77493.0675 1.42477.6127 1.62579 1.3644l.67844 2.5359c.33186 1.2405 1.79586 1.7733 2.84736 1.0363l2.1499-1.5065c.637-.4466 1.4853-.4466 2.1223 0l2.1498 1.5065'
        clipRule='evenodd'
      />
    </g>
    <defs>
      <clipPath>
        <path fill='#fff' d='M0 0h32v32H0z' />
      </clipPath>
    </defs>
  </svg>
)
export default BadgeVerifiedOutline
