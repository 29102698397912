import * as React from 'react'
import type { SVGProps } from 'react'
const Post = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <g>
      <path d='M19.93 7.08H10.5a1.06 1.06 0 0 0 0 2.12h9.43a1.06 1.06 0 0 0 0-2.12zm0 6.29H7.35a1.06 1.06 0 1 0 0 2.12h12.58a1.06 1.06 0 0 0 0-2.12zm-7.86 6.29H7.35a1.06 1.06 0 0 0 0 2.12h4.72a1.06 1.06 0 1 0 0-2.12zm19.16.12l-2.5-2.5a2.59 2.59 0 0 0-1.86-.77 2.65 2.65 0 0 0-1.86.77l-6.94 6.94a2.62 2.62 0 0 0-.77 1.87v4.06a1.05 1.05 0 0 0 1.06 1.06h4.07a2.61 2.61 0 0 0 1.86-.77l6.94-6.94a2.61 2.61 0 0 0 .77-1.86 2.58 2.58 0 0 0-.77-1.86zM29.73 22l-6.94 6.94a.51.51 0 0 1-.36.15h-3v-3a.51.51 0 0 1 .15-.37l6.94-6.94a.51.51 0 0 1 .72 0l2.5 2.5a.51.51 0 0 1 0 .72z' />
      <path d='M11.25 26H3.73a1.69 1.69 0 0 1-1.61-1.74V4.64a1.68 1.68 0 0 1 1.61-1.73h21.39a1.68 1.68 0 0 1 1.61 1.73v7.2a1.07 1.07 0 0 0 2.13 0v-7.2A3.8 3.8 0 0 0 25.12.79H3.73A3.8 3.8 0 0 0 0 4.64v19.57a3.8 3.8 0 0 0 3.73 3.86h7.52a1.06 1.06 0 1 0 0-2.12z' />
    </g>
  </svg>
)
export default Post
