import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { Colors, lighten, randomId } from '@sportsyou/core'
import { useClickAway } from '@sportsyou/react-hooks'
import { Search, X } from '@sportsyou/react-icons'

import { TextInputAutoComplete, TextInputType } from '../../utils/prop-types'

import Button from '../button'
import Spinner from '../spinner'

import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_SIZE_SM,
  LINE_HEIGHT,
} from '../../theme'

type InputStatus = 'error' | 'success'

type TestIds = {
  input?: string
  labelContainer?: string
  search?: string
  clearSearch?: string
  clearButton?: string
  spinner?: string
  validationMessage?: string
}

interface Props {
  autoComplete?: TextInputAutoComplete
  className?: string
  containerStyle?: React.CSSProperties
  defaultValue?: string
  disabled?: boolean
  inputContainerStyle?: React.CSSProperties
  inputStyle?: React.CSSProperties
  isClearable?: boolean
  isLoading?: boolean
  label?: string
  labelStyle?: React.CSSProperties
  /** Max number of characters allowed */
  maxLength?: number
  onBlur?: () => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClear?: () => void
  onFocus?: () => void
  placeholder?: string
  showPasswordToggle?: boolean
  showSearchIcon?: boolean
  showValidationMessage?: boolean
  status?: InputStatus
  testId?: TestIds
  type?: TextInputType
  validationMessage?: string
  validationStyle?: React.CSSProperties
  value?: string
}

type NativeAttributes = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  keyof Props
>
export type TextInputProps = Props & NativeAttributes

export const TextInput = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<TextInputProps>
>(
  (
    {
      className,
      containerStyle,
      defaultValue,
      disabled = false,
      inputContainerStyle,
      inputStyle,
      isClearable,
      isLoading,
      label,
      labelStyle,
      maxLength,
      onBlur,
      onChange,
      onClear,
      onFocus,
      placeholder = 'Text Input',
      showSearchIcon,
      showPasswordToggle,
      showValidationMessage = false,
      status,
      testId,
      type = 'text',
      validationMessage,
      validationStyle,
      value = '',
      ...props
    }: TextInputProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const name = `input-${randomId()}`

    const [inputType, setInputType] = useState(type)
    const [inputValue, setInputValue] = useState(value)
    const [currentState, setCurrentState] = useState<string>()

    const containerRef = useRef<HTMLDivElement>(null)

    const containerClassNames = useMemo(() => {
      let _className
      if (currentState) {
        _className = `${currentState}`
      }
      if (className) {
        _className = `${className} ${className}`
      }
      return _className
    }, [currentState, className])

    useEffect(() => {
      setInputType(type)
    }, [type])

    useEffect(() => {
      setInputValue(value)
    }, [value])

    const handleOnClickEye = useCallback(() => {
      setInputType(inputType === 'text' ? 'password' : 'text')
    }, [inputType])

    const handleOnChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(event)
        setInputValue(event.target.value)
      },
      [onChange]
    )

    const handleOnBlur = useCallback(() => {
      onBlur?.()
      setCurrentState('blur')
    }, [onBlur])

    const handleOnFocus = useCallback(() => {
      onFocus?.()
      setCurrentState('focus')
    }, [onFocus])

    const handleOnClickClearSearch = useCallback(() => {
      setInputValue('')
      onChange?.({
        target: { value: '' },
      } as React.ChangeEvent<HTMLInputElement>)
      onClear?.()
      const _input = document.querySelector(`input[name="${name}"`)
      const nativeValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value'
      )?.set
      nativeValueSetter && nativeValueSetter.call(_input, '')

      const event = new Event('input', { bubbles: true })
      _input?.dispatchEvent(event)
    }, [name, onChange, onClear])

    useClickAway(containerRef, handleOnBlur)

    const renderIcon = useMemo(() => {
      if (isLoading) {
        return (
          <IconContainer>
            <Spinner
              data-testid={testId?.spinner}
              fill={Colors.SHUTTLE_GRAY}
              size={20}
            />
          </IconContainer>
        )
      }

      if (showSearchIcon) {
        return (
          <IconContainer>
            {inputValue === '' ? (
              <Search
                data-testid={testId?.search}
                fill={Colors.SHUTTLE_GRAY}
                height={16}
                width={16}
              />
            ) : (
              <SearchClearButton
                appearance='minimal'
                collapse
                data-testid={testId?.clearSearch}
                onClick={handleOnClickClearSearch}
                textStyle={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <X />
              </SearchClearButton>
            )}
          </IconContainer>
        )
      }

      if (isClearable && inputValue !== '' && currentState === 'focus') {
        return (
          <IconContainer>
            <SearchClearButton
              appearance='minimal'
              collapse
              data-testid={testId?.clearButton}
              onClick={handleOnClickClearSearch}
              textStyle={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <X />
            </SearchClearButton>
          </IconContainer>
        )
      }
      return null
    }, [
      isLoading,
      showSearchIcon,
      isClearable,
      inputValue,
      currentState,
      handleOnClickClearSearch,
    ])

    return (
      <Container
        className={containerClassNames}
        data-status={status}
        // data-state={currentState}
        style={containerStyle}
        ref={containerRef}
      >
        <Label
          data-testid={testId?.labelContainer}
          status={status}
          style={labelStyle}
          $disabled={disabled}
        >
          {label && <LabelText>{label}</LabelText>}

          <InputContainer style={inputContainerStyle}>
            <Input
              ref={ref}
              {...props}
              data-testid={testId?.input}
              disabled={disabled}
              name={name}
              defaultValue={defaultValue}
              maxLength={maxLength}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              onChange={handleOnChange}
              placeholder={placeholder}
              showPasswordToggle={showPasswordToggle}
              style={inputStyle}
              type={inputType}
              value={inputValue}
            />

            {renderIcon}

            {showPasswordToggle && (
              <PasswordToggleButton
                appearance='minimal'
                collapse
                onClick={handleOnClickEye}
                tabIndex={-1}
                type='button'
                textStyle={{
                  fontSize: `${FONT_SIZE_SM}px`,
                  lineHeight: `${LINE_HEIGHT}`,
                }}
              >
                {inputType === 'password' ? 'Show' : 'Hide'}
              </PasswordToggleButton>
            )}
          </InputContainer>
        </Label>
        {showValidationMessage && validationMessage && (
          <ValidationMessage
            data-testid={testId?.validationMessage}
            status={status}
            style={validationStyle}
          >
            {validationMessage}
          </ValidationMessage>
        )}
      </Container>
    )
  }
)

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  max-width: 100%;
  min-height: 60px;
  position: relative;
`
const Label = styled.label<{ $disabled: boolean; status?: InputStatus }>`
  background-color: ${({ $disabled }) =>
    $disabled ? Colors.ALTO : Colors.WHITE};
  border: 1px solid ${Colors.ALTO};
  border-color: ${({ status }) =>
    status
      ? status === 'error'
        ? Colors.MONZA
        : Colors.MOUNTAIN_MEADOW
      : Colors.ALTO};
  border-radius: ${BORDER_RADIUS};
  box-sizing: border-box;

  &:has(input:focus) {
    box-shadow: 0 0 0 2px ${lighten(Colors.HAVELOCK_BLUE, 40)};
  }
`
const LabelText = styled.div`
  color: ${Colors.PUNCH};
  font-size: ${FONT_SIZE_SM}px;
  font-weight: 700;
  margin: 6px 10px 0;
  text-transform: uppercase;
`
const InputContainer = styled.div`
  align-items: center;
  display: flex;
  max-width: 100%;
  @media all and (min-width: 768px) {
    min-width: 300px;
  }
`
const Input = styled.input<
  Pick<TextInputProps, 'showPasswordToggle' | 'disabled'>
>`
  align-self: stretch;
  background: none;
  border: none;
  box-sizing: border-box;
  color: ${({ disabled }) =>
    disabled ? Colors.DUSTY_GRAY : Colors.MINE_SHAFT};
  flex: 1 1 auto;
  font-family: inherit;
  font-size: ${FONT_SIZE}px;
  font-weight: 400;
  line-height: ${LINE_HEIGHT};
  // min-height: 32px;
  outline: none;
  padding: 6px 10px;
  width: 100%;

  &::placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder {
    color: ${Colors.DUSTY_GRAY};
  }
`
const IconContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 4px;
  margin-right: 6px;
`
const SearchClearButton = styled(Button)`
  border-radius: 50%;
  height: 24px;
  min-height: 1px;
  min-width: 1px;
  width: 24px;
`
const PasswordToggleButton = styled(Button)`
  background: none;
  color: ${Colors.HAVELOCK_BLUE};
  cursor: pointer;
  height: auto;
  margin-right: 10px;
  min-height: 1px;
  min-width: 1px;
  padding: 2px 4px;
  transition: background-color 160ms ease-in-out;
  width: 44px;
`
const ValidationMessage = styled.div<{ status?: InputStatus }>`
  color: ${({ status }) =>
    status
      ? status === 'error'
        ? Colors.MONZA
        : Colors.MOUNTAIN_MEADOW
      : Colors.SHUTTLE_GRAY};
  font-size: ${FONT_SIZE_SM}px;
  font-weight: 700;
  margin: 8px 10px 0;
  text-transform: uppercase;
`

TextInput.displayName = 'TextInput'

export default TextInput
