import * as React from 'react'
import type { SVGProps } from 'react'
const BellSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M27.81 20.24l-1.06-1.06a2.2 2.2 0 0 1-.64-1.55V13a8.62 8.62 0 0 0-6-8.18v-.67a4.15 4.15 0 1 0-8.3 0v.67a8.62 8.62 0 0 0-6 8.18v4.63a2.2 2.2 0 0 1-.64 1.55l-.98 1.06a4.46 4.46 0 0 0-1.3 3.13 4.26 4.26 0 0 0 4.25 4.24h4a4.88 4.88 0 0 0 9.71 0h4a4.26 4.26 0 0 0 4.24-4.24 4.46 4.46 0 0 0-1.28-3.13zM14.08 4.15a1.92 1.92 0 1 1 3.83 0v.26h-3.83zM16 29.77a2.67 2.67 0 0 1-2.62-2.16h5.24A2.67 2.67 0 0 1 16 29.77z' />
  </svg>
)
export default BellSolid
