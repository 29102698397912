export default class GraphQLError {
  message: string
  messageType: string | undefined
  code: string | undefined
  path: string | undefined

  constructor(error: any) {
    if (error.path && Array.isArray(error.path) && error.path.length > 0)
      this.path = error.path[0]

    if (!error.message) {
      this.message = error
    } else {
      try {
        const obj = JSON.parse(error.message)
        if (obj.messageType) {
          this.messageType = obj.messageType
          this.message = obj.message
          this.code = obj.code
        } else this.message = error
      } catch (err) {
        this.message = error
      }
    }
  }

  getMessage() {
    return this.message
  }

  getCode() {
    return this.code
  }

  getMessageType() {
    return this.messageType
  }
  getPath() {
    return this.path
  }
}
