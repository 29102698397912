import { FC, useCallback, useState } from 'react'
import styled from 'styled-components'

import { Colors, darken } from '@sportsyou/core'
import { ExtendedUpload } from '@sportsyou/react-hooks'
import { CameraSolid } from '@sportsyou/react-icons'

import { BORDER_RADIUS } from '../../theme'
import Avatar from '../avatar'
import FileInput from '../file-input'
import Spinner from '../spinner'
import Icon from '../icon'

type UploaderProps = {
  cancel?: () => void
  done?: () => void
  error?: () => void
  start?: (
    uploads: Array<ExtendedUpload>,
    currentUpload: ExtendedUpload
  ) => void
  transcode?: (upload?: ExtendedUpload) => void
}
export interface CoverImageProps {
  avatarImageSrc?: string
  canEdit?: boolean
  coverImageSrc?: string
  isVerified?: boolean
  isUploadingAvatar?: boolean
  isUploadingCoverImage?: boolean
  name?: string
  variant?: 'group' | 'team' | 'profile' | string
  onClickCoverImage?: () => void
  onClickAvatar?: () => void
  avatarImageUploaderProps?: UploaderProps
  coverImageUploaderProps?: UploaderProps
  testId?: string
}

const defaultCoverImage = './default-coverimage.png'
// const defaultCoverImage = ''

export const CoverImage: FC<CoverImageProps> = ({
  avatarImageSrc,
  canEdit = false,
  coverImageSrc,
  isUploadingAvatar = false,
  isUploadingCoverImage = false,
  isVerified,
  name,
  variant,
  onClickCoverImage,
  onClickAvatar,
  avatarImageUploaderProps,
  coverImageUploaderProps,
  testId,
}: CoverImageProps) => {
  const [isCoverImageHovered, setIsCoverImageHovered] = useState<boolean>(false)

  const renderAvatar = useCallback(
    () => (
      <StyledAvatar
        arial-label={`${!avatarImageSrc ? 'Upload' : 'Change'} Avatar Image`}
        canEdit={canEdit}
        editLabel={!avatarImageSrc ? 'Upload' : 'Change'}
        diameter={82}
        isLoading={isUploadingAvatar}
        name={name}
        onClick={onClickAvatar}
        title={`${
          canEdit && !avatarImageSrc ? 'Upload' : 'Change'
        } Avatar Image`}
        uri={avatarImageSrc}
      />
    ),
    [avatarImageSrc, canEdit, isUploadingAvatar, name, onClickAvatar]
  )

  return (
    <Container data-testid={testId}>
      {/* // TODO: Should the entire image have drag and drop? */}
      <Image
        onMouseEnter={() => setIsCoverImageHovered(true)}
        onMouseLeave={() => setIsCoverImageHovered(false)}
        src={coverImageSrc}
      >
        {canEdit && (
          <>
            <Backdrop isHovered={isCoverImageHovered} />
            <FileInput
              cropConfig={{
                height: 500,
                modalTitle: 'Update Your Profile Cover Image',
                onClose: () => {},
                onCrop: () => {},
                width: 1600, // width/height updated to match react app
              }}
              onUploaderCancel={coverImageUploaderProps?.cancel}
              onUploaderError={coverImageUploaderProps?.error}
              onUploaderUploadStart={coverImageUploaderProps?.start}
              onUploaderUploadDone={coverImageUploaderProps?.done}
              onTranscodeComplete={coverImageUploaderProps?.transcode}
              onlySelectFileButton
              selectFileButton={
                <EditCoverImageButton
                  onClick={onClickCoverImage}
                  type='button'
                  aria-label={`${
                    !coverImageSrc ? 'Upload' : 'Change'
                  } Cover Image`}
                  title={`${!coverImageSrc ? 'Upload' : 'Change'} Cover Image`}
                >
                  <CameraSolid fill={Colors.SHUTTLE_GRAY} fontSize={14} />{' '}
                  {!coverImageSrc ? 'Upload' : 'Change'} Cover Image
                </EditCoverImageButton>
              }
              transcodings={['coverImage']} // use-uploader will replace it with proper transcoding set
              uploadType={'coverImage'} // setting either transcodings or uploadType should be good enough
            />
          </>
        )}

        <DetailsContainer>
          {canEdit ? (
            <FileInput
              cropConfig={{
                circular: true,
                height: 420,
                modalTitle: 'Update Your Profile Avatar Image',
                onClose: () => {},
                onCrop: () => {},
                width: 420,
              }}
              onUploaderCancel={avatarImageUploaderProps?.cancel}
              onUploaderError={avatarImageUploaderProps?.error}
              onUploaderUploadStart={avatarImageUploaderProps?.start}
              onUploaderUploadDone={avatarImageUploaderProps?.done}
              onTranscodeComplete={avatarImageUploaderProps?.transcode}
              onlySelectFileButton
              selectFileButton={renderAvatar()}
              transcodings={['profileImage']} // use-uploader will replace it with proper transcoding set
              uploadType={'profileImage'} // setting either transcodings or uploadType should be good enough
            />
          ) : (
            renderAvatar()
          )}
          <NameContainer>
            <NameAndBadge>
              <Name>{name}</Name>
              {isVerified && (
                <StyledIcon name='BadgeVerifiedOutline' size={20} />
              )}
            </NameAndBadge>
            {variant && <span>{variant}</span>}
          </NameContainer>
        </DetailsContainer>
      </Image>
      {isUploadingCoverImage && (
        <Spinner
          size={40}
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 20,
          }}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  background-color: ${Colors.MYSTIC};
  position: relative;
`
const Backdrop = styled.div<HTMLDivElement | { isHovered?: boolean }>`
  background-color: rgb(0 0 0 / 40%);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transition: opacity 160ms ease-in-out;
  opacity: ${({ isHovered }) => (isHovered ? '1' : '0')};
`
const EditCoverImageButton = styled.button`
  align-items: center;
  background-color: ${Colors.CATSKILL_WHITE};
  border: 2px solid transparent;
  border-radius: ${BORDER_RADIUS};
  box-sizing: border-box;
  color: ${Colors.SHUTTLE_GRAY};
  cursor: pointer;
  display: none;
  font-size: 13px;
  height: 34px;
  padding-left: 8px;
  padding-right: 8px;
  position: absolute;
  right: 15px;
  top: 15px;
  transition: background-color 160ms ease-in-out;

  & > svg {
    margin-right: 6px;
  }

  &:hover,
  &:active {
    background-color: ${darken(Colors.CATSKILL_WHITE, 20)};
  }
`
const Image = styled.div<HTMLDivElement | { src?: string }>`
  background-image: url('${({ src }) => (src ? src : defaultCoverImage)}');
  background-position: center;
  background-size: cover;
  height: 290px;
  width: 100%;
  &:hover ${EditCoverImageButton} {
    display: inline-flex;
  }
  @media print {
    height: 115px;
  }
`
const StyledAvatar = styled(Avatar)`
  @media print {
    display: none;
  }
`
const DetailsContainer = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  left: 0;
  padding: 15px;
  position: absolute;
  // pointer-events: none;
  width: 100%;
  z-index: 10;
`
const NameContainer = styled.div`
  display: flex;
  color: ${Colors.WHITE};
  flex-direction: column;
  margin-left: 10px;
  overflow: hidden;
  text-shadow: 0 2px 4px rgb(0 0 0 / 80%);

  @media print {
    color: ${Colors.MINE_SHAFT};
    text-shadow: none;
  }

  & > span {
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
  }
`

const NameAndBadge = styled.div`
  align-items: center;
  display: flex;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0;
  margin-top: 0;
`
const Name = styled.span`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const StyledIcon = styled(Icon)`
  flex: 0 0 auto;
  margin-left: 8px;
`

export default CoverImage
