import {
  createSelector,
  createSlice,
  Dispatch,
  PayloadAction,
} from '@reduxjs/toolkit'

import {
  Community,
  queryCommunities,
  QueryCommunitiesRequest,
  queryCommunity,
  QueryCommunityRequest,
  queryNewCommunityPosts,
} from '@sportsyou/api'
import { RootState } from '../rootReducer'

export interface CommunitiesSliceProps {
  communities: Array<Community>
  hasNewPosts: boolean
}

const initialState: CommunitiesSliceProps = {
  communities: [],
  hasNewPosts: false,
}

const CommunitiesSlice = createSlice({
  name: 'communities',
  initialState: initialState,
  reducers: {
    clearPages(state) {
      state = initialState
    },
    storePages(
      state: CommunitiesSliceProps,
      action: PayloadAction<Array<Community>>
    ) {
      state.communities = action.payload
    },
    addPage(state: CommunitiesSliceProps, action: PayloadAction<Community>) {
      const communityIds = state.communities.map((c) => c.id)
      if (!communityIds.includes(action.payload.id)) {
        state.communities.push(action.payload)
      } else {
        state.communities = state.communities.map((community) =>
          community.id === action.payload.id ? action.payload : community
        )
      }
    },
    removePage(state, action: PayloadAction<Community>) {
      state.communities = state.communities.filter(
        (community) => community.id !== action.payload.id
      )
    },
    updatePage(state, action: PayloadAction<Community>) {
      state.communities = state.communities.map((community) =>
        community.id === action.payload.id ? action.payload : community
      )
    },
    setHasNewPosts(state, action: PayloadAction<boolean>) {
      state.hasNewPosts = action.payload
    },
  },
})

export const {
  addPage,
  clearPages,
  setHasNewPosts,
  removePage,
  storePages,
  updatePage,
} = CommunitiesSlice.actions

export default CommunitiesSlice.reducer

export const selectPages = (state: RootState): Community[] =>
  state.communities.communities ?? []

export const selectHasNewPosts = (state: RootState) =>
  state.communities.hasNewPosts

export const selectPageById = createSelector(
  [selectPages, (_, id = '') => id],
  (pages, id?) => pages.find((page) => page.id === id)
)

export const selectPageByUrl = createSelector(
  [selectPages, (_, url = '') => url],
  (pages, url?) => pages.find((page) => page.communityUrl === url)
)

export const fetchNewCommunityPosts = () => async (dispatch: Dispatch) => {
  const { data = false } = await queryNewCommunityPosts()
  dispatch(setHasNewPosts(data))
  return data
}
