import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import {
  MutationPostCreateArgs,
  MutationPostUpdateArgs,
  MutationPostUpdateScheduledArgs,
  PollInput,
  Post as PostProps,
  Profile,
  Team,
  Upload,
  User,
} from '@sportsyou/api'
import { Colors, POST_COLORS } from '@sportsyou/core'
import { useWindowResize } from '@sportsyou/react-hooks'

import Avatar from '../avatar'
import Button from '../button'
import Checkbox, { CheckboxEvent } from '../checkbox'
import CloseButton from '../close-button'
import Icon, { IconProps } from '../icon'
import Modal from '../modal'
import PollEditor from '../poll-editor'
import TextArea from '../textarea'

import { getBoundingBox } from '../../utils'
import { BORDER_RADIUS } from '../../theme'
import { useDialog } from '../../hooks'

import AttachmentOptions from './components/attachments'
import ColorTextPostInput from './components/color-text-post'
import PostingTo, { PostTarget } from './components/posting-to'
import SchedulePostOptions from './components/schedule-post'
import Step from './components/step'

type BoundingBox = {
  left: number
  top: number
  width: number
}

export type PostType = 'color-text' | 'poll' | undefined

export type PostComposerInitialView = 'attachment' | 'color' | 'media' | 'poll'
export interface InitialPostProps {
  allowComments?: boolean
  color?: string
  id?: string
  message?: string
  poll?: PollInput
  post?: PostProps
  posts?: PostProps[]
  postType?: PostType
  scheduledTime?: Date
  scheduleId?: string
  uploads?: Upload[]
}

export interface PostComposerProps {
  canCreatePoll?: boolean
  className?: string
  currentUser: User
  hidePostTypeButtons?: boolean
  initialPost?: InitialPostProps
  initialUploads?: Upload[]
  isCurrentUserProfile?: boolean
  isModalVisible?: boolean
  maxFiles?: number
  messageContainerStyle?: React.CSSProperties
  onClose?: (post?: InitialPostProps) => void
  onCreatePost: (post: MutationPostCreateArgs) => void
  onUpdatePost: (post: MutationPostUpdateArgs) => void
  onUpdateScheduledPost: (post: MutationPostUpdateScheduledArgs) => void
  placeholder?: string
  postingToClassName?: string
  postingToStyle?: React.CSSProperties
  style?: React.CSSProperties
  teamId?: string
  teams: Team[]
  parent?: React.MutableRefObject<HTMLElement | null>
  parentOffset?: {
    x?: number
    y?: number
  }
  initialView?: PostComposerInitialView
}

const defaultPoll = () => ({
  choices: ['', ''],
  expDate: moment()
    .add(1, 'days')
    .set('hour', 12)
    .set('minutes', 0)
    .toISOString(),
  question: '',
})

export const PostComposer = ({
  canCreatePoll,
  className,
  currentUser,
  hidePostTypeButtons,
  initialPost,
  initialUploads,
  initialView,
  isCurrentUserProfile,
  isModalVisible = false,
  maxFiles,
  messageContainerStyle,
  onClose,
  onCreatePost,
  onUpdatePost,
  onUpdateScheduledPost,
  parent,
  parentOffset = {
    x: 0,
    y: 0,
  },
  placeholder = 'Share a post',
  postingToClassName,
  postingToStyle,
  style,
  teamId,
  teams,
}: PostComposerProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)
  const { width: windowWidth } = useWindowResize()
  const { sendConfirm } = useDialog()

  const [areCommentsDisabled, setAreCommentsDisabled] = useState<boolean>(false)
  const [color, setColor] = useState(
    initialView === 'color' ? POST_COLORS.BLUE : initialPost?.color
  )
  const [isAddMediaVisible, setIsAddMediaVisible] = useState(
    (initialUploads?.length ?? 0) > 0 ||
      initialView === 'media' ||
      initialView === 'attachment'
  )
  const [isNew, setIsNew] = useState(!initialPost)
  const [isPostChanged, setIsPostChanged] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [message, setMessage] = useState(initialPost?.message || '')
  const [poll, setPoll] = useState<PollInput | undefined>(
    initialView === 'poll' ? defaultPoll() : (initialPost?.poll as PollInput)
  )
  const [scheduledTime, setScheduledTime] = useState<Date | undefined>(
    initialPost?.scheduledTime
  )
  const [step, setStep] = useState(1)
  const [targetIds, setTargetIds] = useState<string[]>([])
  const [uploads, setUploads] = useState<Upload[]>(
    initialUploads ?? initialPost?.uploads ?? []
  )

  const isFormValid = useMemo(() => {
    if (isUploading) return false
    if (!isNew && scheduledTime) {
      return moment(scheduledTime) > moment()
    }
    if (step === 1) {
      if (poll) {
        return (
          (poll.question?.length ?? 0) > 0 &&
          (poll.choices?.length ?? 0) >= 2 &&
          moment(poll.expDate).isAfter(moment())
        )
      }
      return !!message?.length || !!uploads.length
    }
    return !!targetIds.length
  }, [
    isNew,
    isUploading,
    message,
    poll,
    scheduledTime,
    step,
    targetIds,
    uploads,
  ])

  const postingToString = useMemo(() => {
    let string = `Posting To`
    if (targetIds.length > 1) {
      string = `${string}: Multiple Feeds`
    } else if (teamId && targetIds.includes(teamId)) {
      const name = teams.filter((team) => team.id === teamId)[0].name
      string = `${string}: ${name}`
    }
    return string
  }, [targetIds, teamId, teams])

  const isSmallScreen = useMemo(() => {
    if (!windowWidth) return window.innerWidth < 640
    return windowWidth < 640
  }, [windowWidth])

  const adminOfTeams = useMemo(() => {
    return teams?.filter((team) => team.isAdmin)
  }, [teams])

  const isAdminOfSomeTeam = useMemo(
    () => !!adminOfTeams?.length,
    [adminOfTeams]
  )

  const isAdminOfSelectedTeam = useMemo(() => {
    if (!teamId) return false
    return adminOfTeams?.filter((team) => team.id === teamId).length > 0
  }, [adminOfTeams, teamId])

  useEffect(() => {
    setUploads(initialUploads || initialPost?.uploads || [])
  }, [initialUploads, initialPost?.uploads])

  useEffect(() => {
    if (initialPost) {
      const now = new Date()
      setAreCommentsDisabled(!initialPost.allowComments)
      setColor(initialPost.color)
      setIsNew(false)
      setIsPostChanged(false)
      setMessage(initialPost.message || '')
      setPoll(initialPost.poll)
      setScheduledTime(
        initialPost.scheduledTime && initialPost.scheduledTime < now
          ? now
          : initialPost.scheduledTime
      )
      setStep(1)
      setTargetIds(
        initialPost.posts?.map((post) => post.team?.id as string) ?? []
      )
      setUploads(
        initialUploads ?? (initialPost.post?.uploads as Upload[]) ?? []
      )
    }
  }, [initialPost, initialUploads])

  useEffect(() => {
    // initial view has changed, update post type state
    if (initialView === 'color') {
      setColor(initialPost?.color ?? POST_COLORS.BLUE)
    } else if (initialView === 'poll') {
      setPoll(initialPost?.poll ?? defaultPoll())
    } else if (initialView === 'attachment' || initialView === 'media') {
      setIsAddMediaVisible(true)
    }
  }, [initialPost?.color, initialPost?.poll, initialView])

  useEffect(() => {
    if (isModalVisible) {
      if (textAreaRef.current) {
        textAreaRef.current.focus()
      }
    }
  }, [isModalVisible])

  const featuredFeeds = useMemo(() => {
    const feeds: Array<PostTarget> = [
      {
        data: currentUser,
        disabled: !!scheduledTime || !!poll,
        label: 'Your Feed',
      },
    ]
    if (teamId) {
      feeds.unshift({
        data: teams.filter((t) => t.id === teamId)[0],
        disabled: false,
      })
    }

    return feeds
  }, [currentUser, poll, scheduledTime, teamId, teams])

  const resetState = useCallback(() => {
    setAreCommentsDisabled(false)
    setColor(undefined)
    setIsAddMediaVisible(false)
    setIsPostChanged(false)
    setMessage('')
    setPoll(undefined)
    setScheduledTime(undefined)
    setStep(1)
    setTargetIds([])
    setUploads([])
  }, [])

  const savePost = useCallback(async () => {
    const _scheduledTime = scheduledTime ?? initialPost?.scheduledTime
    const newPost: MutationPostCreateArgs = {
      allowComments: !areCommentsDisabled,
      color,
      message,
      poll,
      postTypes: [],
      scheduledTime: _scheduledTime ? moment(_scheduledTime).toISOString() : '',
      targetIds,
    }

    if (uploads.length && !color && !poll) {
      newPost.uploads = uploads.map((upload) => upload.id as string)
    }

    if (isNew) {
      onCreatePost(newPost as MutationPostCreateArgs)
    } else {
      if (initialPost?.scheduleId) {
        // update a scheduled post
        onUpdateScheduledPost({
          ...newPost,
          scheduleId: initialPost?.scheduleId,
        } as MutationPostUpdateScheduledArgs)
      } else {
        // update a published post
        const updatePost: MutationPostUpdateArgs = {
          color,
          message,
          postId: initialPost?.id,
        }
        onUpdatePost(updatePost)
      }
    }
    resetState()
  }, [
    areCommentsDisabled,
    color,
    initialPost,
    isNew,
    message,
    onCreatePost,
    onUpdatePost,
    onUpdateScheduledPost,
    poll,
    resetState,
    scheduledTime,
    targetIds,
    uploads,
  ])

  // Dismiss the modal without resetting state
  const handleBack = useCallback(() => {
    onClose?.({
      allowComments: initialPost?.allowComments,
      color,
      id: initialPost?.id,
      message,
      poll: poll as PollInput,
      postType: initialPost?.postType,
      scheduledTime,
      scheduleId: initialPost?.scheduleId,
      uploads: uploads as Upload[],
    })
  }, [
    color,
    initialPost?.allowComments,
    initialPost?.id,
    initialPost?.postType,
    initialPost?.scheduleId,
    message,
    onClose,
    poll,
    scheduledTime,
    uploads,
  ])

  const handleClose = useCallback(() => {
    if (isPostChanged) {
      sendConfirm({
        // onCancel: () => {},
        isDestructive: true,
        onConfirm: () => {
          onClose && onClose()
          resetState()
        },
        message: 'Are you sure you want to discard your changes?',
        title: 'Are you sure?',
      })
    } else {
      onClose?.()
      resetState()
    }
    // Reset
  }, [isPostChanged, onClose, resetState, sendConfirm])

  const handleNext = useCallback(() => {
    if (step === 2) {
      savePost()
    } else {
      setStep(step + 1)
    }
  }, [step, savePost])

  const onClickBackButton = useCallback(() => {
    if (step === 1) {
      handleBack()
    } else {
      setStep(step - 1)
    }
  }, [handleBack, step])

  const onChangeMessage = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setIsPostChanged(true)
      setMessage(e.target.value)
    },
    []
  )

  const onChangeColorTextMessage = useCallback(
    (text: string) => {
      setIsPostChanged(true)
      setMessage(text)
    },
    [setMessage]
  )

  const onClickTogglePostTypeColorTextPost = useCallback(() => {
    setPoll(undefined)
    setColor(
      color
        ? undefined
        : POST_COLORS[Object.keys(POST_COLORS)[0] as keyof typeof POST_COLORS]
    )
    setIsAddMediaVisible(false)
  }, [color])

  const onClickTogglePostTypePoll = useCallback(() => {
    setColor(undefined)
    setPoll(poll ? undefined : defaultPoll())
    setIsAddMediaVisible(false)
  }, [poll])

  const onClickAddMedia = useCallback(() => {
    setColor(undefined)
    setPoll(undefined)
    setIsAddMediaVisible(true)
  }, [])

  const onClickAddFile = useCallback(() => {
    setColor(undefined)
    setPoll(undefined)
    setIsAddMediaVisible(true)
  }, [])

  const handleOnChangeDisableComments = useCallback((event: CheckboxEvent) => {
    setAreCommentsDisabled(event.target.checked)
  }, [])

  const postButtonIconProps: Omit<IconProps, 'name'> = {
    fill: '#90A1B5',
    height: 18,
    width: 18,
    style: { marginRight: 6 },
  }

  const postButtonTextStyles: React.CSSProperties = {
    alignItems: 'center',
    color: '#90a1b5',
    display: 'flex',
    whiteSpace: 'nowrap',
  }

  const renderPostTypeButtons = () => (
    <PostTypesContainer>
      <PostTypeButton
        appearance='minimal'
        onClick={onClickAddMedia}
        textStyle={postButtonTextStyles}
      >
        <Icon {...postButtonIconProps} name='CameraSolid' />
        Add Photo/Video
      </PostTypeButton>
      <PostTypeButton
        appearance='minimal'
        onClick={onClickAddFile}
        textStyle={postButtonTextStyles}
      >
        <Icon {...postButtonIconProps} name='Paperclip' />
        Attach File/Document
      </PostTypeButton>
      {canCreatePoll ? (
        <PostTypeButton
          appearance='minimal'
          data-active={typeof poll !== 'undefined'}
          onClick={onClickTogglePostTypePoll}
          textStyle={{
            ...postButtonTextStyles,
            color:
              typeof poll !== 'undefined'
                ? Colors.HAVELOCK_BLUE
                : postButtonTextStyles.color,
          }}
        >
          <Icon
            {...postButtonIconProps}
            fill={
              typeof poll !== 'undefined'
                ? Colors.HAVELOCK_BLUE
                : postButtonIconProps.fill
            }
            name='Polls'
          />
          {poll ? 'Remove' : 'Create'} Poll
        </PostTypeButton>
      ) : null}
      <PostTypeButton
        appearance='minimal'
        // appearance={color ? 'solid' : 'minimal'}
        data-active={!!color}
        onClick={onClickTogglePostTypeColorTextPost}
        textStyle={{
          ...postButtonTextStyles,
          color: color ? Colors.HAVELOCK_BLUE : postButtonTextStyles.color,
        }}
      >
        <Icon
          {...postButtonIconProps}
          fill={color ? Colors.HAVELOCK_BLUE : postButtonIconProps.fill}
          name='ColorPost'
        />
        Color Text Post
      </PostTypeButton>
    </PostTypesContainer>
  )

  if (parent) {
    const { bottom, left, width } = getBoundingBox(parent)

    return (
      <Container
        id='post-composer'
        className={className}
        position={{ left, top: bottom, width }}
        style={{
          borderRadius: BORDER_RADIUS,
          marginLeft: parentOffset?.x ?? undefined,
          marginTop: parentOffset?.y ?? undefined,
          ...style,
        }}
      >
        <StyledCloseButton
          appearance='circular'
          colorPost={!!color}
          hideBackground
          onClick={handleClose}
          size={24}
        />
        <Step currentStep={step} step={1}>
          {color ? (
            <ColorTextPostInput
              color={color}
              message={message}
              onChangeColor={setColor}
              onChangeMessage={onChangeColorTextMessage}
            />
          ) : (
            <>
              {!poll && (
                <MessageContainer
                  onClick={() => {
                    textAreaRef && textAreaRef.current?.focus()
                  }}
                  style={messageContainerStyle}
                >
                  <MessageAvatar
                    diameter={32}
                    name={currentUser.fullName as string}
                    uri={currentUser.profileImage?.[0]?.viewUrl ?? ''}
                  />
                  <MessageTextArea
                    autoFocus
                    autoGrow
                    disableFocusStyles
                    hideBorder
                    isAddMediaVisible={isAddMediaVisible}
                    maximumRows={6}
                    onChange={onChangeMessage}
                    placeholder={placeholder}
                    ref={textAreaRef}
                    value={message ?? ''}
                  />
                </MessageContainer>
              )}
              {poll && (
                <PollEditor
                  currentUser={currentUser as Profile}
                  onChange={setPoll}
                  poll={poll}
                  style={{ flexGrow: 1 }}
                />
              )}
            </>
          )}
          <AttachmentOptions
            isVisibile={isAddMediaVisible}
            maxFiles={maxFiles}
            setIsUploading={setIsUploading}
            setUploads={setUploads}
            style={{ border: 'none', marginBottom: 0 }}
            uploads={uploads}
            uploadsContainerStyle={{ maxHeight: 140 }}
          />
          {!hidePostTypeButtons &&
          ((!teamId && isAdminOfSomeTeam) ||
            (!!teamId && isAdminOfSelectedTeam)) ? (
            <SchedulePostOptions
              onDateTimeChange={setScheduledTime}
              scheduledTime={scheduledTime}
            />
          ) : null}
          {!hidePostTypeButtons ? renderPostTypeButtons() : null}
        </Step>
        <Step step={2} currentStep={step}>
          <div
            style={{
              alignItems: 'center',
              borderBottom: `1px solid ${Colors.ALTO}`,
              display: 'flex',
              padding: 10,
            }}
          >
            <b>{postingToString}</b>
          </div>
          <PostingTo
            className={postingToClassName}
            currentUser={currentUser}
            featured={featuredFeeds}
            isCurrentUserProfile={isCurrentUserProfile}
            isPoll={!!poll}
            isScheduledPost={!!scheduledTime}
            onChange={setTargetIds}
            style={postingToStyle}
            targetIds={targetIds}
            teamId={teamId}
            teams={teams}
          />
        </Step>
        <FooterContainer>
          {step === 2 ? (
            <StyledCheckbox onChange={handleOnChangeDisableComments}>
              Disable Comments
            </StyledCheckbox>
          ) : null}
          <StepButtons>
            <Button
              appearance='minimal'
              onClick={onClickBackButton}
              variant='alternate'
            >
              Back
            </Button>
            <NextButton
              disabled={!isFormValid}
              loading={isUploading}
              onClick={handleNext}
              variant='primary'
            >
              {step === 2 ? 'Post' : 'Next'}
            </NextButton>
          </StepButtons>
        </FooterContainer>
      </Container>
    )
  }

  return (
    <Modal
      backdropIgnoresClicks
      fullscreen={isSmallScreen}
      onClose={handleBack}
      visible={isModalVisible}
    >
      <Modal.Header>
        <b>{step === 1 ? (isNew ? 'New Post' : 'Edit Post') : 'Posting To'}</b>
      </Modal.Header>
      <Modal.Body
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '450px',
          padding: 0,
        }}
      >
        <Step step={1} currentStep={step}>
          {color ? (
            <ColorTextPostInput
              color={color}
              message={message}
              onChangeColor={setColor}
              onChangeMessage={onChangeColorTextMessage}
            />
          ) : (
            <>
              {!poll && (
                <MessageContainer
                  onClick={() => {
                    textAreaRef && textAreaRef.current?.focus()
                  }}
                >
                  <MessageAvatar
                    diameter={32}
                    name={currentUser.fullName as string}
                    uri={currentUser.profileImage?.[0]?.viewUrl ?? ''}
                  />
                  <MessageTextArea
                    autoFocus
                    autoGrow
                    disableFocusStyles
                    hideBorder
                    isAddMediaVisible={isAddMediaVisible}
                    maximumRows={6}
                    onChange={onChangeMessage}
                    placeholder={placeholder}
                    ref={textAreaRef}
                    value={message ?? ''}
                  />
                </MessageContainer>
              )}
              {poll && (
                <PollEditor
                  currentUser={currentUser as Profile}
                  onChange={setPoll}
                  poll={poll}
                />
              )}
            </>
          )}

          <AttachmentOptions
            isVisibile={isAddMediaVisible}
            maxFiles={maxFiles}
            setIsUploading={setIsUploading}
            setUploads={setUploads}
            style={{ border: 'none', marginBottom: 0 }}
            uploads={uploads}
          />
          {!hidePostTypeButtons ? (
            <>
              <SchedulePostOptions
                scheduledTime={scheduledTime}
                onDateTimeChange={setScheduledTime}
              />
              {renderPostTypeButtons()}
            </>
          ) : null}
        </Step>
        <Step step={2} currentStep={step}>
          <PostingTo
            className={postingToClassName}
            currentUser={currentUser}
            featured={featuredFeeds}
            isScheduledPost={!!scheduledTime}
            onChange={setTargetIds}
            style={postingToStyle}
            targetIds={targetIds}
            teamId={teamId}
            teams={teams}
          />
        </Step>
      </Modal.Body>
      <Modal.Footer style={{ paddingBottom: 0, paddingTop: 0 }}>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
          }}
        >
          {step === 2 && (
            <StyledCheckbox onChange={handleOnChangeDisableComments}>
              Disable Comments
            </StyledCheckbox>
          )}

          <StepButtons>
            <Button
              appearance='minimal'
              onClick={onClickBackButton}
              variant='alternate'
            >
              Back
            </Button>
            <NextButton
              disabled={!isFormValid}
              onClick={handleNext}
              variant='primary'
            >
              {step === 2 ? 'Post' : 'Next'}
            </NextButton>
          </StepButtons>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

const Container = styled.div<{ position: BoundingBox }>`
  border-radius: ${BORDER_RADIUS};
  border: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  top: ${({ position }) => position.top}px;
  left: ${({ position }) => position.left}px;
  position: absolute;
  height: 400px;
  max-height: 90vh;
  overflow: hidden;
  transition: all 300ms ease-in-out;
  width: ${({ position }) => position.width}px;
  z-index: 10;
  background-color: ${Colors.WHITE};
`

const MessageContainer = styled.div`
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex: 1 1 240px;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
`
const MessageAvatar = styled(Avatar)`
  position: absolute;
`
const MessageTextArea = styled(TextArea)<{ isAddMediaVisible: boolean }>`
  margin-left: 40px;
  width: 100%;

  ${({ isAddMediaVisible }) =>
    !isAddMediaVisible &&
    css`
      textarea {
        min-height: 300px;
      }
    `}
`
const PostTypesContainer = styled.div`
  border-top: 1px solid ${Colors.ALTO};
  display: flex;
  justify-content: space-between;
  padding: 10px;
  @media all and (max-width: 639px) {
    flex-wrap: wrap;
    padding: 2px;
  }
`
const PostTypeButton = styled(Button)`
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  @media all and (max-width: 639px) {
    border-radius: 0;
    flex: 1 0 50%;
  }
`

const FooterContainer = styled.div`
  align-items: center;
  border-top: 1px solid ${Colors.ALTO};
  display: flex;
  padding: 6px 10px;
  @media all and (min-width: 640px) {
    padding: 10px;
  }
`

const StepButtons = styled.div`
  align-items: center;
  display: flex;
  margin-left: auto;
`
const StyledCheckbox = styled(Checkbox)`
  justify-self: flex-start;
  & > span {
    font-size: 13px;
    font-weight: 500;
  }
`
const NextButton = styled(Button)`
  margin-left: 6px;
`

const StyledCloseButton = styled(CloseButton)<{ colorPost: boolean }>`
  && {
    ${({ colorPost }) => colorPost && `color: ${Colors.WHITE};`}
    box-sizing: border-box;
    position: absolute;
    right: ${({ colorPost }) => (colorPost ? '14px' : '8px')};
    top: 8px;
    z-index: 10;
  }
`

export default PostComposer

// PostComposer.whyDidYouRender = true
