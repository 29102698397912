import { useEffect, useState } from 'react'

const useMediaQuery = (query: string) => {
  const mQuery = window.matchMedia(query)

  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(mQuery.matches)

  useEffect(() => {
    mQuery.addEventListener('change', (e) => setIsSmallScreen(e.matches))

    return () => {
      mQuery.removeEventListener('change', (e) => setIsSmallScreen(e.matches))
    }
  }, [mQuery])

  return isSmallScreen
}

export default useMediaQuery
