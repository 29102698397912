// TODO Add ICON support
// TODO Add HEADER component to dropdown

import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'
import { useClickAway } from '@sportsyou/react-hooks'

import type { Placement } from '../../utils/prop-types'

import {
  //  DropdownConfig,
  DropdownContext,
} from './dropdown-context'

import DropdownMenu from './dropdown-menu'
import DropdownToggle from './dropdown-toggle'

export interface DropdownProps {
  children?: React.ReactNode
  /** Color of default chevron for dropdown menu */
  chevronColor?: string
  /** Size of default chevron for dropdown menu */
  chevronSize?: number
  chevronStyle?: React.CSSProperties
  /** Custom className for dropdown menu */
  className?: string
  /** Whether the dropdown button is disabled or not */
  disabled?: boolean
  /** Removes button padding */
  collapse?: boolean
  /** Removed Chevron from toggle button */
  hideChevron?: boolean
  /** Whether the menu should be visible upon mount */
  initialVisible?: boolean
  itemStyle?: React.CSSProperties
  /** Activates a loading state for the toggle button */
  loading?: boolean
  maxHeight?: number
  /** How far (in px) the menu will be from the toggle button */
  menuOffset?: number
  menuStyles?: React.CSSProperties
  /** Minimum width for the menu */
  menuWidth?: string
  onClick?: (event: React.MouseEvent) => void
  onClose?: () => void
  onOpen?: () => void
  /** Menu placement based on the toggle button */
  placement?: Placement
  /** Attempts to reposition menu if it is rendered horizontally offscreen */
  preventMenuClipping?: boolean
  /** Text alignment for menu items */
  textAlign?: 'left' | 'center' | 'right'
  /** Title of the toggle button for the dropdown */
  title?: string | React.ReactNode
  triggerStyle?: React.CSSProperties
  visible?: boolean
  id?: string
  name?: string
  testId?: string
}

// const stopPropagation = (event: React.MouseEvent<HTMLDivElement>) => {
//   event.stopPropagation()
//   // event.nativeEvent.stopImmediatePropagation()
// }

/**
 * A dropdown list of navigation items
 *
 * ```tsx
 * import { Button, Dropdown } from '@mmrdigital/react-dom-ui'
 *
 * const Element = () => {
 *   return (
 *     <Dropdown
 *       collapse={false}
 *       disabled={false}
 *       hideChevron={false}
 *       loading={false}
 *       title={'Menu'}
 *     >
 *       <Dropdown.Toggle>
 *         <Button appearance='solid'>Custom Toggle</Button>
 *       </Dropdown.Toggle>
 *       <Dropdown.Header>Header</Dropdown.Header>
 *       <Dropdown.Item>Foo</Dropdown.Item>
 *       <Dropdown.Item>Bar</Dropdown.Item>
 *       <Dropdown.Divider />
 *       <Dropdown.Item>Baz</Dropdown.Item>
 *     </Dropdown>
 *   )
 * }
```
 */
export const Dropdown: FC<PropsWithChildren<DropdownProps>> = ({
  chevronColor = Colors.MINE_SHAFT,
  chevronSize = 14,
  chevronStyle,
  children,
  className = '',
  collapse = false,
  disabled = false,
  hideChevron = false,
  initialVisible = false,
  itemStyle,
  loading = false,
  maxHeight,
  menuOffset = 4,
  menuStyles,
  menuWidth,
  onClick,
  onClose,
  onOpen,
  placement = 'bottomStart',
  preventMenuClipping = true,
  testId,
  textAlign = 'left',
  title = 'Dropdown',
  triggerStyle,
  visible: customVisible = false,
  ...props
}: DropdownProps): React.ReactElement => {
  const ref = useRef<HTMLDivElement>(null)

  const [isVisible, setIsVisible] = useState<boolean>(initialVisible)

  useEffect(() => {
    if (isVisible) {
      onOpen?.()
    } else {
      onClose?.()
    }
  }, [isVisible])

  useEffect(() => {
    setIsVisible(customVisible)
  }, [customVisible])

  // Hide menu when a child item is clicked
  const onChildClick = () => {
    setIsVisible(false)
  }

  const handleOnClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event?.preventDefault?.()
      event?.stopPropagation?.()
      if (disabled || loading) return
      onClick?.(event)
      setIsVisible(!isVisible)
    },
    [disabled, isVisible, loading, onClick]
  )
  const customToggleNode = (useMemo(
    () =>
      React.Children.map(children, (item) => {
        if (!React.isValidElement(item)) return null

        if (item.type === DropdownToggle) {
          if (!item.props.children) {
            console.warn(
              '[Dropdown Toggle]: Could not find a proper HTML element to render as custom toggle'
            )
            return null
          }
          if (item.props.children.type === React.Fragment) {
            console.warn(
              '[Dropdown Toggle]: React.Fragments can not be used as a child, please use valid HTML elements'
            )
            return null
          }
          if (item.props.children.length > 1) {
            console.warn(
              '[Dropdown Toggle]: Found multiple children for dropdown toggle. Please make sure a single child is passed to this component'
            )
            return null
          }

          // attach onClick handler
          return React.cloneElement(item.props.children, {
            ...item.props.children.props,
            onClick: (event: React.MouseEvent<HTMLDivElement>) => {
              item.props.children.props.onClick &&
                item.props.children.props.onClick()
              handleOnClick(event)
            },
          })
        }
        return null
      }),
    [children, handleOnClick]
  ) ?? [])[0]

  const hideMenu = () => {
    // if (isVisible) return
    setIsVisible(false)
  }

  useClickAway(ref, () => hideMenu())

  const initialValue = {
    // disabled: false,
    loading,
    onItemClick: onChildClick,
    title,
    disabled,
  }

  const DropdownToggleProps = {
    chevronColor,
    chevronSize,
    chevronStyle,
    collapse,
    customToggle: customToggleNode,
    disabled,
    hideChevron,
    initialVisible,
    loading,
    onClick: handleOnClick,
    title,
    style: triggerStyle,
    visible: isVisible,
  }

  // const getChildrenFromNode = (item: React.ReactNode) => {
  //   if (!React.isValidElement(item)) return null
  //   console.log({ item })
  //   const x = React.cloneElement(item.props.children, {
  //     onClick: handleOnClick,
  //   })
  //   console.log({ x })
  //   return item.props.children
  // }
  // const getPropsFromNode = (item: React.ReactNode) => {
  //   if (!React.isValidElement(item)) return null

  //   return Object.fromEntries(
  //     Object.entries(item.props).filter(([key, _]) => key !== 'children')
  //   )
  // }

  const childrenWithOutToggle = useMemo(
    () =>
      React.Children.map(children, (item) => {
        if (!React.isValidElement(item)) return null
        if (item.type === DropdownToggle) return null
        return item
      }),
    [children]
  )

  return (
    <DropdownContext.Provider value={initialValue}>
      <Container
        data-testid={testId}
        {...props}
        className={className}
        // onClick={stopPropagation}
        ref={ref}
      >
        <DropdownToggle {...DropdownToggleProps} />

        {isVisible && !loading && (
          <DropdownMenu
            itemStyle={itemStyle}
            maxHeight={maxHeight}
            menuWidth={menuWidth}
            offset={menuOffset}
            placement={placement}
            preventClipping={preventMenuClipping}
            style={menuStyles}
            textAlign={textAlign}
          >
            {childrenWithOutToggle}
          </DropdownMenu>
        )}
      </Container>
    </DropdownContext.Provider>
  )
}

const Container = styled.div`
  display: inline-flex;
  position: relative;
  & + & {
    margin-left: 6px;
  }
`

Dropdown.displayName = 'Dropdown'

export default Dropdown
