import * as React from 'react'
import type { SVGProps } from 'react'
const PostFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <g>
      <path d='M26.87 13.68a5.59 5.59 0 0 1 2 .38V4.64A3.8 3.8 0 0 0 25.12.79H3.73A3.8 3.8 0 0 0 0 4.64v19.57a3.8 3.8 0 0 0 3.73 3.86h10.74v-2a5.42 5.42 0 0 1 1.6-3.87L23 15.28a5.42 5.42 0 0 1 3.87-1.6zm-14.8 8.1H7.35a1.06 1.06 0 0 1 0-2.12h4.72a1.06 1.06 0 1 1 0 2.12zm7.86-6.29H7.35a1.06 1.06 0 1 1 0-2.12h12.58a1.06 1.06 0 0 1 0 2.12zm0-6.29H10.5a1.06 1.06 0 1 1 0-2.12h9.43a1.06 1.06 0 0 1 0 2.12z' />
      <path d='M31.23 19.78l-2.5-2.5a2.58 2.58 0 0 0-1.86-.77 2.65 2.65 0 0 0-1.86.77l-6.94 6.94a2.62 2.62 0 0 0-.77 1.87v4.06a1.05 1.05 0 0 0 1.06 1.06h4.06a2.62 2.62 0 0 0 1.87-.77l6.94-6.94a2.61 2.61 0 0 0 .77-1.86 2.58 2.58 0 0 0-.77-1.86zM29.73 22l-6.94 6.94a.51.51 0 0 1-.37.15h-3v-3a.51.51 0 0 1 .15-.37l6.94-6.94a.51.51 0 0 1 .36-.15.51.51 0 0 1 .36.15l2.5 2.5a.51.51 0 0 1 0 .72z' />
    </g>
  </svg>
)
export default PostFilled
