import Dropdown from './dropdown'

import DropdownDivider from './dropdown-divider'
import DropdownHeader from './dropdown-header'
import DropdownItem from './dropdown-item'
import DropdownToggle from './dropdown-toggle'

export type DropdownType = typeof Dropdown & {
  Divider: typeof DropdownDivider
  Header: typeof DropdownHeader
  Item: typeof DropdownItem
  Toggle: typeof DropdownToggle
}
;(Dropdown as DropdownType).Divider = DropdownDivider
;(Dropdown as DropdownType).Header = DropdownHeader
;(Dropdown as DropdownType).Item = DropdownItem
;(Dropdown as DropdownType).Toggle = DropdownToggle

export type { DropdownProps } from './dropdown'
export type { DropdownHeaderProps } from './dropdown-header'
export type { DropdownItemProps } from './dropdown-item'

export default Dropdown as DropdownType
