import * as React from 'react'
import type { SVGProps } from 'react'
const Windows = (props: SVGProps<SVGSVGElement>) => (
  <svg
    stroke='currentColor'
    strokeWidth={0}
    viewBox='0 0 16 16'
    height='1em'
    width='1em'
    xmlns='http://www.w3.org/2000/svg'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M6.555 1.375 0 2.237v5.45h6.555V1.375zM0 13.795l6.555.933V8.313H0v5.482zm7.278-5.4.026 6.378L16 16V8.395H7.278zM16 0 7.33 1.244v6.414H16V0z' />
  </svg>
)
export default Windows
