import { Helmet } from 'react-helmet-async'
import { useEffect, useMemo } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'
import { Button, Icon, Tabs } from '@sportsyou/react-dom-ui'

import {
  ContentContainer,
  HeaderTitleChevron,
} from '../../../styles/global-styles'
import PagesProfilePostList from './PostList'
import PagesProfileSettings from './Settings'
import usePagesPortal from '../UsePagesPortal'

export type PagesProfileTab = 'posts' | 'settings'

interface PagesProfileProps {
  communityId?: string
  tab?: PagesProfileTab
}

export default function PagesProfile(props: PagesProfileProps) {
  const navigate = useNavigate()
  const params = useParams()

  const { fetchPage, isLoading, page } = usePagesPortal({
    communityId: params.id,
  })

  const currentTab = useMemo(() => {
    if (props.tab === 'settings') {
      return 'settings'
    }
    return 'posts'
  }, [props.tab])

  useEffect(() => {
    fetchPage(params.id as string)
  }, [fetchPage, params.id])

  useEffect(() => {
    // If the user is not an admin, moderator or approver, redirect to the content portal
    if (
      page.id &&
      !page.isAdmin &&
      !page.isModerator &&
      !page.isApprover &&
      !isLoading
    ) {
      navigate(`/pages`)
      console.warn(
        'User is not an admin, moderator or approver. Redirecting.',
        { page }
      )
    }
  }, [navigate, page, isLoading])

  return (
    <ContentContainer>
      <Helmet>
        <title>{page.name}</title>
      </Helmet>
      <Header>
        <HeaderTitle>
          <Link to={'/pages'} title='Pages'>
            <Icon name='Home' fill={Colors.BLACK} width={18} />
          </Link>
          <span>
            <HeaderTitleChevron />
            {page.name}
          </span>
        </HeaderTitle>
      </Header>

      <Tabs
        contentStyle={tabsContentStyle}
        index={currentTab === 'settings' ? 1 : 0}
      >
        <Tabs.Item
          onClick={() => navigate(`/pages/${params.id}/posts`)}
          title='Posts'
        >
          <PagesProfilePostList page={page} />
        </Tabs.Item>

        {page.isAdmin ? (
          <Tabs.Item
            title='Page Settings'
            onClick={() => navigate(`/pages/${params.id}/settings`)}
          >
            <PagesProfileSettings page={page} />
          </Tabs.Item>
        ) : null}

        <Tabs.ExtraContent>
          {currentTab === 'posts' && page.canPost ? (
            <Button onClick={() => navigate(`/pages/${page.id}/posts/new`)}>
              <ButtonIcon name='Plus' size={10} fill={Colors.WHITE} />
              Create a Post
            </Button>
          ) : null}
        </Tabs.ExtraContent>
      </Tabs>
    </ContentContainer>
  )
}

const tabsContentStyle = {
  padding: '20px 0',
}

export const Header = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  z-index: 10;
  background-color: #fff;
`

export const HeaderTitle = styled.div`
  align-items: center;
  color: ${Colors.BLACK};
  display: flex;
  flex-direction: row;
  font-weight: bold;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

const ButtonIcon = styled(Icon)`
  margin-right: 5px;
`
