import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { Event as ApiEventProps } from '@sportsyou/api'
import { Colors } from '@sportsyou/core'
import { useDrag } from 'react-dnd'
import moment from 'moment'

export interface EventChipShape {
  continuedFromPrevious: boolean
  continuesToNext: boolean
  daysLong: number
  event: ApiEventProps
  left: number
  multiday: boolean
  row?: number
  top: number
  weekEndDay: number
  weekStartDay: number
  width: number
}

interface Props {
  event: ApiEventProps
  fill: string
  fillWithGameType?: boolean
  onClick?: (event: ApiEventProps, e: React.MouseEvent<HTMLDivElement>) => void
  shape?: EventChipShape
  shouldTriggerClickOnMount?: boolean
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type EventChipProps = Props & NativeAttributes

export const EventChip: React.FC<EventChipProps> = ({
  event,
  fill = Colors.HAVELOCK_BLUE,
  fillWithGameType,
  onClick,
  shape,
  shouldTriggerClickOnMount,
}: EventChipProps) => {
  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: 'EVENT',
    item: event,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }))
  const chipRef = useRef<HTMLDivElement | null>(null)

  const fillColor = useMemo(() => {
    if (fillWithGameType) {
      if (event.type === 'game') {
        return Colors.PUNCH
      } else {
        return Colors.HAVELOCK_BLUE
      }
    } else {
      return fill
    }
  }, [event.type, fill, fillWithGameType])

  const handleOnClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      onClick?.(event, e)
    },
    [event, onClick]
  )

  useEffect(() => {
    function onChangeEvent(ev: Event) {
      //@ts-ignore
      handleOnClick(ev)
    }
    chipRef.current?.addEventListener('change', onChangeEvent)
    return () => {
      chipRef.current?.removeEventListener('change', onChangeEvent)
    }
  }, [handleOnClick])

  useEffect(() => {
    if (shouldTriggerClickOnMount) {
      setTimeout(() => {
        chipRef.current?.click()
      }, 100)
    }
  }, [shouldTriggerClickOnMount])

  return (
    <Container
      continuedFromPrevious={shape?.continuedFromPrevious}
      continuesToNext={shape?.continuesToNext}
      fill={fillColor}
      isDragging={isDragging}
      onClick={handleOnClick}
      ref={(el: any) => {
        chipRef.current = el
        event.isEditable && dragRef(el)
      }}
      style={{
        boxSizing: 'content-box',
        width: shape?.width ?? '-webkit-fill-available',
        left: shape?.left,
        top: shape?.top,
      }}
    >
      {!event.allDay && moment(event.startDate).format('h:mma')} {event.title}
    </Container>
  )
}

const Container = styled.div<{
  continuedFromPrevious?: boolean
  continuesToNext?: boolean
  fill: string
  isDragging: boolean
}>`
  background-color: ${({ fill }) => fill};
  border-radius: 4px;
  color: ${Colors.WHITE};
  cursor: pointer;
  font-size: 13px;
  height: 15px;
  line-height: 15px;
  margin-bottom: 1px;
  opacity: ${({ isDragging }) => (isDragging ? 0.4 : 1)};
  overflow: hidden;
  padding: 0 0 0 03px;
  position: absolute;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
  opacity: 0.85;

  ${({ continuedFromPrevious }) =>
    continuedFromPrevious &&
    `
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  `}

  ${({ continuesToNext }) =>
    continuesToNext &&
    `
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  `}

  &:hover {
    /* box-shadow: 1px 2px 2px 0px ${Colors.BLACK}; */
    opacity: 1;
  }
`

export default EventChip
