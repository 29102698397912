import styled from 'styled-components'

export const FadeInContainer = styled.div<{
  /** Duration (in seconds) it takes to fade in */
  fadeInSpeed?: number
}>`
  animation: fadein ${({ fadeInSpeed }) => fadeInSpeed || 0.2}s;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const Container = styled(FadeInContainer)``

export default Container
