import * as React from 'react'
import type { SVGProps } from 'react'
const NavJersey = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M27.81 4.18l-4.17-1.94a2.65 2.65 0 0 0-2.72.3L16 6.31l-4.76-3.64a3 3 0 0 0-3.07-.34l-4 1.85a5.4 5.4 0 0 0-3.1 4.89v4.07a2.45 2.45 0 0 0 2.44 2.45h3.43v12A2.45 2.45 0 0 0 9.39 30h13.22a2.45 2.45 0 0 0 2.45-2.45v-12h3.43a2.45 2.45 0 0 0 2.44-2.45V9.07a5.4 5.4 0 0 0-3.12-4.89zM23.1 23.6H8.9V20h14.2zm-.49 4.45H9.39a.49.49 0 0 1-.49-.5v-2h14.2v2a.49.49 0 0 1-.49.5zM29 13.14a.49.49 0 0 1-.49.49h-3.45v-2.16a1 1 0 1 0-2 0V18H8.9v-6.53a1 1 0 0 0-2 0v2.17H3.51a.49.49 0 0 1-.51-.5V9.07A3.45 3.45 0 0 1 5 6l4-1.89a1.06 1.06 0 0 1 1.07.11l5.35 4.1a1 1 0 0 0 1.18 0l5.52-4.23a.68.68 0 0 1 .7-.07L27 6a3.45 3.45 0 0 1 2 3.12z' />
  </svg>
)
export default NavJersey
