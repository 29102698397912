import { useEffect, useRef, useState } from 'react'
import { cloneDeep } from 'lodash'
import {
  MutationUploadDeleteRequest,
  mutationUploadDelete,
} from '@sportsyou/api'
import { ExtendedUpload } from '../definitions'
import { useFetchApi } from '../use-fetch-api'

interface ExtendedUseUpload extends ExtendedUpload {
  deleted?: boolean
  existingUpload?: boolean
  markedForDeletion?: boolean
  newUpload?: boolean
}

export interface UseUploadsProps {
  uploads?: ExtendedUseUpload[]
}

export function useUploads(props?: UseUploadsProps) {
  const [existingUploads, setExistingUploads] = useState<ExtendedUseUpload[]>(
    []
  )
  const [newUploads, setNewUploads] = useState<ExtendedUseUpload[]>([])
  const [deletedUploads, setDeletedUploads] = useState<ExtendedUseUpload[]>([])
  const [allUploads, setAllUploads] = useState<ExtendedUseUpload[]>([])

  const { fetch: uploadDelete } = useFetchApi(mutationUploadDelete)

  const localUploads = useRef<ExtendedUseUpload[]>([])

  useEffect(() => {
    if (props?.uploads?.length) {
      const uploads = cloneDeep(props?.uploads).map((up) => ({
        ...up,
        existingUpload: true,
      }))
      setExistingUploads(uploads)
    }
  }, [props?.uploads])

  useEffect(() => {
    console.log({ existingUploads })
  }, [existingUploads])

  useEffect(() => {
    console.log({ newUploads })
  }, [newUploads])

  useEffect(() => {
    console.log({ deletedUploads })
  }, [deletedUploads])

  useEffect(() => {
    console.log({ allUploads })
  }, [allUploads])

  function categorizeUploads() {
    setNewUploads(
      localUploads.current.filter(
        (up) => up.newUpload && !(up.deleted || up.markedForDeletion)
      )
    )
    setDeletedUploads(
      localUploads.current.filter(
        (up) => !up.newUpload && (up.markedForDeletion || up.deleted)
      )
    )
    setAllUploads(
      localUploads.current.filter(
        (up) =>
          (up.existingUpload || up.newUpload) &&
          !(up.markedForDeletion || up.deleted)
      )
    )
  }

  function initializeExistingUploads(uploads: ExtendedUseUpload[]) {
    if (!localUploads.current.length) {
      localUploads.current = cloneDeep(uploads).map((up) => ({
        ...up,
        existingUpload: true,
      }))
      setExistingUploads(localUploads.current.filter((up) => up.existingUpload))
      categorizeUploads()
    }
  }

  function resetExistingUploads(uploads: ExtendedUseUpload[]) {
    localUploads.current = cloneDeep(uploads).map((up) => ({
      ...up,
      existingUpload: true,
    }))
    setExistingUploads(localUploads.current.filter((up) => up.existingUpload))
    categorizeUploads()
  }

  function resetUploads() {
    localUploads.current = []
    categorizeUploads()
  }

  function addNewUploads(uploads: ExtendedUseUpload[]) {
    const newUploadIds = uploads.map((up) => up.id!)
    const dupeIds = localUploads.current
      .filter((up) => newUploadIds.includes(up.id!))
      .map((up) => up.id!)
    if (dupeIds.length) {
      const dupesRemoved = localUploads.current.filter(
        (up) => !newUploadIds.includes(up.id!)
      )
      localUploads.current = [
        ...dupesRemoved,
        ...cloneDeep(uploads).map((up) => ({ ...up, newUpload: true })),
      ]
    } else {
      localUploads.current.push(
        ...cloneDeep(uploads).map((up) => ({ ...up, newUpload: true }))
      )
    }
    categorizeUploads()
  }

  async function removeUpload(uploadId: string) {
    const upload = localUploads.current.find((up) => up.id === uploadId)
    if (upload?.newUpload) {
      await deleteUpload(uploadId)
    } else if (upload?.existingUpload) {
      markUploadToBeDeleted(uploadId)
    }
  }

  async function removeUploads(uploadIds: string[]) {
    const uploads = localUploads.current.filter((up) =>
      uploadIds.includes(up.id!)
    )
    const newUploadIds = uploads
      .filter((up) => up.id && up.newUpload)
      .map((up) => up.id!)
    const existingUploadIds = uploads
      .filter((up) => up.id && up.existingUpload)
      .map((up) => up.id!)
    const promises = []

    newUploadIds.length && promises.push(deleteUploads(newUploadIds))
    existingUploadIds.length &&
      promises.push(markUploadsToBeDeleted(newUploadIds))

    return Promise.allSettled(promises)
  }

  async function deleteUpload(uploadId: string) {
    await uploadDelete({ id: uploadId } as MutationUploadDeleteRequest)
    markUploadDeleted(uploadId)
  }

  async function deleteUploads(uploadIds: string[]) {
    const promises = []
    for (const uploadId of uploadIds) {
      promises.push(
        uploadDelete({ id: uploadId } as MutationUploadDeleteRequest)
      )
      markUploadDeleted(uploadId)
    }

    return Promise.allSettled(promises)
  }

  function markUploadToBeDeleted(uploadId: string) {
    const upload = localUploads.current.find((up) => up.id === uploadId)
    if (upload) {
      const otherUploads = localUploads.current.filter(
        (up) => up.id !== uploadId
      )
      const _upload = cloneDeep(upload)
      _upload.markedForDeletion = true
      localUploads.current = [...otherUploads, _upload]
      categorizeUploads()
    }
  }

  function markUploadsToBeDeleted(uploadIds: string[]) {
    const uploads = localUploads.current.filter((up) =>
      uploadIds.includes(up.id!)
    )
    if (uploads.length) {
      const otherUploads = localUploads.current.filter(
        (up) => !uploadIds.includes(up.id!)
      )
      const _uploads = cloneDeep(uploads).map((up) => ({
        ...up,
        markedForDeletion: true,
      }))
      localUploads.current = [...otherUploads, ..._uploads]
      categorizeUploads()
    }
  }

  function markUploadDeleted(uploadId: string) {
    const upload = localUploads.current.find((up) => up.id === uploadId)
    if (upload?.id === uploadId) {
      const _upload = cloneDeep(upload)
      const otherUploads = localUploads.current.filter(
        (up) => up.id !== uploadId
      )
      _upload.deleted = true
      localUploads.current = [...otherUploads, _upload]
      categorizeUploads()
    }
  }

  async function deleteAllMarkedUploads() {
    const promises = []
    for (const upload of deletedUploads) {
      if (upload.id && !upload.deleted && upload.markedForDeletion) {
        promises.push(
          uploadDelete({ id: upload.id } as MutationUploadDeleteRequest)
        )
        markUploadDeleted(upload.id)
      }
    }

    return Promise.allSettled(promises)
  }

  return {
    addNewUploads,
    allUploads,
    deleteAllMarkedUploads,
    deletedUploads,
    existingUploads,
    initializeExistingUploads,
    newUploads,
    removeUpload,
    removeUploads,
    resetExistingUploads,
    resetUploads,
  }
}

export default useUploads
