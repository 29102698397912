import React, { FC, PropsWithChildren } from 'react'
import styled from 'styled-components'

interface Props {
  className?: string
  style?: React.CSSProperties
}

type NativeAttributes = Omit<React.HTMLAttributes<HTMLElement>, keyof Props>
export type CardBodyProps = Props & NativeAttributes

export const CardBody: FC<PropsWithChildren<CardBodyProps>> = ({
  children,
  className,
  style,
}: CardBodyProps) => {
  return (
    <Body className={className} style={style}>
      {children}
    </Body>
  )
}

const Body = styled.div`
  font-family: inherit;
  font-size: 14px;
  padding: 16px 12px;
`

export default CardBody
