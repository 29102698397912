import ReactTextFormat from 'react-text-format' // https://github.com/react-ninja/react-text-format
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'

export interface FormattedTextProps {
  allowedFormats?: string[]
  children?: React.ReactNode
  className?: string
  linkTarget?: string
  style?: React.CSSProperties
  linkColor?: string
  linkClassName?: string
  linkStyle?: React.CSSProperties
}

export const FormattedText = (props: FormattedTextProps) => {
  let { children } = props

  if (!children) {
    return null
  }

  if (typeof children === 'string') {
    children = children
      .split(' ')
      .map((seg) => {
        try {
          decodeURIComponent(seg)
          return seg
        } catch (error) {
          return encodeURIComponent(seg)
        }
      })
      .join(' ')
  }

  return (
    <ReactTextFormatContainer
      className={props.className}
      linkColor={props.linkColor}
      style={props.style}
    >
      <ReactTextFormat
        allowedFormats={['URL', 'Email', 'Phone']}
        linkTarget={props.linkTarget || '_blank'}
        className={props.linkClassName}
        style={props.linkStyle}
        {...props}
      >
        {children}
      </ReactTextFormat>
    </ReactTextFormatContainer>
  )
}

const ReactTextFormatContainer = styled.span<{ linkColor?: string }>`
  white-space: pre-wrap;
  a {
    color: ${({ linkColor }) => linkColor ?? Colors.HAVELOCK_BLUE};
    padding: 0 2px;
    text-decoration: underline;
  }
`

export default FormattedText
