import * as React from 'react'
import type { SVGProps } from 'react'
const Users = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M25.76 17.6a3.83 3.83 0 1 0-3.82-3.83 3.83 3.83 0 0 0 3.82 3.83zm0-6.07a2.25 2.25 0 1 1-2.25 2.24 2.24 2.24 0 0 1 2.25-2.24zm-9.83 4.1a5.46 5.46 0 1 0-5.46-5.46 5.47 5.47 0 0 0 5.46 5.46zm0-9.34A3.89 3.89 0 1 1 12 10.17a3.88 3.88 0 0 1 3.93-3.88zM6.25 17.6a3.83 3.83 0 1 0-3.83-3.83 3.83 3.83 0 0 0 3.83 3.83zm0-6.07A2.25 2.25 0 1 1 4 13.77a2.24 2.24 0 0 1 2.25-2.24zm21.21 8.12h-1.2a.79.79 0 0 0 0 1.58h1.2a3 3 0 0 1 3 3v1.65a.79.79 0 1 0 1.57 0v-1.69a4.55 4.55 0 0 0-4.57-4.54zm-21.72 0h-1.2A4.55 4.55 0 0 0 0 24.19v1.65a.79.79 0 0 0 .79.79.79.79 0 0 0 .78-.79v-1.65a3 3 0 0 1 3-3h1.2a.79.79 0 0 0 0-1.58zm12.94-1.59h-5.51a6 6 0 0 0-6 6v2.4a.79.79 0 1 0 1.57 0V24.1a4.47 4.47 0 0 1 4.47-4.46h5.51a4.47 4.47 0 0 1 4.47 4.46v2.4a.79.79 0 1 0 1.57 0v-2.4a6 6 0 0 0-6.08-6.04z' />
  </svg>
)
export default Users
