export const getInitialsFromName = (name: string): string => {
  const DEFAULT_AVATAR_INITIALS = '  '
  let nameInitials = DEFAULT_AVATAR_INITIALS

  if (name) {
    const nameSplit = name
      .replace('The ', '')
      .replace(' and ', ' ')
      .toLowerCase()
      .split(' ')
      .filter((n) => !!n) // remove 'the' in the beginning of the name and 'and' connector, split each word into an array and remove empty string from the array (mostly resulting from double space between words)
    // TODO: should we do initial from first 2 words or from first and last word?
    const names = nameSplit.slice(0, 2) // take first 2 words from the result
    const codePoints = names.map((n) => n.codePointAt(0)) // convert first letter from each word to code point to be unicode/emoji safe
    nameInitials = codePoints
      //@ts-ignore
      .map((c) => String.fromCodePoint(c))
      .join('')
      .toUpperCase() // convert code point back to proper character, join them to make initials and upper case any alphabets
    nameInitials = nameInitials || DEFAULT_AVATAR_INITIALS // default initial would happen if the name consists of any combination of 'The ' and ' and '
  }
  return nameInitials
}
