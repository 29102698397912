import { ActivityTypes, StatusTypes, VariantTypes } from '../utils/prop-types'

export * from './style'

export type Palette = {
  primary: string
  content_background: string
  site_background: string
  nav: string
  border: string
  text: string
  text_anchor: string
  text_placeholder: string
  activity?: { [K in ActivityTypes]: string }
  status?: { [K in StatusTypes]: string }
  variant?: { [K in VariantTypes]: string }
}

export type Theme = {
  displayName: string
  key: string
  palette: Palette
}
