import * as React from 'react'
import type { SVGProps } from 'react'
const ShareIos = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <g>
      <path d='M25.91 11.61h-3.26a1.11 1.11 0 0 0 0 2.22H26A2.2 2.2 0 0 1 28.1 16v11.59a2.19 2.19 0 0 1-2.19 2.18H6.09a2.19 2.19 0 0 1-2.19-2.18V16a2.21 2.21 0 0 1 2.19-2.19h3.3a1.11 1.11 0 1 0 .21-2.22H6.09A4.43 4.43 0 0 0 1.67 16v11.59A4.43 4.43 0 0 0 6.09 32h19.82a4.42 4.42 0 0 0 4.42-4.41V16a4.41 4.41 0 0 0-4.42-4.41z' />
      <path d='M11.84 6.9l3.05-3.05v15.48a1.12 1.12 0 0 0 2.22 0V3.85l3.06 3.05a1.12 1.12 0 0 0 1.69-1.46l-.11-.11-5-5a1.21 1.21 0 0 0-.37-.25 1.11 1.11 0 0 0-.84 0 1.16 1.16 0 0 0-.36.25l-5 5a1.1 1.1 0 0 0 0 1.56 1.14 1.14 0 0 0 1.6.07z' />
    </g>
  </svg>
)
export default ShareIos
