import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { useEffect, useCallback, useMemo } from 'react'
import styled from 'styled-components'

import {
  Avatar,
  FadeInContainer,
  Skeleton,
  Table,
} from '@sportsyou/react-dom-ui'
import { Community, Profile } from '@sportsyou/api'
import { getProfileImage } from '@sportsyou/core'

import { ContentContainer } from '../../styles/global-styles'
import usePagesPortal from './UsePagesPortal'

export default function PagesIndex() {
  const { fetchAdminCommunities, isLoading, adminCommunities } =
    usePagesPortal()
  const navigate = useNavigate()

  const onRowClicked = useCallback(
    (community: Community) => {
      navigate(`/pages/${community.id}`)
    },
    [navigate]
  )

  const dataColumns = useMemo(() => {
    return [
      {
        cell: (community: Community) => (
          <ListName onClick={() => onRowClicked(community)}>
            <Avatar
              diameter={20}
              name={community.name as string}
              uri={getProfileImage.getProfileAvatarImageUrl(
                community as Profile
              )}
            />
            {community.name}
          </ListName>
        ),
        selector: (community: Community) => community.name as string,
        sortable: true,
        style: {
          cursor: 'pointer',
        },
      },
    ]
  }, [onRowClicked])

  useEffect(() => {
    fetchAdminCommunities()
  }, [fetchAdminCommunities])

  useEffect(() => {
    if (!isLoading) {
      if (adminCommunities?.length === 1) {
        navigate(`/pages/${adminCommunities[0].id}`)
      }
    }
  }, [adminCommunities, navigate, isLoading])

  return (
    <FadeInContainer>
      <ContentContainer>
        <Helmet>
          <title>sportsYou - Pages</title>
        </Helmet>

        <Table
          // selectableRows
          columns={dataColumns}
          customStyles={customTableStyles}
          data={adminCommunities}
          defaultSortAsc={true}
          highlightOnHover
          noTableHead
          onRowClicked={onRowClicked}
          progressPending={isLoading}
          striped
          progressComponent={
            <Skeleton
              barGap={8}
              barHeight={45}
              showBars
              showHeader={false}
              width={'100%'}
            />
          }
        />
      </ContentContainer>
    </FadeInContainer>
  )
}

const customTableStyles = {
  headCells: {
    style: {
      fontSize: '14px',
      fontWeight: 'bold',
    },
  },
  cells: {
    style: {},
  },
}

const ListName = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`
