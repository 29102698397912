import * as React from 'react'
import type { SVGProps } from 'react'
const MediaAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M23.5345 5.04962H8.47999c-2.67636 0-4.84364 2.14969-4.84364 4.80433V23.2859c0 2.6546 2.16728 4.8043 4.84364 4.8043H23.5345c2.6764 0 4.8437-2.1497 4.8437-4.8043V9.85395c0-2.65464-2.1673-4.80433-4.8437-4.80433Zm3.3891 18.23628c0 1.8467-1.5127 3.3616-3.3891 3.3616H8.47999c-1.86182 0-3.38909-1.5005-3.38909-3.3616v-1.0677l4.68364-4.2993c.53816-.4906 1.33816-.4761 1.86186 0l.2181.202c1.0909 1.0387 2.8364 1.0243 3.9128-.0289l4.0145-3.9387c.4946-.4905 1.2946-.5194 1.8327-.0721l5.2946 4.4292v4.761l.0145.0145Zm0-6.6511L22.56 12.9847l-.48.5627.4654-.5627c-1.1054-.9234-2.749-.8656-3.7818.1443l-4.0145 3.9387c-.5237.5049-1.3673.5193-1.8909 0l-.2182-.202c-1.0764-1.0244-2.7491-1.0244-3.84001-.0289l-3.69454 3.3905V9.85395c0-1.84671 1.51272-3.36159 3.38908-3.36159H23.5491c1.8618 0 3.3891 1.50045 3.3891 3.36159v6.78085h-.0146Z' />
    <path d='M12.16 9.60857c-.3636-.37511-.8727-.59152-1.4255-.59152-.5527 0-1.06178.20198-1.43997.60595-.34909.36068-.58182.8512-.58182 1.4139s.20364 1.0388.59637 1.4139c.36364.3751.87272.5915 1.41092.5915.5382 0 1.0473-.202 1.4254-.5915.3637-.3751.5819-.8801.5819-1.4139 0-.5338-.2037-1.03879-.5964-1.4139l.0291-.01443Zm-1.0327 1.81783c-.1019.1154-.2328.1732-.3928.1732-.1454 0-.2909-.0578-.4072-.1732-.1019-.101-.16-.2452-.16-.4039 0-.1299.0581-.2742.1891-.404.1018-.101.2327-.1587.3927-.1587.1454 0 .2909.0577.4072.1731.1019.101.1601.2453.1601.404 0 .1443-.0582.2885-.1746.404l-.0145-.0145Z' />
  </svg>
)
export default MediaAlt
