import { useMemo } from 'react'
import styled from 'styled-components'

import { Colors } from '@sportsyou/core'
import { Icon } from '@sportsyou/react-dom-ui'
import { IconNames } from '@sportsyou/react-icons'

interface ActivityDirectionIconProps {
  className?: string
  currentValue?: number
  previousValue?: number
  title?: string
}

interface DirectionData {
  name: IconNames
  color: string
  title: string
}

export default function ActivityDirectionIcon(
  props: ActivityDirectionIconProps
) {
  const { className, currentValue, previousValue, title } = props
  const directionData: DirectionData = useMemo(() => {
    if (
      currentValue !== undefined &&
      previousValue !== undefined &&
      currentValue > previousValue
    ) {
      return {
        name: 'ChevronUp',
        color: Colors.MOUNTAIN_MEADOW,
        title: 'More',
      }
    } else {
      return {
        name: 'ChevronDown',
        color: Colors.VIVID_TANGERINE,
        title: 'Less',
      }
    }
  }, [currentValue, previousValue])

  const visible = useMemo(() => {
    return (
      previousValue === undefined ||
      currentValue === undefined ||
      previousValue === currentValue
    )
  }, [previousValue, currentValue])

  if (visible) {
    return null
  }

  return (
    <Container
      backgroundColor={directionData.color}
      className={className}
      title={title ?? directionData.title}
    >
      <Icon name={directionData.name} color={Colors.WHITE} size={8} />
    </Container>
  )
}

const Container = styled.div<{ backgroundColor: string }>`
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 50%;
  display: inline-flex;
  height: 14px;
  justify-content: center;
  width: 14px;
`
