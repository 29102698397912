import * as React from 'react'
import type { SVGProps } from 'react'
const BrandGoogle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    style={props.style}
    {...props}
  >
    <path
      fill='#4285F4'
      fillRule='evenodd'
      d='M31.36 16.3635c0-1.1344-.1019-2.2254-.2908-3.2727H16V19.28h8.6108c-.3708 2-1.4981 3.6946-3.1927 4.8291v4.0144h5.1711c3.0254-2.7854 4.7708-6.8871 4.7708-11.76Z'
      clipRule='evenodd'
    />
    <path
      fill='#34A853'
      fillRule='evenodd'
      d='M16 32c4.32 0 7.9419-1.4328 10.5892-3.8764l-5.171-4.0144c-1.4327.96-3.2655 1.5271-5.4182 1.5271-4.1671 0-7.69454-2.8144-8.95285-6.5963H1.7019v4.1455C4.33462 28.4146 9.74546 32 16 32Z'
      clipRule='evenodd'
    />
    <path
      fill='#FBBC05'
      fillRule='evenodd'
      d='M7.04711 19.0401c-.32-.96-.50168-1.9854-.50168-3.04s.18186-2.08.50186-3.04V8.8147H1.70187C.581866 11.0443-.00095512 13.505.00000117 16.0001c0 2.5819.61813383 5.0254 1.70186883 7.1854l5.34524-4.1454Z'
      clipRule='evenodd'
    />
    <path
      fill='#EA4335'
      fillRule='evenodd'
      d='M16 6.36356c2.3492 0 4.4582.80729 6.1165 2.39289l4.589-4.58916C23.9346 1.58542 20.3129 0 16 0 9.74546 0 4.33462 3.58542 1.7019 8.81458L7.04715 12.96C8.30528 9.17813 11.8327 6.36356 16 6.36356Z'
      clipRule='evenodd'
    />
  </svg>
)
export default BrandGoogle
