import React, { FC, useMemo } from 'react'
// import { BsX } from 'react-icons/bs'
import styled from 'styled-components'

import { Colors, darken } from '@sportsyou/core'
import Icon from '../icon'

export type CloseButtonAppearance = 'circular' | 'rounded' | 'square'

interface Props {
  appearance?: CloseButtonAppearance
  bgColor?: string
  className?: string
  disabled?: boolean
  hideBackground?: boolean
  iconColor?: string
  /**
   * The size of the component. If using a number, the pixel unit is assumed.
   * If using a string, you must to provide the CSS unit, e.g '2rem'.
   */
  size?: number
  style?: React.CSSProperties
  hasBackground?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  /**
   * Ratio of 'x' to size prop
   */
  xRatio?: number
  testId?: string
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type']
}

type NativeAttributes = Omit<
  React.HTMLAttributes<HTMLButtonElement>,
  keyof Props
>
export type CloseButtonProps = Props & NativeAttributes

/** Simple re-useable close button */
export const CloseButton: FC<CloseButtonProps> = ({
  appearance = 'circular',
  bgColor = Colors.CATSKILL_WHITE,
  disabled = false,
  iconColor,
  size = 30,
  className,
  hideBackground,
  onClick,
  style,
  xRatio,
  testId,
  type = 'button',
}: CloseButtonProps) => {
  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(event)
  }
  const xSize = useMemo(
    () =>
      xRatio ? size * xRatio : Math.floor(size * (size <= 26 ? 0.6 : 0.56)),
    // Math.floor(size * (size <= 26 ? 0.72 : 0.66)),
    [xRatio, size]
  )
  const _bgColor = useMemo(
    () => (bgColor.trim().length === 0 ? Colors.CATSKILL_WHITE : bgColor),
    [bgColor]
  )
  const sizeString = useMemo(
    () => (typeof size === 'string' ? size : `${size}px`),
    [size]
  )
  return (
    <Button
      $bgColor={_bgColor}
      $size={sizeString}
      appearance={appearance}
      aria-disabled={disabled}
      className={className}
      data-testid={testId}
      disabled={disabled}
      hideBackground={hideBackground}
      onClick={handleOnClick}
      style={style}
      type={type}
    >
      {/* <BsX fill={iconColor ?? 'currentColor'} size={xSize} /> */}
      <Icon fill={iconColor ?? 'currentColor'} name='X' size={xSize} />
    </Button>
  )
}

const Button = styled.button<{
  $bgColor: string
  $size: string
  appearance?: CloseButtonAppearance
  hideBackground?: boolean
}>`
  align-items: center;
  background-color: ${({ $bgColor, hideBackground }) =>
    hideBackground ? 'transparent' : $bgColor};
  border-radius: ${({ appearance }) =>
    appearance === 'circular'
      ? '50%'
      : appearance === 'rounded'
      ? '6px'
      : '0ox'};
  border: none;
  color: ${Colors.SHUTTLE_GRAY};
  cursor: pointer;
  display: inline-flex;
  height: ${({ $size }) => $size};
  justify-content: center;
  outline: none;
  padding: 0;

  transition: background-color 120ms ease-in-out;
  width: ${({ $size }) => $size};

  &:hover {
    background-color: ${darken(Colors.CATSKILL_WHITE, 6)};
  }
  &:active {
    background-color: ${darken(Colors.CATSKILL_WHITE, 12)};
  }
  &:focus {
    box-shadow: 0 0 0 0.2em rgb(0 109 255 / 40%);
    position: relative;
    z-index: 2;
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:active {
    background-color: ${Colors.ALTO};
    border-color: ${Colors.ALTO};
    cursor: not-allowed;
    color: ${Colors.DUSTY_GRAY};
  }
`

export default CloseButton
