// TODO: Look into perspective scale for better looking 3d space
import {
  CSSProperties,
  // useEffect,
  // useCallback,
  useMemo,
} from 'react'

import Avatar from '../avatar'
import { AvatarDataTypes, AvatarGroupItemProps } from '../avatar/helpers'

interface AvatarStackPropTypes {
  // stackDirection?: 'horizontal' | 'vertical' | 'diagonal'
  useRawString?: boolean
  data?: AvatarDataTypes[]

  backgroundColor?: string
  borderRadius?: number
  items: Array<AvatarGroupItemProps>
  diameter?: number
  hideBackground?: boolean

  names?: Array<string>
  layout?: 'grid' | 'stack'
  stackDirection?: 'horizontal' | 'vertical' | 'diagonal'
  testId?: string
}

const AvatarGroupStack: React.FC<AvatarStackPropTypes> = ({
  diameter = 60,
  data = [],
  names = [],
  stackDirection,
  testId,
  useRawString = false,
}: AvatarStackPropTypes) => {
  const setContainerStyles = useMemo(() => {
    const sharedStyles: CSSProperties = {
      boxSizing: 'border-box',
      position: 'relative',
    }
    let containerStyles: CSSProperties = {}

    if (stackDirection === 'horizontal') {
      containerStyles = {
        display: 'flex',
        height: 'auto',
        margin: 0,
        padding: 0,
        width: 'max-content',
      }
    } else if (stackDirection === 'vertical') {
      containerStyles = {
        height: (diameter / 2) * names.length + diameter / 2,

        width: diameter,
      }
    }

    return {
      ...sharedStyles,
      ...containerStyles,
    }
  }, [diameter, names.length, stackDirection])

  // const setHorizontalPosition = useCallback(
  //   (index: number) => {
  //     const total = names.length
  //     let sharedStyles: CSSProperties = {
  //       height: diameter / 2,
  //       width: diameter / 2,
  //     }
  //     let styles: CSSProperties = {
  //       borderRadius: '50%',
  //       boxSizing: 'border-box',
  //       overflow: 'hidden',
  //       position: 'relative',
  //       flex: `0 0 ${diameter / 2}px`,
  //       // verticalAlign: 'middle',
  //       // width: 'calc(28px)',
  //       // height: 'calc(28px)',
  //       padding: 0,
  //       marginLeft: -10,
  //     }

  //     return { ...sharedStyles, ...styles }
  //   },
  //   [diameter, names.length]
  // )

  const renderStackedAvatar = (item: AvatarDataTypes, index: number) => {
    // Calculate outer div border radius size
    let outerBorderRadius = diameter / 2 + 4 // half the diameter + double size of border
    if (index === 0) {
      outerBorderRadius = outerBorderRadius - 4
    }
    // let margin = '0px'
    // let scale = 1

    let avatarWrapStyles: CSSProperties = {
      alignItems: 'center',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      zIndex: index,
    }

    // Horizontally Stacked Avatar Styles
    if (stackDirection === 'horizontal') {
      if (index > 0) {
        avatarWrapStyles.marginLeft = -(diameter / 2.25)
      }
    } else if (stackDirection === 'vertical') {
      // Adjust scale
      // if (index === 0) {
      //   scale = 0.5
      // } else if (index === 1) {
      //   scale = 0.6
      // } else if (index === 2) {
      //   scale = 0.7
      // } else if (index === 3) {
      //   scale = 0.8
      // }

      // adust margin to stack avatars
      if (index > 0) {
        avatarWrapStyles.marginTop = -(diameter / 2)
      }
    }

    return (
      <div
        key={index}
        style={{
          // border: '2px solid #ffffff',
          borderRadius: outerBorderRadius,
          // boxSizing: 'border-box',
          // margin,
          // marginTop: '-50%',
          // height: 30,
          // width: 30,
          ...avatarWrapStyles,
          // transform: `scale(${scale})`,
        }}
      >
        <Avatar
          // borderRadius={borderRadius}
          // diameter={30 - 4}
          diameter={diameter}
          fontSize={diameter / 3}
          // isSquare={isSquare}
          name={item.display}
          title={item.title}
          uri={item.uri}
          // style={handleAvatarStyles(index)}
          useRawName={item.useRawString}
        />
      </div>
    )
  }

  return (
    <div data-testid={testId} style={{ ...setContainerStyles }}>
      {data.length &&
        data.map((item, index) => renderStackedAvatar(item, index))}
    </div>
  )
}

export default AvatarGroupStack
