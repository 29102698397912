// Export all multi color svg components
export { default as BadgeVerifiedOutline } from './badge-verified-outline'
export { default as BadgeVerified } from './badge-verified'
export { default as BrandGoogle } from './brand-google'
export { default as BubbleES } from './bubble-e-s'
export { default as FileAvi } from './file-avi'
export { default as FileDoc } from './file-doc'
export { default as FileGeneric } from './file-generic'
export { default as FileGif } from './file-gif'
export { default as FileJpg } from './file-jpg'
export { default as FileM4v } from './file-m4v'
export { default as FileMkv } from './file-mkv'
export { default as FileMov } from './file-mov'
export { default as FileMp3 } from './file-mp3'
export { default as FileMp4 } from './file-mp4'
export { default as FilePdf } from './file-pdf'
export { default as FilePng } from './file-png'
export { default as FilePpt } from './file-ppt'
export { default as FileXls } from './file-xls'
export { default as FileZip } from './file-zip'
export { default as HomeES } from './home-e-s'
export { default as MediaES } from './media-e-s'
export { default as SyLogoCircle } from './sy-logo-circle'

// Export all single color svg components
export { default as BellSolid } from './bell-solid'
export { default as Bell } from './bell'
export { default as BrandSportsyou } from './brand-sportsyou'
export { default as BrandSy } from './brand-sy'
export { default as BubbleAlt } from './bubble-alt'
export { default as BubbleSolid } from './bubble-solid'
export { default as BubbleThick } from './bubble-thick'
export { default as Bubble } from './bubble'
export { default as CalendarAlt } from './calendar-alt'
export { default as CalendarSolid } from './calendar-solid'
export { default as CalendarSubscribe } from './calendar-subscribe'
export { default as Calendar } from './calendar'
export { default as CameraSolid } from './camera-solid'
export { default as Camera } from './camera'
export { default as Checkmark } from './checkmark'
export { default as ChevronDown } from './chevron-down'
export { default as ChevronLeft } from './chevron-left'
export { default as ChevronRight } from './chevron-right'
export { default as ChevronUp } from './chevron-up'
export { default as Clock } from './clock'
export { default as Code } from './code'
export { default as ColorPost } from './color-post'
export { default as CreateAlbum } from './create-album'
export { default as CreateEvent } from './create-event'
export { default as CreateGame } from './create-game'
export { default as CreateGroup } from './create-group'
export { default as CreateTeam } from './create-team'
export { default as CustomerService } from './customer-service'
export { default as Dots } from './dots'
export { default as DownloadLine } from './download-line'
export { default as Download } from './download'
export { default as Drag } from './drag'
export { default as EmailArrow } from './email-arrow'
export { default as FabBubble } from './fab-bubble'
export { default as FabCode } from './fab-code'
export { default as FabCreateAlbum } from './fab-create-album'
export { default as FabCreateEvent } from './fab-create-event'
export { default as FabCreateGame } from './fab-create-game'
export { default as FabCreateGroup } from './fab-create-group'
export { default as FabCreateTeam } from './fab-create-team'
export { default as FabEmailArrow } from './fab-email-arrow'
export { default as FabFolder } from './fab-folder'
export { default as FabJersey } from './fab-jersey'
export { default as FabJoinTeam } from './fab-join-team'
export { default as FabMedia } from './fab-media'
export { default as FabUpload } from './fab-upload'
export { default as FabUser } from './fab-user'
export { default as FabUsers } from './fab-users'
export { default as FolderAlt } from './folder-alt'
export { default as FolderLine } from './folder-line'
export { default as FolderSolid } from './folder-solid'
export { default as Folder } from './folder'
export { default as GearSolid } from './gear-solid'
export { default as Gear } from './gear'
export { default as Gif } from './gif'
export { default as Hamburger } from './hamburger'
export { default as HomeAlt } from './home-alt'
export { default as HomeFilled } from './home-filled'
export { default as Home } from './home'
export { default as JerseySolid } from './jersey-solid'
export { default as Jersey } from './jersey'
export { default as JoinTeam } from './join-team'
export { default as Location } from './location'
export { default as Logo } from './logo'
export { default as MediaAlt } from './media-alt'
export { default as MediaSolid } from './media-solid'
export { default as Media } from './media'
export { default as Muted } from './muted'
export { default as NavBellFilled } from './nav-bell-filled'
export { default as NavBell } from './nav-bell'
export { default as NavBubbleFilled } from './nav-bubble-filled'
export { default as NavBubble } from './nav-bubble'
export { default as NavCalendarFilled } from './nav-calendar-filled'
export { default as NavCalendar } from './nav-calendar'
export { default as NavHomeFilled } from './nav-home-filled'
export { default as NavHome } from './nav-home'
export { default as NavJerseyFilled } from './nav-jersey-filled'
export { default as NavJersey } from './nav-jersey'
export { default as Pages } from './pages'
export { default as Paperclip } from './paperclip'
export { default as Pause } from './pause'
export { default as Pencil } from './pencil'
export { default as Pin } from './pin'
export { default as Play } from './play'
export { default as PlaylistNextClip } from './playlist-next-clip'
export { default as PlaylistPause } from './playlist-pause'
export { default as PlaylistPlay } from './playlist-play'
export { default as PlaylistPrevClip } from './playlist-prev-clip'
export { default as PlaylistSlowforward } from './playlist-slowforward'
export { default as Plus } from './plus'
export { default as PollsFilled } from './polls-filled'
export { default as Polls } from './polls'
export { default as PostFilled } from './post-filled'
export { default as Post } from './post'
export { default as Print } from './print'
export { default as SearchAlt } from './search-alt'
export { default as SearchLine } from './search-line'
export { default as Search } from './search'
export { default as ShareAndroid } from './share-android'
export { default as ShareIos } from './share-ios'
export { default as Share } from './share'
export { default as SyLogo } from './sy-logo'
export { default as SyVideo } from './sy-video'
export { default as Sy } from './sy'
export { default as ThumbsUpFilled } from './thumbs-up-filled'
export { default as ThumbsUp } from './thumbs-up'
export { default as Trash } from './trash'
export { default as Upload } from './upload'
export { default as UserCircle } from './user-circle'
export { default as UserSolid } from './user-solid'
export { default as User } from './user'
export { default as Users } from './users'
export { default as Windows } from './windows'
export { default as XLg } from './x-lg'
export { default as X } from './x'

export const iconNames = [
  'BellSolid',
  'Bell',
  'BrandSportsyou',
  'BrandSy',
  'BubbleAlt',
  'BubbleSolid',
  'BubbleThick',
  'Bubble',
  'CalendarAlt',
  'CalendarSolid',
  'CalendarSubscribe',
  'Calendar',
  'CameraSolid',
  'Camera',
  'Checkmark',
  'ChevronDown',
  'ChevronLeft',
  'ChevronRight',
  'ChevronUp',
  'Clock',
  'Code',
  'ColorPost',
  'CreateAlbum',
  'CreateEvent',
  'CreateGame',
  'CreateGroup',
  'CreateTeam',
  'CustomerService',
  'Dots',
  'DownloadLine',
  'Download',
  'Drag',
  'EmailArrow',
  'FabBubble',
  'FabCode',
  'FabCreateAlbum',
  'FabCreateEvent',
  'FabCreateGame',
  'FabCreateGroup',
  'FabCreateTeam',
  'FabEmailArrow',
  'FabFolder',
  'FabJersey',
  'FabJoinTeam',
  'FabMedia',
  'FabUpload',
  'FabUser',
  'FabUsers',
  'FolderAlt',
  'FolderLine',
  'FolderSolid',
  'Folder',
  'GearSolid',
  'Gear',
  'Gif',
  'Hamburger',
  'HomeAlt',
  'HomeFilled',
  'Home',
  'JerseySolid',
  'Jersey',
  'JoinTeam',
  'Location',
  'Logo',
  'MediaAlt',
  'MediaSolid',
  'Media',
  'Muted',
  'NavBellFilled',
  'NavBell',
  'NavBubbleFilled',
  'NavBubble',
  'NavCalendarFilled',
  'NavCalendar',
  'NavHomeFilled',
  'NavHome',
  'NavJerseyFilled',
  'NavJersey',
  'Pages',
  'Paperclip',
  'Pause',
  'Pencil',
  'Pin',
  'Play',
  'PlaylistNextClip',
  'PlaylistPause',
  'PlaylistPlay',
  'PlaylistPrevClip',
  'PlaylistSlowforward',
  'Plus',
  'PollsFilled',
  'Polls',
  'PostFilled',
  'Post',
  'Print',
  'SearchAlt',
  'SearchLine',
  'Search',
  'ShareAndroid',
  'ShareIos',
  'Share',
  'SyLogo',
  'SyVideo',
  'Sy',
  'ThumbsUpFilled',
  'ThumbsUp',
  'Trash',
  'Upload',
  'UserCircle',
  'UserSolid',
  'User',
  'Users',
  'Windows',
  'XLg',
  'X',
  'BadgeVerifiedOutline',
  'BadgeVerified',
  'BrandGoogle',
  'BubbleES',
  'FileAvi',
  'FileDoc',
  'FileGeneric',
  'FileGif',
  'FileJpg',
  'FileM4v',
  'FileMkv',
  'FileMov',
  'FileMp3',
  'FileMp4',
  'FilePdf',
  'FilePng',
  'FilePpt',
  'FileXls',
  'FileZip',
  'HomeES',
  'MediaES',
  'SyLogoCircle',
] as const

export type IconNames = (typeof iconNames)[number]
