import { useMemo } from 'react'
import { Timezone } from '@sportsyou/api'

import Dropdown from '../dropdown'
import { Colors } from '@sportsyou/core'

export interface TimezonePickerProps {
  className?: string
  id?: string
  label?: string
  name?: string
  onChange: (timezone: string) => void
  placeholder?: string
  timezones: Timezone[]
  title?: string
  value?: string
}

export const TimezonePicker = ({
  id,
  name,
  onChange,
  timezones,
  title: initialTitle = 'Timezone',
  value,
}: TimezonePickerProps) => {
  const title =
    useMemo(
      () =>
        timezones.length
          ? timezones.find((tz) => tz.codes?.includes(value ?? ''))?.displayName
          : initialTitle,
      [timezones, initialTitle, value]
    ) ?? initialTitle

  return (
    <Dropdown
      collapse
      chevronColor={Colors.HAVELOCK_BLUE}
      chevronSize={12}
      id={id}
      menuWidth={'270px'}
      name={name}
      placement='bottomStart'
      title={title}
    >
      {timezones.map((tz, index) => (
        <Dropdown.Item
          key={tz.codes?.[0] ?? index}
          onClick={() => onChange(tz.codes?.[0] ?? '')}
          value={tz.codes?.[0] ?? ''}
        >
          {tz.displayName}
        </Dropdown.Item>
      ))}
    </Dropdown>
  )
}

export default TimezonePicker
