import styled, { css } from 'styled-components'

import { Colors } from '../../colors'
import { BORDER_RADIUS, FONT_SIZE_SM } from '../../theme'

export interface WellProps {
  children?: React.ReactNode
  className?: string
  label?: string
  noBorder?: boolean
  style?: React.CSSProperties
  testId?: string
}

export default function Well(props: WellProps) {
  return (
    <Container
      className={props.className}
      data-testid={props.testId}
      noBorder={props.noBorder}
      style={props.style}
    >
      {props.label && <LabelText>{props.label}</LabelText>}
      {props.children}
    </Container>
  )
}

const Container = styled.div<{ noBorder?: boolean }>`
  ${({ noBorder }) =>
    !noBorder &&
    css`
      border: 1px solid ${Colors.ALTO};
      background-color: ${Colors.CATSKILL_WHITE};
      border-radius: ${BORDER_RADIUS};
      padding: 15px;
    `};
`

const LabelText = styled.div`
  color: ${Colors.PUNCH};
  font-size: ${FONT_SIZE_SM}px;
  font-weight: 700;
  padding-bottom: 10px;
  text-transform: uppercase;
`
