import * as React from 'react'
import type { SVGProps } from 'react'
const Share = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <path d='M31.38 16.16L20.06 26.42A1.88 1.88 0 0 1 16.92 25v-5.73c-7.3 0-13.79 2.53-16.92 9.45V26.6C0 16.07 7.68 9.74 16.92 9.74V5.16A1.88 1.88 0 0 1 20 3.73l11.32 9.61a1.88 1.88 0 0 1 .06 2.82z' />
  </svg>
)
export default Share
