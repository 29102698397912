import * as React from 'react'
import type { SVGProps } from 'react'
const Muted = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 15.544 17'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <g>
      <path
        d='M2.725,12.581a1.045,1.045,0,0,1-.015-.159,1.167,1.167,0,0,1,.34-.824l.563-.563A2.385,2.385,0,0,0,4.3,9.372V6.907a3.39,3.39,0,0,1,3.39-3.384H9.281a3.373,3.373,0,0,1,1.9.583l.842-.844a4.558,4.558,0,0,0-1.355-.706V2.2a2.2,2.2,0,1,0-4.41,0v.356A4.581,4.581,0,0,0,3.07,6.907v2.46a1.169,1.169,0,0,1-.34.824l-.521.563a2.368,2.368,0,0,0-.69,1.663,2.224,2.224,0,0,0,.292,1.08ZM7.464,2.2A1.02,1.02,0,0,1,9.5,2.058a.972.972,0,0,1,0,.147v.138H7.464Z'
        transform='translate(-0.712 0)'
      />
      <path
        d='M16.4,14.753a1.169,1.169,0,0,1-.34-.824v-2.46A4.547,4.547,0,0,0,15.7,9.735l-.926.927a3.372,3.372,0,0,1,.1.807v2.46a2.385,2.385,0,0,0,.685,1.663l.563.564a1.167,1.167,0,0,1,.34.824,1.063,1.063,0,0,1-1.057,1.067h-8L6.222,19.232H8.1a2.593,2.593,0,0,0,5.159,0h2.125a2.264,2.264,0,0,0,2.258-2.253,2.369,2.369,0,0,0-.68-1.663Zm-5.711,5.626A1.418,1.418,0,0,1,9.3,19.232H12.08a1.418,1.418,0,0,1-1.392,1.147'
        transform='translate(-2.916 -4.563)'
      />
      <path
        d='M15.368,1.516a.6.6,0,0,0-.845,0L.174,15.9a.6.6,0,0,0,.846.845L15.369,2.361a.6.6,0,0,0,0-.845'
        transform='translate(0 -0.629)'
      />
    </g>
  </svg>
)
export default Muted
