import React, { useEffect, useMemo, useRef, useState } from 'react'

import styled from 'styled-components'
import { getChildrenFromReactNode, getPropsFromReactNode } from '../../utils'

import ContentAside from './modules/aside'
import Content from './modules/content'
import ContentSidebar from './modules/sidebar'

// interface ContentModules {
//   sidebar?: React.ReactNode
//   content?: React.ReactNode
//   aside?: React.ReactNode
// }
// type ModuleTypes = 'sidebar' | 'content' | 'aside'

interface Props {
  className?: string
  contentMaxWidth?: number
  contentStyle?: React.CSSProperties
  fullscreen?: boolean
  /** Space between each section */
  gap?: number
  sidebarWidth?: number
  isSidebarHidden?: boolean
  isSidebarMinimized?: boolean
  minimizedSidebarWidth?: number

  fullWidthContent?: boolean

  stretchContent?: boolean
  // stickyOffsetY?: number
}
type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type ContentLayoutProps = Props & NativeAttributes

export const ContentLayout: React.FC<
  React.PropsWithChildren<ContentLayoutProps>
> = ({
  // asideWidth = 230,
  children,
  className,
  contentMaxWidth = 900,
  contentStyle,
  gap = 10,
  fullscreen = false,
  isSidebarHidden = false,
  isSidebarMinimized = false,
  minimizedSidebarWidth = 90,
  stretchContent = false,
  // sidebarWidth = 230,
  fullWidthContent = false,
  // stickyOffsetY,
  ...props
}) => {
  const asideRef = useRef<HTMLDivElement>(null)
  const sidebarRef = useRef<HTMLDivElement>(null)

  const mQuery = window.matchMedia('(min-width: 768px)')

  const [matches, setMatches] = useState(mQuery.matches)

  const [asideY, setAsideY] = useState<number>()
  const [sidebarY, setSidebarY] = useState<number>()

  useEffect(() => {
    mQuery.addEventListener('change', (e) => setMatches(e.matches))

    return () => {
      mQuery.removeEventListener('change', (e) => setMatches(e.matches))
    }
  }, [mQuery])

  useEffect(() => {
    if (sidebarRef.current) {
      // Don't update
      if (sidebarY) return
      const { y } = sidebarRef.current.getBoundingClientRect()
      setSidebarY(y)
    }
    if (asideRef.current) {
      // Don't update
      if (asideY) return
      const { y } = asideRef.current.getBoundingClientRect()
      setAsideY(y)
    }
  }, [sidebarY, asideY])

  const sidebarNode = useMemo(
    () =>
      React.Children.map(children, (item) =>
        React.isValidElement(item) && item.type === ContentSidebar ? item : null
      ),
    [children]
  )

  const contentNode = useMemo(
    () =>
      React.Children.map(children, (item) =>
        React.isValidElement(item) && item.type === Content ? item : null
      ),
    [children]
  )

  const asideNode = useMemo(
    () =>
      React.Children.map(children, (item) =>
        React.isValidElement(item) && item.type === ContentAside ? item : null
      ),
    [children]
  )

  return (
    <Container
      // asideWidth={asideWidth}
      contentMaxWidth={contentMaxWidth}
      // sidebarWidth={sidebarWidth}
      className={className}
      fullscreen={fullscreen}
      matches={matches}
      {...props}
    >
      {sidebarNode?.map((node, index) => (
        <ContentSidebar
          gap={gap}
          key={index}
          ref={sidebarRef}
          yOffset={sidebarY}
          {...getPropsFromReactNode(node)}
        >
          {getChildrenFromReactNode(node)}
        </ContentSidebar>
      ))}
      <Main fullscreen={fullscreen} gap={gap} stretchContent={stretchContent}>
        {contentNode?.map((node, index) => (
          <Content
            key={index}
            contentMaxWidth={contentMaxWidth}
            fullWidthContent={fullWidthContent}
            {...getPropsFromReactNode(node)}
          >
            {getChildrenFromReactNode(node)}
          </Content>
        ))}
        {asideNode?.map((node, index) => (
          <ContentAside
            gap={gap}
            key={index}
            ref={asideRef}
            yOffset={asideY}
            {...getPropsFromReactNode(node)}
          >
            {getChildrenFromReactNode(node)}
          </ContentAside>
        ))}
      </Main>
    </Container>
  )
}

const Container = styled.div<
  Pick<ContentLayoutProps, 'contentMaxWidth' | 'fullscreen'> & {
    matches?: boolean
  }
>`
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: ${({ matches }) => (matches ? 'row' : 'column')};
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  min-height: ${({ fullscreen }) => (fullscreen ? '100vh' : 'auto')};
  width: ${({ fullscreen }) => (fullscreen ? '100vw' : '1200px')};
`
// @media all and (min-width: ${({
//     asideWidth = 230,
//     contentMaxWidth = 900,
//     sidebarWidth = 230,
//   }) => asideWidth + contentMaxWidth + sidebarWidth}px) {
//   justify-content: space-between;
// }

const Main = styled.div<
  Pick<
    ContentLayoutProps,
    'contentMaxWidth' | 'fullscreen' | 'gap' | 'stretchContent'
  >
>`
  align-items: flex-start;
  display: flex;
  // flex-basis: 900px;
  flex-basis: auto;
  flex-grow: ${({ fullscreen, stretchContent }) =>
    fullscreen && !stretchContent ? '0' : '1'};
  flex-shrink: 1;
  max-width: 100%;
  // min-height: 900px;
  min-height: 1px;
  min-width: 1px;
`
// @media all and (min-width: ${({
//     asideWidth = 230,
//     contentMaxWidth = 900,
//     sidebarWidth = 230,
//   }) => asideWidth + contentMaxWidth + sidebarWidth}px) {
//   flex-grow: 1;
//   justify-content: space-between;
// }
// const StyledContent = styled(Content)<{
//   asideWidth?: number
//   contentMaxWidth?: number
//   fullWidthContent?: boolean
//   sidebarWidth?: number
// }>`
//   flex: 1 1 auto;
//   width: ${({ fullWidthContent }) => (fullWidthContent ? '100%' : 'auto')};
//   [data-fullscreen='true'] & {
//     max-width: ${({ contentMaxWidth, fullWidthContent }) =>
//       fullWidthContent ? '100%' : `${contentMaxWidth}px`};
//     @media all and (min-width: ${({
//         asideWidth = 230,
//         contentMaxWidth = 900,
//         sidebarWidth = 230,
//       }) => asideWidth + contentMaxWidth + sidebarWidth}px) {
//       margin-left: auto;
//       margin-right: auto;
//     }
//   }
// `

ContentLayout.displayName = 'ContentLayout'

export default ContentLayout
