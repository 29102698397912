export function randomInRange(min: number, max: number): number {
  return Math.round(Math.random() * (max - min) + min)
}

export function randomInRangeDecimal(
  min: number,
  max: number,
  digits: number
): number {
  return parseFloat((Math.random() * (max - min) + min).toFixed(digits))
}

export default {
  randomInRange,
  randomInRangeDecimal,
}
