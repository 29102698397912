import * as React from 'react'
import type { SVGProps } from 'react'
const FileGif = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 814 1000'
    width='1em'
    height='1em'
    style={props.style}
    {...props}
  >
    <path
      fill='#373737'
      d='M782.11 124.96h-31.28V31.28C750.83 14.05 736.78 0 719.56 0H32.04C14.81 0 .76 14.05.76 31.28v937.44c0 17.23 14.05 31.28 31.28 31.28h687.52c17.23 0 31.28-14.05 31.28-31.28v-562.4h31.28c17.23 0 31.28-14.05 31.28-31.28v-218.8c-.01-17.23-14.06-31.28-31.29-31.28zm-62.55 0H344.52c-17.23 0-31.28 14.05-31.28 31.28v218.8c0 17.23 14.05 31.28 31.28 31.28h375.04v562.41H32.04V31.28h687.52v93.68zm-33.82 130v20.42h-54.06v46.53h-23.76V210.28h78.99v20.42h-55.23v24.27h54.06zm-99.91-44.68V321.9h-23.76V210.28h23.76zm-90.21 92.54c10.21 0 19.25-4.18 24.1-8.54v-13.56h-29.79V260.3h53.22v42.68c-11.38 12.72-27.28 21.09-47.53 21.09-32.63 0-59.41-22.76-59.41-57.91 0-35.31 26.78-57.74 59.41-57.74 23.1 0 37.66 11.21 46.19 24.43l-19.75 10.71c-5.19-7.87-14.73-14.06-26.44-14.06-20.25 0-34.98 15.56-34.98 36.65 0 21.11 14.73 36.67 34.98 36.67z'
    />
    <path
      fill='#373737'
      d='M448.18 677.55c-.6-.76-1.36-1.36-2.27-1.81l-77.67-49.11c-2.57-1.21-5.29-1.81-8.16-1.66h-.15c-1.96 0-3.93.45-5.74 1.21-.15 0-.3.15-.45.15-1.81.76-3.32 1.81-4.68 3.17-.91.76-1.66 1.66-2.42 2.57-.3.76-.6 1.51-.76 2.12-.15.15-.15.45-.15.6-.6 1.36-.76 2.87-.91 4.38 0 .45-.3.91-.3 1.36v93.68c0 .45.15.91.3 1.36.15 1.66.45 3.02.91 4.53.15.15.15.3.15.6.15.76.45 1.51.76 2.12.76.91 1.51 1.81 2.42 2.57 1.36 1.36 3.02 2.42 4.68 3.17.15 0 .3.15.45.15 1.81.76 3.78 1.06 5.74 1.21h.15c2.87.15 5.59-.45 8.16-1.66l77.67-49.11c.91-.45 1.66-1.06 2.27-1.81 5.44-4.38 6.35-12.39 1.96-17.83-.6-.76-1.21-1.36-1.96-1.96z'
    />
    <path
      fill='#373737'
      d='M391.52 874c8.28 0 15-6.72 15-15s-6.72-15-15-15c-86.02 0-156-69.98-156-156s69.98-156 156-156 156 69.98 156 156c0 42.35-17.23 82.76-47.51 112.09v-32.7c0-8.28-6.72-15-15-15s-15 6.72-15 15v67.65c0 8.28 6.72 15 15 15h71.41c8.28 0 15-6.72 15-15s-6.72-15-15-15H522.5c35.1-34.83 55.02-82.31 55.02-132.04 0-102.56-83.44-186-186-186s-186 83.44-186 186 83.44 186 186 186z'
    />
  </svg>
)
export default FileGif
