import { AVATAR_AND_CALENDAR_COLORS as AVATAR_COLORS } from '../../theme'
import { getInitialsFromName } from './get-initials-from-name'

export const getAvatarColors = (name: string) => {
  const avatarInitials = getInitialsFromName(name)
  let colorIndex = 0
  let characterIndex = 0
  const codePointAt = avatarInitials.codePointAt(0) ?? 0
  const firstInitial: string = String.fromCodePoint(codePointAt)
  const firstInitialNum = parseFloat(firstInitial) as number
  const firstInitialIsNumeric = !isNaN(firstInitialNum) && isFinite(firstInitialNum)

  // console.log({ firstInitial, firstInitialNum, colorIndex, firstInitialIsNumeric })
  if (!isNaN(firstInitialNum) && isFinite(firstInitialNum)) {
    colorIndex = -1
  } else {
    characterIndex = codePointAt - 65
    colorIndex = characterIndex % AVATAR_COLORS.length
  }

  /**
   * Check if first initial is a number and set colorIndex appropriately. This is a
   * simplified version of 1.0. We are only using the first 10 colors in rather than
   * looking up character codes.
   */
  if (firstInitialIsNumeric) {
    // console.log('first letter is a number')
    colorIndex = firstInitialNum === 0 ? 9 : firstInitialNum - 1
  }
  // console.log({ firstInitial, firstInitialNum, colorIndex })
  /**
   * commenting it out as it is not possible for colorIndex to be greater than AVATAR_COLORS.length
   * hence can't be tested
   */
  // if (colorIndex > AVATAR_COLORS.length) {
  //   colorIndex = 0
  // }
  if (colorIndex < 0) {
    colorIndex = 0
  }
  // console.log({ firstInitial, firstInitialNum, colorIndex })
  return AVATAR_COLORS[colorIndex]
}
