import { useCallback, useMemo } from 'react'
import moment from 'moment'
import styled, { css } from 'styled-components'

import { BORDER_RADIUS, Radio } from '@sportsyou/react-dom-ui'
import { campaignAssetTranscodeUrl } from '../CampaignHelpers'
import { Colors } from '@sportsyou/core'
import { Upload } from '@sportsyou/api'

interface AssetPickerItemProps {
  asset: Upload
  locationName: string
  onClick?: (asset?: string) => void
  selectable?: boolean
  selected?: boolean
}

export default function AssetPickerItem({
  asset,
  locationName,
  onClick,
  selectable,
  selected,
}: AssetPickerItemProps) {
  const date = useMemo(() => {
    return moment(asset.createdAt).format('MM/DD/YYYY hh:mm A')
  }, [asset.createdAt])

  const handleOnClick = useCallback(() => {
    onClick?.(asset?.id as string)
  }, [asset, onClick])

  return (
    <Container
      onClick={handleOnClick}
      selectable={selectable}
      title={asset.sponsorInfo?.assetName ?? ''}
    >
      {selectable ? <Radio checked={selected} /> : null}
      <ImageContainer>
        <Image
          alt={asset.sponsorInfo?.assetName ?? ''}
          src={campaignAssetTranscodeUrl(
            asset,
            locationName === 'loadingScreen'
          )}
        />
      </ImageContainer>
      <Name>{asset.sponsorInfo?.assetName ?? ''}</Name>
      <ModifiedDate>{date}</ModifiedDate>
    </Container>
  )
}

const Container = styled.div<{ selectable?: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;

  ${({ selectable }) =>
    selectable
      ? css`
          cursor: pointer;
          padding: 10px 20px;

          &:hover {
            background-color: ${Colors.CATSKILL_WHITE};
          }
        `
      : null}

  &:last-child {
    span {
      border-bottom: 0;
    }
  }
`

const ImageContainer = styled.div`
  width: 200px;
`

const Image = styled.img`
  border-radius: ${BORDER_RADIUS};
  border: 1px solid ${Colors.ALTO};
  max-height: 100px;
  max-width: 200px;
`

const Name = styled.div`
  font-size: 14px;
  flex: 1;
  flex-grow: 1;
`

const ModifiedDate = styled.div`
  font-size: 14px;
`
