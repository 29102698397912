import * as React from 'react'
import type { SVGProps } from 'react'
const Clock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 30 32'
    width='1em'
    height='1em'
    fill={props.fill}
    style={props.style}
    {...props}
  >
    <g fillRule='nonzero'>
      <path d='M27.71 30.82a1 1 0 0 1-.74-.3l-3.59-3.59a1 1 0 0 1 0-1.48 1 1 0 0 1 1.47 0L28.44 29a1 1 0 0 1-.73 1.78v.04zM2.29 30.82A1 1 0 0 1 1.55 29l3.59-3.59a1.047 1.047 0 0 1 1.48 1.48L3 30.52a1 1 0 0 1-.71.3zM1.41 7a1.06 1.06 0 0 1-.8-.37 1 1 0 0 1 .13-1.47l4.76-4a1.06 1.06 0 0 1 1.5.1 1 1 0 0 1-.13 1.47l-4.77 4a1 1 0 0 1-.69.27zM28.59 7a1 1 0 0 1-.67-.24l-4.77-4a1.047 1.047 0 0 1 1.35-1.6l4.76 4a1 1 0 0 1 .13 1.47 1 1 0 0 1-.8.37zM19.76 22.44a1.08 1.08 0 0 1-.54-.15l-5.16-3.15a1 1 0 0 1-.5-.89v-6.57a1.04 1.04 0 1 1 2.08 0v6l4.66 2.82a1.007 1.007 0 0 1-.54 1.94z' />
      <path d='M15 31.11a13.76 13.76 0 0 1-12.71-19 13.75 13.75 0 1 1 12.71 19zM4.22 12.89c-2.463 5.949.362 12.768 6.31 15.233 5.949 2.464 12.769-.36 15.234-6.308 2.466-5.948-.356-12.768-6.304-15.235a11.62 11.62 0 0 0-15.24 6.31z' />
    </g>
  </svg>
)
export default Clock
