import React, { FC, PropsWithChildren, useCallback } from 'react'

import styled from 'styled-components'

import CloseButton from '../close-button'

export interface ModalBodyProps {
  children?: React.ReactNode
  className?: string
  closeButton?: React.ReactElement
  closeButtonClassName?: string
  closeButtonStyle?: React.CSSProperties
  onClickClose?: () => void
  includeCloseButton?: boolean
  style?: React.CSSProperties
}

export const ModalBody: FC<PropsWithChildren<ModalBodyProps>> = ({
  children,
  className,
  closeButton,
  closeButtonClassName,
  closeButtonStyle,
  onClickClose,
  includeCloseButton,
  style,
}: ModalBodyProps) => {
  const handleOnClickClose = useCallback(() => {
    onClickClose?.()
  }, [onClickClose])
  const renderCloseButton = useCallback(
    () =>
      closeButton ?? (
        <StyledCloseButton
          appearance='circular'
          className={closeButtonClassName}
          onClick={handleOnClickClose}
          style={closeButtonStyle}
        />
      ),
    [closeButton, closeButtonClassName, closeButtonStyle, handleOnClickClose]
  )
  return (
    <Body className={className} style={style}>
      {includeCloseButton && renderCloseButton()}
      {children}
    </Body>
  )
}

const Body = styled.div`
  flex: 1 1 auto;
  font-size: 14px;
  line-height: 1.4;
  min-height: 90px;
  overflow-y: auto;
  padding: 10px 12px;
  position: relative;
`
const StyledCloseButton = styled(CloseButton)`
  && {
    border-radius: 50%;
    margin: 10px;
    position: absolute;
    right: 0;
    top: 0;
  }
`

export default ModalBody
