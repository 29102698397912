import { useCallback } from 'react'
import styled from 'styled-components'

import { Community } from '@sportsyou/api'
import { Colors } from '@sportsyou/core'

import FollowButton from '../../follow-button'

interface PostCommunityProps {
  buttonText?: string
  clickThroughText?: string
  clickThroughUrl?: string
  community: Community
  headline?: string
  hideFollowButton?: boolean
  onClickFollow?: (postId: string) => void
  postId: string
  tagline?: string
}

export const PostCommunity = ({
  buttonText,
  clickThroughText,
  clickThroughUrl,
  community,
  headline,
  hideFollowButton,
  onClickFollow,
  postId,
  tagline,
}: PostCommunityProps) => {
  const handleOnClick = useCallback(() => {
    onClickFollow?.(postId)
  }, [onClickFollow, postId])

  const onClickthrough = useCallback(() => {
    if (clickThroughUrl) {
      window.open(clickThroughUrl, '_blank')
    }
  }, [clickThroughUrl])

  return (
    <Container>
      <LeftColumn hasUrl={!!clickThroughUrl} onClick={onClickthrough}>
        {!!clickThroughText && (
          <ClickthroughText>{clickThroughText}</ClickthroughText>
        )}
        {!!headline && <Headline>{headline}</Headline>}
        {!!tagline && <Tagline>{tagline}</Tagline>}
      </LeftColumn>
      <RightColumn>
        {!hideFollowButton && (
          <FollowButton
            isFollowing={!!community.isFollowing}
            onClick={handleOnClick}
          />
        )}
      </RightColumn>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px;
  border-top: 1px solid ${Colors.ALTO};
  padding-top: 10px;
`

const LeftColumn = styled.div<{ hasUrl?: boolean }>`
  cursor: ${({ hasUrl }) => (hasUrl ? 'pointer' : undefined)};
  margin-left: 2px;
  margin-right: 10px;
  flex: 1;
`

const RightColumn = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  justify-content: flex-end;
`

const ClickthroughText = styled.div`
  color: ${Colors.SHUTTLE_GRAY};
  font-size: 14px;
`

const Headline = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  padding-bottom: 5px;
  padding-top: 5px;
`

const Tagline = styled.div`
  font-size: 16px;
`

export default PostCommunity
