import {
  Alignment,
  autoPlacement,
  autoUpdate,
  computePosition,
  FloatingFocusManager,
  FloatingPortal,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { Colors } from '@sportsyou/core'
import { useMediaQuery } from '@sportsyou/react-hooks'

import { getBoundingBox } from '../../utils'

export interface PopoverProps {
  borderColor: string
  children: React.ReactNode
  className?: string
  open: boolean
  pointerEvent?: 'auto' | 'none'
  setOpen: (open: boolean) => void
  showBorder: boolean
  target: React.RefObject<HTMLElement>
}

export interface Position {
  x: number
  y: number
}

export interface ContainerProps {
  borderColor: string
  open: boolean
  pointerEvent?: 'auto' | 'none'
  position: Position
  showBorder: boolean
}

export function Popover(props: PopoverProps) {
  const {
    borderColor,
    children,
    className,
    open,
    pointerEvent,
    setOpen,
    showBorder,
    target,
  } = props

  const [position, setPosition] = useState<Position>({ x: 0, y: 0 })
  const isSmallScreen = useMediaQuery(`(max-width: 639px)`)

  const { context } = useFloating({
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [autoPlacement(), shift()],
  })
  const { getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context),
    useRole(context),
  ])

  const ref = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (target.current && ref.current) {
      const { left: rowX } = getBoundingBox(target)
      const { innerWidth: screenWidth, innerHeight: screenHeight } = window

      let _alignment: Alignment | undefined

      const xPosition = rowX / screenWidth
      if (xPosition < 0.3333) {
        _alignment = 'start'
      } else if (xPosition > 0.6666) {
        _alignment = 'end'
      } else {
        _alignment = undefined
      }

      computePosition(target.current, ref.current, {
        middleware: [
          autoPlacement({
            allowedPlacements: [
              'bottom',
              'bottom-end',
              'bottom-start',
              'top-end',
              'top-start',
              'top',
            ],
            alignment: _alignment,
          }),
        ],
      }).then(({ x, y }) => {
        let _x = x
        let _y = y
        const { height: elHeight, width: elWidth } = getBoundingBox(ref)

        // if x position is offscreen, set it to the edge of viewport
        if (_x < 0) {
          _x = 0
        } else if (_x + elWidth > screenWidth) {
          _x = screenWidth - elWidth
        }

        // if y position is offscreen, set it to the edge of viewport
        if (_y < 0) {
          _y = 0
        } else if (_y + elHeight > screenHeight) {
          _y = screenHeight - elHeight
        }

        // center popover onsmall screens
        if (isSmallScreen && elWidth < screenWidth) {
          _x = (screenWidth - elWidth) / 2
        }

        setPosition({ x: _x, y: _y })
      })
    }
  }, [isSmallScreen, open, ref, target])

  return (
    <FloatingPortal>
      {open && (
        <FloatingFocusManager context={context} modal={true}>
          <Container
            className={className}
            borderColor={borderColor}
            open={open}
            pointerEvent={pointerEvent}
            position={position}
            showBorder={showBorder}
            ref={ref}
            {...getFloatingProps()}
          >
            {children}
          </Container>
        </FloatingFocusManager>
      )}
    </FloatingPortal>
  )
}

export default Popover

const Container = styled.div<ContainerProps>`
  background-color: ${Colors.WHITE};
  border-radius: 6px;
  box-shadow: 0px 4px 8px -2px rgb(0 0 0 / 35%);
  box-sizing: border-box;
  left: ${({ position }) => position.x}px;
  max-width: 100vw;
  min-width: 300px;
  padding: 10px;
  position: absolute;
  top: ${({ position }) => position.y}px;
  z-index: 10000;

  ${({ borderColor, showBorder }) =>
    showBorder &&
    css`
      border: 4px solid ${borderColor};
    `}

  ${({ open }) =>
    !open &&
    css`
      display: none;
    `}

  ${({ pointerEvent }) =>
    pointerEvent === 'none' &&
    css`
      pointer-events: none;
    `}
`
