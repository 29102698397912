import { CSSProperties, FC, PropsWithChildren, ReactNode } from 'react'
import styled from 'styled-components'

export interface NavbarCenterProps {
  children?: ReactNode
  className?: string
  style?: CSSProperties
}

export const NavbarCenter: FC<PropsWithChildren<NavbarCenterProps>> = ({
  children,
  className,
  style,
}: NavbarCenterProps) => (
  <Container className={className} style={style}>
    {children}
  </Container>
)

const Container = styled.div`
  align-items: center;
  display: flex;
`

export default NavbarCenter
