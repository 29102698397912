import React from 'react'
import styled from 'styled-components'

interface Props {
  className?: string
  contentMaxWidth?: number
  fullWidthContent?: boolean
  /**
   * Should the content fill the empty space. This is usefull when the parent
   * `Content` componenet is set to fullscreen.
   */
  stretch?: boolean
  style?: React.CSSProperties
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type ContentProps = Props & NativeAttributes

export const Content: React.FC<React.PropsWithChildren<ContentProps>> = ({
  children,
  className,
  stretch,
  style,
  contentMaxWidth = 900,
  fullWidthContent = false,
}) => {
  return (
    <Container
      className={className}
      contentMaxWidth={contentMaxWidth}
      fullWidthContent={fullWidthContent}
      stretch={stretch}
      style={style}
    >
      {children}
    </Container>
  )
}

const Container = styled.div<
  Pick<ContentProps, 'contentMaxWidth' | 'fullWidthContent' | 'stretch'>
>`
  flex: 1 1 auto;
  max-width: 100%;
  width: ${({ contentMaxWidth, stretch }) =>
    stretch ? '100%' : `${contentMaxWidth}px`};
`
// @media all and (min-width: ${({
//     asideWidth = 230,
//     contentMaxWidth = 900,
//     sidebarWidth = 230,
//   }) => asideWidth + contentMaxWidth + sidebarWidth}px) {
//   margin-left: auto;
//   margin-right: auto;
// }

export default Content
