import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { Colors, getTranscodeUrl, isGif } from '@sportsyou/core'
import {
  SponsoredPostInfo,
  Image as TranscodeImageProps,
  Upload as UploadProps,
} from '@sportsyou/api'

import Icon from '../../icon'

// export type PostUploadProps = {
//   id?: string
//   postId?: string
//   url?: string
// }

export type CurrentUploadProps = {
  allUploads: Array<UploadProps>
  // current: PostUploadProps
  currentIndex: number
}

interface Props {
  onClickUpload?: (data: CurrentUploadProps) => void
  postId?: string | null
  sponsoredPostInfo?: SponsoredPostInfo
  uploads?: Array<UploadProps>
}

type ImageLayout = 'single' | 'double' | 'triple'

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type PostUploadsProps = Props & NativeAttributes

export const PostUploads: React.FC<PostUploadsProps> = ({
  onClickUpload,
  postId,
  sponsoredPostInfo,
  uploads,
}: PostUploadsProps) => {
  const [imageLayout, setImageLayout] = useState<ImageLayout>('single')

  useEffect(() => {
    if (!uploads || uploads.length === 1) return
    if (uploads?.length === 2) {
      setImageLayout('double')
    } else if (uploads?.length > 2) {
      setImageLayout('triple')
    }
  }, [uploads?.length])

  if (!uploads || uploads?.length === 0) return null

  const handleOnClickUpload = (index: number) => {
    console.log({ sponsoredPostInfo })
    if (
      sponsoredPostInfo?.openWebLinkForImages &&
      sponsoredPostInfo.clickThroughUrl
    ) {
      window.open(sponsoredPostInfo.clickThroughUrl, '_blank')
    } else {
      const _uploads: UploadProps[] = uploads.map((up) => ({ ...up, postId }))
      onClickUpload?.({ allUploads: _uploads, currentIndex: index })
    }
  }

  function getViewableUrl(upload?: UploadProps, firstImage?: boolean): string {
    let url = ''

    if (!upload) return url

    const { transcodes } = upload
    let transcodeTypes: string[] = []

    // search order based on the order of the image and imageLayout
    switch (true) {
      case firstImage && imageLayout === 'single':
        transcodeTypes = ['feedLarge', 'feed', 'media', 'THUMB']
        break
      case firstImage && imageLayout === 'triple':
        transcodeTypes = ['feed', 'feedLarge', 'media', 'THUMB']
        break
      default:
        transcodeTypes = ['media', 'feed', 'feedLarge', 'THUMB']
    }

    // getTranscodeUrl has been updated to return url in the order of given transcodeTypes
    url = getTranscodeUrl({
      upload: transcodes as TranscodeImageProps[],
      transcodeTypes,
    })

    if ((isGif(upload.contentType ?? '') || !url) && upload.viewUrl) {
      url = upload.viewUrl
    }

    return url
  }

  const renderImageLayout = () => {
    const firstImage = uploads[0]
    const otherImages = uploads.filter((_, i) => i > 0 && i < 3)
    // amount of images left
    const count = uploads.length - 3
    const firstImageUrl = getViewableUrl(firstImage, true)

    if (!firstImageUrl) return null

    if (uploads.length === 1) {
      return (
        <LayoutContainer data-image-layout={imageLayout}>
          <ImageContainer onClick={() => handleOnClickUpload(0)}>
            <Image
              $isVideo={firstImage.contentType?.includes('video')}
              src={firstImageUrl}
              alt=''
            />
            {firstImage.contentType?.includes('video') && (
              <VideoThumbOverlay>
                <Icon
                  fill={Colors.WHITE}
                  height={26}
                  name='Play'
                  style={{ marginLeft: 6 }}
                  width={26}
                />
              </VideoThumbOverlay>
            )}
          </ImageContainer>
        </LayoutContainer>
      )
    }

    return (
      <LayoutContainer data-image-layout={imageLayout}>
        {/* FIRST IMAGE FLEXBOX */}
        <FirstCol>
          <a onClick={() => handleOnClickUpload(0)}>
            <Image
              $isVideo={firstImage.contentType?.includes('video')}
              src={firstImageUrl}
              alt=''
            />
            {firstImage.contentType?.includes('video') && (
              <VideoThumbOverlay>
                <Icon
                  fill={Colors.WHITE}
                  height={26}
                  name='Play'
                  style={{ marginLeft: 6 }}
                  width={26}
                />
              </VideoThumbOverlay>
            )}
          </a>
        </FirstCol>
        <SecondCol>
          {otherImages.map((img, i: number) => {
            return (
              <ImageContainer key={i}>
                <a onClick={() => handleOnClickUpload(i + 1)}>
                  <Image src={getViewableUrl(img)} alt='' />

                  {i < 2 && img.contentType?.includes('video') && (
                    <VideoThumbOverlay>
                      <Icon
                        fill={Colors.WHITE}
                        height={26}
                        name='Play'
                        style={{ marginLeft: 6 }}
                        width={26}
                      />
                    </VideoThumbOverlay>
                  )}

                  {uploads.length > 3 && i === 1 && (
                    <MoreImagesOverlay>+ {count}</MoreImagesOverlay>
                  )}
                </a>
              </ImageContainer>
            )
          })}
        </SecondCol>
      </LayoutContainer>
    )
  }

  return renderImageLayout()
}

const PaddingHack = css`
  content: '';
  display: block;
  padding-bottom: 100%;
`

const LayoutContainer = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-between;

  padding-left: 10px;
  padding-right: 10px;
`
const FirstCol = styled.div`
  overflow: hidden;
  position: relative;
  [data-image-layout='double'] &::after,
  [data-image-layout='triple'] &::after {
    ${PaddingHack};
  }
  [data-image-layout='double'] & {
    flex: 0 0 calc(50% - 10px);
  }
  [data-image-layout='triple'] & {
    flex: 0 0 calc(66.6666% - 10px);
  }
`
const SecondCol = styled.div`
  display: flex;
  flex: 0 0 50%;
  margin-left: 10px;
  overflow: hidden;
  position: relative;
  [data-image-layout='triple'] & {
    flex-basis: 33%;
    flex-direction: column;
    justify-content: space-between;
  }
`
const VideoThumbOverlay = styled.div`
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  border: 4px solid ${Colors.WHITE};
  color: ${Colors.WHITE};
  cursor: pointer;
  display: flex;
  // font-size: 26px;
  font-weight: 500;
  height: 60px;
  justify-content: center;
  left: 50%;
  margin-left: -30px;
  margin-top: -30px;
  opacity: 0.6;
  position: absolute;
  top: 50%;
  transition: opacity 120ms ease-in-out;
  width: 60px;
  z-index: 10;
`
const ImageContainer = styled.div`
  overflow: hidden;
  position: relative;

  [data-image-layout='single'] & {
    flex: 0 0 100%;
  }
  [data-image-layout='double'] & {
    flex: 0 0 100%;
  }
  [data-image-layout='triple'] & {
    display: flex;
    flex: 0 0 calc(50% - 5px);
    flex-direction: column;
  }

  [data-image-layout='double'] &::after,
  [data-image-layout='triple'] &::after {
    ${PaddingHack};
  }

  &:hover ${VideoThumbOverlay}, &:active ${VideoThumbOverlay} {
    opacity: 1;
  }
`
const Image = styled.img<{ $isVideo?: boolean }>`
  backface-visibility: hidden;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  display: block;
  transform-style: preserve-3d;
  width: 100%;

    ${({ $isVideo }) =>
      $isVideo
        ? {
            aspectRatio: `${16 / 9}`,
            objectFit: 'cover',
          }
        : undefined};

  [data-image-layout='double'] &,
  [data-image-layout='triple'] & {
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);Z
  }
`
const MoreImagesOverlay = styled.div`
  align-items: center;
  background-color: rgb(0 0 0 / 45%);
  cursor: pointer;
  color: ${Colors.WHITE};
  display: flex;
  font-size: 26px;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 10;
`

export default PostUploads
