import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { Colors } from '@sportsyou/core'

import TabsItem, { TabsItemProps } from './tabs-item'
import TabsExtraContent from './tabs-extra-content'

interface BarProps {
  animated?: boolean
  color?: string
  height?: number
}

interface Props {
  bar?: BarProps
  buttonStyle?: React.CSSProperties
  className?: string
  contentStyle?: React.CSSProperties
  extraContent?: React.ReactNode | JSX.Element
  /** Render all content on initial mountmount */
  forceRender?: boolean
  headerStyle?: React.CSSProperties
  hideContent?: boolean
  index?: number
  itemBarColor?: string
  onChange?: (index: number) => void
  style?: React.CSSProperties
  value?: string
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type TabsProps = Props & NativeAttributes

export interface TabsHeaderItem {
  children: React.ReactNode
  item: React.ReactNode
  title: string
  type?: string
}

export type TabsItemComponent = React.FC<TabsItemProps>

interface BarPosition {
  left: number
  top: number
  bottom: number
  right: number
  width: number
}

const Tabs: React.FC<React.PropsWithChildren<TabsProps>> = ({
  bar = {
    animated: true,
    height: 2,
    color: Colors.HAVELOCK_BLUE,
  },
  buttonStyle,
  children,
  className,
  contentStyle,
  extraContent,
  hideContent,
  forceRender = false,
  headerStyle,
  index = 0,
  itemBarColor = Colors.HAVELOCK_BLUE,
  onChange,
  style,
  ...props
}: TabsProps) => {
  const [currentIndex, setCurrentIndex] = useState<number>(index)
  const [initialLoad, setInitialLoad] = useState(true)
  const [tabs, setTabs] = useState<Array<TabsHeaderItem>>([])
  const [tabsExtraData, setTabsExtraData] = useState<React.ReactNode>()

  const [barPosition, setBarPosition] = useState<BarPosition>({
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
    width: 0,
  })

  const tabsRef = useRef<Array<HTMLDivElement>>([])
  const tabsHeaderRef = useRef<HTMLElement>(null)

  // const getTabItems = React.Children.map(children, (item, index) => {
  //   if (!React.isValidElement(item)) return null
  //   if (item.type === TabsItem) {
  //     return {
  //       children: item.props.children,
  //       item,
  //       title: item.props.title,
  //     }
  //   }
  //   return null
  // })

  const nodeData = useMemo(
    () =>
      React.Children.map(children, (item) => {
        if (!React.isValidElement(item)) return null
        if (item.type === TabsItem) {
          return {
            children: hideContent ? [] : item.props.children,
            item,
            title: item.props.title,
            type: 'tab',
          }
        }
        if (item.type === TabsExtraContent) {
          return {
            item,
            type: 'extra',
          }
        }
        return null
      }),
    [children]
  )

  interface TabsExtraItem {
    children: React.ReactNode
    item: React.ReactNode
    type?: string
  }

  // const tabItems = useMemo(
  //   () =>
  //     (nodeData as Array<TabsHeaderItem>).filter((item) => item.type === 'tab'),
  //   [nodeData]
  // )

  // const tabsExtra = useMemo(
  //   () =>
  //     (nodeData as Array<TabsExtraItem>)
  //       .filter((item) => item.type === 'extra')
  //       .flat()
  //       .map((item) => item.item),
  //   [nodeData]
  // )

  useEffect(() => {
    if (!nodeData) return
    const tabItems = (nodeData as TabsHeaderItem[]).filter(
      (item) => item.type === 'tab'
    )
    const tabsExtra = (nodeData as TabsExtraItem[])
      .filter((item) => item.type === 'extra')
      .flat()
      .map((item) => item.item)

    if (tabsExtra !== tabsExtraData) {
      setTabsExtraData(tabsExtra)
    }
    if (tabs !== tabItems) {
      setTabs(tabItems)
    }
    setTimeout(() => {
      setInitialLoad(false)
    }, 150)
  }, [nodeData])

  useEffect(() => {
    if (index !== undefined && index !== currentIndex) {
      setCurrentIndex(index)
    }
  }, [index])

  useEffect(() => {
    if (
      tabs.length > 0 &&
      tabsRef.current.length > 0 &&
      tabsHeaderRef.current
    ) {
      const tabHeaderBoundingBox = tabsHeaderRef.current.getBoundingClientRect()
      const tabItemBoundingBox =
        tabsRef.current[currentIndex].getBoundingClientRect()

      setBarPosition({
        bottom: tabItemBoundingBox.bottom - tabHeaderBoundingBox.bottom,
        left: tabItemBoundingBox.left - tabHeaderBoundingBox.left,
        right: tabItemBoundingBox.right - tabHeaderBoundingBox.right,
        top: tabItemBoundingBox.top - tabHeaderBoundingBox.top,
        width: tabItemBoundingBox.width,
      })
    }
    return () => {
      setBarPosition({
        left: 0,
        bottom: 0,
        right: 0,
        top: 0,
        width: 0,
      })
    }
  }, [currentIndex, tabs])

  const handleClickEvent = (index: number, itemClick?: () => void) => {
    if (index === currentIndex) return
    setCurrentIndex(index)
    onChange && onChange(index)
    itemClick && itemClick()
  }

  // const getTitle = (items: Array<React.ReactNode>) => {
  //   return items.map((item: React.ReactNode, index: number) => {
  //     return React.isValidElement(item) ? (
  //       <TabItemContainer
  //         key={index}
  //         ref={(el: HTMLDivElement) => (tabsRef.current[index] = el)}
  //       >
  //         <TabsItem
  //           active={index === currentIndex}
  //           disabled={item.props.disabled}
  //           onClick={() => handleClickEvent(index, item.props.onClick)}
  //           style={buttonStyle}
  //           title={item.props.title}
  //           value={currentIndex}
  //         />
  //       </TabItemContainer>
  //     ) : (
  //       ''
  //     )
  //   })
  // }

  // const renderActiveTab = () => {
  //   if (forceRender) return null
  //   return tabs.map(({ item, children }, index) => {
  //     if (!React.isValidElement(item)) return null
  //     return item.props.active || index === currentIndex ? (
  //       <TabContent role='tabpanel' key={index} style={contentStyle}>
  //         {children ? children : <p>This tab has no content</p>}
  //       </TabContent>
  //     ) : null
  //   })
  // }

  const renderAllTabs = useMemo(() => {
    if (!forceRender) return null
    return tabs.map(({ item, children }, index) => {
      if (!React.isValidElement(item)) return null
      return (
        <TabContent key={index} role='tabpanel' style={contentStyle}>
          {children}
        </TabContent>
      )
    })
  }, [tabs])

  if (!children) return null

  return (
    <Container
      className={className}
      currentIndex={currentIndex}
      forceRender={forceRender}
      style={style}
      {...props}
    >
      <TabHeader ref={tabsHeaderRef} role='tablist' style={headerStyle}>
        {tabs.map(({ item, title }, index) => {
          if (!React.isValidElement(item)) return null
          // const test = getBoundingBox(ref)
          // console.log(index === currentIndex)
          return (
            <TabItemContainer
              key={index}
              ref={(el: HTMLDivElement) => (tabsRef.current[index] = el)}
            >
              <TabsItem
                active={index === currentIndex}
                disabled={item.props.disabled}
                onClick={() => handleClickEvent(index, item.props.onClick)}
                style={buttonStyle}
                title={title}
                value={currentIndex}
              />
            </TabItemContainer>
          )
        })}

        {extraContent && (
          <ExtraContentContainer>{extraContent}</ExtraContentContainer>
        )}

        {tabsExtraData && tabsExtraData}

        <Bar
          style={{
            height: bar.height,
            backgroundColor: itemBarColor,
            bottom: barPosition.bottom,
            left: barPosition.left,
            right: barPosition.right,
            transition: !bar.animated
              ? 'none'
              : initialLoad
              ? 'left 300ms, right 300ms'
              : 'width 300ms, left 300ms, right 300ms',
            width: barPosition.width,
          }}
        />
      </TabHeader>

      {forceRender
        ? renderAllTabs
        : tabs.map(({ item, children }, index) => {
            if (!React.isValidElement(item)) return null
            return item.props.active || index === currentIndex ? (
              <TabContent role='tabpanel' key={index} style={contentStyle}>
                {children}
              </TabContent>
            ) : null
          })}
    </Container>
  )
}

const TabContent = styled.div<{ $active?: boolean; forceRender?: boolean }>`
  display: ${({ $active, forceRender }) =>
    forceRender ? ($active ? 'block' : 'none') : 'block'};
  padding: 15px;
`
const Container = styled.div<{ currentIndex: number; forceRender: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: 90px;
  // min-width: 400px;
  position: relative;

  & > ${TabContent} {
    display: ${({ forceRender }) => (forceRender ? 'none' : 'block')};
  }

  & > ${TabContent}:nth-of-type(${({ currentIndex }) => currentIndex + 1}) {
    display: block;
  }
`
const TabHeader = styled.header`
  align-items: center;
  background-color: ${Colors.WHITE};
  border-bottom: 1px solid ${Colors.ALTO};
  display: flex;
  position: relative;
  transition: transform 300ms;
`
const TabItemContainer = styled.div`
  position: relative;
`
const Bar = styled.div`
  height: 3px;
  position: absolute;
  pointer-events: none;
`
const ExtraContentContainer = styled.div`
  margin-left: auto;
  & + div {
    margin-left: 10px;
  }
`

export default Tabs
