import { Helmet } from 'react-helmet-async'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Breadcrumbs, Spinner, Tabs } from '@sportsyou/react-dom-ui'
import { Campaign } from '@sportsyou/api'
import { Colors } from '@sportsyou/core'
import { ContentContainer } from 'apps/web/src/styles/global-styles'
import { Home } from '@sportsyou/react-icons'
import CampaignEditTab from './TabEdit'
import styled from 'styled-components'
import useCampaignPortal from '../UseCampaignPortal'
import CampaignMetricsTab from './TabMetrics'

interface CampaignDetailScreenProps {
  campaignId?: string
}

export default function CampaignDetailScreen(props: CampaignDetailScreenProps) {
  const navigate = useNavigate()
  const params = useParams()

  const {
    currentCampaign: campaign,
    isLoading,
    loadCampaign,
    loadSponsors,
    pathCampaignDetail,
    pathCampaigns,
  } = useCampaignPortal()

  const activeTab = useMemo(() => {
    if (params.tab === 'edit') {
      return 1
    } else {
      return 0
    }
  }, [params.tab])

  const onChangeTabs = useCallback(
    (index: number) => {
      if (campaign?.id) {
        if (index === 1) {
          navigate(pathCampaignDetail(campaign, 'edit'))
        } else {
          navigate(pathCampaignDetail(campaign, 'metrics'))
        }
      }
    },
    [campaign, navigate, pathCampaignDetail]
  )

  const handleCancel = useCallback(() => {
    navigate(pathCampaigns())
  }, [])

  const breadcrumbItems = useMemo(() => {
    let text: string | React.ReactNode = 'Create a Campaign'

    if (campaign?.id) {
      text = campaign?.campaignName ?? ''
    } else if (props.campaignId || params.id) {
      text = <Spinner size={24} />
    }

    return [
      {
        icon: <Home color={Colors.BLACK} />,
        onClick: handleCancel,
      },
      { text },
    ]
  }, [campaign, campaign?.id])

  useEffect(() => {
    loadSponsors()
  }, [])

  useEffect(() => {
    if (params.id) {
      loadCampaign(params.id)
    }
  }, [props.campaignId ?? params.id])

  return (
    <ContentContainer>
      <Helmet>
        <title>sportsYou - Campaign Editor</title>
      </Helmet>
      <Breadcrumbs items={breadcrumbItems} />

      {params.id ? (
        <Tabs
          contentStyle={tabsContentStyle}
          onChange={onChangeTabs}
          tabIndex={activeTab}
        >
          <Tabs.Item title='Metrics'>
            <CampaignMetricsTab campaign={campaign} />
          </Tabs.Item>
          <Tabs.Item title='Edit'>
            <CampaignEditTab campaignId={params.id} />
          </Tabs.Item>
        </Tabs>
      ) : (
        <CampaignEditTab />
      )}
    </ContentContainer>
  )
}

const tabsContentStyle = {
  padding: '20px 0',
}

const NavButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`
