import React, { useMemo } from 'react'
import { useDrag } from 'react-dnd'
import styled from 'styled-components'

import { Event } from '@sportsyou/api'
import { checkColorValue, Colors } from '@sportsyou/core'

interface Props {
  event: Event
  fill?: string
  fillWithGameType?: boolean
  onClick?: (event: Event, e: React.MouseEvent<HTMLDivElement>) => void
  style?: React.CSSProperties
  time: string
}

type NativeAttributes = Omit<React.HTMLAttributes<any>, keyof Props>
export type DayEventChipProps = Props & NativeAttributes

export const DayEventChip: React.FC<DayEventChipProps> = ({
  event,
  fill = Colors.HAVELOCK_BLUE,
  fillWithGameType,
  onClick,
  style,
  time,
}: DayEventChipProps) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'EVENT',
    item: event,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }))

  const fillColor = useMemo(() => {
    if (fillWithGameType) {
      if (event.type === 'game') {
        return Colors.PUNCH
      } else {
        return Colors.HAVELOCK_BLUE
      }
    } else {
      return fill
    }
  }, [event.type, fill, fillWithGameType])

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
    onClick && onClick(event, e)
  }

  return (
    <Container
      $fill={fillColor}
      ref={event.isEditable ? drag : undefined}
      onClick={handleOnClick}
      isDragging={isDragging}
      style={style}
    >
      <Time style={{ color: checkColorValue(fill) }}>{time}</Time>
      <Label style={{ color: checkColorValue(fill) }} title={event.title!}>
        {event.title}
      </Label>
    </Container>
  )
}

const Container = styled.div<{ $fill: string; isDragging: boolean }>`
  background-color: ${({ $fill }) => $fill};
  border-radius: 2px;
  cursor: pointer;
  margin-bottom: 2px;
  margin-left: 2px;
  margin-right: 2px;
  opacity: ${({ isDragging }) => (isDragging ? 0.4 : 1)};
  overflow: hidden;
`

const Time = styled.div`
  color: ${Colors.WHITE};
  font-size: 10px;
  line-height: 10px;
  padding: 2px 5px;
`

const Label = styled.div`
  color: ${Colors.WHITE};
  font-size: 14px;
  font-weight: 700;
  line-break: anywhere;
  line-height: 14px;
  padding: 2px 4px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
`

export default DayEventChip
