// BUG: Datepicker error when wrapping in styled-components
// TODO: Fixed styled-components issue with date picker
import { FocusEvent, useCallback } from 'react'
import { parseISO } from 'date-fns'
import ReactDatePicker from 'react-datepicker'
import styled from 'styled-components'

import 'react-datepicker/dist/react-datepicker.css'

import { Colors } from '@sportsyou/core'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_SIZE_SM,
  LINE_HEIGHT,
} from '../../theme'
import { Validation } from '../shared-types'

type TestIds = {
  container?: string
  datePicker?: string
  label?: string
  validation?: string
}
export interface DatePickerProps {
  className?: string
  datePickerStyle?: React.CSSProperties
  dateFormat?: string
  disabled?: boolean
  endDate?: Date
  highlightDates?: Array<string | Date>
  id?: string
  label?: string
  labelStyle?: React.CSSProperties
  maxDate?: Date
  maxTime?: Date
  minDate?: Date
  minTime?: Date
  name?: string
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  onFocus?: (event: React.FocusEvent) => void
  onChange?: (date: Date) => void
  onChangeRaw?: (event: FocusEvent<HTMLInputElement, Element>) => void
  placeholder?: string
  scrollableYearDropdown?: boolean
  selected?: Date
  shouldCloseOnSelect?: boolean
  showTimeSelect?: boolean
  showTimeSelectOnly?: boolean
  showYearDropdown?: boolean
  showYearPicker?: boolean
  startDate?: Date
  style?: React.CSSProperties
  timeIntervals?: number
  validation?: Validation
  value?: string
  yearDropdownItemNumber?: number
  placement?: 'top' | 'right' | 'bottom' | 'left'
  testId?: TestIds
}

export const DatePicker = (props: DatePickerProps) => {
  const { onBlur, onChange } = props

  const handleOnBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      onBlur?.(event)
    },
    [onBlur]
  )

  const handleOnChange = useCallback(
    (date: Date | null) => {
      date && onChange?.(date)
    },
    [onChange]
  )

  const stringToDate = (date?: Date | string): Date | undefined => {
    if (typeof date === 'string') {
      return parseISO(date)
    }
    return date
  }

  const stringsToDates = (dates: Array<string | Date>): Date[] => {
    return dates.map((date) => stringToDate(date) as Date)
  }

  const renderDatePicker = () => {
    return (
      <StyledReactDatePicker
        $style={props.datePickerStyle}
        className={props.className}
        data-testid={props.testId?.datePicker}
        dateFormat={props.dateFormat}
        disabled={props.disabled}
        endDate={stringToDate(props.endDate)}
        highlightDates={stringsToDates(props.highlightDates ?? [])}
        id={props.id}
        maxDate={stringToDate(props.maxDate)}
        maxTime={stringToDate(props.maxTime)}
        minDate={stringToDate(props.minDate)}
        minTime={stringToDate(props.minTime)}
        name={props.name}
        onBlur={handleOnBlur}
        onFocus={props.onFocus}
        onChange={handleOnChange}
        onChangeRaw={props.onChangeRaw}
        placeholderText={props.placeholder}
        popperPlacement={props.placement}
        scrollableYearDropdown={props.scrollableYearDropdown}
        selected={stringToDate(props.selected)}
        shouldCloseOnSelect={props.shouldCloseOnSelect}
        showPopperArrow={false}
        showTimeSelect={props.showTimeSelect}
        showTimeSelectOnly={props.showTimeSelectOnly}
        showYearDropdown={props.showYearDropdown}
        showYearPicker={props.showYearPicker}
        startDate={stringToDate(props.startDate)}
        timeIntervals={props.timeIntervals ?? 15}
        value={props.value}
        yearDropdownItemNumber={props.yearDropdownItemNumber}
      />
    )
  }

  return (
    <Container
      className={props.className}
      data-testid={props.testId?.container}
      style={props.style}
    >
      {props.label ? (
        <Label data-testid={props.testId?.label} validation={props.validation}>
          {props.label && (
            <LabelText style={props.labelStyle}>{props.label}</LabelText>
          )}
          {renderDatePicker()}
        </Label>
      ) : (
        renderDatePicker()
      )}
      {props.validation?.isVisible && (
        <ValidationMessage
          data-testid={props.testId?.validation}
          status={props.validation.status}
        >
          {props.validation.message}
        </ValidationMessage>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: min-content;
  max-width: 100%;
`
const Label = styled.label<{ validation?: Validation }>`
  background-color: ${Colors.WHITE};
  border: ${({ validation }) =>
    validation?.isVisible
      ? `1px solid ${
          validation.status === 'error' ? Colors.MONZA : Colors.MOUNTAIN_MEADOW
        }`
      : `1px solid ${Colors.ALTO}`};
  border-radius: ${BORDER_RADIUS};
  box-sizing: border-box;
`
const ValidationMessage = styled.div<{ status: Validation['status'] }>`
  color: ${({ status }) =>
    status === 'error' ? Colors.MONZA : Colors.MOUNTAIN_MEADOW};
  font-size: ${FONT_SIZE_SM}px;
  font-weight: 700;
  margin: 8px 10px 0;
  text-transform: uppercase;
`

const LabelText = styled.div`
  color: ${Colors.PUNCH};
  font-size: ${FONT_SIZE_SM}px;
  font-weight: 700;
  margin: 6px 10px 0;
  text-transform: uppercase;
`

const StyledReactDatePicker = styled(ReactDatePicker)<{
  disabled?: boolean
  $style?: React.CSSProperties
}>`
  background-color: transparent;
  border: 0;
  color: ${Colors.MINE_SHAFT};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-family: inherit;
  font-size: ${FONT_SIZE}px;
  font-weight: 400;
  line-height: ${LINE_HEIGHT};
  max-width: 100%;
  min-width: 190px;
  padding: 6px 10px;
  width: 100%;
  ${({ $style }) => ($style ? { ...$style } : undefined)};

  &:focus {
    outline: none;
  }
`

export default DatePicker
