// https://stackoverflow.com/a/34255423

export interface SleepPromise extends Promise<unknown> {
  // TODO change type
  // eslint-disable-next-line @typescript-eslint/ban-types
  cancel?: Function
}

export function sleep(ms: number) {
  let ctr: NodeJS.Timeout
  // TODO set type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let rej: (reason?: any) => void
  const p: SleepPromise = new Promise(function (resolve, reject) {
    ctr = setTimeout(resolve, ms)
    rej = reject
  })

  p.cancel = () => {
    clearTimeout(ctr)
    rej(Error('Cancelled'))
  }

  return p
}

export default sleep
