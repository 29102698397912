import React, { useCallback, useState } from 'react'
import moment from 'moment'

// import {
//   Post as ApiPostProps,
//   TeamMember,
//   Upload,
//   VideoPlaylistSummary,
// } from '@sportsyou/api'
import { capitalize } from '@sportsyou/core'

// import type { PollChoiceReturnData } from './modules'
import {
  CurrentUploadProps,
  // PostAttachmentsProps,
  PostMenuAction,
} from './modules'
// import { ReportCommentParams } from '../comment'
import PostContainer, { PostContainerProps } from './post-container'
import { PostProps } from './post-types'

export type UserProps = {
  id?: string
  name?: string
  profileImage?: string
}

export const Post: React.FC<PostProps> = ({
  activeCommentId,
  allowComments = true,
  attachments,
  autoFocusCommentInput,
  canEdit,
  canHidePost,
  canPin,
  className,
  color,
  comments,
  community,
  communityPostInfo,
  createdAt = moment().fromNow(),
  createdBy,
  currentUserId,
  disableCommentsModal,
  disableLikesModal,
  hasMoreComments,
  hideFollowButton,
  hideMenu,
  id = null,
  isAnnouncement,
  isCreator = false,
  isEdited = false,
  isFetchingCount = false,
  isFetchingMoreComments,
  isLiked = false,
  isOwner,
  isPending = false,
  isPinned = false,
  message,
  metadata,
  newCommentSuccessful,
  namesWhoCommented,
  namesWhoLiked,
  numberComments,
  numberLikes,
  onClick,
  onClickAdFeedback,
  onClickApprove,
  onClickAvatar,
  onClickCommentAuthor,
  onClickCommentButton,
  onClickCommentCount,
  onClickCreateComment,
  onClickDelete,
  onClickDeleteScheduledPost,
  onClickDisableComments,
  onClickDownloadUpload,
  onClickEdit,
  onClickEditScheduledPost,
  onClickFollow,
  onClickHelmetProfile,
  onClickHide,
  onClickHideAd,
  onClickLikeButton,
  onClickLikeCount,
  onClickMoreComments,
  onClickPin,
  onClickPollChoice,
  onClickPollVotes,
  onClickProfileName,
  onClickReject,
  onClickRemoveVote,
  onClickReport,
  onClickSaveUpload,
  onClickTeamProfile,
  onClickToggleComments,
  onClickTogglePin,
  onClickUpload,
  onClickUserProfile,
  onClickVideoPlaylistCard,
  onClickViewedBy,
  onClickViewUpload,
  onDeleteComment,
  onExpand,
  onHoverCommentCount,
  onHoverLikeCount,
  onReportComment,
  onViewed,
  playlists,
  poll,
  posts,
  scheduledTime,
  scheduleId,
  showCommentListComponent = false,
  sortComments = 'descending',
  sponsoredPostInfo,
  team,
  uploads,
  viewed,
  viewCount,
}: PostProps) => {
  const [isScheduledPost, _setIsScheduledPost] = useState<boolean>(!!scheduleId)

  const buildMenuActions = useCallback(
    (post?: PostProps) => {
      const actions: Array<PostMenuAction> = []
      // set up props to handle both single and multi post data
      const _props = {
        allowComments: post?.allowComments ?? allowComments,
        canEdit: post?.canEdit ?? canEdit,
        canPin: post?.canPin ?? canPin,
        isAnnouncement: post?.isAnnouncement || isAnnouncement,
        isOwner: post?.isOwner ?? isOwner,
        isPinned: post?.isPinned ?? isPinned,
        isSponsoredPost: !!post?.sponsoredPostInfo || !!sponsoredPostInfo,
        poll: post?.poll ?? poll,
        team: post?.team ?? team,
      }

      if (_props.isOwner) {
        // edit post
        if (_props.canEdit && !_props.poll) {
          if (post) {
            post.onClickEdit &&
              actions.push({
                name: 'Edit Post',
                fn: () => post.onClickEdit && post.onClickEdit(post),
              })
          } else {
            onClickEdit &&
              actions.push({
                name: 'Edit Post',
                fn: () =>
                  onClickEdit({
                    ..._props,
                    createdBy,
                    id,
                    message,
                  }),
              })
          }
        }

        //  disable/enable comments
        if (post) {
          post.onClickToggleComments &&
            actions.push({
              name: `${_props.allowComments ? 'Disable' : 'Enable'} Comments`,
              fn: () =>
                post.onClickToggleComments && post.onClickToggleComments(post),
            })
        } else {
          onClickToggleComments &&
            actions.push({
              name: `${_props.allowComments ? 'Disable' : 'Enable'} Comments`,
              fn: () =>
                onClickToggleComments &&
                onClickToggleComments({
                  ..._props,
                  id,
                }),
            })
        }

        // pinning posts
        if (_props.canPin && _props.team) {
          if (post) {
            post.onClickTogglePin &&
              actions.push({
                name: `${
                  _props.isPinned ? 'Unpin from' : 'Pin to'
                } ${capitalize(_props.team?.type ?? 'team')} Page`,
                fn: () => post.onClickTogglePin && post.onClickTogglePin(),
              })
          } else {
            onClickTogglePin &&
              actions.push({
                name: `${
                  _props.isPinned ? 'Unpin from' : 'Pin to'
                } ${capitalize(_props.team?.type ?? 'team')} Page`,
                fn: () => onClickTogglePin && onClickTogglePin(),
              })
          }
        }

        if (actions.length > 1) actions.push('divider')

        // delete post
        if (post) {
          post.onClickDelete &&
            actions.push({
              name: 'Delete Post',
              fn: () => {
                post.onClickDelete?.(post.id as string)
              },
              isDestructive: true,
            })
        } else {
          onClickDelete &&
            actions.push({
              name: 'Delete Post',
              fn: () => {
                console.log('test')
                onClickDelete(id as string, {
                  ..._props,
                  id,
                  uploads,
                })
              },
              isDestructive: true,
            })
        }
      } else {
        // report post
        if (!_props.isSponsoredPost && !_props.isAnnouncement) {
          if (post) {
            post.onClickReport &&
              actions.push({
                name: 'Report User',
                fn: () => post.onClickReport?.(),
              })
          } else {
            onClickReport &&
              actions.push({
                name: 'Report User',
                fn: () => onClickReport(),
              })
          }
        }

        // Ad Feedback and Hide Ads
        if (_props.isSponsoredPost) {
          if (post) {
            post.onClickAdFeedback &&
              actions.push({
                name: 'Ad Feedback',
                fn: () => post.onClickAdFeedback?.(),
              })
            post.onClickHide &&
              actions.push({
                name: 'Hide Post',
                fn: () => post.onClickHide?.(post.id as string),
              })
          } else {
            onClickAdFeedback &&
              actions.push({
                name: 'Ad Feedback',
                fn: () => onClickAdFeedback(),
              })
            onClickHide &&
              actions.push({
                name: 'Hide Post',
                fn: () => onClickHide(id as string),
              })
          }
        } else if (_props.isAnnouncement) {
          // only show hide post if not a team feed
          if (canHidePost) {
            if (post) {
              post.onClickHide &&
                actions.push({
                  name: 'Hide This Post',
                  fn: () => post.onClickHide?.(post.id as string),
                })
            } else {
              onClickHide &&
                actions.push({
                  name: 'Hide This Post',
                  fn: () => onClickHide(id as string),
                })
            }
          }
        } else {
          // only show hide post if not a team feed
          if (canHidePost) {
            if (post) {
              post.onClickHide &&
                actions.push({
                  name: 'Hide Post',
                  fn: () => post.onClickHide?.(post.id as string),
                })
            } else {
              onClickHide &&
                actions.push({
                  name: 'Hide Post',
                  fn: () => onClickHide(id as string),
                })
            }
          }
        }
      }

      return actions
    },
    [
      allowComments,
      canEdit,
      canHidePost,
      canPin,
      createdBy,
      id,
      isAnnouncement,
      isOwner,
      isPinned,
      message,
      onClickAdFeedback,
      onClickDelete,
      onClickEdit,
      onClickHide,
      onClickReport,
      onClickToggleComments,
      onClickTogglePin,
      poll,
      sponsoredPostInfo,
      team,
      uploads,
    ]
  )

  const handleOnClickUpload = (data: CurrentUploadProps) => {
    onClickUpload && onClickUpload(data)
  }
  const handleOnClickDeleteScheduledPost = (id: string) => {
    onClickDeleteScheduledPost && onClickDeleteScheduledPost(id)
  }
  const handleOnClickEditScheduledPost = (id: string, time: string) => {
    onClickEditScheduledPost && onClickEditScheduledPost(id, time)
  }

  const sharedProps: PostContainerProps = {
    activeCommentId,
    allowComments,
    attachments,
    autoFocusCommentInput,
    className,
    color: color ?? undefined,
    comments,
    community,
    communityPostInfo,
    createdAt,
    createdBy,
    currentUserId,
    disableCommentsModal,
    disableLikesModal,
    hasMoreComments,
    hideFollowButton,
    hideMenu,
    id,
    isCreator,
    isEdited,
    isFetchingCount,
    isFetchingMoreComments,
    isLiked,
    isOwner,
    isPending,
    isPinned,
    isScheduledPost,
    message,
    metadata,
    namesWhoCommented,
    namesWhoLiked,
    newCommentSuccessful,
    numberOfComments: numberComments ?? 0,
    numberOfLikes: numberLikes ?? 0,
    onClick,
    onClickAdFeedback,
    onClickApprove,
    onClickAvatar,
    onClickCommentAuthor,
    onClickCommentButton,
    onClickCommentCount,
    onClickCreateComment,
    onClickDelete,
    onClickDeleteScheduledPost: handleOnClickDeleteScheduledPost,
    onClickDownloadUpload,
    onClickEdit,
    onClickEditScheduledPost: handleOnClickEditScheduledPost,
    onClickFollow,
    onClickHelmetProfile,
    onClickHide,
    onClickHideAd,
    onClickLikeButton,
    onClickLikeCount,
    onClickMoreComments,
    onClickPollChoice,
    onClickPollVotes,
    onClickProfileName,
    onClickReject,
    onClickRemoveVote,
    onClickReport,
    onClickSaveUpload,
    onClickTeamProfile,
    onClickToggleComments,
    onClickTogglePin,
    onClickUpload: handleOnClickUpload,
    onClickUserProfile,
    onClickVideoPlaylistCard,
    onClickViewedBy,
    onClickViewUpload,
    onDeleteComment,
    onHoverCommentCount,
    onHoverLikeCount,
    onReportComment,
    onViewed,
    playlists,
    poll,
    showCommentListComponent,
    sortComments,
    sponsoredPostInfo,
    team,
    uploads,
    viewed,
    viewCount,
  }

  const renderSinglePost = () => (
    <PostContainer {...sharedProps} menuActions={buildMenuActions()} />
  )

  const renderScheduledPost = () => (
    <PostContainer
      {...sharedProps}
      menuActions={buildMenuActions()}
      posts={posts}
      team={team ?? posts?.[0]?.team ?? undefined}
      scheduleId={scheduleId}
      scheduledTime={scheduledTime}
    />
  )

  const renderMultiPost = () => (
    <>
      {(posts as PostProps[]).map((post: PostProps, i: number) => {
        return (
          <PostContainer
            {...sharedProps}
            {...post}
            color={post.color ?? undefined}
            comments={posts?.[i].comments}
            hideToggleButton={i === 0}
            isMultiPost
            key={post.id}
            menuActions={buildMenuActions({ ...sharedProps, ...post })}
            minimized={i !== 0}
            numberOfComments={post.numberComments ?? 0}
            numberOfLikes={post.numberLikes ?? 0}
            onExpand={onExpand}
            postsIndex={i}
          />
        )
      })}
    </>
  )

  return scheduleId
    ? renderScheduledPost()
    : posts?.length
    ? renderMultiPost()
    : renderSinglePost()
}

export default Post
