import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { queryCommunities, querySponsors } from '@sportsyou/api'
import { useFetchApi } from '@sportsyou/react-hooks'

import { selectPortalAccess, updatePortalAccess } from './store/slices'

export type ContentPortalId =
  | 'sponsoredPosts'
  | 'campaigns'
  | 'pages'
  | undefined

interface UseContentPortalProps {
  PortalId?: ContentPortalId
}

export default function UseContentPortal(props?: UseContentPortalProps) {
  const dispatch = useDispatch()

  const { fetch: fetchPages } = useFetchApi(queryCommunities)
  const { fetch: fetchSponsors } = useFetchApi(querySponsors)

  const portalAccess = useSelector(selectPortalAccess)

  const [isLoading, setIsLoading] = useState(true)

  const checkSponsoredPostPermissions = useCallback(async () => {
    const userFeatures = JSON.parse(
      localStorage.getItem('user-features') ?? '[]'
    ) as string[]
    const hasAccess =
      userFeatures.includes('SPONSORED_POST_CREATE') ||
      userFeatures.includes('SPONSORED_POST_MANAGE') ||
      userFeatures.includes('SPONSORED_POST_STATS')
    dispatch(
      updatePortalAccess({
        sponsoredPosts: hasAccess,
      })
    )
    return hasAccess
  }, [dispatch])

  const hasAnyPortalAccess = useMemo(() => {
    return Object.values(portalAccess).some((access) => access)
  }, [portalAccess])

  const canAccessPortal = useCallback(
    (portalId?: ContentPortalId) => {
      return portalAccess[portalId ?? ''] ?? false
    },
    [portalAccess]
  )

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    const { data: sponsors } = await fetchSponsors()
    const sponsorAccess = (sponsors?.length ?? 0) > 0

    const { data: pages } = await fetchPages({ adminsOnly: true })
    const pagesAccess = (pages?.length ?? 0) > 0

    const sponsoredPostAccess = await checkSponsoredPostPermissions()

    setIsLoading(false)

    const access = {
      campaigns: sponsorAccess,
      pages: pagesAccess,
      sponsoredPosts: sponsoredPostAccess,
    }
    dispatch(updatePortalAccess(access))
    return access
  }, [checkSponsoredPostPermissions, fetchPages, fetchSponsors])

  const verifyHasPortalAccess = useCallback(async () => {
    const access = await fetchData()
    return Object.values(access).some((hasAccess) => hasAccess)
  }, [fetchData])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return {
    canAccessPortal,
    fetchData,
    hasAnyPortalAccess,
    isLoading,
    portalAccess,
    verifyHasPortalAccess,
  }
}
