import * as React from 'react'
import type { SVGProps } from 'react'
const FileJpg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 537.7 661.8'
    width='1em'
    height='1em'
    style={props.style}
    {...props}
  >
    <path
      fill='#00675b'
      d='M113.7 579h268.8a20.74 20.74 0 0 0 20.7-20.7V351.6a20.74 20.74 0 0 0-20.7-20.7H113.7A20.74 20.74 0 0 0 93 351.6v206.8a20.72 20.72 0 0 0 20.7 20.6zm268.9-68.2v47.5H113.7v-47.5l82.7-82.7 64.6 64.6c.2.2.2.5.4.7s.5.2.7.4l20.2 20.2a10.25 10.25 0 0 0 14.5-14.5L283.4 486l37.2-37.2zM113.5 351.6h269v130.2l-54.2-54.2c-.2-.2-.2-.5-.4-.7a10.63 10.63 0 0 0-14.8 0c-.2.2-.3.5-.5.7l-43.9 43.9-64.6-64.6c-.2-.2-.2-.5-.4-.7a10.63 10.63 0 0 0-14.8 0c-.2.2-.3.5-.5.7l-74.9 74.9z'
    />
    <path
      fill='#00675b'
      d='M517 82.7h-20.7v-62A20.74 20.74 0 0 0 475.6 0h-455A20.72 20.72 0 0 0 0 20.7v620.4a20.74 20.74 0 0 0 20.7 20.7h455a20.74 20.74 0 0 0 20.7-20.7V268.8H517a20.74 20.74 0 0 0 20.7-20.7V103.4A20.74 20.74 0 0 0 517 82.7zm-41.3 0H227.5a20.74 20.74 0 0 0-20.7 20.7v144.8a20.74 20.74 0 0 0 20.7 20.7h248.2v372.2h-455V20.7h455zm-63.6 105.2a22.31 22.31 0 0 0 8.4 9.3 21.88 21.88 0 0 0 12.1 3.4 20.9 20.9 0 0 0 9.2-2 23.17 23.17 0 0 0 7-4.7v-10.5h-20v-12.8h35.7v28.6a40.69 40.69 0 0 1-14 10.4 41.86 41.86 0 0 1-17.9 3.7 42.6 42.6 0 0 1-20.1-4.8 35.83 35.83 0 0 1-14.4-13.7 38.21 38.21 0 0 1-5.3-20.3 39.25 39.25 0 0 1 5.3-20.4 36.89 36.89 0 0 1 14.4-13.6 42.6 42.6 0 0 1 20.1-4.8c7.3 0 13.5 1.4 18.6 4.1a33.83 33.83 0 0 1 12.4 11l-13.3 7.2a22.37 22.37 0 0 0-7.5-6.8 20 20 0 0 0-10.3-2.6 21.88 21.88 0 0 0-12.1 3.4 24.36 24.36 0 0 0-8.4 9.3 29.22 29.22 0 0 0-3 13.3 30.29 30.29 0 0 0 3.1 13.3zm-25.7-26.7a25 25 0 0 1-3 12.1 22.21 22.21 0 0 1-8.6 8.6 27.5 27.5 0 0 1-13.5 3.2h-19.1V212h-16v-74.9h35a26.86 26.86 0 0 1 13.6 3.3 21.79 21.79 0 0 1 8.6 8.7 24.13 24.13 0 0 1 3 12.1zm-75-24.1v50.4c0 8.6-2.4 15-7.2 19.4s-11.2 6.6-19.2 6.6q-12.75 0-20.1-6.6l7-11.6c3.7 3.6 7.7 5.4 11.9 5.4a11.45 11.45 0 0 0 8.5-3.3 12.52 12.52 0 0 0 3.2-9v-51.3z'
    />
    <path
      fill='#00675b'
      d='M359.1 149.9h-17v22.5h17a10.47 10.47 0 0 0 7.9-3.1 12.13 12.13 0 0 0 0-16.2 10.33 10.33 0 0 0-7.9-3.2z'
    />
  </svg>
)
export default FileJpg
