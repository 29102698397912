import React, { PropsWithChildren, ReactNode, useMemo } from 'react'
import styled from 'styled-components'
import { Colors } from '@sportsyou/core'
import { useMediaQuery } from '@sportsyou/react-hooks'

import NavbarCenter from './navbar-center'
import NavbarLeft from './navbar-left'
import NavbarRight from './navbar-right'
import { getChildrenFromReactNode, getPropsFromReactNode } from '../../utils'
import { SITE_MAX_WIDTH } from '../../theme'

export interface NavbarProps {
  children?: ReactNode
  className?: string
  /**
   * Width of the content container within the navbar.
   *
   * If using a number, the pixel unit is assumed. If using a string, you must
   * to provide the CSS unit, e.g '2rem'.
   *
   * @default '1200'
   */
  contentWidth?: number | string
  /**
   * Create a fixed navbar that will scroll with the page
   */
  fixed?: 'bottom' | 'top'
  /**
   * This determines the `max-width` of the navbar content if set to true this
   * will match the `contentWidth`
   */
  matchContentWidth?: boolean
  mobileBreakpoint?: number
  /**
   * The ARIA role for the navbar, will default to 'banner'.
   *
   * @default 'banner'
   */
  role?: string
  /**
   * Position the navbar at the top of the viewport, but only after scrolling past it.
   * Not supported in <= IE11 and other older browsers without a polyfill
   *
   * @default false
   */
  sticky?: boolean
  style?: React.CSSProperties
  backgroundColor?: string
  color?: string
}

export const Navbar = React.forwardRef<
  HTMLElement,
  PropsWithChildren<NavbarProps>
>(
  (
    {
      backgroundColor,
      color,
      children,
      className,
      contentWidth = SITE_MAX_WIDTH,
      fixed,
      matchContentWidth = true,
      mobileBreakpoint = 600,
      role = 'banner',
      sticky = false,
      style,
      ...props
    }: NavbarProps,
    ref: React.Ref<HTMLElement>
  ): React.ReactElement => {
    const isSmallScreen = useMediaQuery(`(min-width: ${mobileBreakpoint}px)`)

    const contentWidthAsString = useMemo(
      () =>
        typeof contentWidth === 'string' ? contentWidth : `${contentWidth}px`,
      [contentWidth]
    )

    const leftNode = useMemo(
      () =>
        React.Children.map(children, (item) =>
          React.isValidElement(item) && item.type === NavbarLeft ? item : null
        ),
      [children]
    )

    const centerNode = useMemo(
      () =>
        React.Children.map(children, (item) =>
          React.isValidElement(item) && item.type === NavbarCenter ? item : null
        ),
      [children]
    )
    const rightNode = useMemo(
      () =>
        React.Children.map(children, (item) =>
          React.isValidElement(item) && item.type === NavbarRight ? item : null
        ),
      [children]
    )

    return (
      <Container
        backgroundColor={backgroundColor}
        className={className}
        color={color}
        fixed={fixed}
        ref={ref}
        role={role}
        sticky={sticky}
        style={style}
        {...props}
      >
        <Content
          contentWidth={contentWidthAsString}
          matchContentWidth={matchContentWidth}
        >
          {leftNode &&
            leftNode.map((node, index) => (
              <NavbarLeft
                backgroundColor={backgroundColor}
                color={color}
                key={index}
                {...getPropsFromReactNode(node)}
                mobileBreakpoint={mobileBreakpoint}
              >
                {getChildrenFromReactNode(node)}
              </NavbarLeft>
            ))}

          {!isSmallScreen &&
            centerNode &&
            centerNode.map((node, index) => (
              <NavbarCenter key={index} {...getPropsFromReactNode(node)}>
                {getChildrenFromReactNode(node)}
              </NavbarCenter>
            ))}

          {rightNode &&
            rightNode.map((node, index) => (
              <NavbarRight key={index} {...getPropsFromReactNode(node)}>
                {getChildrenFromReactNode(node)}
              </NavbarRight>
            ))}
        </Content>
      </Container>
    )
  }
)

const Container = styled.header<
  Pick<NavbarProps, 'fixed' | 'sticky' | 'backgroundColor' | 'color'>
>`
  background-color: ${({ backgroundColor }) => backgroundColor ?? Colors.WHITE};
  color: ${({ color }) => color ?? Colors.MINE_SHAFT};
  border-bottom: 1px solid ${Colors.ALTO};
  border-bottom-color: ${Colors.ALTO};
  box-sizing: border-box;
  height: 56px;
  left: 0;
  padding-left: 10px;
  padding-right: 10px;
  position: ${({ fixed, sticky }) =>
    sticky ? 'sticky' : fixed ? 'fixed' : undefined};
  transition: background-color 500ms ease-in-out, color 500ms ease-in-out;
  width: 100vw;
  max-width: 100%;
  // width: ${({ fixed }) => (fixed ? '100%' : undefined)};

  ${({ fixed, sticky }) =>
    fixed === 'top' || sticky
      ? 'top: 0;'
      : fixed === 'bottom'
      ? 'bottom: 0;'
      : undefined};
  z-index: 900;
`
const Content = styled.div<
  Pick<NavbarProps, 'contentWidth' | 'matchContentWidth'>
>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  max-width: ${({ contentWidth, matchContentWidth }) =>
    matchContentWidth ? contentWidth : '100%'};
  width: 100%;
`

export default Navbar
