import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'

import { Event as EventProps } from '@sportsyou/api'
import { Colors } from '@sportsyou/core'

import AgendaItem from './agenda-item'

export interface ExtdCalendarEvent extends EventProps {
  agendaDate?: string
  customTime?: string
}

interface Props {
  contentMaxHeight?: number
  events: ExtdCalendarEvent[]
  onClickEvent?: (event?: ExtdCalendarEvent, date?: string) => void
  style?: React.CSSProperties
}

type NativeAttributes = Omit<React.HTMLAttributes<HTMLElement>, keyof Props>
export type AgendaWidgetProps = Props & NativeAttributes

export const AgendaWidget: React.FC<AgendaWidgetProps> = ({
  events,
  onClickEvent,
}: AgendaWidgetProps) => {
  type Section = {
    events: Array<ExtdCalendarEvent>
    title: string
  }
  const [sections, setSections] = useState<Array<Section>>([])

  useEffect(() => {
    const _sections = events.reduce(
      (acc: Array<Section>, event: ExtdCalendarEvent) => {
        const section = acc.find(
          (section: Section) =>
            section.title ===
            moment(event.agendaDate ?? event.startDate).format('YYYY-MM-DD')
        )
        if (section) {
          section.events.push(event)
        } else {
          acc.push({
            title: moment(event.agendaDate ?? event.startDate).format(
              'YYYY-MM-DD'
            ),
            events: [event],
          })
        }
        return acc
      },
      []
    )
    setSections(_sections)
  }, [events])

  const renderSectionHeader = (title: string, index: number) => {
    const date = moment(title)
    return (
      <SectionHeader index={index} key={title}>
        <Day>{date.format('ddd')}</Day>
        <Date>{date.format('MMM D')}</Date>
      </SectionHeader>
    )
  }

  return sections.length === 0 ? (
    <>
      <P>No scheduled events.</P>
      <P>Your scheduled events will appear here.</P>
    </>
  ) : (
    sections.map((section, index) => (
      <div key={`${index}-${section.title}`}>
        {renderSectionHeader(section.title, index)}
        {section.events.map((event: ExtdCalendarEvent, index: number) => (
          <AgendaItem
            event={event}
            key={`${index}-${section.title}-${event.id}`}
            onClickEvent={() => onClickEvent?.(event, section.title)}
          />
        ))}
      </div>
    ))
  )
}

const SectionHeader = styled.header<{ index?: number }>`
  border-bottom: 1px solid ${Colors.MYSTIC};
  border-top: ${({ index }) =>
    index === 0 ? 'none' : `1px solid ${Colors.MYSTIC}`};
  color: ${Colors.PUNCH};
  padding: 8px 10px;
  text-transform: uppercase;
`
const Day = styled.span`
  font-weight: bold;
  margin-right: 5px;
`
const Date = styled.span``
const P = styled.p`
  color: ${Colors.SHUTTLE_GRAY};
  font-size: 14px;
  margin-bottom: 0;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  &:last-child {
    margin-bottom: 10px;
  }
`

export default AgendaWidget
